import { message } from "antd";
import timezone from "moment-timezone";
import axios from "axios";
import { STORAGE_ACCESS_TOKEN, LogoutClearStorage, vCode } from "config";
import { getPlatformValue } from "utils/common";
import i18n from "i18n";

const svDeviceInfo = `${getPlatformValue()} web ${vCode}`

export const request = axios.create();

request.interceptors.request.use(
    config => {
        const token = window.localStorage.getItem(STORAGE_ACCESS_TOKEN) as any;
        config.headers['timezone'] = timezone.tz.guess();
        config.headers['svDeviceInfo'] = svDeviceInfo;
        if (token && token.length > 10) {
            /* tslint:disable:no-string-literal */
            config.headers["Authorization"] = "Bearer " + token;
        }

        return config;
    },
);

request.interceptors.response.use(
    response => {
        if ((window as any).$environment !== "svmeet" && response.data.code === 404) {
            message.warn(response.data.data.value)
        }
        return response;
    },
    async error => {
        if (error.response.status === 401) {
            LogoutClearStorage()
            if (!is_weixin() && window.location.href.indexOf("/login") === -1) {
                window.location.href = window.location.origin + window.location.pathname + "#/login" + window.location.search
                message.info(i18n.t("message.loginExpired") || error.response.data.msg)
            }
            return Promise.reject(error);
        } else if (error.response.status === 404) {
            if (!is_weixin()) {
                message.error("连接不到服务器")
            }
        }
        return Promise.reject(error);
    },
);

// 微信打开网页
function is_weixin() {
    const ua = navigator.userAgent.toLowerCase() as string;
    if (ua.indexOf('micromessenger') !== -1) {
        return true;
    }
}
