import { request } from "utils/request";
import { API_URL } from "config";


/**
 * @name 获取等候室的人
 */
export async function getWaitingRoomUser(primaryKey) {
    const response = (await request.get(
        `${API_URL}/waiting/get/user?meetingListId=${primaryKey}`)).data;

    return response;
}

/**
 * @name 等候室人向主持人发送信息
 */
export async function sendWaitingRoomMsgUserToHost(meetId, userId, msg) {
    const response = (await request.post(
        `${API_URL}/waiting/staff/send`,
        {
            msg,
            userId,
            meetingListId: meetId
        },
    )).data;

    return response;
}

/**
 * @name 主持人给等候室人发送信息
 */
export async function sendWaitingRoomMsgHostToUser(meetWaitingId, msg) {
    const response = (await request.post(
        `${API_URL}/waiting/host/send`,
        {
            toUserId: meetWaitingId,
            msg,
        },
    )).data;

    return response;
}

/**
 * @name  允许进入会议
 */
export async function allowJoinMeetRoom(primaryKey, meetWaitingId) {
    const response = (await request.post(
        `${API_URL}/waiting/allow`, {
        meetingListId: primaryKey,
        userId: meetWaitingId
    }
    )).data;

    return response;
}

/**
 * @name  拒绝进入会议
 */
export async function refuseJoinMeetRoom(primaryKey, meetWaitingId) {
    const response = (await request.post(
        `${API_URL}/waiting/refuse`, {
        meetingListId: primaryKey,
        userId: meetWaitingId
    }
    )).data;

    return response;
}

/**
 * @name  修改答辩人姓名
 */
export async function editWaitingUserName(meetWaitingId, name) {
    const response = (await request.post(
        `/api/meeting/sw/meetingList/editName/${meetWaitingId}`,
        {
            name
        }
    )).data;

    return response;
}