import React from 'react';
import ReactDOM from 'react-dom';
import DragObj from './drag';
import { Row, Col, Button } from "antd";
import Draggable, { DraggableCore } from 'react-draggable'; // <DraggableCore>

//main_class和title_class都是类名
export default class Index extends React.Component {
    drag_obj: any;
    WindowWi: any;
    WindowHi: any;
    DefpageX: any;
    DefpageY: any;
    state = {
        pageHeight: 0,
        PageWidth: 0,
        moving: false,
        diffX: 0,
        diffY: 0,
        pageX: 0,
        pageY: 0
    }
    componentDidMount() {
        this.InitWindow()
    }

    InitWindow = () => {
        // 初始化窗口在当前页面的正中
        const { pageHeight, PageWidth } = this.state;
        let WindowX = document.body.clientWidth;
        let WindowY = document.body.clientHeight;
        let pageX = WindowX / 2 - PageWidth / 2;
        let pageY = WindowY / 2 - pageHeight / 2;
        this.WindowWi = WindowX;//页面的宽、高
        this.WindowHi = WindowY;
        this.DefpageX = pageX;//初始坐标
        this.DefpageY = pageY;
        this.setState({ pageX, pageY });
    }

    // init = () => {
    //     setTimeout(() => {
    //         const child_node = ReactDOM.findDOMNode(this.refs.child) as any; //获取到原生的dom元素
    //         if (child_node) {
    //             this.drag_obj = new DragObj(
    //                 main_class ? child_node.querySelector(`.${main_class}`) : child_node, //只拖拽类名为 ${main_class} 的div
    //                 title_class ? child_node.querySelector(`.${title_class}`) : child_node //当鼠标按在类名为 ${title_class} 的div上时才允许拖拽
    //             );
    //         }
    //     }, 0);
    // };

    componentWillUnmount() {
        if (this.drag_obj) {
            this.drag_obj.destory();
        }
    }

    render() {
        return (
            <Draggable
                axis="x"
                handle=".handle"
                defaultPosition={{ x: 0, y: 0 }}
                // position={null}
                grid={[25, 25]}
                scale={1}
                // onStart={this.handleStart}
                // onDrag={this.handleDrag}
                // onStop={this.handleStop}
                >
                <div>
                    <div className="handle">Drag from here</div>
                    <div>This readme is really dragging on...</div>
                </div>
            </Draggable>
        )
        // const { pageHeight, PageWidth, pageX, pageY } = this.state;
        // return <div className="DragWindows" style={{ height: pageHeight, width: PageWidth, left: pageX, top: pageY }}>
        //     <Row className="Header DragWindowsHeader" justify="space-between" onMouseDown={this.onMouseDown}>
        //         <Col className="Title">标题</Col>
        //         <Col className="Edit">
        //             aa
        //                 {/* <Icon type="scan" className='Header_deformation' onClick={this.deformation} />
        //                 <Icon type="close" className='Header_close' onClick={this.props.onCancel} /> */}
        //         </Col>
        //     </Row>
        //     <div className="Content">
        //         {this.props.children}
        //     </div>
        //     <Row className="Footer" justify="end">
        //         <Col>
        //             {/* <Button onClick={this.props.onCancel}>取消</Button>
        //                 <Button type="primary" onClick={this.props.onOk}>确定</Button> */}
        //         </Col>
        //     </Row>
        // </div>
    }

    // 公共方法
    getPosition = (e) => {
        const titleDom = e.target// 标题DOM元素titleDom
        const X = titleDom.getBoundingClientRect().left// titleDom的坐标(视窗)
        const Y = titleDom.getBoundingClientRect().top
        let mouseX = e.clientX;// 鼠标点击的坐标(页面)
        let mouseY = e.clientY;
        const diffX = mouseX - X// 鼠标点击位置与modal的位置差
        const diffY = mouseY - Y
        return { X, Y, mouseX, mouseY, diffX, diffY };
    }
    // 鼠标移动
    onMouseDown = (e) => {
        const position = this.getPosition(e)
        window.onmousemove = this.onMouseMove;
        window.onmouseup = this.onMouseUp
        this.setState({ moving: true, diffX: position.diffX, diffY: position.diffY });
    }
    // 松开鼠标，设置modal状态为不可移动
    onMouseUp = (e) => {
        const { moving } = this.state
        moving && this.setState({ moving: false });
    }
    // 鼠标移动重新设置modal的位置
    onMouseMove = (e) => {
        const { moving, diffX, diffY } = this.state
        if (moving) {
            const position = this.getPosition(e);// 获取鼠标位置数据
            const x = position.mouseX - diffX;// 计算modal应该随鼠标移动到的坐标
            const y = position.mouseY - diffY;
            const { clientWidth, clientHeight } = document.documentElement;// 窗口大小，结构限制，需要做调整，减去侧边栏宽度
            const modal = document.getElementsByClassName("DragWindowsHeader")[0] as any
            if (modal) {
                const maxHeight = clientHeight - modal.offsetHeight;// 计算modal坐标的最大值
                const maxWidth = clientWidth - modal.offsetWidth;
                const left = x > 0 ? (x < maxWidth ? x : maxWidth) : 0;// 判断得出modal的最终位置，不得超出浏览器可见窗口
                const top = y > 0 ? (y < maxHeight ? y : maxHeight) : 0
                this.setState({ pageX: left, pageY: top })
            }
        }
    }
    enlarge: any;
    // DefPageHi:any;
    deformation = () => {
        const { enlarge } = this;
        this.enlarge = !enlarge;
        if (this.enlarge) {
            this.setState({ pageHeight: this.WindowHi, PageWidth: this.WindowWi, pageX: 0, pageY: 0 });
        } else {
            // this.setState({pageHeight:this.DefPageHi,PageWidth:this.DefPageWi,pageX:this.DefpageX,pageY:this.DefpageY});
        }
    }
};