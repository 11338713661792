
import React from "react";
import i18n from "i18n";
import api from "api";
import { withRouter } from 'react-router-dom';
import { SUBTITLE_USAGE_MODAL } from "config";
import { formatSeconds } from "utils/data";
import { toPurchaseEnhancedService } from "utils/common";
import "./index.less";

interface IState {
    list: Array<{
        duration: number;
        durationText: string;
        name: string;
        primaryKey: ""
    }>,
    totalText: string;
}
class Index extends React.Component<{
    onClose: () => void;
}, IState> {

    public state: IState = {
        list: [],
        totalText: ""
    }

    public async componentDidMount() {
        const subtitle = localStorage.getItem(SUBTITLE_USAGE_MODAL)
        if (subtitle) {
            const res = await api.summary.getTransliterationConsumption(subtitle)
            if (res.code === 200) {
                let total = 0;
                res.data && res.data.forEach(element => {
                    total += element.duration;
                    element.durationText = formatSeconds(element.duration, 2)
                });
                this.state.totalText = formatSeconds(total, 2)
                this.setState({ list: res.data, totalText: this.state.totalText })
            }
        }
    }

    public render() {
        return <div className='translation-detail-component'>
            <div className='container' >
                <div className="title" dangerouslySetInnerHTML={{ __html: i18n.t("translationServiceTotalTime") }}></div>
                <div className="total-time">{this.state.totalText}</div>
                {
                    this.state.list.map((node, index) => {
                        return <div className="item">
                            <span>{node.name || (i18n.t("userText") + index + 1)}</span>
                            <span>{node.durationText}</span>
                        </div>
                    })
                }
                <div className="btns">
                    <div className="okBtn" onClick={() => {
                        localStorage.removeItem(SUBTITLE_USAGE_MODAL)
                        this.props.onClose()
                    }}>
                        {i18n.t("IKnow")}
                    </div>
                    <div className="buyBtn" onClick={this.goBuy.bind(this)}>
                        {i18n.t("servicePurchase")}
                    </div>
                </div>
            </div>
        </div>
    }

    public goBuy() {
        toPurchaseEnhancedService()
    }

}

export default withRouter(Index)