import React from "react";
import i18n from "i18n";
import api from "api";
import { RouteComponentProps } from "react-router-dom";
import Tooltip from "antd/lib/tooltip";
import Collapse from "antd/lib/collapse";
import Modal from "antd/lib/modal";
import Input from "antd/lib/input";
import message from "antd/lib/message";
import Select from "antd/lib/select";
import Dropdown from "antd/lib/dropdown"
import Menu from "antd/lib/menu";
import Checkbox from "antd/lib/checkbox";
import { CURRENT_MEETING_DATA } from "config";
import { IParticipantItem, IMeetingItem, IMyPermission, IRaiseHandsListItem } from "models/meet";
import { EllipsisOutlined } from '@ant-design/icons';
import "./index.less";
interface IState {
    userId: string;
    userName: string;
    currentIndex: number;
    menu: "participant" | "raiseHand" | "remoteLiveUser";
    primaryKey: string;
    message: any[],
    isOpenWaitingRoom: boolean;
    meetData: IMeetingItem,
    language: any[];
    raiseHand: boolean;
}

class Index extends React.Component<{
    videoList: IParticipantItem[],
    permissionMenu: object;
    myPermission: IMyPermission;
    raiseHandsList: IRaiseHandsListItem[];
    muted: boolean;
    remoteLiveUserList: Array<{
        name: string;
        userId: string;
    }>;
    switchParticipantTranslationLang: (lang) => void;
    kickParticipant: (participantId: string) => void;
    grantOwner: (participantId: string) => void;
    setAllMutedPolicy: () => void;
    changePermission: () => void;
    raiseHand: () => void;
    confirmSpeakOperation: (participantId: string, status: 0 | 1) => void;
    invitationToSpeak: (participantId: string) => void;
    toggleRemote: (userId) => void
    previewDevice: () => void
    streamLeaveChat: () => void // 移除第三方设备
} & RouteComponentProps, IState> {

    public state: IState = {
        userId: "",
        userName: "",
        currentIndex: -1,
        menu: "participant",
        primaryKey: "",
        message: [],
        isOpenWaitingRoom: false,
        language: [],
        meetData: {
            meetId: '',
            primaryKey: '',
            transliteration: false
        },
        raiseHand: false
    }

    public componentDidMount() {
        let meet: any = window.localStorage.getItem(CURRENT_MEETING_DATA)
        if (meet) {
            meet = JSON.parse(meet)
            this.setState({ primaryKey: meet.primaryKey, isOpenWaitingRoom: meet.isOpenWaitingRoom, meetData: meet })
        }
        this.lang()
        const raiseHand = sessionStorage.getItem("raiseHand")
        this.setState({ raiseHand: raiseHand === "true" ? true : false })
        sessionStorage.removeItem("raiseHand")
    }

    public render() {
        const { menu, raiseHand, meetData } = this.state;
        return <div className='meetListMain'>
            <div className='navTitle'>
                <div style={{ color: menu === "participant" ? "#31A6FF" : "#E5E5E5" }} onClick={() => this.setState({ menu: "participant" })}>{i18n.t(meetData.live === 0 ? 'nearbyGuests' : 'meet.participant')}（{this.props.videoList.length}）</div>
                {this.props.isLocalModerator && meetData.live === 0 && <div style={{ color: menu === "remoteLiveUser" ? "#31A6FF" : "#E5E5E5" }} onClick={() => this.setState({ menu: "remoteLiveUser" })}>{i18n.t('liveParticipants')}（{this.props.remoteLiveUserList.length}）</div>}
                {this.props.isLocalModerator && <div style={{ color: menu === "raiseHand" ? "#31A6FF" : "#E5E5E5" }} onClick={() => this.setState({ menu: "raiseHand" })}>{i18n.t('raiseHandsList')}（{this.props.raiseHandsList.length}）</div>}
            </div>
            {/* {
                isOpenWaitingRoom && this.props.isLocalModerator && <div className={styles.menu}>
                    <div style={{ visibility: menu === "waiting" ? "initial" : "hidden" }}
                        onClick={() => this.setState({ menu: "meet" })}>
                        <div className={styles.iconone}><i className={`iconfont icon-select`}></i></div>会议室</div>
                    <div style={{ visibility: menu === "meet" ? "initial" : "hidden" }}
                        onClick={() => this.setState({ menu: "waiting" }, () => {
                            console.log(this.waitingRoomUserRef && this.waitingRoomUserRef.getWaitingRoomUser())
                        })}>
                        等候室<div className={styles.icontwo}><i className={`iconfont icon-select`}></i></div></div>
                </div>
            } */}
            {menu === "participant" && this.userList()}
            {menu === "raiseHand" && this.raiseHandList()}
            {menu === "remoteLiveUser" && this.remoteLiveUserList()}
            {/* <WaitingRoomUser show={menu === "waiting" ? true : false}
                ref={(refs) => this.waitingRoomUserRef = refs}
                primaryKey={primaryKey}
                isLocalModerator={this.props.isLocalModerator} /> */}
            {
                menu === "participant" && this.props.isLocalModerator &&
                <div className='btnBox'>
                    {/* <div className='btn' onClick={() => {
                    }}>
                        <img src={require("static/images/meet/icon-locking.png")} alt="" />
                        {i18n.t("lockScreen")}
                    </div> */}
                    <div className='btn' onClick={() => {
                        const res = Modal.confirm({
                            title: null,
                            icon: null,
                            className: "cast-modal",
                            content: <div className="cast-modal-content" >
                                <div>{i18n.t("dialog.muteAllParticipants")}</div>
                                <div className="msg">{i18n.t("dialog.muteAllParticipantsMessage")}</div>
                                <div className="btns">
                                    <div className="btn1" onClick={() => {
                                        res.destroy()
                                    }}>{i18n.t("cancel")}</div>
                                    <div className="btn2" onClick={() => {
                                        this.props.setAllMutedPolicy()
                                        message.success({
                                            content: i18n.t('AudienceIsMuted'),
                                            icon: <span></span>,
                                            style: {
                                                marginTop: '30vh',
                                            },
                                            duration: 5,
                                            className: "meet-custom-message"
                                        })
                                        res.destroy()
                                    }}>{i18n.t("meet.determine")}</div>
                                </div>
                            </div>,
                        })
                    }}>
                        <img src={require("static/images/meet/icon-all-mute.png")} alt="" />
                        {i18n.t("muteAll")}
                    </div>
                    <Dropdown overlay={<Menu>
                        {

                            Object.keys(this.props.permissionMenu).map((node, index) => {
                                return <Menu.Item key={index}>
                                    <Checkbox checked={this.props.permissionMenu[node].checkout} onClick={() => this.props.changePermission(node)}>{i18n.t(node)}</Checkbox>
                                </Menu.Item>
                            })
                        }
                    </Menu>} placement="topRight">
                        <div className="moreBtn">
                            {i18n.t("meet.more")}
                        </div>
                    </Dropdown>

                </div>
            }
            {
                this.props.muted && this.props.myPermission.speak === 1 && menu === "participant" && !this.props.isLocalModerator && <div className='btnBox' style={{ justifyContent: "center" }}>
                    <div className='btn handBtn' onClick={() => {
                        this.setState({ raiseHand: !raiseHand }, () => {
                            this.props.raiseHand(this.state.raiseHand)
                        })
                    }}>
                        <img src={require("static/images/meet/icon-hand.png")} alt="" />
                        {i18n.t(raiseHand ? "cancelRaiseHands" : "raiseHands")}
                    </div>
                </div>
            }
        </div>
    }

    public userList() {
        return <div className='userList'>
            <Collapse defaultActiveKey={['1']}
                expandIconPosition="right"
                style={{ color: "#fff" }}
                ghost={true}
            >
                {
                    this.props.videoList.length && this.props.videoList.map((participant, index) => {
                        return <Collapse.Panel
                            className="listMode-Collapse"
                            key={participant.userId}
                            disabled={(!participant.local && this.props.isLocalModerator) ? false : true}
                            // showArrow={x.local ? false : this.props.isLocalModerator}
                            showArrow={false}
                            header={
                                <div className='item'>
                                    <div className='left'>
                                        <Tooltip visible={(window as any).$svdebug === true && (index === this.state.currentIndex)} placement="leftTop" title={this.TooltipRender(participant.stats)} overlayClassName="">
                                            <img src={participant.avatar || require(participant.isDevice ? "static/images/meet/icon-camera.png" : "static/images/default-photo.png")}
                                                onMouseEnter={() => this.setState({ currentIndex: index })}
                                                onMouseLeave={() => this.setState({ currentIndex: -1 })}
                                            />
                                        </Tooltip>
                                        <div className='userBox'>{this.state.userId === participant.userId ?
                                            <Input placeholder={i18n.t('meet.pleaseEnter')} className='input'
                                                maxLength={8}
                                                value={this.state.userName}
                                                onChange={(e) => this.setState({ userName: e.currentTarget.value.trim() })}
                                                onBlur={() => {
                                                    if (!this.state.userName) {
                                                        message.info(i18n.t('message.pleaseEnterUserName'))
                                                        return
                                                    }
                                                    this.setState({ userId: "" },
                                                        () => this.props.setDisplayName(this.state.userName))
                                                }} /> :
                                            <span className='_displayName'>{(participant.realName ? participant.realName : (participant._displayName ? participant._displayName : i18n.t('meet.stranger')))}</span>}
                                            <span className='role'>{participant.local && `(${i18n.t('meet.me')})`} {participant.moderator && <img src={require("static/images/meet/speaker.png")} alt="" />}</span>
                                        </div>
                                    </div>
                                    {
                                        !participant.isDevice ? <div className='mutedBox'>
                                            {/* 静音 */}
                                            {participant.muted ? <div className={`icon iconfont icon-muted`} style={{ color: "#F66262" }}></div> :
                                                <div className={`icon iconfont icon-unmuted`} style={{ color: participant.voiceIncentive ? "#09FFFF" : "#fff", cursor: participant.local || !this.props.isLocalModerator ? "inherit" : "pointer" }} onClick={() => {
                                                    if (participant.local || !this.props.isLocalModerator) return;
                                                    const res = Modal.confirm({
                                                        title: null,
                                                        icon: null,
                                                        className: "cast-modal",
                                                        content: <div className="cast-modal-content" >
                                                            <div>{i18n.t("dialog.silentAttendee", { name: participant._displayName })}</div>
                                                            <div className="msg">{i18n.t("dialog.silentAttendeeMessage")}</div>
                                                            <div className="btns">
                                                                <div className="btn1" onClick={() => {
                                                                    res.destroy()
                                                                }}>{i18n.t("cancel")}</div>
                                                                <div className="btn2" onClick={() => {
                                                                    this.props.muteRemote(participant.userId)
                                                                    res.destroy()
                                                                }}>{i18n.t("meet.determine")}</div>
                                                            </div>
                                                        </div>,
                                                    })
                                                }}></div>}
                                            {/* 摄像头 */}
                                            {participant.list.length ? (participant.list.filter(ele => ele.mediaType === "video").length === 0 ?
                                                <div className={`icon iconfont icon-video_off`} style={{ color: "#F66262" }}></div> :
                                                <div className={`icon iconfont icon-video`} style={{ color: "#fff", cursor: participant.local || !this.props.isLocalModerator ? "inherit" : "pointer" }}
                                                    onClick={() => {
                                                        if (participant.local || !this.props.isLocalModerator) return;
                                                        const res = Modal.confirm({
                                                            title: null,
                                                            icon: null,
                                                            className: "cast-modal",
                                                            content: <div className="cast-modal-content" >
                                                                <div>{i18n.t("dialog.closeParticipantCamera", { name: participant._displayName })}</div>
                                                                <div className="msg">{i18n.t("dialog.closeParticipantCameraMessage")}</div>
                                                                <div className="btns">
                                                                    <div className="btn1" onClick={() => {
                                                                        res.destroy()
                                                                    }}>{i18n.t("cancel")}</div>
                                                                    <div className="btn2" onClick={() => {
                                                                        this.props.closeCameraRemote("user", participant.userId)
                                                                        res.destroy()
                                                                    }}>{i18n.t("meet.determine")}</div>
                                                                </div>
                                                            </div>,
                                                        })
                                                    }}></div>) :
                                                <div className={`icon iconfont icon-video_off`} style={{ color: "#F66262" }}></div>}
                                            {//翻译状态
                                                this.props.isLocalModerator && this.state.meetData.transliteration &&
                                                <img className="speeching" src={participant.speeching ? require("static/images/meet/subtitle_status_open.png") :
                                                    require("static/images/meet/subtitle_status_close.png")} />
                                            }

                                            { // 更多
                                                this.props.isLocalModerator && ((!participant.local && !participant.moderator) ? <Dropdown overlay={<Menu>
                                                    <Menu.Item>
                                                        <span onClick={this.grantOwner.bind(this, participant)}>{i18n.t("setAsHost")}</span>
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        <span onClick={this.kick.bind(this, participant)}>{i18n.t("kickMeeting")}</span>
                                                    </Menu.Item>
                                                    {
                                                        participant.muted && <Menu.Item>
                                                            <span onClick={() => this.props.invitationToSpeak(participant.businessUserId)}>{i18n.t("InvitationSpeak")}</span>
                                                        </Menu.Item>
                                                    }
                                                    {
                                                        this.state.meetData.live === 0 && <Menu.Item>
                                                            <span onClick={this.toggleRemote.bind(this, participant)}>{i18n.t("returnFarField")}</span>
                                                        </Menu.Item>
                                                    }
                                                </Menu>} placement="bottomCenter">
                                                    <EllipsisOutlined style={{ fontSize: "20px", margin: "0 18px 0 10px" }} />
                                                </Dropdown> :
                                                    <EllipsisOutlined style={{ fontSize: "20px", margin: "0 18px 0 10px", cursor: "not-allowed" }} />)
                                            }

                                            { // 切换语言
                                                this.props.isLocalModerator ? <Select className="languageSelect"
                                                    defaultValue={i18n.t("notSet")}
                                                    value={participant.translationLanguage || undefined}
                                                    onChange={(value) => this.props.switchParticipantTranslationLang(value, participant.userId)}
                                                >
                                                    {
                                                        this.state.language.map(x => {
                                                            return <Select.Option key={x.value} value={x.value}>{x.label}</Select.Option>
                                                        })
                                                    }
                                                </Select> : <Select className="languageSelect"
                                                    disabled={!participant.local}
                                                    defaultValue={i18n.t("notSet")}
                                                    value={participant.translationLanguage || undefined}
                                                    onChange={(value) => this.props.switchParticipantTranslationLang(value, participant.userId)}
                                                >
                                                    {
                                                        this.state.language.map(x => {
                                                            return <Select.Option key={x.value} value={x.value}>{x.label}</Select.Option>
                                                        })
                                                    }
                                                </Select>
                                            }
                                        </div> : <div className='mutedBox'>
                                            { // 更多
                                                this.props.isLocalModerator && ((!participant.local && !participant.moderator) ? <Dropdown overlay={<Menu>
                                                    <Menu.Item>
                                                        <span onClick={this.previewDevice.bind(this, participant)}>{i18n.t("preview")}</span>
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        <span onClick={this.kickDevice.bind(this, participant)}>{i18n.t("kickMeeting")}</span>
                                                    </Menu.Item>
                                                </Menu>} placement="bottomCenter">
                                                    <EllipsisOutlined style={{ fontSize: "20px", margin: "0 18px 0 10px" }} />
                                                </Dropdown> :
                                                    <EllipsisOutlined style={{ fontSize: "20px", margin: "0 18px 0 10px", cursor: "not-allowed" }} />)
                                            }
                                        </div>
                                    }
                                </div>
                            }>
                        </Collapse.Panel>
                    })
                }
            </Collapse>
        </div>
    }

    // 举手列表
    public raiseHandList() {
        return <div className='userList' style={{ marginBottom: "54px" }}>
            {
                this.props.raiseHandsList.length ? this.props.raiseHandsList.map((participant, index) => {
                    return <div key={participant.participantId} className='item'>
                        <div className='left'>
                            <img src={participant.avatar || require("static/images/default-photo.png")}
                                onMouseEnter={() => this.setState({ currentIndex: index })}
                                onMouseLeave={() => this.setState({ currentIndex: -1 })}
                            />
                            <div className='userBox'>{
                                <span className='_displayName'>{(participant.name || i18n.t('meet.stranger'))}</span>}
                                <span className='role'>{participant.moderator && <img src={require("static/images/meet/speaker.png")} alt="" />}</span>
                            </div>
                        </div>
                        {
                            this.props.isLocalModerator ?
                                <div className="handBtns">
                                    <div onClick={() => this.props.confirmSpeakOperation(participant.participantId, 0, participant.userId)}>{i18n.t("InvitationSpeak")}</div>
                                    <div onClick={() => this.props.confirmSpeakOperation(participant.participantId, 1, participant.userId)}>{i18n.t("IgnoreRequest")}</div>
                                </div> : <img src={require("static/images/meet/icon-hand-a.png")} alt="" />
                        }

                    </div>
                }) : <div className="noData">{i18n.t("noRaisedHand")}</div>
            }
        </div>
    }

    // 远场人员列表
    public remoteLiveUserList() {
        return <div className='userList' style={{ marginBottom: "54px" }}>
            {
                this.props.remoteLiveUserList.length && this.props.remoteLiveUserList.map((participant, index) => {
                    return <div key={participant.userId} className='item'>
                        <div className='left'>
                            <img src={require("static/images/default-photo.png")}
                                onMouseEnter={() => this.setState({ currentIndex: index })}
                                onMouseLeave={() => this.setState({ currentIndex: -1 })}
                            />
                            <div className='userBox'>{
                                <span className='_displayName'>{(participant.name || i18n.t('meet.stranger'))}</span>}
                            </div>
                        </div>
                        <div className="handBtns">
                            <div onClick={() => this.toggleNearField(participant.userId)}>{i18n.t("InvitationSpeak")}</div>
                        </div>
                    </div>
                })
            }
        </div>
    }

    // 踢人
    public kick(participant) {
        const res = Modal.confirm({
            title: null,
            icon: null,
            className: "cast-modal",
            content: <div className="cast-modal-content" >
                <div>{i18n.t("kickMeet", { name: participant._displayName })}</div>
                <div className="btns">
                    <div className="btn1" onClick={() => {
                        res.destroy()
                    }}>{i18n.t("cancel")}</div>
                    <div className="btn2" onClick={() => {
                        this.props.kickParticipant(participant.participantId)
                        res.destroy()
                    }}>{i18n.t("meet.determine")}</div>
                </div>
            </div>,
        })
    }
    // 设为主持人
    public grantOwner(participant) {
        const res = Modal.confirm({
            title: null,
            icon: null,
            className: "cast-modal",
            content: <div className="cast-modal-content" >
                <div>{i18n.t("setAsHostConfirm", { name: participant._displayName })}</div>
                <div className="btns">
                    <div className="btn1" onClick={() => {
                        res.destroy()
                    }}>{i18n.t("cancel")}</div>
                    <div className="btn2" onClick={() => {
                        this.props.grantOwner(participant.participantId)
                        res.destroy()
                    }}>{i18n.t("meet.determine")}</div>
                </div>
            </div>,
        })
    }

    // 将用户切换到远场
    public toggleRemote(participant) {
        const res = Modal.confirm({
            title: null,
            icon: null,
            className: "cast-modal",
            content: <div className="cast-modal-content" >
                <div>{i18n.t("sreturnFarFieldConfirm", { name: participant._displayName })}</div>
                <div className="btns">
                    <div className="btn1" onClick={() => {
                        res.destroy()
                    }}>{i18n.t("cancel")}</div>
                    <div className="btn2" onClick={() => {
                        this.props.meetingSocket && this.props.meetingSocket.send(JSON.stringify({
                            type: "toggleRemote",
                            data: participant.businessUserId
                        }))
                        res.destroy()
                    }}>{i18n.t("meet.determine")}</div>
                </div>
            </div>,
        })
    }

    // 将用户切换到近场
    public toggleNearField(userId) {
        const list = this.props.raiseHandsList.filter(x => x.userId === userId)
        if (list.length) {// 此用户已举手，需要更改他的状态
            this.setTimer(userId)
        }
        this.props.meetingSocket && this.props.meetingSocket.send(JSON.stringify({
            type: "toggleNearField",
            data: userId
        }))
    }

    public setTimer(userId) {
        const timer = setTimeout(() => {
            clearTimeout(timer)
            const list = this.props.remoteLiveUserList.filter(x => x.userId === userId)
            if (list.length) {
                this.setTimer(userId)
            } else {
                const list = this.props.videoList.filter(x => x.businessUserId === userId)
                this.props.confirmSpeakOperation(list[0].participantId, 0, userId)
            }
        }, 2000)
    }

    // 预览设备
    public previewDevice(participant) {
        this.props.previewDevice(participant.deviceShareId)
    }

    // 移除设备
    public kickDevice(participant) {
        this.props.streamLeaveChat(participant.deviceShareId)
    }

    // 获取语言列表
    public async lang() {
        try {
            const res = await api.summary.translateLocales()
            const list: any[] = [];
            for (const key in res.data) {
                if (Object.prototype.hasOwnProperty.call(res.data, key)) {
                    const title = res.data[key].title;
                    list.push({
                        label: title,
                        value: key
                    })
                }
            }
            this.setState({ language: list })
        } catch (e) {
            message.error(i18n.t("message.requestFailed"))
        }
    }

    public TooltipRender(remoteStats) {
        if (!remoteStats) {
            return
        }
        const { download, upload } = remoteStats.bitrate || {};
        return <div>
            <div>
                <div>连接：</div>
                <div></div>
            </div>
            <div className='remoteStatsItem'>
                <div>比特率：</div>
                <div>
                    <span>
                        &darr;
                    </span>
                    {download ? `${download} Kbps` : 'N/A'}
                    <span>
                        &uarr;
                    </span>
                    {upload ? `${upload} Kbps` : 'N/A'}
                </div>
            </div>
            <div className='remoteStatsItem'>
                <div>丢包：</div>
                {this._renderPacketLoss(remoteStats)}
            </div>
            <div className='remoteStatsItem'>
                <div>分辨率：</div>
                {this._renderResolution(remoteStats)}
            </div>
            <div className='remoteStatsItem'>
                <div>帧率：</div>
                {this._renderFrameRate(remoteStats)}
            </div>
            <div className='remoteStatsItem'>
                <div>服务器数量：</div>
                <div>{remoteStats.bridgeCount}</div>
            </div>
            {
                remoteStats.e2eRtt && <div className='remoteStatsItem'>
                    <div>E2E RTT</div>
                    <div>{remoteStats.e2eRtt ? `${remoteStats.e2eRtt.toFixed(0)}ms` : 'N/A'}</div>
                </div>
            }
            {
                remoteStats.packetLoss && <div className='remoteStatsItem'>
                    <div>估计宽带：</div>
                    {this._renderBandwidth(remoteStats)}
                </div>
            }
            {this._renderTransport(remoteStats)}
            <div>Audio SSRC：{remoteStats.audioSsrc ? remoteStats.audioSsrc : "N/A"}</div>
            <div>Video SSRC：{remoteStats.videoSsrc ? remoteStats.videoSsrc : "N/A"}</div>
        </div>
    }

    // 丢包
    public _renderPacketLoss(remoteStats) {
        const { packetLoss } = remoteStats;
        if (packetLoss) {
            const { download, upload } = packetLoss;
            return <div>
                <span className='connection-info__download'>
                    &darr;
            </span>
                {download === null ? 'N/A' : `${download}%`}
                <span className='connection-info__upload'>
                    &uarr;
            </span>
                {upload === null ? 'N/A' : `${upload}%`}
            </div>
        } else {
            return <div>N/A</div>
        }
    }

    // 分辨率
    _renderResolution(remoteStats) {
        const { resolution, maxEnabledResolution } = remoteStats;
        let resolutionString = Object.keys(resolution || {})
            .map(ssrc => {
                const { width, height } = resolution[ssrc];
                if (width && height) {
                    return `${width}x${height}`
                } else {
                    let res;
                    Object.keys(resolution[ssrc] || {}).map(ssrc2 => {
                        const { width, height } = resolution[ssrc][ssrc2];
                        res = `${width}x${height}`;
                    })
                    return res;
                }
            })
            .join(', ') || 'N/A';
        if (maxEnabledResolution && maxEnabledResolution < 720) {
            const maxEnabledResolutionTitle = "verstuur max";

            resolutionString += ` (${maxEnabledResolutionTitle} ${maxEnabledResolution}p)`;
        }

        return (
            <div>{resolutionString}</div>
        );
    }

    // 帧率
    _renderFrameRate(remoteStats) {
        const { framerate } = remoteStats;
        const frameRateString = Object.keys(framerate || {})
            .map(ssrc => framerate[ssrc])
            // .map(ssrc1 => framerate[ssrc1])
            .join(', ') || 'N/A';

        return (
            <div>{frameRateString}</div>
        );
    }

    // 估计宽带
    _renderBandwidth(remoteStats) {
        const { download, upload } = remoteStats.bandwidth || {};
        return (
            <div>
                <span className='connection-info__download'>
                    &darr;
                    </span>
                {download ? `${download} Kbps` : 'N/A'}
                <span className='connection-info__upload'>
                    &uarr;
                    </span>
                {upload ? `${upload} Kbps` : 'N/A'}
            </div>
        );
    }
    // 
    _renderTransport(remoteStats) {
        const { transport } = remoteStats || {};

        if (!transport || transport.length === 0) {
            const NA = (
                <div>
                    N/A
                </div>
            );

            return [NA];
        }

        const data: any = {
            localIP: [],
            localPort: [],
            remoteIP: [],
            remotePort: [],
            transportType: []
        };

        for (let i = 0; i < transport.length; i++) {
            const ip = this.getIP(transport[i].ip);
            const localIP = this.getIP(transport[i].localip);
            const localPort = this.getPort(transport[i].localip);
            const port = this.getPort(transport[i].ip);

            if (!data.remoteIP.includes(ip)) {
                data.remoteIP.push(ip);
            }

            if (!data.localIP.includes(localIP)) {
                data.localIP.push(localIP);
            }

            if (!data.localPort.includes(localPort)) {
                data.localPort.push(localPort);
            }

            if (!data.remotePort.includes(port)) {
                data.remotePort.push(port);
            }

            if (!data.transportType.includes(transport[i].type)) {
                data.transportType.push(transport[i].type);
            }
        }

        // All of the transports should be either P2P or JVB
        let isP2P = false, isTURN = false;

        if (transport.length) {
            isP2P = transport[0].p2p;
            isTURN = transport[0].localCandidateType === 'relay'
                || transport[0].remoteCandidateType === 'relay';
        }

        const additionalData: any[] = [];

        if (isP2P) {
            additionalData.push(
                <span> (p2p)</span>);
        }
        if (isTURN) {
            additionalData.push(<span> (turn)</span>);
        }

        // First show remote statistics, then local, and then transport type.
        const tableRowConfigurations = [
            {
                additionalData,
                data: data.remoteIP,
                key: 'remoteaddress',
                label: "远端地址：",
            },
            {
                data: data.remotePort,
                key: 'remoteport',
                label: "远程端口：",
            },
            {
                data: data.localIP,
                key: 'localaddress',
                label: "本地地址：",
            },
            {
                data: data.localPort,
                key: 'localport',
                label: '本地端口：',
            },
            {
                data: data.transportType,
                key: "transportType",
                label: "传输："
            }
        ];

        return tableRowConfigurations.map(this._renderTransportTableRow.bind(this));
    }

    _renderTransportTableRow(config: any) {
        const { additionalData, data, key, label } = config;

        return (
            <div key={key} className='remoteStatsItem'>
                <div>{label}</div>
                <div> {this.getStringFromArray(data)}
                    {additionalData || null}</div>
            </div>
        );
    }

    getIP(value) {
        if (!value) {
            return '';
        }
        return value.substring(0, value.lastIndexOf(':'));
    }

    getPort(value) {
        if (!value) {
            return '';
        }
        return value.substring(value.lastIndexOf(':') + 1, value.length);
    }

    getStringFromArray(array) {
        let res = '';

        for (let i = 0; i < array.length; i++) {
            res += (i === 0 ? '' : ', ') + array[i];
        }

        return res;
    }

    private waitingRoomUserRef;
}

export default Index;
