

import { JitsiConferenceErrors, JitsiTrackErrors } from "./index";
import notification from "antd/lib/notification";
import i18n from "i18n";
import Modal from "antd/lib/modal";

function _onConferenceFailed(err, that, ...params) {
    switch (err) {
        case JitsiConferenceErrors.CONFERENCE_DESTROYED: {
            const [code] = params;
            if (code === "11008") {//企业管理员停止会议
                Modal.info({
                    title: i18n.t('meet.administratorStopMeeting'),
                    onOk: () => {
                        that.refFooter && that.refFooter.leavePage()
                    }
                })
            } else if (code === "11007") {//会议时间已到
                Modal.info({
                    title: i18n.t('meet.timeForTheMeeting'),
                    onOk: () => {
                        that.refFooter && that.refFooter.leavePage()
                    }
                })
            }
            try {
                that.stopMeet()
                that.meetingSocket && that.meetingSocket.close()
                that.meetingSocket = null
            } catch (e) {
                console.log(e)
            }
            // let's show some auth not allowed page
            break;
        }
        case JitsiConferenceErrors.NOT_ALLOWED_ERROR: {
            // let's show some auth not allowed page
            Modal.info({
                title: i18n.t('UnableVerifyIentity'),
                onOk: () => {
                    that.refFooter && that.refFooter.leavePage()
                }
            })
            break;
        }

        // not enough rights to create conference
        case JitsiConferenceErrors.AUTHENTICATION_REQUIRED: {
            console.log('AUTHENTICATION_REQUIRED')
            that.setState({ waitingModeratorJoin: true })
            // Schedule reconnect to check if someone else created the room.
            const reconnectTimeout = setTimeout(() => {
                // APP.store.dispatch(conferenceWillJoin(room));
                that.room.join();
                clearTimeout(reconnectTimeout)
            }, 5000);

            // const { password }
            //     = APP.store.getState()['features/base/conference'];

            // AuthHandler.requireAuth(room, password);

            break;
        }

        case JitsiConferenceErrors.RESERVATION_ERROR: {
            const [code, msg] = params;
            console.log(code, msg)
            // that.setState({ switchReportProblem: true })
            notification.error({ message: i18n.t('dialog.bookSystemError'), placement: "bottomLeft", duration: 8 })
            // APP.UI.notifyReservationError(code, msg);
            break;
        }

        case JitsiConferenceErrors.GRACEFUL_SHUTDOWN:
            // APP.UI.notifyGracefulShutdown();
            break;

        // FIXME FOCUS_DISCONNECTED is a confusing event name.
        // What really happens there is that the library is not ready yet,
        // because Jicofo is not available, but it is going to give it another
        // try.
        case JitsiConferenceErrors.FOCUS_DISCONNECTED: {
            const [focus, retrySec] = params;

            // APP.UI.notifyFocusDisconnected(focus, retrySec);
            break;
        }

        case JitsiConferenceErrors.FOCUS_LEFT:
        case JitsiConferenceErrors.ICE_FAILED:
        case JitsiConferenceErrors.VIDEOBRIDGE_NOT_AVAILABLE:
        case JitsiConferenceErrors.OFFER_ANSWER_FAILED:
            // APP.store.dispatch(conferenceWillLeave(room));

            // FIXME the conference should be stopped by the library and not by
            // the app. Both the errors above are unrecoverable from the library
            // perspective.
            // room.leave().then(() => connection.disconnect());
            break;

        case JitsiConferenceErrors.CONFERENCE_MAX_USERS:
            // connection.disconnect();
            // APP.UI.notifyMaxUsersLimitReached();
            break;

        case JitsiConferenceErrors.INCOMPATIBLE_SERVER_VERSIONS:
            // APP.store.dispatch(reloadWithStoredParams());
            break;

        default:
        // this._handleConferenceFailed(err, ...params);
    }
}

function _onConferenceFailedErrors(err, ...params) {
    console.log(new Date(), err, ...params)
    switch (err) {
        case JitsiConferenceErrors.CONNECTION_ERROR:
            console.log("会议连接丢失")
            break;
        case JitsiConferenceErrors.NOT_ALLOWED_ERROR: {
            console.log("NOT_ALLOWED_ERROR")
            // let's show some auth not allowed page
            break;
        }
        // not enough rights to create conference
        case JitsiConferenceErrors.AUTHENTICATION_REQUIRED:
            console.log("用户必须经过身份验证才能创建此会议")
            break;

        case JitsiConferenceErrors.RESERVATION_ERROR: {
            console.log("预定系统错误")
            break;
        }

        case JitsiConferenceErrors.GRACEFUL_SHUTDOWN:
            console.log('GRACEFUL_SHUTDOWN')
            break;

        // FIXME FOCUS_DISCONNECTED is a confusing event name.
        // What really happens there is that the library is not ready yet,
        // because Jicofo is not available, but it is going to give it another
        // try.
        case JitsiConferenceErrors.FOCUS_DISCONNECTED: {
            const [focus, retrySec] = params;
            console.log("FOCUS_DISCONNECTED")
            break;
        }

        case JitsiConferenceErrors.FOCUS_LEFT:
        case JitsiConferenceErrors.ICE_FAILED:
        case JitsiConferenceErrors.VIDEOBRIDGE_NOT_AVAILABLE:
        case JitsiConferenceErrors.OFFER_ANSWER_FAILED:
            console.log('视频桥问题')
            break;

        case JitsiConferenceErrors.CONFERENCE_MAX_USERS:
            console.log("已达到最大用户限制")
            break;

        case JitsiConferenceErrors.INCOMPATIBLE_SERVER_VERSIONS:
            console.log('INCOMPATIBLE_SERVER_VERSIONS')
            break;

        default:
            break;
    }
}
// 共享报错信息
function _handleScreenSharingError(error) {
    if (error.name === JitsiTrackErrors.SCREENSHARING_USER_CANCELED) {
        return;
    }
    let descriptionKey = error.message;
    if (error.name === JitsiTrackErrors.PERMISSION_DENIED) {
        descriptionKey = 'dialog.cannotAccessSharedScreen';
    } else if (error.name === JitsiTrackErrors.CONSTRAINT_FAILED) {
        descriptionKey = 'dialog.cameraConstraintFailedError';
    } else if (error.name === JitsiTrackErrors.SCREENSHARING_GENERIC_ERROR) {
        descriptionKey = 'dialog.screenSharingFailed';
    }
    notification.info({
        message: i18n.t(descriptionKey),
        placement: "bottomLeft",
        bottom: 60
    });
}

// 音视频报错信息
function _handleAudioVideoError(error, type) {
    let descriptionKey = error.message;
    if (error.name === JitsiTrackErrors.PERMISSION_DENIED) {
        descriptionKey = type === "video" ? 'dialog.unableCameraAccess' : 'dialog.unableMicrophoneAccess';
    } else if (error.name === JitsiTrackErrors.CONSTRAINT_FAILED) {
        descriptionKey = type === "video" ? 'dialog.cameraConstraintFailedError' : 'dialog.micConstraintFailedError';
    } else if (error.name === JitsiTrackErrors.SCREENSHARING_GENERIC_ERROR) {
        descriptionKey = 'dialog.screenSharingFailed';
    } else if (error.name === JitsiTrackErrors.GENERAL) {
        descriptionKey = type === "video" ? 'dialog.cameraUnknownError' : 'dialog.micUnknownError';
    } else if (error.name === JitsiTrackErrors.NOT_FOUND) {
        descriptionKey = type === "video" ? 'dialog.cameraNotFoundError' : 'dialog.micNotFoundError';
    } else if (error.name === JitsiTrackErrors.UNSUPPORTED_RESOLUTION && type === "video") {
        descriptionKey = 'dialog.cameraUnsupportedResolutionError'
    } else if (error.name === JitsiTrackErrors.TIMEOUT) {
        descriptionKey = type === "video" ? 'dialog.cameraTimeoutError' : 'dialog.micTimeoutError';
    }
    notification.info({
        message: i18n.t(descriptionKey),
        placement: "bottomLeft",
        bottom: 60
    });
}

export default {
    _onConferenceFailed,
    _onConferenceFailedErrors,
    _handleScreenSharingError,
    _handleAudioVideoError
}