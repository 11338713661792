
import React from "react";
import "./index.css"

export default class extends React.Component<{
    chatMessage: Array<{
        avatar: string;
        userId: string;
        message: string;
        timestamp: string;
        userName: string;
        privateMessage: boolean;
    }>;
}> {

    public scrolling = false;


    public componentDidMount() {
        const chat_contain = document.getElementById('chat_message_contain');
        if (chat_contain) {
            let t1 = 0;
            let t2 = 0;
            let timer;
            chat_contain.addEventListener('scroll', () => {
                this.scrolling = true
                clearTimeout(timer)
                timer = setTimeout(() => {
                    t2 = chat_contain.scrollTop;
                    if (t2 == t1) {
                        this.scrolling = false
                        clearTimeout(timer)
                    }
                }, 800)
                t1 = chat_contain.scrollTop
            })
        }
    }

    public render() {
        return <div className='chatMessageMain' id="chat_message_contain">
            {
                this.props.chatMessage.map((node, index) => {
                    return <div key={node.userId + "" + index} className='chatMsgBox'>
                        <div className='main'>
                            <img src={node.avatar ||require('static/images/default-photo.png')} alt="" />
                            <span className='userName' style={{ color: node.userId === "系统通知" ? "rgba(247, 118, 35, 1)" : "#32c5ff" }} title={node.userName}>{node.userName}：</span>
                            <pre className='chatMsg'>{node.message}</pre>
                        </div>
                        <div className="timestamp">{node.timestamp}</div>
                    </div>
                })
            }
        </div>
    }

    public scrollTop() {
        const ele = document.getElementById("chat_message_contain");
        if (ele) {
            if (!this.scrolling) {
                if (ele) {
                    ele.scrollTop = ele.scrollHeight;
                    ele.style.height = ele.scrollHeight + "px"
                }
            }
        }
    }
}