
import React from "react";
import { IParticipantItem } from "models/meet"
import i18n from "i18n";
import { Pagination } from 'antd';
import "./index.css";

const CONNECTION_QUALITY = [
    i18n.t('Low'),
    i18n.t('Medium'),
    i18n.t('Good')
];

interface IState {
    columns: any[];
    remoteColumns: any[];
    page: number;
    pageSize: number;
}

const defaultPageSize = 5
export default class extends React.Component<{
    participants: IParticipantItem[];
    onClose?: () => void;
}> {

    public state: IState = {
        columns: [
            {
                title: "参会人",
                key: "title",
            }, {
                title: "连接状态",
            }, {
                title: "比特率",
            }, {
                title: "丢包",
            }, {
                title: "分辨率",
            }, {
                title: "帧率",
            }, {
                title: "估计宽带",
            }, {
                title: "远程地址",
            }, {
                title: "本地地址",
            }, {
                title: "本地端口",
            }
        ],
        remoteColumns: [
            {
                title: "参会人",
                key: "title",
            }, {
                title: "连接状态",
            }, {
                title: "比特率",
            }, {
                title: "丢包",
            }, {
                title: "分辨率",
            }, {
                title: "帧率",
            }, {
                title: "端到端时延",
            }, {
                title: "连接到",
            }
        ],
        page: 1,
        pageSize: 10
    }

    public render() {
        const rawList = this.props.participants.filter(x => !x.local)
        const listData = [...rawList].slice((this.state.page - 1) * this.state.pageSize, (this.state.page - 1) * this.state.pageSize + defaultPageSize)

        return <div className={'connectionQuality-component'} >
            <div className={'main'} >
                <div className={'containerHead'}>
                    <span>{i18n.t('networkStatus')}</span>
                    {this.props.onClose && <img src={require('static/images/meet/icon-guanbi.png')} style={{ cursor: "pointer" }} alt=""
                        onClick={this.props.onClose} />}
                </div>
                <div className="container">
                    <div className="header">
                        {
                            this.state.columns.map((x, index) => {
                                return <div className={'item' + (index + 1)} key={index}>{x.title}</div>
                            })
                        }
                    </div>
                    <div className="body">
                        {
                            this.props.participants.map(node => {
                                if (node.local) {
                                    const { download, upload } = node.stats.bitrate ? node.stats.bitrate : { upload: "", download: "" };
                                    return <div className="item" key={node.participantId + ""}>
                                        <div className="item1" title={node.realName || node._displayName}>{node.realName || node._displayName}({i18n.t("meet.me")})</div>
                                        <div className="item2">{this._extractConnection(node.stats) || "N/A"}</div>
                                        <div className="item3">
                                            <span>&darr; </span>
                                            {download ? `${download} Kbps` : 'N/A'}
                                            &nbsp;<span>&uarr;</span>
                                            {upload ? `${upload} Kbps` : 'N/A'}</div>
                                        <div className="item4"> {this._renderPacketLoss(node.stats)}</div>
                                        <div className="item5">{this._renderResolution(node.stats)}</div>
                                        <div className="item6">{this._renderFrameRate(node.stats)}</div>
                                        <div className="item7">{this._renderBandwidth(node.stats)}</div>
                                        {this._renderTransport(node.stats)}
                                    </div>
                                }

                            })
                        }
                    </div>
                    {
                        listData.length > 0 && <div className="header">
                            {
                                this.state.remoteColumns.map((x, index) => {
                                    return <div className={'item' + (index + 1)} key={index}>{x.title}</div>
                                })
                            }
                        </div>
                    }
                    <div className="body">
                        {
                            listData.map(node => {
                                if (!node.local) {
                                    const { download, upload } = node.stats.bitrate ? node.stats.bitrate : { upload: "", download: "" };
                                    return <div className="item" key={node.participantId + ""}>
                                        <div className="item1" title={node.realName || node._displayName}>{node.realName || node._displayName}</div>
                                        <div className="item2">{this._extractConnection(node.stats) || "N/A"}</div>
                                        <div className="item3">
                                            <span>&darr;</span>
                                            {download ? `${download} Kbps` : 'N/A'}
                                            &nbsp;<span>&uarr;</span>
                                            {upload ? `${upload} Kbps` : 'N/A'}</div>
                                        <div className="item4"> {this._renderPacketLoss(node.stats)}</div>
                                        <div className="item5">{this._renderResolution(node.stats)}</div>
                                        <div className="item6">{this._renderFrameRate(node.stats)}</div>
                                        <div className="item7">{node.stats.e2eRtt ? `${node.stats.e2eRtt.toFixed(0)}ms` : 'N/A'}</div>
                                        <div className="item8">{node.stats.serverRegion}</div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                }

                            })
                        }
                    </div>
                </div>
                {rawList.length >= 5 && <div style={{ display: "flex", justifyContent: "space-between", margin: "0.15rem 0.57rem 0.58rem" }}>
                    <div></div>
                    <Pagination defaultCurrent={1} defaultPageSize={defaultPageSize} total={rawList.length} onChange={this.onChangePagination.bind(this)} />
                </div>}
            </div>
        </div>
    }

    public onChangePagination(page, pageSize) {
        this.setState({ page, pageSize })
    }

    // 连接状态
    _extractConnection(stats) {
        const { connectionQuality } = stats;

        if (connectionQuality) {
            const signalLevel = Math.floor(connectionQuality / 33.4);

            return CONNECTION_QUALITY[signalLevel];
        }
    }

    // 丢包
    public _renderPacketLoss(remoteStats) {
        const { packetLoss } = remoteStats;
        if (packetLoss) {
            const { download, upload } = packetLoss;
            return <div>
                <span className='connection-info__download'>
                    &darr;
            </span>
                {download === null ? 'N/A' : `${download}%`}
                &nbsp;<span className='connection-info__upload'>
                    &uarr;
            </span>
                {upload === null ? 'N/A' : `${upload}%`}
            </div>
        } else {
            return <div>N/A</div>
        }
    }

    // 分辨率
    _renderResolution(remoteStats) {
        const { resolution, maxEnabledResolution } = remoteStats;
        let resolutionString = Object.keys(resolution || {})
            .map(ssrc => {
                const { width, height } = resolution[ssrc];
                if (width && height) {
                    return `${width}x${height}`
                } else {
                    let res;
                    Object.keys(resolution[ssrc] || {}).map(ssrc2 => {
                        const { width, height } = resolution[ssrc][ssrc2];
                        res = (width && height) ? `${width}x${height}` : "N/A";
                    })
                    return res;
                }
            })
            .join(', ') || 'N/A';
        if (maxEnabledResolution && maxEnabledResolution < 720) {
            const maxEnabledResolutionTitle = "max";
            if (resolutionString !== 'N/A') {
                resolutionString += ` (${maxEnabledResolutionTitle} ${maxEnabledResolution}p)`;
            }
        }

        return (
            <div>{resolutionString}</div>
        );
    }

    // 帧率
    _renderFrameRate(remoteStats) {
        const { framerate } = remoteStats;
        const frameRateString = Object.keys(framerate || {})
            .map(ssrc => framerate[ssrc])
            // .map(ssrc1 => framerate[ssrc1])
            .join(', ') || 'N/A';

        return (
            <div>{frameRateString}</div>
        );
    }

    // 估计宽带
    _renderBandwidth(remoteStats) {
        const { download, upload } = remoteStats.bandwidth || {};
        return (
            <div>
                <span className='connection-info__download'>
                    &darr;
                    </span>
                {download ? `${download} Kbps` : 'N/A'}
                &nbsp;<span className='connection-info__upload'>
                    &uarr;
                    </span>
                {upload ? `${upload} Kbps` : 'N/A'}
            </div>
        );
    }

    // 
    _renderTransport(remoteStats) {
        const { transport } = remoteStats || {};

        if (!transport || transport.length === 0) {
            const NA = (
                <div>
                    N/A
                </div>
            );

            return [NA];
        }

        const data: any = {
            localIP: [],
            localPort: [],
            remoteIP: [],
            remotePort: [],
            transportType: []
        };

        for (let i = 0; i < transport.length; i++) {
            const ip = this.getIP(transport[i].ip);
            const localIP = this.getIP(transport[i].localip);
            const localPort = this.getPort(transport[i].localip);
            const port = this.getPort(transport[i].ip);

            if (!data.remoteIP.includes(ip)) {
                data.remoteIP.push(ip);
            }

            if (!data.localIP.includes(localIP)) {
                data.localIP.push(localIP);
            }

            if (!data.localPort.includes(localPort)) {
                data.localPort.push(localPort);
            }

            if (!data.remotePort.includes(port)) {
                data.remotePort.push(port);
            }

            if (!data.transportType.includes(transport[i].type)) {
                data.transportType.push(transport[i].type);
            }
        }

        // All of the transports should be either P2P or JVB
        let isP2P = false, isTURN = false;

        if (transport.length) {
            isP2P = transport[0].p2p;
            isTURN = transport[0].localCandidateType === 'relay'
                || transport[0].remoteCandidateType === 'relay';
        }

        const additionalData: any[] = [];

        if (isP2P) {
            additionalData.push(
                <span> (p2p)</span>);
        }
        if (isTURN) {
            additionalData.push(<span> (turn)</span>);
        }

        // First show remote statistics, then local, and then transport type.
        const tableRowConfigurations = [
            {
                additionalData,
                data: data.remoteIP,
                key: 'remoteaddress',
                label: "远端地址：",
                classV: "item8"
            },
            // {
            //     data: data.remotePort,
            //     key: 'remoteport',
            //     label: "远程端口：",
            // },
            {
                data: data.localIP,
                key: 'localaddress',
                label: "本地地址：",
                classV: "item9"
            },
            {
                data: data.localPort,
                key: 'localport',
                label: '本地端口：',
                classV: "item10"
            },
            // {
            //     data: data.transportType,
            //     key: "transportType",
            //     label: "传输："
            // }
        ];
        return tableRowConfigurations.map(this._renderTransportTableRow.bind(this));
    }

    _renderTransportTableRow(config: any) {
        const { additionalData, data, key, classV } = config;

        return <div className={classV} key={key}>{this.getStringFromArray(data)}
            {additionalData || null}</div>
    }

    getIP(value) {
        if (!value) {
            return '';
        }
        return value.substring(0, value.lastIndexOf(':'));
    }

    getPort(value) {
        if (!value) {
            return '';
        }
        return value.substring(value.lastIndexOf(':') + 1, value.length);
    }

    getStringFromArray(array) {
        let res = '';

        for (let i = 0; i < array.length; i++) {
            res += (i === 0 ? '' : ', ') + array[i];
        }

        return res;
    }
}