import { request } from "utils/request";
import moment from "moment";
import timezone from "moment-timezone";
import { API_URL, dateFormat } from "config";
import { message } from "antd";
import i18n from "i18n";
import axios from "axios";

/**
 * @name 创建会议
 */
export async function createMeet(data: any) {
    const response = (await request.post(
        `${API_URL}/v2/meeting`, {
        theme: data.theme && data.theme.trim(),
        password: data.password || undefined,
        content: data.content,
        meetServiceId: data.meetServiceId,
        reserveParticipants: data.reserveParticipants.map(n => ({
            type: n.type,
            userId: n.userId,
            name: n.name,
            guestAddressBookId: n.guestAddressBookId || null
        })),
        secret: data.secret,
    },
    )).data;
    return response;
}
/**
 * @name 预约会议
 */
export async function reserveMeet(data: any) {
    const response = (await request.post(
        `${API_URL}/v2/meeting/reservation`,
        {
            theme: data.theme ? data.theme.trim() : "",
            password: data.password || undefined,
            forecastStartTime: data.startTime ? timezone(data.startTime).tz(timezone.tz.guess()).format(dateFormat).valueOf() : moment().format(dateFormat),
            content: data.content,
            forecastDuration: data.forecastDuration,
            meetServiceId: data.meetServiceId,
            reserveParticipants: data.reserveParticipants.map(n => ({
                type: n.type,
                userId: n.userId,
                name: n.name,
                guestAddressBookId: n.guestAddressBookId || null
            })),
            hostUserId: data.hostUserId,
            reminder: data.remind,
            secret: data.secret,
        }
    )).data;
    return response;
}
// 加入会议
export async function joinMeet(id: string, pwd: string) {
    const response = (await request.get(
        `${API_URL}/meeting/join/${id}?password=${pwd || ""}`)).data;
    if (response.code !== 200) {
        if (response.code === 11015) {
            message.error(i18n.t("repeatJoining2"))
        } else if (response.code === 11018) {
            message.error(i18n.t("meetingLocked1"))
        } else {
            message.error(response.msg)
        }
    }
    return response;
}


/**
 * @name 获取当前会议
 */
export async function getCurrentMeetList(time: string) {
    const response = (await request.get(
        `${API_URL}/v2/meeting/day?date=${encodeURIComponent(moment(time).format(dateFormat))}`)).data;

    return response;
}

// 游客加入会议
export async function touristJoinMeet(name: string, meetId: string, pwd: string) {
    const response = (await request.get(
        `${API_URL}/meetingList/tourist/joinMeet?name=${name}&meetId=${Number(meetId)}&password=${pwd}`)).data;
    return response;
}

// 立即会议
export async function immediatelyJoinMeet(id: string, phone: string) {
    const response = (await request.get(
        `${API_URL}/meetingList/joinMeet?meetId=${Number(id)}&phone=${phone}`)).data;
    return response;
}

// 获取历史会议
export async function getHistoryMeetList(query) {
    const response = (await request.get(
        `${API_URL}/meeting/history`, {
        params: {
            pageNumber: query.pageIndex,
            pageSize: query.pageSize,
            search: query.keywords,
            startTime: query.startTime && timezone(query.startTime).tz(timezone.tz.guess()).format(dateFormat),
            endTime: query.endTime && timezone(query.endTime).tz(timezone.tz.guess()).format(dateFormat),
        }
    })).data;

    return response;
}

// 修改参会人
export async function editMeetUser(meetingId: string, userIds) {
    const response = (await request.post(
        `${API_URL}/meetingList/edit/${meetingId}/user`, userIds,
    )).data;
    return response;
}

// 将当前会议的主持人修改为自己
export async function editCurrentMeetModeratorToSelf(meetingId: string) {
    const response = (await request.put(
        `${API_URL}/meetingList/host/${meetingId}`
    )).data;
    return response;
}

/**
 * @name 获取会议详情
 * @param query 
 */
export async function getMeetDetail(meetId: string) {
    const response = (await request.get(
        `${API_URL}/meeting/info/${meetId}`)).data;

    return response;
}

/**
 * @name 获取当前用户可使用的增强服务
 * @param guestUserId 企业ID 
 */
export async function getIotService(guestUserId: string) {
    const response = (await request.get(
        `${API_URL}/meeting/iot`, { params: { guestUserId } })).data;
    return response;
}

/**
 * @name 获取会议详情
 */
export async function getMeetingDetail(meetingInfoId: string) {
    const response = (await request.get(
        `${API_URL}/v2/meeting/${meetingInfoId}`)).data;
    if (response.code !== 200) {
        if (response.code === 40001) {
            message.error(response.msg)
        } else {
            message.error(response.msg || i18n.t("message.requestFailed"))
        }
    }
    return response;
}

/**
 * @name 设置主持人开启音转文服务
 */
export async function setTransliterationStart(key: string) {
    const response = (await request.get(
        `${API_URL}/meeting/iot/transliteration/start/${key}`)).data;

    return response;
}

/**
 * @name 设置主持人关闭音转文服务
 */
export async function setTransliterationStop(key: string) {
    const response = (await request.get(
        `${API_URL}/meeting/iot/transliteration/stop/${key}`)).data;

    return response;
}

/**
 * @name 通过会议号和密码换取会议邀请信息（不需要token）
 */
export async function getInviteInfo(data) {
    const response = (await axios.post(
        `${API_URL}/meeting/invite/info`, data, {
        headers: {
            timezone: timezone.tz.guess()
        }
    }
    )).data;

    return response;
}

/**
 * @name 生成邀请连接url
 */
export async function createInviteLink(meetingInfoId: string) {
    const response = (await request.get(
        `${API_URL}/meeting/invite/link/${meetingInfoId}`)).data;

    return response;
}