import { IPictureInPictureItem } from "models/meet";

export const STORE_CURRENT_MEET_DATA = "STORE_CURRENT_MEET_DATA";
export const STORE_MUTED = "STORE_MUTED";
export const STORE_E2EE_SUPPORTED = "STORE_E2EE_SUPPORTED";
export const STORE_MY_INFO = "STORE_MY_INFO";
export const STORE_PICTURE_IN_PICTURE = "STORE_PICTURE_IN_PICTURE";
export const STORE_MEETING_RECORDING = "STORE_MEETING_RECORDING";

export function setCurrentMeetData(currentMeetData: any) {
    return { type: STORE_CURRENT_MEET_DATA, currentMeetData }
}


export function setMuted(muted: boolean) {
    return { type: STORE_MUTED, muted }
}

/**
 * @param e2eeSupported  是否支持e2ee
 */
export function setE2eeSupported(e2eeSupported: boolean) {
    return { type: STORE_E2EE_SUPPORTED, e2eeSupported }
}
/**
 * @name 会议中当前人的服务器数据
 */
export function setMyInfo(myInfo: any) {
    return { type: STORE_MY_INFO, myInfo }
}

/**
 * @name 会议中画中画数据
 */
export function setPictureInPictureData(pictureInPictureData: IPictureInPictureItem) {
    return { type: STORE_PICTURE_IN_PICTURE, pictureInPictureData }
}


/**
 * @name 会议中当前人的服务器数据
 */
export function setMeetingRecordingData(recordData) {
    return { type: STORE_MEETING_RECORDING, recordData }
}
