import * as bodyPix from "@tensorflow-models/body-pix";
import { message } from "antd";
import streamBlurEffect from './streamBlurEffect';
import streamBackgroundEffect from './streamBackgroundEffect';

const models = {
    model96: 'models/segm_lite_v681.tflite',
    model144: 'models/segm_full_v679.tflite'
};

let tflite;
let wasmCheck;
let isWasmDisabled = false;

const segmentationDimensions = {
    model96: {
        height: 96,
        width: 160
    },
    model144: {
        height: 144,
        width: 256
    }
};

/**
 * @name 参数说明
 * @param architecture  MobileNetV1或ResNet50,加载的BodyPix体系结构
 * @param outputStride  16支持对MobileNetV1架构, 8,16，32支持为RESNET架构;  值越大，模型越小，预测时间越快，但准确性越低。
 * @param multiplier  0.75 建议中端/低端GPU的计算机；0.50建议将带乘数的模型用于移动设备；具有更强大GPU的计算机，建议使用ResNet; 较小的值导致较小的模型和较快的预测时间，但准确性较低。
 * @param quantBytes  控制用于权重量化的字节 可选4、2、1
 */

/**
 * @name 视频背景模糊
 */

export async function createBlurEffect() {
    if (!MediaStreamTrack.prototype.getSettings && !MediaStreamTrack.prototype.getConstraints) {
        throw new Error('streamBlurEffect not supported!');
    }
    const bpModel = await bodyPix.load(
        {
            architecture: 'MobileNetV1',
            outputStride: 16,
            multiplier: 0.50,
            quantBytes: 2
        }
    );
    return new streamBlurEffect(bpModel);
}

/**
 * @name 替换视频背景
 */
export async function createReplaceBgEffect(imgPath, type?) {
    if (!MediaStreamTrack.prototype.getSettings && !MediaStreamTrack.prototype.getConstraints) {
        throw new Error('streamBlurEffect not supported!');
    }
    // const bpModel = await bodyPix.load(
    //     {
    //         architecture: 'MobileNetV1',
    //         outputStride: 16,
    //         multiplier: 0.75,
    //         quantBytes: 2
    //     }
    // );
    if (!tflite && !isWasmDisabled) {
        try {
            wasmCheck = require('wasm-check');
            const tfliteTimeout = 10000;
            if (wasmCheck?.feature?.simd) {
                // @ts-ignore
                tflite = await timeout(tfliteTimeout, createTFLiteSIMDModule());
            } else {
                // @ts-ignore
                tflite = await timeout(tfliteTimeout, createTFLiteModule());
            }
        } catch (err) {
            isWasmDisabled = true;
            if (err?.message === '408') {
                console.info('Failed to download tflite model!');
            } else {
                message.error('不支援 WebAssembly')
            }

            return;
        }
    }

    const modelBufferOffset = tflite._getModelBufferMemoryOffset();
    const modelResponse = await fetch(wasmCheck.feature.simd ? models.model144 : models.model96);
    if (!modelResponse.ok) {
        throw new Error('Failed to download tflite model!');
    }

    const model = await modelResponse.arrayBuffer();

    tflite.HEAPU8.set(new Uint8Array(model), modelBufferOffset);

    tflite._loadModel(model.byteLength);

    const options = {
        ...wasmCheck.feature.simd ? segmentationDimensions.model144 : segmentationDimensions.model96,
        virtualBackground: type === "blur" ?
            {
                backgroundType: "blur",
                blurValue: 25,
                selectedThumbnail: 'blur'
            } :
            {
                backgroundType: "image",
                virtualSource: imgPath,
                url: imgPath
            }
    };
    return new streamBackgroundEffect(tflite, options);
}



function timeout(milliseconds: number, promise): Promise<Object> {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            reject(new Error('408'));

            return;
        }, milliseconds);

        promise.then(resolve, reject);
    });
}
