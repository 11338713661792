
import api from "api";
import { message } from "antd";
import i18n from "i18n";
import moment from "moment";
import videoLayout from "./lib-jitsi-meet/videolayout";
import { whiteboardUrl, WHITEBOARD_LOCALSTORAGE } from "config"
import { logger } from "./log";

export default class Whiteboard {
    params: {
        annotation: boolean;// 是否批注
        activelyOpen: boolean
    } = { annotation: false, activelyOpen: false }
    meetingSocket
    db;
    waitingStorageData: any[] = []; // 等待存储的数据
    currentThis
    constructor(params?, meetingSocket?, currentThis?) {
        this.currentThis = currentThis
        if (params) {
            this.params = params
            this.meetingSocket = meetingSocket
            this.whiteboardReturnData = this.whiteboardReturnData.bind(this)
            if (this.params.activelyOpen) { // 主动开启白板
                (window as any).websocketWhiteboard && (window as any).websocketWhiteboard.send(JSON.stringify({
                    type: "openWhiteboard",
                    data: { annotation: this.params.annotation, cloudSave: false }
                }))
            }
            window.addEventListener('message', this.whiteboardReturnData);
            this.openIndexedDB((window as any).meetingData.roomName)
        }
    }

    /**
     * @name 白板返回的数据
    */
    async whiteboardReturnData(e) {
        if (e.origin === window.location.origin) {
            console.log("收到的白板数据", moment().format("YYYY/MM/DD HH:mm:ss"), e)
            // 发给服务端
            if (e.data) {
                const data = JSON.parse(e.data)
                if (data.type === "saveImg") { // 保存图片到本地
                    const a = document.createElement("a") as any;
                    a.href = data.data;
                    a.download = `${(window as any).meetingData.theme}-${i18n.t('whiteboard')}.png`;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                } else if (data.type === "serverSave") {// 保存图片到服务器
                    const res = await api.whiteboard.saveWhiteboardPng((window as any).meetingData.primaryKey, data.data)
                    if (res.code === 40001) {// 无权限
                        message.info(i18n.t("NoPermission"))
                    } else {
                        message.success(i18n.t("whiteboardSaveSuccess"))
                    }
                } else if (data.type === "openFile") {
                    const ele = document.createElement("input")
                    ele.type = "file"
                    ele.accept = ".jpeg,.jpg,.png";
                    ele.style.cssText = "position: fixed;z-index: -1;"
                    document.body.appendChild(ele)
                    ele.click()
                    ele.onchange = function (e) {
                        const input = e.target as any;
                        const files = input.files;
                        if (files && files[0]) {
                            const isJPG = files[0].type === 'image/jpeg' || files[0].type === 'image/png';
                            if (!isJPG) {
                                message.error(i18n.t("message.whiteboardImgFormat"));
                                return
                            }
                            if (window.FileReader) {
                                const reader = new FileReader();
                                reader.readAsDataURL(files[0])
                                reader.onloadend = function (e) {
                                    (window as any).iframeWhiteboard && (window as any).iframeWhiteboard.contentWindow.postMessage(JSON.stringify({
                                        type: "insertImg",
                                        data: e.target && e.target.result
                                    }), whiteboardUrl);
                                }
                            }
                        }
                        document.body.removeChild(ele)
                    }
                } else if (data.type === 'reply') {
                    if (data.data.count === data.data.current) {//历史数据已绘画完毕
                        (window as any).closeWhiteboardMaskFun && (window as any).closeWhiteboardMaskFun()
                    }
                    try {
                        this.currentThis && this.currentThis.setState({
                            whiteboardLoadingProgress: {
                                current: data.data.current,
                                count: data.data.count,
                                percent: data.data.current / data.data.count * 100
                            }
                        })
                    } catch (e) { }
                } else {
                    (window as any).websocketWhiteboard && (window as any).websocketWhiteboard.send(JSON.stringify({
                        type: "whiteboardMsg",
                        data: data,
                    }));
                    if (data.type !== "realTimeData") {// 跟手数据不用处理
                        // 需要注意this
                        this.setWhiteboardLocalStorage(data.data)
                    }
                }
            }
        }
    }
    /**
    * @name 获取本地白板数据
    */
    getWhiteboardLocalStorage() {
        return new Promise(resolve => {
            if (this.db) {
                const store = this.db.transaction(['data'], "readwrite").objectStore('data')
                const request = store.getAll()
                request.onsuccess = function () {
                    resolve(request.result || [])
                }
            }
        });
    }
    /**
    * @name 存储白板数据
    */
    setWhiteboardLocalStorage(data1) {
        if (this.waitingStorageData.length) { // 有数据在执行，先等待
            this.waitingStorageData.push(data1)
        } else {
            this.waitingStorageData.push(data1)
            this.saveInIndexDB()
        }
    }
    /**
     * @name 打开数据库
     */
    openIndexedDB(dbName) {
        if (!window.indexedDB) {
            console.log("Your browser doesn't support a stable version of IndexedDB. Such and such feature will not be available.");
        } else {
            // 打开or创建数据库
            const request = window.indexedDB.open(WHITEBOARD_LOCALSTORAGE + dbName);//whiteboardBlob
            // 数据库初始化事件
            request.onupgradeneeded = function (event: any) {
                const db = event.target.result;
                console.log("数据库初始化事件", db)
                // 创建数据库实例
                db.createObjectStore("data", { keyPath: 'id', autoIncrement: false });
            }
            request.onsuccess = (event: any) => {
                this.db = event.target.result;
            }
            request.onerror = function (event) {
                logger.error('报错了', event)
            }
        }
    }
    /**
     * @name 保存数据库
     */
    saveInIndexDB() {
        const data = this.waitingStorageData[0]
        //调用我们自己添加的方法来处理一个事务
        if (this.db) {
            const store = this.db.transaction(['data'], "readwrite").objectStore('data')
            const request1 = store.get(data.id)
            request1.onsuccess = () => {
                const res = request1.result
                if (res) {
                    res['serial'] = data.serial
                }
                const request = res ? store.put(res) : store.add(data)
                request.onsuccess = () => {
                    this.waitingStorageData.splice(0, 1)
                    if (this.waitingStorageData.length) {
                        this.saveInIndexDB()
                    }
                }
            }
        }
    }
    /**
     * @name 清除已结束会议的白板数据
     */
    clearWhiteboardLocalStorage(arr: string[]) {
        (window.indexedDB as any).databases().then((databases) => {
            for (const key in databases) {
                const element = databases[key].name;
                if (element.indexOf(WHITEBOARD_LOCALSTORAGE) > -1 && arr.indexOf(element.split('_')[1]) === -1) {
                    window.indexedDB.deleteDatabase(element)
                }
            }
        })
    }
    /**
       * @name 打开共享按钮面板
       */
    openSharedWhiteboard(data, confirmCloseWhiteboard: Function) {
        videoLayout.createWhiteboardButton(data, (num) => {
            if (num === 1) {// 最小化
                const ele = document.getElementById('conferenceOperationIframe')
                if (ele) {
                    ele.style.zIndex = "-1";
                    videoLayout.removeWhiteboardButton();
                    videoLayout.createReturnWhiteboardButton(() => {
                        videoLayout.removeReturnWhiteboardButton()
                        const ele = document.getElementById('conferenceOperationIframe')
                        if (ele) {
                            ele.style.zIndex = "100";
                        }
                        this.openSharedWhiteboard(data, confirmCloseWhiteboard)
                    })
                }

            } else if (num === 2) {// 点击关闭
                confirmCloseWhiteboard()
            }
        })
    }
    /**
       * @name 通知保存图片到服务器
       */
    postSaveWhiteboardImg(iframeWhiteboard, callback) {
        iframeWhiteboard && iframeWhiteboard.contentWindow.postMessage(JSON.stringify({
            type: "serverSave"
        }), whiteboardUrl);
        const timer = setTimeout(() => {
            callback()
            clearTimeout(timer)
        }, 200)
    }

    /**
     * @name 角色更新为主持人
     */
    postUserRoleChange(initData, iframeWhiteboard) {
        if (!initData) {
            const timer = setTimeout(() => {
                this.postUserRoleChange(initData, iframeWhiteboard)
                clearTimeout(timer)
            }, 1000)
            return
        }
        const obj = {
            type: "updateUserInfo",
            data: {
                isManage: initData.userType,
            }
        }
        if (initData.userType === 1) {
            obj.data.isManage = 0;// 0是主持人并且是开启人
        } else {
            obj.data.isManage = 2;// 2是主持人
        }
        if (iframeWhiteboard) {
            iframeWhiteboard.contentWindow.postMessage(JSON.stringify(obj), whiteboardUrl);
        } else {
            const timer = setTimeout(() => {
                iframeWhiteboard.contentWindow.postMessage(JSON.stringify(obj), whiteboardUrl);
                clearTimeout(timer)
            }, 2000)
        }
    }
    /**
    * @name 关闭白板
    */
    closeWhiteboard(bool?) {
        window.removeEventListener('message', this.whiteboardReturnData)
        const ele = document.getElementById('conferenceOperationIframe')
        if (ele) {
            document.body.removeChild(ele)
        }
        if (!bool) {
            try {
                this.meetingSocket && this.meetingSocket.send(JSON.stringify({
                    type: 'closeWhiteboard'
                }));
            } catch (e) { }
        }
        videoLayout.removeReturnWhiteboardButton()
        videoLayout.removeWhiteboardButton()
        this.db = null
    }
};