import { combineReducers } from "redux";
import { currentMeetData, videoMuted, e2eeSupported, myInfo, pictureInPictureData, meetingRecordingData } from "reduxs/reducers/meet";
import { noticeSocket } from "reduxs/reducers/message"
import { user } from "reduxs/reducers/user"

export const reducers = combineReducers({
    currentMeetData,
    videoMuted,
    noticeSocket,
    e2eeSupported,
    user,
    myInfo,
    pictureInPictureData,
    meetingRecordingData
})