

import { request } from "utils/request";
import { API_URL } from "config";

// /**
//  * @name 获取登录轮播图
//  * @param query 
//  */
// export async function getCarousel() {
//     const response = (await request.get(
//         `${API_URL}/introduction/carousel`)).data;

//     return response;
// }

/**
 * @name 满意度调查
 * @param query 
 */
export async function setSatisfaction(meetListId: string, satisfaction: boolean) {
    const response = (await request.put(
        `${API_URL}/meetingList/${meetListId}/${satisfaction}`)).data;

    return response;
}

/**
 * @name 报告问题
 * @param query 
 */
export async function setFeedback(data: {
    type: number;
    content: string;
    contactInformation: string;
    meetingListId: string;
    imageUrls: string[]
}) {
    const response = (await request.post(
        `${API_URL}/issue/feedback/${data.type}`, data)).data;

    return response;
}

/**
 * @name 获取地区代码
 * @param phoneAreaCode 手机前缀代码
 * @param code 地区英文简写代码
 */
export async function getAreaCode(phoneAreaCode: string, code: string) {
    const response = (await request.get(
        `${API_URL}/area/code`, {
        params: {
            phoneAreaCode,
            code
        }
    })).data;

    return response;
}

/**
 * @name 获取地区
 */
export async function getServerRegion() {
    const response: any = (await request.get('https://userzone.svmeet.com/region', { timeout: 2 * 1000 })).data;

    return response;
}


/**
 * @name 获取级联信息
 */
export async function getRegion() {
    const response: any = (await request.get(
        `${API_URL}/region`)).data;

    return response;
}


/**
 * @name 设置默认翻译语言
 */
export async function setDefaultTranslation(languageCode: string) {
    const response: any = (await request.put(
        `${API_URL}/user/default/translation/${languageCode}`)).data;

    return response;
}

/**
 * @name 获取默认翻译语言
 */
export async function getDefaultTranslation() {
    const response: any = (await request.get(
        `${API_URL}/user/default/translation`)).data;

    return response;
}

/**
 * @name 问题反馈
 * @param query 
 */
export async function sendProblemFeedback(data) {
    const response = (await request.post(
        `${API_URL}/feedback/front/page`, data)).data;

    return response;
}

/**
 * @name 远程投屏
 */
export async function remoteCastScreen(screenNumber) {
    const response: any = (await request.get(
        `${API_URL}/screen/remote/${screenNumber}`)).data;

    return response;
}

/**
 * @name 上传日志到服务器
 * @param query 
 */
export async function uploadLogsServer(userId, data) {
    const response = (await request.post(
        `https://maxframing.svmeet.com/weblog?user=${userId}`, JSON.stringify(data), {
        headers: {
            "Content-type": "application/json; charset=utf-8"
        }
    })).data;

    return response;
}

/**
 * @name 获取验证图片
 */
export async function getVerificationImg() {
    const response: any = (await request.get(
        `${API_URL}/verification/img`)).data;

    return response;
}

/**
 * @name 获取版本号
 */
export async function getVersion(platform) {
    let lang = "";
    navigator.languages.forEach((node: string) => {
        if (lang) return
        if (node.split('-')[0] === 'zh') {
            lang = "zh"
        } else if (node.split('-')[0] === 'en') {
            lang = "en"
        }
    })
    const response: any = (await request.get(
        `${API_URL}/version/${platform}/${lang || "zh"}`)).data;

    return response;
}

/**
 * @name 上传图片
 * @param query 
 */
export async function uploadFile(file) {
    let type = file.type.split("/")[1]
    type = type === "jpeg" ? "jpg" : type
    try {
        const formData = new FormData()
        formData.append("file", file)
        const response = (await request.post(
            `${API_URL}/upload/${type}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })).data;

        return response;
    } catch (e) {
        console.warn(e)
    }
}
