import React from "react";
import i18n from "i18n";
import { Input, message } from "antd";
import styles from "./index.module.css";
import api from "api";

interface IForm {
    name?: string;
    companyName?: string;
    email?: string,
    phone?: string,
    problem?: string
}

interface IState {
    showMobileQrcode: boolean;
    form: IForm;
}

let bool = true;

class Page404 extends React.Component {

    public state: IState = {
        showMobileQrcode: false,
        form: {
            name: "",
            companyName: "",
            email: "",
            phone: "",
            problem: "",
        }
    }
    public render() {
        const { form } = this.state;
        return <div className={styles.downloadPage}>
            <div className={styles.nav}>
                <img src={require("static/images/logo2.png")} alt="" />
                <div onClick={() => window.history.back()}>{i18n.t("return")}</div>
            </div>
            <div className={styles.formBox}>
                <div className={styles.formItem}>
                    <div className={styles.label}><span>*</span>{i18n.t('yourName')}</div>
                    <div className={styles.inputWrapper}>
                        <Input
                            className="input"
                            value={form.name}
                            maxLength={20}
                            onChange={evt => this.setForm({ name: evt.target.value.trim() })}
                            onKeyDown={(e) => {
                                const evt = window.event || e;
                                if ((evt as any).keyCode == 13) {
                                    //回车事件
                                    this.submit()
                                }
                            }}
                        />
                    </div>
                </div>
                <div className={styles.formItem}>
                    <div className={styles.label}>{i18n.t('companyName')}</div>
                    <div className={styles.inputWrapper}>
                        <Input
                            className="input"
                            value={form.companyName}
                            maxLength={32}
                            onChange={evt => this.setForm({ companyName: evt.target.value.trim() })}
                        />
                    </div>
                </div>
                <div className={styles.formItem}>
                    <div className={styles.label}><span>*</span>{i18n.t('contactNumber')}</div>
                    <div className={styles.inputWrapper}>
                        <Input
                            className="input"
                            value={form.phone}
                            maxLength={30}
                            onChange={evt => this.setForm({ phone: evt.target.value })}
                            onKeyDown={(e) => {
                                const evt = window.event || e;
                                if ((evt as any).keyCode == 13) {
                                    //回车事件
                                    this.submit()
                                }
                            }}
                        />
                    </div>
                </div>
                <div className={styles.formItem}>
                    <div className={styles.label}>{i18n.t('yourEmail')}</div>
                    <div className={styles.inputWrapper}>
                        <Input
                            className="input"
                            value={form.email}
                            maxLength={100}
                            onChange={evt => this.setForm({ email: evt.target.value })}
                        />
                    </div>
                </div>
                <div className={styles.formItem}>
                    <div className={styles.label}><span>*</span>{i18n.t('problemDescription')}</div>
                    <div className={styles.inputWrapper}>
                        <textarea className={styles.textarea} name="" id="" maxLength={400}
                            onChange={(evt) => this.setForm({ problem: evt.target.value.trim() })} ></textarea>
                    </div>
                </div>
                <div className={styles.formItem}>
                    <div className={styles.label}></div>
                    <div className={styles.confirmBtn + (form.phone && form.name && form.problem ? "" : ' ' + styles.confirmBtnDisabled)}
                        onKeyDown={(e) => {
                            const evt = window.event || e;
                            if ((evt as any).keyCode == 13) {
                                //回车事件
                                this.submit()
                            }
                        }}
                        onClick={this.submit.bind(this)}>{i18n.t('meet.submit')}</div>
                </div>
            </div>
        </div>
    }

    public async submit() {
        if (!bool) {
            return
        }
        const { form } = this.state;
        if (!form.phone || !form.problem || !form.name) {
            message.warn(i18n.t("message.enterFequiredItem"))
            return
        }
        if (form.email && !/^[a-zA-Z0-9_\\.-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(form.email)) {
            message.warn(i18n.t('message.pleaseEnterYourVaildEmail'))
            return
        }
        bool = false;
        const res = await api.common.sendProblemFeedback(form)
        if (res.code === 200) {
            message.success(i18n.t("thanksFeedback2"))
            const timer = setTimeout(() => {
                clearTimeout(timer)
                window.history.back()
            }, 3000)
        } else {
            bool = true;
            message.error(i18n.t("message.requestFailed"))
        }
    }

    public setForm(form: IForm) {
        this.setState({
            form: {
                ...this.state.form,
                ...form
            }
        })
    }
}

export default Page404;