
import { STORAGE_ACCESS_TOKEN, vCode } from "config"
import { IMeetingItem } from "models/meet";
import { getPlatformValue } from "utils/common";

export default {
    meetingSocket: null as any,
    pingTimerMeeting: null as any,
    /**
     * @name 会议控制/白板-连接服务器
     */
    meetingServerWebSocket(meetData: IMeetingItem, participantId: string, onopen: Function, success: Function) {
        const svDeviceInfo = `${getPlatformValue()} web ${vCode}`
        const url = `${window.location.protocol === "http:" ? "ws" : "wss"}://${window.location.host}/api/chat/room/meeting/websocket?token=${window.localStorage.getItem(STORAGE_ACCESS_TOKEN)}&meetingId=${meetData.primaryKey}&participantId=${participantId}&svDeviceInfo=${svDeviceInfo}`
        //@ts-ignore
        this.meetingSocket = new ReconnectingWebSocket(url, null, { debug: false, reconnectInterval: 3000, maxRetries: 10 });
        this.meetingSocket.onopen = () => {
            (window as any).websocketWhiteboard = this.meetingSocket
            onopen(this.meetingSocket);
            console.log("会议控制已连接");
            this.pingTimerMeeting = setInterval(() => {
                const token = localStorage.getItem(STORAGE_ACCESS_TOKEN) as any
                if (token && token.length) {
                    try {
                        this.meetingSocket.send(JSON.stringify({
                            type: "ping"
                        }))
                    } catch { }
                }
                if (!token) {
                    this.pingTimerMeeting && clearInterval(this.pingTimerMeeting)
                    this.meetingSocket.close()
                }
            }, 5000)
        }
        this.meetingSocket.addEventListener('message', (receive) => {
            let msg = JSON.parse(receive.data)
            if (msg.type === 'ping') return
            success(msg)
        })
        this.meetingSocket.onerror = (e) => {
            console.log(e, '连接建立失败');
            this.pingTimerMeeting && clearInterval(this.pingTimerMeeting);
            (window as any).websocketWhiteboard = null;
            onopen(null)
        }

        this.meetingSocket.onclose = (e) => {
            console.log(e);
            this.pingTimerMeeting && clearInterval(this.pingTimerMeeting);
            (window as any).websocketWhiteboard = null;
        }
    },
    close() {
        this.meetingSocket && this.meetingSocket.close()
        this.meetingSocket = null;
        (window as any).websocketWhiteboard = null;
        this.pingTimerMeeting && clearInterval(this.pingTimerMeeting)
    }

}
