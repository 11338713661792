
import React from "react";
import i18n from "i18n"
import api from "api";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { Modal, Input, message, Spin, Select } from "antd";
// import { sendMsg, UDPClose } from "utils/UDPUtils";
// import { sendTCP, closeTCP, } from "utils/TCPUtils"
import jitsi from "utils/lib-jitsi-meet/api";
import { desktopApplyConstraints } from "utils/lib-jitsi-meet/config"
import { IUser } from "models/user";
import "./index.less"

interface DispatchProps {
    user: IUser;
    noticeSocket: any;
}

interface IDevice {
    id: string;
    name: string;
    ip: string;
    port: string;
}

interface IState {
    devices: IDevice[];
    isLoading: boolean;
    isSearch: boolean;
    password: string;
    sharing: boolean;
    waitingConsent: boolean;
    hover: boolean;
    projectionCode: string;
    deviceName: string;
    currentShareMode: "content" | "video" | "whiteboard" | "";// 共享屏幕的模式
    currentDesktopMode: 0 | 1 | 2;// 共享设置模式
}
class Index extends React.Component<{
    close: () => void
} & DispatchProps, IState> {

    constructor(props) {
        super(props)
        this.websocketNotice = this.websocketNotice.bind(this)
    }

    public state: IState = {
        devices: [],
        isLoading: true,
        isSearch: false,
        password: "",
        sharing: false,
        waitingConsent: false,
        hover: false,
        projectionCode: "",
        deviceName: "",
        currentShareMode: "content",
        currentDesktopMode: 0
    }

    public componentDidMount() {
        let time = 0;
        const timer = setInterval(() => {
            time += 1;
            if (time === 10) {
                clearInterval(timer)
                time = 0;
                this.setState({ isLoading: false })
            }
        }, 1000)
        // sendMsg(this.props.user.name || "", (msg: any, remoteInfo) => {
        //     if (this.state.isLoading && msg.type === "SVMSERVER") {
        //         if (this.state.devices.findIndex(x => x.id === msg.data.id) === -1) {
        //             this.state.devices.push({
        //                 id: msg.data.id,
        //                 name: msg.data.name,
        //                 port: msg.data.port,
        //                 ip: remoteInfo.address
        //             })
        //             this.setState({ devices: this.state.devices })
        //         }
        //     }
        // })

        this.props.noticeSocket && this.props.noticeSocket.addEventListener('message', this.websocketNotice)
    }

    public render() {
        const { sharing } = this.state;
        return <Modal style={{ display: sharing ? "none" : "block" }}
            className="cast-screen-component"
            width={388}
            maskStyle={{ background: "rgba(54, 66, 96, 0.2)" }}
            maskClosable={false}
            visible={true} footer={null}
            title={<div className="title-box">
                {i18n.t('selectDevices')}
            </div>} onCancel={this.props.close}>
            {/* <div className="search-btn">
                <img src={require("public/image/address/icon-search.png")} alt="" />
                <input type="text" className="input"
                    placeholder={i18n.t("searchDevice")}
                    onChange={this.search.bind(this)}
                />
            </div> */}

            {/* <div className="title">{i18n.t("recentlyUsed")}</div> */}
            {/* <div className="title">
                <span>{i18n.t("deviceList")}</span>
                <Tooltip title={i18n.t("EquipmentRefresh")}>
                    <img src={(this.state.isLoading || this.state.hover) ? require("static/images/home/icon-refresh1.png") : require("static/images/home/icon-refresh.png")} alt=""
                        className={this.state.isLoading ? "img1" : "img2"}
                        onClick={() => {
                            if (!this.state.isLoading)
                                this.setState({ isLoading: true, devices: [] }, () => {
                                    sendMsg(this.props.user.name || "")
                                    let time = 0;
                                    const timer = setInterval(() => {
                                        time += 1;
                                        if (time === 10) {
                                            clearInterval(timer)
                                            time = 0;
                                            this.setState({ isLoading: false })
                                        }
                                    }, 1000)
                                })
                        }}
                        onMouseLeave={() => this.setState({ hover: false })}
                        onMouseEnter={() => this.setState({ hover: true })}
                    /> </Tooltip>
            </div> */}
            {/* <div className="list">
                {
                    !this.state.isSearch && (this.state.devices.length ? this.state.devices.map((x, index) => {
                        return <div key={x.id + index} className="item" onClick={this.send.bind(this, x)} title={x.name + " " + x.id}>{x.name}  {x.id}</div>
                    }) : <div style={{ textAlign: "center" }}>{i18n.t('noDeviceIsCurrentlyFound')}</div>)
                }
            </div> */}

            <div className="projection">
                <Input
                    className="input"
                    placeholder={i18n.t('enterProjectionCode')}
                    value={this.state.projectionCode}
                    maxLength={30}
                    onChange={evt => this.setState({ projectionCode: evt.target.value.trim() })}
                    onKeyDown={(e) => {
                        const evt = window.event || e;
                        if ((evt as any).keyCode === 13) {
                            //回车事件
                            this.remoteScreen()
                        }
                    }}
                />
                <div className="btn" onClick={this.remoteScreen.bind(this)}>{i18n.t('startScreencast')}</div>
            </div>
            <div className="title" style={{ marginTop: 23, marginBottom: 12, lineHeight: "17px" }}>{i18n.t('castScreenSettings')}</div>
            <div className="setting">
                <Select value={this.state.currentShareMode} onChange={(value) => {
                    this.setState({ currentShareMode: value as any })
                }}>
                    <Select.Option value="content">{i18n.t('shareScreenContent')}</Select.Option>
                    <Select.Option value="video">{i18n.t('shareVideo')}</Select.Option>
                </Select>
            </div>
        </Modal>

    }
    // 远程投屏反馈
    public websocketNotice(receive) {
        let msg = JSON.parse(receive.data)
        if (msg.type === "deviceRemoteScreenGiveBack") { // 远程投屏回馈	
            this.isBack = true
            message.destroy("remoteCastScreen")
            this.timer && clearTimeout(this.timer)
            const data = msg.parameter;
            if (data.deviceNumber !== this.state.projectionCode) {// 避免重复请求
                return
            }
            if (data.code === 201) {// 设备不在会议中
                const res = Modal.confirm({
                    title: null,
                    icon: null,
                    className: "cast-modal",
                    content: <div className="cast-modal-content" >
                        <div>{i18n.t("deviceNotMeeting")}</div>
                        <div className="btns">
                            <div className="btn1" onClick={() => res.destroy()}>{i18n.t("shutDown")}</div>
                        </div>
                    </div>,
                });
                this.castModal && this.castModal.destroy()
                this.setState({ waitingConsent: false })
            } else if (data.code === 202) {// 此时在会议中，允许共享
                if (!data.meetingPassword) { // 没有密码时，需要输入密码
                    const res = Modal.confirm({
                        title: null,
                        icon: null,
                        className: "cast-modal",
                        content: <div className="cast-modal-content" >
                            <div>   <Input
                                style={{ marginRight: "0.1rem" }}
                                className="input"
                                placeholder={i18n.t('meetPassword')}
                                maxLength={30}
                                onChange={evt => this.setState({ password: evt.target.value.trim() })}
                            /></div>
                            <div className="btns">
                                <div className="btn1" onClick={() => {
                                    this.desktop(data.meetingNumber, this.state.password)
                                    res.destroy()
                                }}>{i18n.t("meet.submit")}</div>
                            </div>
                        </div>,
                    });
                } else {
                    this.desktop(data.meetingNumber, data.meetingPassword)
                }
            } else if (data.code === 203) {// 主持人拒绝
                const res = Modal.confirm({
                    title: null,
                    icon: null,
                    className: "cast-modal",
                    content: <div className="cast-modal-content" >
                        <div>{i18n.t("NoScreencastPermission")}</div>
                        <div className="btns">
                            <div className="btn1" onClick={() => res.destroy()}>{i18n.t("shutDown")}</div>
                        </div>
                    </div>,
                });
            } else if (data.code === 204 || data.code === 207) {// 设备忙碌中
                const res = Modal.confirm({
                    title: null,
                    icon: null,
                    className: "cast-modal",
                    content: <div className="cast-modal-content" >
                        <div>{i18n.t("deviceBusy")}</div>
                        <div className="btns">
                            <div className="btn1" onClick={() => res.destroy()}>{i18n.t("againLater")}</div>
                            <div className="btn2" onClick={() => {
                                this.setState({ waitingConsent: true }, () => {
                                    this.remoteScreen()
                                    res.destroy()
                                })
                            }}>{i18n.t("Retry")}</div>
                        </div>
                    </div>,
                });
            }
        }
    }

    // 发送外网投屏
    public async remoteScreen() {
        if (!this.state.projectionCode) {
            return
        }
        this.isBack = false
        const res = await api.common.remoteCastScreen(this.state.projectionCode)
        if (res.code === 200) {
            message.loading({ content: "loading", key: "remoteCastScreen", duration: 0 })
            if (res.data) {
                this.timer = setTimeout(() => {
                    clearTimeout(this.timer)
                    // 设备无人处理
                    if (!this.isBack) {
                        message.destroy("remoteCastScreen")
                        const res = Modal.confirm({
                            title: null,
                            icon: null,
                            className: "cast-modal",
                            content: <div className="cast-modal-content" >
                                <div>{i18n.t("deviceBusy")}</div>
                                <div className="btns">
                                    <div className="btn1" onClick={() => res.destroy()}>{i18n.t("againLater")}</div>
                                    <div className="btn2" onClick={() => {
                                        this.setState({ waitingConsent: true }, () => {
                                            this.remoteScreen()
                                            res.destroy()
                                        })
                                    }}>{i18n.t("Retry")}</div>
                                </div>
                            </div>,
                        });
                    }
                }, 30000)
            }
        } else {
            message.destroy("remoteCastScreen")
            if (res.code === 14100) { // 设备不存在
                message.error(i18n.t('deviceNotExist'))
            } else if (res.code === 14106) {// 设备不在线
                message.error(i18n.t('deviceNotOnline'))
            } else {
                message.error(i18n.t("message.requestFailed"))
            }
        }
    }
    // 发送投屏
    // public submit(data1: IDevice) {
    //     sendTCP(data1.ip, data1.port, {
    //         id: data1.id,
    //         name: this.props.user.name
    //     }, async (msg) => {// 接收到返回
    //         // 把确认弹框关掉
    //         if (!this.state.waitingConsent) {
    //             return
    //         }
    //         this.castModal && this.castModal.destroy()
    //         this.setState({ waitingConsent: false })

    //         const data = msg && JSON.parse(msg)
    //         if (data.code === 201 && data.type === "SVMSERVER") {// 设备不在会议中
    //             message.info(i18n.t("deviceNotMeeting"))
    //             this.castModal && this.castModal.destroy()
    //             this.setState({ waitingConsent: false })
    //         }

    //         if (data.code === 202 && data.type === "SVMSERVER") {// 此时在会议中，允许共享
    //             if (!data.data.meetPWD) { // 没有密码时，需要输入密码
    //                 const res = Modal.confirm({
    //                     title: null,
    //                     icon: null,
    //                     className: "cast-modal",
    //                     content: <div className="cast-modal-content" >
    //                         <div>   <Input
    //                             style={{ marginRight: "0.1rem" }}
    //                             className="input"
    //                             placeholder={i18n.t('meetPassword')}
    //                             maxLength={30}
    //                             onChange={evt => this.setState({ password: evt.target.value.trim() })}
    //                         /></div>
    //                         <div className="btns">
    //                             <div className="btn1" onClick={() => {
    //                                 this.desktop(data.data.meetID, this.state.password, data1.name)
    //                                 res.destroy()
    //                             }}>{i18n.t("meet.submit")}</div>
    //                         </div>
    //                     </div>,
    //                 });
    //             } else {
    //                 this.desktop(data.data.meetID, data.data.meetPWD, data1.name)
    //             }
    //         }
    //         if (data.code === 203 && data.type === "SVMSERVER") {// 主持人拒绝
    //             const res = Modal.confirm({
    //                 title: null,
    //                 icon: null,
    //                 className: "cast-modal",
    //                 content: <div className="cast-modal-content" >
    //                     <div>{i18n.t("NoScreencastPermission")}</div>
    //                     <div className="btns">
    //                         <div className="btn1" onClick={() => res.destroy()}>{i18n.t("shutDown")}</div>
    //                     </div>
    //                 </div>,
    //             });
    //         }

    //         if (data.code === 204 && data.type === "SVMSERVER") {// 设备忙碌中
    //             const res = Modal.confirm({
    //                 title: null,
    //                 icon: null,
    //                 className: "cast-modal",
    //                 content: <div className="cast-modal-content" >
    //                     <div>{i18n.t("deviceBusy")}</div>
    //                     <div className="btns">
    //                         <div className="btn1" onClick={() => res.destroy()}>{i18n.t("againLater")}</div>
    //                         <div className="btn2" onClick={() => {
    //                             this.setState({ waitingConsent: true }, () => {
    //                                 this.submit(data)
    //                                 res.destroy()
    //                             })
    //                         }}>{i18n.t("Retry")}</div>
    //                     </div>
    //                 </div>,
    //             });
    //         }

    //         if (data.code === 206 && data.type === "SVMSERVER") {// 请求客户端断开投屏
    //             this.exit()
    //             this.props.close()
    //         }

    //     })
    // }
    // 共享屏幕
    public async desktop(meetNumber: string, pwd: string, username?: string) {
        const res = await api.meeting.joinMeet(meetNumber, pwd)
        if (res.code === 200) {
            if (res.data.roomName) {
                // 入会等待
                const content = Modal.confirm({
                    title: null,
                    icon: null,
                    className: "cast-modal",
                    content: <div className="cast-modal-content" style={{ height: "0.7rem", display: "flex", justifyContent: "center", flexDirection: "column", marginTop: "-8px" }}>
                        <Spin />
                        <div>{i18n.t("justMoment")}</div>
                    </div>,
                });
                this.jitsiObj = new jitsi();
                this.jitsiObj.init("castScreen", null, {
                    roomName: res.data.roomName,
                    userName: i18n.t("meet.sharingOfName", { name: this.props.user.name }),
                    isDesktop: true,
                    userKey: this.props.user.primaryKey
                }, () => {// 入会成功
                    content.destroy();
                    this.jitsiObj.desktopConferenceJoined(
                        (tracks, fps) => {// 成功入会
                            console.log("成功", tracks, fps)
                            this.setState({ sharing: true, currentDesktopMode: fps }, () => {
                                this.createCastButtonElement()
                            })
                            tracks.forEach(track => {
                                if (track.getType() === "video") {
                                    this.videoTrack = track
                                }
                            });
                        }, (params) => {// 取消共享
                            this.jitsiObj && this.jitsiObj.disconnect()
                            if (params === "close") {
                                this.props.close()
                                const ele = document.getElementById('cast-screen-handle')
                                if (ele) {
                                    document.body.removeChild(ele)
                                }
                                const modal = document.getElementById("cast-screen-fps-modal")
                                if (modal) {
                                    document.body.removeChild(modal)
                                }
                            }
                        }, this.state.currentShareMode)
                }, () => {//会议结束
                    let num = 3;
                    const modal = Modal.confirm({
                        title: null,
                        icon: null,
                        className: "cast-modal",
                        content: <div className="cast-modal-content" >
                            <div>{i18n.t("screencastEnded")}</div>
                            <div className="btns">
                                <div className="btn1" onClick={() => modal.destroy()}>{i18n.t("shutDown")}（{num}s）</div>
                            </div>
                        </div>,
                    });

                    const timer = setInterval(() => {
                        num -= 1;
                        modal.update({
                            content: <div className="cast-modal-content" >
                                <div>{i18n.t("screencastEnded")}</div>
                                <div className="btns">
                                    <div className="btn1" onClick={() => modal.destroy()}>{i18n.t("shutDown")}（{num}s）</div>
                                </div>
                            </div>,
                        })
                        if (num === 0) {
                            clearInterval(timer)
                            modal.destroy()
                        }
                    }, 1000)

                    this.setState({ sharing: false })
                    this.exit()
                    this.props.close()
                }, this.state.currentShareMode)
            }
        }
    }

    public send(data: IDevice) {// 确定投屏框
        // this.setState({ deviceName: data.name })
        // const content = <div className="cast-modal-content" >
        //     <div dangerouslySetInnerHTML={{ __html: i18n.t("castScreenIng", { name: data.name }) }}></div>
        //     <div className="btns">
        //         <div className="btn1" onClick={() => this.castModal.destroy()}>{i18n.t("cancel")}</div>
        //         <div className="btn2" onClick={() => {
        //             this.submit(data)
        //             this.setState({ waitingConsent: true }, () => {
        //                 this.castModal && this.castModal.update({
        //                     content: <div className="cast-modal-content" >
        //                         <div>{i18n.t("WaitingApproval")}</div>
        //                         <div className="btns">
        //                             <div className="btn1" onClick={() => {
        //                                 this.setState({ waitingConsent: false })
        //                                 this.castModal.destroy()
        //                             }}>{i18n.t("cancel")}</div>
        //                         </div>
        //                     </div>
        //                 })
        //             })
        //         }}>{i18n.t("login.confirm")}</div>
        //     </div>
        // </div>
        // this.castModal = Modal.confirm({
        //     title: null,
        //     icon: null,
        //     className: "cast-modal",
        //     content
        // });
    }

    // public search(e) { // 设备搜索
    //     const value = e.currentTarget.value.trim();
    //     if (value) {
    //         this.state.devices.forEach(node => {
    //             const index = this.state.serachList.findIndex(x => x.id === node.id)
    //             if (node.name.indexOf(value) > -1 || node.id.indexOf(value) > -1) {
    //                 if (index === -1) {
    //                     this.state.serachList.push(node)
    //                 }
    //             } else {
    //                 if (index > -1) {
    //                     this.state.serachList.splice(index, 1)
    //                 }
    //             }
    //         })
    //         this.setState({ serachList: this.state.serachList, isSearch: true })
    //     } else {
    //         this.setState({ serachList: [], isSearch: false })
    //     }
    // }

    // 添加投屏按钮
    public createCastButtonElement() {
        const that = this;
        const div = document.createElement('div')
        div.className = "cast-screen-handle";
        div.id = 'cast-screen-handle';
        div.innerHTML = `
            <span class="name">设备</span>
            <span class="text">${i18n.t("casting")}</span>
            <img id="toupSet" src="${require("static/images/home/toup-set.png")}"/>
       `
        // 退出投屏
        const btn = document.createElement('span')
        btn.className = "btn";
        btn.innerText = i18n.t("exitScreencast")
        btn.onclick = function () {
            that.exit()
            that.props.close()
            const modal = document.getElementById("cast-screen-fps-modal")
            if (modal) {
                document.body.removeChild(modal)
            }
        }
        div.appendChild(btn)
        document.body.appendChild(div)
        const toupSet = document.getElementById('toupSet') as any;
        let show = false
        toupSet.onmouseenter = function () {
            toupSet.src = require("static/images/home/toup-set-a.png");
            const docu = document.getElementById("cast-screen-fps-modal")
            if (docu) return
            const modal = document.createElement("div")
            modal.id = "cast-screen-fps-modal"
            modal.className = "cast-screen-fps-modal"
            modal.innerHTML = `<div>
                    <div id="shareScreenMode1" class="shareScreenMode">${i18n.t('shareScreenMode1')}</div>
                    <div id="shareScreenMode2" class="shareScreenMode">${i18n.t('shareScreenMode2')}</div>
                    <div id="shareScreenMode3" class="shareScreenMode">${i18n.t('shareScreenMode3')}</div>
                </div>`;
            document.body.appendChild(modal)
            modal.onmouseenter = function () {
                show = true
            }
            modal.onmouseleave = function () {
                document.body.removeChild(modal)
                show = false
            }

            const timer = setTimeout(() => {
                const shareScreenMode1 = document.getElementById("shareScreenMode1")
                if (shareScreenMode1) {
                    shareScreenMode1.className = that.state.currentDesktopMode === 0 ? "shareScreenModeActive" : "shareScreenMode"
                }
                const shareScreenMode2 = document.getElementById("shareScreenMode2")
                if (shareScreenMode2) {
                    shareScreenMode2.className = that.state.currentDesktopMode === 1 ? "shareScreenModeActive" : "shareScreenMode"
                }
                const shareScreenMode3 = document.getElementById("shareScreenMode3")
                if (shareScreenMode3) {
                    shareScreenMode3.className = that.state.currentDesktopMode === 2 ? "shareScreenModeActive" : "shareScreenMode"
                }
                if (shareScreenMode1 && shareScreenMode2 && shareScreenMode3) {
                    shareScreenMode1.onclick = function () {
                        shareScreenMode1.className = "shareScreenModeActive";
                        shareScreenMode2.className = "shareScreenMode";
                        shareScreenMode3.className = "shareScreenMode";
                        desktopApplyConstraints(0, that.videoTrack)
                        that.setState({ currentDesktopMode: 0 })
                    }
                    shareScreenMode2.onclick = function () {
                        shareScreenMode1.className = "shareScreenMode";
                        shareScreenMode2.className = "shareScreenModeActive";
                        shareScreenMode3.className = "shareScreenMode";
                        desktopApplyConstraints(1, that.videoTrack)
                        that.setState({ currentDesktopMode: 1 })
                    }
                    shareScreenMode3.onclick = function () {
                        shareScreenMode1.className = "shareScreenMode";
                        shareScreenMode2.className = "shareScreenMode";
                        shareScreenMode3.className = "shareScreenModeActive";
                        desktopApplyConstraints(2, that.videoTrack)
                        that.setState({ currentDesktopMode: 2 })
                    }
                }

                clearTimeout(timer)
            }, 200)
        }

        toupSet.onmouseleave = function () {
            toupSet.src = require("static/images/home/toup-set.png");
            const timer = setTimeout(() => {
                if (!show) {
                    const modal = document.getElementById("cast-screen-fps-modal")
                    if (modal) {
                        document.body.removeChild(modal)
                    }
                }
                clearTimeout(timer)
            }, 500);
        }
    }

    public componentWillUnmount() {
        this.exit()
        // UDPClose()
        this.props.noticeSocket && this.props.noticeSocket.removeEventListener('message', this.websocketNotice)
    }

    // 退出
    public exit() {
        if (this.state.sharing) {
            this.jitsiObj && this.jitsiObj.disconnect();
        }
        // closeTCP()
        const ele = document.getElementById('cast-screen-handle')
        if (ele) {
            document.body.removeChild(ele)
        }
    }

    private jitsiObj;
    private castModal;
    private isBack = false;
    private timer;
    private videoTrack;
}


export default withRouter(connect(
    (state: any) => ({
        noticeSocket: state.noticeSocket,
        user: state.user
    })
)(Index))