import { STORAGE_ACCESS_TOKEN } from "config";

//访问用户媒体设备的兼容方法
export function getUserMedia(type, deviceId, success) {
    let constraints = {}
    if (type === "video") {
        constraints = {
            video: {
                deviceId
            }, audio: false
        }
    } else {
        constraints = {
            video: false, audio: true
        }
    }
    if (navigator.mediaDevices.getUserMedia) {
        //最新的标准API
        navigator.mediaDevices.getUserMedia(constraints).then(success).catch((error) => {
            console.error(`访问用户媒体设备失败${error.name}, ${error.message}`);
            return
        });
    } else if ((navigator as any).webkitGetUserMedia) {
        //webkit核心浏览器
        (navigator as any).webkitGetUserMedia(constraints, success, (error) => {
            console.error(`访问用户媒体设备失败${error.name}, ${error.message}`);
            return
        })
    } else if ((navigator as any).mozGetUserMedia) {
        //firfox浏览器
        (navigator as any).mozGetUserMedia(constraints, success, (error) => {
            console.error(`访问用户媒体设备失败${error.name}, ${error.message}`);
            return
        });
    } else if (navigator.getUserMedia) {
        //旧版API
        navigator.getUserMedia(constraints, success, (error) => {
            console.error(`访问用户媒体设备失败${error.name}, ${error.message}`);
            return
        });
    }
}

/**
 * @name 获取媒体设备
 * @param success 
 */

export function enumerateDevices(success) {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        console.log('menumerateDevices is not supported!');
    } else {
        navigator.mediaDevices.enumerateDevices().then(devices => {
            const videoInput: any[] = [];
            const audioInput: any[] = [];
            const audioOutput: any[] = [];
            devices.forEach((device) => {
                if (device.kind === "videoinput") {
                    videoInput.push(device)
                }
                if (device.kind === "audioinput" && device.deviceId !== 'communications' && device.label.indexOf("Virtual") === -1) {
                    audioInput.push(device)
                }
                if (device.kind === "audiooutput" && device.deviceId !== 'communications' && device.label.indexOf("Virtual") === -1) {
                    audioOutput.push(device)
                }
            });
            success(videoInput, audioInput, audioOutput)
        }).catch((e) => {
            console.log('error:' + e)
        })
        navigator.mediaDevices.ondevicechange = () => {
            enumerateDevices(success)
        }
    }
}
/**
 * @name 删除任何对用户不太友好的设备细节，例如放在最后括号中的 USB id
 * @param success 
 */

export function formatDeviceLabel(label: string) {

    let formattedLabel = label;

    // Remove braked description at the end as it contains non user friendly strings i.e.
    // Microsoft® LifeCam HD-3000 (045e:0779:31dg:d1231)
    const ix = formattedLabel.lastIndexOf('(');

    if (ix !== -1) {
        formattedLabel = formattedLabel.substr(0, ix);
    }

    return formattedLabel;
}

// 麦克风音量检测
export function beginDetect(stream, disconnect, success) {
    // 将麦克风的声音输入这个对象
    let mediaStreamSource: any = null
    let scriptProcessor: any = null;
    let audioContext = new AudioContext();

    mediaStreamSource = audioContext.createMediaStreamSource(stream);
    // 创建一个音频分析对象，采样的缓冲区大小为4096，输入和输出都是单声道
    scriptProcessor = audioContext.createScriptProcessor(4096, 1, 1);
    // 将该分析对象与麦克风音频进行连接
    mediaStreamSource.connect(scriptProcessor);
    // 此举无甚效果，仅仅是因为解决 Chrome 自身的 bug
    scriptProcessor.connect(audioContext.destination);
    // 开始处理音频
    scriptProcessor.onaudioprocess = (e) => {
        if (disconnect()) {
            // 获得缓冲区的输入音频，转换为包含了PCM通道数据的32位浮点数组
            const buffer = e.inputBuffer.getChannelData(0);
            // 获取缓冲区中最大的音量值
            const maxVal = Math.max(...buffer);
            // 显示音量值
            const mv = Math.round(maxVal * 100);
            success(mv)
            // this.setState({ audioInputPercent: mv })
        } else {
            scriptProcessor.disconnect()
        }
    };
}

// 语音缓存base64转换为ArrayBuffer
export function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let len = binaryString.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}


// 购买增强服务（字幕）
export function toPurchaseEnhancedService() {
    const query = `?User-Agent=${navigator.userAgent}&token=${localStorage.getItem(STORAGE_ACCESS_TOKEN)}`
    window.open(window.location.origin + '/account/center/' + `#/products-buy/2/0/123` + query)
}

// 图片转换为base64
export function getBase64Image(img) {
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext("2d") as any;
    ctx.drawImage(img, 0, 0, img.width, img.height);
    const ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
    const dataURL = canvas.toDataURL("image/" + ext);
    return dataURL;
}

// 获取平台
export function getPlatformValue() {
    const agent = navigator.userAgent.toLowerCase();
    let platformValue = ""
    if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0 || agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
        platformValue = "win"
    } else if (/macintosh|mac os x/i.test(navigator.userAgent)) {
        platformValue = "mac"
    }
    return platformValue
}

// 保存一段音视频到本地用来定位问题
export function saveStreamFile(stream, type, seconds?) {
    const mimeType = "video/webm";
    // @ts-ignore
    let mediaRecorderSv = new MediaRecorder(stream, {
        mimeType: mimeType
    });
    const recordedChunks: any[] = [];
    const recordName = `测试音频.webm`;
    mediaRecorderSv.ondataavailable = (event) => {
        console.log(event.data,)
        recordedChunks.push(event.data);
    };
    mediaRecorderSv.onstop = () => {
        const blob = type === "video" ? new Blob(recordedChunks, { type: mimeType }) : new Blob(recordedChunks);
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a") as any;
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = recordName;
        a.click();
        window.URL.revokeObjectURL(url);
    }
    mediaRecorderSv.start(4000);

    const timer = setTimeout(() => {
        clearTimeout(timer)
        mediaRecorderSv && mediaRecorderSv.stop();
    }, seconds || 10000)
}
