import React from "react";
import i18n from "i18n";
import { RouteComponentProps } from "react-router-dom";
import Drawer from "antd/lib/drawer";
import styles from "./index.module.css";
import Swiper1 from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import { SHOW_N_PARTICIPANTS_PER_PAGE } from "config"
import { IParticipantItem, IPictureInPictureItem } from "models/meet";
// import videolayout from "utils/lib-jitsi-meet/videolayout";
interface IState {
    navMode: "all" | "self" | "none"
    // heightRroportion: number;
    currentPinUserId: string; // 是否是选中主讲模式
    // currentPictureUserId: string;
    showNavMenu: boolean; // 主讲模式的头部列表
    showSwiperNavigation: boolean;
    showSwiperNavigationBtn: boolean;
    // toggleSelfSpeaker: "smallSelf" | "smallSpeaker" | "";
    startIndex: number; // 拉取视频流的开始坐标
    endIndex: number;// 拉取视频流的结束坐标
}
class Index extends React.PureComponent<{
    participants: IParticipantItem[],
    mode: 'gallery' | 'speaker' | 'pictureInPicture';
    pictureInPictureData: IPictureInPictureItem;
    showNavMenu: (bool: boolean) => void;
    setReceiverConstraints: (selectedEndpoints: string[], laseN: Number) => void;
} & RouteComponentProps, IState> {
    constructor(props) {
        super(props)
        // this.onresize = this.onresize.bind(this)
    }

    public state: IState = {
        navMode: "self",
        currentPinUserId: "",
        // currentPictureUserId: "",
        showNavMenu: true,
        showSwiperNavigation: false,
        showSwiperNavigationBtn: false,
        // toggleSelfSpeaker: "smallSelf",
        startIndex: 0,
        endIndex: SHOW_N_PARTICIPANTS_PER_PAGE,
    }

    public componentDidMount() {
        this.swiperInit()
        this.galleryScrollListener()
        this.timer = setInterval(() => {
            const ele = document.getElementById("localvideocameraself") as any
            let ele2
            if (!ele || ele.srcObject) {
                ele2 = document.getElementById("localvideodesktopself") as any
                if (!ele2 || ele2.srcObject) return
            }
            if (this.selfVideoing) {
                this.selfVideoing = false
            } else {
                this.props.participants.forEach(element => {
                    if (element.local && (ele2 ? element.sharedUser : !element.sharedUser)) {
                        element.list.forEach(track => {
                            if (track.mediaType === "video") {
                                const ele = document.getElementById(track.id + "self") as any
                                track.jitsiTrack.attach(ele)
                            }
                        });
                    }
                });
            }
        }, 500)
        // window.addEventListener("resize", this.onresize)
    }

    public swiperInit() {
        // @ts-ignore
        this.swiperSpeaker = new Swiper1('#swiper-speaker', {
            observer: true,// //修改swiper自己或子元素时，自动初始化swiper
            observeParents: true,//修改swiper的父元素时，自动初始化swiper
            observeSlideChildren: true,
            slidesPerView: 'auto',//设置slider容器能够同时显示的slides数量(carousel模式)
            centerInsufficientSlides: true,
            // slidesPerGroup: 6,//slides的数量多少为一组
            grabCursor: true,
            setWrapperSize: true,
            autoHeight: true, // wrapper和container会随着当前slide的高度而发生变化
            centeredSlides: true,
            centeredSlidesBounds: true, //配合centeredSlides居中
            mousewheel: true,
            keyboard: { enabled: true },
            watchOverflow: true,//足够的slide切换时，例如只有1个slide，swiper会失效且隐藏导航
            navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
            on: {
                observerUpdate: (e) => {
                    if (this.props.mode === "speaker" && this.props.participants.length > 2) {
                        let num = 0;
                        (e as any).slidesSizesGrid.forEach(element => {
                            num += element
                        });
                        if (num >= window.innerWidth) {
                            if (this.state.showSwiperNavigation === false) {
                                this.setState({ showSwiperNavigation: true })
                            }
                        } else {
                            if (this.state.showSwiperNavigation === true) {
                                this.setState({ showSwiperNavigation: false })
                            }
                        }
                    }
                },
                slideChangeTransitionStart: (swiper) => {
                    console.log("slideChangeTransitionStart", swiper);
                    this.setSpeakerModeReceiver()
                },
                slideChangeTransitionEnd: (swiper) => {
                    console.log("slideChangeTransitionEnd", swiper);
                    this.setSpeakerModeReceiver()
                },
            }
        })
    }

    // 监听按esc退出全屏
    // public eventFullscreenchange() {
    //     document.addEventListener('fullscreenchange', this.Fn.bind(this));
    //     document.addEventListener('webkitfullscreenchange', this.Fn.bind(this));
    //     document.addEventListener('mozfullscreenchange', this.Fn.bind(this));
    //     document.addEventListener('msfullscreenchange', this.Fn.bind(this));

    // }
    // public Fn() {
    //全屏按钮切换类
    // this.setState({ heightRroportion: this.state.heightRroportion })
    // }

    public swiperSpeaker;

    public render() {
        const { showSwiperNavigation, showSwiperNavigationBtn, showNavMenu } = this.state;
        const { participants, mode, pictureInPictureData } = this.props
        const overallWidth = Math.floor((window.innerHeight * (window.screen.width / window.screen.height)) / window.screen.width * 100)

        return <div className={styles.meetSpeakerMain} id="meetingMain">
            <Drawer
                mask={false}
                placement="top"
                closable={false}
                visible={showNavMenu}
                className={`rightMenuDrawer ${this.props.mode === 'speaker' ? "" : "navDrawer"}`}
                getContainer={false}
                style={{
                    height: this.props.mode === 'speaker' ? (showNavMenu ? '1.01rem' : 0) : "100%",
                    transition: this.props.mode === 'speaker' ? "" : "none"
                }}
                zIndex={this.props.mode === 'speaker' ? 100 : 0}
            >
                { // 参会人全部展示模式
                    <div style={{
                        width: "100vw",
                        height: "100%",
                        display: this.props.mode === 'speaker' ? (this.state.navMode === "all" ? "flex" : "none") : "flex",
                    }}
                        className={styles.meetSpeakerTopBox}>
                        {this.props.mode === 'speaker' && showSwiperNavigation && <div className={styles.btnPrev}
                            id='swiper-button-prev'
                            onMouseOver={() => this.setState({ showSwiperNavigationBtn: true })}
                            onMouseLeave={() => this.setState({ showSwiperNavigationBtn: false })}
                            onClick={() => {
                                this.swiperSpeaker.slidePrev()
                                this.setSpeakerModeReceiver()
                            }}>
                            {
                                showSwiperNavigationBtn && <img src={require('static/images/meet/icon-jiantou-l.png')} alt="" />
                            }
                        </div>}
                        <div className="swiper-container" id='swiper-speaker'>
                            <div className="swiper-wrapper" id='filmstripRemoteVideosContainer'
                                style={{
                                    width: this.props.mode === "gallery" ? (overallWidth > 100 ? 100 : overallWidth) + "%" : "",
                                }}>
                                <div className='swiper-slide'>
                                    <div id='localVideoTileViewContainer' className='videocontainer' />
                                </div>
                            </div>
                        </div>
                        {
                            this.props.mode === 'speaker' && showSwiperNavigation && <div className={styles.btnNext}
                                id='swiper-button-next'
                                onMouseOver={() => this.setState({ showSwiperNavigationBtn: true })}
                                onMouseLeave={() => this.setState({ showSwiperNavigationBtn: false })}
                                onClick={() => {
                                    this.swiperSpeaker.slideNext()
                                    this.setSpeakerModeReceiver()
                                }}>
                                {
                                    showSwiperNavigationBtn && <img src={require('static/images/meet/icon-jiantou-r.png')} alt="" />
                                }
                            </div>
                        }
                    </div>
                }
            </Drawer>
            {// 主窗口
                mode === 'speaker' && (participants.length && (participants.length === 1 ? <div className={styles.speakerBoxWaiting} >
                    <img src={require('static/images/meet/icon-waiting.png')} alt="" />
                    <div>{i18n.t('meet.waitingAccess')}</div>
                </div> :
                    <div className='speaker_usercontainer' id="speaker_usercontainer" onClick={() => {
                        if (mode === 'speaker' && participants.length > 2) {
                            this.setState({ showNavMenu: !this.state.showNavMenu }, () => {
                                this.props.showNavMenu(this.state.showNavMenu)
                                this.setSpeakerModeReceiver()
                            })
                        }
                    }}></div>))
            }
            {// pin功能
                mode !== "pictureInPicture" && participants.length > 2 && <div className={styles.pinIcon} style={{ color: this.state.currentPinUserId ? "#1296db" : "#707070" }}
                    onClick={() => {
                        const target = participants.filter(x => x.speaker)
                        if (target.length) {
                            this.setState({ currentPinUserId: this.state.currentPinUserId ? "" : target[0].userId })
                        }
                    }}>
                    <span className={`${styles.iconfont} iconfont icon-pin`}></span>
                </div>
            }
            {// 跟随图标显示
                mode === "pictureInPicture" && pictureInPictureData.switch && <img className={styles.imgPIP}
                    style={{
                        right: (pictureInPictureData.template === 1 || pictureInPictureData.template === 3) ? "initial" : 0,
                        left: (pictureInPictureData.template === 2 || pictureInPictureData.template === 4) ? "initial" : 0
                    }}
                    src={require("static/images/pictureInPicture/PIP.png")} />}
            {/* {// 两人模式-我自己
                this.props.mode === 'speaker' && this.state.navMode === 'self' &&
                    this.props.participants.length ? this.props.participants.map(element => {
                        if (element.local && !element.sharedUser) {
                            if (this.state.toggleSelfSpeaker === "smallSelf") {
                                return this.selfRender(element)
                            } else {
                                return this.speakerRender(element)
                            }
                        }
                    }) : ""
            } */}
            {/* {// 两人模式-主讲
                this.props.mode === 'speaker' &&
                this.props.participants && this.props.participants.map(element => {
                    if (this.state.toggleSelfSpeaker === "smallSelf") {
                        if (!element.local) {
                            return this.speakerRender(element, true)
                        } else {
                            if (this.props.participants.length === 1 || element.sharedUser) {
                                return this.speakerRender(element, true)
                            }
                        }
                    } else {
                        if (this.props.participants.length <= 2) {
                            return this.selfRender(element)
                        } else {
                            return this.state.currentPinUserId === element.userId ? this.speakerRender(element, true) : (element.speaker && this.speakerRender(element, true))
                        }
                    }
                })
            } */}
            {/* 切换我模式按钮 */}
            {/* {this.props.mode === 'speaker' && <div className={styles.navMenuModeBtn} onClick={() => {
                this.setState({
                    navMode:
                        (this.state.navMode === "all" && "self") ||
                        (this.state.navMode === "self" && "none") ||
                        (this.state.navMode === "none" && "all") || "all"
                }, () => {
                    if (this.state.navMode === "self") {
                        this.props.updateSelfAddTrackNode()
                        const target = this.props.participants.filter(x => !x.local);
                        if (target.length) {
                            this.openPictureInPicture(target[0].userId, true)
                            this.setState({ currentPictureUserId: target[0].userId })
                        }
                    }
                    if (this.state.navMode !== "self") {
                        this.openPictureInPicture("", false)
                    }
                })
            }}>
                <MenuOutlined style={{ color: "#1296db" }} />
            </div>} */}
            {/* 切换比例按钮 */}
            {/* {
                this.props.mode === 'speaker' && this.state.navMode === "all" && <div className={styles.navMenuModeBtn}
                    style={{ top: this.state.heightRroportion * 100 + "vh", position: "absolute" }}
                    onClick={() => {
                        const num = this.state.heightRroportion === 0.1 ? 0.2 : 0.1
                        this.setState({
                            heightRroportion: num
                        }, () => {
                            videoLayout.setSpeakerModeSizeProportion(this.state.heightRroportion === 0.1 ? 'small' : 'default')
                            // this.props.updateAddTrackNode()
                        })
                    }}>
                    {
                        this.state.heightRroportion === 0.1 && <ExpandOutlined className={styles.icon} />
                    }
                    {
                        this.state.heightRroportion === 0.2 && <CompressOutlined className={styles.icon} />
                    }
                </div>
            } */}
            {/* 切换画中画按钮 */}
            {/* {
                this.props.mode === 'speaker' && this.state.navMode === "self" &&
                <Popover placement="leftTop" title={"切换画中画"} content={<div>
                    {pictureList.map(node => {
                        return !node.local && <div key={node.participantId}
                            className={styles.pictureInPictureUser}
                            style={{
                                color: this.state.currentPictureUserId === node.userId ? 'rgba(24,144,255)' : '',
                                background: this.state.currentPictureUserId === node.userId ? 'rgba(24,144,255,0.2)' : ''
                            }}
                            onClick={() => {
                                if (this.state.navMode !== "all") {
                                    this.openPictureInPicture(node.userId, true)
                                    this.setState({ currentPictureUserId: node.userId })
                                }
                            }}
                        >{node.realName ? node.realName : node._displayName ? node._displayName : "陌生人"}</div>
                    })}
                </div>} trigger="click">
                    <div className={styles.navMenuModeBtn}
                        style={{ top: "55%" }}
                    >
                        <UserOutlined style={{ color: "#1296db" }} />
                    </div>
                </Popover>
            } */}

            {/* <ContextMenu flipClick={this.flip.bind(this)} /> */}
        </div >
    }

    public speakerRender(element, bool?) {
        // if (this.props.participants.length === 1) {
        //     return <div className={styles.speakerBoxWaiting} >
        //         <img src={require('static/images/meet/icon-waiting.png')} alt="" />
        //         <div>{i18n.t('meet.waitingAccess')}</div>
        //     </div>
        // }
        return <div className={styles.speakerBox} onClick={() => {
            if (this.props.mode === 'speaker' && this.props.participants.length > 2) {
                this.setState({ showNavMenu: !this.state.showNavMenu }, () => {
                    this.props.showNavMenu(this.state.showNavMenu)
                    this.setSpeakerModeReceiver()
                })
            }
        }}
        >
            {/* pin功能 */}
            {/* {
                !element.sharedUser && this.props.participants.length > 2 && <div className={styles.pinIcon} style={{ color: this.state.currentPinUserId ? "#1296db" : "#707070" }}
                    onClick={() => this.setState({ currentPinUserId: this.state.currentPinUserId ? "" : element.userId })}>
                    <span className={`${styles.iconfont} iconfont icon-pin`}></span>
                </div>
            } */}

            {/* 名字 */}
            {/* <div className={styles.speakerIcon}>
                {
                    element.moderator ? <img src={require("static/images/meet/speaker.png")} alt="" /> : ""
                }
                <span className={styles.name}>{element.realName ? element.realName : (element._displayName || i18n.t('meet.stranger'))}{!element.sharedUser && element.translationLanguageText ? `(${element.translationLanguageText})` : ""}</span>
                <div className="speaker-audioindicator-container">
                    <span className='audioindicator-container' id={`${element.participantId}-speaker-audioindicator-container`}>
                        <span className="audioindicator in-react">
                            <span className="audiodot-bottom" ></span>
                            <span className="audiodot-bottom" ></span>
                            <span className="audiodot-middle" ></span>
                            <span className="audiodot-top" ></span>
                            <span className="audiodot-top" ></span>
                        </span>
                    </span>
                </div>
            </div> */}

            {/* {
                element.list.map(node => {
                    return node.mediaType === "video" ? <div key={node.id} style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => this.props.showMenu()}>
                        {element.local && node.videoType === "desktop" ?
                            <div className={styles.noVideoTextTwo}>{i18n.t('meet.youAreSharingScreen')}</div> :
                            <video
                                className={styles.video}
                                autoPlay={true}
                                id={(bool ? "speaker" : (node.local ? "self" : "speaker")) + node.id}
                            // style={this.getVideoStyle(element, false) as any}
                            />
                        }
                    </div> : <div key={node.id} style={{
                        width: "100%",
                        height: element.list.length === 1 ? "100%" : "",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }} onClick={() => this.props.showMenu()}>
                        {element.list.length === 1 ? <div className={styles.noVideoText} id={element.participantId + "speakerContainer"}>
                            <img className={styles.defaultPhotoBig + " dynamic-shadow"} src={element.avatar || require("static/images/default-photo.png")} />
                        </div> : ""}
                    </div>
                })
            } */}
            {/* {
                !element.list.length && <div className={styles.noVideoTextTwo} id={element.participantId + "speakerContainer"} onClick={() => this.props.showMenu()}>
                    <img className={styles.defaultPhotoBig + " dynamic-shadow"} src={element.avatar || require("static/images/default-photo.png")} />
                </div>
            } */}
        </div>
    }

    // public selfRender(element) {
    //     return <div className={styles.meetSpeakerSelfBox} key={element.participantId + Math.random()}
    //         onClick={() => {
    //             this.setState({ toggleSelfSpeaker: this.state.toggleSelfSpeaker === "smallSpeaker" ? "smallSelf" : "smallSpeaker" }, () => {
    //                 this.props.updateAddTrackNode()
    //             })
    //         }}>
    //         <div className={styles.item}>
    //             {
    //                 element.list.map(node => {
    //                     return node.mediaType === "video" ? <div key={node.id} style={{ width: "100%", height: "100%", position: "relative" }}>
    //                         <video style={{
    //                             width: "100%", height: "100%"
    //                         }} autoPlay={true} id={node.id + (element.local ? "self" : "speaker")}
    //                             onProgress={(e) => {
    //                                 this.selfVideoing = true
    //                             }}
    //                         />
    //                     </div> :
    //                         <div key={node.id} style={{
    //                             width: "100%",
    //                             height: element.list.length === 1 ? "100%" : "",
    //                             display: "flex",
    //                             alignItems: "center",
    //                             justifyContent: "center"
    //                         }}>
    //                             {element.list.length === 1 && <img className={styles.defaultPhoto} src={element.avatar || require("static/images/default-photo.png")} />}
    //                         </div>
    //                 })
    //             }
    //             {
    //                 !element.list.length ? <div className={styles.noVideoTextTwo}>{<img className={styles.defaultPhoto} src={element.avatar || require("static/images/default-photo.png")} />} </div> : ""
    //             }
    //             {
    //                 <div className={styles.userName}>
    //                     {element.realName ? element.realName : (element._displayName || i18n.t('meet.stranger'))}({element.translationLanguageText})
    //                 </div>
    //             }
    //             <div className={styles.mutedBox} style={{ background: element.muted ? '#e02020' : (element.voiceIncentive ? "rgba(0, 145, 255, 1)" : "rgba(0, 0, 0, 0.5)") }}>
    //                 {element.muted ? <div className={`${styles.icon} iconfont icon-muted`}></div> :
    //                     <div className={`${styles.icon} iconfont icon-unmuted`}></div>}
    //             </div>

    //             {
    //                 element.moderator ? <div className={styles.mutedBox} style={{ background: 'rgba(0, 0, 0, 0.5)', top: '0.1rem' }}>
    //                     <img src={require("static/images/meet/speaker.png")} alt="" />
    //                 </div> : ""
    //             }
    //         </div>
    //     </div>
    // }

    // 监听画廊模式滚动
    public galleryScrollListener() {
        const box = document.getElementById('filmstripRemoteVideosContainer');
        if (!box) return
        let t1 = 0;
        let t2 = 0;
        let timer;
        let currentScrollTop = box.offsetHeight
        box.addEventListener('scroll', (e) => {
            console.log(box.scrollHeight, box.offsetHeight, box.scrollTop)
            clearTimeout(timer)
            timer = setTimeout(() => {
                t2 = box.scrollTop;
                if (t2 == t1) { // 滚动结束
                    clearTimeout(timer)
                    if (this.props.mode !== 'gallery') return
                    // 滚动到某个位置
                    const child = document.getElementsByClassName('galley-participant-wrap') as any;
                    let cHeight = child[0].offsetHeight;
                    const row = Math.round(box.scrollTop / cHeight)
                    if (row === 0 || box.scrollTop === 0) return
                    if (0 < box.scrollTop && row > 0) { // 滚了一行宽度的一半就算滚了一行，不到一半不处理
                        box.scrollTop = currentScrollTop + cHeight * row
                    }
                    // 当前展示的参与人
                    const num = (SHOW_N_PARTICIPANTS_PER_PAGE === 16 && 4) || 3;//3 * 3最多布局 
                    const countRow = Math.round(box.scrollHeight / cHeight) // 一共几行
                    const endRow = Math.round(box.scrollTop / cHeight) + num
                    const endIndex = endRow * num;// 参与人开始坐标
                    const startIndex = endIndex - num * num;
                    this.sliceSelectedEndpoints(startIndex, endIndex)
                    this.setState({ startIndex, endIndex })
                }
            }, 800)
            t1 = box.scrollTop
        })
    }

    // 截取要拉流的参与者并设置
    public sliceSelectedEndpoints(startIndex, endIndex) {
        const idlist: string[] = []
        this.props.participants && this.props.participants.forEach((element, index) => {
            if (startIndex <= index && index < endIndex) {
                idlist.push(element.userId)
            }
        });
        this.props.setReceiverConstraints(idlist)
    }

    // 设置主讲模式下拉流
    public setSpeakerModeReceiver() {
        if (this.state.showNavMenu) { // 主讲模式显示导航小框,显示谁拉谁
            if (this.props.participants.length === 2) { // 两人模式
                this.props.setReceiverConstraints([], 1)
            } else {
                const indexList = this.swiperSpeaker.visibleSlidesIndexes;
                if (!indexList) return
                const idlist: string[] = []
                this.props.participants && this.props.participants.forEach((element, index) => {
                    if (indexList[0] <= index && index <= indexList[indexList.length - 1]) {
                        idlist.push(element.userId)
                    } else {
                        if (element.speaker) {
                            idlist.push(element.userId)
                        }
                    }
                });
                this.props.setReceiverConstraints(idlist, idlist.length)
            }
        } else {
            // 全屏模式就拉一路流
            this.props.setReceiverConstraints([], 1)
        }
    }

    // 获取主讲模式video的宽高
    // public getVideoStyle(element, bool) {
    //     if (element.videoWidth && element.videoHeight) {
    //         if (element.videoWidth > element.videoHeight) {
    //             if (element.videoHeight * (document.body.offsetWidth / element.videoWidth) > document.body.offsetHeight) {// 宽设为100%时:video的实际高>可展示的区域
    //                 if (bool) {
    //                     return "width:auto;height:100%"
    //                 } else {
    //                     return {
    //                         width: "auto",
    //                         height: "100%"
    //                     }
    //                 }

    //             } else {
    //                 if (bool) {
    //                     return "width:100%;height:auto"
    //                 } else {
    //                     return {
    //                         width: "100%",
    //                         height: "auto"
    //                     }
    //                 }
    //             }
    //         } else {
    //             if (element.videoWidth * (document.body.offsetHeight / element.videoHeight) > document.body.offsetWidth) {// 高设为100%时:video的实际宽>可展示的区域
    //                 if (bool) {
    //                     return "width:100%;height:auto"
    //                 } else {
    //                     return {
    //                         width: "100%",
    //                         height: "auto"
    //                     }
    //                 }
    //             } else {
    //                 if (bool) {
    //                     return "width:auto;height:100%"
    //                 } else {
    //                     return {
    //                         width: "auto",
    //                         height: "100%"
    //                     }
    //                 }
    //             }
    //         }
    //     } else {
    //         if (bool) {
    //             return "width:100%;height:auto"
    //         } else {
    //             return {
    //                 width: "100%",
    //                 height: "auto"
    //             }
    //         }
    //     }
    // }

    // public onresize() {
    //     const target = this.props.participants.filter(x => x.speaker)
    //     if (target.length) {
    //         const list = target[0].list.filter(x => x.mediaType === "video")
    //         if (list.length) {
    //             const ele = document.getElementById(list[0].id + "speaker")
    //             if (ele) ele.style.cssText = this.getVideoStyle(ele, true) as any
    //         }
    //     }
    // }

    // public flip(bool) {
    //     this.props.participants.map(x => {
    //         if (x.local) {
    //             x.list.map(z => {
    //                 const doc = document.getElementById(z.id) as any;
    //                 if (doc) {
    //                     if (bool) {
    //                         doc.style.transform = "rotateY(180deg)";
    //                     } else {
    //                         doc.style.transform = "rotateY(0deg)"
    //                     }
    //                 }
    //                 const docSelf = document.getElementById(z.id + "self") as any;
    //                 if (docSelf) {
    //                     if (bool) {
    //                         docSelf.style.transform = "rotateY(180deg)";
    //                     } else {
    //                         docSelf.style.transform = "rotateY(0deg)"
    //                     }
    //                 }
    //                 const docSpeaker = document.getElementById(z.id + "speaker") as any;
    //                 if (docSpeaker) {
    //                     if (bool) {
    //                         docSpeaker.style.transform = "rotateY(180deg)";
    //                     } else {
    //                         docSpeaker.style.transform = "rotateY(0deg)"
    //                     }
    //                 }
    //             })
    //         }
    //     })
    // }
    // 开启关闭画中画
    // public openPictureInPicture(userId, bool) {
    //     if (bool) {
    //         this.props.participants.map(x => {
    //             if (userId === x.userId) {
    //                 x.list.map(async z => {
    //                     if (z.mediaType === "video") {
    //                         const vid = document.getElementById(z.id) as any
    //                         // console.log(navigator.appName.indexOf("Safari"))
    //                         // if (navigator.userAgent.indexOf("Safari") > -1) {
    //                         // try {
    //                         //     // console.log((vid as any).webkitSupportsPresentationMode("picture-in-picture"))
    //                         //     // if (!(vid as any).webkitSupportsPresentationMode("picture-in-picture")) {
    //                         //     //     Message.info("safari浏览器暂不支持画中画模式或您通过浏览器选项禁用了此功能")
    //                         //     // }
    //                         //     console.log(vid);
    //                         //     console.log(vid.webkitPresentationMode())
    //                         //     if ((vid as any).webkitSetPresentationMode("inline")) {
    //                         //         console.log(1);
    //                         //         (vid as any).webkitSetPresentationMode("picture-in-picture");
    //                         //         (vid as any).playsInline = true
    //                         //     } else {
    //                         //         console.log(2);
    //                         //         (vid as any).webkitSetPresentationMode("inline")
    //                         //     }
    //                         // } catch (e) {
    //                         //     Message.info("是 safari浏览器暂不支持画中画模式或您通过浏览器选项禁用了此功能")
    //                         // }
    //                         // } else {
    //                         try {
    //                             if (!(document as any).pictureInPictureEnabled) {
    //                                 Message.info("浏览器暂不支持画中画模式或您通过浏览器选项禁用了此功能")
    //                                 return
    //                             }
    //                             if (vid !== (document as any).pictureInPictureElement) { // 判断当前 PictureInPicture 是否已经开启并是否指向该 video
    //                                 await (vid as any).requestPictureInPicture() // 调用 API 开启功能
    //                                     (vid as any).addEventListener('leavepictureinpicture', () => {
    //                                         this.setState({ navMode: "all" })
    //                                         // 退出了画中画模式
    //                                     })
    //                             } else {
    //                                 if (!bool) {
    //                                     await (document as any).exitPictureInPicture() // 调用 API 关闭功能
    //                                     this.openPictureInPicture(userId, true)
    //                                 }
    //                             }
    //                         } catch (e) {
    //                             console.info(e)
    //                         }
    //                         // }
    //                     }
    //                 })
    //             }
    //         })
    //     } else {
    //         // 关闭画中画
    //         if ((document as any).pictureInPictureElement) {
    //             (document as any).exitPictureInPicture() // 调用 API 关闭功能
    //         }
    //     }
    // }
    public componentWillUnmount() {
        this.timer && clearInterval(this.timer)
        // window.removeEventListener("resize", this.onresize)
    }

    private timer;
    private selfVideoing;
}

export default Index;
