
import io from "socket.io-client";
class logIo {
    socket;
    waitingStorageData: any[] = []; // 等待存储的数据

    constructor(name, particpantId) {
        //@ts-ignore
        this.socket = io.connect("https://maxframing.svmeet.com", { path: "/console/socket.io", reconnect: true });
        this.socket.on("connect", () => {
            console.log("Connected!");
            this.socket.emit("message", JSON.stringify({ type: 'reg', name, particpantId }));
        });
        this.socket.on("message", (message) => {
            try {
                let jd = message
                if (typeof message === 'string') {
                    jd = JSON.parse(jd);
                }
                console.log(jd)
                if (jd['terminalID'] == "web") {
                    switch (jd.type) {
                        case "uploadOnlineLog":
                            (window as any).isUpdateLog = true;
                            (window as any).updateLogParams = { ...jd.data, name, particpantId }
                            break;
                        default:
                            break;
                    }
                }
            } catch (e) {
                console.log(e.message, message)
            }
        });
        this.socket.on("disconnect", function () {
            console.log("disconnect!");
            (window as any).isUpdateLog = false;
        });
        this.socket.connect();
    }
    close() {
        try {
            this.socket && this.socket.destroy()
            this.socket = null
        } catch (e) { }
    }
};

export default logIo;

// 拉取在线日志 模块:BASE 白板:WHITEBOARD 房间:ROOM 连接:CONNECTION 流:TRACK 统计：STATS
//{"terminalID":"web","type":"uploadOnlineLog","data":{"level":"INFO","serverUrl":"http://maxframing.svmeet.com:50001","module":"TRACK"}}
