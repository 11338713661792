
import React, { CSSProperties } from "react";
import moment from "moment";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { IMeetingItem } from "models/meet"
import { copyText } from "utils/data";
import i18n from "i18n";
import api from "api";
import { IUser } from "models/user";
import "./index.css"

interface DispatchProps {
    user: IUser;
}
interface IState {
    meetData: IMeetingItem;
}
class Index extends React.Component<{
    meetData: IMeetingItem;
    defaultCopy: boolean;
    pageText?: "maa-meet" | "",
    style?: CSSProperties;
    index?: number;
    onClose?: () => void;
    updateData?: (meetData?: IMeetingItem) => void
} & DispatchProps> {

    public state: IState = {
        meetData: {
            primaryKey: "",
            theme: ""
        }
    }

    public async componentDidMount() {
        if (this.props.updateData && (!this.props.meetData.theme || !this.props.meetData.startTime)) { // 直播远场进来缺少数据
            try {
                const detail = await api.meeting.getMeetingDetail(this.props.meetData.primaryKey);
                if (detail.code === 200) {
                    this.setState({ meetData: detail.data }, () => {
                        this.props.updateData && this.props.updateData(detail.data)
                        if (this.props.defaultCopy) {
                            this.copy()
                        }
                    })
                }
            } catch (e) {
                console.info("会议详情获取接口失败", e)
            }
        } else {
            if (this.props.defaultCopy) {
                this.copy()
            }
        }
    }


    public render() {
        const { meetData } = this.state;
        return <div className={'invitationModal-component'} >
            <div className={'main'} style={this.props.style}>
                <div className={'containerHead'}>
                    <span>{i18n.t('meetingAnnouncement')}</span>
                    {this.props.onClose && <img src={require('static/images/meet/icon-guanbi.png')} style={{ cursor: "pointer" }} alt=""
                        onClick={this.props.onClose} />}
                </div>
                <div id="copyBoxMeet" className="containerText-box">
                    <div className={'containerText'} style={{ opacity: 0, height: 0 }}>
                        {i18n.t('svmeetInviteParticipate')}
                    </div>
                    <p></p>
                    <div className={'containerText'} style={{ marginTop: 0 }}>
                        <span>{i18n.t('conferenceTheme')}</span><span>：</span>
                        <span>{this.props.meetData.theme || meetData.theme}</span>
                    </div>
                    {
                        this.props.meetData.startTime ? <div className={'containerText'}>
                            <span>{i18n.t('startingTime')}</span><span>：</span>
                            <span>{moment(this.props.meetData.startTime).format("YYYY-MM-DD HH:mm")}</span>
                        </div> : <div className={'containerText'}>
                            <span>{i18n.t('startingTime')}</span><span>：</span>
                            <span>{moment(meetData.startTime).format("YYYY-MM-DD HH:mm")}</span>
                        </div>
                    }
                    {
                        this.props.meetData.content && <div className={'containerText'}>
                            <span>{i18n.t('meetingAnnouncement')}</span><span>：</span>
                            <span>{this.props.meetData.content}</span>
                        </div>
                    }

                    <div className={'containerText'}>
                        <span>{i18n.t('conferenceInvitationCode')}</span><span>：</span>
                        <span>{this.props.meetData.meetNumber}</span>
                    </div>
                    {
                      (this.props.meetData.password || meetData.password) && <div className={'containerText'}>
                            <span>{i18n.t('meetPassword')}</span><span>：</span>
                            <span>{this.props.meetData.password || meetData.password}</span>
                        </div>
                    }
                    <div className={'containerText'}>
                        <span>{i18n.t('conferenceAddress')}</span><span>：</span>
                        <span>{`${window.location.origin}/sv/${this.props.meetData.meetNumber}${(this.props.meetData.password || meetData.password) ? '?password=' + (this.props.meetData.password || meetData.password || "") : ""}`}</span>
                    </div>
                </div>
                {
                    this.props.pageText === "maa-meet" ? <div className={'containerText'} style={{ color: '#0091ff', padding: "0 0.2rem" }}>{i18n.t('reservationsMeetInfo')}</div> : ""
                }
                <div className={'btns'}>
                    {this.props.defaultCopy ?
                        <div className={'containerText'} style={{ color: '#0091ff' }}>{i18n.t('copyClipboard')}</div> :
                        <div className={'btn'} onClick={() => {
                            this.copy();
                            this.props.onClose && this.props.onClose()
                        }}>{i18n.t('copyToClipboard')}</div>
                    }
                </div>
                {
                    this.props.pageText === "maa-meet" && this.props.defaultCopy && <div className={'btns'}>
                        <div className={'btn'} onClick={() => {
                            window.history.back()
                        }}>{i18n.t('returnHomePage')}</div>
                    </div>
                }
            </div>
            <div id={"invitationModal-component-NewsToolBoxMeet" + this.props.index}></div>
        </div>
    }

    public copy() {
        const ele = document.getElementById('copyBoxMeet')
        copyText(ele?.innerText, "invitationModal-component-NewsToolBoxMeet" + this.props.index)
    }
}

export default withRouter(connect(
    (state: any) => ({
        user: state.user
    }),
)(Index));