

// @ts-nocheck
import { desktopApplyConstraints, connectionOptions } from "utils/lib-jitsi-meet/config"

export default class meetConnection {
    connection: null;
    room: null;
    tracks: null;
    type: "castScreen" | "";
    init(type: "castScreen" | "", token: string, meetData: {
        roomName: string;
        userName: string;
        isDesktop: boolean;
        userKey: string;
    }, successJoin: Function, endFun: Function) {
        this.type = type
        connectionOptions['desktopSharingFrameRate'] = {
            min: 5,
            max: 30
        }
        JitsiMeetJS.init(connectionOptions)
        JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR)
        connectionOptions['statisticsId'] = (meetData.isDesktop ? 'sv-desktop-' : 'sv-user-') + meetData.userKey;
        this.connection = new JitsiMeetJS.JitsiConnection(null, token || null, connectionOptions)

        this.connection.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
            () => {
                this.room = this.connection.initJitsiConference(meetData.roomName, connectionOptions); // 为添加第二条流
                this.room.on(
                    JitsiMeetJS.events.conference.CONFERENCE_JOINED, () => {
                        successJoin()
                        if (type === "castScreen") {// 投屏
                            this.room.setLastN(0) // 不拉取视频
                        }
                    });
                this.room.on(
                    JitsiMeetJS.events.conference.KICKED, (user) => {
                        endFun() // 会议结束
                        this.connection && this.connection.disconnect();
                    })
                this.room.setDisplayName(meetData.userName)
                this.room.join()
            });

        this.connection.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_FAILED,
            (err) => { // 服务器连接失败
                console.log("服务器连接失败", err)
            });
        this.connection.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
            (err) => { // 服务器连接失败
                console.log("服务器连接断开", err)
                this.disconnect()
            });

        this.connection.connect();
    }
    // 共享用户成功加入会议
    desktopConferenceJoined(success: Function, cancelDesktop: Function, mode: string) {
        JitsiMeetJS.createLocalTracks({
            devices: ['desktop'],
        }).then((tracks) => {
            success(tracks, mode === "video" ? 1 : 0)
            this.tracks = tracks
            tracks.forEach(track => {
                this.room.addTrack(track);
                if (track.getType() === "video") {
                    if (mode === "video") {
                        desktopApplyConstraints(1, track)
                    } else {
                        desktopApplyConstraints(0, track)
                    }
                }
                this.onLocalTracks(tracks, (params) => cancelDesktop(params))
            });
        })
            .catch(error => {
                console.log(error)
                conference._handleScreenSharingError(error)
                this.disconnect()
                cancelDesktop()
            });
    }

    // 本地流
    onLocalTracks(tracks, cancelDesktop) {
        for (let i = 0; i < tracks.length; i++) {
            tracks[i].addEventListener(
                JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
                () => {
                    if (this.type === "castScreen") { // 投屏
                        if (tracks[i].videoType === "desktop") {// 点击了悬浮的取消共享按钮
                            this.disconnect()
                            cancelDesktop('close')
                        }
                    }
                })
        }
    }

    disconnect() {
        if (this.tracks) {
            this.tracks.forEach(track => {
                track.dispose()
            });
            this.tracks.stream && this.tracks.stream.getTracks().foreach(x => {
                x.stop()
            })
        }
        try { this.room && this.room.leave() } catch (e) { }
        this.connection && this.connection.disconnect();
        // this.connection = null
    }
}