//@ts-nocheck
// import JitsiMeetJS from "JitsiMeetJS";
import React from "react";
import moment from "moment";
import api from "api";
import i18n from "i18n";
import { connect } from "react-redux";
import { DisconnectPage, ChatMessage, SetCamera, MeetTheme, InvitationModal, Summary, ReportProblem, WaitingJoinMeet, ConnectionQuality, LiveSetting, JoinDevice, MeetingRecording } from "components";
import { RouteComponentProps } from "react-router-dom";
import notification from "antd/lib/notification";
import Modal from "antd/lib/modal";
import Drawer from "antd/lib/drawer";
import Spin from "antd/lib/spin";
import message from "antd/lib/message";
import Progress from "antd/lib/progress"
import { SETTING_PARAMS, CURRENT_MEETING_DATA, STORAGE_ACCESS_TOKEN, MEET_PROVISIONAL_USERNAME, GUIDE_PAGE, SUBTITLE_USAGE_MODAL, whiteboardUrl, JVB_SERVER_REGION, getWebSocketUrl } from "config";
import Footer from "./footer"
import SpeakerMode from "./speaker-mode"
import { desktopApplyConstraints, connectionOptions } from "utils/lib-jitsi-meet/config"
import meetWebsocket from "utils/lib-jitsi-meet/websocket";
import { setE2eeSupported, setMyInfo, setPictureInPictureData, setMeetingRecordingData } from "reduxs/actions/meet"
import { getAvailableDevices, disconnectAudioContext, connectAudioContext, authorizeRemoteAssistance, endRecord, endRecordAudio } from "utils/lib-jitsi-meet/devices";
import videoLayout from "utils/lib-jitsi-meet/videolayout";
import { createRnnoiseProcessor } from 'utils/lib-jitsi-meet/rnnoise';
import { IDefaultDeviceSetItem, IParticipantItem, IMyPermission, IRaiseHandsListItem, IMeetingItem, defaultPictureInPictureData } from "models/meet";
import { ISummaryMessageItem, IRecorderCommand } from "models/common"
import { IUser } from "models/user";
import { createReplaceBgEffect } from "utils/background";
import { base64ToArrayBuffer, toPurchaseEnhancedService, formatDeviceLabel, saveStreamFile } from "utils/common";
import Whiteboard from "utils/whiteboard";
import summary from "utils/lib-jitsi-meet/summary";
import conference from "utils/lib-jitsi-meet/conference";
import { watermark, GetRandomNum } from "utils/data";
import logSocket from "utils/logSocketio"
import { LoadingOutlined } from '@ant-design/icons';
import SetLang from "./set-lang"
import SetAccount from "./set-account"
import LanguageList from "./language-list"
import { logger } from "utils/log";
import videojs from "video.js"
import "videojs-vr"
import "./index.less";
import mqtt from "mqtt"
interface IState {
    loading: boolean;
    project: "yj" | "";
    mode: "gallery" | "speaker" | 'pictureInPicture';
    videoList: IParticipantItem[];
    user: IUser;
    devices: {
        audiooutput: any[],
        videoinput: any[],
        audioinput: any[]
    },
    settingParams: IDefaultDeviceSetItem;
    showFooter: boolean;
    showNavMenu: boolean;
    isLocalModerator: boolean;
    // showSummary: "message" | "chat" | "notice" | "";
    chatMessage: Array<{
        avatar: string;
        userName: string;
        userId: string
        timestamp: string;
        message: string;
        privateMessage: boolean;
    }>,
    currentShareScreenUserId: string;
    connection_failed: boolean;
    waitingJoin: boolean;
    currentFooterVideoIcon: boolean;
    meetData: IMeetingItem,
    moreMenuActive: "bg" | "set" | "",
    showInviteCodeModal: boolean;
    summaryMessage: ISummaryMessageItem[];
    switchReportProblem: boolean;
    switchMeetingRecording: boolean;// 会议录制
    waitingModeratorJoin: boolean;
    showInviteCodeFooterModal: boolean;
    currentMediaRecorderParticipantId: string;// 当前录像的主持人ID
    showGuide: number;
    currentTranslationLang: string;
    translationLoading: boolean;
    isWaitOpenSubtitle: boolean; // 未设置默认语言时，等设置了语言再开启字幕
    selectAccountModal: boolean;
    currentTranslationAcccount: string;// 选择要使用的翻译账号
    enterpriseSubtitleAccount: boolean;// 字幕账号是不是企业
    closeAccountModal: boolean;
    showConnectionQuality: boolean;
    permissionMenu: object;
    myPermission: IMyPermission;
    raiseHand: boolean;
    showWhiteboardMask: boolean;// 白板loading
    whiteboardLoadingProgress: {
        count: number;//白板历史数据的总条数
        current: number;// 白板加载到当前条数
        percent: number;// 进度条百分百
    };
    roomWatermarkPermissions: boolean;// 会议的水印权限
    isLiveStreaming: boolean; // 正在直播
    raiseHandsList: IRaiseHandsListItem[];
    remoteLiveUserList: Array<{
        name: string;
        userId: string;
    }>;
    participantAudioLevel: object;
    whetherReconnect: boolean;
    languageListModal: boolean;
    customExceptionConnection: boolean;
    liveStatus: 0 | 1 | 2;//0:未开始直播 1:开始直播未推流 2:直播中
    connectionActive: boolean; // 10秒内连接未恢复再重连
    currentPinUserId: string; // 是否是选中主讲模式
    switchJoinDevice: boolean;// 手动加入设备
    previewDevice: any;// 有数据表示预览设备
    joiningDeviceList: any[];
    pictureInPictureUser: IParticipantItem[];//布局在画中画的用户
    disableCloudRecording: boolean;// 禁用云端录制
    liveLayoutInfo: any; // 直播布局数据
}
let localTracks: any[] = [];
let CLOSE_CAMERA_COMMAND = "CLOSE_CAMERA_COMMAND";
let OPEN_CLOSE_MEDIA_RECORDER = 'OPEN_CLOSE_MEDIA_RECORDER';
let SWITCH_TRANSLATION_LANGUAGE = 'SWITCH_TRANSLATION_LANGUAGE';
let SWITCH_SUBTITLE_SERVICE = "SWITCH_SUBTITLE_SERVICE";
let CLOSE_DESKTOP = 'CLOSE_DESKTOP';
let AUTHORIZE_REMOTE_ASSISTANCE = 'AUTHORIZE_REMOTE_ASSISTANCE';
let resolutionData = {}
let kickCode = "F100"
let localVideoSmall = 'localVideoSmall_'
let speakerVideo = 'speakerVideo'
let JOINING_DEVICE_LIST = 'joiningDeviceList'
class Index extends React.Component<RouteComponentProps, IState> {
    constructor(props) {
        super(props)
        this.onMessageMeetWebsocketListener = this.onMessageMeetWebsocketListener.bind(this)
        window.setAudioDom = this.setAudioDom.bind(this)
    }
    public state: IState = {
        loading: true,
        project: "",
        mode: "speaker",
        videoList: [],
        user: {},
        devices: {
            videoinput: [],
            audioinput: [],
            audiooutput: [],
        },
        settingParams: {
            microphone: true,
            camera: false,
            audioInput: {
                deviceId: "default",
            },
            audioOutput: {
                deviceId: "default",
            },
            videoInput: {
                deviceId: "default",
            },
            watermark: false
        },
        showFooter: true,
        showNavMenu: true,
        isLocalModerator: false,
        // showSummary: "",
        chatMessage: [],
        currentShareScreenUserId: "",
        connection_failed: false,
        waitingJoin: true,
        currentFooterVideoIcon: false, //  本地dispose 共享和视频都会走本地轨道离开监听，需要做个判断，只有点击了浮窗上的关闭共享才处理监听
        meetData: {
            primaryKey: '',
            theme: "",
            content: "",
        },
        moreMenuActive: "",
        showInviteCodeModal: true,
        summaryMessage: [],
        switchReportProblem: false,
        switchMeetingRecording: false,
        waitingModeratorJoin: false,
        showInviteCodeFooterModal: false,
        currentMediaRecorderParticipantId: "",
        showGuide: 0,
        currentTranslationLang: "",
        translationLoading: true,
        isWaitOpenSubtitle: false,
        selectAccountModal: false,
        currentTranslationAcccount: "",
        enterpriseSubtitleAccount: false,
        closeAccountModal: false,
        showConnectionQuality: false,
        permissionMenu: {
            "prohibitUnmute": {
                label: i18n.t("prohibitUnmute"),
                checkout: false
            },
            "onlyHostCanShare": {
                label: i18n.t("onlyHostCanShare"),
                checkout: false
            },
            "onlyhostCanOpenWhiteboard": {
                label: i18n.t("onlyhostCanOpenWhiteboard"),
                checkout: false
            },
            // "onlyHostJoinDevice": {
            //     label: i18n.t("onlyHostJoinDevice"),
            //     checkout: false
            // },
            "lockMeeting": {
                label: i18n.t("lockMeeting"),
                checkout: false
            }
        },
        myPermission: {
            handRaisedState: 0,
            speak: 0,
            screenSharing: 0,
            whiteboard: 0,
        },
        raiseHand: false,
        showWhiteboardMask: false,
        whiteboardLoadingProgress: {
            count: 0,
            current: 0,
            percent: 0,
        },
        roomWatermarkPermissions: false,
        isLiveStreaming: false,
        raiseHandsList: [],
        remoteLiveUserList: [],
        participantAudioLevel: {},
        whetherReconnect: false,
        languageListModal: false,
        customExceptionConnection: false,
        liveStatus: 0,
        connectionActive: true,
        currentPinUserId: "",
        switchJoinDevice: false,
        previewDevice: false,
        joiningDeviceList: [],
        pictureInPictureUser: [],
        disableCloudRecording: false,
        liveLayoutInfo: null
    }

    public async componentDidMount() {
        (window as any).participants = this.state.videoList;
        if ((window as any).websocketLive) {
            this.meetingSocket = (window as any).websocketLive;
            (window as any).meetingData = this.state.meetData;
            this.meetingSocket.addEventListener('message', this.onMessageMeetWebsocketListener)
        }
        // 引导页
        const guide = localStorage.getItem(GUIDE_PAGE)
        if (guide) {
            if (JSON.parse(guide).indexOf("meet") > -1) {
                this.setState({ showGuide: 1 })
            }
        }
        // 入会展示几秒主题，然后在关闭
        const timerone = setTimeout(() => {
            this.setState({ showNavMenu: false, showInviteCodeModal: false })
            timerone && clearTimeout(timerone)
        }, 6000)

        // 监听断网
        if (!window.navigator.onLine) {
            this.setState({ connection_failed: true, waitingJoin: false })
            return
        }

        //检测网络宽带
        // navigator.connection.addEventListener("change", function () {
        //     console.log("网速为" + navigator.connection.downlink * 1024 / 8 + "KB/s")
        // })

        if (this.props.match.params.project === "yj") { // 应急项目进入会议
            const arr = decodeURIComponent(this.props.location.search).substr(1).split("&");
            const searchObj = {}
            arr.map(x => {
                searchObj[x.split("=")[0]] = x.split("=")[1]
            })
            const { user } = this.state;
            user.name = searchObj["name"]
            localStorage.setItem(
                CURRENT_MEETING_DATA,
                JSON.stringify({
                    primaryKey: searchObj["primaryKey"],
                    theme: searchObj["theme"],
                })
            );
            this.setState({ project: "yj", user }, () => {
                this.init()
            })
        } else {
            if (window.localStorage.getItem(STORAGE_ACCESS_TOKEN)) { // 判断是否登录
                this.state.user = this.props.user;
                const provisionalUsername = window.localStorage.getItem(MEET_PROVISIONAL_USERNAME)
                if (provisionalUsername) {
                    this.state.user.name = provisionalUsername;
                }
                logger.info("BASE", '入会的用户信息', this.state.user)
                const setting = window.localStorage.getItem(SETTING_PARAMS)
                if (setting) {
                    this.setState({ settingParams: JSON.parse(setting) })
                }
                logger.info("BASE", '入会的设置参数', setting)
                const meetData = window.localStorage.getItem(CURRENT_MEETING_DATA) as any
                if (meetData) {
                    this.state.meetData = JSON.parse(meetData)
                } else {
                    window.location.replace(window.location.origin + window.location.pathname + "#/home")
                }
                logger.info("BASE", '当前会议数据', this.state.meetData)
                connectionOptions['statisticsId'] = 'sv-user-' + this.state.user.primaryKey;
                let region = localStorage.getItem(JVB_SERVER_REGION)
                if (region) {
                    region = JSON.parse(region)
                } else {
                    const list = await api.common.getRegion()
                    try {
                        const region1 = await api.common.getServerRegion()
                        list.data.forEach(element => {
                            if (region1 && (element.regionKey === region1)) {
                                region = element
                            }
                        });
                    } catch (e) { }
                    finally {
                        region = region || list.data[0]
                    }
                }
                connectionOptions['deploymentInfo'] = {
                    shard: region.shard,
                    userRegion: region.userRegion,
                    region: region.region
                }
                this.init()
            } else {
                window.location.replace(window.location.origin + window.location.pathname + "#/login")
            }
        }

        // 获取设备列表
        const deviceList = localStorage.getItem(JOINING_DEVICE_LIST)
        if (deviceList) {
            this.setState({ joiningDeviceList: JSON.parse(deviceList) })
        }
    }

    // 监听
    public onMessageMeetWebsocketListener(receive) {
        let msg = JSON.parse(receive.data)
        if (msg.type === 'ping') return
        this.onMessageMeetWebsocket(msg)
    }

    // 会议控制
    public onMessageMeetWebsocket(msg) {
        const data = msg.data
        switch (msg.type) {
            // 服务可用
            case 'meetingInfo':
                if (data) {
                    if (data.roomInfo.muteState === 0) { //允许解除静音
                        this.state.permissionMenu['prohibitUnmute'].checkout = false
                    } else if (data.roomInfo.muteState === 1) { //禁止解除静音
                        this.state.permissionMenu['prohibitUnmute'].checkout = true;
                    }

                    if (data.roomInfo.screenSharingState === 0) {//允许共享
                        this.state.permissionMenu['onlyHostCanShare'].checkout = false;
                    } else if (data.roomInfo.screenSharingState === 1) {//不允许共享
                        this.state.permissionMenu['onlyHostCanShare'].checkout = true;
                    }

                    if (data.roomInfo.whiteboardStatE === 0) {//允许白板
                        this.state.permissionMenu['onlyhostCanOpenWhiteboard'].checkout = false;
                    } else if (data.roomInfo.whiteboardStatE === 1) {//不允许白板
                        this.state.permissionMenu['onlyhostCanOpenWhiteboard'].checkout = true;
                    }

                    this.state.permissionMenu['lockMeeting'].checkout = data.roomInfo.locking; // 锁定会议

                    if (data.myInfo) {
                        if (data.myInfo.speak === 1) {
                            if (this.refFooter.state.muted) {
                                message.success({
                                    content: i18n.t('AudienceIsMuted'),
                                    icon: <span></span>,
                                    style: {
                                        marginTop: '30vh',
                                    },
                                    duration: 5,
                                    className: "meet-custom-message",
                                    key: "youHaveBeenMuted"
                                })
                            } else {
                                message.success({
                                    content: i18n.t('AudienceIsMuted2'),
                                    icon: <span></span>,
                                    style: {
                                        marginTop: '30vh',
                                    },
                                    duration: 5,
                                    className: "meet-custom-message",
                                    key: "youHaveBeenMuted"
                                })
                                this.refFooter && this.refFooter.setState({
                                    muted: true,
                                }, () => {
                                    this.setLacalMute(true)
                                })
                            }
                        }
                    }
                    this.setState({
                        myPermission: data.myInfo || this.state.myPermission, permissionMenu: this.state.permissionMenu,
                        roomWatermarkPermissions: data.roomInfo.shareScreenWatermark,
                        videoList: this.state.videoList
                    }, () => {
                        if (data.memberInfo) {
                            for (const key in data.memberInfo) {
                                if (Object.prototype.hasOwnProperty.call(data.memberInfo, key)) {
                                    const ele = data.memberInfo[key];
                                    const target = this.state.videoList.filter(x => x.userId === key)
                                    if (target.length) { // 更新已有用户的值
                                        target[0].shareScreenWatermark = ele.shareScreenWatermark;
                                        if (target[0].shareScreenWatermark) {
                                            let openWatermark = false
                                            if (target[0].sharedUser) {// 共享用户是用户本身（手机、TV等）
                                                openWatermark = true
                                            } else { // 共享用户和用户本身是单独两个用户（PC、web）
                                                const target1 = this.state.videoList.filter(z => key === z._displayName.split("-")[1])
                                                if (target1.length) {
                                                    openWatermark = true
                                                }
                                            }
                                            if (openWatermark) {
                                                this.openWatermark(document.getElementById(speakerVideo)?.parentElement, target[0].participantId)
                                                if (this.state.mode !== "speaker") {
                                                    const eleSmall = document.getElementById("participant_" + target[0].participantId)
                                                    this.openWatermark(eleSmall, target[0].participantId)
                                                }
                                            }
                                        } else {
                                            if (target[0].sharedUser) {
                                                this.closeWatermark()
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        this.props.setMyInfo(this.state.myPermission)
                        if (this.state.isLocalModerator) {// 我自己是主持人，会议中的水印状态和我的水印状态对应
                            this.state.settingParams.watermark = this.state.roomWatermarkPermissions
                            this.setState({ settingParams: this.state.settingParams })
                        }
                    })
                }
                break;
            case 'roomInfo':
                if (data.muteState === 0) { //允许解除静音
                    this.state.permissionMenu['prohibitUnmute'].checkout = false
                } else if (data.muteState === 1) { //禁止解除静音
                    this.state.permissionMenu['prohibitUnmute'].checkout = true;
                }

                if (data.screenSharingState === 0) {//允许共享
                    this.state.permissionMenu['onlyHostCanShare'].checkout = false;
                } else if (data.screenSharingState === 1) {//不允许共享
                    this.state.permissionMenu['onlyHostCanShare'].checkout = true;
                }

                if (data.whiteboardStatE === 0) {//允许白板
                    this.state.permissionMenu['onlyhostCanOpenWhiteboard'].checkout = false;
                } else if (data.whiteboardStatE === 1) {//不允许白板
                    this.state.permissionMenu['onlyhostCanOpenWhiteboard'].checkout = true;
                }
                this.setState({ roomWatermarkPermissions: data.shareScreenWatermark, permissionMenu: this.state.permissionMenu }, () => {
                    if (this.state.isLocalModerator) {// 我自己是主持人，会议中的水印状态和我的水印状态对应
                        this.state.settingParams.watermark = this.state.roomWatermarkPermissions
                        this.setState({ settingParams: this.state.settingParams })
                    }
                    const target = this.state.videoList.filter(z => z.sharedUser === true)
                    if (target.length) {
                        if (data.shareScreenWatermark) { // 会议中开启水印
                            const desktopData = target[0].list.filter(z => z.mediaType === "video")
                            if (desktopData.length) {
                                this.openWatermark(document.getElementById(speakerVideo)?.parentElement, target[0].participantId)
                                if (this.state.mode !== "speaker") {
                                    const eleSmall = document.getElementById("participant_" + target[0].participantId)
                                    this.openWatermark(eleSmall, target[0].participantId)
                                }
                            }
                        } else {// 会议中关闭水印
                            if (target[0].shareScreenWatermark === false) {
                                this.closeWatermark()
                            }
                        }
                    }
                }) // 水印
                break;
            case 'updateChatMemberInfo':
                this.state.videoList.forEach(async ele => {// 用户更改了水印状态
                    if ((ele.participantId === Object.keys(data)[0]) || ele._displayName.indexOf(Object.keys(data)[0]) > -1) {
                        ele.shareScreenWatermark = data[Object.keys(data)[0]].shareScreenWatermark;
                        if (ele.moderator) { // 如果此人是主持人，对应会议的权限也跟着改变
                            await this.setState({ roomWatermarkPermissions: ele.shareScreenWatermark })
                        }
                        if (ele.shareScreenWatermark) {
                            if (ele.sharedUser) {
                                this.openWatermark(document.getElementById(speakerVideo)?.parentElement, ele.participantId)
                                if (this.state.mode !== "speaker") {
                                    const eleSmall = document.getElementById("participant_" + ele.participantId)
                                    this.openWatermark(eleSmall, ele.participantId)
                                }
                            } else if (ele.moderator) {
                                const desktopData = this.state.videoList.filter(x => x.sharedUser)
                                if (desktopData.length) {
                                    this.openWatermark(document.getElementById(speakerVideo)?.parentElement, desktopData[0].participantId)
                                    if (this.state.mode !== "speaker") {
                                        const eleSmall = document.getElementById("participant_" + desktopData[0].participantId)
                                        this.openWatermark(eleSmall, desktopData[0].participantId)
                                    }
                                }
                            }
                        } else {
                            if (!this.state.roomWatermarkPermissions) { // 会议未开启水印权限(=主持人的水印权限)
                                if (ele.sharedUser || ele.moderator) { // 当前共享人取消水印 or  主持人取消
                                    this.closeWatermark()
                                }
                            }
                        }
                    }
                })
                this.setState({ videoList: this.state.videoList })
                break;
            case 'raiseHandsList':
                if (msg.data.length) {
                    if (!this.state.isLocalModerator) return
                    if (this.state.raiseHandsList.length < msg.data.length) {
                        let current = msg.data[msg.data.length - 1]
                        message.success({
                            content: (current.name || i18n.t('meet.stranger')) + " " + i18n.t('HandsUp'),
                            icon: <span></span>,
                            style: {
                                marginTop: '30vh',
                            },
                            duration: 5,
                            className: "meet-custom-message",
                            key: "HandsUp"
                        })
                        if (current.participantId) {
                            const tar = this.state.videoList.filter(x => x.userId === current.participantId)
                            if (tar.length) {
                                current.avatar = tar[0].avatar;
                                current.moderator = tar[0].moderator;
                            }
                        } else {
                            current.avatar = "";
                            current.moderator = false;
                        }
                    }
                    this.setState({ raiseHandsList: msg.data })
                } else {
                    this.setState({ raiseHandsList: [] })
                }
                break;
            case 'myInfo':
                const { raiseHand } = this.state
                if (raiseHand) { // 举手
                    if (this.state.myPermission.handRaisedState === 1) {
                        this.refFooter && this.refFooter.raiseHandHandle(false)
                    }
                    this.setState({ raiseHand: false })
                } else {
                    this.setState({ raiseHand: data.handRaisedState === 1 ? true : false })
                }
                if (this.state.myPermission.speak !== data.speak && this.refFooter) {
                    if (data.speak === 0 && this.refFooter.state.muted) {//允许发言
                        const timer = setTimeout(() => {
                            if (!this.invitationToSpeakModal) {
                                message.success({
                                    content: i18n.t('allowSpeakText'),
                                    icon: <span></span>,
                                    style: {
                                        marginTop: '30vh',
                                        color: "rgba(61, 230, 47, 1)"
                                    },
                                    duration: 5,
                                    className: "meet-custom-message",
                                    key: "myPermissionsKey"
                                })
                            }
                            clearTimeout(timer)
                        }, 1000)
                    } else if (data.speak === 1 && this.refFooter.state.muted) { // 不允许发言
                        message.success({
                            content: i18n.t('refusedSpeakText1'),
                            icon: <span></span>,
                            style: {
                                marginTop: '30vh',
                            },
                            duration: 5,
                            className: "meet-custom-message",
                            key: "myPermissionsKey"
                        })
                    }
                }
                this.setState({ myPermission: data || this.state.myPermission }, () => {
                    this.props.setMyInfo(this.state.myPermission)
                })
                break;
            case "refuseToSpeak": //主持人拒绝了
                message.success({
                    content: i18n.t('refusedSpeakText'),
                    icon: <span></span>,
                    style: {
                        marginTop: '30vh',
                    },
                    duration: 5,
                    className: "meet-custom-message",
                    key: "refuseToSpeakMessage"
                })
                break;
            case 'invitationToSpeak': // 主持人邀请我发言
                if (this.invitationToSpeakModal) return
                this.invitationToSpeakModal = Modal.confirm({
                    title: null,
                    icon: null,
                    className: "cast-modal",
                    content: <div className="cast-modal-content" >
                        <div>{i18n.t("invitesYouToSpeak")}</div>
                        <div className="btns">
                            <div className="btn1" onClick={() => {
                                this.invitationToSpeakModal.destroy()
                                this.invitationToSpeakModal = null
                            }}>{i18n.t("cancel")}</div>
                            <div className="btn2" onClick={() => {
                                if (this.refFooter.state.muted) {
                                    this.refFooter && this.refFooter.setState({
                                        muted: false,
                                    }, () => {
                                        this.setLacalMute(false)
                                    })
                                }
                                this.invitationToSpeakModal.destroy()
                                this.invitationToSpeakModal = null
                            }}>{i18n.t("meet.determine")}</div>
                        </div>
                    </div>,
                })
                break;
            case "updateWhiteboard":
                switch (data.type) {
                    case 0:
                        this.setState({ showWhiteboardMask: true }, () => {
                            const obj = {
                                type: "init",
                                version: 2,
                                data: {
                                    user: {
                                        id: this.state.user.primaryKey,
                                        name: this.state.user.name,
                                        avatar: this.state.user.avatar,
                                        isManage: data.data.userType, //0.开启人并且主持人 1. 开启人 2. 主持人 3. 白板参与者，4.白板旁观者
                                    },
                                    count: data.data.serialNumber, // 历史总条数
                                    total: data.data.canvasNumber // 页数
                                }
                            }
                            if (this.state.isLocalModerator) {
                                obj.data.user.isManage = 2;
                                if (data.data.userType === 1) {
                                    obj.data.user.isManage = 0;
                                }
                            }
                            this.initDataWhiteboard = data.data;
                            this.refFooter && this.refFooter.setState({ visibleMoreDropdown: false, visibleListUserDropdown: false })
                            if (!this.iframeWhiteboard) {
                                const user = this.state.videoList.filter(x => x.userId === data.data.participantId)
                                if (user.length && user[0].local === false) {
                                    this.initDataWhiteboard = { ...this.initDataWhiteboard, participantName: user[0]._displayName }
                                    message.success({
                                        content: i18n.t('message.xxxOpensTheWhiteboard', { name: user[0]._displayName }),
                                        icon: <span></span>,
                                        style: {
                                            marginTop: '30vh', color: "rgba(61, 230, 47, 1)"
                                        },
                                        duration: 6,
                                        className: "meet-custom-message",
                                        key: "xxxOpensTheWhiteboard",
                                    })
                                }
                                if (data.data.userType !== 1) {//我不是白板开启者
                                    this.refFooter && this.refFooter.openWhiteboard(false, false)
                                }
                                this.iframeWhiteboard = document.createElement('iframe') as any
                                this.iframeWhiteboard.id = "conferenceOperationIframe"
                                this.iframeWhiteboard.frameBorder = "0";
                                this.iframeWhiteboard.src = whiteboardUrl + `?ts=${moment().date()}`;
                                this.iframeWhiteboard.style.cssText = "position: fixed;top: 0;left: 0;z-index: 100;width: 100vw;height: 100vh;";
                                this.iframeWhiteboard.sandbox = 'allow-scripts allow-same-origin '
                                document.body.appendChild(this.iframeWhiteboard)
                                this.iframeWhiteboard.onload = async () => {
                                    this.iframeWhiteboard.style.background = "#fff"
                                    this.refFooter && this.refFooter.whiteboardIframeCallback(data.data.userType)
                                    this.iframeWhiteboard.contentWindow.postMessage(JSON.stringify(obj), whiteboardUrl);
                                    (window as any).iframeWhiteboard = this.iframeWhiteboard
                                    // 大于0表示之前有数据，需要发给白板
                                    if (data.data.serialNumber > 0) {
                                        this.whiteboardData.getWhiteboardLocalStorage().then((storage) => {
                                            if (storage.length) { // 本地有数据
                                                for (let index = 0; index < data.data.serialNumber; index++) {
                                                    const value = storage.find(x => x.serial === index)
                                                    if (value) {
                                                        this.iframeWhiteboard && this.iframeWhiteboard.contentWindow.postMessage(JSON.stringify({
                                                            type: "realMessage",
                                                            data: value
                                                        }), whiteboardUrl);
                                                    } else {// 本地少数据，需要获取对应serial的数据
                                                        this.meetingSocket.send(JSON.stringify({
                                                            type: "whiteboardHistoricalData",
                                                            data: {
                                                                start: index,
                                                                end: index + 1
                                                            }
                                                        }));
                                                    }
                                                }
                                            } else {
                                                this.meetingSocket.send(JSON.stringify({
                                                    type: "whiteboardHistoricalData",
                                                    data: {
                                                        start: 0,
                                                        end: data.data.serialNumber
                                                    }
                                                }));
                                            }
                                        })
                                    } else {
                                        this.setState({ showWhiteboardMask: false })
                                    }
                                    // 打开会议操作面板
                                    this.whiteboardData.openSharedWhiteboard({
                                        isLocalModerator: this.state.isLocalModerator,
                                        isWhiteboardCreator: data.data.userType === 1 ? true : this.refFooter.state.isWhiteboardCreator,
                                        username: user.length ? user[0]._displayName : ""
                                    }, () => {
                                        this.refFooter && this.refFooter.confirmCloseWhiteboard()
                                    });
                                    (window as any).closeWhiteboardMaskFun = () => {
                                        this.setState({ showWhiteboardMask: false })
                                    }
                                    // 批注：发送给白板图片作为背景图
                                    if (this.refFooter && this.refFooter.state.switchAnnotation) {
                                        const data = JSON.parse(JSON.stringify(obj));
                                        data.result.type = 5
                                        data.result.data['str'] = this.refFooter.annotationImg
                                        logger.log("WHITEBOARD", '批注：发送给白板图片作为背景图', data)
                                        this.iframeWhiteboard.contentWindow.postMessage(JSON.stringify(data), whiteboardUrl);
                                    }
                                }
                            }
                        })
                        break
                    case 1:// 关闭白板
                        this.setState({ showWhiteboardMask: false })
                        this.refFooter && this.refFooter.setState({ currentShareMode: "", isWhiteboardCreator: false })
                        this.whiteboardData.closeWhiteboard(true)
                        this.iframeWhiteboard = null
                        const user = this.state.videoList.filter(x => x.userId === data.data.participantId)
                        let name = ""
                        if (user.length && user[0].local === false) {
                            name = user[0]._displayName
                        } else {
                            if (this.initDataWhiteboard.participantId === data.data.participantId) { // 用户先退出，服务器发来退出用户关闭白板
                                name = this.initDataWhiteboard.participantName
                            }
                        }
                        if (name) {
                            message.destroy("xxxOpensTheWhiteboard")
                            message.success({
                                content: i18n.t('message.xxxClosedTheWhiteboard', { name }),
                                icon: <span></span>,
                                style: {
                                    marginTop: '30vh', color: "rgba(61, 230, 47, 1)"
                                },
                                duration: 5,
                                className: "meet-custom-message",
                                key: "xxxOpensTheWhiteboard",
                            })
                        }
                        break
                    case 2:// 接收到参与人发来的数据
                        logger.log("WHITEBOARD", "收到服务端的数据", data)
                        this.iframeWhiteboard && this.iframeWhiteboard.contentWindow.postMessage(JSON.stringify(data.version === 2 ? data.data : {
                            type: "realMessage",
                            data: data.data
                        }), whiteboardUrl);
                        if (data.data.type !== "realTimeData") {
                            this.whiteboardData.setWhiteboardLocalStorage(data.data.data)
                        }
                        break
                    case 4: // 接收到历史数据
                        logger.log("WHITEBOARD", "收到服务端的历史数据", data)
                        this.iframeWhiteboard && this.iframeWhiteboard.contentWindow.postMessage(JSON.stringify(data.version === 2 ? {
                            type: "realMessage",
                            data: data.data.data
                        } : {
                            type: "realMessage",
                            data: data.data
                        }), whiteboardUrl);
                        if (data.data.type !== "realTimeData") {
                            this.whiteboardData.setWhiteboardLocalStorage(data.data.data)
                        }
                        break
                    case 3:// 更新流水号
                        this.iframeWhiteboard && this.iframeWhiteboard.contentWindow.postMessage(JSON.stringify(data.version === 2 ? data.data : {
                            type: "updateSerial",
                            data: data.data
                        }), whiteboardUrl);
                        if (data.data.type !== "realTimeData") {
                            this.whiteboardData.setWhiteboardLocalStorage(data.data.data)
                        }
                        break
                    default:
                        break;
                }
                break;
            case "toggleRemote":// 切换到远场
                this.refFooter && this.refFooter.goRemoteMeet()
                break;
            case "remoteUserInfo": //远场用户信息更新
                this.setState({ remoteLiveUserList: data })
                break;
            case "updateLiveStatus": // 直播状态
                this.setState({ liveStatus: data }, this.updateLiveLayoutInfo.bind(this))
                if (data === 1) {
                    const live = document.getElementById('live-setting-component')
                    if (live) live.style.zIndex = '-1'
                } else if (data === 2 && this.state.isLocalModerator) {
                    message.success({
                        content: i18n.t('liveBroadcastBegun'),
                        icon: <span></span>,
                        style: {
                            marginTop: '30vh',
                            color: "rgba(61, 230, 47, 1)"
                        },
                        duration: 4,
                        className: "meet-custom-message",
                        key: "myPermissionsKey"
                    })
                }
                break;
            case "liveLayoutInfo"://更新直播布局
                if (data) {
                    const isLiveStreaming = this.state.isLiveStreaming
                    this.setState({ isLiveStreaming: true }, () => {
                        if (isLiveStreaming === false) {
                            const live = document.getElementById('live-setting-component')
                            if (live) live.style.zIndex = '-1'
                        }
                    })
                    this.setState({ liveLayoutInfo: data }, this.updateLiveLayoutInfo.bind(this))
                }
                break;
            case "pushStreamAddress": //返回直播推流地址
                this.refJoinDevice && this.refJoinDevice.returnStreamAddress(data)
                break;
            case "previewStreamAddress": //返回预览连接
                this.refJoinDevice && this.refJoinDevice.previewStreamAddress(data)
                break;
            case "pictureInPicture": //主持人开启跟随
                this.openPictureInPictureWs(data)
                break;
            case "closePictureInPicture": //关闭跟随
                this.closePictureInPictureWs(data)
                break;
            default:
                break;
        }
    }
    // 初始化
    public init() {
        if (window.addEventListener) {
            window.addEventListener("unload", () => {
                this.unload()
            })
            window.addEventListener("beforeunload", () => {
                this.unload()
            })
        } else {
            window.attachEvent('onbeforeunload', () => {
                this.unload()
            })
        }
        // 噪音检测
        if (connectionOptions.enableNoisyMicDetection) {
            connectionOptions.createVADProcessor = createRnnoiseProcessor;
        }
        JitsiMeetJS.init(connectionOptions)
        JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.WARN)
        this.connection = new JitsiMeetJS.JitsiConnection(null, this.state.meetData.token, connectionOptions);
        this.connection.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
            this.onConnectionSuccess.bind(this));

        this.connection.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_FAILED, this.onConnectionFailed.bind(this));

        this.connection.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
            this.disconnect.bind(this));

        this.connection.connect();

        // 获取设备列表及监听设备变化
        getAvailableDevices().then(async devices => {
            await this.getNewMediaDevicesAfterDeviceListChanged(devices)
            this.deviceChangeListener = devices =>
                window.setTimeout(() => this._onDeviceListChanged(devices), 0);
            JitsiMeetJS.mediaDevices.addEventListener(
                JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED, this.deviceChangeListener);
        })
    }

    // 服务器连接失败
    public onConnectionFailed(err) {
        logger.error("CONNECTION", "CONNECTION_FAILED服务器连接失败", err, err.name)
        if (this.state.connection_failed) {
            this.setState({ waitingJoin: false })
        }
        this.unsubscribe()
        this.setState({ connection_failed: true })
        this._connectionFailedHandler(err)
    }

    // 连接失败
    public _connectionFailedHandler(error) {
        if (this.isFatalJitsiConnectionError(error)) {
            logger.warn("CONNECTION", "isFatalJitsiConnectionError", error)
            this.connection.removeEventListener(
                JitsiMeetJS.events.connection.CONNECTION_FAILED,
                this.onConnectionFailed.bind(this));
            if (this.room) {
                this.room.leave();
            }
        }
    }
    // 检查是否是严重错误
    public isFatalJitsiConnectionError(error: Object | string) {
        if (typeof error !== 'string') {
            error = error.name; // eslint-disable-line no-param-reassign
        }

        return (
            error === JitsiMeetJS.errors.connection.CONNECTION_DROPPED_ERROR
            || error === JitsiMeetJS.errors.connection.OTHER_ERROR
            || error === JitsiMeetJS.errors.connection.SERVER_ERROR);
    }


    public initConnection2() {
        connectionOptions['statisticsId'] = 'sv-desktop-' + this.state.user.primaryKey;
        connectionOptions['disableSimulcast'] = true;
        connectionOptions['channelLastN'] = 0; // 共享用户不拉视频流
        // connectionOptions['videoQuality']['preferredCodec'] = 'vp8';
        // connectionOptions['videoQuality']["enforcePreferredCodec"] = true;
        if (this.state.videoList.length === 1) {
            connectionOptions.p2p.enabled = false
        }
        this.connection2 = new JitsiMeetJS.JitsiConnection(null, null, connectionOptions);// 为添加第二条流
        this.connection2.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
            () => {
                const localUser = this.state.videoList.filter(x => x.local === true)
                this.room2 = this.connection2.initJitsiConference(this.state.meetData.primaryKey, connectionOptions); // 为添加第二条流
                this.room2.on(
                    JitsiMeetJS.events.conference.CONFERENCE_JOINED,
                    this.onConferenceJoined2.bind(this));
                this.room2.setDisplayName(localUser.length ? "userId-" + localUser[0].userId : "") // 为添加第二条流
                this.room2.setLocalParticipantProperty('userId', this.state.user.primaryKey)
                this.room2.setLocalParticipantProperty('userAvatar', this.state.user.avatar)
                this.room2.join()
                this.room2.on(
                    JitsiMeetJS.events.conference.KICKED, (user, code) => {
                        logger.info("ROOM", "共享屏幕KICKED", user, code)
                        if (code === kickCode) { // 主持人共享
                            this.refFooter && this.refFooter.setState({
                                shared: false, currentShareMode: ""
                            }, () => {
                                this.switchVideo(false) // 如果有共享关闭共享
                            })
                            const res = Modal.confirm({
                                title: null,
                                icon: null,
                                className: "cast-modal",
                                content: <div className="cast-modal-content" >
                                    <div>{i18n.t("meet.hostTurnedOffYourSharing")}</div>
                                    <div className="btns">
                                        <div className="btn1" onClick={() => {
                                            res.destroy()
                                        }}>{i18n.t("IKnow")}</div>
                                    </div>
                                </div>,
                            })
                        }
                    })
            });

        this.connection2.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_FAILED,
            (err) => { // 服务器连接失败
                logger.error("CONNECTION", "共享屏幕服务器连接失败", err)
            });
        this.connection2.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, this.disconnect2.bind(this));
        this.connection2.connect();
    }

    public _onDeviceListChanged(devices) {
        logger.log("BASE", "检测到新设备", devices)
        if (!devices.length) {
            return
        }
        // 检测到新设备
        const audiooutput = devices.filter(d => d.kind === 'audiooutput' && d.deviceId !== 'communications' && d.label.indexOf("Virtual") === -1);
        const videoinput = devices.filter(d => d.kind === 'videoinput');
        const audioinput = devices.filter(d => d.kind === 'audioinput' && d.deviceId !== 'communications' && d.label.indexOf("Virtual") === -1);
        if (audioinput.length > this.state.devices.audioinput.length) {
            let targetaudioinput;
            audioinput.forEach(x => {
                const tar = this.state.devices.audioinput.find(z => z.deviceId === x.deviceId)
                if (!tar) {
                    targetaudioinput = x
                }
            })
            if (this.state.settingParams.audioInput.deviceId !== "default") {
                notification.info({
                    key: "audio_noti",
                    message: <div>
                        <div>{i18n.t('meet.repeatNewAudioEquipment')}</div>
                        <div style={{ color: "#4d79f3", cursor: "pointer" }} onClick={() => {
                            notification.close("audio_noti")
                            this.setAudioInputDevice(targetaudioinput.deviceId)
                        }}>{i18n.t('meet.use')}</div>
                    </div>,
                    placement: "bottomLeft",
                    duration: 15,
                });
            } else {
                notification.info({ message: i18n.t('meet.systemDefaultAudioDevice'), placement: "bottomLeft", })
                this.setAudioInputDevice("default")
            }
        } else if (audioinput.length < this.state.devices.audioinput.length) {
            this.setAudioInputDevice(this.state.settingParams.audioInput.deviceId === "default" ? "default" : audioinput[0].deviceId)
        }
        if (audiooutput.length > this.state.devices.audiooutput.length) {
            if (JitsiMeetJS.mediaDevices.isDeviceChangeAvailable('output')) {
                JitsiMeetJS.mediaDevices.setAudioOutputDevice("default")
            }
            this.setState({
                settingParams: {
                    ...this.state.settingParams,
                    audioOutput: {
                        deviceId: "default"
                    }
                }
            })
        } else if (audiooutput.length < this.state.devices.audiooutput.length) {
            if (JitsiMeetJS.mediaDevices.isDeviceChangeAvailable('output')) {
                JitsiMeetJS.mediaDevices.setAudioOutputDevice("default")
            }
            this.setState({
                settingParams: {
                    ...this.state.settingParams,
                    audioOutput: {
                        deviceId: "default"
                    }
                }
            })
        }

        if (videoinput.length > this.state.devices.videoinput.length) {
            let targetvideoinput;
            videoinput.map(x => {
                const tar = this.state.devices.videoinput.find(z => z.deviceId === x.deviceId)
                if (!tar) {
                    targetvideoinput = x
                }
            })
            if (this.state.settingParams.videoInput.deviceId !== "default") {
                notification.info({
                    key: "video_noti",
                    message: <div>
                        <div>{i18n.t('meet.newCameraEquipmentDetected')}</div>
                        <div style={{ color: "#4d79f3", cursor: "pointer" }} onClick={() => {
                            notification.close("video_noti")
                            if (this.refFooter.state.video) {
                                this.setVideoInputDevice(targetvideoinput.deviceId)
                            } else {
                                this.setState({
                                    settingParams: {
                                        ...this.state.settingParams,
                                        videoInput: {
                                            deviceId: targetvideoinput.deviceId
                                        }
                                    }
                                })
                            }
                        }}>{i18n.t('meet.use')}</div>
                    </div>,
                    placement: "bottomLeft",
                    duration: 15,
                });
            } else {
                notification.info({ message: i18n.t('meet.systemDefaultCameraDevice'), placement: "bottomLeft" })
            }
        } else if (videoinput.length < this.state.devices.videoinput.length) { // 拔掉设备
            this.setState({
                settingParams: {
                    ...this.state.settingParams,
                    videoInput: {
                        deviceId: this.state.settingParams.videoInput.deviceId === "default" ? "default" : videoinput[0]?.deviceId
                    }
                }
            })
            if (this.refFooter.state.video) {
                this.setVideoInputDevice(this.state.settingParams.videoInput.deviceId === "default" ? "default" : videoinput[0]?.deviceId)
            }
        }

        this.setState({
            devices: {
                audiooutput,
                videoinput,
                audioinput
            }
        })
    }

    public getNewMediaDevicesAfterDeviceListChanged(devices) {
        logger.log("BASE", '更新设备列表', devices)
        const audiooutput = devices.filter(d => d.kind === 'audiooutput' && d.deviceId !== 'communications' && d.label.indexOf("Virtual") === -1);
        const videoinput = devices.filter(d => d.kind === 'videoinput');
        const audioinput = devices.filter(d => d.kind === 'audioinput' && d.deviceId !== 'communications' && d.label.indexOf("Virtual") === -1);
        this.setState({
            devices: {
                audiooutput,
                videoinput,
                audioinput
            }
        })
    }

    // 该函数在连接成功建立时被调用
    public onConnectionSuccess() {
        logger.info("CONNECTION", "onConnectionSuccess", connectionOptions)
        this.setState({ connection_failed: false, customExceptionConnection: false })
        this.room = this.connection.initJitsiConference(this.state.meetData.primaryKey, connectionOptions);
        this.room.on(JitsiMeetJS.events.conference.TRACK_ADDED, this.onRemoteTrack.bind(this));
        this.room.on(JitsiMeetJS.events.conference.TRACK_REMOVED, track => {
            logger.info("TRACK", `轨道离开track removed!!!`, track, track.isLocal());
            const participantId = track.getParticipantId();
            if (!track.isLocal()) {
                let index = -1;
                let participant = null
                this.state.videoList.forEach((node) => {
                    if (node.participantId === participantId) {
                        participant = node
                        node.list.forEach((x, ind) => {
                            if (track.type === x.mediaType) {
                                index = ind;
                                if (track.type === "audio") {
                                    node.muted = true
                                } else if (track.type === "video") {
                                    node.video = false;
                                    if (track.videoType === "desktop") {
                                        node.sharedUser = false
                                    }
                                }
                            }
                        })
                        if (index !== -1) {
                            node.list.splice(index, 1)
                        }
                    }
                })
                this.setState({ videoList: this.state.videoList })
                videoLayout.removeVideoOrAudioElement(track, participant)

                if (track.getType() === "audio") {
                    if (this.refFooter && (this.refFooter.state.recordScreen || this.refFooter.state.recordAudio)) {
                        disconnectAudioContext(participantId)
                    }
                }
            }
        });
        this.room.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, this.onConferenceJoined.bind(this));
        this.room.on(JitsiMeetJS.events.conference.USER_JOINED, (id, user) => {
            let statsID = user.getStatsID()
            logger.info("ROOM", '用户加入', id, user, statsID);
            this.sendTranslationLanguageCommand(this.state.currentTranslationLang, this.room.myUserId()) //告诉后加入的参会人我的字幕语言
            if (statsID === "$invisible$oversee$" || user.isHidden() === true) { // 用户后台隐藏用户的特殊标识
                return
            }
            let realName = "";
            let local = false;
            let sharedUser = false;
            let shareScreenWatermark = false;
            let _displayName = user.getDisplayName()
            let isDevice: any = "";// 加入的是不是设备
            let deviceShareId = 0// 加入设备的ID
            if (statsID.indexOf('device-share-360') > -1) {
                isDevice = "360"
                deviceShareId = Number(statsID.split('-')[3])
            } else if (statsID.indexOf('device-share-ipcamera') > -1) {
                isDevice = "ipcamera"
                deviceShareId = Number(statsID.split('-')[3])
            }
            if (_displayName && _displayName.indexOf("userId-") > -1) {
                sharedUser = true;
                const userTarget = this.state.videoList.filter(x => x.userId === _displayName.split("-")[1]);
                if (userTarget.length) {
                    realName = userTarget[0]._displayName
                    local = userTarget[0].local;
                    shareScreenWatermark = userTarget[0].shareScreenWatermark;
                }
            }
            const moderator = this.room.getParticipantById(id).isModerator()
            const userdata: IParticipantItem = {
                userId: user._id,
                _displayName: _displayName || "",
                local: local,
                participantId: id,
                muted: true,
                moderator,
                list: [],
                realName: realName ? i18n.t('meet.sharingOfName', { name: realName }) : "",
                sharedUser,
                stats: {},
                speaker: false,
                businessUserId: statsID ? statsID.split("sv-user-")[1] || statsID.split("sv-tv-")[1] : "",
                avatar: user._properties.userAvatar || "",
                translationLanguageText: "",
                speeching: false,
                shareScreenWatermark,
                isDevice,
                deviceShareId
            }
            this.state.videoList.push(userdata)

            if (!sharedUser) { // 共享用户在用户本身之前进入时
                const target = this.state.videoList.filter(z => id === z._displayName.split("-")[1])
                if (target.length) {
                    target[0].realName = i18n.t('meet.sharingOfName', { name: _displayName });
                    videoLayout.setDisplayName(target[0])
                }
            } else {
                this.state.currentShareScreenUserId = id;
                // this.state.videoList.forEach(ele => {
                //     if (id === ele.userId) {
                //         ele.speaker = true;
                //     } else {
                //         ele.speaker = false
                //     }
                // })
                this.speakerChanged(id)
            }
            this.setState({ videoList: this.state.videoList, currentShareScreenUserId: this.state.currentShareScreenUserId }, () => {
                if (this.state.videoList.length > 2) {
                    this.refSpeakerMode.setState({ navMode: "all" }, () => {
                        this.updateAddTrackNode()
                        videoLayout.removeTopLeftUserContainer()
                    })
                } else {
                    this.speakerChanged(id)
                    if (this.state.videoList.length === 2) {
                        videoLayout.addSpeakerUserContainer({ userdata })
                    }
                }
                videoLayout.addRemoteUserContainer(userdata, this.state.videoList, this.state.mode, this.containerClickChange.bind(this), this.switchSpeaker.bind(this))
                if (this.state.mode === "pictureInPicture") {
                    const pictureInPictureData = this.props.pictureInPictureData
                    // 小于三人时并且我是主持人并且画中画跟随开启下需要更新画中画跟随数据
                    if (pictureInPictureData && this.state.videoList.length <= 3 && this.state.isLocalModerator && pictureInPictureData.switch) {
                        if (this.state.videoList.length === 2 && (pictureInPictureData.template === 1 || pictureInPictureData.template === 2)) {
                            this.updatePictureInPictureUser(true)
                        } else if (this.state.videoList.length === 3 && (pictureInPictureData.template === 3 || pictureInPictureData.template === 4)) {
                            this.updatePictureInPictureUser(true)
                        } else {
                            this.updatePictureInPictureUser()
                        }
                    } else {
                        this.updatePictureInPictureUser()
                    }
                }
            })
            logger.info("ROOM", '用户列表更新', this.state.videoList);
            if (this.refFooter.state.switchSubtitles && this.state.meetData.transliteration && this.state.isLocalModerator) {// 我是主持人并且开启了字幕请客模式，发广播让其他用户都开启字幕
                this.sendSubtitleServiceCommand(true)
            }
        });
        this.room.on(JitsiMeetJS.events.conference.USER_LEFT, this.onUserLeft.bind(this));
        this.room.on(JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED, (track, participantThatMutedUs) => {
            logger.info("TRACK", "TRACK_MUTE_CHANGED", `${track.getType()} - ${track.isMuted()}`, participantThatMutedUs);
            if (participantThatMutedUs) { // 参会者被静音
                this.participantMutedUs()
            } else {
                this.trackMuteChange(track)
            }
        });
        this.room.on(
            JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED,
            (userID, displayName) => { // 用户已更改自己名称
                logger.info("ROOM", "用户已更改自己名称", `${userID} - ${displayName}`)
                this.state.videoList.forEach(user => {
                    if (user.userId === userID) {
                        user._displayName = displayName;
                        videoLayout.setDisplayName(user)
                    }
                })
                this.state.videoList.forEach(user => {
                    if (user.sharedUser) {
                        const target = this.state.videoList.filter(z => z.userId === user._displayName.split("-")[1])
                        if (target.length) {
                            user.realName = i18n.t('meet.sharingOfName', { name: target[0]._displayName })
                            videoLayout.setDisplayName(user)
                        }
                    }
                })

                this.setState({ videoList: this.state.videoList })
            });
        this.room.on(
            JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED,
            async (id, audioLevel) => {
                videoLayout.setAudioLevel(id, audioLevel)
                let { participantAudioLevel } = this.state;
                const target = this.state.videoList.filter(x => x.userId === id)
                logger.debug("TRACK", "TRACK_AUDIO_LEVEL_CHANGED", id, audioLevel, participantAudioLevel)
                if (!target.length) return
                if (!target[0].local && target[0].audioSsrc) {
                    let r = await Array.from(this.room.rtc.peerConnections).map(m => m[1])[0].getStats()
                    let e = r.entries()
                    let es = []
                    for (let i = 0; i < r.size; i++) {
                        es.push(e.next())
                    }
                    es = es.map(ee => ee.value[1])
                    es = es.filter(e => new RegExp(target[0].audioSsrc).test(e.id) && e.type === "inbound-rtp")
                    if (es.length && es[0].audioLevel === 0) {
                        console.log(target[0]._displayName, es[0].audioLevel)
                        audioLevel = es[0].audioLevel
                    }
                }
                if (audioLevel === 0 && target[0].muted === false) {
                    if (participantAudioLevel.hasOwnProperty(id) && participantAudioLevel[id]) {
                        logger.log('TRACK', "TRACK_AUDIO_LEVEL_CHANGED已经存在", target[0].participantId, target[0]._displayName, "静音：" + target[0].muted, "本地：" + target[0].local)
                        // if (participantAudioLevel[id].timer) return// 已经出现过0，时间窗口还没消除
                        // 20秒后audioLevel还是0并且audio的download(bytesReceived_in_bits)并且这条流没静音
                        // if (target[0].local) { //本地audioLevel为0,启动重连
                        //     logger.warn("audioLevel本地启动重连")
                        //     if (target[0].stats.bitrate) {
                        //         const audio = target[0].stats.bitrate.audio
                        //         logger.warn("swntech", target[0]._displayName, audio.download)
                        //         if (audio.download > 3) {// 说明有声音，只是编解码出了问题
                        //             // this.reconnectConference()
                        //         }
                        //     }
                        // } else {// 听不到远端用户的
                        //     console.log("audioLevel远端重连", target[0].muted, target[0].userId)
                        //     if (target.length && target[0].muted === false) {
                        //         const jitsiTrack = target[0].list.filter(x => x.mediaType === "audio")
                        //         console.log(jitsiTrack)
                        //         if (jitsiTrack.length) {
                        //             const node = document.getElementById('remoteAudio_' + target[0].userId)
                        //             jitsiTrack[0].jitsiTrack.detach(node) //重新加载audio标签
                        //             const timer1 = setTimeout(() => {
                        //                 clearTimeout(timer1)
                        //                 logger.warn('audioLevel远端重连1')
                        //                 jitsiTrack[0].jitsiTrack.attach(node)
                        //             }, 600)
                        //             delete participantAudioLevel.id
                        //         }
                        //         if (target[0].stats.bitrate) {
                        //             const audio = target[0].stats.bitrate.audio
                        //             logger.warn("swntech", target[0]._displayName, audio.download)
                        //             if (audio.download > 3) {// 说明有声音，只是编解码出了问题
                        //             }
                        //         }
                        //     }
                        // }
                    } else {
                        logger.debug('TRACK', target[0].participantId, target[0]._displayName, "静音：" + target[0].muted, "本地：" + target[0].local)
                        if (target[0].local || target[0].muted) return // 静音不处理
                        const timer = setInterval(() => {
                            // 每延迟20秒如果还是0，那需要重新加载audio标签
                            console.log("20秒了", target[0].userId, participantAudioLevel)
                            // 处理：audiolevel为0的情况
                            const audioTar = target[0].list.filter(x => x.mediaType === "audio")
                            if (audioTar.length) {
                                this.setAudioDom(target[0].userId, false, 0, audioTar[0].jitsiTrack)
                            }
                        }, 15000)
                        participantAudioLevel[id] = {
                            audioLevel: audioLevel,
                            timer: timer,
                        }
                    }
                } else {
                    if (participantAudioLevel.hasOwnProperty(id)) {
                        console.log("清除audiolevel", id, participantAudioLevel[id])
                        participantAudioLevel[id].timer && clearInterval(participantAudioLevel[id].timer)
                        delete participantAudioLevel[id]
                    }
                }
                this.setState({ participantAudioLevel })
                this.pictureInPicturevoiceExcitationTimer()
            });
        this.room.on(
            JitsiMeetJS.events.conference.DOMINANT_SPEAKER_CHANGED,
            (id) => {// 发言人已更改
                const user = this.state.videoList.filter(x => x.userId === id)
                console.log(new Date(), "swntech", "发言人已更改", id, user);
                if (this.refSpeakerMode && !this.refSpeakerMode.state.currentPinUserId) { // pin权限最高
                    // 如果是自己就不切换主讲了
                    if (user.length && !user[0].local && this.state.videoList.length > 2) {
                        // 共享时不切
                        if (!user[0].sharedUser && !this.state.currentShareScreenUserId) {
                            this.speakerChanged(id)
                        }
                    }
                }
                this.state.videoList.map(x => {
                    if (x.userId === id) {
                        x.voiceIncentive = true;
                    } else {
                        x.voiceIncentive = false;
                    }
                    videoLayout.setAudioMutedIcon(x, this.state.mode)
                })
                this.setState({ videoList: this.state.videoList }, () => {
                    this.updatePictureInPictureUser()
                })
            });

        this.room.on(
            JitsiMeetJS.events.conference.START_MUTED_POLICY_CHANGED,
            (obj) => {
                logger.info("ROOM", "通知所有新参与者将加入静音音频", obj)
                if (this.state.isLocalModerator) {
                    return
                }
                if (obj.audio) {
                    notification.info({
                        message: i18n.t('dialog.moderatorSetsMute'),
                        placement: "bottomLeft",
                    });
                    this.setLacalMute(true)
                    this.refFooter.setState({
                        muted: true
                    })
                }
                if (obj.video) {
                    notification.info({
                        message: i18n.t('dialog.moderatorSetsCamera'),
                        placement: "bottomLeft",
                    });
                    this.switchVideo(false)
                    this.setState({
                        settingParams: {
                            ...this.state.settingParams,
                            camera: false
                        }
                    })
                    this.refFooter.setState({
                        video: false
                    })
                }
            });

        this.room.on(
            JitsiMeetJS.events.conference.STARTED_MUTED,
            () => {
                const audioMuted = this.room.isStartAudioMuted();
                const videoMuted = this.room.isStartVideoMuted();
                logger.info("ROOM", "通知本地用户已开始静音", audioMuted, videoMuted)
                const target = this.state.videoList.filter(x => x.local === true)
                if (target.length) {
                    let index = -1;
                    target[0].list.forEach(async (n, inx) => {
                        if (audioMuted && n.mediaType === "audio" && !JitsiMeetJS.util.browser.isWebKitBased()) {
                            try {
                                message.success({
                                    content: i18n.t('dialog.youHaveBeenMuted'),
                                    icon: <span></span>,
                                    style: {
                                        marginTop: '30vh',
                                    },
                                    duration: 5,
                                    className: "meet-custom-message",
                                    key: "youHaveBeenMuted"
                                })
                                index = inx
                                await this.room.replaceTrack(n.jitsiTrack, null)
                            } catch (e) {
                                logger.debug("ROOM", "audio", e)
                                const timer = setTimeout(() => {
                                    this.room.replaceTrack(n.jitsiTrack, null)
                                    clearTimeout(timer)
                                }, 1000)
                            } finally {
                                if (index > -1) {
                                    target[0].list.splice(index, 1)
                                }
                                this.setState({ videoList: this.state.videoList })
                                this.refFooter && this.refFooter.setState({
                                    muted: true
                                })
                            }
                        }
                    })
                    try {
                        let indexV = -1
                        target[0].list.forEach(async (n, inx) => {
                            if (videoMuted && n.mediaType === "video") {
                                await this.room.replaceTrack(n.jitsiTrack, null)
                                indexV = inx
                                this.refFooter && this.refFooter.switchVideo()
                                if (indexV > -1) {
                                    target[0].list.splice(indexV, 1)
                                }
                                this.setState({ videoList: this.state.videoList })
                            }
                        })
                    } catch (e) {
                        logger.debug("ROOM", e)
                    }
                }
            });

        this.room.on(
            JitsiMeetJS.events.conference.CONFERENCE_LEFT,
            (e) => {
                logger.info("ROOM", "通知本地用户已成功离开会议", this.state.whetherReconnect, e)
                if (this.state.whetherReconnect) {
                    this.connection.disconnect()
                    this.state.videoList = this.state.videoList.filter(x => !x.local || x.sharedUser)
                    this.init()
                } else {
                    this.disconnect()
                }
            });

        this.room.on(
            JitsiMeetJS.events.conference.USER_STATUS_CHANGED,
            (e) => logger.info("ROOM", "通知某些用户的状态已更改", e));

        this.room.on(
            JitsiMeetJS.events.conference.BOT_TYPE_CHANGED,
            (e) => logger.info("ROOM", "通知某些用户的", e));

        this.room.on(
            JitsiMeetJS.events.conference.CONFERENCE_FAILED,
            (errorCode, params) => {
                logger.warn("ROOM", "通知用户未能加入会议", errorCode, params)
                conference._onConferenceFailed(errorCode, this, params)
                this.setState({ loading: false })
            });

        this.room.on(
            JitsiMeetJS.events.conference.CONFERENCE_ERROR,
            (errorCode) => {
                logger.warn("ROOM", "通知发生错误")
                conference._onConferenceFailed(errorCode, this)
            });

        this.room.on(JitsiMeetJS.events.conference.AUTH_STATUS_CHANGED, (isAuthEnabled, authIdentity) => {
            logger.info("ROOM", 'External authentication enabled:', isAuthEnabled, authIdentity)
        })

        this.room.on(
            JitsiMeetJS.events.conference.ENDPOINT_MESSAGE_RECEIVED,
            (e, data) => {
                logger.debug('ROOM', "通知在数据通道上接收到来自另一个参与者的新消息", e, data)
                // if (data.type !== 'e2e-ping-request' && data.type !== 'stats' || data.type !== 'e2e-ping-response')
            });

        this.room.on(
            JitsiMeetJS.events.conference.TALK_WHILE_MUTED,
            (e) => {
                logger.info("ROOM", "通知本地用户在麦克风静音时正在讲话", e)
                if (this.refFooter.state.muted) {
                    notification.info({
                        message: <div>
                            <div>{i18n.t('dialog.talkWhileMutedPopup')}</div>
                            <div style={{ color: "#4d79f3", cursor: "pointer" }} onClick={() => {
                                if (this.state.myPermission.speak === 1) { // 需举手
                                    this.raiseHand(true)
                                    this.refFooter && this.refFooter.raiseHandHandle(true)
                                } else { // 取消静音
                                    this.setLacalMute(false)
                                }
                                notification.close('talkWhileMutedPopup')
                            }}>{i18n.t(this.state.myPermission.speak === 1 ? "raiseHands" : 'meet.unmute')}</div>
                        </div>,
                        placement: "bottomLeft",
                        duration: 15,
                        key: "talkWhileMutedPopup"
                    });
                }
            });

        this.room.on(
            JitsiMeetJS.events.conference.NO_AUDIO_INPUT, this.noAudioInput.bind(this));

        this.room.on(
            JitsiMeetJS.events.conference.AUDIO_INPUT_STATE_CHANGE, (hasAudioInput) => {
                // 如果显示通知但会议检测到音频输入信号，我们将其隐藏。
                logger.info("ROOM", "通知当前会议音频输入在音频输入状态之间切换，即有或没有音频输入", hasAudioInput)
                // 把NO_AUDIO_INPUT记录的ID关了
                if (hasAudioInput) {
                    this.noAudioSignalNotificationKey && notification.close(this.noAudioSignalNotificationKey)
                    this.noAudioSignalNotificationKey = null
                }
            })

        this.room.on(
            JitsiMeetJS.events.conference.NOISY_MIC, () => {
                console.log(new Date(), "ROOM", "麦克风有噪音")
                notification.info({
                    message: <div>
                        <div>{i18n.t("dialog.noisyAudioInputTitle")}</div>
                        <div style={{ fontSize: 13 }}>{i18n.t('dialog.noisyAudioInputDesc')}</div>
                    </div>,
                    placement: "bottomLeft",
                    duration: 15,
                });
            }
        )

        this.room.on(
            JitsiMeetJS.events.conference.PARTICIPANT_PROPERTY_CHANGED,
            (participant, propertyKey, oldValue, newValue) => {
                console.log(new Date(), "ROOM", "通知用户已更改其自定义参与者属性", participant, propertyKey, oldValue, newValue)
                const target = this.state.videoList.filter(x => x.participantId === participant._id)
                if (target.length && propertyKey === "userAvatar") { // 设置头像
                    target[0].avatar = newValue;
                    this.setState({ videoList: this.state.videoList })
                    videoLayout.setAvatar(target[0])
                }
            });

        this.room.on(
            JitsiMeetJS.events.conference.USER_ROLE_CHANGED, this.onUserRoleChange.bind(this));

        this.room.on(JitsiMeetJS.events.conference.LAST_N_ENDPOINTS_CHANGED, (leavingIds, enteringIds) => {
            logger.debug("ROOM", 'LAST_N_ENDPOINTS_CHANGED', leavingIds, enteringIds)
            videoLayout.onLastNEndpointsChanged(leavingIds, enteringIds, this.state.videoList);
        })

        this.room.on(JitsiMeetJS.events.conference.CONNECTION_INTERRUPTED, async (e) => {
            logger.error("ROOM", '本地连接中断', e)
            this.setState({ connectionActive: false }, () => {
                const timer = setTimeout(() => {
                    timer && clearTimeout(timer)
                    if (!this.state.connectionActive) { // 连接未恢复
                        this.setState({ connection_failed: true, customExceptionConnection: true })
                    }
                }, 10000)
            })
        })

        this.room.on(JitsiMeetJS.events.conference.CONNECTION_RESTORED, (e) => {
            logger.warn("ROOM", '本地连接活跃', e)
            if (!this.state.connectionActive) {
                logger.warn("ROOM", '超过十秒重连了哈')
                this.setState({ connectionActive: true })
            }
        })

        this.room.on(JitsiMeetJS.events.conference.DATA_CHANNEL_OPENED, (e) => {
            console.log(new Date(), "swntech", 'DATA_CHANNEL_OPENED', e)
        })

        this.room.on(
            JitsiMeetJS.events.conference.BOT_TYPE_CHANGED,
            (e) => logger.info("ROOM", "通知某些用户的", e));

        this.room.on(
            JitsiMeetJS.events.conference.PARTICIPANT_KICKED, (kicker, kicked) => {
                logger.info("ROOM", '参与者被踢', kicker, kicked)
            });

        this.room.on(
            JitsiMeetJS.events.conference.SUSPEND_DETECTED, (e) => {
                // 需要注意下处理
                logger.info("ROOM", 'SUSPEND_DETECTED', e)
            });

        this.room.on(
            JitsiMeetJS.events.conference.MESSAGE_RECEIVED, (id, message, timestamp, nick) => {
                console.log(id, message, timestamp, nick)
                const target = this.state.videoList.filter(x => x.userId === id || x.participantId === id)
                const { chatMessage } = this.state;
                chatMessage.push({
                    avatar: target.length ? target[0].avatar : "",
                    userId: id,
                    userName: target.length ? (target[0].realName || target[0]._displayName) : id,
                    message,
                    timestamp: moment(timestamp).format("MM-DD HH:mm"),
                    privateMessage: this.room.myUserId() === id ? true : false
                })
                this.setState({
                    chatMessage,
                }, () => {
                    const timer = setTimeout(() => {
                        this.refChatMessage && this.refChatMessage.scrollTop();
                        clearTimeout(timer)
                    }, 200)
                    this.refFooter && this.refFooter.setState({ showChatMessage: true })
                })
            }
        )

        this.room.on(
            JitsiMeetJS.events.conference.KICKED, (user, code) => {
                logger.info("ROOM", "通知用户已被踢出会议", user, code)
                if (window.location.hash.indexOf("/meet") === -1) {
                    return
                }
                if (code === '11005') {//人数已满
                    const res = Modal.confirm({
                        title: null,
                        icon: null,
                        className: "cast-modal",
                        content: <div className="cast-modal-content" >
                            <div>{i18n.t("meet.meetingIsFull")}</div>
                            <div className="btns">
                                <div className="btn1" onClick={() => {
                                    this.refFooter && this.refFooter.leavePage()
                                    res.destroy()
                                }}>{i18n.t("IKnow")}</div>
                            </div>
                        </div>,
                    })
                } else if (code === kickCode) { // 主持人踢人
                    const res = Modal.confirm({
                        title: null,
                        icon: null,
                        className: "cast-modal",
                        content: <div className="cast-modal-content" >
                            <div>{i18n.t("meet.kickedOutMeeting")}</div>
                            <div className="btns">
                                <div className="btn1" onClick={() => {
                                    this.refFooter && this.refFooter.leavePage()
                                    res.destroy()
                                }}>{i18n.t("IKnow")}</div>
                            </div>
                        </div>,
                    })
                } else if (code === "11016") {//重复入会
                    const res = Modal.confirm({
                        title: null,
                        icon: null,
                        className: "cast-modal",
                        content: <div className="cast-modal-content" >
                            <div>{i18n.t("repeatJoining")}</div>
                            <div className="btns">
                                <div className="btn1" onClick={() => {
                                    this.refFooter && this.refFooter.leavePage()
                                    res.destroy()
                                }}>{i18n.t("IKnow")}</div>
                            </div>
                        </div>,
                    })
                } else {
                    if (this.refFooter && this.refFooter.state.shared) {
                        this.refFooter.setState({
                            shared: false
                        }, () => {
                            this.switchVideo(false) // 如果有共享关闭共享
                        })
                    }
                    const res = Modal.confirm({
                        title: null,
                        icon: null,
                        className: "cast-modal",
                        content: <div className="cast-modal-content" >
                            <div>{i18n.t("meet.meetingHasEnded")}</div>
                            <div className="btns">
                                <div className="btn1" onClick={() => {
                                    this.refFooter && this.refFooter.leavePage()
                                    res.destroy()
                                }}>{i18n.t("IKnow")}</div>
                            </div>
                        </div>,
                    })
                }
                try {
                    this.stopMeet()
                    meetWebsocket.close()
                } catch (e) {
                    logger.debug('ROOM', e)
                }
            }
        )

        this.room.on(JitsiMeetJS.events.connectionQuality.LOCAL_STATS_UPDATED, (stats) => {
            const target = this.state.videoList.filter(x => x.local)
            if (target.length) {
                resolutionData = stats.resolution;
                logger.debug("STATS", "本地stats", stats)
                target[0].stats = {
                    ...target[0].stats,
                    packetLoss: stats.packetLoss,
                    bitrate: stats.bitrate,
                    resolution: stats.resolution[target[0].userId],
                    maxEnabledResolution: stats.maxEnabledResolution,
                    transport: stats.transport,
                    bandwidth: stats.bandwidth,
                    bridgeCount: stats.bridgeCount,
                    e2eRtt: stats.e2eRtt,
                    connectionQuality: stats.connectionQuality
                }
                // if (stats.packetLoss && (stats.packetLoss.download > 7 || stats.packetLoss.upload > 7)) {
                //     logger.debug('STATS', "swntech本地", `丢包：↓${stats.packetLoss.download}% ↑${stats.packetLoss.upload}%`, `估计宽带：↓${stats.bandwidth.download}Kbps ↑${stats.bandwidth.upload}Kbps`, `连接状态：${stats.connectionQuality}`)
                // }
                target[0].list.forEach(item => {
                    if (item.mediaType === "audio") {
                        target[0].stats.audioSsrc = this.room.getSsrcByTrack(item.jitsiTrack)
                    } else if (item.mediaType === "video") {
                        target[0].stats.videoSsrc = this.room.getSsrcByTrack(item.jitsiTrack)
                    }
                })

                this.state.videoList.map(x => {
                    if (stats['framerate'] && stats['framerate'].hasOwnProperty(x.userId)) {
                        x.stats["framerate"] = stats.framerate[x.userId]
                    }
                })
                const signalLevel = Math.floor(stats.connectionQuality / 33.4);
                if (this.assistance && this.assistance.showVideoModal && signalLevel < 2 && !this.connectQualityDelayConfirm) {
                    notification.open({
                        onClose: () => {
                            this.connectQualityDelayConfirm = true
                            const timer = setTimeout(() => { // 5分钟后如果网络还不好，需要再次提示
                                clearTimeout(timer)
                                this.connectQualityDelayConfirm = false
                            }, 300000)
                        },
                        message: <div className="message">
                            <div className="title">{i18n.t('dialog.connectionQualityOperationDelay')}</div>
                        </div>,
                        className: "meet-subtitle-notification meet-quality-notification",
                        placement: "topRight",
                        duration: null,
                        key: 'connectionQualityOperationDelay',
                        top: '24px'
                    })
                }
                if (this.refFooter && this.refFooter.state.bandwidthQuality === null) {
                    if (signalLevel === 0) { // 带宽差：// 不展示视频
                        this.setReceiverConstraints([], 0)
                        this.networkConditionsMessage()
                    } else if (signalLevel === 1) { // 带宽一般://只展示主持人的视频
                        this.setReceiverConstraints([], 1)
                        this.networkConditionsMessage()
                    } else {// 优
                        this.setReceiverConstraints()
                    }
                }
            }
        })
        this.room.on(
            JitsiMeetJS.events.connectionQuality.REMOTE_STATS_UPDATED, (id, stats) => {
                const target = this.state.videoList.filter(x => x.userId === id)
                if (target.length) {
                    logger.debug("STATS", "远端stats", target[0]._displayName, stats)
                    const list = target[0].list.filter(x => x.mediaType === "video")
                    let resolution = {};
                    if (list.length) {
                        const obj = resolutionData[id]
                        if (obj) {
                            resolution = obj
                        } else {
                            resolution = {
                                [list[0].jitsiTrack.ssrc]: list[0].jitsiTrack ? list[0].jitsiTrack.track.getSettings() : {}
                            }
                        }
                        const settings = resolution[list[0].jitsiTrack.ssrc]
                        if (settings) {
                            target[0].videoWidth = settings.width
                            target[0].videoHeight = settings.height
                            this.assistance && this.assistance.setVideoStyle(target[0])
                        }
                    }
                    target[0].stats = {
                        ...target[0].stats, ...{
                            packetLoss: stats.packetLoss,
                            bitrate: stats.bitrate,
                            resolution: resolution,
                            maxEnabledResolution: stats.maxEnabledResolution,
                            transport: stats.transport,
                            bandwidth: stats.bandwidth,
                            framerate: target[0].stats['framerate'] ? target[0].stats.framerate : stats.framerate,
                            bridgeCount: stats.bridgeCount,
                            serverRegion: stats.serverRegion,
                            connectionQuality: stats.connectionQuality
                        }
                    }
                    // if (stats.packetLoss && (stats.packetLoss.download > 7 || stats.packetLoss.upload > 7)) {
                    //     logger.debug('STATS', "远端", target[0]._displayName, `丢包：↓${stats.packetLoss.download}% ↑${stats.packetLoss.upload}%`, `比特率：↓${stats.bitrate.download}Kbps ↑${stats.bitrate.upload}Kbps`, `端到端时延：${stats.e2eRtt ? stats.e2eRtt.toFixed(0) : ""}ms`, `连接状态：${stats.connectionQuality}`)
                    // }
                    this.setState({ videoList: this.state.videoList })
                }
            }
        )

        this.room.on(
            JitsiMeetJS.events.e2eping.E2E_RTT_CHANGED, (participant, e2eRtt) => {
                // console.log('E2E_RTT_CHANGED', participant, e2eRtt)
                const target = this.state.videoList.filter(x => x.userId === participant.getId())
                if (target.length) {
                    target[0].stats = {
                        ...target[0].stats,
                        e2eRtt
                    }
                    this.setState({ videoList: this.state.videoList })
                }
            }
        )

        this.room.on(
            JitsiMeetJS.events.connection.WRONG_STATE, () => {
                logger.info("ROOM", "指示用户执行了由于连接处于错误状态而无法执行的操作")
            }
        )

        this.room.on(
            JitsiMeetJS.errors.conference.CONFERENCE_FAILED, conference._onConferenceFailedErrors)

        this.room.on(
            'conference.jvb_websocket.error', (code, reason) => { // 自定义的事件
                logger.error('CONNECTION', 'websocket.error', code, reason)
                if (code === 1006) {
                    this.setState({ connection_failed: true, customExceptionConnection: true })
                }
            })

        this.room.addCommandListener(CLOSE_CAMERA_COMMAND, (res) => {// 监听摄像头是否被主持人要求关闭
            if (this.state.isLocalModerator || !this.refFooter.state.video) return;
            if (res.value === "all") {
                this.switchVideo(false)
                this.refFooter.setState({ video: false })
            } else {
                if (this.room.myUserId() === res.attributes.userId) {
                    this.switchVideo(false)
                    this.refFooter.setState({ video: false })
                } else {
                    return
                }
            }
            notification.info({
                message: i18n.t('dialog.youHaveBeenClosed'),
                placement: "bottomLeft",
            });
        })

        this.room.addCommandListener(OPEN_CLOSE_MEDIA_RECORDER, (res) => {// 监听是否开启录屏提示
            if (res.attributes.participantId === this.room.myUserId()) return;
            this.setState({ currentMediaRecorderParticipantId: res.attributes.participantId })
            if (res.value === "true") {
                videoLayout.mediaRecorderPromptUI({ ...res.attributes, status: true })
            } else {
                videoLayout.mediaRecorderPromptUI({ ...res.attributes, status: false })
            }
        })

        this.room.addCommandListener(SWITCH_TRANSLATION_LANGUAGE, (res) => { //监听参与人字幕语言修改
            // if (res.attributes.participantId === this.room.myUserId()) return;
            if (!res.value) return
            const target = this.state.videoList.filter(x => x.userId === res.attributes.participantId)
            if (target.length) {
                target[0].translationLanguage = res.value;
                target[0].translationLanguageText = Object.keys(this.refFooter.state.language).length ? this.refFooter.state.language[res.value].title : "";
                videoLayout.setDisplayName(target[0])
                if (target[0].local) { // 主持人改了我
                    this.setState({ currentTranslationLang: res.value })
                }
                this.setState({ videoList: this.state.videoList })
            }
        })

        this.room.addCommandListener(SWITCH_SUBTITLE_SERVICE, (res) => { // 监听主持人是否要开启字幕
            if (res.attributes.participantId === this.room.myUserId()) return;
            if (res.value === "true") {
                if (!this.refFooter.state.switchSubtitles) {
                    if (this.state.currentTranslationLang) { // 必须设置了默认语言再开启
                        this.showSubtitleServiceModal()
                        if (this.refFooter.state.subtitlesSwitchLoading) {
                            summary.speechServerWebSocket(this.state.meetData.primaryKey, this.state.user.primaryKey, this.onMessageSpeechServer.bind(this), this.summarySocketAvailable.bind(this))
                        } else {
                            summary.openOrCloseSummary(true, this.state.currentTranslationAcccount, this.state.currentTranslationLang || this.refFooter.state.currentLanguage)
                            this.refFooter && this.refFooter.setState({ switchSubtitles: true })
                            const timer = setTimeout(() => {
                                clearTimeout(timer)
                                this.subtitleModal && this.subtitleModal.destroy()
                            }, 2000)
                        }
                    } else {
                        this.setState({ isWaitOpenSubtitle: true })
                    }
                }
            } else {
                if (this.refFooter.state.switchSubtitles) {
                    this.refFooter && this.refFooter.setState({ switchSubtitles: false, }, () => {
                        summary.openOrCloseSummary(false)
                    })
                }
            }
        })

        this.room.addCommandListener(AUTHORIZE_REMOTE_ASSISTANCE, (res) => {
            if (res.attributes.userId === this.room.myUserId()) return;
            if (res.attributes.authorized === this.room.myUserId()) { // 共享人授予我远程控制权限
                console.log('共享人授予我远程控制权限', res)
                const cancel = res.attributes.cancel
                const target = this.state.videoList.filter(x => x.userId === res.attributes.authorized)
                if (target.length) {
                    message.success({
                        content: cancel === 'true' ? i18n.t('dialog.RemoteControlPermissionRevoked') : i18n.t('dialog.RemoteControlPermissionTranted'),
                        icon: <span></span>,
                        style: {
                            marginTop: '30vh',
                            color: cancel === 'true' ? '#fff' : "rgba(61, 230, 47, 1)"
                        },
                        duration: 4,
                        className: "meet-custom-message",
                        key: "AUTHORIZE_REMOTE_ASSISTANCE"
                    })
                    if (cancel === 'true') {
                        this.assistance && this.assistance.remove()
                        this.assistance = null
                    } else {
                        const desktopUser = this.state.videoList.filter(x => x.sharedUser && x._displayName.indexOf(res.attributes.authorized))
                        if (desktopUser.length) {
                            this.assistance = new authorizeRemoteAssistance({
                                room: this.room, userId: res.attributes.userId, desktopUser: desktopUser[0], cancel,
                                screenWidth: Number(res.attributes.screenWidth), screenHeight: Number(res.attributes.screenHeight)
                            })
                        }
                    }
                } else {
                    if (cancel === 'true') {
                        this.assistance && this.assistance.remove()
                        this.assistance = null
                    }
                }
            }
        })

        this.room.setLocalParticipantProperty('userId', this.state.user.primaryKey)
        this.room.setLocalParticipantProperty('userAvatar', this.state.user.avatar)
        this.room.join()
    }

    // 监听到视频/语音发生变化
    public trackMuteChange(trackNode) {
        if (trackNode.isLocal()) {
            return
        }
        const target = this.state.videoList.filter(node => node.participantId === trackNode.getParticipantId());
        if (target.length) {
            if (trackNode.getVideoType() === "desktop") {
                target[0].sharedUser = true;
            }
            let mediaType = trackNode.getType()
            let muted = trackNode.isMuted()
            if (mediaType === "video" && target[0].sharedUser === false) {
                if (trackNode.muted) { // 关闭摄像头
                    target[0].list = target[0].list.filter(x => x.mediaType === "audio");
                    this.setState({ videoList: this.state.videoList })
                    videoLayout.removeVideoOrAudioElement(trackNode, target[0])
                } else {// 开启摄像头
                    target[0].list.map(x => {
                        if (x.mediaType === "audio") {
                            target[0].list = [x]
                        } else if (x.mediaType === "video") {
                            x.jitsiTrack.dispose() // 把之前的video清掉
                        }
                    })
                    const eleId = trackNode.getParticipantId() + trackNode.getType()
                    target[0].list.push({
                        id: eleId,
                        jitsiTrack: trackNode,
                        local: trackNode.isLocal(),
                        mediaType: trackNode.getType(),
                        muted: trackNode.isMuted(),
                        videoType: trackNode.videoType,
                        participantId: trackNode.getParticipantId(),
                    })
                    this.setState({
                        videoList: this.state.videoList
                    }, () => {
                        videoLayout.addVideoOrAudioElement(trackNode, target[0])
                        if (this.state.mode === "speaker" && target[0].speaker) {
                            const nodeSpeaker = document.getElementById(speakerVideo)
                            if (nodeSpeaker) {
                                trackNode.attach(nodeSpeaker);
                                if (trackNode.videoType === "desktop") {
                                    this.openWatermark(nodeSpeaker.parentElement, trackNode.getParticipantId())
                                }
                            }
                            this.setReceiverConstraints()
                        }
                    })
                }
            } else if (mediaType === "audio") { // 点击的是声音按钮
                target[0].muted = muted;
                this.setState({
                    videoList: this.state.videoList,
                })
                videoLayout.setAudioMutedIcon(target[0])
            } else {
                this.setState({
                    videoList: this.state.videoList,
                })
            }
        }
    }

    public onConferenceJoined2() {
        logger.info("CONNECTION", '共享用户在加入会议时执行', this.room2.myUserId());
        JitsiMeetJS.createLocalTracks({
            devices: ['desktop'],
        }).then((tracks) => {
            tracks.forEach(track => {
                if (track.getType() === "video") {
                    if (this.refFooter.state.currentShareMode === "video") {
                        desktopApplyConstraints(1, track)
                    } else {
                        desktopApplyConstraints(0, track)
                    }
                }

            });
            const timer = setTimeout(() => {
                this.onLocalTracks(tracks, "shareDesktop")
                clearTimeout(timer)
            }, 400)
        })
            .catch(error => {
                logger.error("TRACK", "创建共享屏幕轨道报错", error)
                conference._handleScreenSharingError(error)
                // if (error.name === "gum.permission_denied") {
                //     message.error(i18n.t('dialog.cannotAccessSharedScreen'))
                // }
                this.state.videoList.forEach(node => { // 解决有时候取消共享后，共享用户未走到离开监听事件
                    if (node.sharedUser && node.local) {
                        node.list.forEach(z => {
                            if (z.videoType === "desktop") {
                                z.jitsiTrack.dispose()
                            }
                        })
                    }
                })
                this.refFooter && this.refFooter.setState({ shared: false, currentShareMode: "" })
                this.disconnect2()
            });
    }

    // 连接会议控制
    public meetingServerWebSocket() {
        if (this.room.myUserId()) {
            this.meetingSocketTimer && clearInterval(this.meetingSocketTimer)
            meetWebsocket.meetingServerWebSocket(this.state.meetData, this.room.myUserId(),
                (meetingSocket) => { // 创建成功/失败
                    this.meetingSocket = meetingSocket;
                    (window as any).meetingData = this.state.meetData;
                    if (this.state.settingParams.watermark) {
                        this.meetingSocket.send(JSON.stringify({
                            type: "openWatermark" //打开水印权限
                        }))
                    }
                    // 直播会议，主持人自动开启直播
                    if (this.state.meetData.live === 0 && this.state.isLocalModerator) {
                        this.startLive()
                    }
                }, this.onMessageMeetWebsocket.bind(this))
        }
    }

    //自己在加入会议后
    public async onConferenceJoined() {
        this.setState({ loading: false })
        if (!(window as any).websocketLive) {
            if (!this.meetingSocket) {
                this.meetingSocketTimer = setInterval(() => {
                    if (location.hash.indexOf('/meet') === -1) {
                        clearInterval(this.meetingSocketTimer)
                        return
                    }
                    this.meetingServerWebSocket()
                }, 300)
            }
        }
        this.websocketNotice()
        logger.debug("ROOM", '自己在加入会议后', this.room.myUserId());
        this.setState({ waitingModeratorJoin: false })
        if (JitsiMeetJS.mediaDevices.isDeviceChangeAvailable('output')) {
            JitsiMeetJS.mediaDevices.setAudioOutputDevice(this.state.settingParams.audioOutput.deviceId)
        }
        // this.room.setLocalParticipantProperty('userId', this.state.user.primaryKey)
        // this.room.setSenderVideoConstraint(2160)
        // this.room.setReceiverVideoConstraint(360)
        this.room.setDisplayName(this.state.user.name)
        // this.props.setE2eeSupported(this.room.isE2EESupported())//

        this.state.videoList.unshift({
            userId: this.room.myUserId(),
            _displayName: this.state.user.name,
            speaker: false,
            local: true,
            muted: true,
            participantId: null,
            list: [],
            moderator: false,
            stats: {},
            avatar: this.state.user.avatar || "",
            businessUserId: this.state.user.primaryKey,
            translationLanguageText: "",
            speeching: false,
            handRaiseState: false,
            mediaVideo: null
        })
        if (this.state.videoList.length === 1) { // 没有设置演讲者时，谁先进来是谁
            this.state.videoList[0].speaker = true;
        } else {
            const target = this.state.videoList.filter(x => x.moderator === true)
            if (target.length) {
                target[0].speaker = true
            }
        }
        videoLayout.addLocalUserContainer(this.state.videoList[0], this.containerClickChange.bind(this), this.switchSpeaker.bind(this))

        this.setState({ videoList: this.state.videoList }, () => {

            this.getDefaultTranslation() // 获取默认语言

            if (this.state.videoList.length === 2) {
                this.speakerChanged(this.state.videoList[1].participantId)
                videoLayout.addSpeakerUserContainer({ userdata: this.state.videoList[1] })
            } else if (this.state.videoList.length > 2) {
                this.refSpeakerMode.setState({ navMode: "all" })
                videoLayout.removeTopLeftUserContainer()
            }
            this.refFooter && this.refFooter.setState({ muted: !this.state.settingParams.microphone }, () => {
                this.createLocalTracks(["audio"])
            })
            if (this.state.settingParams.camera || this.refFooter && this.refFooter.state.video) {
                this.createLocalTracks(["video"])
            }
        })

        // 说明会议异常重连了，需要更新已经在共享的userId
        if (this.state.whetherReconnect && this.connection2 && this.room2) {
            this.room2.setDisplayName("userId-" + this.room.myUserId()) // 为添加第二条流
            this.setState({ whetherReconnect: false })
        }

        const timer = setTimeout(() => {
            clearTimeout(timer)
            const { user, meetData } = this.state
            // 连接选项
            const options = {
                clean: true, // true: 清除会话, false: 保留会话
                connectTimeout: 4000, // 超时时间
                // 认证信息
                clientId: user.primaryKey + GetRandomNum(100000, 999999),
                username: user.primaryKey,
                password: localStorage.getItem(STORAGE_ACCESS_TOKEN) || "",
                keepalive: 20
            }
            const connectUrl = `${getWebSocketUrl()}/mqtt`
            const client = mqtt.connect(connectUrl, options)
            client.on('connect', () => {
                this.mqttClient = client
            })
            client.on('message', (_topic, message) => {
                const msg = JSON.parse(message.toString())
                if (msg.type === "meetingDuration" && msg.data) { // 会议剩余时长通知
                    const unitLabel = { 0: i18n.t('date.millisecond'), 1: i18n.t('date.second'), 2: i18n.t('date.minutes'), 4: i18n.t('date.hours') }
                    const notikey = 'meetingExpiringReminder'
                    if (msg.data.host) {
                        if (this.state.isLocalModerator) {// 提醒主持人
                            notification.open({
                                closeIcon: null,
                                message: <div className="message">
                                    <div className="title" dangerouslySetInnerHTML={{ __html: i18n.t('dialog.MeetingExpiringReminder', { duration: msg.data.duration + unitLabel[msg.data.unit] }) }}></div>
                                    <div className="btns">
                                        <div className="btn btn1" onClick={(() => notification.close(notikey))}>{i18n.t('IKnow')}</div>
                                    </div>
                                </div>,
                                className: "meet-subtitle-notification",
                                placement: "bottomRight",
                                duration: null,
                                key: notikey,
                                bottom: "1.5rem" as any
                            })
                        }
                    } else { // 提醒参会人
                        notification.open({
                            closeIcon: null,
                            message: <div className="message">
                                <div className="title" dangerouslySetInnerHTML={{ __html: i18n.t('dialog.MeetingExpiringReminder', { duration: msg.data.duration + unitLabel[msg.data.unit] }) }}></div>
                                <div className="btns">
                                    <div className="btn btn1" onClick={(() => notification.close(notikey))}>{i18n.t('IKnow')}</div>
                                </div>
                            </div>,
                            className: "meet-subtitle-notification",
                            placement: "bottomRight",
                            duration: null,
                            key: notikey,
                            bottom: "1.5rem" as any
                        })
                    }
                }
            })
            client.subscribe(`meeting/notice/${meetData.roomName}`)
            // 暴露全局
            window.peerConnections = []
            this.room.rtc.peerConnections.forEach(element => {
                window.peerConnection = element
                window.peerConnections.push(element)
            });
        }, 3000)
        // 连接日志控制台socket
        this.logSocket = new logSocket(this.state.user.name, this.room.myUserId())
    }

    // 创建本地媒体
    public createLocalTracks(data) {
        console.log(new Date(), "swntech", "创建本地媒体", this.state.settingParams.audioInput.deviceId, this.state.settingParams.videoInput.deviceId, data)
        let option;
        if (data.indexOf("audio") > -1) {
            option = {
                devices: data,
                micDeviceId: this.state.settingParams.audioInput.deviceId,
            }
        } else {
            option = {
                devices: data,
                cameraDeviceId: this.state.settingParams.videoInput.deviceId,
            }
        }
        JitsiMeetJS.createLocalTracks({ ...option, constraints: connectionOptions.constraints })
            .then(async (tracks) => {
                // 允许的情况更新设备列表
                await getAvailableDevices().then(async devices => {
                    this.getNewMediaDevicesAfterDeviceListChanged(devices)
                })
                await this.refFooter && this.refFooter.setState({ switchVideoLoading: false })
                if (data.indexOf("video") > -1 || (data.indexOf("audio") > -1 && this.refFooter && !this.refFooter.state.muted)) {
                    // 这一步if是解决问题：麦克风关闭状态下进入会议，刷新浏览器后听不到会议中其他人的声音;
                    // 原因：play报错DOMException: play() failed because the user didn't interact with the document first
                    // 问题方案：创建createLocalTracks不报错了，创建后不发远端即可
                    this.onLocalTracks(tracks)
                }
            })
            .catch(error => {
                logger.error("TRACK", '创建轨道报错', error)
                conference._handleAudioVideoError(error, data[0])
                if (data.indexOf("audio") > -1) {
                    this.refFooter.setState({ muted: true })
                } else {
                    this.state.settingParams.camera = false;
                    this.refFooter.setState({ video: false, switchVideoLoading: false })
                }
            });
    }

    //处理本地音轨
    public async onLocalTracks(tracks, type?) {
        localTracks = tracks;
        for (let i = 0; i < localTracks.length; i++) {
            const mediaType = localTracks[i].getType()
            // if (type !== "shareDesktop" && !this.state.settingParams.microphone && mediaType === "audio") {
            //     localTracks[i].mute() //解决此问题：麦克风关闭状态下进入会议，刷新浏览器后听不到会议中其他人的声音
            // } else if (type !== "shareDesktop" && this.refFooter.state.muted && mediaType === "audio") {
            //     localTracks[i].mute() // 切换设备后，如果按钮是静音状态，需把新创建的流静音
            // }
            logger.info("TRACK", `开始处理本地${mediaType}轨`, localTracks[i].getDeviceId())
            localTracks[i].addEventListener(
                JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
                (track) => { // 本地
                    logger.info("TRACK", "本地音频发生变化", 'local track muted', track.type)
                    const target = this.state.videoList.filter(x => x.local === true)
                    if (target.length && track.getType() === 'audio') {
                        target[0].muted = track.isMuted()
                        videoLayout.setAudioMutedIcon(target[0])
                        this.setState({
                            videoList: this.state.videoList,
                        })
                        this.refFooter && this.refFooter.setState({ muted: track.isMuted() })
                    }
                });
            localTracks[i].addEventListener(
                JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
                () => {
                    logger.info("TRACK", 'local track stoped', localTracks[i].getParticipantId())
                    let localList: any = []
                    this.state.videoList.map(x => {
                        if (x.local && x.participantId) {
                            if (x.participantId === localTracks[i].getParticipantId()) {
                                localList.push(x)
                            } else {
                                if ((x._displayName && x._displayName.split("-")[1]) === localTracks[i].getParticipantId()) {
                                    localList.push(x)
                                }
                            }
                        } else {
                            if (x.participantId === localTracks[i].getParticipantId()) {
                                localList.push(x)
                            }
                        }
                    })
                    if (!this.state.currentFooterVideoIcon && localList.length) {
                        localList[0].list.map((x, index) => {
                            if (x.videoType === "desktop") { //  结束共享桌面
                                x.jitsiTrack.dispose()
                                this.disconnect2()
                                localList[0].list.splice(index, 1)
                                this.setState({ videoList: this.state.videoList })
                                this.refFooter.setState({
                                    shared: false, currentShareMode: ""
                                })
                                // if ((document as any).pictureInPictureElement) {
                                //     (document as any).exitPictureInPicture()
                                // }
                            }
                        })
                    } else {
                        this.state.currentFooterVideoIcon = false
                    }
                });
            localTracks[i].addEventListener(
                JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
                deviceId => console.log(
                    `track audio output device was changed to ${deviceId}`));

            if (mediaType === "audio") {
                let audioLevel = 0
                localTracks[i].addEventListener(
                    JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
                    (level) => {
                        audioLevel = level
                    });
                this.noAudioInputTimer && clearInterval(this.noAudioInputTimer)
                this.noAudioInputTimer = setInterval(() => {
                    let isReceivingData = localTracks[i].isReceivingData();
                    logger.debug(isReceivingData, audioLevel, localTracks[i].isMuted())
                    if (!localTracks[i].isMuted() && audioLevel === 0.008 && isReceivingData === false) {// 没有静音
                        //静音麦克风的值为 0.008\没有音频输入，则值为 0
                        // 处理audioLevel没变化了
                        logger.info("音频开启的情况下没声音了")
                        this.noAudioInput()
                    }
                }, 10000)
            }
            const id = `local${mediaType}${localTracks[i].videoType}`;

            let targetList = this.state.videoList.filter(x => x.participantId === null)

            if (type === "shareDesktop") {
                targetList = this.state.videoList.filter(x => {
                    if (x.sharedUser && x._displayName.split("-")[1] === targetList[0].userId) {
                        return x
                    }
                })
            }
            if (localTracks[i].videoType === "desktop" && targetList.length) {
                this.state.currentShareScreenUserId = targetList[0].userId
                this.state.videoList.map((x) => {
                    if (x.userId === targetList[0].userId) {
                        x.speaker = true;
                    } else {
                        x.speaker = false;
                    }
                })
            }

            if (targetList.length) {
                if (mediaType === "audio") {
                    targetList[0].muted = localTracks[i].isMuted()
                    targetList[0].mediaAudio = {
                        jitsiTrack: localTracks[i],
                        muted: localTracks[i].isMuted(),
                        participantId: localTracks[i].getParticipantId()
                    }
                } else if (mediaType === "video") {
                    targetList[0].list.forEach(x => {
                        if (x.mediaType === mediaType) {
                            x.jitsiTrack.dispose();
                        }
                    })

                    targetList[0].mediaVideo = {
                        jitsiTrack: localTracks[i],
                        videoType: localTracks[i].videoType,
                        muted: localTracks[i].isMuted(),
                        participantId: localTracks[i].getParticipantId()
                    }
                }

                targetList[0].list = targetList[0].list.filter(x => x.mediaType !== mediaType)

                targetList[0].list.push({
                    id,
                    jitsiTrack: localTracks[i],
                    mediaType: mediaType,
                    videoType: localTracks[i].videoType,
                    local: true,
                    muted: localTracks[i].isMuted(),
                    participantId: localTracks[i].getParticipantId()
                })

                this.setState({
                    videoList: this.state.videoList,
                    currentShareScreenUserId: this.state.currentShareScreenUserId
                }, () => {
                    if (mediaType === "video") {
                        if (targetList[0].speaker) { // 演讲者需要最大展示
                            const node = document.getElementById(speakerVideo)
                            localTracks[i].attach(node);
                            this.setReceiverConstraints()
                        }
                        if (this.state.mode !== "speaker" && type === "shareDesktop") {
                            const nodeSmall = document.getElementById(`participant_${targetList[0].userId}`)
                            this.openWatermark(nodeSmall, localTracks[i].getParticipantId())
                        }
                        if (localTracks[i].videoType === "camera") {
                            const nodeSelf = document.getElementById(localVideoSmall + targetList[0].userId)
                            nodeSelf && localTracks[i].attach(nodeSelf);
                            if (this.state.settingParams.backgroundModel === 'blur') {
                                createReplaceBgEffect("", "blur")
                                    .then(blurEffectInstance => {
                                        localTracks[i].setEffect(blurEffectInstance)
                                    })
                            } else if (this.state.settingParams.backgroundModel === "img") {
                                createReplaceBgEffect(this.state.settingParams.backgroundImg).then(blurEffectInstance => {
                                    localTracks[i].setEffect(blurEffectInstance)
                                })
                            }
                        }
                    }
                })

                if (type === "shareDesktop") {
                    this.room2.addTrack(localTracks[i]); // 为添加第二条流
                } else {
                    try {
                        await this.room.addTrack(localTracks[i]);
                    } catch (e) {
                        logger.error("TRACK", 'addTrack', e)
                    }
                }

                if (type !== "shareDesktop" && this.state.settingParams.microphone && mediaType === "audio") {
                    this.refFooter.setState({
                        muted: localTracks[i].isMuted(),
                    })
                }
                if (this.state.settingParams.camera && mediaType === "video") {
                    this.refFooter.setState({
                        video: this.state.settingParams.camera,
                    })
                }
                // 添加video/audio标签
                videoLayout.addVideoOrAudioElement(localTracks[i], targetList[0], this.allowAutoplayFun.bind(this))
            }
        }
    }

    //处理远程音视频轨
    public onRemoteTrack(track) {
        if (track.isLocal()) {
            let isReceivingData = track.isReceivingData();
            if (!isReceivingData) {
                if (track.getType() === "audio") {
                    notification.info({
                        message: i18n.t('dialog.yourMicrophoneMutedSystem'),
                        placement: "bottomLeft",
                        duration: 15,
                        key: "noAudioSignalTitle"
                    });
                } else {
                    notification.info({
                        message: i18n.t('dialog.unableAccessCameras'),
                        placement: "bottomLeft",
                        duration: 15,
                        key: "noAudioSignalTitle"
                    });
                }

            }
            return;
        }
        logger.info("TRACK", `开始处理远程${track.type}轨`, track, track.isMuted())
        const participant = track.getParticipantId();
        const id = participant + track.getType()
        track.addEventListener(
            JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
            (id, audioLevel) => {
                // console.log(`Audio Level remote: ${id},${audioLevel}`)
                // videoLayout.setAudioLevel(id, audioLevel)
            });
        track.addEventListener(
            JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
            () => {
                console.log(new Date(), "swntech", `remote track muted ${track.getType()} - ${track.isMuted()}`, this.state.videoList);
            });
        track.addEventListener(
            JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
            () => console.log(new Date(), "swntech", 'remote track stoped'));
        track.addEventListener(JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
            deviceId => console.log(
                `输出设备变化 ${deviceId}`));
        track.on(JitsiMeetJS.events.track.NO_DATA_FROM_SOURCE, () => {
            console.log(new Date(), "swntech", 'NO_DATA_FROM_SOURCE')
        })

        let target = this.state.videoList.filter(x => x.participantId === participant)
        if (target.length > 0) {
            if (target[0].sharedUser && target[0].local) {
                if (target[0].list.length >= 1) { // 在我自己共享时 本地已经存过数据，远端过来的就不需要存了
                    this.updateLiveInfoDefault()
                    return
                }
            }
            target[0].list = target[0].list.filter(x => x.mediaType !== track.getType()) // 过滤掉因overwriting remote track for造成的数据重复
            if (track.getType() === "audio") {
                target[0].muted = track.isMuted();
                target[0].audioSsrc = track.getSSRC()
            } else {
                target[0].videoSsrc = track.getSSRC()
                if (track.getVideoType() === "desktop") {
                    target[0].sharedUser = true;
                    this.state.currentShareScreenUserId = target[0].userId;
                    this.state.videoList.forEach((x) => {
                        if (target[0].userId === x.userId) {
                            x.speaker = true;
                        } else {
                            x.speaker = false;
                        }
                    })
                    this.updateLiveInfoShareScreen(participant)
                }
            }
            target[0].list.push({
                id,
                jitsiTrack: track,
                local: track.isLocal(),
                mediaType: track.getType(),
                muted: track.isMuted(),
                videoType: track.videoType,
                participantId: participant,
            })
            this.setState({
                videoList: this.state.videoList,
                currentShareScreenUserId: this.state.currentShareScreenUserId,
            }, () => {
                if (track.getType() === "video") {
                    const nodeSpeaker = document.getElementById(speakerVideo)
                    if (target[0].speaker && nodeSpeaker) {
                        track.attach(nodeSpeaker);
                        if (track.videoType === "desktop" || target[0].sharedUser) {
                            this.openWatermark(nodeSpeaker.parentElement, track.getParticipantId())
                            this.updateLiveInfoDefault()
                        }
                        if (target[0].isDevice === "360") {// 360设备，vr展示
                            this.set360videojs(id)
                        }
                    }
                    if (this.state.mode !== "speaker" && (track.videoType === "desktop" || target[0].sharedUser)) {
                        const nodeSmall = document.getElementById(`participant_${target[0].participantId}`)
                        this.openWatermark(nodeSmall, target[0].participantId)
                    }
                    const nodeSelf = document.getElementById(localVideoSmall + target[0].userId)
                    nodeSelf && track.attach(nodeSelf);
                    this.setReceiverConstraints()
                } else if (track.getType() === "audio") {
                    // 录屏
                    if (this.refFooter.state.recordScreen || this.refFooter.state.recordAudio) {
                        connectAudioContext(target[0])
                    }
                }
                videoLayout.addVideoOrAudioElement(track, target[0], this.allowAutoplayFun.bind(this))
            })
        }
    }

    // 通知用户角色变化
    public async onUserRoleChange(id, role) {
        logger.debug("ROOM", "通知用户角色是不是主持人", id, role)
        const target = this.state.videoList.filter(x => x.userId === id)
        if (target.length) {
            if (role === "moderator") {
                target[0].moderator = true; // 是主持人
                if (target[0].local) {
                    if (window.localStorage.getItem(STORAGE_ACCESS_TOKEN) !== "token") {
                        if (!this.state.isLocalModerator) {
                            message.success({
                                content: i18n.t('dialog.YouHaveBeenMadeTheTost'),
                                icon: <span></span>,
                                style: {
                                    marginTop: '30vh',
                                },
                                duration: 3,
                                className: "meet-custom-message",
                                key: "YouHaveBeenMadeTheTost"
                            })
                        }
                        this.state.isLocalModerator = true;
                        if (this.refFooter.state.currentShareMode === "whiteboard") {
                            const timer = setTimeout(() => {
                                this.whiteboardData && this.whiteboardData.postUserRoleChange(this.initDataWhiteboard, this.iframeWhiteboard)
                                videoLayout.disableCloseWhiteboardButton({
                                    isLocalModerator: true,
                                    isWhiteboardCreator: this.refFooter ? this.refFooter.state.isWhiteboardCreator : false
                                })
                                clearTimeout(timer)
                            }, 2000)
                        }
                        // 我自己是主持人，我的水印状态和会议水印状态对应
                        this.setState({ settingParams: { ...this.state.settingParams, watermark: this.state.roomWatermarkPermissions } })
                    } else {
                        this.state.isLocalModerator = false
                    }
                }
            } else {
                target[0].moderator = false; // 不是主持人
                if (target[0].local) {
                    this.state.isLocalModerator = false;
                }
            }
        }
        this.setState({ videoList: this.state.videoList, isLocalModerator: this.state.isLocalModerator }, () => {
            videoLayout.userRoleChange(this.state.videoList)
        })
    }

    // 参与者被静音
    public participantMutedUs() {
        message.success({
            content: i18n.t('dialog.youHaveBeenMuted'),
            icon: <span></span>,
            style: {
                marginTop: '30vh',
            },
            duration: 5,
            className: "meet-custom-message",
            key: "youHaveBeenMuted"
        })
        this.refFooter.setState({
            muted: true
        })
        this.setLacalMute(true)
    }

    // 远程用户离开
    public onUserLeft(id) {
        logger.info("ROOM", '用户离开', id);
        if (id === this.state.currentMediaRecorderParticipantId) {
            videoLayout.mediaRecorderPromptUI({ status: false }) // 当前离开的人开了录音/录像，需要关闭提示
        }
        // 用户离开后删除对应dom
        const list = this.state.videoList.filter(x => x.participantId !== id);
        videoLayout.removeUserContainer(this.state.videoList.filter(x => x.participantId === id)[0], list.length,
            () => {
                const timer = setTimeout(() => {
                    clearTimeout(timer)
                    this.refSpeakerMode && this.refSpeakerMode.swiperSpeaker.update()
                }, 100)
            })
        let isShareUser = false
        this.setState({ videoList: list }, () => {
            (window as any).participants = this.state.videoList;
            if (this.state.currentShareScreenUserId) { // 有共享用户
                if (this.state.currentShareScreenUserId === id) { // 退出的用户是正在共享用户
                    isShareUser = true
                    let preTarget: IParticipantItem = this.state.videoList[this.state.videoList.length - 1]
                    this.speakerChanged(preTarget.userId)
                    preTarget.list.forEach(x => {
                        if (x.mediaType === "video") {
                            const timer = setTimeout(() => {
                                const nodeSpeaker = document.getElementById(speakerVideo)
                                if (nodeSpeaker) {
                                    x.jitsiTrack.attach(nodeSpeaker);
                                    if (x.videoType === "desktop") {
                                        this.openWatermark(nodeSpeaker.parentElement, preTarget.participantId)
                                    }
                                }
                                const nodeSelf = document.getElementById(localVideoSmall + preTarget.userId)
                                nodeSelf && x.jitsiTrack.attach(nodeSelf);
                                clearTimeout(timer)
                            }, 200);
                        }
                    })
                    this.setState({ currentShareScreenUserId: '' })
                    this.assistance && this.assistance.remove()
                    this.updateLiveInfoDefault()
                }
            } else { // 没有共享用户
                if (this.state.videoList.length) {
                    this.speakerChanged(this.state.videoList[this.state.videoList.length - 1].userId)
                }
            }
            // 画中画处理
            if (this.state.mode === "pictureInPicture") {
                const { pictureInPictureData } = this.props
                const { pictureInPictureUser } = this.state
                const pictureUserIndex = pictureInPictureUser.findIndex(p => p.userId === id)
                if (pictureUserIndex > -1) { // 退出的是画中画用户,需要更新画中画
                    if (this.state.isLocalModerator && pictureInPictureData.hasOwnProperty("switch") && pictureInPictureData.switch) {
                        if (pictureUserIndex === 0) {//
                            if (pictureInPictureData.mainPicture.value === "systemAutomatic") {// 主画面离开-规则是系统自动
                                console.log('主画面离开-规则是系统自动') // 再选一个人出来，更新UI、更新给远端
                                this.updatePictureInPictureUser(true)
                            } else {// 主画面离开-指定的用户：显示此人离开UI
                                if (isShareUser) { // 当前离开的是共享用户，恢复成指定的即可
                                    this.updatePictureInPictureUser()
                                } else {
                                    console.log("主画面离开-指定的用户：显示此人离开UI")
                                    videoLayout.pictureLeaveUserOccupiedUI(pictureUserIndex, pictureInPictureData.template)
                                    this.setState({ showFooter: true })
                                }
                            }
                        } else if (pictureUserIndex === 1 && pictureInPictureData.picture1.value !== "voiceStimulation") { //画中画1离开：显示此人离开UI
                            console.log("画中画1离开：显示此人离开UI")
                            videoLayout.pictureLeaveUserOccupiedUI(pictureUserIndex, pictureInPictureData.template)
                        } else if (pictureUserIndex === 2 && pictureInPictureData.picture2.value !== "voiceStimulation") {//画中画2离开：显示此人离开UI
                            console.log("画中画2离开：显示此人离开UI")
                            videoLayout.pictureLeaveUserOccupiedUI(pictureUserIndex, pictureInPictureData.template)
                        }
                    } else {
                        this.updatePictureInPictureUser()
                    }
                }
            }

            if (this.state.isLocalModerator && this.state.moreMenuActive === "set") { // 画中画多主持人时
                this.refSetCamera && this.refSetCamera.setPictureInPictureData()
            }
        })

        // 用户离开后如果少于2人要换展示模式
        if (this.state.videoList.length <= 2) {
            this.refSpeakerMode && this.refSpeakerMode.setState({ navMode: this.state.mode === "speaker" ? "none" : "self" }, () => {
                this.updateAddTrackNode()
                if (this.state.mode === "speaker") {
                    videoLayout.addTopLeftUserContainer(this.state.videoList[0])
                }
            })
        }

        // 有用户离开时需要重新设置画廊模式、主讲模式下拉视频流的参与人列表
        this.setReceiverConstraints()
    }

    // 卸载本地视频
    public async unload() {
        console.log("卸载", this.state.videoList)
        // 移除远端和本地
        this.state.videoList.map(node => {
            if (node.local) {
                for (let i = 0; i < node.list.length; i++) {
                    node.list[i].jitsiTrack.dispose();
                }
            } else {
                node.list.map(z => {
                    const node = document.getElementById(z.id)
                    z.jitsiTrack.detach(node)
                })
            }
        })
        const timer = setTimeout(() => {
            this.disconnect2()
            this.disconnect()
            clearTimeout(timer)
        }, 1000)

        summary.close()
        this.assistance && this.assistance.remove()

        this.noAudioInputTimer && clearInterval(this.noAudioInputTimer)
        this.noAudioInputTimer = null
        const { participantAudioLevel } = this.state;
        for (const key in participantAudioLevel) {
            if (Object.prototype.hasOwnProperty.call(participantAudioLevel, key)) {
                const element = participantAudioLevel[key];
                element && element.timer && clearInterval(element.timer)
            }
        }
        this.props.setPictureInPictureData(defaultPictureInPictureData)
        this.props.setMeetingRecordingData(null)

        endRecord(() => { })//下载录屏
        endRecordAudio(() => { })//下载录音
        videoLayout.mediaRecorderPromptUI({ status: false })
    }

    // 断开
    public disconnect() {
        logger.debug('CONNECTION', '断开!');
        if (this.state.whetherReconnect) {
            return
        }
        this.connection && this.connection.disconnect();
        this.unsubscribe()
        if (this.deviceChangeListener) {
            JitsiMeetJS.mediaDevices.removeEventListener(
                JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED, this.deviceChangeListener);
        }
    }

    public unsubscribe() {
        this.connection.removeEventListener(
            JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
            this.onConnectionSuccess.bind(this));
        this.connection.removeEventListener(
            JitsiMeetJS.events.connection.CONNECTION_FAILED,
            this.onConnectionFailed.bind(this));
        this.connection.removeEventListener(
            JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, this.disconnect.bind(this));
    }

    public disconnect2() {
        this.room2 && this.room2.leave()
        this.connection2 && this.connection2.disconnect();
        this.connection2 = null
    }

    // 离开会议
    public leaveMeet() {
        if (this.room) {
            try {
                this.room.leave();
            } catch (e) {
                logger.debug('ROOM', "离开会议leave", e)
            }
        }
    }

    // 结束会议
    public stopMeet() {
        this.state.videoList.map(x => !x.local && this.room.kickParticipant(x.participantId))
        this.leaveMeet()
        this.unload()
    }

    // 异常启动重连
    public reconnectConference() {
        try {
            meetWebsocket.close()
            connectionOptions['statisticsId'] = 'sv-user-' + this.state.user.primaryKey;
        } catch (e) {
            logger.warn('reconnectConference', e)
        } finally {
            this.state.videoList.map(node => {
                // 卸载本地
                if (!node.local) {
                    // 移除远端节点
                    node.list.map(z => {
                        const node = document.getElementById(z.id)
                        z.jitsiTrack.detach(node)
                    })
                }
            })
            this.setState({ whetherReconnect: true }, () => {
                this.leaveMeet()
            })
        }
    }
    // 单击用户框做出反应
    public containerClickChange(id) {
        if (this.state.mode === "pictureInPicture") {
            const { pictureInPictureUser } = this.state;
            const target = pictureInPictureUser.filter(x => x.userId === id)
            // console.log(target[0]._displayName, target[0].switchedId, pictureInPictureUser[0]._displayName, pictureInPictureUser[0].switchedId)
            if (!pictureInPictureUser[0].switchedId && pictureInPictureUser[0].userId === id) {
                this.setState({ showFooter: !this.state.showFooter })
            } else if (pictureInPictureUser[0].switchedId && target[0].switchedId === pictureInPictureUser[0].userId) {
                this.setState({ showFooter: !this.state.showFooter })
            } else {
                if (target.length) {
                    // if (!ele||!target[0].switchedId) return // 没video不可切换
                    if (id === pictureInPictureUser[0].userId) {// 已经和主画面切换
                        videoLayout.switchMainWindowAndWindow(target[0])
                        const user = pictureInPictureUser.filter(x => x.userId === pictureInPictureUser[0].switchedId)
                        if (user.length) {// 对应需要归位的用户
                            user[0].switchedId = ""
                        }
                        target[0].switchedId = ""
                    } else {
                        pictureInPictureUser.forEach(p => {
                            if (p.switchedId && p.userId !== target[0].userId && p.userId === pictureInPictureUser[0].userId) {// 已经有小画面和主画面切换了，需切换回去，再切换此窗口
                                videoLayout.switchMainWindowAndWindow(p)
                                const user = pictureInPictureUser.filter(x => x.userId === p.switchedId)
                                if (user.length) { // 对应需要归位的用户
                                    user[0].switchedId = ""
                                }
                                p.switchedId = ""
                            }
                        })
                        target[0].switchedId = pictureInPictureUser[0].userId;
                        pictureInPictureUser[0].switchedId = id
                        videoLayout.switchMainWindowAndWindow(target[0])
                    }
                    this.setState({ pictureInPictureUser })
                }
            }
        } else {
            this.speakerChanged(id)
            this.refSpeakerMode && this.refSpeakerMode.setState({ currentPinUserId: "" })
        }
    }
    // 切换video或桌面
    public switchVideoDesktop(bool) {
        console.log('切换video或桌面')
        if (bool) { // 共享桌面需要建立一条服务器连接 并且入会
            this.initConnection2()
            return
        }
    }
    // 开启或关闭本地摄像头
    public switchVideo(boole) {
        logger.info("ROOM", `${boole ? "开启" : '关闭'}本地摄像头`, boole)
        if (this.state.waitingModeratorJoin) { // 是等待加入状态
            this.setState({
                settingParams: {
                    ...this.state.settingParams,
                    camera: boole
                }
            })
            this.refFooter && this.refFooter.setState({ switchVideoLoading: false })
            return
        }
        if (boole) {
            this.createLocalTracks(['video'])
        } else {
            let index = -1;
            let userBool = false;
            this.state.videoList.forEach((x) => {
                if (x.local) {
                    if (!this.refFooter.state.shared) {
                        let prevUserId = ""; // 最后一个共享用户
                        this.state.videoList.forEach(z => {
                            if (z.sharedUser) {
                                if (x.userId === z._displayName.split("-")[1]) { // 关闭共享,退出共享用户
                                    this.state.currentShareScreenUserId = prevUserId;
                                    userBool = true;
                                    z.list.forEach(y => {
                                        y.jitsiTrack.dispose()
                                        this.disconnect2()
                                    })
                                } else {
                                    prevUserId = x.userId;
                                    if (userBool) {
                                        this.state.currentShareScreenUserId = prevUserId;
                                    }
                                }
                            }
                        })
                    }
                    if (!userBool) { // 关闭视频
                        x.list.forEach(async (z, ind) => {
                            if (z.mediaType === "video") {
                                if (z.videoType !== "desktop") {
                                    index = ind;
                                    await z.jitsiTrack.setEffect(undefined)
                                    z.jitsiTrack.dispose()
                                    this.room.removeTrack(z.jitsiTrack)
                                    this.state.currentFooterVideoIcon = true;
                                    videoLayout.removeVideoOrAudioElement(z.jitsiTrack, x)
                                }
                            }
                        })
                        if (index !== -1) {
                            x.list.splice(index, 1)
                        }
                    }
                    index = -1;
                }
            })
            this.setState({ videoList: this.state.videoList })
            this.refFooter && this.refFooter.setState({ switchVideoLoading: false })
        }
    }

    // 切换本地摄像头
    public async setVideoInputDevice(deviceId) {
        await this.setState({
            settingParams: {
                ...this.state.settingParams,
                videoInput: {
                    deviceId,
                },
            }
        })

        if (!this.refFooter.state.video) {
            return
        }
        const res = this.state.videoList.filter(x => x.local === true)
        console.log(new Date(), "swntech", "切换本地摄像头", res)
        if (res.length) {
            const list: any[] = []
            res[0].list.map(x => {
                if (x.mediaType === "video") {
                    x.jitsiTrack.dispose()
                    const element = document.getElementById(x.id)
                    x.jitsiTrack.detach(element)
                } else {
                    list.push(x)
                }
            })
            res[0].list = list;
        }
        this.setState({ videoList: this.state.videoList }, () => {
            this.createLocalTracks(['video'])
        })
    }

    // 开启或关闭麦克风
    public setLacalMute(bool) {
        logger.info("ROOM", `${bool ? '关闭' : "开启"}本地声音`)
        if (this.state.waitingModeratorJoin) { // 是等待加入状态
            this.setState({
                settingParams: {
                    ...this.state.settingParams,
                    microphone: !bool
                }
            })
            return
        }
        const target = this.state.videoList.filter(x => x.local === true)
        if (target.length) {
            const targetAudio = target[0].list.filter(x => x.mediaType === "audio")
            if (targetAudio.length) {
                if (bool) {
                    targetAudio[0].jitsiTrack.mute()
                } else {
                    targetAudio[0].jitsiTrack.unmute()
                }
                target[0].muted = bool;
                videoLayout.setAudioMutedIcon(target[0])
                this.setState({ videoList: this.state.videoList })
            } else {
                if (!bool) {
                    this.createLocalTracks(["audio"])
                }
            }
        }
        if (!bool) { // 关闭提示框
            notification.close('talkWhileMutedPopup')
        }
    }

    // 切换本地麦克风
    public async setAudioInputDevice(deviceId) {
        await this.setState({
            settingParams: {
                ...this.state.settingParams,
                audioInput: {
                    deviceId
                },
            }
        })
        const res = this.state.videoList.filter(x => x.local === true && x.sharedUser !== true)
        if (res.length) {
            const list: any[] = []
            res[0].list.map(x => {
                if (x.mediaType === "audio") {
                    this.state.currentFooterVideoIcon = true
                    x.jitsiTrack.dispose()
                    const element = document.getElementById(x.id)
                    x.jitsiTrack.detach(element)
                } else {
                    list.push(x)
                }
            })
            res[0].list = list;
        }
        this.setState({ videoList: this.state.videoList }, () => {
            this.createLocalTracks(["audio"])
        })
    }

    // 重新给video标签添加流
    public updateAddTrackNode() {
        this.closeWatermark()
        this.state.videoList.forEach(x => {
            x.list.forEach(z => {
                if (z.mediaType === "video") {
                    if (this.state.mode === "speaker" && x.speaker) {
                        const nodeSpeaker = document.getElementById(speakerVideo)
                        if (nodeSpeaker) {
                            z.jitsiTrack.attach(nodeSpeaker);
                            this.setReceiverConstraints()
                            if (x.sharedUser) {
                                this.openWatermark(nodeSpeaker.parentElement, x.participantId)
                            } else if (x.isDevice === "360") {// 360设备，vr展示
                                this.set360videojs(z.id)
                            }
                        }
                    } else if (this.state.mode !== "speaker" && x.sharedUser) {
                        const nodeSmall = document.getElementById(`participant_${x.participantId}`)
                        this.openWatermark(nodeSmall, x.participantId)
                    }
                    const nodeSelf = document.getElementById(localVideoSmall + x.userId)
                    nodeSelf && z.jitsiTrack.attach(nodeSelf);
                }
            })
        })
    }

    // 发言者更改
    public speakerChanged(id) {
        let index = 0;
        this.state.videoList.forEach((x, ind) => {
            if (x.userId === id) {
                x.speaker = true;
                index = ind;
            } else {
                x.speaker = false;
                const nodeSelf = document.getElementById(localVideoSmall + x.userId)
                if (nodeSelf) {
                    x.list.forEach(z => {
                        if (z.mediaType === "video") {
                            z.jitsiTrack.attach(nodeSelf);
                        }
                    })
                }
            }
        })
        this.closeWatermark()
        this.setState({ videoList: this.state.videoList }, () => {
            if (this.state.mode === "speaker") { // pin权限最高
                videoLayout.addSpeakerUserContainer({ // 切换主讲人
                    userdata: this.state.videoList[index]
                })
                this.state.videoList[index].list.forEach(z => {
                    if (z.mediaType === "video") {
                        const nodeSpeaker = document.getElementById(speakerVideo)
                        if (nodeSpeaker) {
                            z.jitsiTrack.attach(nodeSpeaker);
                            if (z.videoType === "desktop") {
                                this.openWatermark(nodeSpeaker.parentElement, z.participantId)
                            } else if (this.state.videoList[index].isDevice === "360") {
                                this.set360videojs(z.id)
                            }
                        }
                    }
                })
                this.setReceiverConstraints()
            }
        })
    }

    // 点击画廊模式某个用户切换到演讲者模式
    public switchSpeaker(id) {
        if (this.state.mode === "pictureInPicture") return
        this.setState({ mode: "speaker" }, async () => {
            await videoLayout.switchMode('speaker')
            await this.refSpeakerMode.swiperInit()
            await this.speakerChanged(id)
            if (this.state.videoList.length <= 2) {
                this.updateAddTrackNode()
                videoLayout.addTopLeftUserContainer(this.state.videoList[0].userId === id ? this.state.videoList[1] : this.state.videoList[0])
            }
        })
    }

    // 全体静音
    public setAllMutedPolicy() {
        this.state.videoList.map(x => !x.local && x.list.map(z => this.muteRemote(z.participantId)))
    }

    // 静音远端用户
    public muteRemote(participantId) {
        this.room.muteParticipant(participantId, "audio")
    }

    // 全体关闭摄像头
    // public setAllCloseCamera() {
    //     this.closeCameraRemote("all")
    // }

    // 关闭远端用户摄像头
    public closeCameraRemote(value, userId?) {
        this.room.sendCommandOnce(CLOSE_CAMERA_COMMAND, {
            value,
            attributes: {
                userId,
            }
        })
    }

    // 成员入会关闭摄像头/静音
    public setStartMutedPolicy(video, audio) {
        this.room.setStartMutedPolicy({
            audio,
            video,
        })
    }

    // 修改名称
    public setDisplayName(name) {
        this.room.setDisplayName(name)
        this.state.videoList.map(x => {
            if (x.sharedUser && x.local) {
                x.realName = i18n.t('meet.sharingOfName', { name: name });
                videoLayout.setDisplayName(x)
            } else if (x.local) {
                x._displayName = name;
                videoLayout.setDisplayName(x)
            }
        })
        this.setState({ videoList: this.state.videoList })
    }

    // 授权主持人
    public grantOwner(userId) {
        this.room.grantOwner(userId)
    }

    // 设置扬声器
    public setAudiooutputDevice(deviceId) {
        if (JitsiMeetJS.mediaDevices.isDeviceChangeAvailable('output')) {
            JitsiMeetJS.mediaDevices.setAudioOutputDevice(deviceId)
        }
        this.setState({
            settingParams: {
                ...this.state.settingParams,
                audioOutput: {
                    deviceId,
                }
            }
        })
    }

    // 设置背景图片
    public setBgImg(blurEffectInstance, setParams) {
        if (this.refFooter?.state.video) {
            const track = this.room.getLocalVideoTrack()
            if (track) {
                track.setEffect(blurEffectInstance)
            }

        }
        this.setState({
            settingParams: {
                ...this.state.settingParams,
                ...setParams
            }
        })
    }

    //水印开关
    public switchWatermark(value) {
        this.state.settingParams.watermark = value
        let sharedUserWatermark = false
        this.state.videoList.forEach(ele => {
            if (ele.local) {
                ele.shareScreenWatermark = value;
            }
            if (ele.sharedUser) {
                sharedUserWatermark = ele.shareScreenWatermark
            }
        })
        this.meetingSocket && this.meetingSocket.send(JSON.stringify({
            type: value ? "openWatermark" : "closeWatermark"
        }))
        // 我是主持人&&共享用户未开启水印
        if (!value && this.state.isLocalModerator && !sharedUserWatermark) {
            this.closeWatermark()
        }
        this.setState({ videoList: this.state.videoList, settingParams: this.state.settingParams })
    }

    // 语音识别消息
    public onMessageSpeechServer(data, bool) {
        const audioBufferList: any[] = []
        switch (data.type) {
            // 服务可用
            case 'available':
                this.summarySocketAvailable()
                break;
            case 'speechInfo':
                // 用户ID
                let id = data.data.id;
                // 用户名称
                let displayName = data.data.speaker || i18n.t('meet.stranger');
                // 识别结果
                const msg = data.data.message;
                // 识别时间
                let time = data.data.time;
                // 如果会议中有用户开启了翻译，这里为翻译结果
                let tr = data.data.origin;
                // 是否最终结果，如果开启了realtime，会返回final为false的中间结果
                let final = data.data.final;
                // 结果序号，如果开启了realtime，中间结果和最终结果的序号一致
                let mid = data.data.mid;
                if (msg) {
                    if (this.state.summaryMessage.length > 0 && this.state.summaryMessage[this.state.summaryMessage.length - 1].id === id) { // 同一人连续说话
                        const ele = document.getElementById(mid + "")
                        if (final) {
                            if (ele) {
                                const target = this.state.summaryMessage[this.state.summaryMessage.length - 1];
                                const index = target.message.indexOf(`<span style="color:rgba(255, 177, 0, 1)" id="${mid}">`)
                                target.final = true;
                                if (index !== -1) {
                                    const str = target.message.slice(index, target.message.indexOf(`</span>`, index) + 7)
                                    target.message = target.message.replace(str, '')
                                    target.message += msg;
                                } else {
                                    target.message += msg;
                                }
                            }
                        } else {
                            if (ele) {
                                ele.innerText = msg;
                            } else {
                                this.state.summaryMessage[this.state.summaryMessage.length - 1].message += `<span style="color:rgba(255, 177, 0, 1)" id="${mid}">${msg}</span>`;
                            }
                        }
                    } else {
                        if (final) {
                            if (this.state.summaryMessage.length === 0) {// 第一句且说完了
                                this.state.summaryMessage.push({ id, displayName, message: msg, time, tr, final, mid })
                            } else {
                                const ele = document.getElementById(mid + "")
                                if (ele) {
                                    const target = this.state.summaryMessage.filter(x => x.message.indexOf(`<span style="color:rgba(255, 177, 0, 1)" id="${mid}">`) !== -1)
                                    if (target.length) {
                                        const index = target[0].message.indexOf(`<span style="color:rgba(255, 177, 0, 1)" id="${mid}">`)
                                        target[0].final = true;
                                        if (index !== -1) {
                                            const str = target[0].message.slice(index, target[0].message.indexOf(`</span>`, index) + 7)
                                            target[0].message = target[0].message.replace(str, '')
                                        }
                                    }
                                }
                                if (this.state.summaryMessage.filter(x => x.id === id).length) {
                                    //@ts-ignore
                                    this.state.summaryMessage = this.state.summaryMessage.filter(x => x.mid !== mid)
                                }
                                this.state.summaryMessage.push({ id, displayName, message: msg, time, tr, final, mid })
                            }
                        } else {
                            const ele = document.getElementById(mid + "")
                            if (ele) {
                                const target = this.state.summaryMessage.filter(x => x.message.indexOf(`<span style="color:rgba(255, 177, 0, 1)" id="${mid}">`) !== -1)
                                if (target.length) {
                                    const index = target[0].message.indexOf(`<span style="color:rgba(255, 177, 0, 1)" id="${mid}">`)
                                    target[0].final = true;
                                    if (index !== -1) {
                                        const str = target[0].message.slice(index, target[0].message.indexOf(`</span>`, index) + 7)
                                        target[0].message = target[0].message.replace(str, '')
                                    }
                                }
                            }

                            if (this.state.summaryMessage.filter(x => x.id === id).length) {
                                //@ts-ignore
                                this.state.summaryMessage = this.state.summaryMessage.filter(x => x.mid !== mid)
                            }
                            this.state.summaryMessage.push({ id, displayName, message: `<span style="color:rgba(255, 177, 0, 1)" id="${mid}">${msg}</span>`, time, tr, final, mid })
                        }
                    }
                    this.setState({ summaryMessage: this.state.summaryMessage }, () => {
                        this.refSummary && this.refSummary.scrollTop()
                    })
                }

                // 识别结果转语言
                let audioBuffer = data.data.audioBuffer;
                if (audioBuffer && final) {
                    let audioContext = new AudioContext();
                    audioContext.decodeAudioData(base64ToArrayBuffer(audioBuffer)).then(buffer => {
                        // 转换为音频buffer，并播放
                        let bufferSource = audioContext.createBufferSource()
                        bufferSource.connect(audioContext.destination);
                        bufferSource.buffer = buffer;
                        if (!audioBufferList.length) {
                            bufferSource.start();
                        } else {
                            audioBufferList.push(bufferSource)
                        }
                        bufferSource.onended = function () {
                            audioBufferList.shift()
                            if (audioBufferList.length) {
                                audioBufferList[0].start()
                            }
                        }
                    });
                }
                break;
            case 'unprovide': //无法提供服务
                this.subtitleModal && this.subtitleModal.destroy()
                localStorage.removeItem(SUBTITLE_USAGE_MODAL)
                this.unprovide(data)
                break;
            case 'notice': // 提示信息
                switch (data.data.subType) {
                    case 'translation':
                        // 翻译余额，字数
                        notification.warn({
                            message: i18n.t('dialog.translationBalance', { num: data.data.balance }),
                            className: "meet-api-notification",
                            duration: null,
                        })
                        break;
                    case 'subtitle':
                        // 字幕余额，秒
                        notification.warn({
                            message: i18n.t('dialog.subtitleBalanceRemain', { num: data.data.balance }),
                            className: "meet-api-notification",
                            duration: null,
                        })
                        break;
                }
                break;
            case 'userStatus':
                data.data.forEach(element => {
                    this.state.videoList.forEach(x => {
                        if (element.userId.indexOf(x.businessUserId) > -1) {
                            x.speeching = element.speeching;
                        }
                    })
                });
                this.setState({ videoList: this.state.videoList })
                break;
            default:
                if (!bool) {
                    this.onMessageSpeechServerRecord(data, true)
                }
                break;
        }
    }
    // 监听服务端字幕服务可用
    public summarySocketAvailable() {
        if (this.state.meetData.transliteration && this.state.isLocalModerator) { // 请客模式：结束会议后主持人需要展示字幕使用量
            localStorage.setItem(SUBTITLE_USAGE_MODAL, this.state.meetData.primaryKey)
        }
        if (!this.state.meetData.transliteration) { // 无请客: 结束会议后只展示自己
            localStorage.setItem(SUBTITLE_USAGE_MODAL, this.state.meetData.primaryKey)
        }
        const timer = setTimeout(() => {
            clearTimeout(timer)
            this.subtitleModal && this.subtitleModal.destroy()
            this.setState({ languageListModal: false })
        }, 2000)
        this.refFooter && this.refFooter.setState({ subtitlesSwitchLoading: false, switchSubtitles: true })
        let firstLang = this.refFooter.state.currentLanguage;
        navigator.languages.forEach(lang => {
            if (firstLang === "" && this.refFooter) {
                const la = lang.split('-')[0]
                if (Object.keys(this.refFooter.state.language).indexOf(la) > -1) {
                    firstLang = la
                }
            }
        })
        if (firstLang) {
            this.refFooter && this.refFooter.setState({ currentLanguage: firstLang })
            summary.openOrCloseSummary(true, this.state.currentTranslationAcccount, firstLang)
        } else {
            const modal = Modal.info({
                className: "meet-modal-languages",
                title: i18n.t('meet.pleaseSelectYourLanguage'),
                content: <div onClick={() => modal.destroy()}>
                    {this.refFooter && this.refFooter.languageRender()}
                </div>,
                footer: null,
                okText: <span className='spanModalOk'></span>
            });
        }
    }

    // 无法提供服务
    public unprovide(data) {
        const notikey = "subtitle";
        switch (data.data.code) {
            case 500:
                message.error(i18n.t("message.requestFailed"));
                this.refFooter && this.refFooter.setState({ switchSubtitles: false })
                break;
            case 11012:
                //音转文服务不存在
                notification.open({
                    closeIcon: null,
                    message: <div className="message">
                        <div className="title">{(!this.state.isLocalModerator && this.state.meetData.transliteration) ? i18n.t('HostServiceBalanceZero') : i18n.t('ServiceBalanceZero')}</div>
                        <div className="btns">
                            <div className="btn btn1" onClick={(() => notification.close(notikey + "11012"))}>{i18n.t('IKnow')}</div>
                            {
                                !this.state.enterpriseSubtitleAccount && !this.state.meetData.transliteration && <div className="btn btn2" onClick={() => {
                                    toPurchaseEnhancedService()
                                    notification.close(notikey + "11012")
                                }}>{i18n.t('servicePurchase')}</div>
                            }
                        </div>
                    </div>,
                    className: "meet-subtitle-notification",
                    placement: "bottomRight",
                    duration: null,
                    key: notikey + "11012",
                    bottom: "1.5rem" as any
                })
                this.refFooter && this.refFooter.setState({ switchSubtitles: false })
                break;
            case 11013:
                //主持人未打开音转文服务
                notification.warn({
                    message: i18n.t('dialog.hostNotOpenService'),
                    className: "meet-api-notification",
                    duration: null,
                    key: notikey
                })
                this.refFooter && this.refFooter.setState({ switchSubtitles: false })
                break;
            case 11002:
                //未找到会议或密码错误
                message.error(i18n.t('message.passwordIncorrect'));
                break;
            case 11014:
                // 音转文无余额
                notification.open({
                    closeIcon: null,
                    message: <div className="message">
                        <div className="title">{(!this.state.isLocalModerator && this.state.meetData.transliteration) ? i18n.t('HostServiceBalanceZero') : i18n.t('ServiceBalanceZero')}</div>
                        <div className="btns">
                            <div className="btn btn1" onClick={(() => notification.close(notikey + "11014"))}>{i18n.t('IKnow')}</div>
                            {
                                !this.state.enterpriseSubtitleAccount && !this.state.meetData.transliteration && <div className="btn btn2" onClick={() => {
                                    toPurchaseEnhancedService()
                                    notification.close(notikey + "11014")
                                }}>{i18n.t('servicePurchase')}</div>
                            }
                        </div>
                    </div>,
                    className: "meet-subtitle-notification",
                    placement: "bottomRight",
                    duration: null,
                    key: notikey + "11014",
                    bottom: "1.5rem" as any
                })
                this.refFooter && this.refFooter.setState({ switchSubtitles: false })
                break;
            default:
        }
    }

    // 监听服务端录制消息
    public onMessageSpeechServerRecord(data, bool) {
        switch (data.type) {
            // 服务可用
            case 'available':
                this.recordSocketAvailable()
                break;
            case 'unprovideVideoRecord': // 处理错误
                if (data.data.message === "timeOverLimit") {
                    this.recordingDurationZeroNoti()
                }
                break;
            case 'unprovideAudioRecord':
                if (data.data.message === "timeOverLimit") {
                    this.recordingDurationZeroNoti()
                }
                break;
            default:
                if (!bool) {
                    this.onMessageSpeechServer(data, true)
                }
                break;
        }
    }

    // 监听服务端录制服务可用
    public recordSocketAvailable() {
        summary.openOrCloseRecord(true, this.recordingMethod, this.state.meetData.meetServiceId)
        this.props.setMeetingRecordingData({
            open: true,
            recordingMethod: this.recordingMethod, // 录制方式
            storageType: 2,// 录制类型
            socket: (window as any)['$speechServer'],
        })
        this.sendMediaRecorderCommand({
            status: true,
            userType: "user",
            type: this.recordingMethod === 2 ? "audio" : "video"
        })
        videoLayout.mediaRecorderPromptUI({
            status: true,
            userType: "moderator",
            type: this.recordingMethod === 2 ? "audio" : "video"
        })
    }
    // 提示录制时间已用完
    public recordingDurationZeroNoti() {
        this.refFooter && this.refFooter.endRecordingUi()
        notification.open({
            closeIcon: null,
            message: <div className="message">
                <div className="title">{i18n.t('dialog.recordingDurationZero')}</div>
                <div className="btns">
                    <div className="btn btn1" onClick={(() => notification.close("recordTimeOverLimit"))}>{i18n.t('IKnow')}</div>
                </div>
            </div>,
            className: "meet-subtitle-notification",
            placement: "bottomRight",
            duration: null,
            key: 'recordTimeOverLimit',
            bottom: "1.5rem" as any
        })
        this.setState({ disableCloudRecording: true })
    }
    // 通知所有用户主持人打开或者关闭了录像录音
    public sendMediaRecorderCommand(params: IRecorderCommand) {
        this.room.sendCommandOnce(OPEN_CLOSE_MEDIA_RECORDER, {
            value: params.status,
            attributes: { ...params, participantId: this.room.myUserId() }
        })
        if (!params.status) {
            this.props.setMeetingRecordingData({
                open: false,
                socket: null,
            })
        }
    }

    // 主持人通知共享用户关闭共享
    public sendCloseDesktopCommand(participantId) {
        this.room.sendCommandOnce(CLOSE_DESKTOP, {
            value: participantId,
        })
    }

    public websocketNotice() {
        if (this.props.noticeSocket.hasOwnProperty('addEventListener'))
            this.props.noticeSocket.addEventListener('message', (receive) => {
                let msg = JSON.parse(receive.data)
                if (msg.type === 'ping') return
                if (msg.type === "insufficientBalanceOfVoiceTransfer") {//音转文服务余额限制
                    const ele = document.getElementById('meet-renew-handle')
                    if (!ele) {
                        const notikey = "insufficientBalanceOfVoiceTransfer"
                        notification.open({
                            closeIcon: null,
                            message: <div className="message">
                                <div className="title">{i18n.t('LowServiceBalance')}</div>
                                <div className="btns">
                                    <div className="btn btn1" onClick={(() => notification.close(notikey))}>{i18n.t('IKnow')}</div>
                                    <div className="btn btn2" onClick={() => {
                                        toPurchaseEnhancedService()
                                        notification.close(notikey)
                                    }}>{i18n.t('servicePurchase')}</div>
                                </div>
                            </div>,
                            className: "meet-subtitle-notification",
                            placement: "bottomRight",
                            duration: null,
                            key: notikey,
                            bottom: "1.5rem" as any
                        })
                        videoLayout.createRenewButtonElement()
                    }
                } else if (msg.type === "refreshTheBalanceOfVoiceTransfer") { //音转文余额已刷新
                    videoLayout.removeRenewButtonElement()
                    notification.close("insufficientBalanceOfVoiceTransfer")
                }
            })
    }
    //设置视频接受参数
    public setReceiverConstraints(selectedEndpoints?: string[], lastN?: number) {
        if (!selectedEndpoints && this.state.mode === "gallery") {
            this.refSpeakerMode && this.refSpeakerMode.sliceSelectedEndpoints(this.refSpeakerMode.state.startIndex, this.refSpeakerMode.state.endIndex)
            return
        } else if (!selectedEndpoints && this.state.mode === "speaker") {
            this.refSpeakerMode && this.refSpeakerMode.setSpeakerModeReceiver()
            return
        }
        const selected: string[] = [] // 优先用户
        let constraints = {}
        this.state.videoList.forEach(participant => {
            if (this.state.mode === "speaker") {
                if (participant.speaker) {//主讲人
                    selected.push(participant.participantId)
                    let maxHeight = 720
                    if (this.refFooter && this.refFooter.state.bandwidthQuality === 3) {
                        maxHeight = 2160
                    } else if (participant.sharedUser) {
                        maxHeight = 1080
                    }
                    constraints[participant.participantId] = { 'maxHeight': maxHeight }
                }
            }
        })
        if (this.state.mode === "gallery") {
            let target = this.state.videoList.filter(x => x.sharedUser === true)
            if (target.length) { // 共享用户
                selected.push(target[0].participantId)
            } else {
                target = this.state.videoList.filter(x => x.voiceIncentive === true)
                if (target.length) {// 语音激励
                    selected.push(target[0].participantId)
                } else if (this.state.videoList.length > 1) {
                    selected.push(this.state.videoList[1].participantId)
                }
            }
        }
        let N = 9
        if (this.refFooter && this.refFooter.state.bandwidthQuality === 0) {
            N = 0
        } else if (this.refFooter && this.refFooter.state.bandwidthQuality === 1) {
            N = 1
        } else if (lastN !== undefined) {
            N = lastN
        } else if ((window as any).$lastN) {
            N = (window as any).$lastN
        }
        logger.debug("ROOM", '设置视频约束', {
            'lastN': N,
            'selectedEndpoints': selectedEndpoints || [],
            'onStageEndpoints': selected,
            'defaultConstraints': { 'maxHeight': (this.state.mode === "speaker" || this.state.mode === "pictureInPicture") ? 180 : (selectedEndpoints.length < 9 ? 540 : 360) },
            'constraints': constraints
        })
        this.room && this.room.setReceiverConstraints({
            // 从bridge请求的视频数
            'lastN': N,
            // 优先的参与者的ID
            'selectedEndpoints': selectedEndpoints || [],
            // 优先级更高的参与者的ID
            'onStageEndpoints': selected,
            // 为所有端点请求的默认分辨率
            'defaultConstraints': { 'maxHeight': (this.state.mode === "speaker" || this.state.mode === "pictureInPicture") ? 180 : (selectedEndpoints.length < 9 ? 540 : 360) },
            // 端点特定分辨率
            'constraints': constraints
        })
    }

    // 获取默认发言语言
    public async getDefaultTranslation() {
        const res = await api.common.getDefaultTranslation()
        if (res.code === 200) {
            if (res.data) {
                this.state.videoList[0].translationLanguage = res.data;
                this.state.videoList[0].translationLanguageText = Object.keys(this.refFooter.state.language).length ? this.refFooter.state.language[res.data].title : "";
                videoLayout.setDisplayName(this.state.videoList[0])
                this.sendTranslationLanguageCommand(res.data, this.state.videoList[0].userId)
                this.setState({ currentTranslationLang: res.data, videoList: this.state.videoList, translationLoading: false })
                this.refFooter && this.refFooter.setState({ currentLanguage: res.data })
            } else {
                this.setState({ translationLoading: false })
            }
        } else {
            message.error(i18n.t("message.requestFailed"))
            this.setState({ translationLoading: false })
        }
    }

    // 翻译语言发生改变，广播给所有人
    public sendTranslationLanguageCommand(language: string, participantId: string) {
        this.room.sendCommandOnce(SWITCH_TRANSLATION_LANGUAGE, {
            value: language,
            attributes: { participantId }
        })
    }

    // 请客模式：通知参与人开启字幕服务
    public sendSubtitleServiceCommand(value: boolean) {
        this.room.sendCommandOnce(SWITCH_SUBTITLE_SERVICE, {
            value: value + "",
            attributes: { participantId: this.room.myUserId() }
        })
    }

    // 正在为您开启Ai翻译服务
    public showSubtitleServiceModal() {
        if (!this.subtitleModal) {
            this.subtitleModal = Modal.confirm({
                width: '3.5rem',
                title: null,
                icon: null,
                className: "subtitle-loading-modal",
                content: <div className="subtitle-loading-modal-content" >
                    <img src={require("static/images/home/icon-close-white.png")} alt="" className="closeImg" onClick={() => this.subtitleModal.destroy()} />
                    <Spin indicator={<LoadingOutlined style={{ fontSize: '0.24rem', color: "#fff" }} spin />} />
                    <div className="title" dangerouslySetInnerHTML={{ __html: i18n.t("AITranslationServer") }}></div>
                    <div className="msg">
                        <span className="item1" dangerouslySetInnerHTML={{ __html: i18n.t("UnableProvideService") }}
                            onMouseOver={() => this.setState({ languageListModal: true })}
                            onMouseLeave={() => this.setState({ languageListModal: false })}
                        ></span>
                        <span dangerouslySetInnerHTML={{ __html: i18n.t("PaidService") }}></span>
                    </div>
                </div>,
            });
        }
    }

    // 获取跳过、下一步的定位
    public getStyle() {
        if (this.state.showGuide === 1) {
            return { skip: { left: "78.59%", top: "85.92%" }, nextStep: { left: "83.22%", top: "85.92%" } }
        }
        if (this.state.showGuide === 2) {
            return { nextStep: { left: "73.38%", top: "80.92%" } }
        }
    }
    // 跳过
    public skip() {
        const guide = localStorage.getItem(GUIDE_PAGE)
        if (guide) {
            const gui = JSON.parse(guide)
            if (gui.indexOf("meet") > -1) {
                gui.splice('meet', 1)
                localStorage.setItem(GUIDE_PAGE, JSON.stringify(gui))
            }
        }
        this.setState({ showGuide: 0 })
    }

    // 下一步
    public nextStep() {
        if (this.state.showGuide === 1) {
            this.setState({ showGuide: 2 })
        }
        if (this.state.showGuide === 2) {
            this.skip()
        }
    }

    // 踢人
    public kickParticipant(participantId) {
        this.room && this.room.kickParticipant(participantId, kickCode)
    }

    // 修改权限菜单
    public changePermission(key) {
        let { permissionMenu } = this.state
        permissionMenu[key].checkout = !permissionMenu[key].checkout;
        this.setState({ permissionMenu })
        if (!this.meetingSocket) return
        if (key === "prohibitUnmute") {
            this.meetingSocket.send(JSON.stringify({
                type: "muteState",
                data: Number(permissionMenu[key].checkout)
            }))
        } else if (key === "onlyHostCanShare") {
            this.meetingSocket.send(JSON.stringify({
                type: "screenSharingState",
                data: Number(permissionMenu[key].checkout)
            }))
        } else if (key === "onlyhostCanOpenWhiteboard") {
            this.meetingSocket.send(JSON.stringify({
                type: "whiteboardStatE",
                data: Number(permissionMenu[key].checkout)
            }))
        } else if (key === "lockMeeting") {
            this.meetingSocket.send(JSON.stringify({
                type: "lockMeeting",
                data: permissionMenu[key].checkout
            }))
            message.success({
                content: i18n.t(permissionMenu[key].checkout ? 'meetingLocked' : "meetingUnlocked"),
                icon: <span></span>,
                style: {
                    marginTop: '30vh',
                },
                duration: 5,
                className: "meet-custom-message",
                key: "meetingLocked"
            })
        }
    }

    // 举手
    public raiseHand(bool) {
        this.meetingSocket && this.meetingSocket.send(JSON.stringify({
            type: "handRaisedState",
            data: bool ? 1 : 0
        }))
    }

    //主持人允许/拒绝发言
    public confirmSpeakOperation(participantId: string, status: 0 | 1, businessUserId) {
        this.meetingSocket && this.meetingSocket.send(JSON.stringify({
            type: "speak",
            data: {
                participantId, status, userId: businessUserId
            }
        }))
    }

    // 邀请发言
    public invitationToSpeak(participantId) {
        this.meetingSocket && this.meetingSocket.send(JSON.stringify({
            type: "invitationToSpeak",
            data: participantId
        }))
    }

    // 打开水印
    public openWatermark(node, participantId) {
        const list = document.getElementsByClassName("mask_div")
        if (list.length) return
        if (node) {
            if (this.state.roomWatermarkPermissions) {
                watermark(node, this.state.user.name + i18n.t('xxxwatermark'))
            } else {
                const target = this.state.videoList.filter(z => z.sharedUser === true)
                if (target.length && target[0].userId === participantId && target[0].shareScreenWatermark) {
                    watermark(node, this.state.user.name + i18n.t('xxxwatermark'))
                }
            }
        }
    }

    // 关闭水印
    public closeWatermark() {
        const list = document.getElementsByClassName("mask_div")
        if (list.length) {
            for (let i = 0; i < list.length; i++) {
                list[i].remove()
            }
            this.closeWatermark()
        }
    }

    // 直播
    public showLiveModal() {
        if (this.state.isLiveStreaming) {
            const live = document.getElementById('live-setting-component')
            if (live) live.style.zIndex = '101'
            this.refLiveSetting && this.refLiveSetting.pullStream()
        } else {
            this.setState({ isLiveStreaming: true })
        }
    }

    // 更新直播信息
    public updateLiveInfo(data) {
        this.meetingSocket && this.meetingSocket.send(JSON.stringify({
            type: "updateLiveInfo",
            data
        }))
    }

    // 自动更新默认直播配置
    public updateLiveInfoDefault() {
        console.log("自动更新默认直播配置", this.state.liveStatus, this.state.isLocalModerator, this.state.meetData.live)
        if (this.state.liveStatus === 0 || !this.state.isLocalModerator || this.state.meetData.live !== 0) return
        let sharedPId = ""
        let hostPId = ""
        let otherHostPId = ""
        this.state.videoList.forEach(x => {
            if (x.sharedUser) {
                sharedPId = x.userId
            }
            if (x.businessUserId === this.state.meetData.hostUserId) {
                hostPId = x.userId
            } else if (!otherHostPId) {
                otherHostPId = x.userId
            }
        })
        const obj = {
            type: 0,
            data: {
                main: {
                    activity: false,
                    id: sharedPId || hostPId || otherHostPId
                },
                questioner: null,
                speaker: {
                    activity: true,
                    id: ""
                },
                speakerPosition: 1
            }
        }
        this.refLiveSetting && this.refLiveSetting.initDefault(obj)
    }
    // 更新直播布局
    public updateLiveLayoutInfo() {
        if (this.state.liveStatus === 2 && this.state.liveLayoutInfo) {
            this.refLiveSetting && this.refLiveSetting.initDefault(this.state.liveLayoutInfo, true)
        }
    }
    // 开始直播
    public startLive() {
        this.meetingSocket && this.meetingSocket.send(JSON.stringify({
            type: "startLive"
        }))
        this.setState({ liveStatus: 1, isLiveStreaming: true }, () => {
            const live = document.getElementById('live-setting-component')
            if (live) live.style.zIndex = '-1'
            this.updateLiveInfoDefault()
        })
    }
    // 关闭直播
    public closeLive() {
        this.meetingSocket && this.meetingSocket.send(JSON.stringify({
            type: "closeLive",
        }))
    }

    // 更新直播中的共享
    public updateLiveInfoShareScreen(id) {
        if (this.refLiveSetting && this.refLiveSetting.state.liveStreaming) { // 在直播，需要更新对应共享
            const { mode, speakerData, sidebarData } = this.refLiveSetting.state;
            if (mode === "speaker") {
                speakerData.main.id = id;
                this.refLiveSetting.setState({ speakerData }, () => this.refLiveSetting.updateLiveInfo())
            } else if (mode === "sidebar") {
                sidebarData.main.id = id
                this.refLiveSetting.setState({ sidebarData }, () => this.refLiveSetting.updateLiveInfo())
            }
        }
    }

    // 切换模式
    public async switchMode(mode) {
        if (mode === 'gallery') {
            try {
                this.refSpeakerMode.swiperSpeaker.destroy(false, true)
            } catch (e) {
                logger.warn('ROOM', 'swiperInit', e)
            } finally {
                console.log(new Date(), "swntech", 'swiperInit', this.refSpeakerMode.swiperSpeaker.destroyed)
                if (this.refSpeakerMode.swiperSpeaker.destroyed) {
                    this.setState({ mode }, async () => {
                        videoLayout.removeTopLeftUserContainer()
                        videoLayout.removeSpeakerUserContainer()
                        await videoLayout.switchMode(mode)
                        const ele = document.getElementById("filmstripRemoteVideosContainer")
                        if (ele) ele.style.transform = 'translate3d(0px, 0px, 0px)'
                        this.updateAddTrackNode()
                        this.refSpeakerMode.setState({ showNavMenu: true })
                        this.setReceiverConstraints()
                    })
                }
            }
        } else if (mode === 'speaker') {
            console.log(new Date(), "swntech", 'swiperInit', mode)
            if (this.refSpeakerMode.swiperSpeaker.destroyed) {
                await videoLayout.switchMode(mode)
                const timer = setTimeout(async () => {
                    await this.refSpeakerMode.swiperInit()
                    clearTimeout(timer)
                }, 400)
                await this.setState({ mode }, () => {
                    if (this.state.videoList.length <= 2) {
                        videoLayout.addTopLeftUserContainer(this.state.videoList[0])
                    }
                    const target = this.state.videoList.filter(x => x.speaker)
                    if (target.length) {
                        videoLayout.addSpeakerUserContainer({
                            userdata: target[0]
                        })
                    }
                    this.updateAddTrackNode()
                    this.refSpeakerMode.setState({ showNavMenu: true })
                    this.setReceiverConstraints()
                })
            }
        } else if (mode === "pictureInPicture") {
            try {
                this.refSpeakerMode.swiperSpeaker.destroy(false, true)
            } catch (e) {
                logger.warn('ROOM', 'swiperInit', e)
            } finally {
                console.log(new Date(), "swntech", 'swiperInit', mode)
                if (this.refSpeakerMode.swiperSpeaker.destroyed) {
                    this.setState({ mode, showFooter: false }, async () => {
                        videoLayout.removeTopLeftUserContainer()
                        await videoLayout.switchMode(mode, this.state.videoList)
                        const ele = document.getElementById("filmstripRemoteVideosContainer")
                        if (ele) ele.style.transform = 'translate3d(0px, 0px, 0px)'
                        this.updateAddTrackNode()
                        this.refSpeakerMode.setState({ showNavMenu: true })
                        this.setReceiverConstraints()
                        this.updatePictureInPictureUser()
                        this.pictureInPicturevoiceExcitationTimer()
                    })
                }
            }
        }
    }

    // 获取设备直播推流地址
    public getPushStreamAddress(data) {
        this.meetingSocket && this.meetingSocket.send(JSON.stringify({
            type: "getPushStreamAddress",
            data
        }))
    }

    // 设备推流成功通知
    public pushStreamSuccess(data) {
        this.meetingSocket && this.meetingSocket.send(JSON.stringify({
            type: "pushStreamSuccess",
            data
        }))
    }

    // 第三方设备加入会议
    public streamSuccessJoin(data) {
        this.meetingSocket && this.meetingSocket.send(JSON.stringify({
            type: "streamSuccessJoin",
            data
        }))
        this.state.joiningDeviceList.push(data)
        localStorage.setItem(JOINING_DEVICE_LIST, JSON.stringify(this.state.joiningDeviceList))
    }

    //第三方设备移除会议
    public streamLeaveChat(id) {
        const target = this.state.joiningDeviceList.filter(x => x.stream.id === id)
        if (target.length) {
            this.meetingSocket && this.meetingSocket.send(JSON.stringify({
                type: "streamLeaveChat",
                data: target[0].stream
            }))
        }
    }
    // 设置video为360全景
    public set360videojs(id) {
        const timer = setTimeout(() => {
            timer && clearTimeout(timer)
            const videoId = "speaker" + id;
            // if(document.getElementById(videoId)) return
            let player = this.videoVrPlayers[videoId]
            player && player.dispose()
            player = videojs(videoId)
            this.videoVrPlayers[videoId] = player;
            player.mediainfo = player.mediainfo || {};
            player.mediainfo.projection = '360';
            player.controls(false)
            player.debug(false)
            player.vr({ projection: 'AUTO', debug: false, forceCardboard: true });
            player.on('loadedmetadata', (e) => {
                const canvas = document.getElementsByTagName("canvas")
                for (let i = 0; i < canvas.length; i++) {
                    canvas[i].style.zIndex = '1'
                }
            })
            player.on('dispose', function (e) {
                console.log('dispose')
            })
        }, 1000);
    }
    //通知当前选定的输入设备没有信号
    public async noAudioInput(e) {
        logger.info("ROOM", "通知当前选定的输入设备没有信号", e)
        // 遍历系统上所有音频设备找到有音频信号的设备
        const activeDevice = await JitsiMeetJS.getActiveAudioDevice();
        logger.info('ROOM', activeDevice)
        let descriptionKey = 'dialog.noAudioSignalDesc';
        let customActionNameKey;
        let customActionHandler;
        let notificationKey = "noAudioSignalTitle"
        if (activeDevice.deviceLabel !== '') {
            descriptionKey = 'dialog.noAudioSignalDescSuggestion';
            customActionNameKey = `${i18n.t('dialog.SwitchTo')} ${formatDeviceLabel(activeDevice.deviceLabel)}`;
            customActionHandler = () => {
                // 切换到检测的设备上
                this.setAudioInputDevice(activeDevice.deviceId)
                notification.close(notificationKey)
            };
        }
        notification.info({
            message: <div>
                <div>{i18n.t('dialog.noAudioSignalTitle')}</div>
                <div style={{ fontSize: 13 }}>{i18n.t(descriptionKey)}</div>
                {
                    customActionNameKey && <div style={{ color: "#4d79f3", cursor: "pointer" }}
                        onClick={customActionHandler}>{customActionNameKey}</div>
                }
            </div>,
            placement: "bottomLeft",
            duration: null,
            key: notificationKey,
            onClose: () => {
                this.noAudioInputTimer && clearInterval(this.noAudioInputTimer)
            }
        });
        // dispatch(playSound(NO_AUDIO_SIGNAL_SOUND_ID));
        // 需要保存key，然后在AUDIO_INPUT_STATE_CHANGE监听事件里关掉
        this.noAudioSignalNotificationKey = notificationKey
    }
    // 开启白板
    public sharedWhiteboard(data) {
        this.setState({ whiteboardLoadingProgress: { count: 0, current: 0, percent: 0 } }, () => {
            this.whiteboardData = new Whiteboard(data, this.meetingSocket, this)
        })
    }
    // 网络条件提示
    public networkConditionsMessage() {
        if (this.networkConditionsMessageTime === 0 || (new Date().getTime() - this.networkConditionsMessageTime > 180000)) {
            message.success({
                content: i18n.t('dialog.PoorNetworkConditionsTips'),
                icon: <span></span>,
                style: {
                    marginTop: '30vh',
                },
                duration: 5,
                className: "meet-custom-message",
                key: "PoorNetworkConditionsTips"
            })
            this.networkConditionsMessageTime = new Date().getTime()
        }
    }
    // 主持人开关画中画跟随
    public async switchPictureInPicture(bool, data) {
        this.meetingSocket && this.meetingSocket.send(JSON.stringify({
            type: bool ? "pictureInPicture" : "closePictureInPicture",
            data: data || ""
        }))
        const pData = this.props.pictureInPictureData
        if (data) {
            this.sendRemotePictureData = data
            this.switchMode("pictureInPicture")
        } else {
            this.sendRemotePictureData = null
            await this.props.setPictureInPictureData(defaultPictureInPictureData)
            this.updatePictureInPictureUser()
        }
    }
    // 获取画中画需要展示的用户列表
    public updatePictureInPictureUser(isSendWs?: boolean) {
        videoLayout.deleteAllPictureLeaveUserOccupiedUI()
        const participants = this.state.videoList
        let userList = [];
        let mainWindowUser: IParticipantItem = null
        let picture1User: IParticipantItem = null
        let picture2User: IParticipantItem = null
        let sharedUser: IParticipantItem = null
        let voiceIncentiveUser: IParticipantItem = null
        let defaultUser: IParticipantItem = null
        const pData = this.props.pictureInPictureData
        if (pData && pData.switch) {
            const surplusUser: IParticipantItem[] = [] // 剩余的参与人
            const surplusUser2: IParticipantItem[] = []
            participants.forEach(p => {
                if (p.sharedUser) {
                    sharedUser = p
                    console.log("主画面共享")
                } else {
                    surplusUser.push(p)
                }
            });
            surplusUser.forEach(p => {
                if (!sharedUser && pData.hasOwnProperty("mainPicture") && (pData.mainPicture.value === p.businessUserId || pData.mainPicture.userId === p.businessUserId)) { // 没有共享时，优先指定
                    mainWindowUser = p
                    console.log("主画面指定")
                } else if (!picture1User && pData.hasOwnProperty("picture1") && pData.picture1.value === p.businessUserId) {
                    picture1User = p
                    console.log("画中画1指定")
                } else if (pData.hasOwnProperty("picture2") && pData.picture2.value === p.businessUserId) {
                    picture2User = p
                    console.log("画中画2指定")
                } else {
                    surplusUser2.push(p)
                }
            })
            surplusUser2.forEach(p => {
                if (!sharedUser && !mainWindowUser && pData.hasOwnProperty("mainPicture") && pData.mainPicture.value === "systemAutomatic") { // 没有共享，没有指定时，按顺序选一个
                    mainWindowUser = p
                    console.log("主画面自动")
                } else if (!voiceIncentiveUser && p.voiceIncentive) {
                    console.log("语音激励指定1")
                    voiceIncentiveUser = p
                } else if (!defaultUser) {// 默认一个
                    defaultUser = p
                }
            })
            // 主画面指定人离开了
            if (!sharedUser && !mainWindowUser && pData.mainPicture.value && pData.mainPicture.value !== "systemAutomatic") {
                videoLayout.pictureLeaveUserOccupiedUI(0, pData.template)
                userList.push({ userId: "", switchedId: "" })
                this.setState({ showFooter: true })
            } else {
                userList = [sharedUser || mainWindowUser || picture2User || voiceIncentiveUser || picture1User || defaultUser]
            }
            console.log(sharedUser, mainWindowUser, picture2User, voiceIncentiveUser, picture1User, defaultUser)
            if (pData.picture1.value === "voiceStimulation") {
                userList.push(voiceIncentiveUser || defaultUser || { userId: "", switchedId: "" })
                console.log("画中画1语音激励")
            } else {
                picture1User && userList.push(picture1User)
            }
            // 画中画1指定人离开了
            if (userList.length === 1 && pData.picture1.value && pData.picture1.value !== "voiceStimulation") {
                videoLayout.pictureLeaveUserOccupiedUI(1, pData.template)
                userList.push({ userId: "", switchedId: "" })
            }
            if (pData.template === 3 || pData.template === 4) {
                if (pData.picture2.value === "voiceStimulation") {
                    userList.push(voiceIncentiveUser || defaultUser || { userId: "", switchedId: "" })
                    console.log("画中画2语音激励")
                } else {
                    picture2User && userList.push(picture2User)
                }
                // 画中画2指定人离开了
                if (userList.length === 2 && pData.picture1.value && pData.picture1.value !== "voiceStimulation") {
                    videoLayout.pictureLeaveUserOccupiedUI(2, pData.template)
                    userList.push({ userId: "", switchedId: "" })
                }
            }
        } else {
            if (participants) {
                if (participants.length === 1) {
                    userList[0] = participants[0]
                } else if (participants.length === 2) {
                    userList[0] = participants[1]
                    userList[1] = participants[0]
                } else {
                    participants.forEach(p => {
                        if (p.sharedUser) {
                            sharedUser = p
                            console.log("主画面共享")
                        } else if (p.local && !p.sharedUser) {
                            picture1User = p
                            console.log("画中画1")
                        } else if (!voiceIncentiveUser && p.voiceIncentive) {
                            console.log("语音激励")
                            voiceIncentiveUser = p
                        }
                    });
                    participants.forEach(p => {
                        if (sharedUser && sharedUser._displayName.indexOf(p.userId) > -1) {//有共享时，画面1展示共享开启人
                            console.log(p, '画面1显示共享开启人')
                            picture2User = picture1User
                            picture1User = p
                        } else if (!sharedUser && !mainWindowUser && picture1User.userId !== p.userId) {
                            mainWindowUser = p
                            if (voiceIncentiveUser && voiceIncentiveUser.userId === p.userId) { // 主画面和语音激励去重
                                voiceIncentiveUser = null
                            }
                        } else if (picture1User.userId !== p.userId && !p.sharedUser && (voiceIncentiveUser ? voiceIncentiveUser.userId !== p.userId : true)) {
                            picture2User = p
                        }
                    });
                    console.log(voiceIncentiveUser, mainWindowUser, picture2User)
                    userList = [sharedUser || mainWindowUser, picture1User, voiceIncentiveUser || picture2User]
                }
            }
        }
        userList.forEach(p => { p.switchedId = "" }) // 重新切换模式后，把窗口互相切换的数据清空
        this.setState({ pictureInPictureUser: userList }, this.setPictureInPictureStyle.bind(this, isSendWs))
    }
    // 设置画中画样式
    public setPictureInPictureStyle(isSendWs?: boolean) {
        const pData = this.props.pictureInPictureData
        videoLayout.setPictureInPictureStyle(this.state.pictureInPictureUser, (pData && pData.hasOwnProperty("template") && pData.template) || 1)
        if (isSendWs && this.sendRemotePictureData) {
            if (this.state.pictureInPictureUser.length) {
                this.sendRemotePictureData.mainWindow.type = pData.mainPicture.value === "systemAutomatic" ? 1 : 3;
                this.sendRemotePictureData.mainWindow.userId = this.state.pictureInPictureUser[0].businessUserId;
                this.sendRemotePictureData.mainWindow.dataId = this.state.pictureInPictureUser[0].userId;
                this.meetingSocket && this.meetingSocket.send(JSON.stringify({
                    type: "pictureInPicture",
                    data: this.sendRemotePictureData
                }))
            }
        }
    }
    //主持人开启跟随
    public openPictureInPictureWs(data) {
        console.log(data, '收到的画中画')
        const picture = {
            switch: true,
            template: data.stencil,
            mainPicture: {
                value: "",
                userId: ""
            },
            picture1: {
                value: "",
            },
            picture2: {
                value: "voiceStimulation",
            }
        }
        if (data.mainWindow) {
            if (data.mainWindow.type === 3) {
                picture.mainPicture.value = data.mainWindow.userId;
            } else if (data.mainWindow.type === 2) {
                picture.mainPicture.value = "voiceStimulation";
            } else if (data.mainWindow.type === 1) {
                picture.mainPicture.value = "systemAutomatic";
                picture.mainPicture.userId = data.mainWindow.userId
            }
        }
        data.windows && data.windows.forEach(win => {
            if (win.number === 1 && win.hide === false) {
                if (win.type === 3) {
                    picture.picture1.value = win.userId;
                } else if (win.type === 2) {
                    picture.picture1.value = "voiceStimulation";
                }
            } else if (win.number === 2 && win.hide === false) {
                if (win.type === 3) {
                    picture.picture2.value = win.userId;
                }
            }
        });
        const { pictureInPictureData } = this.props
        const targetUser = data.hasOwnProperty("openId") ? this.state.videoList.filter(p => p.businessUserId === data.openId) : []
        const modal = Modal.confirm({
            width: 320,
            title: null,
            icon: null,
            className: "picture-loading-modal",
            content: <div className="content" >
                <div className="title" dangerouslySetInnerHTML={{
                    __html:
                        i18n.t(pictureInPictureData && pictureInPictureData.hasOwnProperty("switch") && pictureInPictureData.switch ? "message.hostUpdatePictureInPicture" : "message.hostOpenPictureInPicture", { name: targetUser.length ? targetUser[0]._displayName : "" })
                }}></div>
                <Spin key="pictureSpin" tip="" />
            </div>,
        });
        const timer = setTimeout(() => {
            clearTimeout(timer)
            modal && modal.destroy()
        }, 2000)
        this.props.setPictureInPictureData(picture)
        if (this.state.mode === "pictureInPicture") {
            this.updatePictureInPictureUser() // 更新画中画
            if (this.state.isLocalModerator && this.state.moreMenuActive === "set") { // 多主持人时
                this.refSetCamera && this.refSetCamera.setPictureInPictureData()
            }
        } else { // 打开画中画
            this.setState({ mode: "pictureInPicture" })
            this.switchMode("pictureInPicture")
        }
    }
    //关闭跟随
    public async closePictureInPictureWs(data) {
        this.setState({ pictureInPictureUser: [], showFooter: true })
        const targetUser = data.hasOwnProperty("userId") ? this.state.videoList.filter(p => p.businessUserId === data.userId) : []
        await this.props.setPictureInPictureData(defaultPictureInPictureData)
        const modal = Modal.confirm({
            width: 320,
            title: null,
            icon: null,
            className: "picture-loading-modal",
            content: <div className="content" >
                <div className="title" dangerouslySetInnerHTML={{ __html: i18n.t("message.hostClosePictureInPicture", { name: targetUser.length ? targetUser[0]._displayName : "" }) }}></div>
                <Spin key="pictureSpin" tip="" />
            </div>,
        });
        setTimeout(() => {
            modal && modal.destroy()
        }, 1500)
        await this.updatePictureInPictureUser() // 恢复默认规则
        if (this.state.isLocalModerator && this.state.moreMenuActive === "set") { // 多主持人时
            this.refSetCamera && this.refSetCamera.setPictureInPictureData()
        }
    }
    // 画中画：如果画中画2是语音激励，5秒没人说话就隐藏
    pictureInPicturevoiceExcitationTimer() {
        if (this.pictureInPictureTimer) { // 先清除之前的定时器
            this.pictureInPictureTimer && clearTimeout(this.pictureInPictureTimer)
            this.pictureInPictureTimer = null
        }
        this.pictureInPictureTimer = setTimeout(() => {
            if (this.state.mode !== "pictureInPicture") return
            // 如果超过五秒
            let pData = this.props.pictureInPictureData
            if (this.state.pictureInPictureUser.length === 3) {
                if (pData && ((!pData.hasOwnProperty("switch") || pData.switch === false) || (pData.hasOwnProperty("picture2") && pData.picture2.value === "voiceStimulation"))) {
                    this.state.pictureInPictureUser.pop()
                    this.setState({ pictureInPictureUser: this.state.pictureInPictureUser }, this.setPictureInPictureStyle.bind(this))
                }
            }
            this.pictureInPicturevoiceExcitationTimer()
        }, 5000)
    }
    // 自动播放失败的用户操作提示
    public allowAutoplayFun() {
        if (this.allowAutoplayModal) return
        this.allowAutoplayModal = Modal.confirm({
            title: null,
            icon: null,
            className: "cast-modal",
            content: <div className="cast-modal-content" >
                <div>{i18n.t("dialog.allowAutoplayMsg")}</div>
                <div className="btns">
                    <div id="allowAutoplayBtn" className="btn2" onClick={() => {
                        this.allowAutoplayModal.destroy()
                    }}>{i18n.t("login.confirm")}</div>
                </div>
            </div>,
        })
    }
    public render() {
        const { mode, loading, whiteboardLoadingProgress, isLocalModerator } = this.state;
        const meet1 = i18n.t('guideImages.meet1')
        const meet2 = i18n.t('guideImages.meet2')
        return <div className='meetPage' id="meetPage" onMouseMove={(e) => {
            if (e.clientY < 5 && this.state.showNavMenu === false) {
                this.setState({ showNavMenu: true }, () => {
                    const tiemr = setTimeout(() => {
                        this.setState({ showNavMenu: false })
                        clearTimeout(tiemr)
                    }, 3000)
                })
            }
        }}>
            {/* 引导页 */}
            {
                this.state.showGuide === 1 && <img className='guide' src={require(`static/images/guide/${meet1}.png`)} alt="" />
            }
            {
                this.state.showGuide === 2 && <img className='guide' src={require(`static/images/guide/${meet2}.png`)} alt="" />
            }
            {
                this.state.showGuide === 1 && <div className='skip' style={this.getStyle()?.skip} onClick={this.skip.bind(this)}>{i18n.t("skip")}</div>
            }
            {
                this.state.showGuide === 1 && <div className='nextStep' style={this.getStyle()?.nextStep} onClick={this.nextStep.bind(this)}>{i18n.t('nextStep')}</div>
            }

            {
                this.state.showGuide === 2 && <div className='nextStep' style={this.getStyle()?.nextStep} onClick={this.nextStep.bind(this)}>{i18n.t('IKnow')}</div>

            }
            {
                this.state.connection_failed && <DisconnectPage waitingJoin={this.state.waitingJoin} customExceptionConnection={this.state.customExceptionConnection}
                    close={() => this.setState({ connection_failed: false, customExceptionConnection: false })} isWhiteboardCreator={this.refFooter.state.isWhiteboardCreator} />
            }
            <div className='contentBox'>
                {loading && <div className="loadingImg"><img src={require("static/images/meet/loading.png")} alt="" /><p>{i18n.t("meet.conferenceIsConnecting")}</p></div>}
                <SpeakerMode
                    ref={(ref) => this.refSpeakerMode = ref}
                    mode={mode}
                    participants={this.state.videoList || []}
                    pictureInPictureData={this.props.pictureInPictureData}
                    updateAddTrackNode={this.updateAddTrackNode.bind(this)}
                    showNavMenu={(bool) => {
                        this.setState({ showNavMenu: bool === this.state.showNavMenu ? bool : !this.state.showNavMenu }, () => {
                            if (this.state.showNavMenu) {
                                const tiemr = setTimeout(() => {
                                    this.setState({ showNavMenu: false })
                                    clearTimeout(tiemr)
                                }, 3000)
                            }
                        })
                    }}
                    setReceiverConstraints={this.setReceiverConstraints.bind(this)} />
            </div>
            <div></div>
            <Drawer
                mask={false}
                placement="bottom"
                closable={false}
                visible={this.state.showFooter}
                getContainer={false}
                className="rightMenuDrawer"
                style={{
                    height: "9.07%",
                    display: "flex",
                    justifyContent: "spaceBetween",
                    alignItems: "center",
                    border: "0px",
                    boxShadow: "none"
                }}
                zIndex={1}
            >
                <Footer
                    {...this.props}
                    videoList={this.state.videoList}
                    isLocalModerator={this.state.isLocalModerator}
                    meetingSocket={this.meetingSocket}
                    iframeWhiteboard={this.iframeWhiteboard}
                    ref={(ref) => { if (ref) this.refFooter = ref; }}
                    mode={this.state.mode}
                    whiteboardData={this.whiteboardData}
                    recordData={this.props.recordData}
                    setMode={(mode) => this.switchMode(mode)}
                    setLacalMute={this.setLacalMute.bind(this)}
                    switchVideoDesktop={this.switchVideoDesktop.bind(this)}
                    switchVideo={this.switchVideo.bind(this)}
                    stopMeet={this.stopMeet.bind(this)}
                    leaveMeet={this.leaveMeet.bind(this)}
                    grantOwner={this.grantOwner.bind(this)}
                    showSummary={async (value) => {
                        //连接语音转文本服务
                        if (value === true || value === 1) {
                            this.setState({ selectAccountModal: this.state.meetData.transliteration ? false : (value === true ? true : false) }, () => {
                                const timer = setInterval(async () => {
                                    if (!this.state.selectAccountModal) {
                                        clearInterval(timer)
                                        if (this.state.closeAccountModal) { // 用户关闭了账号选择框
                                            this.setState({ closeAccountModal: false })
                                            return
                                        }
                                        if (this.state.meetData.transliteration && this.state.isLocalModerator) {
                                            await api.meeting.setTransliterationStart(this.state.meetData.primaryKey)
                                        }
                                        if (value === true) {
                                            this.showSubtitleServiceModal()
                                            summary.speechServerWebSocket(this.state.meetData.primaryKey, this.state.user.primaryKey, this.onMessageSpeechServer.bind(this), this.summarySocketAvailable.bind(this))
                                        }
                                        if (this.state.meetData.transliteration && this.state.isLocalModerator) {// 我是主持人并且开启了字幕请客模式，发广播让其他用户都开启字幕
                                            this.sendSubtitleServiceCommand(true)
                                        }
                                    }
                                }, 200)
                            })
                        } else {
                            if (this.state.meetData.transliteration && this.state.isLocalModerator) {
                                await api.meeting.setTransliterationStop(this.state.meetData.primaryKey)
                                this.sendSubtitleServiceCommand(false)
                            }
                            summary.openOrCloseSummary(false, this.state.currentTranslationAcccount, this.state.currentTranslationLang)
                        }
                    }}
                    setStartMutedPolicy={this.setStartMutedPolicy.bind(this)}
                    hideMenu={() => this.setState({ showFooter: false })}
                    sendTextMessage={(text) => this.room.sendTextMessage(text)}
                    setMoreMenu={(value) => this.setState({ moreMenuActive: value })}
                    showInviteCodeModal={() => {
                        this.setState({ showInviteCodeFooterModal: true }, () => {
                            const timer = setTimeout(() => {
                                this.setState({ showInviteCodeFooterModal: false })
                                clearTimeout(timer)
                            }, 10000)
                        })
                    }}
                    updateAddTrackNode={this.updateAddTrackNode.bind(this)}
                    chatMessage={this.state.chatMessage}
                    showHideReportProblem={() => this.setState({ switchReportProblem: !this.state.switchReportProblem })}
                    showHideMeetingRecording={() => this.setState({ switchMeetingRecording: !this.state.switchMeetingRecording })}
                    setDisplayName={this.setDisplayName.bind(this)}
                    muteRemote={this.muteRemote.bind(this)}
                    closeCameraRemote={this.closeCameraRemote.bind(this)}
                    setShowGuide={() => {
                        if (this.state.showGuide === 1) {
                            this.setState({ showGuide: 0 })
                        } else {
                            this.setState({ showGuide: 1 })
                        }
                    }}
                    switchParticipantTranslationLang={(lang, userId) => {
                        const target = this.state.videoList.filter(x => x.userId === userId);
                        if (target.length) {
                            target[0].translationLanguage = lang;
                            target[0].translationLanguageText = Object.keys(this.refFooter.state.language).length ? this.refFooter.state.language[lang].title : "";
                            videoLayout.setDisplayName(target[0])
                            if (target[0].local) {
                                this.refFooter && this.refFooter.setState({ currentLanguage: lang })
                                this.setState({ currentTranslationLang: lang })
                            }
                        }
                        this.setState({ videoList: this.state.videoList })
                        this.sendTranslationLanguageCommand(lang, userId)
                    }}
                    currentTranslationAcccount={this.state.currentTranslationAcccount}
                    setDesktopParams={(type) => {
                        const target = this.state.videoList.filter(x => x.sharedUser === true)
                        if (target && target.length) {
                            const desktop = target[0].list.filter(x => x.videoType === "desktop")
                            if (desktop.length) {
                                desktopApplyConstraints(type, desktop[0].jitsiTrack)
                            }
                        }
                    }}
                    // sendWhiteboardCommand={this.sendWhiteboardCommand.bind(this)}
                    kickParticipant={this.kickParticipant.bind(this)}
                    sendCloseDesktopCommand={this.sendCloseDesktopCommand.bind(this)}
                    setAllMutedPolicy={this.setAllMutedPolicy.bind(this)}
                    permissionMenu={this.state.permissionMenu}
                    changePermission={this.changePermission.bind(this)}
                    raiseHand={this.raiseHand.bind(this)}
                    myPermission={this.state.myPermission}
                    confirmSpeakOperation={this.confirmSpeakOperation.bind(this)}
                    invitationToSpeak={this.invitationToSpeak.bind(this)}
                    showLiveModal={this.showLiveModal.bind(this)}
                    raiseHandsList={this.state.raiseHandsList}
                    remoteLiveUserList={this.state.remoteLiveUserList}
                    liveStreaming={this.state.liveStatus === 2 ? true : false}
                    setReceiverConstraints={this.setReceiverConstraints.bind(this)}
                    showJoinDevice={() => this.setState({ switchJoinDevice: !this.state.switchJoinDevice })}
                    previewDevice={(id) => {
                        const target = this.state.joiningDeviceList.filter(x => x.stream.id === id)
                        if (target.length) {
                            this.setState({ switchJoinDevice: true, previewDevice: target[0] })
                        }
                    }}
                    streamLeaveChat={this.streamLeaveChat.bind(this)}
                    sharedWhiteboard={this.sharedWhiteboard.bind(this)}
                    sendMediaRecorderCommand={this.sendMediaRecorderCommand.bind(this)}
                />
            </Drawer>
            <div className='chatMessageBox'>
                <ChatMessage ref={(ref) => this.refChatMessage = ref} chatMessage={this.state.chatMessage} />
            </div>
            {
                this.state.moreMenuActive === 'set' && <SetCamera
                    ref={(ref) => this.refSetCamera = ref}
                    menu={['videoinput', 'audioinput', 'audiooutput', "bgSet", (!isLocalModerator && !this.refFooter.state.shared) ? "" : "watermark", isLocalModerator ? "pictureInPicture" : ""]}
                    settingParams={this.state.settingParams}
                    participants={this.state.videoList}
                    setVideoInputDevice={this.setVideoInputDevice.bind(this)}
                    setAudioInputDevice={this.setAudioInputDevice.bind(this)}
                    setAudiooutputDevice={this.setAudiooutputDevice.bind(this)}
                    setBgImg={this.setBgImg.bind(this)}
                    onClose={() => this.setState({ moreMenuActive: "" })}
                    switchWatermark={this.switchWatermark.bind(this)}
                    switchPictureInPicture={this.switchPictureInPicture.bind(this)} />
            }
            {this.state.showInviteCodeFooterModal && <InvitationModal style={{
                position: "absolute",
                bottom: "0.80rem",
                left: "0.80rem",
            }}
                defaultCopy={true} meetData={this.state.meetData} index={1}
                onClose={() => this.setState({ showInviteCodeFooterModal: false })}
                updateData={(data) => {
                    this.setState({
                        meetData: {
                            ...this.state.meetData,
                            ...data
                        }
                    })
                }} />}
            {
                ((this.state.showInviteCodeModal && this.state.videoList.length <= 1 && this.state.isLocalModerator)) &&
                <InvitationModal
                    style={{
                        position: "absolute",
                        bottom: "0.80rem",
                        left: "0.80rem",
                    }}
                    meetData={this.state.meetData} defaultCopy={true} index={2}
                    onClose={() => this.setState({ showInviteCodeModal: false })}
                    updateData={(data) => {
                        this.setState({
                            meetData: {
                                ...this.state.meetData,
                                ...data
                            }
                        })
                    }} />}

            { // 会议主题
                this.state.showNavMenu && <MeetTheme localParticipant={this.state.videoList.filter(x => x.local && !x.sharedUser)[0]}
                    showConnectionQuality={() => this.setState({ showConnectionQuality: !this.state.showConnectionQuality })} />
            }
            {
                this.refFooter && this.refFooter.state.switchSubtitles && <Summary
                    ref={(ref) => this.refSummary = ref}
                    participants={this.state.videoList}
                    summaryMessage={this.state.summaryMessage} />
            }
            { // 报告问题
                this.state.switchReportProblem && <ReportProblem
                    meetingListId={this.state.meetData.primaryKey}
                    onClose={() => this.setState({ switchReportProblem: false })}
                    type="6"
                    styleType="1"
                />
            }
            {this.state.waitingModeratorJoin && <WaitingJoinMeet />}
            {
                !this.state.translationLoading && !this.state.currentTranslationLang && <SetLang
                    onClose={(lang) => {
                        this.state.videoList[0].translationLanguage = lang;
                        this.state.videoList[0].translationLanguageText = Object.keys(this.refFooter.state.language).length ? this.refFooter.state.language[lang].title : "";
                        videoLayout.setDisplayName(this.state.videoList[0])
                        this.sendTranslationLanguageCommand(lang, this.state.videoList[0].userId)
                        this.setState({ currentTranslationLang: lang, videoList: this.state.videoList }, () => {
                            if (this.state.isWaitOpenSubtitle) {
                                this.showSubtitleServiceModal()
                                summary.speechServerWebSocket(this.state.meetData.primaryKey, this.state.user.primaryKey, this.onMessageSpeechServer.bind(this), this.summarySocketAvailable.bind(this))
                            }
                        })
                    }} />
            }
            {this.state.selectAccountModal && <SetAccount
                closeModal={() => {
                    this.setState({ selectAccountModal: false, closeAccountModal: true })
                }}
                onClose={(value, isEnterprise) => {
                    this.setState({ selectAccountModal: false, currentTranslationAcccount: value, enterpriseSubtitleAccount: isEnterprise })
                }} />}

            {this.state.languageListModal && <LanguageList />}
            {
                this.state.showConnectionQuality && <ConnectionQuality participants={this.state.videoList} onClose={() => {
                    this.setState({ showConnectionQuality: false })
                }} />
            }
            {
                this.state.showWhiteboardMask && <div className='whiteboardMask'>
                    {
                        whiteboardLoadingProgress.count === 0 ? <Spin tip={i18n.t("loading")} /> : <div className="progressText">
                            <div>{i18n.t("loadingHistoricalData")}</div>
                            <Progress style={{ width: "5.94rem", height: "0.2rem" }} percent={whiteboardLoadingProgress.percent}
                                strokeColor={{ '0%': 'rgba(78, 180, 255, 1)', '100%': 'rgba(78, 124, 255, 1)' }}
                                format={() => `${whiteboardLoadingProgress.current}/${whiteboardLoadingProgress.count}`} />
                        </div>
                    }
                </div>
            }

            { // 直播设置
                this.state.isLiveStreaming && <LiveSetting
                    ref={(ref) => this.refLiveSetting = ref}
                    participants={this.state.videoList}
                    meetData={this.state.meetData}
                    userId={this.state.user.primaryKey}
                    liveStatus={this.state.liveStatus}
                    close={() => this.setState({ isLiveStreaming: false })}
                    updateLiveInfo={this.updateLiveInfo.bind(this)}
                    closeLive={this.closeLive.bind(this)} />
            }
            { // 手动加入设备
                this.state.switchJoinDevice && <JoinDevice
                    ref={(ref) => this.refJoinDevice = ref}
                    meetingListId={this.state.meetData.primaryKey}
                    preview={this.state.previewDevice}
                    onClose={() => this.setState({ switchJoinDevice: false, previewDevice: false })}
                    getPushStreamAddress={this.getPushStreamAddress.bind(this)}
                    pushStreamSuccess={this.pushStreamSuccess.bind(this)}
                    streamSuccessJoin={this.streamSuccessJoin.bind(this)}
                />
            }
            { // 会议录制
                this.state.switchMeetingRecording && <MeetingRecording
                    participants={this.state.videoList}
                    meetingData={this.state.meetData}
                    disableCloudRecording={this.state.disableCloudRecording}
                    onClose={() => this.setState({ switchMeetingRecording: false })}
                    sendMediaRecorderCommand={this.sendMediaRecorderCommand.bind(this)}
                    websocketCloudRecording={(recordingMethod) => {
                        this.recordingMethod = recordingMethod
                        summary.speechServerWebSocket(this.state.meetData.primaryKey, this.state.user.primaryKey, this.onMessageSpeechServerRecord.bind(this),
                            this.recordSocketAvailable.bind(this))
                    }} />
            }
        </div>
    }

    public setAudioDom(userId, bool, seconds?, track?) {
        const id = 'remoteAudio_' + userId
        const node = document.getElementById(id)
        if (!node) return
        track.detach(node)
        const parent = node?.parentElement
        if (seconds !== 0) {
            saveStreamFile(stream, "audio", seconds)
        }
        if (bool) {
            console.log("重置标签")
            node && node.parentNode && node.parentNode.removeChild(node);
            const audio = document.createElement("audio")
            audio.id = id;
            // audio.autoplay = true;
            // audio.style.display = 'none';
            // audio.srcObject = stream
            parent.insertBefore(audio, parent.children[0])
            track.attach(audio)
            videoLayout._play(audio)
        } else {
            console.log('重置流')
            track.attach(node)
            videoLayout._play(node)
        }
    }

    public componentWillUnmount() {
        this.meetingSocket && this.meetingSocket.removeEventListener('message', this.onMessageMeetWebsocketListener)
        this.unload()
        try { summary.close() } catch { }
        try { this.mqttClient && this.mqttClient.end() } catch { } finally { notification.close('meetingExpiringReminder') }
        this.refSummary && this.refSummary.closeWindow()
        notification.close("subtitle")
        notification.close("subtitle" + "11012")
        notification.close("subtitle" + "11014")
        notification.close('connectionQualityOperationDelay')
        notification.close('talkWhileMutedPopup')
        notification.close('recordTimeOverLimit')
        videoLayout.removeRenewButtonElement()
        this.subtitleModal && this.subtitleModal.destroy()
        localStorage.removeItem(JOINING_DEVICE_LIST)
        message.destroy('refuseToSpeakMessage')
        message.destroy('myPermissionsKey')
        message.destroy('AUTHORIZE_REMOTE_ASSISTANCE')
        message.destroy('YouHaveBeenMadeTheTost')
        message.destroy('youHaveBeenMuted')
        message.destroy('meetingLocked')
        message.destroy('HandsUp')
        message.destroy('PoorNetworkConditionsTips')
        this.whiteboardData = null
        videoLayout.mediaRecorderPromptUI({ status: false })
        this.logSocket && this.logSocket.close()
    }

    private connection;
    private connection2
    private room;
    private room2;
    private refFooter;
    private refSpeakerMode;
    private refChatMessage;
    private refSummary;
    private refSetCamera;
    private deviceChangeListener;
    private subtitleModal;
    private meetingSocket;
    private iframeWhiteboard;
    private initDataWhiteboard;
    private invitationToSpeakModal; // 邀请发言的modal
    private refLiveSetting;
    private assistance;
    private refJoinDevice;
    private videoVrPlayers = {}
    private mqttClient;
    private connectQualityDelayConfirm = false;
    private meetingSocketTimer;
    private noAudioSignalNotificationKey = "";
    private noAudioInputTimer; // 间隔时间查看本地audiolevel
    private whiteboardData;
    private allowAutoplayModal;// 部分浏览器需要允许自动播放
    private logSocket;
    private networkConditionsMessageTime = 0;
    private sendRemotePictureData;// 发送给远端ws的数据
    private pictureInPictureTimer;// 画中画隐藏定时器
    private recordingMethod; // 录制类型：视频/音频
}

export default connect(
    (state: any) => ({
        e2eeSupported: state.e2eeSupported,
        noticeSocket: state.noticeSocket,
        user: state.user,
        pictureInPictureData: state.pictureInPictureData,
        recordData: state.meetingRecordingData
    }),
    (dispatch) => {
        return {
            setE2eeSupported: (data: boolean) => dispatch(setE2eeSupported(data)),
            setMyInfo: (data: boolean) => dispatch(setMyInfo(data)),
            setPictureInPictureData: (data: any) => dispatch(setPictureInPictureData(data)),
            setMeetingRecordingData: (data: any) => dispatch(setMeetingRecordingData(data))
        }
    },
    null,
    { forwardRef: true }
)(Index);