
import React from "react";
import message from "antd/lib/message"
import Select from "antd/lib/select"
import i18n from "i18n";
import { copyText } from "utils/data";
import SrsSdk from "utils/srs"
import "./index.less";

interface IDataItem {
    deviceName: string,
    deviceType: string,
    stream: any
}
export default class extends React.Component<{
    meetingListId: string;
    preview: IDataItem;
    onClose: () => void;
    getPushStreamAddress: (data) => void
    pushStreamSuccess: (data) => void
    streamSuccessJoin: (data) => void
}, {
    type: string | undefined;
    deviceName: string; // 设备名称
    address: string; // 推流地址
    typeData: Array<{
        value: string;
        lable: string;
    }>;
    step: number;
    streamFailed: boolean;
    previewAddress: string;
}> {

    constructor(props) {
        super(props)
        this.state = {
            type: "0",
            deviceName: "",
            address: "",
            typeData: [
                {
                    lable: "Instan 360",
                    value: "0"
                },
                {
                    lable: "IP Camera",
                    value: "1"
                }
            ],
            step: 1,
            streamFailed: false,
            previewAddress: ""
        }
    }

    public componentDidMount() {
        if (this.props.preview) {
            this.setState({ step: 3, deviceName: this.props.preview.deviceName })
            const data = this.props.preview.stream;
            this.previewStreamAddress(data)
            this.setState({ address: `${data.streamProtocol === 0 ? "rtmp" : "webRtc"}://${data.domain}:${data.port}/${data.streamAddress}/${data.id}` })
        } else {
            this.getDeviceName()
            this.getPushStreamAddress()
        }
    }

    // 获取推流地址
    public getPushStreamAddress() {
        this.props.getPushStreamAddress({
            "streamType": 0,
            "deviceType": Number(this.state.type)
        })
    }
    // 获取设备名称
    public getDeviceName() {
        let { deviceName, typeData } = this.state;
        const target = typeData.filter(x => x.value === this.state.type)
        deviceName = target.length ? target[0].lable : '';
        this.setState({ deviceName })
    }

    public async request(data) {
        let { deviceName, type } = this.state;
        if (data === 2) { // 推流设置完成
            this.props.pushStreamSuccess(this.addressData)
            this.setState({ step: 3 })
        } else { // 加入会议
            this.props.streamSuccessJoin({
                deviceName: deviceName,
                deviceType: type,
                stream: this.addressData
            })
            this.props.onClose()
        }
    }

    public render() {
        const { typeData, type, step, deviceName, address, streamFailed } = this.state;
        return <div className="join_device_component" >
            <div className='main' style={{
                // width: this.props.preview ? '7.52rem' : '5.05rem'
            }}>
                <div className='container_head'>
                    <span>{i18n.t(this.props.preview ? 'videoPreview' : 'joinDevice')} <span className="testMark">内测</span></span>
                    <img src={require('static/images/meet/icon-guanbi.png')} style={{ cursor: "pointer" }} alt="" onClick={this.props.onClose} />
                </div>
                <div className='container_main'>
                    { // 设备类型
                        step === 1 && <div className="formItem">
                            <div className='label'>{i18n.t('DeviceType')}</div>
                            <div className='inputWrapper'>
                                <Select className="select" placeholder={i18n.t('message.PleaseSelectQuestionType')} value={type}
                                    onChange={(value) => {
                                        this.setState({ type: value }, () => {
                                            this.getPushStreamAddress()
                                            this.getDeviceName()
                                        })
                                    }}>
                                    {
                                        typeData.map(x => {
                                            return <Select.Option key={x.value} value={x.value}>{x.lable}</Select.Option>
                                        })
                                    }
                                </Select>
                            </div>
                        </div>
                    }
                    { // 设备名称
                        step !== 1 && <div className="formItem" >
                            <div className='label'>{i18n.t('deviceName')}</div>
                            <div className='inputWrapper' >
                                <input className="input" name="" id=""
                                    value={deviceName}
                                    maxLength={20}
                                    disabled={!!this.props.preview}
                                    onChange={(evt) => this.setState({ deviceName: evt.target.value.trim() })}
                                ></input>
                            </div>
                        </div>
                    }
                    { // 推流提示
                        step === 1 && <div className="hintBox">
                            <img src={require("static/images/meet/icon-hint.png")} alt="" />
                            <div className='text'>{i18n.t("pleaseStartStreamingSperationDevice")}</div>
                            <div className="formItem">
                                <div className='label'>{i18n.t('devicePushStreamAddress')}</div>
                                <div className='address' style={{ lineHeight: "none" }}>
                                    {address}  <span onClick={this.copy.bind(this)}>{i18n.t("clickCopy")}</span>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        step === 2 && <div className="formItem" >
                            <div className='label'>{i18n.t('devicePushStreamAddress')}</div>
                            <div className='inputWrapper address' >
                                {address}  <span onClick={this.copy.bind(this)}>{i18n.t("clickCopy")}</span>
                            </div>
                        </div>
                    }
                    <div className="contain">
                        { // 拉流成功/失败
                            step === 3 && <div className="videoBox">
                                <div className="formItem">
                                    <div className='label'>{i18n.t('devicePushStreamAddress')}</div>
                                    <div className='address' style={{ lineHeight: "none" }}>
                                        {address}  <span onClick={this.copy.bind(this)}>{i18n.t("clickCopy")}</span>
                                    </div>
                                </div>
                                <div className="stream">
                                    {
                                        streamFailed ? i18n.t("pullStreamSisplayFailed") :
                                            <video width="100%" height="100%" id="previewVideo" autoPlay ></video>
                                    }
                                </div>
                            </div>
                        }
                        {/* {
                            this.props.preview && <div className="ptz">
                                <div className="handle">
                                    <img src={require("static/images/meet/icon-arrow1.png")} alt="" />
                                    <img src={require("static/images/meet/icon-arrow2.png")} alt="" />
                                    <img src={require("static/images/meet/icon-arrow3.png")} alt="" />
                                    <img src={require("static/images/meet/icon-arrow4.png")} alt="" />
                                </div>
                            </div>
                        } */}
                    </div>

                    <div className="formItem" >
                        {
                            step === 2 && <div className='label'></div>
                        }
                        <div className='inputWrapper' style={{ justifyContent: step !== 1 ? "center" : "flex-end" }}>
                            {step === 1 && <div className="btnStep1">
                                <span>{i18n.t('streamingSetupCompleted')}</span>
                                <div className="btn" onClick={this.request.bind(this, 2)}>{i18n.t('nextStep')}</div>
                            </div>}
                            {step === 3 && !this.props.preview && <div className="btn" onClick={this.request.bind(this, 3)} >{i18n.t(streamFailed ? "refresh" : 'joinMeeting')}</div>}
                            {this.props.preview && <div className="btn shutDown" onClick={this.props.onClose} >{i18n.t('shutDown')}</div>}
                        </div>
                    </div>
                </div>
                <div id="NewsToolBoxAddress"></div>
            </div>
        </div>
    }
    // 返回的直播流
    public returnStreamAddress(data) {
        this.addressData = data
        this.setState({ address: `${data.streamProtocol === 0 ? "rtmp" : "webRtc"}://${data.domain}:${data.port}/${data.streamAddress}/${data.id}` })
    }

    // 预览直播
    public previewStreamAddress(data) {
        this.addressData = data
        this.setState({ previewAddress: `${data.streamProtocol === 0 ? "rtmp" : "webRtc"}://${data.domain}:${data.port}/${data.streamAddress}/${data.id}` }, () => {
            const video = document.getElementById('previewVideo') as any
            if (!video) return
            this.srs = new SrsSdk(`https://${data.domain}/rtc/v1/publish/`, `https://${data.domain}/rtc/v1/play/`);
            video.addEventListener('load', () => {
                video.play();
            });
            video.addEventListener("canplay", (e) => {
                const videoWidth = e.target.videoWidth
                const videoHeight = e.target.videoHeight
                console.log('流的分辨率', videoWidth, videoHeight)
            })
            try {
                this.srs.pull({
                    id: "/" + data.streamAddress + "/" + data.id,
                }, [], (evt) => {
                    console.info('拉流:', evt.streams);
                    video.srcObject = evt.streams[0];
                });
            } catch (err) {
                console.error('pull stream error', err);
            }
        })
    }

    public copy() {
        copyText(this.state.address, 'NewsToolBoxAddress')
        message.success(i18n.t('copyClipboard1'))
    }

    public componentWillUnmount() {
        this.srs && this.srs.stop()
    }

    private addressData = {}
    private srs;
}