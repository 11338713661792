
export interface IMeetItem {
    theme?: string;
    userIds?: string[];
    userNames?: string[];
    password: string;
    startTime?: string;
    endTime?: string;
    continueTime?: number[];
    meetId: string;
    status?: number;
    residueTime: number;
    primaryKey: string;
    calendar?: string;
    startTimeValue?: string;
    createUser?: string;
    companyId?: string;
    content?: string;
    meetToken?: string;
    [x: string]: any; //动态添加属性
}

/**
 * @interface 会议参与者数据定义
 */
export interface IParticipantItem {
    avatar: string;
    userId: string;
    _displayName: string;
    local: boolean;
    muted: boolean;
    participantId: string | null;
    speaker: boolean; // 主讲人
    moderator: boolean;
    voiceIncentive: boolean; // 语音激励
    list: Array<{
        id: string,
        jitsiTrack: any,
        local: boolean,
        mediaType: "video" | "audio",
        muted: boolean,
        videoType: string,
        participantId: string,
    }>,
    mediaVideo: {
        id: string,
        jitsiTrack: any,
        // local: boolean,
        // mediaType: "video" | "audio",
        muted: boolean,
        videoType: string,
        participantId: string,
    },
    mediaAudio: {
        // id: string,
        jitsiTrack: any,
        muted: boolean,
        participantId: string,
    }
    sharedUser: boolean; // 当前用户是共享用户
    realName: string;// 共享用户的名称
    stats: {
        bitrate: any; // 比特率
        bridgeCount: number; // 服务器数量
        bandwidth: string;// 估计宽带
        framerate: string; // 帧率
        packetLoss: object; //丢包
        resolution: any;
        maxEnabledResolution: any;
        transport: any;
        e2eRtt: any;
        serverRegion: string;
        connectionQuality: number;
        audioSsrc: string;
        videoSsrc: string
    },
    businessUserId: string;// 会议系统的用户ID
    translationLanguage: string;
    translationLanguageText: string;
    speeching: boolean; // 是否开了字幕 只有请客模式主持人可以看
    handRaiseState: boolean; // 举手状态
    shareScreenWatermark: boolean; // 共享屏幕水印打开权限
    isDevice: '360' | 'ipcamera';// 360设备
    deviceShareId: string // 设备ID
    switchedId: string;// 画中画和谁切换的对应ID
}

/**
 * @name 默认设备设置项
 */
export interface IDefaultDeviceSetItem {
    microphone?: boolean;
    camera?: boolean;
    audioInput?: {
        deviceId: string;
    },
    audioOutput?: {
        deviceId: string;
    },
    videoInput?: {
        deviceId: string;
    },
    backgroundModel?: "" | "img" | "blur",
    backgroundImg?: string;
    watermark?: boolean;
}

/**
 * @name  会议数据定义
 */
export interface IMeetingItem {
    actualParticipants?: IParticipant[];//实际参会人
    createUser?: string;//发起人
    createUserId?: string;// 发起人ID
    content?: string; // 会议内容
    startTime?: string;
    endTime?: string;
    duration?: number;//会议预计时长
    forecastStartTime?: string;//会议预计开始时间
    forecastEndTime?: string;//会议预计开始时间
    hostUser?: string;//主持人名
    hostUserId?: string; //主持人的id
    hostUserAvatar?: string;
    meetServiceId?: string;//对应的会议室服务id，null则代表使用免费的
    password?: string;
    primaryKey: string;
    theme?: string;
    username?: string;
    reserveParticipants?: IParticipant[];//预约参会人
    meetNumber?: string;
    remind?: number;// 到时提醒
    status?: 0 | 3 | 6 | 8;//会议状态0进行中 3未开始 6已结束 8已取消
    month?: number;//是否每月循环 0 or null 不循环，1 每月循环
    week?: number;//是否周循环，0 or null 不按照每周循环，1 每周循环，2 每2周循环
    whatNumber?: number;//month = 1需要填写本字段，1 每月第1个周几 周几写在whichDay上，2 每月最后一个周几 周几写在whichDay上，3 第一个工作日，4 最后一个工作日
    whichDay?: string;//周几预定会议 多个使用[,]分割,当(whatNumber = 1 or 2) or (week = 1 or 2 ) 需要传递此值
    secret?: number;//保密会议
    type?: 0 | 1;//0 会议 1 周期性会议
    residueTime?: number; // 剩余时间 前端定义
    cycle?: string; // 周期：每周/每两周/每月  前端定义
    transliteration?: boolean;
    live?: 0 | 1;// 0 直播 1不直播
    [key: string]: any;
}


/**
 * @name 参与人数据定义
 */
export interface IParticipant {
    userId: string;//参会人实际会议系统中的id
    type: 0 | 1 | 3;//参会人类型0：好友，1：企业 设备
    guestAddressBookId: string;//当type为1时需要传递企业通讯录的id，冗余一下
    name: string;
    dept: any; // 部门树
    guestUserId: string; // 企业ID
    attendDate?: string;
    primaryKey?: string;
    avatar?: string;
    [key: string]: any;
}

/**
 * @name 我的会议中权限
 */
export interface IMyPermission {
    handRaisedState: 0 | 1;
    speak: 0 | 1;
    screenSharing: 0 | 1;
    whiteboard: 0 | 1;
}


/**
 * @name 举手列表数据定义
 */
export interface IRaiseHandsListItem {
    participantId: string;
    userId: string;// 业务ID
    name: string;
}

/**
 * @name 画中画数据定义
 */
export interface IPictureInPictureItem {
    switch: boolean;
    template: 1 | 2 | 3 | 4;
    mainPicture: {
        value: string//选择的值
        type?: 0 | 1 | 2 | null//0：共享桌面(使用dataId指明共享桌面的id)；1：语音激励；2：具体用户，用户id使用userId标明
        dataId?: string;//jitsi的id
        userId?: string;
        defaultLabel?: string;// option里没有value
    },
    picture1: {
        value: string//选择的值
        type: 0 | 1 | 2 | null;
        dataId: string;
        userId: string;
        defaultLabel?: string;
    },
    picture2: {
        value: string//选择的值
        type: 0 | 1 | 2 | null;
        dataId: string;
        userId: string;
        defaultLabel?: string;
    }
}
/**
 * @name 画中画默认数据
 */
export const defaultPictureInPictureData: IPictureInPictureItem = {
    switch: false,
    template: 1,
    mainPicture: {
        value: "systemAutomatic",
        type: null, //0：共享桌面(使用dataId指明共享桌面的id)；1：语音激励；2：具体用户，用户id使用userId标明
        dataId: "",//jitsi的id
        userId: "",
    },
    picture1: {
        value: "",
        type: null,
        dataId: "",
        userId: "",
    },
    picture2: {
        value: "voiceStimulation",
        type: null,
        dataId: "",
        userId: "",
    }
}