

import React from "react";
import i18n from "i18n";
import { SETTING_PARAMS } from "config";
import { enumerateDevices } from "utils/common";
import { SetCamera } from "components"
import "./index.css";
import conference from "utils/lib-jitsi-meet/conference";

interface IState {
    setParams: {
        microphone?: boolean;
        camera?: boolean;
        audioInput: {
            deviceId: string;
        },
        audioOutput: {
            deviceId: string;
        },
        videoInput: {
            deviceId: string;
        },
    },
    devices: {
        audioInput: any[];
        audioOutput: any[];
        videoInput: any[];
    },
    showSetCamera: boolean;
    clickIcon: "bg" | "set" | "";
    isVideoBeginning: boolean;
}

class Index extends React.Component<{
    toggleAudio?: (stream) => void;
}, IState> {

    public state: IState = {
        setParams: {
            microphone: true,
            camera: false,
            audioInput: {
                deviceId: "default",
            },
            audioOutput: {
                deviceId: "default",
            },
            videoInput: {
                deviceId: "default",
            },
        },
        devices: {
            audioInput: [],
            audioOutput: [],
            videoInput: [],
        },
        showSetCamera: false,
        clickIcon: "",
        isVideoBeginning: false
    }

    public async componentDidMount() {
        // const setParams = window.localStorage.getItem(SETTING_PARAMS)
        // console.log(setParams)
        // if (setParams) {
        //     this.setState({ setParams: JSON.parse(setParams) })
        // }
        //@ts-ignore
        JitsiMeetJS.init();
        await this.enumerateDevices()
    }

    public enumerateDevices() {
        enumerateDevices((videoInput, audioInput, audioOutput) => {
            this.setState({
                devices: {
                    videoInput,
                    audioInput,
                    audioOutput,
                },
            })
            // const setting = window.localStorage.getItem(SETTING_PARAMS)
            // if (setting) {
            //     this.setState({ setParams: JSON.parse(setting) }, () => {
            //         this.toggleVideoDevice()
            //     })
            // } else {
            this.setState({
                setParams: {
                    ...this.state.setParams,
                    videoInput: {
                        deviceId: videoInput[0].deviceId,
                    },
                    audioInput: {
                        deviceId: audioInput[0].deviceId,
                    },
                    audioOutput: {
                        deviceId: audioOutput.length ? audioOutput[0].deviceId : "default",
                    }
                }
            }, () => {
                this.setStorage()
                if (this.state.setParams.camera) {
                    this.toggleVideoDevice()
                }
            })
            // }
        })
    }

    public render() {
        const { setParams } = this.state;
        return <div className="set-out-canmera-component">
            {
                setParams.camera ? <video className='joinVideoinputBg' id={"joinVideoinputBg"} autoPlay={true}></video> :
                    <div className='default-user'>
                        <img src={require('static/images/set/icon-default-user.png')} alt=""></img>
                        <div>{i18n.t('cameraIsClosed')}</div>
                    </div>
            }

            <div className='audio-video-box'>
                <div className='item' onClick={() => {
                    this.setState({
                        setParams: {
                            ...this.state.setParams,
                            microphone: !this.state.setParams.microphone
                        }
                    }, () => this.setStorage())
                    //@ts-ignore
                    _hmt.push(['_trackEvent', "icon", "click", 'mute_icon', !setParams.microphone]);
                }} >
                    {
                        !setParams.microphone ? <img src={require("static/images/meet/icon-mute.png")} alt="" /> :
                            <img src={require("static/images/meet/icon-muted.png")} alt="" />
                    }
                </div>
                <div className='item' onClick={() => {
                    if (this.state.isVideoBeginning) {
                        return
                    }
                    this.setState({
                        setParams: {
                            ...this.state.setParams,
                            camera: !this.state.setParams.camera,
                        },
                        isVideoBeginning: !this.state.isVideoBeginning,
                    }, async () => {
                        this.setStorage()
                        if (this.state.setParams.camera) {
                            this.createLocalTracks(this.state.setParams.videoInput.deviceId)
                        } else {
                            await this.clear()
                            this.setState({ isVideoBeginning: !this.state.isVideoBeginning })
                        }
                        //@ts-ignore
                        _hmt.push(['_trackEvent', "icon", "click", 'switch_camera_icon', this.state.setParams.camera]);
                    })
                }}>
                    {
                        setParams.camera ? <img
                            // style={{ cursor: isVideoBeginning ? "wait" : "pointer" }} 
                            src={require("static/images/meet/icon-open-video.png")} alt="" /> :
                            <img src={require("static/images/meet/icon-close-video.png")} alt="" />
                    }
                </div>
            </div>
            <div className='bg-set-box'>
                {/* <img src={require('static/images/set/icon-beijing.png')} alt="" onClick={() => this.setState({ clickIcon: "bg" }, () => {
                    //@ts-ignore
                    _hmt.push(['_trackEvent', "icon", "click", 'open_background_set_icon']);
                })} /> */}
                <img src={require("static/images/set/icon-shezhi.png")} onClick={() => this.setState({ clickIcon: "set" }, () => {
                    //@ts-ignore
                    _hmt.push(['_trackEvent', "icon", "click", 'open_set_icon']);
                })} alt="" />
            </div>
            {
                this.state.clickIcon === "bg" && <SetCamera
                    participants={[]}
                    menu={['bgSet']}
                    setBgImg={this.setBgImg.bind(this)}
                    onClose={() => this.setState({ clickIcon: "" })} />
            }
            {
                this.state.clickIcon === "set" && <SetCamera
                    participants={[]}
                    menu={['videoinput', 'audioinput', 'audiooutput']}
                    setVideoInputDevice={(deviceId) => this.setState({
                        setParams: {
                            ...this.state.setParams,
                            videoInput: {
                                deviceId: deviceId
                            }
                        }
                    }, () => this.toggleVideoDevice())}
                    onClose={() => this.setState({ clickIcon: "" })} />
            }
        </div>
    }


    public setBgImg(blurEffectInstance) {
        this.localMediaStream && this.localMediaStream.setEffect(blurEffectInstance)
        this.setVideo()
    }

    public async toggleVideoDevice() {
        const { devices, setParams } = this.state;
        const target = devices.videoInput.filter(x => x.deviceId === setParams.videoInput.deviceId)
        if (!target.length) {
            setParams.videoInput.deviceId = 'default';
            this.setState({ setParams }, () => this.setStorage())
        }
        await this.clear()
        setTimeout(() => {
            this.createLocalTracks(target.length ? setParams.videoInput.deviceId : "default")
        }, 100)
    }

    // 创建本地媒体
    public createLocalTracks(deviceId) {
        // @ts-ignore
        JitsiMeetJS.createLocalTracks({
            devices: ["video"],
            cameraDeviceId: deviceId,
        })
            .then((localTracks) => {
                this.localMediaStream = localTracks[0];
                this.setVideo()
                this.setState({ isVideoBeginning: false })
            })
            .catch(error => {
                console.error(error);
                this.setState({ isVideoBeginning: !this.state.isVideoBeginning })
                conference._handleAudioVideoError(error, 'video')
            });
    }

    public setVideo() {
        const node = document.getElementById("joinVideoinputBg")
        node && this.localMediaStream.attach(node);
    }

    public setStorage() {
        window.localStorage.setItem(SETTING_PARAMS, JSON.stringify(this.state.setParams))
    }

    public clear() {
        this.localMediaStream && this.localMediaStream.setEffect(undefined).then(() => {
            const timer = setTimeout(() => {
                this.localMediaStream && this.localMediaStream.stream.getTracks().map(x => x.stop())
                timer && clearTimeout(timer)
            }, 200)
        })
        const video: any = document.getElementById("joinVideoinputBg")
        if (video) {
            video.src = null
        }
    }

    public componentWillUnmount() {
        this.clear()
        // 清除video
        navigator.mediaDevices.ondevicechange = () => {

        }
    }

    private localMediaStream;

}

export default Index;
