
import React from "react";
import i18n from "i18n";
import api from "api";
import { withRouter } from 'react-router-dom';
import { message } from "antd";
import "./index.less"

interface IState {
    iotPrimaryKey: string;
    listData: Array<{
        primaryKey: string;
        name: string;
        servicePrimaryKey: string;
        authority: boolean;
        remainingTime: number;
        isEnterprise: boolean;
    }>
}

class Index extends React.Component<{
    onClose: (value: string, isEnterprise: boolean) => void;
    closeModal: () => void
}, IState> {

    public state: IState = {
        listData: [],
        iotPrimaryKey: ""
    }

    public async componentDidMount() {
        await this.getIotService("").then((res: any) => {
            this.setState({ iotPrimaryKey: res.primaryKey })
        })
        this.getClientList()
    }

    // 获取企业及企业服务
    public async getClientList() {
        const res = await api.addressList.getClientList()
        if (res.code === 200) {
            const asyncFun: any[] = [];
            if (!res.data.length) return this.props.onClose(this.state.iotPrimaryKey, false)
            res.data.forEach(x => {
                asyncFun.push(this.getIotService(x.primaryKey));
            })
            Promise.all(asyncFun).then(rs => {
                res.data.forEach((n, index) => {
                    n.servicePrimaryKey = rs[index].primaryKey;
                    n.authority = rs[index].authority;
                    n.remainingTime = rs[index].remainingTime;
                    n.isEnterprise = true
                })
                res.data.push({
                    servicePrimaryKey: this.state.iotPrimaryKey,
                    authority: true,
                    primaryKey: null,
                    name: i18n.t("personalAccount"),
                    isEnterprise: false
                })
                this.setState({ listData: res.data })
            });
        }
    }

    // 获取服务
    public getIotService(guestUserId) {
        return new Promise(async (resolve, reject) => {
            const res = await api.meeting.getIotService(guestUserId)
            if (res.code === 200) {
                resolve({
                    primaryKey: res.data.primaryKey,
                    authority: true,
                    remainingTime: res.data.remainingTime
                })
            } else {
                if (res.code === 11011) { //音转文服务无权限
                    resolve({
                        primaryKey: null,
                        authority: false
                    })
                } else {
                    message.error(res.msg)
                    reject()
                }
            }
        })
    }

    public render() {
        return <div className='set-account-component' style={{ display: this.state.listData.length ? "flex" : "none" }}>
            <div className={'main'}>
                <img src={require("static/images/home/icon-close-white.png")} alt="" className="closeImg" onClick={this.props.closeModal} />
                <div className="title">{i18n.t("PaidService")}</div>
                <div className="msg">{i18n.t('selectPaidAccount')}</div>
                <div className="list">
                    {
                        this.state.listData.map(node => {
                            if (node.authority) {
                                return <div className="item" key={node.primaryKey}
                                    style={{ opacity: node.remainingTime === 0 ? 0.5 : 1 }}
                                    onClick={() => {
                                        if (node.remainingTime !== 0) {
                                            this.props.onClose(node.servicePrimaryKey, node.isEnterprise)
                                        } else {
                                            message.info(i18n.t('dialog.noSubtitleBalance'))
                                        }
                                    }}>{node.name}</div>
                            }
                        })
                    }
                </div>
            </div>
        </div>
    }

}

export default withRouter(Index)