import React from "react";

class Page404 extends React.Component {
    public render() {
        return <div style={{
            fontFamily: 'PingFangSC-Regular, PingFang SC',
            fontWeight: 400,
            color: '#9d9d9d',
            lineHeight: '0.5rem',
            letterSpacing: 1,
            background: "#FBFBFB",
            height: "100vh",
            fontSize: '0.36rem'
        }} >
            <div style={{ paddingTop: '2.01rem', textAlign: "center" }}>哎呦！页面去环游宇宙了</div>
            <img style={{ position: "fixed", left: "6.76rem", top: '3.88rem', width: "2.22rem", height: '2.55rem' }} src={require("static/images/home/404-yhy.png")} alt="" />
            <img style={{ position: "fixed", left: "8.98rem", bottom: 0, width: "5.17rem", height: '3.46rem' }} src={require("static/images/home/404-xingqiu.png")} alt="" />
        </div>
    }
}

export default Page404;