import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import Message from "antd/lib/message";
import Radio from "antd/lib/radio";
import Modal from "antd/lib/modal";
import Button from "antd/lib/button";
import Select from "antd/lib/select";
import notification from "antd/lib/notification"
import styles from "./index.module.css";
import api from "api";
import { STORAGE_ACCESS_TOKEN, USER_DATA, CURRENT_MEETING_DATA, LogoutClearStorage, GUIDE_PAGE, vCode, faqUrl, getDomainUrl } from "config";
import { setNoticeSocketObj } from "reduxs/actions/message"
import { setUser } from "reduxs/actions/user"
import { ResetPassword, Introduction } from "components";
import i18n from "i18n";
import timezone from "moment-timezone";
import { debounce } from "utils/data";
import { getPlatformValue } from "utils/common";
import { Dropdown, Menu } from "antd";
interface IState {
    account: string;
    password: string;
    imgCode: string;
    showForgetPwdModal: boolean;
    loginMethod: 'phoneCode' | "phone" | 'email' | 'emailCode';
    captchaTime: string;
    privacyChecked: boolean;
    isSubmitting: boolean;
    svTextModal: boolean;
    registered: boolean,
    isSendLoading: boolean;
    loginType: "password" | "code";
    enterBeforeAccount: boolean;
    codeList: any[],
    phoneAreaCode: string;
    phoneAreaCodeValue: string;
    defaultPhoneAreaCode: string;
    defaultPhoneAreaCodeValue: string;
    verificationImg: { id: string; img: string },
    manualSwitchLoginType: boolean;
}

let number = 0;
class Index extends React.Component<RouteComponentProps & any, IState> {


    constructor(props) {
        super(props)
        this.isAccountExist = debounce(this.isAccountExist.bind(this), 500) as any;
    }

    public state: IState = {
        account: "",
        password: "",
        imgCode: "",
        showForgetPwdModal: false,
        loginMethod: "phone",
        captchaTime: "",
        privacyChecked: false,
        isSubmitting: false,
        svTextModal: false,
        registered: true,
        isSendLoading: false,
        loginType: "password",
        enterBeforeAccount: true,
        codeList: [],
        phoneAreaCode: "",
        phoneAreaCodeValue: "",
        defaultPhoneAreaCode: "",
        defaultPhoneAreaCodeValue: "",
        verificationImg: { id: "", img: "" },
        manualSwitchLoginType: false
    }

    public async componentDidMount() {
        LogoutClearStorage()
        if (this.props.noticeSocket && Object.keys(this.props.noticeSocket).length) {
            this.props.noticeSocket.close && this.props.noticeSocket.close()
        }
        const res = window.localStorage.getItem(USER_DATA) as any;
        if (res) {
            this.setState({ account: JSON.parse(res).phone || "" })
        }
        let firstCode = "";
        navigator.languages.forEach(lang => {
            if (firstCode === "") {
                if (lang.split('-').length === 2) {
                    firstCode = lang.split('-')[1]
                }
            }
        })
        this.getAreaCode(firstCode)
        // 图片验证码
        this.getVerificationImg()
    }
    // 获取地区代码
    public async getAreaCode(firstCode) {
        const res = await api.common.getAreaCode("", "")
        if (res.code === 200) {
            this.setState({ codeList: res.data }, async () => {
                const defaultCode = await api.common.getAreaCode("", firstCode)
                if (defaultCode.code === 200) {
                    if (defaultCode.data.length) {
                        this.setState({
                            phoneAreaCode: defaultCode.data[0].primaryKey,
                            phoneAreaCodeValue: defaultCode.data[0].phoneAreaCode,
                            defaultPhoneAreaCode: defaultCode.data[0].primaryKey,
                            defaultPhoneAreaCodeValue: defaultCode.data[0].phoneAreaCode
                        })
                    }
                }
            })
        }
    }

    public render() {
        const { password, account, captchaTime, loginType, enterBeforeAccount, imgCode, verificationImg, privacyChecked, loginMethod, showForgetPwdModal, phoneAreaCodeValue,
            codeList, phoneAreaCode, defaultPhoneAreaCode, defaultPhoneAreaCodeValue, isSendLoading, registered, isSubmitting } = this.state;
        return <div className={styles.loginPage}>
            <div className={styles.bgLeftBottom}></div>
            <div className={styles.bgRightTop}></div>
            <div className={styles.header}>
                <img src={require("static/images/logo2.png")} alt="" />
                {/* <span onClick={() => this.setState({ svTextModal: true })}>{i18n.t("PrivacyAgreement")}</span> */}
                <span><a target="_blank" style={{ color: '#171717' }}
                    href='https://www.swntech.com/#/introduction'>{i18n.t("ProductIntroduction")}</a></span>
                <Dropdown overlay={this.menuRender} placement="topCenter" arrow><span>{i18n.t("Instructions")}</span></Dropdown>
                <span ><a target="_blank" href={faqUrl} style={{ color: '#171717' }}>{i18n.t("helpCenter")}</a></span>
                {/* <span onClick={() => this.props.history.push("/problem")}>{i18n.t("problemFeedback")}</span> */}
                <span onClick={() => this.props.history.push("/about")}>{i18n.t("AboutUs")}</span>
                <span style={{ color: '#2F63F5' }} onClick={() => this.props.history.push("/download")}>{i18n.t('downloadCenter')}</span>
                <div className={styles.rightBox} onClick={() => {
                    const a = document.createElement("a")
                    a.target = "_black"
                    a.href = getDomainUrl() + '/customer'
                    a.click()
                }}>{i18n.t("enterpriseAdmin")}</div>
            </div>
            <div className={styles.layout}>
                <div className={styles.bgleft}></div>
                <div className={styles.main}>
                    <div className={styles.logoTitle}>{i18n.t("login.login")}</div>
                    <div className={styles.accountInput}>
                        <img src={require("static/images/home/icon-login-acc.png")} />
                        {
                            (loginMethod === "phone" || loginMethod === "phoneCode") &&
                            <Select showSearch
                                className="code-search"
                                style={{ width: '0.8rem', padding: '0 5px', fontSize: "0.2rem", color: "#868686" }}
                                suffixIcon={<span></span>}
                                placeholder=""
                                optionFilterProp="children"
                                dropdownStyle={{ width: 400 }}
                                onChange={(e) => {
                                    const target = codeList.filter(x => x.primaryKey === e)
                                    if (target.length) {
                                        this.setState({ phoneAreaCode: e, phoneAreaCodeValue: target[0].phoneAreaCode }, () => {
                                            this.isAccountExist()
                                        })
                                    }
                                }}
                                filterOption={true}
                                optionLabelProp="label"
                                value={phoneAreaCode}
                                dropdownMatchSelectWidth={200}
                                onSearch={(e) => {
                                    this.setState({ phoneAreaCode: e })
                                }}
                                onBlur={(e) => {
                                    if (!phoneAreaCode)
                                        this.setState({
                                            phoneAreaCode: defaultPhoneAreaCode,
                                            phoneAreaCodeValue: defaultPhoneAreaCodeValue
                                        })
                                }}
                            >
                                {
                                    codeList.map(node => {
                                        return <Select.Option key={node.primaryKey} label={node.phoneAreaCode} value={node.primaryKey}>
                                            {node.phoneAreaCode} {node.country}
                                        </Select.Option>
                                    })
                                }
                            </Select>
                        }
                        <input className={styles.input} type="text"
                            autoComplete={"off"}
                            placeholder={this.getAccount()}
                            value={account}
                            maxLength={(phoneAreaCodeValue === "+86" && (loginMethod === "phone" || loginMethod === "phoneCode")) ? 11 : 100}
                            onChange={evt => {
                                evt.persist();
                                const value = evt.target.value.trim()
                                const rp = (loginMethod === "phone" || loginMethod === "phoneCode") ? !/^[0-9]{0,11}$/.test(value) : /[\u4E00-\u9FA5]/g.test(value)
                                if (rp) {
                                    return
                                }
                                this.setState({ account: value }, () => this.isAccountExist())
                                number = 0
                            }}
                        />
                    </div>
                    {(loginMethod === "phone" || loginMethod === "phoneCode") && <div className={styles.loginType}>
                        <span onClick={async () => {
                            this.setState({ loginMethod: loginType === "code" ? "emailCode" : "email", account: "" })
                        }}>{i18n.t('login.switchEmailLogin')}</span></div>}
                    {(loginMethod === "email" || loginMethod === "emailCode") && <div className={styles.loginType}>
                        <span onClick={async () => {
                            this.setState({ loginMethod: loginType === "code" ? "phoneCode" : "phone", account: "" })
                        }}>{i18n.t('login.switchPhoneLogin')}</span></div>}
                    {
                        loginType === "code" && <div className={styles.accountInput}>
                            <img src={require("static/images/home/icon-login-imgcode.png")} style={{ width: "0.27rem", height: "0.21rem" }} />
                            <input className={styles.input} type="text"
                                autoComplete={"off"}
                                placeholder={i18n.t('login.pleaseEnterImgVerificationCode')}
                                value={imgCode}
                                maxLength={4}
                                onChange={evt => {
                                    const value = evt.target.value.trim()
                                    if (/^[a-zA-Z0-9]{0,4}$/.test(value)) {
                                        this.setState({ imgCode: value })
                                    }
                                }}
                            />
                            {verificationImg.img && <img src={'data:image/png;base64,' + verificationImg.img} alt="" className={styles.verificationImg} onClick={this.getVerificationImg.bind(this)} />}
                        </div>
                    }
                    {
                        loginType === "code" && <div className={styles.accountInput} style={{ visibility: enterBeforeAccount ? "initial" : "hidden" }}>
                            <img src={require("static/images/home/icon-login-pwd.png")} />
                            <input className={styles.input} type="text" id="codeInput"
                                autoComplete={"off"}
                                placeholder={this.getCode()}
                                value={password}
                                maxLength={6}
                                onChange={evt => {
                                    const value = evt.target.value.trim()
                                    if (/^[0-9]{0,6}$/.test(value)) {
                                        this.setState({ password: value })
                                    }
                                }}
                                onKeyDown={(e) => {
                                    const evt = window.event || e;
                                    if ((evt as any).keyCode == 13) {
                                        //回车事件
                                        this.login()
                                    }
                                }}
                            />
                            <Button
                                style={{ fontSize: "0.14rem" }}
                                className={captchaTime ? styles.captchaBtnActive : styles.captchaBtn}
                                disabled={!!captchaTime || isSendLoading}
                                onClick={() => this.sendCode(account.indexOf('@') > -1 ? 'email' : "phone")}>{captchaTime ? captchaTime : i18n.t('login.getVerificationCode')}</Button>
                        </div>
                    }

                    {
                        loginType === "password" && <div className={styles.accountInput} >
                            <img src={require("static/images/home/icon-login-pwd.png")} />
                            <input className={styles.input} type="password"
                                autoComplete={"off"}
                                placeholder={this.getCode()}
                                value={password}
                                onChange={evt => {
                                    const value = evt.target.value.trim()
                                    this.setState({ password: value })
                                }}
                                onKeyDown={(e) => {
                                    const evt = window.event || e;
                                    if ((evt as any).keyCode == 13) {
                                        //回车事件
                                        this.login()
                                    }
                                }}
                            />
                            <div className={styles.forgetPwd}
                                onClick={() => {
                                    if (account)
                                        this.setState({ showForgetPwdModal: true })
                                }}
                            >{i18n.t('login.forgotPassword')}</div>
                        </div>
                    }
                    {
                        registered && <div className={styles.loginType} style={{ visibility: enterBeforeAccount ? "initial" : "hidden" }}>
                            <span onClick={this.toggleMode.bind(this)}>{loginType === "code" ? i18n.t('login.loginWithPassword') : i18n.t('login.loginWithCode')}</span>
                        </div>
                    }

                    {
                        !registered && <div style={{ marginTop: 20 }}>
                            <Radio
                                className={styles.pricacyText}
                                checked={privacyChecked}
                                onClick={(e) => {
                                    this.setState({ privacyChecked: !privacyChecked })
                                }}
                            >{i18n.t('login.readAndAgree')}</Radio>
                            <span className={styles.pricacyText} style={{ textDecoration: 'underline', marginLeft: -7 }} onClick={() => this.setState({ svTextModal: true })}>{i18n.t('login.privacyStatement')}</span>
                        </div>
                    }

                    <Button type="primary"
                        loading={isSubmitting}
                        className={(privacyChecked && this.checkAccount() && password) ? styles.loginBtn : styles.loginDisableBtn}
                        disabled={!privacyChecked || !this.checkAccount() || !password}
                        onClick={this.login.bind(this)}>
                        {i18n.t('login.login')}</Button>
                </div>
            </div>
            <Modal visible={this.state.svTextModal} width={'8.0rem'} style={{ padding: "0.2rem" }} footer={null}
                onCancel={() => this.setState({ svTextModal: false })}>
                <iframe style={{ width: "100%", height: '66vh', paddingTop: '20px' }} frameBorder={0} src={`https://www.svmeet.com/suiwen/${i18n.t('privacyUrl')}.html`} ></iframe>
            </Modal>
            {
                showForgetPwdModal && account &&
                <ResetPassword type="forget" account={((loginMethod === "phone" || loginMethod === "phoneCode") ? phoneAreaCodeValue : "") + account} title={i18n.t('login.forgotPassword')} onCancel={() => this.setState({ showForgetPwdModal: false })} />
            }
            <Introduction type="new" />
        </div>
    }

    public menuRender() {
        return (<Menu>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href="https://www.svmeet.com:19000/public/resources/WebUserManual.pdf">
                    {i18n.t('WebVersionManual')}
                </a>
            </Menu.Item>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href="https://www.svmeet.com:19000/public/resources/PCUserManual.pdf">
                    {i18n.t('PcVersionManual')}
                </a>
            </Menu.Item>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href="https://www.svmeet.com:19000/public/resources/MobileUserManual.pdf">
                    {i18n.t('MobileVersionManual')}
                </a>
            </Menu.Item>
        </Menu>)
    }

    public async isAccountExist() {
        if (!this.state.account || !this.checkAccount()) return
        // if (/^[0-9]{0,11}$/.test(this.state.account)) {
        //     if (this.state.account.length !== 11) {
        //         return
        //     }
        // } else {
        //     const rePass = new RegExp('^([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+\\.[a-zA-Z]{2,3}$', '');
        //     if (!rePass.test(this.state.account)) {
        //         return
        //     }
        // }
        if (number === 0) {
            const res = await api.user.isAccountExist((this.state.loginMethod === "emailCode" || this.state.loginMethod === "email") ? 'email' : "phone",
                ((this.state.loginMethod === "phone" || this.state.loginMethod === "phoneCode") ? this.state.phoneAreaCodeValue : "") + this.state.account)
            if (res.code === 200) {
                if (!this.state.manualSwitchLoginType) {
                    this.setState({
                        loginType: res.data ? "password" : "code",
                        loginMethod: res.data ?
                        ((this.state.loginMethod === "phoneCode" && "phone")
                            || (this.state.loginMethod === "emailCode" && "email") || this.state.loginMethod) : ((this.state.loginMethod === "phone" && "phoneCode") || (this.state.loginMethod === "email" && "emailCode") || this.state.loginMethod),
                    })
                }
                this.setState({
                    password: "",
                    registered: res.data,
                    privacyChecked: res.data ? true : false,
                    enterBeforeAccount: true
                })
                if (!res.data) {
                    localStorage.setItem(GUIDE_PAGE, JSON.stringify(["home", "create-meet", "meet"]))
                } else {
                    localStorage.removeItem(GUIDE_PAGE)
                }
            }
        }
    }

    public async sendCode(status?: string): Promise<any> {
        //@ts-ignore
        // if (window.$svdebug === true) {
        //     Message.warn('debug模式请输入默认密码')
        //     return
        // }
        let { phoneAreaCodeValue, imgCode, verificationImg, account, loginMethod } = this.state;
        if (!imgCode) {
            Message.warn(i18n.t('login.pleaseEnterImgVerificationCode'))
            return
        }
        this.setState({ isSendLoading: true })
        let res: any = null
        if (status === 'phone') {
            if (!account) {
                Message.warn(i18n.t('message.pleaseEnterValidPhoneNum'))
                this.setState({ isSendLoading: false })
                return
            }
            res = await api.user.sendCode('login', (phoneAreaCodeValue + account) || "", verificationImg.id, imgCode)
            loginMethod = 'phoneCode'
        } else if (status === 'email') {
            const value = account;
            if (!value || !/^[a-zA-Z0-9_\\.-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)) {
                Message.error(i18n.t('message.pleaseEnterYourVaildEmail'))
                this.setState({ isSendLoading: false })
                return
            }
            res = await api.user.emailSendCode('login', account || "", verificationImg.id, imgCode)
            loginMethod = 'emailCode'
        }
        if (res.code === 200) {
            let num = 60;
            const timer = setInterval(() => {
                if (num === 1) {
                    clearInterval(timer)
                    this.setState({ captchaTime: "" })
                    return
                }
                this.setState({
                    captchaTime: i18n.t('login.reissueAfterNSeconds', {
                        num: --num
                    })
                })
            }, 1000)
            this.getVerificationImg()
        }
        this.setState({ isSendLoading: false })
    }

    public getAccount = (): string => {
        let str: string = ''
        const { loginMethod } = this.state;
        if (loginMethod === 'phone' || loginMethod === 'phoneCode') {
            str = i18n.t('message.pleaseEnterPhoneNumber')
        } else if (loginMethod === 'email' || loginMethod === 'emailCode') {
            str = i18n.t('message.pleaseInputYourEmail')
        }
        return str
    }

    public getCode = (): string => {
        let str: string = ''
        const { loginMethod } = this.state;
        if (loginMethod === 'phone' || loginMethod === 'email') {
            str = i18n.t('login.pleaseEnterYourPassword')
        } else if (loginMethod === 'phoneCode' || loginMethod === 'emailCode') {
            str = i18n.t('login.pleaseEnterVerificationCode')
        }
        return str
    }

    public toggleMode = (): void => {
        number = 0
        this.setState({ loginType: this.state.loginType === "code" ? "password" : "code" }, () => {
            this.setState({
                loginMethod: this.state.loginType === "password" ? (this.state.account.indexOf('@') > -1 ? 'email' : "phone") : (this.state.account.indexOf('@') > -1 ? "emailCode" : "phoneCode"),
                password: "",
                manualSwitchLoginType: this.state.loginType === "code" ? true : false
            })
            if (this.state.loginType === "code") {
                this.getVerificationImg()
            }
        })
    }

    public async login() {
        const { password, account, loginMethod, imgCode, privacyChecked, loginType } = this.state;
        if (this.state.isSubmitting) {
            return
        }
        if (loginMethod === 'emailCode') {
            if (!account) {
                Message.warn(i18n.t('message.pleaseInputYourEmail'))
                return
            }
            if (!password) {
                Message.warn(i18n.t('login.pleaseEnterVerificationCode'))
                return
            }
        } else if (loginMethod === 'phoneCode') {
            if (account) {
                // if (account.indexOf('@') === -1) {
                //     // if (account.length !== 11) {
                //         Message.warn(i18n.t('message.pleaseEnterValidPhoneNum'))
                //         return
                //     // }
                // }
            } else {
                Message.warn(i18n.t('message.pleaseEnterCorrectAccount'))
                return
            }
            if (!password) {
                Message.warn(i18n.t('login.pleaseEnterVerificationCode'))
                return
            }
        } else if (loginMethod === 'phone') {
            if (!account) {
                Message.warn(i18n.t('message.pleaseEnterPhoneNumber'))
                return
            }
            if (!password) {
                Message.warn(i18n.t('login.pleaseEnterYourPassword'))
                return
            }
        } else if (loginMethod === 'email') {
            if (!account) {
                Message.warn(i18n.t('login.pleaseInputYourEmail'))
                return
            }
            if (!password) {
                Message.warn(i18n.t('login.pleaseEnterYourPassword'))
                return
            }
        }
        if (loginType === "code" && !imgCode) {
            Message.warn(i18n.t('login.pleaseEnterImgVerificationCode'))
            return
        }
        if (!privacyChecked) {
            Message.warn(i18n.t('message.pleaseReadPrivacyStatement'))
            return
        }
        this.setState({ isSubmitting: true }, async () => {
            const acc = (loginMethod === "phone" || loginMethod === "phoneCode") ? this.state.phoneAreaCodeValue + account : account
            const res = await api.user.login({ account: acc, code: password, areaCode: (loginMethod === "phone" || loginMethod === "phoneCode") ? this.state.phoneAreaCodeValue : "" }, loginMethod)
            if (res.code === 200) {
                await window.localStorage.setItem(STORAGE_ACCESS_TOKEN, res.data)
                setTimeout(() => {
                    this.getUserInfo()
                }, 200);
                if (this.props.noticeSocket && Object.keys(this.props.noticeSocket).length) {
                    this.props.noticeSocket.url = `${window.location.protocol === "http:" ? "ws" : "wss"}://${window.location.host}/api/ws/notice?token=${window.localStorage.getItem(STORAGE_ACCESS_TOKEN)}&timezone=${timezone.tz.guess()}`
                    await this.props.noticeSocket.open()
                } else {
                    this.websocketNotice()
                }
            } else {
                Message.error(res.msg)
            }
            this.setState({ isSubmitting: false })
        })

    }

    public async getUserInfo() {
        const res = await api.user.getUserInfo();
        if (res.code === 200) {
            this.props.setUser(res.data)
            if (this.props.match.params.meetId) {
                window.location.href = window.location.origin + window.location.pathname + "#/join-meet/" + this.props.match.params.meetId + this.props.location.search
            } else {
                this.props.history.push("/home");
            }
        }

    }

    public websocketNotice() {
        const svDeviceInfo = `${getPlatformValue()} web ${vCode}`
        const url = `${window.location.protocol === "http:" ? "ws" : "wss"}://${window.location.host}/api/ws/notice?token=${window.localStorage.getItem(STORAGE_ACCESS_TOKEN)}&timezone=${timezone.tz.guess()}&svDeviceInfo=${svDeviceInfo}`
        //@ts-ignore
        this.noticeSocket = new ReconnectingWebSocket(url, null, { debug: false, reconnectInterval: 3000 });
        this.props.setNoticeSocketObj(this.noticeSocket)
        this.noticeSocket.onopen = () => {
            this.pingTimer = setInterval(() => {
                const token = localStorage.getItem(STORAGE_ACCESS_TOKEN) as any
                if (this.props.noticeSocket && token && token.length) {
                    this.noticeSocket.send(JSON.stringify({
                        code: 10,
                        type: "ping"
                    }))
                }
                if (!token) {
                    this.pingTimer && clearInterval(this.pingTimer)
                    this.noticeSocket.close()
                }
            }, 5000)
        }
        this.noticeSocket.addEventListener('message', (receive) => {
            let msg = JSON.parse(receive.data)
            if (msg.type === 'ping') return
            if (msg.type === "meeting") {
                if (msg.code === 0) {
                    notification.open({
                        message: msg.title,
                        description: msg.msg,
                        duration: null
                    })
                } else if (msg.code === 1) {
                    notification.open({
                        message: msg.title,
                        description: msg.msg,
                        duration: null,
                        placement: "bottomRight",
                        btn: <Button type="primary" size="small" onClick={() => {
                            notification.close('notification-key')
                            this.joinMeeting(msg.parameter)
                        }}>
                            {i18n.t('join')}
                        </Button>,
                        key: "notification-key"
                    })
                }
            }
        })
        this.noticeSocket.onerror = () => {
            console.log(this.noticeSocket + '连接建立失败');
            // this.noticeSocket.url = url;
            this.pingTimer && clearInterval(this.pingTimer)
        }
    }

    public async joinMeeting(data: any) {
        const res = await api.meeting.joinMeet(data.meetId, data.password)
        if (res.code === 200) {
            window.localStorage.setItem(CURRENT_MEETING_DATA, JSON.stringify(res.data))
            if (res.data.waitingRoom) {
                window.history.pushState({}, '', window.location.origin + window.location.pathname + `#/waiting-room/${data.meetId}`)
                window.location.reload()
            } else {
                // 主会议
                window.history.pushState({}, '', window.location.origin + window.location.pathname + `#/meet/${data.meetId}`)
                window.location.reload()
            }
        }
    }

    // 获取图片验证码
    public async getVerificationImg() {
        const res = await api.common.getVerificationImg()
        if (res.code === 200) {
            this.setState({ verificationImg: res.data })
        }
    }

    // 检查账号是否合规
    public checkAccount() {
        const { loginMethod, account, phoneAreaCodeValue } = this.state;
        if (loginMethod === "phone" || loginMethod === "phoneCode") { // 手机号检测
            if (/^[0-9]{0,11}$/.test(account)) {
                if (phoneAreaCodeValue === "+86" && account.length !== 11) {
                    return false
                }
            }
        } else {// 邮箱账号检测
            const rePass = new RegExp('^[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$', '');
            if (!rePass.test(account)) {
                return false
            }
        }
        return true
    }

    public componentWillUnmount() {
        number = 0
    }

    private noticeSocket;
    private pingTimer;
}

export default connect(
    (state: any) => ({
        noticeSocket: state.noticeSocket
    }),
    (dispatch) => {
        return {
            setNoticeSocketObj: (data: any) => dispatch(setNoticeSocketObj(data)),
            setUser: (data: any) => dispatch(setUser(data)),
        }
    }
)(Index);
