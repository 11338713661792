
import React from "react";
import api from "api";
import i18n from "i18n";
import moment from "moment";
import timezone from "moment-timezone";
import { connect } from "react-redux";
import { routes } from "../config/route";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import zh_CN from 'antd/lib/locale/zh_CN';
import en_GB from 'antd/lib/locale/en_GB';
import ConfigProvider from "antd/lib/config-provider";
import { JVB_SERVER_REGION, version } from "config";
import { setNoticeSocketObj } from "reduxs/actions/message"
import { setUser } from "reduxs/actions/user";
import { STORAGE_ACCESS_TOKEN, CURRENT_MEETING_DATA, vCode } from "config";
import notification from "antd/lib/notification";
import Button from 'antd/lib/button';
import Page404 from "pages/404";
import '@tensorflow/tfjs-backend-webgl';
import '@tensorflow/tfjs-backend-cpu';
import { BrowserGuide, MobileGuide, DownloadAppGuide } from "components";
import { logger } from "utils/log";
import { getPlatformValue } from "utils/common";
import { readRecordIndexedDB } from 'utils/lib-jitsi-meet/devices';

// let isMobilePage = false;
let timeoutInterval = 10000;
class Pages extends React.Component<any> {

    constructor(props) {
        super(props)
        this.isPC = this.IsPC()
        this.isWeixin = this.is_weixin()
        // if (!this.isPC) {
        //     // 是手机端
        //     if (!this.isWeixin) {//是微信浏览器显示引导页,不是微信浏览器时显示下载页面
        //         isMobilePage = true;
        //     }
        // } else {
        //     // 浏览器端
        // }
    }
    public state = {
        language: zh_CN,
        showDownloadAppGuide: false
    }

    public componentWillMount() {
        if (this.IsPC() && !this.is_weixin()) {
            if (window.location.pathname.indexOf('/sv/') > -1) {
                window.history.replaceState({}, '', window.location.origin + `#/join-meet/` + window.location.pathname.replace("/sv/", "") + window.location.search)
            }
        }
    }

    public async componentDidMount() {
        console.log("版本号：" + version)
        const index1 = localStorage.getItem('LogEndTs')
        if (index1) {
            this.logEndTs = Number(index1)
        }

        if (this.IsPC()) {
            window.addEventListener('beforeunload', () => {
                this.props.setNoticeSocketObj(null)
            })
            window.addEventListener('load', () => {
                this.websocketNotice()
                if ((window as any).$environment !== 'ml') {
                    const timer = setTimeout(() => {
                        clearTimeout(timer)
                        this.uploadLogs()
                    }, timeoutInterval)
                }
            })
            if (!this.isWeixin && window.location.hash.indexOf('/join-meet/') > -1) { // 是PC端并且不是微信浏览器提示下载APP
                this.setState({ showDownloadAppGuide: true })
            }
        }
        // 读取数据库是否有录音录像未保存
        readRecordIndexedDB()

        // antd语言
        let num = 0;
        navigator.languages.forEach((node: string) => {
            if (num === 0) {
                if (node.split('-')[0] === 'zh') {
                    num += 1
                    this.setState({ language: zh_CN })
                    // 设置日期组件
                    moment.locale('zh-cn');
                } else if (node.split('-')[0] === 'en') {
                    num += 1
                    this.setState({ language: en_GB })
                }
            }
        })
        // title修改
        const svmeetTitle = document.getElementById('svmeet-title');
        if (svmeetTitle) svmeetTitle.innerText = i18n.t('svmeet')

        try {
            const res = await api.user.checkToken()
            if (res.code === 200 && res.data === true) {
                if (window.location.hash.indexOf("/meet") === -1) {
                    try {
                        const list = await api.common.getRegion()
                        let data = ""
                        try {
                            const region = await api.common.getServerRegion()
                            list.data.forEach(element => {
                                if (region && (element.regionKey === region)) {
                                    data = element
                                }
                            });
                        } catch (e) { } finally {
                            localStorage.setItem(JVB_SERVER_REGION, JSON.stringify(data || list.data[0]))
                        }
                    } catch (e) { console.log(e) }
                }
            }
        } catch { }

        document.addEventListener("visibilitychange", () => {
            if (window.location.hash.indexOf("/login") === -1 && (this.props.user.token !== localStorage.getItem(STORAGE_ACCESS_TOKEN))) {
                this.getUserInfo()
            }
        })

        this.getUserInfo()
        this.websocketNotice()
    }


    public is_weixin() {
        const ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i)) {
            return true;
        }
    }

    public IsPC() {
        const userAgentInfo = navigator.userAgent;
        const Agents = ['Mobile'
            // "Android", "iPhone",
            // "SymbianOS", "Windows Phone",
        ];//"iPad","iPod"
        let flag = true;
        for (var v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) {
                flag = false;
                break;
            }
        }
        return flag;
    }

    //上传日志
    public async uploadLogs() {
        try {
            let LogJS = localStorage.getItem('LogJS') as any
            if (!LogJS) return
            LogJS = JSON.parse(LogJS)
            let list: any[] = []
            if (this.logEndTs === 0) {
                const res = await api.common.uploadLogsServer(this.props.user.primaryKey, LogJS)
                if (res) {
                    this.logEndTs = LogJS[LogJS.length - 1].ts
                }
            } else if (LogJS[LogJS.length - 1].ts !== this.logEndTs) {// 有新日志
                try {
                    LogJS.reverse().map((log) => {
                        list.push(log) // 最新几条没上传的数据
                        // console.log(list)
                        if (log.ts === this.logEndTs) {
                            throw Error();
                        }
                    });
                } catch (e) {
                    // console.log(e)
                } finally {
                    const res = await api.common.uploadLogsServer(this.props.user.primaryKey, list)
                    if (res) {
                        this.logEndTs = list[0].ts
                    }
                }
            } else { // 没有新日志
                return
            }
            localStorage.setItem('LogEndTs', String(this.logEndTs))
        } catch (e) {
            logger.error("上传日志接口报错", e)
        } finally {
            const timer = setTimeout(() => {
                clearTimeout(timer)
                this.uploadLogs()
            }, timeoutInterval)
        }
    }

    public render() {
        return (
            <ConfigProvider locale={this.state.language}>
                { // 微信浏览器：手机端和PC的引导页不同
                    (this.isWeixin && this.isPC) ? <BrowserGuide /> : !this.isPC ? <MobileGuide /> : <Router basename={"/"}>
                        <Switch>
                            {routes.map((route) => (
                                <Route exact={true} path={route.path} component={route.component} key={route.path} />
                            ))}
                            <Route path="*" component={Page404} />
                        </Switch>
                    </Router>
                }
                {this.state.showDownloadAppGuide && <DownloadAppGuide close={() => this.setState({ showDownloadAppGuide: false })} />}
            </ConfigProvider>
        );
    }

    public websocketNotice() {
        if (this.noticeSocket || !window.localStorage.getItem(STORAGE_ACCESS_TOKEN)) return
        const svDeviceInfo = `${getPlatformValue()} web ${vCode}`
        const url = `${window.location.protocol === "http:" ? "ws" : "wss"}://${window.location.host}/api/ws/notice?token=${window.localStorage.getItem(STORAGE_ACCESS_TOKEN)}&timezone=${timezone.tz.guess()}&svDeviceInfo=${svDeviceInfo}`
        //@ts-ignore
        this.noticeSocket = new ReconnectingWebSocket(url, null, { debug: false, reconnectInterval: 3000 });
        this.props.setNoticeSocketObj(this.noticeSocket)
        this.noticeSocket.onopen = () => {
            this.pingTimer = setInterval(() => {
                const token = localStorage.getItem(STORAGE_ACCESS_TOKEN) as any
                if (this.props.noticeSocket && token && token.length) {
                    this.noticeSocket.send(JSON.stringify({
                        code: 10,
                        type: "ping"
                    }))
                }
                if (!token) {
                    this.pingTimer && clearInterval(this.pingTimer)
                    this.noticeSocket.close()
                }
            }, 5000)
        }
        this.noticeSocket.addEventListener('message', (receive) => {
            let msg = JSON.parse(receive.data)
            if (msg.type === 'ping') return
            if (msg.type === "meeting") {
                if (msg.code === 0) {
                    notification.open({
                        message: msg.title,
                        description: msg.msg,
                        duration: null
                    })
                } else if (msg.code === 1) {
                    notification.open({
                        message: msg.title,
                        description: msg.msg,
                        duration: null,
                        placement: "bottomRight",
                        btn: <Button type="primary" size="small" onClick={() => {
                            notification.close('notification-key')
                            this.joinMeeting(msg.parameter)
                        }}>
                            {i18n.t('join')}
                        </Button>,
                        key: "notification-key"
                    })
                }
            } else if (msg.type === "userInfo") {
                const token = localStorage.getItem(STORAGE_ACCESS_TOKEN) as any
                if (token && token.length > 10) {
                    this.getUserInfo()
                }
            }
        })
        this.noticeSocket.onerror = () => {
            console.log(this.noticeSocket + '连接建立失败');
            // this.noticeSocket.url = url;
            this.pingTimer && clearInterval(this.pingTimer)
        }
    }

    public async joinMeeting(data: any) {
        const res = await api.meeting.joinMeet(data.meetId, data.password)
        if (res.code === 200) {
            window.localStorage.setItem(CURRENT_MEETING_DATA, JSON.stringify(res.data))
            if (res.data.waitingRoom) {
                window.history.pushState({}, '', window.location.origin + window.location.pathname + `#/waiting-room/${data.meetId}`)
                window.location.reload()
            } else {
                // 主会议
                window.history.pushState({}, '', window.location.origin + window.location.pathname + `#/meet/${data.meetId}`)
                window.location.reload()
            }
        }
    }

    public async getUserInfo(): Promise<any> {
        const res = await api.user.getUserInfo();
        if (res.code === 200) {
            this.props.setUser(res.data)
        }
    }

    public noticeSocket: any
    public pingTimer;
    private logEndTs = 0;
    private isPC; // 是浏览器
    private isWeixin; // 是微信浏览器
}

export default connect(
    (state: any) => ({
        noticeSocket: state.noticeSocket,
        user: state.user
    }),
    (dispatch) => {
        return {
            setNoticeSocketObj: (data: any) => dispatch(setNoticeSocketObj(data)),
            setUser: (data: any) => dispatch(setUser(data))
        }
    }
)(Pages);