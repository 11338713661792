
import React from "react";
import Modal from "antd/lib/modal";
import Progress from "antd/lib/progress";
import Button from "antd/lib/button";
import "./index.css"
import i18n from "i18n";
import { message } from "antd";

export default class extends React.Component<{
    waitingJoin: boolean;
    customExceptionConnection: boolean; // 连接中断、websocket403失败等问题进行重连
    isWhiteboardCreator: boolean;
    close: () => void
}, {
    seconds: number;
}> {

    constructor(props) {
        super(props)
        this.state = {
            seconds: this.props.customExceptionConnection ? 10 : 20,
        }
    }
    public componentDidMount() {
        if (this.props.isWhiteboardCreator) {// 我是白板开启人的话，需要提示
            message.success({
                content: i18n.t('message.whiteboardNetworkConnectionException'),
                icon: <span></span>,
                style: {
                    marginTop: '40vh', color: "rgba(61, 230, 47, 1)"
                },
                duration: 8,
                className: "meet-custom-message",
                key: "xxxOpensTheWhiteboard",
            })
        }
        this.timer = setInterval(() => {
            if (this.state.seconds === 1) {
                window.location.reload()
                clearInterval(this.timer)
                return
            }
            const seconds = this.state.seconds - 1;
            this.setState({ seconds })
        }, 1000)
    }

    public render() {
        return (
            <div className="disconnect_page">
                {this.props.waitingJoin ?
                    <Modal className="disconnect_page_modal" visible={true} title={i18n.t('dialog.youHaveDisconnected')}
                        onCancel={this.props.close}
                        footer={<div>
                            <Button type="primary" onClick={() => window.location.reload()}>{i18n.t('join')}</Button>
                        </div>}>
                        <div>{this.props.customExceptionConnection ? `连接不稳定，将会在${this.state.seconds}秒后重新连接…` : i18n.t('dialog.reconnectText', { num: this.state.seconds })}</div>
                        <Progress className="disconnect_progress" percent={4 * (25 - this.state.seconds)} format={() => ""} />
                    </Modal>
                    : <div className="disconnect_text">
                        <span className="title">{i18n.t('dialog.networkAnomaly')}</span>
                    </div>}
            </div>
        );
    }

    public componentWillUnmount() {
        this.timer && clearInterval(this.timer)
    }

    private timer;
}