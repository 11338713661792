
import React from "react";
import i18n from "i18n";
import $ from "jquery"
import { IMeetingItem, IParticipantItem } from "models/meet";
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Popover, message, Spin } from "antd"
import { LIVE_URL_PUSH, LIVE_URL_PULL, LIVE_CODEC } from "config";
import SrsSdk from "utils/srs"
import { getBase64Image } from "utils/common";
import { logger } from "utils/log";
import { LoadingOutlined } from '@ant-design/icons';
import "./index.less"

const galleryModeType = [2, 4, 7, 9, 16]

interface IDataItem {
    activity: boolean; // 是否为语音激励
    id: string;// 当activity为false时需要填写jitsiId
    name: string;
    track: any;
    avatar: string;
    image?: any;
}
interface IState {
    galleryLayoutType: number;
    mode: "speaker" | "sidebar" | "gallery";
    liveStreaming: boolean;
    isEdit: boolean;
    currentSelectIndex: number;
    speakerData: {// 主讲模式
        main: IDataItem, // 主讲画面
        speakerPosition: number; // 提问人位置，0：左上，1：右上,2：左下，3：右下
        speaker: IDataItem; // 讲话人
        questioner: IDataItem;// 提问人 不传或者null则不展示演讲人
    }
    sidebarData: {// 边栏模式
        main: IDataItem,
        sidebar: IDataItem[]
    },
    galleryData: {// 画廊模式
        quantity: number,//0(代表自动处理，数量最大16),2,4,7,9,16  
        participant: IDataItem[]
    },
    voiceStimulation: boolean; // 任何模式下语音激励是互斥的 只能存在一个语音激励
    currentSelectSmallIndex: number | undefined;
    liveing: boolean;
    loading: boolean;
}

export default class extends React.Component<{
    participants: IParticipantItem[];
    meetData: IMeetingItem;
    userId: string;
    liveStatus: number;
    updateLiveInfo: (data) => void;
    closeLive: () => void;
    close: () => void;
}, IState> {
    constructor(props) {
        super(props)
        this.state = {
            galleryLayoutType: 2,
            mode: "speaker",
            liveStreaming: false,
            isEdit: false,
            currentSelectIndex: 0,
            speakerData: {
                main: {
                    activity: false,
                    id: '',
                    track: "",
                    name: "",
                    avatar: "",
                    image: ""
                },
                speakerPosition: 1,
                speaker: {
                    activity: false,
                    id: '',
                    track: "",
                    name: "",
                    avatar: "",
                },
                questioner: {
                    activity: false,
                    id: '',
                    track: "",
                    name: "",
                    avatar: "",
                },
            },
            sidebarData: {
                main: {
                    activity: false,
                    id: '',
                    track: "",
                    name: "",
                    avatar: "",
                    image: ""
                },
                sidebar: []
            },
            galleryData: {
                quantity: 2,
                participant: []
            },
            voiceStimulation: false,
            currentSelectSmallIndex: undefined,
            liveing: false,
            loading: false
        }
    }

    public componentDidMount() {
        //鼠标移动
        window.onmousemove = (e) => {
            if (this.isDown == false) {
                return;
            }
            //获取x和y
            const nx = e.clientX;
            const ny = e.clientY;
            //计算移动后的左偏移量和顶部的偏移量
            const nl = nx - (this.x - this.l);
            const nt = ny - (this.y - this.t);
            const w = $(`#${this.dragDivId}`).width();
            const h = $(`#${this.dragDivId}`).height();
            const wP = $(`#drag-parent`).width();
            const hP = $(`#drag-parent`).height();
            const dv = document.getElementById(this.dragDivId) as any;
            if (!dv) return
            console.log(w, h, wP, hP, nl, nt)
            console.log(nl > (wP / 2 - w) && nt < (hP / 2 - h), "右上边") // 
            console.log(nl > (wP / 2 - w) && nt > (hP / 2 - h), "右下边") // 
            console.log(nl < (wP / 2 - w) && nt < (hP / 2 - h), "左上边") // 
            console.log(nl < (wP / 2 - w) && nt > (hP / 2 - h), "左下边") // 
            if (nl < (wP / 2 - w) && nt < (hP / 2 - h)) {//左上
                dv.style.left = 0;
                dv.style.top = 0;
                this.state.speakerData.speakerPosition = 0
            } else if (nl < (wP / 2 - w) && nt > (hP / 2 - h)) {// 左下
                dv.style.left = 0;
                dv.style.top = '3.5rem'// hP - h + 'px';
                this.state.speakerData.speakerPosition = 2
            } else if (nl > (wP / 2 - w) && nt < (hP / 2 - h)) {//右上
                dv.style.left = '8rem'// wP - w + 'px';
                dv.style.top = 0;
                this.state.speakerData.speakerPosition = 1
            } else if (nl > (wP / 2 - w) && nt > (hP / 2 - h)) {// 右下
                dv.style.left = '8rem'// wP - w + 'px';
                dv.style.top = '3.5rem'//hP - h + 'px';
                this.state.speakerData.speakerPosition = 3
            }
        }

        const { sidebarData, galleryData, galleryLayoutType } = this.state;
        sidebarData.sidebar = JSON.parse(JSON.stringify(Array(5).fill({
            activity: false,
            id: '',
            name: ""
        })))
        galleryData.participant = JSON.parse(JSON.stringify(Array(galleryLayoutType).fill({
            activity: false,
            id: '',
            name: ""
        })))
        this.setState({ sidebarData, galleryData })

        const image = new Image();
        image.src = require("static/images/live-default-pic.png");
        image.onload = () => {
            const base64 = getBase64Image(image);
            this.defaultPicture = base64
        }
        const image1 = new Image();
        image1.src = require("static/images/live-default-pic2.png");
        image1.onload = () => {
            const base64 = getBase64Image(image1);
            this.defaultPicture2 = base64
        }
    }
    // 默认开始直播时，自动设置参数 
    public initDefault(data, noSendDataWss) {
        console.log(data, '默认开始直播时，自动设置参数')
        let { speakerData, sidebarData, galleryData } = this.state;
        if (data.type === 0) { // 主讲
            speakerData = {
                main: {
                    activity: data.data.main.activity,
                    id: data.data.main.id,
                    track: "",
                    name: data.data.main.activity ? i18n.t("voiceStimulation") : "",
                    avatar: "",
                    image: data.data.main.image
                },
                speakerPosition: data.data.speakerPosition,
                speaker: {
                    activity: data.data.speaker.activity,
                    id: data.data.speaker.id,
                    track: "",
                    name: data.data.speaker.activity ? i18n.t("voiceStimulation") : "",
                    avatar: "",
                },
                questioner: {
                    activity: data.data.questioner ? data.data.questioner.activity : false,
                    id: data.data.questioner ? data.data.questioner.id : "",
                    track: "",
                    name: (data.data.questioner && (data.data.questioner.activity || data.data.questioner.id)) ? "" : i18n.t("without"),
                    avatar: "",
                },
            }
            this.props.participants.forEach(p => {
                if (p.userId === speakerData.main.id) {
                    speakerData.main.avatar = p.avatar
                    speakerData.main.name = p.realName || p._displayName
                    p.list.forEach(t => {
                        if (t.mediaType === "video") {
                            speakerData.main.track = t.jitsiTrack
                        }
                    })
                }
                if (p.userId === speakerData.speaker.id) {
                    speakerData.speaker.avatar = p.avatar
                    speakerData.speaker.name = p.realName || p._displayName
                    p.list.forEach(t => {
                        if (t.mediaType === "video") {
                            speakerData.speaker.track = t.jitsiTrack
                        }
                    })
                }
                if (p.userId === speakerData.questioner.id) {
                    speakerData.questioner.avatar = p.avatar
                    speakerData.questioner.name = p.realName || p._displayName
                    p.list.forEach(t => {
                        if (t.mediaType === "video") {
                            speakerData.questioner.track = t.jitsiTrack
                        }
                    })
                }
            });
            this.setState({ speakerData, mode: "speaker" }, () => {
                this.confirmLiveLayout(noSendDataWss)
            })
        } else if (data.type === 1) {
            sidebarData = {
                main: {
                    activity: data.data.main.activity,
                    id: data.data.main.id,
                    track: "",
                    name: "",
                    avatar: "",
                    image: data.data.main.image
                },
                sidebar: data.data.sidebar.map(({ activity, id }) => ({
                    activity,
                    id,
                    track: "",
                    name: activity ? i18n.t("voiceStimulation") : "",
                    avatar: "",
                }))
            }
            this.props.participants.forEach(p => {
                if (p.userId === sidebarData.main.id) {
                    sidebarData.main.avatar = p.avatar
                    sidebarData.main.name = p.realName || p._displayName
                    p.list.forEach(t => {
                        if (t.mediaType === "video") {
                            sidebarData.main.track = t.jitsiTrack
                        }
                    })
                }
                sidebarData.sidebar.forEach(n => {
                    if (p.userId === n.id) {
                        n.name = p.realName || p._displayName
                        n.avatar = p.avatar
                        p.list.forEach(t => {
                            if (t.mediaType === "video") {
                                n.track = t.jitsiTrack
                            }
                        })
                    }
                });

            });
            console.log(sidebarData)
            this.setState({ sidebarData, mode: "sidebar" }, () => {
                this.confirmLiveLayout(noSendDataWss)
            })
        } else if (data.type === 2) {
            galleryData = {
                quantity: data.data.quantity,
                participant: data.data.participant.map(({ activity, id }) => ({
                    activity,
                    id,
                    track: "",
                    name: activity ? i18n.t("voiceStimulation") : "",
                    avatar: "",
                }))
            }
            galleryData.participant.forEach(n => {
                this.props.participants.forEach(p => {
                    if (p.userId === n.id) {
                        n.name = p.realName || p._displayName
                        n.avatar = p.avatar
                        p.list.forEach(t => {
                            if (t.mediaType === "video") {
                                n.track = t.jitsiTrack
                            }
                        })
                    }
                });
            });
            console.log(galleryData)
            this.setState({ galleryData, mode: "gallery", galleryLayoutType: galleryData.quantity }, () => {
                this.confirmLiveLayout(noSendDataWss)
            })
        }
    }
    public render() {
        const { galleryLayoutType, mode, liveStreaming, isEdit, sidebarData, galleryData, speakerData } = this.state;
        return (
            <div className="live-setting-component-mask" id="live-setting-component">
                <div className="live-setting-component" id="live-setting-component">
                    {
                        !liveStreaming ? <div className="nav">
                            {
                                !isEdit && <div className="title">{i18n.t("meet.selectMode")}</div>
                            }
                            {
                                !isEdit && <div className="mode">
                                    <span onClick={this.changeMode.bind(this, 'speaker')} className={mode === "speaker" ? "active" : ""}>{i18n.t("meet.leadMode")}</span>
                                    <div className="line"></div>
                                    <span onClick={this.changeMode.bind(this, 'sidebar')} className={mode === "sidebar" ? "active" : ""}>{i18n.t("meet.sidebarMode")}</span>
                                    <div className="line"></div>
                                    <Dropdown trigger={["click"]}
                                        overlayStyle={{ paddingTop: "10px", left: "630px" }}
                                        overlay={
                                            <Menu className="meet-footer" style={{ background: "rgba(0,0,0,0.8)", borderRadius: '0.14rem' }}>
                                                {this.layoutRender()}
                                            </Menu>} placement="bottomCenter">
                                        <div>
                                            <span onClick={this.changeMode.bind(this, 'gallery')} className={mode === "gallery" ? "active" : ""}>{i18n.t("meet.galleryMode")}</span>
                                            <DownOutlined style={{ marginLeft: "0.15rem" }} />
                                        </div>
                                    </Dropdown>
                                </div>
                            }
                            <div className="btns">
                                <div className="btn1" onClick={this.cancelHandle.bind(this)}>{i18n.t("cancel")}</div>
                                <div className="btn2" onClick={this.confirmLiveLayout.bind(this, false)}>{i18n.t("login.confirm")}</div>
                            </div>
                        </div> : <div className="nav" style={{ justifyContent: "space-between" }}>
                            <div className="editBox">
                                <div className="change" onClick={this.change.bind(this)}>{i18n.t("change")}</div>
                                <div className="edit" onClick={this.edit.bind(this)}>{i18n.t("edit")}</div>
                                <div className="btn3" onClick={this.stopLive.bind(this)}><img src={require("static/images/meet/icon-stop.png")} alt="" />{i18n.t("stop")}</div>
                            </div>
                            <div className="liveing">
                                <img src="" alt="" />
                                <span>{i18n.t('live')}</span>
                            </div>
                            <div className="closeImg">
                                <img src={require("static/images/home/icon-close-white.png")} alt="" onClick={() => {
                                    // 隐藏
                                    const live = document.getElementById('live-setting-component')
                                    if (live) live.style.zIndex = '-1'
                                    this.srs && this.srs.stop()
                                    this.srs = null
                                }} />
                            </div>
                        </div>
                    }

                    {//主讲
                        !liveStreaming && mode === "speaker" && <div className="content" id="drag-parent" onMouseUp={this.onMouseUpSpeaker.bind(this)}>
                            {// 主讲-共享屏幕
                                <Popover trigger={["hover"]} content={
                                    <Menu className="meet-footer" style={{ background: "transparent", borderRadius: '0.14rem', borderRight: "none" }}>
                                        {this.largeParticipantRender(speakerData)}
                                    </Menu>} placement="bottom">
                                    <div className="speaker-large" >{speakerData.main.track ?
                                        <video autoPlay={true} muted={true} id="sidebar-large"></video>
                                        : (speakerData.main.image ?
                                            <div className="img1" style={{ backgroundImage: `url(${speakerData.main.image})` }} onClick={() => this.uploadPicture(speakerData)}>
                                                <span>{i18n.t("clickUploadImage")}</span>
                                            </div> :
                                            (speakerData.main.name ? (speakerData.main.id ? <img className="img2" src={speakerData.main.avatar || require("static/images/default-photo.png")} /> : speakerData.main.name) : i18n.t("mainScreen")))}
                                    </div>
                                </Popover>
                            }
                            <div className="speaker-small-drag" id="drag1" onMouseDown={this.onMouseDownSpeaker.bind(this)} >
                                { // 主讲-演讲人
                                    mode === "speaker" && <Popover trigger={["click"]} content={
                                        <Menu className="meet-footer" style={{ background: "transparent", borderRadius: '0.14rem', borderRight: "none" }}>
                                            {this.speakerParticipantRender(speakerData.speaker, 1)}
                                        </Menu>} placement="leftTop">
                                        <div className="speaker-small" >{speakerData.speaker.track ?
                                            <video autoPlay={true} muted={true} id="speaker-small-1"></video>
                                            : (speakerData.speaker.name ? (speakerData.speaker.id ? <img className="img2" src={speakerData.speaker.avatar || require("static/images/default-photo.png")} /> : speakerData.speaker.name) : i18n.t("meet.speaker"))}</div>
                                    </Popover>
                                }
                                { // 主讲-提问人
                                    speakerData.speaker.name !== i18n.t("without") && mode === "speaker" && <Popover trigger={["click"]} content={
                                        <Menu className="meet-footer" style={{ background: "transparent", borderRadius: '0.14rem', borderRight: "none" }}>
                                            {this.speakerParticipantRender(speakerData.questioner, 2)}
                                        </Menu>} placement="leftTop">
                                        <div className="speaker-small" >{speakerData.questioner.track ?
                                            <video autoPlay={true} muted={true} id="speaker-small-2"></video>
                                            : (speakerData.questioner.name ? (speakerData.questioner.id ? <img className="img2" src={speakerData.questioner.avatar || require("static/images/default-photo.png")} /> : speakerData.questioner.name) : i18n.t("meet.speaker"))}</div>
                                    </Popover>
                                }
                            </div>
                        </div>
                    }
                    {// 边栏
                        !liveStreaming && mode === "sidebar" && <div className="content sidebar">
                            <Popover trigger={["click"]} content={
                                <Menu className="meet-footer" style={{ background: "transparent", borderRadius: '0.14rem', borderRight: "none" }}>
                                    {this.largeParticipantRender(sidebarData)}
                                </Menu>} placement="bottom">
                                <div className="left" >{sidebarData.main.track ?
                                    <video autoPlay={true} muted={true} id={`sidebar-large`}></video>
                                    : sidebarData.main.image ? <div className="img1" style={{ backgroundImage: `url(${sidebarData.main.image})` }} onClick={() => this.uploadPicture(sidebarData)}>
                                        <span>{i18n.t("clickUploadImage")}</span>
                                    </div> : (sidebarData.main.name ? (sidebarData.main.id ? <img className="img2" src={sidebarData.main.avatar || require("static/images/default-photo.png")} /> : sidebarData.main.name) : i18n.t('mainScreen'))}
                                </div>
                            </Popover>
                            <div className="right">
                                {sidebarData.sidebar.map((ele, index) => {
                                    return <Popover key={index} trigger={["click"]} onVisibleChange={(value) => {
                                        if (value) {
                                            this.setState({ currentSelectIndex: index })
                                        }
                                    }}
                                        content={
                                            <Menu className="meet-footer" style={{ background: "transparent", borderRadius: '0.14rem', borderRight: "none" }}>
                                                {this.participantRender()}
                                            </Menu>} placement="leftTop">
                                        <div key={index} className="sidebar-small">{ele.track ?
                                            <video autoPlay={true} muted={true} id={`sidebar-small-` + index}></video>
                                            : (ele.name ? (ele.id ? <img src={ele.avatar || require("static/images/default-photo.png")} /> : ele.name) : i18n.t("guest"))}</div>
                                    </Popover>
                                })}
                            </div>
                        </div>
                    }
                    { // 画廊
                        !liveStreaming && mode === "gallery" && <div className="content gallery">
                            {galleryLayoutType !== 7 && galleryData.participant.map((ele, index) => {
                                return <Popover key={index} trigger={["click"]} onVisibleChange={(value) => {
                                    if (value) {
                                        this.setState({ currentSelectIndex: index })
                                    }
                                }}
                                    content={
                                        <Menu className="meet-footer" style={{ background: "none", borderRadius: '0.14rem', borderRight: "none" }}>
                                            {this.galleryParticipantRender()}
                                        </Menu>} placement="right"><div key={index + ""} className={`gallery-small col${galleryLayoutType}`}>{ele.track ?
                                            <video autoPlay={true} muted={true} id={`gallery-small-` + index}></video>
                                            : (ele.name ? (ele.id ? <img src={ele.avatar || require("static/images/default-photo.png")} /> : ele.name) : i18n.t("guest"))}</div>
                                </Popover>
                            })}
                            {galleryLayoutType === 7 && galleryData.participant.map((node, index) => {
                                return index < 3 && <Popover key={index} trigger={["click"]} onVisibleChange={(value) => {
                                    if (value) {
                                        this.setState({ currentSelectIndex: index, currentSelectSmallIndex: undefined })
                                    }
                                }}
                                    content={
                                        <Menu className="meet-footer" style={{ background: "none", borderRadius: '0.14rem', borderRight: "none" }}>
                                            {this.gallery7ParticipantRender()}
                                        </Menu>} placement="right"><div key={index + ""} className={`gallery-small col${galleryLayoutType}`}>{node.track ?
                                            <video autoPlay={true} muted={true} id={`gallery-small-` + index}></video>
                                            : (node.name ? (node.id ? <img src={node.avatar || require("static/images/default-photo.png")} /> : node.name) : i18n.t("guest"))}</div>
                                </Popover>
                            })}
                            {galleryLayoutType === 7 && <div className={`gallery-small col${galleryLayoutType}`} style={{ flexWrap: "wrap" }}>
                                {
                                    galleryData.participant.map((node, indexSmall) => {
                                        return indexSmall >= 3 && <Popover key={indexSmall} trigger={["click"]} onVisibleChange={(value) => {
                                            if (value) {
                                                this.setState({ currentSelectSmallIndex: indexSmall })
                                            }
                                        }}
                                            content={
                                                <Menu className="meet-footer" style={{ background: "none", borderRadius: '0.14rem', borderRight: "none" }}>
                                                    {this.gallery7ParticipantRender()}
                                                </Menu>} placement="right"><div key={indexSmall + ""} className={`small`}>{node.track ?
                                                    <video autoPlay={true} muted={true} id={`small-` + indexSmall}></video>
                                                    : (node.name ? (node.id ? <img src={node.avatar || require("static/images/default-photo.png")} /> : node.name) : i18n.t("guest"))}</div>
                                        </Popover>
                                    })
                                }
                            </div>}

                        </div>
                    }

                    { // 直播
                        liveStreaming && (this.state.loading ? <div className="loadBox">
                            <Spin indicator={<LoadingOutlined style={{ fontSize: '0.24rem', color: "#fff" }} spin={true} />} />
                            <p>{i18n.t('loading')}</p></div> :
                            <video src="" id="liveMeetingVideo1" controls={false} autoPlay muted={true} style={{ width: "100%", height: "100%" }}></video>)
                    }
                </div>
            </div>
        );
    }

    public cancelHandle() {
        if (this.state.liveing) {
            this.setState({ liveStreaming: true }, () => this.pullStream())
        } else {
            this.props.closeLive()
            this.props.close()
        }
    }

    // 确认直播布局
    public confirmLiveLayout(noSendDataWss) {
        this.setState({ liveStreaming: true, loading: true }, () => this.pullStream())
        this.updateLiveInfo(noSendDataWss)
    }

    public updateLiveInfo(noSendDataWss) {
        const { mode, sidebarData, galleryLayoutType, galleryData, speakerData } = this.state;
        let data = {}
        if (mode === "speaker") {
            const nodata = !speakerData.main.activity && !speakerData.main.id && !speakerData.main.image;
            let shareId = ""
            if (nodata) {
                const target = this.props.participants.filter(x => x.sharedUser === true)
                if (target.length) {
                    shareId = target[0].userId
                }
            }
            data = {
                type: 0,
                data: {
                    main: {
                        activity: speakerData.main.activity,
                        id: nodata ? shareId : speakerData.main.id,
                        image: speakerData.main.image,
                    },
                    speakerPosition: speakerData.speakerPosition,
                    speaker: {
                        activity: speakerData.speaker.activity,
                        id: speakerData.speaker.id,
                    },
                    questioner: speakerData.speaker.id || speakerData.speaker.activity ? {
                        activity: speakerData.questioner.activity,
                        id: speakerData.questioner.id,
                    } : null,
                }
            }
        } else if (mode === "sidebar") {
            data = {
                type: 1,
                data: {
                    main: {
                        activity: sidebarData.main.activity,
                        id: sidebarData.main.id,
                        image: sidebarData.main.image
                    },
                    sidebar: sidebarData.sidebar.map(({ activity, id }) => ({ show: !(!activity && !id), activity, id }))
                }
            }
        } else if (mode === "gallery") {
            galleryData.participant.forEach(x => {
                if (!x.activity && !x.id) {
                    x.image = this.defaultPicture2;
                }
            })
            data = {
                type: 2,
                data: {
                    quantity: galleryLayoutType,
                    participant: galleryData.participant.map(({ activity, id, image }) => ({ activity, id, image }))
                }
            }
        }
        console.log(noSendDataWss)
        if (!noSendDataWss) {//noSendDataWss 如果是同步消息就不发数据
            this.props.updateLiveInfo(data)
        }
        this.setState({ liveing: true })
    }
    // 拉直播流
    public pullStream() {
        this.statusNum += 1
        if (this.statusNum === 10) { // 直播一直没返回状态
            this.statusNum = 0
            return
        }
        if (this.props.liveStatus !== 2) {
            const timer = setTimeout(() => {
                clearTimeout(timer)
                this.pullStream()
            }, 500)
            return
        } else {
            this.statusNum = 0
        }
        if (this.srs) {
            this.setState({ loading: false }, () => {
                const videoRemote = document.getElementById('liveMeetingVideo1') as any;
                // 视频加载完成后播放
                videoRemote.addEventListener('load', () => {
                    videoRemote.play();
                });
                videoRemote.srcObject = this.streams;
            })
        } else {
            // 创建srs实例
            // this.srs = new WpsSdk(
            //     LIVE_URL,
            //     async () => {
            //         // 服务已连接
            //         logger.warn('WpsSdk服务已连接。');
            //         // 推送和拉取接口地址
            //         this.pull()
            //     },
            //     () => {
            //         // 服务关闭
            //         logger.warn('WpsSdk服务已断开。');
            //         this.setState({ loading: false })
            //     }
            // );
            this.srs = new SrsSdk(LIVE_URL_PUSH, LIVE_URL_PULL);
            // 拉流
            try {
                let audioReady = true
                this.srs.pull({
                    id: `/meeting/${this.props.meetData.primaryKey}`,
                    codec: LIVE_CODEC
                }, [], (evt) => {
                    // 远程流加入
                    this.setState({ loading: false }, () => {
                        logger.info('on track streams:', evt.streams,);
                        const videoRemote = document.getElementById('liveMeetingVideo1') as any;
                        if (!audioReady) return
                        videoRemote.srcObject = evt.streams[0];
                        this.streams = evt.streams[0];
                        audioReady = false
                        videoRemote.play().then(() => {
                            console.log("播放成功")
                        }, e => {
                            console.log('播放失败', e)
                        })
                    })
                });
            } catch (err) {
                logger.error('pull stream error', err);
                message.error(i18n.t("message.requestFailed"));
                this.setState({ loading: false })
            }
        }
    }

    public async pull() {
        try {
            const cmd = await this.srs.startPull({
                conf: this.props.meetData.primaryKey,
                name: this.props.userId,
                net: 'wifi'
            }, (streams) => {
                this.setState({ loading: false }, () => {
                    // 远程流加入
                    const videoRemote = document.getElementById('liveMeetingVideo1') as any;
                    // 视频加载完成后播放
                    videoRemote.addEventListener('load', () => {
                        videoRemote.play();
                    });
                    console.debug('服务端流接入:', streams);
                    videoRemote.srcObject = streams[0];
                    this.streams = streams[0];
                })
            });
            logger.info('⬇️\t发送pull请求：', JSON.stringify(cmd, null, 2));
        } catch (err) {
            message.error(i18n.t("message.requestFailed"));
            logger.error('⬇️🔥\t发送pull请求异常：', err.message);
            this.setState({ loading: false })
        }
    }

    // 关闭直播流
    public stopStream() {
        // 关闭推流/拉流
        try {
            const videoRemote = document.getElementById('liveMeetingVideo1') as any;
            // this.srs.stop();
            if (videoRemote) {
                videoRemote.pause();
                videoRemote.srcObject = null;
            }
        } catch (err) {
            console.error('stop pull error', err);
        }
    }

    public changeMode(value) {
        this.setState({ mode: value, voiceStimulation: false, currentSelectIndex: 0 }, () => {
            this.setAttach()
        })
    }

    // 更改
    public change() {
        // this.stopStream()
        this.setState({ liveStreaming: false, isEdit: false }, () => {
            this.setAttach()
        })
    }

    // 编辑
    public edit() {
        // this.stopStream()
        this.setState({ liveStreaming: false, isEdit: true }, () => {
            this.setAttach()
        })
    }

    // 切换或更改时数据显示
    public setAttach() {
        const { mode, sidebarData, galleryData, galleryLayoutType, speakerData } = this.state;
        if (mode === "sidebar") {
            if (sidebarData.main.track) {
                const video = document.getElementById('sidebar-large')
                video && sidebarData.main.track.attach(video)
            }
            sidebarData.sidebar.forEach((node, index) => {
                if (node.track) {
                    const video = document.getElementById('sidebar-small-' + index)
                    video && node.track.attach(video)
                }
            })
        } else if (mode === "gallery") {
            galleryData.participant.forEach((node, index) => {
                if (node.track) {
                    if (galleryLayoutType === 7) {
                        if (index < 3) {
                            const video = document.getElementById('gallery-small-' + index)
                            video && node.track.attach(video)
                        } else {
                            const video = document.getElementById('small-' + index)
                            video && node.track.attach(video)
                        }
                    } else {
                        const video = document.getElementById('gallery-small-' + index)
                        video && node.track.attach(video)
                    }
                }
            })
        } else {
            if (speakerData.main.track) {
                const video = document.getElementById('sidebar-large')
                video && speakerData.main.track.attach(video)
            }
            if (speakerData.questioner.track) {
                const video = document.getElementById('speaker-small-' + 2)
                video && speakerData.questioner.track.attach(video)
            }
            if (speakerData.speaker.track) {
                const video = document.getElementById('speaker-small-' + 1)
                video && speakerData.speaker.track.attach(video)
            }
            const dv = document.getElementById(this.dragDivId) as any;
            const w = $(`#${this.dragDivId}`).width();
            const h = $(`#${this.dragDivId}`).height();
            const wP = $(`#drag-parent`).width();
            const hP = $(`#drag-parent`).height();
            if (dv) {
                if (speakerData.speakerPosition === 0) {//左上
                    dv.style.left = 0 + 'px';
                    dv.style.top = 0 + 'px';
                } else if (speakerData.speakerPosition === 2) {// 左下
                    dv.style.left = 0 + 'px';
                    dv.style.top = hP - h + 'px';
                } else if (speakerData.speakerPosition === 1) {//右上
                    dv.style.left = wP - w + 'px';
                    dv.style.top = 0 + 'px';
                } else if (speakerData.speakerPosition === 3) {// 右下
                    dv.style.left = wP - w + 'px';
                    dv.style.top = hP - h + 'px';
                }
            }
        }
    }

    // 停止直播
    public async stopLive() {
        await this.stopStream()
        this.props.closeLive()
        this.setState({ liveing: false, liveStreaming: false })
        this.props.close()
        this.srs && this.srs.stop()
        this.srs = null
    }

    // 画廊模式选择
    public layoutRender() {
        return <div className="live-gallery-dropdown" style={{ paddingLeft: "0.16rem" }}>
            {
                galleryModeType.map(ele => {
                    return <div key={ele} className={this.state.galleryLayoutType === ele ? "active" : ""} onClick={() => {
                        this.state.galleryData.participant = JSON.parse(JSON.stringify(Array(ele).fill({
                            activity: false,
                            id: '',
                            name: ""
                        })))
                        this.changeMode('gallery')
                        this.setState({ galleryLayoutType: ele, galleryData: this.state.galleryData })
                    }}>{ele}</div>
                })
            }
        </div>
    }

    // 边栏参与人列表
    public participantRender() {
        const { sidebarData, currentSelectIndex, voiceStimulation } = this.state;
        return <div className="live-gallery-dropdown">
            <div className={sidebarData.sidebar[currentSelectIndex].name === '' ? "active" : ""} onClick={() => {
                if (sidebarData.sidebar[currentSelectIndex].name === i18n.t("voiceStimulation")) {
                    this.setState({ voiceStimulation: false })
                }
                sidebarData.sidebar[currentSelectIndex].name = i18n.t("without");
                sidebarData.sidebar[currentSelectIndex].id = '';
                sidebarData.sidebar[currentSelectIndex].track = '';
                sidebarData.sidebar[currentSelectIndex].activity = false;
                sidebarData.sidebar[currentSelectIndex].avatar = '';
                this.setState({ sidebarData })
            }}>{i18n.t("without")}</div>
            {
                !voiceStimulation && <div className={sidebarData.sidebar[currentSelectIndex].name === i18n.t("voiceStimulation") ? "active" : ""} onClick={() => {
                    sidebarData.sidebar[currentSelectIndex].name = i18n.t("voiceStimulation");
                    sidebarData.sidebar[currentSelectIndex].activity = true;
                    sidebarData.sidebar[currentSelectIndex].track = '';
                    sidebarData.sidebar[currentSelectIndex].id = '';
                    sidebarData.sidebar[currentSelectIndex].avatar = '';
                    this.setState({ sidebarData, voiceStimulation: true })
                }}>{i18n.t("voiceStimulation")}</div>
            }
            {
                this.props.participants.map(ele => {
                    return <div key={ele.userId} className={sidebarData.sidebar[currentSelectIndex].id === ele.userId ? "active" : ""} onClick={() => {
                        if (sidebarData.sidebar[currentSelectIndex].name === i18n.t("voiceStimulation")) {
                            this.setState({ voiceStimulation: false })
                        }
                        sidebarData.sidebar[currentSelectIndex].name = ele.realName || ele._displayName;
                        sidebarData.sidebar[currentSelectIndex].id = ele.userId;
                        sidebarData.sidebar[currentSelectIndex].activity = false;
                        sidebarData.sidebar[currentSelectIndex].avatar = ele.avatar;
                        const jit = ele.list.filter(x => x.mediaType === "video")
                        if (jit.length) {
                            sidebarData.sidebar[currentSelectIndex].track = jit[0].jitsiTrack;
                        } else {
                            sidebarData.sidebar[currentSelectIndex].track = ""
                        }
                        this.setState({ sidebarData }, () => {
                            if (jit.length) {
                                const video = document.getElementById('sidebar-small-' + currentSelectIndex)
                                video && sidebarData.sidebar[currentSelectIndex].track.attach(video)
                            }
                        })
                    }}>{ele.realName || ele._displayName}</div>
                })
            }
        </div>
    }

    // 主讲模式：演讲人、提问人
    public speakerParticipantRender(data, type) {
        const { voiceStimulation } = this.state;
        return <div className="live-gallery-dropdown">
            <div className={data.name === '' ? "active" : ""} onClick={() => {
                if (data.name === i18n.t("voiceStimulation")) {
                    this.setState({ voiceStimulation: false })
                }
                data.name = i18n.t("without");
                data.id = '';
                data.track = '';
                data.activity = false;
                data.avatar = '';
                this.setState({ speakerData: this.state.speakerData })
            }}>{i18n.t("without")}</div>
            {
                !voiceStimulation && <div className={data.name === i18n.t("voiceStimulation") ? "active" : ""} onClick={() => {
                    data.name = i18n.t("voiceStimulation");
                    data.activity = true;
                    data.track = '';
                    data.id = '';
                    data.avatar = '';
                    data.image = '';
                    this.setState({ speakerData: this.state.speakerData, voiceStimulation: true })
                }}>{i18n.t("voiceStimulation")}</div>
            }
            {
                this.props.participants.map(ele => {
                    return !ele.sharedUser && <div key={ele.userId} className={data.id === ele.userId ? "active" : ""} onClick={() => {
                        if (data.name === i18n.t("voiceStimulation")) {
                            this.setState({ voiceStimulation: false })
                        }
                        data.name = ele.realName || ele._displayName;
                        data.id = ele.userId;
                        data.activity = false;
                        data.avatar = ele.avatar;
                        data.image = '';
                        const jit = ele.list.filter(x => x.mediaType === "video")
                        data.track = jit.length ? jit[0].jitsiTrack : "";
                        this.setState({ speakerData: this.state.speakerData }, () => {
                            if (data.track) {
                                const video = document.getElementById('speaker-small-' + type)
                                video && data.track.attach(video)
                            }
                        })
                    }}>{ele.realName || ele._displayName}</div>
                })
            }
        </div>
    }

    // 大画面
    public largeParticipantRender(sidebarData) {
        const { voiceStimulation, mode } = this.state;
        const share = this.props.participants.filter(x => x.sharedUser)
        return <div className="live-gallery-dropdown">
            {
                !voiceStimulation && <div className={sidebarData.main.name === i18n.t("voiceStimulation") ? "active" : ""} onClick={() => {
                    sidebarData.main.name = i18n.t("voiceStimulation");
                    sidebarData.main.activity = true;
                    sidebarData.main.track = '';
                    sidebarData.main.id = '';
                    sidebarData.main.avatar = '';
                    sidebarData.main.image = '';
                    if (mode === "speaker") {
                        this.setState({ speakerData: sidebarData, voiceStimulation: true })
                    } else {
                        this.setState({ sidebarData, voiceStimulation: true })
                    }
                }}>{i18n.t("voiceStimulation")}</div>
            }
            {share.length ?
                <div className={sidebarData.main.name === i18n.t("meet.sharingScreen") ? "active" : ""} onClick={() => {
                    if (sidebarData.main.name === i18n.t("voiceStimulation")) {
                        this.setState({ voiceStimulation: false })
                    }
                    sidebarData.main.name = i18n.t("meet.sharingScreen");
                    sidebarData.main.activity = false;
                    let jitsiTrack: any = "";
                    if (share.length) {
                        jitsiTrack = share[0].list.filter(x => x.mediaType === "video")[0].jitsiTrack
                    }
                    sidebarData.main.track = jitsiTrack;
                    sidebarData.main.id = share.length ? share[0].userId : "";
                    sidebarData.main.avatar = '';
                    sidebarData.main.image = '';
                    if (mode === "speaker") {
                        this.setState({ speakerData: sidebarData, }, () => {
                            if (jitsiTrack) {
                                const video = document.getElementById('sidebar-large')
                                video && jitsiTrack.attach(video)
                            }
                        })
                    } else {
                        this.setState({ sidebarData }, () => {
                            if (jitsiTrack) {
                                const video = document.getElementById('sidebar-large')
                                video && jitsiTrack.attach(video)
                            }
                        })
                    }

                }}>{i18n.t("meet.sharingScreen")}</div> : ""
            }
            {
                <div className={sidebarData.main.name === i18n.t("picture") ? "active" : ""} onClick={() => {
                    if (sidebarData.main.name === i18n.t("voiceStimulation")) {
                        this.setState({ voiceStimulation: false })
                    }
                    sidebarData.main.activity = false;
                    sidebarData.main.track = '';
                    sidebarData.main.id = '';
                    sidebarData.main.avatar = '';
                    sidebarData.main.image = this.defaultPicture
                    sidebarData.main.name = i18n.t("picture");
                    if (mode === "speaker") {
                        this.setState({ speakerData: sidebarData })
                    } else {
                        this.setState({ sidebarData })
                    }
                }}>{i18n.t("picture")}</div>
            }
            {
                this.props.participants.map(ele => {
                    return !ele.sharedUser && <div key={ele.userId} className={sidebarData.main.id === ele.userId ? "active" : ""} onClick={() => {
                        if (sidebarData.main.name === i18n.t("voiceStimulation")) {
                            this.setState({ voiceStimulation: false })
                        }
                        sidebarData.main.name = ele.realName || ele._displayName;
                        sidebarData.main.id = ele.userId;
                        sidebarData.main.activity = false;
                        sidebarData.main.avatar = ele.avatar;
                        sidebarData.main.image = '';
                        const jit = ele.list.filter(x => x.mediaType === "video")
                        sidebarData.main.track = jit.length ? jit[0].jitsiTrack : "";

                        if (mode === "speaker") {
                            this.setState({ speakerData: sidebarData }, () => {
                                if (jit.length) {
                                    const video = document.getElementById('sidebar-large')
                                    video && jit[0].jitsiTrack.attach(video)
                                }
                            })
                        } else {
                            this.setState({ sidebarData }, () => {
                                if (jit.length) {
                                    const video = document.getElementById('sidebar-large')
                                    video && jit[0].jitsiTrack.attach(video)
                                }
                            })
                        }
                    }}>{ele.realName || ele._displayName}</div>
                })
            }
        </div>
    }

    // 画廊参与人列表
    public galleryParticipantRender() {
        const { galleryData, currentSelectIndex, voiceStimulation } = this.state;
        const share = this.props.participants.filter(x => x.sharedUser)
        return <div className="live-gallery-dropdown">
            <div className={galleryData.participant[currentSelectIndex].name === '' ? "active" : ""} onClick={() => {
                if (galleryData.participant[currentSelectIndex].name === i18n.t("voiceStimulation")) {
                    this.setState({ voiceStimulation: false })
                }
                galleryData.participant[currentSelectIndex].name = '';
                galleryData.participant[currentSelectIndex].id = '';
                galleryData.participant[currentSelectIndex].track = '';
                galleryData.participant[currentSelectIndex].activity = false;
                galleryData.participant[currentSelectIndex].avatar = '';
                this.setState({ galleryData })
            }}>{i18n.t("without")}</div>
            {
                !voiceStimulation && <div className={galleryData.participant[currentSelectIndex].name === i18n.t("voiceStimulation") ? "active" : ""} onClick={() => {
                    galleryData.participant[currentSelectIndex].name = i18n.t("voiceStimulation");
                    galleryData.participant[currentSelectIndex].activity = true;
                    galleryData.participant[currentSelectIndex].track = '';
                    galleryData.participant[currentSelectIndex].id = '';
                    galleryData.participant[currentSelectIndex].avatar = '';
                    this.setState({ galleryData, voiceStimulation: true })
                }}>{i18n.t("voiceStimulation")}</div>
            }
            {share.length ?
                <div className={galleryData.participant[currentSelectIndex].name === i18n.t("meet.sharingScreen") ? "active" : ""} onClick={() => {
                    if (galleryData.participant[currentSelectIndex].name === i18n.t("voiceStimulation")) {
                        this.setState({ voiceStimulation: false })
                    }
                    galleryData.participant[currentSelectIndex].name = i18n.t("meet.sharingScreen");
                    galleryData.participant[currentSelectIndex].activity = false;
                    let jitsiTrack: any = "";
                    if (share.length) {
                        jitsiTrack = share[0].list.filter(x => x.mediaType === "video")[0].jitsiTrack
                    }
                    galleryData.participant[currentSelectIndex].track = jitsiTrack;
                    galleryData.participant[currentSelectIndex].id = share.length ? share[0].userId : "";
                    galleryData.participant[currentSelectIndex].avatar = '';
                    galleryData.participant[currentSelectIndex].image = '';
                    this.setState({ galleryData }, () => {
                        if (jitsiTrack) {
                            const video = document.getElementById('gallery-small-' + currentSelectIndex)
                            video && jitsiTrack.attach(video)
                        }
                    })
                }}>{i18n.t("meet.sharingScreen")}</div> : ""
            }
            {
                this.props.participants.map(ele => {
                    return !ele.sharedUser && <div key={ele.userId} className={galleryData.participant[currentSelectIndex].id === ele.userId ? "active" : ""} onClick={() => {
                        if (galleryData.participant[currentSelectIndex].name === i18n.t("voiceStimulation")) {
                            this.setState({ voiceStimulation: false })
                        }
                        galleryData.participant[currentSelectIndex].name = ele.realName || ele._displayName;
                        galleryData.participant[currentSelectIndex].id = ele.userId;
                        galleryData.participant[currentSelectIndex].activity = false;
                        galleryData.participant[currentSelectIndex].avatar = ele.avatar;
                        const jit = ele.list.filter(x => x.mediaType === "video")
                        if (jit.length) {
                            galleryData.participant[currentSelectIndex].track = jit[0].jitsiTrack;
                        } else {
                            galleryData.participant[currentSelectIndex].track = ""
                        }
                        this.setState({ galleryData }, () => {
                            if (jit.length) {
                                const video = document.getElementById('gallery-small-' + currentSelectIndex)
                                video && jit[0].jitsiTrack.attach(video)
                            }
                        })
                    }}>{ele.realName || ele._displayName}</div>
                })
            }
        </div>
    }

    // 画廊参与人列表7
    public gallery7ParticipantRender() {
        const { galleryData, currentSelectIndex, voiceStimulation, currentSelectSmallIndex } = this.state;
        const share = this.props.participants.filter(x => x.sharedUser)
        return <div className="live-gallery-dropdown">
            <div className={galleryData.participant[currentSelectSmallIndex || currentSelectIndex].name === '' ? "active" : ""} onClick={() => {
                if (currentSelectSmallIndex) { // 小框
                    if (galleryData.participant[currentSelectSmallIndex].name === i18n.t("voiceStimulation")) {
                        this.setState({ voiceStimulation: false })
                    }
                    galleryData.participant[currentSelectSmallIndex].name = '';
                    galleryData.participant[currentSelectSmallIndex].id = '';
                    galleryData.participant[currentSelectSmallIndex].track = '';
                    galleryData.participant[currentSelectSmallIndex].activity = false;
                    galleryData.participant[currentSelectSmallIndex].avatar = '';
                    this.setState({ galleryData })
                    return
                }
                if (galleryData.participant[currentSelectIndex].name === i18n.t("voiceStimulation")) {
                    this.setState({ voiceStimulation: false })
                }
                galleryData.participant[currentSelectIndex].name = '';
                galleryData.participant[currentSelectIndex].id = '';
                galleryData.participant[currentSelectIndex].track = '';
                galleryData.participant[currentSelectIndex].activity = false;
                galleryData.participant[currentSelectIndex].avatar = '';
                this.setState({ galleryData })
            }}>{i18n.t("without")}</div>
            {
                !voiceStimulation && <div className={galleryData.participant[currentSelectSmallIndex || currentSelectIndex].name === i18n.t("voiceStimulation") ? "active" : ""} onClick={() => {
                    if (currentSelectSmallIndex) { // 小框
                        galleryData.participant[currentSelectSmallIndex].name = i18n.t("voiceStimulation");
                        galleryData.participant[currentSelectSmallIndex].activity = true;
                        galleryData.participant[currentSelectSmallIndex].track = '';
                        galleryData.participant[currentSelectSmallIndex].id = '';
                        galleryData.participant[currentSelectSmallIndex].avatar = '';
                        this.setState({ galleryData, voiceStimulation: true })
                        return
                    }
                    galleryData.participant[currentSelectIndex].name = i18n.t("voiceStimulation");
                    galleryData.participant[currentSelectIndex].activity = true;
                    galleryData.participant[currentSelectIndex].track = '';
                    galleryData.participant[currentSelectIndex].id = '';
                    galleryData.participant[currentSelectIndex].avatar = '';
                    this.setState({ galleryData, voiceStimulation: true })
                }}>{i18n.t("voiceStimulation")}</div>
            }
            {share.length ?
                <div className={galleryData.participant[currentSelectSmallIndex || currentSelectIndex].name === i18n.t("meet.sharingScreen") ? "active" : ""} onClick={() => {
                    if (currentSelectSmallIndex) { // 小框
                        if (galleryData.participant[currentSelectSmallIndex].name === i18n.t("voiceStimulation")) {
                            this.setState({ voiceStimulation: false })
                        }
                        galleryData.participant[currentSelectSmallIndex].name = i18n.t("meet.sharingScreen");
                        galleryData.participant[currentSelectSmallIndex].activity = false;
                        let jitsiTrack: any = "";
                        if (share.length) {
                            jitsiTrack = share[0].list.filter(x => x.mediaType === "video")[0].jitsiTrack
                        }
                        galleryData.participant[currentSelectSmallIndex].track = jitsiTrack;
                        galleryData.participant[currentSelectSmallIndex].id = share.length ? share[0].userId : "";
                        galleryData.participant[currentSelectSmallIndex].avatar = '';
                        galleryData.participant[currentSelectSmallIndex].image = '';
                        this.setState({ galleryData }, () => {
                            if (jitsiTrack) {
                                const video = document.getElementById('small-' + currentSelectSmallIndex)
                                video && galleryData.participant[currentSelectSmallIndex].track.attach(video)
                            }
                        })
                        return
                    }
                    if (galleryData.participant[currentSelectIndex].name === i18n.t("voiceStimulation")) {
                        this.setState({ voiceStimulation: false })
                    }
                    galleryData.participant[currentSelectIndex].name = i18n.t("meet.sharingScreen");
                    galleryData.participant[currentSelectIndex].activity = false;
                    let jitsiTrack: any = "";
                    if (share.length) {
                        jitsiTrack = share[0].list.filter(x => x.mediaType === "video")[0].jitsiTrack
                    }
                    galleryData.participant[currentSelectIndex].track = jitsiTrack;
                    galleryData.participant[currentSelectIndex].id = share.length ? share[0].userId : "";
                    galleryData.participant[currentSelectIndex].avatar = '';
                    galleryData.participant[currentSelectIndex].image = '';
                    this.setState({ galleryData }, () => {
                        if (jitsiTrack) {
                            const video = document.getElementById('gallery-small-' + currentSelectIndex)
                            video && galleryData.participant[currentSelectIndex].track.attach(video)
                        }
                    })
                }}>{i18n.t("meet.sharingScreen")}</div> : ""
            }
            {
                this.props.participants.map(ele => {
                    return !ele.sharedUser && <div key={ele.userId} className={galleryData.participant[currentSelectSmallIndex || currentSelectIndex].id === ele.userId ? "active" : ""} onClick={() => {
                        if (currentSelectSmallIndex) { // 小框
                            if (galleryData.participant[currentSelectSmallIndex].name === i18n.t("voiceStimulation")) {
                                this.setState({ voiceStimulation: false })
                            }
                            galleryData.participant[currentSelectSmallIndex].name = ele.realName || ele._displayName;
                            galleryData.participant[currentSelectSmallIndex].id = ele.userId;
                            galleryData.participant[currentSelectSmallIndex].activity = false;
                            galleryData.participant[currentSelectSmallIndex].avatar = ele.avatar;
                            const jit = ele.list.filter(x => x.mediaType === "video")
                            if (jit.length) {
                                galleryData.participant[currentSelectSmallIndex].track = jit[0].jitsiTrack;
                            } else {
                                galleryData.participant[currentSelectSmallIndex].track = ""
                            }
                            this.setState({ galleryData }, () => {
                                if (jit.length) {
                                    const video = document.getElementById('small-' + currentSelectSmallIndex)
                                    video && galleryData.participant[currentSelectSmallIndex].track.attach(video)
                                }
                            })
                            return
                        }
                        if (galleryData.participant[currentSelectIndex].name === i18n.t("voiceStimulation")) {
                            this.setState({ voiceStimulation: false })
                        }
                        galleryData.participant[currentSelectIndex].name = ele.realName || ele._displayName;
                        galleryData.participant[currentSelectIndex].id = ele.userId;
                        galleryData.participant[currentSelectIndex].activity = false;
                        galleryData.participant[currentSelectIndex].avatar = ele.avatar;
                        const jit = ele.list.filter(x => x.mediaType === "video")
                        if (jit.length) {
                            galleryData.participant[currentSelectIndex].track = jit[0].jitsiTrack;
                        } else {
                            galleryData.participant[currentSelectIndex].track = ""
                        }
                        this.setState({ galleryData }, () => {
                            if (jit.length) {
                                const video = document.getElementById('gallery-small-' + currentSelectIndex)
                                video && galleryData.participant[currentSelectIndex].track.attach(video)
                            }
                        })
                    }}>{ele.realName || ele._displayName}</div>
                })
            }
        </div>
    }

    public onMouseDownSpeaker(e) {
        const dv = document.getElementById("drag1") as any
        //获取x坐标和y坐标
        this.x = e.clientX;
        this.y = e.clientY;

        //获取左部和顶部的偏移量
        this.l = dv.offsetLeft;
        this.t = dv.offsetTop;
        //开关打开
        this.isDown = true;
        //设置样式  
        dv.style.cursor = 'move';
        this.dragDivId = 'drag1'
    }

    public onMouseUpSpeaker() {
        const dv = document.getElementById("drag1") as any
        if (!dv) return
        //开关关闭
        this.isDown = false;
        dv.style.cursor = 'default';
    }

    // 上传图片
    public uploadPicture(sidebarData) {
        const { mode } = this.state;
        const liveFileInput = document.getElementById("liveFileInput") as any;
        const ele = liveFileInput || document.createElement("input")
        if (!liveFileInput) {
            ele.id = "liveFileInput"
            ele.type = "file"
            ele.accept = ".jpeg,.jpg,.png";
            ele.style.cssText = "position: fixed;z-index: -1;"
        }
        document.body.appendChild(ele)
        ele.click()
        const that = this
        ele.onchange = function (e) {
            const input = e.target as any;
            const files = input.files;
            if (files && files[0]) {
                const isJPG = files[0].type === 'image/jpeg' || files[0].type === 'image/png';
                if (!isJPG) {
                    message.error(i18n.t("message.whiteboardImgFormat"));
                    return
                }
                if (window.FileReader) {
                    const reader = new FileReader();
                    reader.readAsDataURL(files[0])
                    reader.onloadend = function (_e: any) {
                        sidebarData.main.image = _e.target.result || "";
                        if (mode === "speaker") {
                            that.setState({ speakerData: sidebarData })
                        } else {
                            that.setState({ sidebarData })
                        }
                    }
                }
            }
            document.body.removeChild(ele)
        }
    }

    public componentWillUnmount() {
        this.onMouseUpSpeaker()
        if (this.srs) {
            this.stopLive()
        }
    }

    private x = 0;
    private y = 0;
    private l = 0;
    private t = 0;
    private isDown = false
    private dragDivId;
    private srs;
    private defaultPicture;
    private defaultPicture2;
    private statusNum = 0;
    private streams;
}