
import React from "react";
import { getPlatformValue } from "utils/common";
import i18n from "i18n";
import "./index.less"
import api from "api";
import QRCode from 'qrcode.react';
import { Input, message } from "antd";
import { IMeetingItem } from "models/meet";
import { copyText } from "utils/data";
import moment from "antd/node_modules/moment";
interface IState {
    showEditPassword: Boolean,
    qrUrl: string;
    search: {
        meetNumber: string,
        password: string,
    };
    isInvitePath: boolean;
    meetingData: IMeetingItem;
}
export default class extends React.Component {

    public state: IState = {
        qrUrl: "",
        showEditPassword: false,
        search: {
            meetNumber: "",
            password: "",
        },
        isInvitePath: false,
        meetingData: {
            primaryKey: "",
            content: "",
            meetNumber: "",
            password: "",
            startDate: "",
            status: 0,
            theme: "",
        },
    }

    public async componentDidMount() {
        // 获取平台
        this.platform = getPlatformValue()
        const str = window.location.href.slice(0, window.location.href.lastIndexOf("/") + 1)
        const path = window.location.href.replace(str, '')
        const arr = path.split("?")
        console.log(arr, str, path)
        if (!path) { // 不是邀请入会链接进来的
            return
        }
        if (arr.length < 2) return
        const obj = {}
        obj['meetNumber'] = arr[0]
        const search = arr[1].split("&")
        search.forEach(element => {
            const arr = element.split("=")
            obj[arr[0]] = arr[1]
        });
        this.setState({ search: obj, isInvitePath: true })
        if (obj['password']) {
            this.getInviteInfo(obj)
        } else {
            this.setState({ showEditPassword: true })
        }
    }

    public render() {
        const { showEditPassword, search, isInvitePath, meetingData } = this.state;
        return <div className="browser-component">
            <div className="main">
                {
                    showEditPassword ? <React.Fragment>
                        <div className="confidential">{i18n.t("dialog.ConnectConfidentialMeeting")}</div>
                        <Input
                            className="input"
                            placeholder={i18n.t('message.pleaseEnterMeetPassword')}
                            value={search.password}
                            maxLength={15}
                            onChange={evt => {
                                const value = evt.target.value.trim();
                                if (/^[0-9]{0,15}$/.test(value)) {
                                    this.setState({
                                        search: {
                                            ...search,
                                            password: value
                                        }
                                    })
                                }
                            }}
                            onKeyDown={(e) => {
                                const evt = window.event || e;
                                if ((evt as any).keyCode == 13) {
                                    this.confirmPassword()
                                }
                            }}
                        />
                        <div className="submitBtn" onClick={this.confirmPassword.bind(this)}>{i18n.t("meet.submit")}</div>
                    </React.Fragment> : <React.Fragment>
                        {isInvitePath && <div className='main-meet'>
                            <div className="name">
                                {meetingData.theme}
                            </div>
                            <div className="time">
                                <span>{meetingData.startDate}</span>
                                <span style={{ background: meetingData.statusBackground }}>{meetingData.statusText}</span>
                            </div>
                            {
                                meetingData.meetNumber && <div className="containerText">
                                    <span>{i18n.t('conferenceInvitationCode')}</span><span>：</span>
                                    <span>{meetingData.meetNumber}</span>
                                </div>
                            }
                            {
                                meetingData.password && <div className="containerText">
                                    <span>{i18n.t('meetPassword')}</span><span>：</span>
                                    <span>{meetingData.password}</span>
                                </div>
                            }
                            {
                                meetingData.content && <div className="containerText">
                                    <span>{i18n.t('contentMeeting')}</span><span>：</span>
                                    <span>{meetingData.content}</span>
                                </div>
                            }
                            {
                                meetingData.status !== 6 && <div className="containerText">
                                    <span>{i18n.t('conferenceAddress')}</span><span>：</span>
                                    <span className="address">{`${window.location.origin}/sv/${meetingData.meetNumber}?password=${meetingData.password || ""}`}<img className="icon-copy" src={require("static/images/icon-copy.png")} alt=""
                                        onClick={this.copy.bind(this)} /></span>
                                </div>
                            }
                        </div>}
                        <div className="top">
                            <div dangerouslySetInnerHTML={{ __html: i18n.t("wechatBrowserDesc1", { text: i18n.t(this.platform === "win" ? "left" : "right") }) }}></div>
                            <div className="shadow"><img src={require(this.platform === "win" ? 'static/images/win-browser-icon.png' : "static/images/mac-browser-icon.png")} alt="" /></div>
                        </div>
                        <div className="text" dangerouslySetInnerHTML={{ __html: i18n.t("wechatBrowserDesc2") }}></div>
                        {isInvitePath && <div className="text2">{i18n.t("or")}</div>}
                        {isInvitePath && <div className="text" dangerouslySetInnerHTML={{ __html: i18n.t("wechatBrowserDesc3") }}></div>}
                        {isInvitePath && <div className="qrCode">
                            {
                                this.state.qrUrl && <QRCode
                                    value={this.state.qrUrl}  //value参数为生成二维码的链接
                                    size={111} //二维码的宽高尺寸
                                    fgColor="#000000"  //二维码的颜色
                                />
                            }
                        </div>}
                    </React.Fragment>
                }
            </div>
            <div id="NewsToolBoxAddressGuide1"></div>
        </div>
    }

    // 复制
    public copy() {
        const { meetingData } = this.state
        copyText(`${window.location.origin}/sv/${meetingData.meetNumber}?password=${meetingData.password || ""}`, 'NewsToolBoxAddressGuide1')
        message.success(i18n.t('copyClipboard1'))
    }

    // 提交密码
    public async confirmPassword() {
        if (!this.state.search.password) {
            message.warn(i18n.t("message.pleaseEnterMeetPassword"))
            return
        }
        this.getInviteInfo(this.state.search)
    }

    public async getInviteInfo(obj) {
        const res = await api.meeting.getInviteInfo(obj)
        if (res.code === 200) {
            if (res.data.status === 0) {// 进行中
                res.data.statusText = i18n.t("ongoing");
                res.data.statusBackground = '#31A6FF'
            } else if (res.data.status === 3) {// 未开始
                res.data.statusText = i18n.t("notStarted")
                res.data.statusBackground = '#FFBD0B'
            } else if (res.data.status === 6) { // 已结束
                res.data.statusText = i18n.t("ended")
                res.data.statusBackground = '#B4B4B4'
            } else if (res.data.status === 8) {
                res.data.statusText = i18n.t("canceled")
                res.data.statusBackground = '#B4B4B4'
            }
            res.data.startDate = moment(res.data.startDate).format('YYYY-MM-DD HH:mm')
            if (this.state.showEditPassword && window.location.pathname.indexOf('/sv/') > -1) {
                window.history.replaceState({}, '', window.location.href + obj.password)
            }
            this.setState({ qrUrl: res.data.qrUrl, showEditPassword: false, meetingData: res.data })
        } else {
            if (res.code === 11002) {
                message.error(i18n.t("message.passwordIncorrect"))
            } else {
                message.error(i18n.t("message.FailedGetMeetingInformation"))
            }
        }
    }

    private platform;
}