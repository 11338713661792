import React from "react";
import api from "api";
import i18n from 'i18n';
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import Dropdown from "antd/lib/dropdown";
import Drawer from "antd/lib/drawer";
import { CURRENT_MEETING_DATA, STORAGE_ACCESS_TOKEN, GUIDE_PAGE } from "config";
import styles from "./index.module.css";
import { setNoticeSocketObj } from "reduxs/actions/message"
import { IMeetItem as IItem } from "models/meet"
import { IUser } from "models/user";
import Account from "../account";
import { setUser } from "reduxs/actions/user";
import { LogoutClearStorage } from "config";
import { Introduction, CastScreen, BindPhone } from "components";

interface DispatchProps {
    setNoticeSocketObj: () => void;
    user: IUser;
    setUser: (user: IUser) => void
}
interface IState {
    listData: IItem[];
    showAccountModal: boolean;
    fileDownloadModal: boolean;
    showGuide: number;
    downloadHover: boolean;
    downloadHover2: boolean;
    downloadHover3: boolean;
    isCastScreen: boolean;
    showSetPhoneModal: boolean;
}

class Index extends React.Component<RouteComponentProps & DispatchProps, IState> {
    constructor(props) {
        super(props)
        // this.socketNoticeEventListener = this.socketNoticeEventListener.bind(this)
    }

    public state: IState = {
        listData: [],
        showAccountModal: false,
        fileDownloadModal: false,
        showGuide: 0,
        downloadHover: false,
        downloadHover2: false,
        downloadHover3: false,
        isCastScreen: false,
        showSetPhoneModal: false
    }

    public async componentDidMount() {
        // 引导页
        const guide = localStorage.getItem(GUIDE_PAGE)
        if (guide) {
            if (JSON.parse(guide).indexOf("home") > -1) {
                this.setState({ showGuide: 1 })
            }
        }
        if (!this.props.user.phone && navigator.language.indexOf("CN") > -1) { // 没有绑定手机号，提示强制绑定
            this.setState({ showSetPhoneModal: true })
        }
        // this.socketNoticeMsg()
        // window.onload = () => {
        //     const timer = setTimeout(() => {
        //         this.socketNoticeMsg()
        //         clearTimeout(timer)
        //     }, 1000)
        // }
    }

    // public socketNoticeMsg() {
    //     this.noticeSocket = this.props.noticeSocket;
    //     if (this.noticeSocket && Object.keys(this.noticeSocket).length) {
    //         this.noticeSocket.addEventListener && this.noticeSocket.addEventListener('message', this.socketNoticeEventListener)
    //     }
    // }

    // public socketNoticeEventListener(receive) {
    //     let msg = JSON.parse(receive.data)
    //     if (msg.type === 'ping') return
    //     if (msg.type === "userInfo") {
    //         const token = localStorage.getItem(STORAGE_ACCESS_TOKEN) as any
    //         if (token && token.length > 10) {
    //             this.getUserInfo()
    //         }
    //     }
    // }

    // public async getUserInfo(): Promise<any> {
    //     const res = await api.user.getUserInfo();
    //     if (res.code === 200) {
    //         this.props.setUser(res.data)
    //         if (!res.data.phone && navigator.language.indexOf("CN") > -1) { // 没有绑定手机号，提示强制绑定
    //             this.setState({ showSetPhoneModal: true })
    //         }
    //     }
    // }

    public render() {
        const { showAccountModal } = this.state;
        const home1 = i18n.t('guideImages.home1')
        const home2 = i18n.t('guideImages.home2')
        const home3 = i18n.t('guideImages.home3')
        const home4 = i18n.t('guideImages.home4')
        return <div className={styles.homePage} id="home-page">
            {/* 引导页 */}
            {
                this.state.showGuide === 1 && <img className={styles.guide} src={require(`static/images/guide/${home1}.png`)} alt="" />
            }
            {
                this.state.showGuide === 2 && <img className={styles.guide} src={require(`static/images/guide/${home2}.png`)} alt="" />
            }

            {
                this.state.showGuide === 3 && <img className={styles.guide} src={require(`static/images/guide/${home3}.png`)} alt="" />
            }

            {
                this.state.showGuide === 4 && <img className={styles.guide} src={require(`static/images/guide/${home4}.png`)} alt="" />
            }
            {
                this.state.showGuide !== 0 && this.state.showGuide !== 4 && <div className={styles.skip} style={this.getStyle()?.skip} onClick={this.skip.bind(this)}>{i18n.t("skip")}</div>
            }
            {
                this.state.showGuide !== 0 && this.state.showGuide !== 4 && <div className={styles.nextStep} style={this.getStyle()?.nextStep} onClick={this.nextStep.bind(this)}>{i18n.t('nextStep')}</div>
            }

            {
                this.state.showGuide === 4 && <div className={styles.nextStep} style={this.getStyle()?.nextStep} onClick={this.nextStep.bind(this)}>{i18n.t('IKnow')}</div>

            }

            <div className={styles.bgleft}>
                <img src={require('static/images/home/mobile-logo.png')} alt="" />
            </div>
            {/* <img className={styles.downIcon} src={require("static/images/home/download-icon.png")} alt=""
                onClick={() => this.props.history.push('/history-meeting')} /> */}
            <div className={styles.logoTitle}>
                <div className={styles.des} dangerouslySetInnerHTML={{ __html: i18n.t('slogan') }}></div>
            </div>
            <div className={styles.hiUsername}>
                <span>{i18n.t('hi')}，{this.props.user.name || i18n.t('hello')}</span>
            </div>
            <div className={styles.menuTitle}></div>
            <div className={styles.main}>
                <div className={styles.menuItem} onClick={this.goCreateMeeting.bind(this)}>
                    <img src={require("static/images/home/join-meeting.png")} />
                    <div className={styles.joinBtn}><span>{i18n.t('createSession')}</span></div>
                </div>
                <div className={`${styles.menuItem}`}
                    onClick={this.goJoinMeeting.bind(this)}>
                    <img src={require("static/images/home/create-meeting.png")} />
                    <div className={styles.createBtn}><span>{i18n.t('joinSession')}</span></div>
                </div>
            </div>
            <Drawer
                mask={true}
                placement="right"
                closable={true}
                closeIcon={null}
                visible={showAccountModal}
                getContainer={false}
                className="accountDrawer"
                bodyStyle={{
                    background: '#F6F7FB',
                    borderRadius: '35px 0 0 35px',
                }}
                maskStyle={{ background: "0,0,0,0" }}
                style={{
                    height: "78%", top: '11%',
                }}
                maskClosable={true}
                onClose={() => this.setState({ showAccountModal: false })}
            >
                <Account />
            </Drawer>
            <img src={require("static/images/guide/icon-guide.png")} alt="" style={{ cursor: "pointer", width: '0.33rem', height: '0.33rem', position: "fixed", bottom: '0.2rem', left: '0.2rem', zIndex: 100 }}
                onClick={() => this.setState({ showGuide: 1 })}
            />

            <div className={styles.download}>
                <div onMouseEnter={() => this.setState({ downloadHover3: true })}
                    onMouseLeave={() => this.setState({ downloadHover3: false })}
                    onClick={() => this.setState({ isCastScreen: true })}>
                    <img className={styles.img1} src={this.state.downloadHover3 ? require("static/images/home/icon-touping-a.png") : require("static/images/home/icon-touping.png")}
                    />
                    <span style={{ marginRight: "0.3rem" }}>{i18n.t("castScreen")}</span>
                </div>
                <div onMouseEnter={() => this.setState({ downloadHover2: true })}
                    onMouseLeave={() => this.setState({ downloadHover2: false })}
                    onClick={() => this.goMyMeeting()}>
                    <img className={styles.img1} src={this.state.downloadHover2 ? require("static/images/home/icon-mymeet-a.png") : require("static/images/home/icon-mymeet.png")}
                    />
                    <span style={{ marginRight: "0.3rem" }}>{i18n.t("myMeeting")}</span>
                </div>
                <div onMouseEnter={() => this.setState({ downloadHover: true })}
                    onMouseLeave={() => this.setState({ downloadHover: false })}
                    onClick={() => this.props.history.push("/download")}>
                    <img className={styles.img1} src={this.state.downloadHover ? require("static/images/home/icon-download-a.png") : require("static/images/home/icon-download.png")}
                    />
                    <span style={{ marginRight: "0.3rem" }}>{i18n.t("downloadCenter")}</span>
                </div>
                <Dropdown trigger={["click", "hover"]}
                    overlayStyle={{ paddingBottom: "3%" }}
                    overlay={this.accountRender()} placement="bottomCenter">
                    <img className={styles.avatar} src={this.props.user.avatar || require("static/images/home/icon-avatar.png")} alt="" />
                </Dropdown>
            </div>

            <Introduction style={{ bottom: 10 }} />

            {
                this.state.isCastScreen && <CastScreen close={() => {
                    this.setState({ isCastScreen: false })
                }} />
            }
            {
                this.state.showSetPhoneModal && <BindPhone onCancel={() => {
                    this.setState({ showSetPhoneModal: false })
                }} />
            }
        </div>
    }

    public accountRender() {
        return <div className={styles.accountMenu}>
            <div className={styles.item} onClick={this.goAccount.bind(this)}>{i18n.t("personalCenter")}</div>
            <div className={styles.item} onClick={() => {
                LogoutClearStorage()
                window.location.replace(window.location.origin + window.location.pathname + "#/login")
            }}>{i18n.t("signOut")}</div>
        </div>
    }

    public goAccount() {
        const query = `?User-Agent=${navigator.userAgent}&token=${localStorage.getItem(STORAGE_ACCESS_TOKEN)}`
        //  'http://localhost:3000'
        window.open((window.location.origin) + '/account/center/#/' + query)
    }

    public goMyMeeting() {
        const query = `?User-Agent=${navigator.userAgent}&token=${localStorage.getItem(STORAGE_ACCESS_TOKEN)}`
        window.open((window.location.origin) + '/account/center/#/meeting-record' + query)
    }

    public async joinMeeting(data: IItem) {
        const res = await api.meeting.joinMeet(data.meetId, data.password)
        if (res.code === 200) {
            window.localStorage.setItem(CURRENT_MEETING_DATA, JSON.stringify(res.data))
            if (res.data.waitingRoom) {
                this.props.history.push(`/waiting-room/${data.meetId.toString().substr(4)}`)
            } else {
                //答辩室
                // this.props.history.push(`/reply-meeting/${data.meetId.toString().substr(4)}`);
                // 主会议
                this.props.history.push(`/meet/${data.meetId.toString().substr(4)}`);
            }
        }
    }

    public goCreateMeeting() {
        this.props.history.push("/create-meet")
    }

    public goJoinMeeting() {
        this.props.history.push("/join-meet")
    }

    public componentWillUnmount() {
        // try {
        //     this.noticeSocket.removeEventListener('message', this.socketNoticeEventListener)
        //     this.noticeSocket = null;
        // } catch { }

    }

    // 获取跳过、下一步的定位
    public getStyle() {
        if (this.state.showGuide === 1) {
            return { skip: { left: "54.47%", top: "81.38%" }, nextStep: { left: "59.16%", top: "81.38%" } }
        }
        if (this.state.showGuide === 2) {
            return { skip: { left: "72.96%", top: "67.40%" }, nextStep: { left: "77.60%", top: "67.40%" } }
        }
        if (this.state.showGuide === 3) {
            return { skip: { left: "34.32%", top: "30.27%" }, nextStep: { left: "38.95%", top: "30.27%" } }
        }
        if (this.state.showGuide === 4) {
            return { nextStep: { right: "10.95%", top: "55.27%" } }
        }
    }
    // 跳过
    public skip() {
        const guide = localStorage.getItem(GUIDE_PAGE)
        if (guide) {
            const gui = JSON.parse(guide)
            if (gui.indexOf("home") > -1) {
                gui.splice('home', 1)
                localStorage.setItem(GUIDE_PAGE, JSON.stringify(gui))
            }
        }
        this.setState({ showGuide: 0 })
    }

    // 下一步
    public nextStep() {
        if (this.state.showGuide === 1) {
            this.setState({ showGuide: 2 })
        }
        if (this.state.showGuide === 2) {
            this.setState({ showGuide: 3 })
        }
        if (this.state.showGuide === 3) {
            this.setState({ showGuide: 4 })
        }
        if (this.state.showGuide === 4) {
            this.skip()
        }
    }

    private timer: any = "";
    private noticeSocket;
}

export default connect(
    (state: any) => ({
        noticeSocket: state.noticeSocket,
        user: state.user
    }),
    (dispatch) => {
        return {
            setNoticeSocketObj: (data: any) => dispatch(setNoticeSocketObj(data)),
            setUser: (data: any) => dispatch(setUser(data))
        }
    }
)(Index);