
import { connectionOptions } from "./config";


export default {
    speechServer: null,
    params: {
        locale: '',
        realtime: true,
        speaker: ''
    },
    success: null,
    /**
     * @name 会议字幕/会议录制-连接语音服务器
     */
    speechServerWebSocket(meetId: string, userId: string, success: Function, returnFun: Function) {
        if (this.speechServer) {
            returnFun()
            return
        }
        const url = `wss://${(window as any).$environment === "svmeet" ? "www" : "deve"}.svmeet.com/oversee/ws/meeting/${meetId}/user/sv-user-${userId}?engine=${connectionOptions.serviceUrl}&domain=${connectionOptions.hosts.domain}&muc=${connectionOptions.hosts.muc}&anonymousdomain=${connectionOptions.hosts.anonymousdomain || ""}` //&origin=https://ml.svmeet.com
        //@ts-ignore
        this.speechServer = new ReconnectingWebSocket(url, null, { debug: false, reconnectInterval: 8000 });
        //@ts-ignore
        this.speechServer.onopen = () => {
            console.log("语音服务已连接", window);
            (window as any)['$speechServer'] = this.speechServer
        }
        //@ts-ignore
        this.success = success
        //@ts-ignore
        this.speechServer.addEventListener('message', this.onMessage.bind(this))
        //@ts-ignore
        this.speechServer.onerror = () => {
            console.log(this.speechServer + '连接建立失败');
        }

    },
    onMessage(receive) {
        let data = JSON.parse(receive.data)
        //@ts-ignore
        if (this.success) this.success(data)
    },
    /**
     * 打开或关闭字幕
     */
    openOrCloseSummary(value: boolean, transliterationId?: string, locale?) {
        if (value) { // 打开语音识别
            //@ts-ignore
            this.speechServer.send(JSON.stringify({
                // 信令类型
                type: 'startSpeech',
                // 需要翻译时的识别结果的地域信息，不是必须
                locale: locale,
                realtime: true,
                speaker: this.params.speaker,
                transliterationId
            }));
            this.params.locale = locale;
        } else {// 停止语音识别
            //@ts-ignore
            this.speechServer.send(JSON.stringify({
                type: 'stopSpeech'
            }));
        }

    },
    /**
      * 打开朗读
      */
    openReadingAloud(value: boolean, data) {
        if (value) {
            //@ts-ignore
            this.speechServer.send(JSON.stringify({
                type: 'startSpeech',
                locale: this.params.locale,
                realtime: this.params.realtime,
                speaker: data
            }));
            this.params.speaker = data;
        } else {
            //@ts-ignore
            this.speechServer.send(JSON.stringify({
                type: 'startSpeech',
                locale: this.params.locale,
                realtime: this.params.realtime,
            }));
        }

    },

    /**
    * 打开或关闭录制
     */
    openOrCloseRecord(value: boolean, recordingMethod: 1 | 2, meetServiceId?: Number | null) {
        if (value) { // 打开录制
            //@ts-ignore
            this.speechServer.send(JSON.stringify({
                type: recordingMethod === 1 ? 'startVideoRecord' : 'startAudioRecord',
                timeLimit: meetServiceId === null ? 600000 : 0
            }));
        } else {// 停止录制
            //@ts-ignore
            this.speechServer.send(JSON.stringify({
                type: recordingMethod === 1 ? 'stopVideoRecord' : "stopAudioRecord"
            }));
        }
    },

    close() {
        //@ts-ignore
        this.speechServer && this.speechServer.close()
        this.speechServer = null
    }

}
