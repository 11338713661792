

import { request } from "../utils/request";
import { API_URL } from "config";

/**
 * @name 获取用户所属企业(客户)
 */
export async function getClientList() {
    const response: any = (await request.get(
        `${API_URL}/guest/belonging`)).data;

    return response;
}
