import i18n from "i18n"

// 计时器
export function getTimer(time) {
    const ss = 1000;
    const mi = ss * 60;
    const hh = mi * 60;
    const dd = hh * 24;

    const day = Math.floor(time / dd);
    let hours: any = Math.floor((time - day * dd) / hh);
    let mins: any = Math.floor((time - day * dd - hours * hh) / mi);
    let second: any = Math.floor((time - day * dd - hours * hh - mins * mi) / ss);
    hours = hours < 10 ? "0" + hours : hours;
    mins = mins < 10 ? "0" + mins : mins;
    second = second < 10 ? "0" + second : second;
    return [day, hours, mins, second]
}

// 拷贝
export function copyText(text, toolBoxElement?) {
    let textarea = document.createElement("textarea"); //创建input对象
    let currentFocus = document.activeElement; //当前获得焦点的元素
    let toolBoxwrap = document.getElementById(toolBoxElement || 'NewsToolBox'); //将文本框插入到NewsToolBox这个之后
    (toolBoxwrap as any).appendChild(textarea); //添加元素
    textarea.value = text;
    textarea.focus();
    if (textarea.setSelectionRange) {
        textarea.setSelectionRange(0, textarea.value.length); //获取光标起始位置到结束位置
    } else {
        textarea.select();
    }
    let flag;
    try {
        flag = document.execCommand("copy"); //执行复制
    } catch (eo) {
        flag = false;
    }
    (toolBoxwrap as any).removeChild(textarea); //删除元素
    (currentFocus as any).focus();
    return flag;
}

export function userApplyForJoinMeetNotification(id: string, username: string, onOk: Function, onCancel: Function) {
    const box = document.createElement('div')
    box.className = 'apply_for_join_notification';
    box.id = 'apply_for_join_notification_' + id;
    box.innerHTML = `
         <img class='icon-user1' src="${require("static/images/meet/icon-user1.png")}"/>
         <span class='username'>${username}正在申请加入会议</span>
        <div class='btn' id='allow-btn-${id}'><img src="${require("static/images/meet/icon-allow.png")}"/>允许</div>
        <div class='btn' id='refuse-btn-${id}'><img src="${require("static/images/meet/icon-refuse.png")}"/>拒绝</div>
    `;
    document.body.appendChild(box)

    const allowEle = document.getElementById('allow-btn-' + id)
    const refuseEle = document.getElementById('refuse-btn-' + id)
    console.log(allowEle)
    if (allowEle) {
        allowEle.onclick = function () {
            console.log(id)
            onOk(id)
            const hh = document.getElementById('apply_for_join_notification_' + id);
            hh?.parentNode?.removeChild(hh)
        }
    }
    if (refuseEle) {
        refuseEle.onclick = function () {
            onCancel(id)
        }
    }

}

/**
 * 防抖
 */

export function debounce(fn, wait) {
    let timeout: any = null;
    return () => {
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(fn, wait);
    }
}


// 随机数
export function GetRandomNum(Min, Max) {
    var Range = Max - Min;
    var Rand = Math.random();
    return (Min + Math.round(Rand * Range));
}


// 秒转换为时分秒
export function formatSeconds(value, type: 1 | 2 | 3) {
    let result = parseInt(value)
    let h = Math.floor(result / 3600) < 10 ? Math.floor(result / 3600) : Math.floor(result / 3600);
    let m = Math.floor((result / 60 % 60)) < 10 ? Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
    let s = Math.floor((result % 60)) < 10 ? Math.floor((result % 60)) : Math.floor((result % 60));
    let res = '';
    if (h !== 0) res += `${h}${i18n.t("date.hours")}`;
    if (m !== 0) res += `${m}${i18n.t("date.minutes")}`;
    if (type === 3) {
        res += `${s}${i18n.t("date.minutes")}`;
    }
    return res;
}

// 添加水印
export function watermark(element, text) {
    //默认设置
    var defaultSettings = {
        watermarl_element: element,
        watermark_txt: text,
        watermark_x: 20,//水印起始位置x轴坐标
        watermark_y: 20,//水印起始位置Y轴坐标
        watermark_rows: 2000,//水印行数
        watermark_cols: 2000,//水印列数
        watermark_x_space: 70,//水印x轴间隔
        watermark_y_space: 30,//水印y轴间隔
        watermark_color: '#aaa',//水印字体颜色
        watermark_alpha: 0.2,//水印透明度
        watermark_fontsize: '0.48rem',//水印字体大小
        watermark_font: 'Alibaba PuHuiTi',//水印字体
        watermark_width: 477,//水印宽度
        watermark_height: 306,//水印长度
        watermark_angle: 25//水印倾斜度数
    };
    //采用配置项替换默认值，作用类似jquery.extend
    if (arguments.length === 1 && typeof arguments[0] === "object") {
        const src = arguments[0] || {};
        for (const key in src) {
            if (src[key] && defaultSettings[key] && src[key] === defaultSettings[key])
                continue;
            else if (src[key])
                defaultSettings[key] = src[key];
        }
    }

    var oTemp = document.createDocumentFragment();


    var maskElement = element || document.body;

    //获取页面最大宽度
    var page_width = Math.max(maskElement.scrollWidth, maskElement.clientWidth);

    //获取页面最大高度
    var page_height = Math.max(maskElement.scrollHeight, maskElement.clientHeight);

    //水印数量自适应元素区域尺寸
    defaultSettings.watermark_cols = Math.ceil(page_width / (defaultSettings.watermark_x_space + defaultSettings.watermark_width));
    defaultSettings.watermark_rows = Math.ceil(page_height / (defaultSettings.watermark_y_space + defaultSettings.watermark_height));
    var x;
    var y;
    for (var i = 0; i < defaultSettings.watermark_rows; i++) {
        y = defaultSettings.watermark_y + (defaultSettings.watermark_y_space + defaultSettings.watermark_height) * i;
        for (var j = 0; j < defaultSettings.watermark_cols; j++) {
            x = defaultSettings.watermark_x + (defaultSettings.watermark_width + defaultSettings.watermark_x_space) * j;
            var mask_div = document.createElement('div');
            mask_div.id = 'mask_div' + i + j;
            mask_div.className = 'mask_div';
            //mask_div.appendChild(document.createTextNode(defaultSettings.watermark_txt));
            mask_div.innerHTML = (defaultSettings.watermark_txt);
            //设置水印div倾斜显示
            mask_div.style.webkitTransform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
            // mask_div.style.MozTransform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
            // mask_div.style.msTransform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
            // mask_div.style.OTransform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
            mask_div.style.transform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
            mask_div.style.visibility = "";
            mask_div.style.position = "absolute";
            mask_div.style.left = x + 'px';
            mask_div.style.top = y + 'px';
            mask_div.style.overflow = "hidden";
            mask_div.style.zIndex = "9999";
            mask_div.style.pointerEvents = 'none';//pointer-events:none  让水印不遮挡页面的点击事件
            //mask_div.style.border="solid #eee 1px";　　　　　　　　　　//兼容IE9以下的透明度设置                mask_div.style.filter="alpha(opacity=50)";
            mask_div.style.opacity = defaultSettings.watermark_alpha as any;
            mask_div.style.fontSize = defaultSettings.watermark_fontsize;
            mask_div.style.fontFamily = defaultSettings.watermark_font;
            mask_div.style.color = defaultSettings.watermark_color;
            mask_div.style.fontWeight = '500';
            mask_div.style.textAlign = "center";
            mask_div.style.width = defaultSettings.watermark_width / 100 + '.rem';
            mask_div.style.height = defaultSettings.watermark_height / 100 + '.rem';
            mask_div.style.display = "block";
            oTemp.appendChild(mask_div);
        };
    };
    maskElement.appendChild(oTemp);
}

/**
 * @description 去重
 * @returns  
 */
export function uniqueFunc(arr, uniId) {
    const res = new Map();
    return arr.filter((item) => !res.has(item[uniId]) && res.set(item[uniId], 1));
}

/**
 * 判断对象是否相等
 */
export function isObjectValueEqual(a, b) {
    if (a === null || b === null) {
        return a === b ? true : false
    }
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);
    if (aProps.length != bProps.length) {
        return false;
    }
    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i]
        var propA = a[propName]
        var propB = b[propName]
        if ((typeof (propA) === 'object') && propA !== null && propB !== null) {
            if (isObjectValueEqual(propA, propB)) {
            } else {
                return false
            }
        } else if (propA !== propB) {
            return false
        } else { }
    }
    return true
}