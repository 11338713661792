import { request } from "utils/request";
import { API_URL } from "config";

/**
 * @name 用户获取站内消息
 */
export async function getStationNews(query: {
    pageNumber: number,
    pageSize: number,
    title: string;
    msg: string;
}) {
    const params = [
        { name: "title", value: query.title, rule: 1 },
        { name: "msg", value: query.msg, rule: 1 },
    ].filter(x => !!x.value)
    return (await request.get(`${API_URL}/notice/stationNews/getAll`, {
        params: {
            pageNumber: query.pageNumber,
            pageSize: query.pageSize,
            search: params.length ? encodeURIComponent(JSON.stringify(params)) : null
        }
    })).data;
}


/**
 * @name 删除站内消息 
 */

export async function deleteStationNews(primaryKey: string) {
    return (await request.delete(`${API_URL}/notice/stationNews/del`, {
        params: {
            primaryKey
        }
    })).data;
}