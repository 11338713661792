
import React from "react";
import i18n from "i18n";
import { ISummaryMessageItem } from "models/common";
import summary from "utils/lib-jitsi-meet/summary";
import videolayout from "utils/lib-jitsi-meet/videolayout"
import { IParticipantItem } from "models/meet";
import { Checkbox, Button } from "antd";
import "./index.css"
export default class extends React.Component<{
    summaryMessage: ISummaryMessageItem[];
    participants: IParticipantItem[];
}, {
    up: boolean;
    horn: boolean;
    checkedList: string[]
}> {

    constructor(props) {
        super(props)

        this.state = {
            up: false,
            horn: false,
            checkedList: []
        }
    }

    public scrolling = false;

    public componentDidMount() {
        const chat_contain = document.getElementById('summary_component');
        if (chat_contain) {
            let t1 = 0;
            let t2 = 0;
            let timer;
            chat_contain.addEventListener('scroll', () => {
                this.scrolling = true
                clearTimeout(timer)
                timer = setTimeout(() => {
                    t2 = chat_contain.scrollTop;
                    if (t2 == t1) {
                        this.scrolling = false
                        clearTimeout(timer)
                    }
                }, 800)
                t1 = chat_contain.scrollTop
            })
        }
    }


    public windowObj: any = null;

    public modal() {
        // menubar菜单location地址栏resizable调整大小scrollbars滚动条status状态栏
        const res = window.open("/#/summary", "_black", "width=692,height=450,menubar=no,location=no,resizable=no,scrollbars=no,status=no,directories=no")
        const that = this;
        let num = 0;
        this.windowObj = res;
        res?.addEventListener("unload", function () {
            if (num === 1) {
                that.setState({ up: false })
                num = 0
            }
            num += 1
        })
        window['$summaryMessage'] = this.props.summaryMessage;
        window['$participants'] = this.props.participants || [];
    }

    public shouldComponentUpdate() {
        window['$participants'] = this.props.participants || [];
        return true
    }

    // 关闭window
    public closeWindow() {
        this.windowObj && this.windowObj.close()
    }

    public componentDidUpdate() {
        if (this.props.summaryMessage.length > 2) {
            this.scrollTop()
        }
    }

    public scrollTop() {
        const ele = document.getElementById("summary_component");
        if (!this.scrolling) {
            if (ele) {
                ele.scrollTop = ele.scrollHeight;
            }
        }
    }

    public render() {
        const lineNum = 3
        return <div className="summary_component" style={{ display: this.state.up ? "none" : "block" }} >
            {
                !this.state.up && <img className="icon-up" src={require("static/images/meet/icon-up.png")} onClick={() => {
                    this.modal()
                    this.setState({ up: true })
                }} />
            }

            {/* {
                this.state.horn ?
                    <Dropdown trigger={["hover"]} overlay={<Menu>
                        {this.languageRender()}
                    </Menu>} placement="bottomCenter" arrow>
                        <img className="icon-up" style={{ right: "0.67rem" }} onClick={() => this.hornClose()}
                            src={this.state.horn ? require("static/images/meet/icon-laba.png") : require("static/images/meet/icon-laba-close.png")} alt=""
                        />
                    </Dropdown>
                    : <Dropdown trigger={["click", "hover"]} overlay={<Menu>
                        {this.languageRender()}
                    </Menu>} placement="bottomCenter" arrow>
                        <img className="icon-up" style={{ right: "0.67rem" }}
                            src={this.state.horn ? require("static/images/meet/icon-laba.png") : require("static/images/meet/icon-laba-close.png")} alt=""
                        />
                    </Dropdown>
            } */}

            <div className="list" id="summary_component" style={{ maxHeight: this.props.summaryMessage ? "1.46rem" : "" }}
            >
                {
                    this.props.summaryMessage.slice(this.props.summaryMessage.length > 3 ? this.props.summaryMessage.length - lineNum : 0).map((ele, index) => {
                        return <div key={ele.id + index} className="msg_item"
                            style={{
                                // fontSize: this.props.summaryMessage.length >= lineNum ? 17 - (2 - index) : 17,
                                fontSize: 15
                                // color: this.props.summaryMessage.length >= lineNum ? (index === (lineNum - 1) ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.7)') : ""
                            }}
                        >
                            <div className="parent">
                                <div style={{
                                    display: "flex",
                                    opacity: this.props.summaryMessage.length >= lineNum ? index === 0 ? "0.3" : index === 1 ? "0.6" : "" : ""
                                }}>
                                    <span className="userName">{ele.displayName}</span>：
                                    <span style={{ color: !ele.final ? "rgba(255, 177, 0, 1)" : (this.props.summaryMessage.length >= lineNum ? (index === (lineNum - 1) ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.7)') : "") }}
                                        dangerouslySetInnerHTML={{ __html: ele.message }}></span></div>
                                {/* <div style={{ fontSize: '0.13rem', color: !ele.final ? "rgba(255, 177, 0, 0.7)" : 'rgba(255,255,255,0.7)' }}>
                                    {ele.tr && <div className="userName"></div>}
                                    {ele.tr}
                                </div> */}
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    }


    public languageRender() {
        return <div className='summay-languageRender'>
            {
                this.props.participants.map(ele => {
                    if (!ele.local) {
                        return <div key={ele.userId} onChange={() => {
                            const index = this.state.checkedList.findIndex(x => x === ele.businessUserId)
                            if (index !== -1) {
                                this.state.checkedList.splice(index, 1)
                            } else {
                                this.state.checkedList.push(ele.businessUserId)
                            }
                            this.setState({ checkedList: this.state.checkedList })
                        }}>
                            <Checkbox checked={this.state.checkedList.indexOf(ele.businessUserId) > -1 ? true : false} style={{ width: "100%" }}>{ele.realName || ele._displayName}</Checkbox>
                        </div>
                    }
                })
            }
            <div style={{ textAlign: "center" }}>
                <Button onClick={() => this.horn()}>{i18n.t("meet.determine")}</Button>
            </div>
        </div>
    }

    public horn() { // 文本朗读
        if (!this.state.checkedList.length) {
            this.hornClose()
            return
        }
        const data = {}
        this.setState({ horn: true }, () => {
            this.state.checkedList.forEach(element => {
                const target = this.props.participants.filter(x => x.businessUserId === element)
                if (target.length) {
                    videolayout.pauseOrPlayUserAuido(!this.state.horn, target[0].participantId || "")
                }
            });
            if (this.state.horn) {
                this.state.checkedList.forEach(x => {
                    data[x] = "male"
                })
            }
            summary.openReadingAloud(this.state.horn, data)
            if (!this.state.horn) {
                this.setState({ checkedList: [] })
            }
        })
    }

    public hornClose() {
        this.setState({ horn: false })
        summary.openReadingAloud(this.state.horn, "")
        this.setState({ checkedList: [] })
    }

}