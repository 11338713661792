import React from "react";
import i18n from "i18n";
import { connect } from "react-redux";
import { LIVE_URL_PUSH, LIVE_URL_PULL, LIVE_CODEC, STORAGE_ACCESS_TOKEN, getWebSocketUrl, CURRENT_MEETING_DATA } from "config";
import { message, Tooltip, Modal } from "antd";
// import WpsSdk from "utils/wps"
import SrsSdk from "utils/srs"
import { IMyPermission } from "models/meet";
import meetWebsocket from "utils/lib-jitsi-meet/websocket";
import { IUser } from "models/user";
import { logger } from "utils/log";
import { setMyInfo } from "reduxs/actions/meet"
import mqtt from "mqtt"
import "./index.less";
import { GetRandomNum } from "utils/data";
import api from "api";
interface DispatchProps {
    user: IUser;
}
interface IState {
    isRaiseHand: boolean;
    liveStarted: boolean;
    isFullScreen: boolean;
    myPermission: IMyPermission;
    pause: boolean;
    isMute: boolean;
    loading: boolean;
    liveStatus: number;
    stopLive: boolean;
    meetingProgress: boolean;
}
class Index extends React.Component<any & DispatchProps, IState> {
    constructor(props) {
        super(props)
        this.messageListener = this.messageListener.bind(this)
    }
    public state: IState = {
        isRaiseHand: false,
        liveStarted: false,
        isFullScreen: false,
        myPermission: {
            handRaisedState: 0,
            speak: 0,
            screenSharing: 0,
            whiteboard: 0,
        },
        pause: false,
        isMute: false,
        loading: true,
        liveStatus: 0,
        stopLive: false,
        meetingProgress: false
    }
    public async componentDidMount() {
        // 从近场到远场的
        if ((window as any).websocketLive) {
            this.meetingSocket = (window as any).websocketLive;
            this.meetingSocket.addEventListener('message', this.messageListener)
            this.setState({ myPermission: this.props.myInfo })
        } else {// 直接进入远场的
            let meetingData = localStorage.getItem(CURRENT_MEETING_DATA) as any
            if (meetingData) {
                meetingData = JSON.parse(meetingData)
                if (meetingData.status === 0) {
                    this.meetingBegun()
                } else {
                    this.setState({ meetingProgress: false, loading: false }, () => this.mqtt())
                }
            }
        }
        const timer = setTimeout(() => {
            clearTimeout(timer)
            if (this.state.loading) {
                this.setState({ loading: false })
            }
        }, 5000)

        if (window.addEventListener) {
            window.addEventListener("unload", () => {
                this.unload()
            })
            window.addEventListener("beforeunload", () => {
                this.unload()
            })
        } else {
            (window as any).attachEvent('onbeforeunload', () => {
                this.unload()
            })
        }
    }

    public mqtt() {
        const user = this.props.user
        const options = {
            clean: true, // true: 清除会话, false: 保留会话
            connectTimeout: 4000, // 超时时间
            // 认证信息
            clientId: user.primaryKey + GetRandomNum(100000, 999999),
            username: user.primaryKey,
            password: localStorage.getItem(STORAGE_ACCESS_TOKEN) || "",
            keepalive: 20
        }
        const connectUrl = `${getWebSocketUrl()}/mqtt`
        const client = mqtt.connect(connectUrl, options)
        client.on('connect', () => {
            this.mqttClient = client
        })
        client.on('message', (_topic, message) => {
            const msg = JSON.parse(message.toString())
            if (msg.type === "meetingStatus") { // 会议已开始
                let meetingData = localStorage.getItem(CURRENT_MEETING_DATA) as any
                if (meetingData) {
                    meetingData = { ...JSON.parse(meetingData), status: msg.data }
                    localStorage.setItem(CURRENT_MEETING_DATA, JSON.stringify(meetingData))
                }
                if (msg.data === 0) { // 会议进行中
                    this.meetingBegun()
                } else {
                    this.setState({ meetingProgress: false })
                }
            }
        })
        client.subscribe(`meeting/status/notice/${this.props.match.params.meetKey}`)
    }

    // 会议已开始，连接wss
    public meetingBegun() {
        this.setState({ meetingProgress: true })
        meetWebsocket.meetingServerWebSocket({ primaryKey: this.props.match.params.meetKey },
            "",
            (meetingSocket) => { // 创建成功/失败
                this.meetingSocket = meetingSocket;
                (window as any).websocketLive = meetingSocket
            }, this.onMessageMeetWebsocket.bind(this))
    }

    public pullStream() {
        this.statusNum += 1
        if (this.statusNum === 10) { // 直播一直没返回状态
            this.statusNum = 0
            return
        }
        if (this.state.liveStatus !== 2) {
            const timer = setTimeout(() => {
                clearTimeout(timer)
                this.pullStream()
            }, 500)
            return
        } else {
            this.statusNum = 0
        }
        if (this.srs) {
            // const videoRemote = document.getElementById('liveMeetingVideo') as any;
            // videoRemote.srcObject = this.streams
            // videoRemote.play().then(() => {
            //     console.log("播放成功")
            // }, e => {
            //     console.log('播放失败', e)
            // });
        } else {
            // 创建srs实例
            // this.srs = new WpsSdk(
            //     LIVE_URL,
            //     async () => {
            //         // 服务已连接
            //         logger.warn('WpsSdk服务已连接。');
            //         // 推送和拉取接口地址
            //         this.pull()
            //     },
            //     () => {
            //         // 服务关闭
            //         logger.warn('WpsSdk服务已断开。');
            //     }
            // );
            // 编码
            const videoRemote = document.getElementById('liveMeetingVideo') as any;
            // 创建srs实例
            this.srs = new SrsSdk(LIVE_URL_PUSH, LIVE_URL_PULL);
            // 拉流
            try {
                let audioReady = true
                this.srs.pull({
                    id: `/meeting/${this.props.match.params.meetKey}`,
                    codec: LIVE_CODEC
                }, [], (evt) => {
                    // 远程流加入
                    logger.info('on track streams:', evt.streams);
                    if (!audioReady) return
                    videoRemote.srcObject = evt.streams[0];
                    audioReady = false
                    videoRemote.play().then(() => {
                        console.log("播放成功")
                    }, e => { // code:0表示浏览器禁止自动播放
                        console.log('播放失败', e, e.code)
                        if (e.code !== 0 || this.allowAutoplayModal) return
                        this.allowAutoplayModal = Modal.confirm({
                            title: null,
                            icon: null,
                            className: "cast-modal",
                            content: <div className="cast-modal-content" >
                                <div>{i18n.t("dialog.allowAutoplayMsg")}</div>
                                <div id="allowAutoplayBtn" className="btns">
                                    <div className="btn2" onClick={() => {
                                        this.allowAutoplayModal.destroy()
                                        videoRemote.play()
                                    }}>{i18n.t("login.confirm")}</div>
                                </div>
                            </div>,
                        })
                    });
                });
            } catch (err) {
                logger.error('pull stream error', err);
                message.error(err.message);
            }
        }
    }

    public async pull() {
        const videoRemote = document.getElementById('liveMeetingVideo') as any;
        // 视频加载完成后播放
        videoRemote.addEventListener('load', () => {
            videoRemote.play();
        });
        try {
            const cmd = await this.srs.startPull({
                conf: this.props.match.params.meetKey,
                name: this.props.user.primaryKey,
                net: 'wifi'
            }, (streams) => {
                // 远程流加入
                console.debug('服务端流接入:', streams);
                if (!videoRemote.srcObject) {
                    videoRemote.srcObject = streams[0];
                    this.streams = streams[0];
                }
            });
            logger.info('⬇️\t发送pull请求：', JSON.stringify(cmd, null, 2));
        } catch (err) {
            message.error(i18n.t("message.requestFailed"));
            logger.error('⬇️🔥\t发送pull请求异常：', err.message);
        }
    }

    public messageListener(receive) {
        let msg = JSON.parse(receive.data)
        if (msg.type === 'ping') return
        this.onMessageMeetWebsocket(msg)
    }

    // 会议控制
    public onMessageMeetWebsocket(msg) {
        if (window.location.hash.indexOf("/live-meeting") === -1) return
        const data = msg.data
        switch (msg.type) {
            // 服务可用
            case 'myInfo':
                this.setState({ isRaiseHand: data.handRaisedState === 1 ? true : false })
                if (this.state.myPermission.speak !== data.speak) {
                    if (data.speak === 0) {//允许发言
                        message.success({
                            content: i18n.t('allowSpeakText'),
                            icon: <span></span>,
                            style: {
                                marginTop: '30vh',
                                color: "rgba(61, 230, 47, 1)"
                            },
                            duration: 5,
                            className: "meet-custom-message"
                        })
                        this.joinMeeting()
                    }
                }
                this.setState({ myPermission: data || this.state.myPermission }, () => {
                    this.props.setMyInfo(this.state.myPermission)
                })
                break;
            case "refuseToSpeak": //主持人拒绝了
                message.success({
                    content: i18n.t('refusedSpeakText'),
                    icon: <span></span>,
                    style: {
                        marginTop: '30vh',
                    },
                    duration: 5,
                    className: "meet-custom-message",
                    key: "refuseToSpeakMessage"
                })
                break;
            case "liveAddress":
                this.setState({ loading: false })
                if (data) { // 直播中
                    this.setState({ liveStarted: true, liveStatus: 2 }, () => {
                        const timer = setTimeout(() => {
                            this.pullStream()
                            clearTimeout(timer)
                        }, 2000)
                    })
                }
                break;
            case "toggleNearField": // 切换到近场
                if (this.state.isRaiseHand) {// 主动举手
                    this.joinMeeting()
                } else {// 被邀请
                    if (this.invitationToSpeakModal) return
                    this.invitationToSpeakModal = Modal.confirm({
                        title: null,
                        icon: null,
                        className: "cast-modal",
                        content: <div className="cast-modal-content" >
                            <div>{i18n.t("invitesYouToSpeak")}</div>
                            <div className="btns">
                                <div className="btn1" onClick={() => {
                                    this.invitationToSpeakModal.destroy()
                                    this.invitationToSpeakModal = null
                                }}>{i18n.t("cancel")}</div>
                                <div className="btn2" onClick={() => {
                                    this.invitationToSpeakModal.destroy()
                                    this.joinMeeting()
                                }}>{i18n.t("meet.determine")}</div>
                            </div>
                        </div>
                    })
                }
                break;
            case "stopLive"://直播停止
                this.setState({ stopLive: true, liveStarted: false })
                this.srs = null
                break;
            case "endMeeting":// 会议结束
                const res = Modal.confirm({
                    title: null,
                    icon: null,
                    className: "cast-modal",
                    content: <div className="cast-modal-content" >
                        <div>{i18n.t("meet.meetingHasEnded")}</div>
                        <div className="btns">
                            <div className="btn1" onClick={() => {
                                this.invitationToSpeakModal && this.invitationToSpeakModal.destroy()
                                this.exit()
                                res.destroy()
                            }}>{i18n.t("IKnow")}</div>
                        </div>
                    </div>,
                })
                // this.setState({ meetingProgress: false })
                break;
            default:
                break;
        }
    }

    // 关闭推流/拉流
    public stopStream() {
        try {
            const videoRemote = document.getElementById('liveMeetingVideo') as any;
            // this.srs.stop();
            videoRemote.pause();
            videoRemote.srcObject = null;
        } catch (err) {
            console.error('stop pull error', err);
            // message.error(err.message);
        }
    }

    public render() {
        const { isRaiseHand, liveStarted, pause, loading, stopLive, meetingProgress } = this.state;
        return <div className="live-meeting-page">
            {
                liveStarted ? <video src="" id="liveMeetingVideo" controls={false} autoPlay style={{ width: "100%", height: "100%" }}></video> :
                    <div className="noStartText">{loading ? i18n.t("loading") : i18n.t(!meetingProgress ? "dialog.meetingNotBegun" : (stopLive ? "livePaused" : "liveNotStarted"))}</div>
            }
            <div className="footer">
                <div>
                    {
                        liveStarted && <img className="pause" src={require(pause ? "static/images/icon-live-start.png" : "static/images/icon-live-pause.png")} alt="" onClick={this.pauseHandle.bind(this)} />
                    }
                </div>
                <div className="right">
                    <Tooltip title={!isRaiseHand ? i18n.t("raiseHands") : i18n.t("cancelRaiseHands")}>
                        <img className="hand" src={require(!isRaiseHand ? "static/images/meet/icon-hand.png" : "static/images/meet/icon-hand-a.png")} alt="" onClick={this.raiseHand.bind(this)} />
                    </Tooltip>
                    <Tooltip title={this.state.isFullScreen ? i18n.t('meet.exitFullScreen') : i18n.t('meet.openFullScreen')}>
                        <img src={require('static/images/meet/icon-quanping.png')} alt="" className='fullScreen' onClick={() => {
                            this.setState({ isFullScreen: !this.state.isFullScreen }, async () => {
                                this.isFullscreen(this.state.isFullScreen)
                            })
                        }} />
                    </Tooltip>
                    <Tooltip title={this.state.isMute ? i18n.t('eliminateMute') : i18n.t('meet.mute')}>
                        <img src={require(this.state.isMute ? 'static/images/icon-live-muted.png' : "static/images/icon-live-mute.png")} alt="" className='fullScreen'
                            onClick={this.muted.bind(this)} />
                    </Tooltip>
                    <Tooltip title={i18n.t("exitLive")}>
                        <img className="exit" src={require("static/images/icon-exit.png")} alt="" onClick={this.exit.bind(this)} />
                    </Tooltip>
                </div>
            </div>
            {/* {
                liveStarted && <div className="useNum">
                    <img src={require("static/images/meet/icon-user.png")} alt="" />
                    <span>20</span>
                </div>} */}
        </div>
    }

    // 暂停/开始
    public pauseHandle() {
        this.setState({ pause: !this.state.pause }, () => {
            const videoRemote = document.getElementById('liveMeetingVideo') as any;
            if (this.state.pause) {
                if (!videoRemote.paused) {
                    videoRemote.pause()
                }
            } else {
                if (videoRemote.paused) {
                    videoRemote.play()
                }
            }
        })
    }

    // 静音
    public muted() {
        this.setState({ isMute: !this.state.isMute }, async () => {
            const videoRemote = document.getElementById('liveMeetingVideo') as any;
            if (this.state.isMute) {
                videoRemote.muted = true
            } else {
                videoRemote.muted = false
            }
        })
    }

    // 举手
    public raiseHand() {
        try {
            this.meetingSocket && this.meetingSocket.send(JSON.stringify({
                type: "handRaisedState",
                data: !this.state.isRaiseHand ? 1 : 0
            }))
        } catch (e) { }
        this.setState({ isRaiseHand: !this.state.isRaiseHand })
    }

    // 退出
    public exit() {
        this.meetingSocket && this.meetingSocket.close();
        (window as any).websocketLive = null
        this.props.history.push("/home")
    }

    // 全屏
    public isFullscreen(bool) {
        const docElm = document.documentElement as any;
        if (bool) {
            //W3C
            if (docElm.requestFullscreen) {
                docElm.requestFullscreen();
            }
            //FireFox
            else if (docElm.mozRequestFullScreen) {
                docElm.mozRequestFullScreen();
            }
            //Chrome等
            else if (docElm.webkitRequestFullScreen) {
                docElm.webkitRequestFullScreen();
            }
            //IE11
            else if (docElm.msRequestFullscreen) {
                (document.body as any).msRequestFullscreen();
            }
        } else {
            if ((document as any).hasOwnProperty('exitFullscreen') && document.exitFullscreen) {
                document.exitFullscreen();
            }
            else if ((document as any).msExitFullscreen) {
                (document as any).msExitFullscreen();
            }
            else if ((document as any).mozCancelFullScreen) {
                (document as any).mozCancelFullScreen();
            }
            else if ((document as any).webkitCancelFullScreen) {
                (document as any).webkitCancelFullScreen();
            } else {
                (window.parent as any).showTopBottom();
            }
        }

    }

    // 进入近场
    public async joinMeeting() {
        this.props.history.push(`/meet/${this.props.match.params.meetNumber}`);
    }

    // 监听按esc退出全屏
    public eventFullscreenchange() {
        document.addEventListener('fullscreenchange', this.Fn.bind(this));
        document.addEventListener('webkitfullscreenchange', this.Fn.bind(this));
        document.addEventListener('mozfullscreenchange', this.Fn.bind(this));
        document.addEventListener('msfullscreenchange', this.Fn.bind(this));

    }
    public Fn() {
        const fullscreenElement = document.fullscreenElement || (document as any).webkitFullscreenElement || (document as any).mozFullScreenElement || (document as any).webkitIsFullScreen || (document as any).mozFullScreen;
        //全屏按钮切换类
        if (fullscreenElement) {
            // this.setState({ isFullScreen: true })
            //目前全屏
        } else {
            this.setState({ isFullScreen: false })
            //目前不是全屏
        }
    }

    public unload() {
        if (this.state.liveStarted) {
            this.stopStream()
            this.srs && this.srs.stop()
            this.srs = null
            this.streams = null
        }
    }

    public componentWillUnmount() {
        try { this.mqttClient && this.mqttClient.end() } catch { }
        this.meetingSocket && this.meetingSocket.removeEventListener('message', this.messageListener)
        this.unload()
    }

    private srs;
    private meetingSocket;
    private invitationToSpeakModal;
    private statusNum = 0;
    private streams;
    private allowAutoplayModal;
    private mqttClient;
}

export default connect(
    (state: any) => ({
        user: state.user,
        myInfo: state.myInfo
    }),
    (dispatch) => {
        return {
            setMyInfo: (data: boolean) => dispatch(setMyInfo(data))
        }
    }
)(Index);