
import React from "react";
import { getPlatformValue } from "utils/common";
import i18n from "i18n";
import "./index.less"
import api from "api";
import { Input, message } from "antd";
import { IMeetingItem } from "models/meet"
import moment from "moment";
import { copyText } from "utils/data";

interface IState {
    showEditPassword: Boolean,
    meetingData: IMeetingItem;
    loading: boolean;
    search: {
        meetNumber: string,
        password: string,
    };
}

export default class extends React.Component<{
    close: () => void
}> {
    public state: IState = {
        showEditPassword: false,
        meetingData: {
            primaryKey: "",
            content: "",
            meetNumber: "",
            password: "",
            startDate: "",
            status: 0,
            theme: "",
        },
        loading: true,
        search: {
            meetNumber: "",
            password: "",
        }
    }
    public async componentDidMount() {
        // 获取平台
        this.platform = getPlatformValue()
        const str = window.location.hash.slice(0, window.location.hash.lastIndexOf("/") + 1)
        const path = window.location.hash.replace(str, '')
        this.path = path
        console.log(`svmeet://sv/${path}`)
        const arr = path.split("?")
        console.log(arr)
        if (arr.length < 2) {
            this.props.close()
            return
        }
        const obj = {}
        obj['meetNumber'] = arr[0]
        const search = arr[1].split("&")
        search.forEach(element => {
            const arr = element.split("=")
            obj[arr[0]] = arr[1]
        });
        console.log(obj)
        if (obj['password']) {
            await this.getInviteInfo(obj)
        } else {
            this.setState({ showEditPassword: true })
        }
        this.setState({ search: obj })
        const env = (window as any).$environment === "ml" ? "M" : ((window as any).$environment === "deve" ? "D" : "R")
        const res = await api.common.getVersion("winPC")
        if (res.code === 200) {
            this.windowsUrl = res.data.url + "/svmeet_setup_" + res.data.version + "_" + env + ".exe"
        }
        const res2 = await api.common.getVersion("macPC")
        if (res2.code === 200) {
            this.macUrl = res.data.url + "/svmeet_setup_" + res.data.version + "_" + env + ".dmg"
        }
        console.log(this.windowsUrl, this.macUrl)
    }

    public async getInviteInfo(obj) {
        try {
            const res = await api.meeting.getInviteInfo(obj)
            if (res.code === 200) {
                if (res.data.status === 0) {// 进行中
                    res.data.statusText = i18n.t("ongoing");
                    res.data.statusBackground = '#31A6FF'
                    this.openPC(this.path)
                } else if (res.data.status === 3) {// 未开始
                    res.data.statusText = i18n.t("notStarted")
                    res.data.statusBackground = '#FFBD0B'
                    this.openPC(this.path)
                } else if (res.data.status === 6) { // 已结束
                    res.data.statusText = i18n.t("ended")
                    res.data.statusBackground = '#B4B4B4'
                } else if (res.data.status === 8) {
                    res.data.statusText = i18n.t("canceled")
                    res.data.statusBackground = '#B4B4B4'
                }
                res.data.startDate = res.data.startDate ? moment(res.data.startDate).format('YYYY-MM-DD HH:mm') : ""
                this.setState({ meetingData: res.data, showEditPassword: false })
            } else {
                if (res.code === 11002) {
                    message.error(i18n.t("message.passwordIncorrect"))
                } else {
                    message.error(i18n.t("message.FailedGetMeetingInformation"))
                }
            }
            this.setState({ loading: false })
        } catch (e) {
            this.setState({ loading: false })
        }
    }

    public render() {
        const { showEditPassword, meetingData, loading, search } = this.state;
        return <div className="download-app-component">
            <div className="main" style={{ display: loading ? "none" : "flex" }}>
                <i className="iconfont icon-close" onClick={this.props.close}></i>
                {/* <div className="top">
                    <img src={require('static/images/icon-exclamation.png')} alt="" />
                    <div dangerouslySetInnerHTML={{ __html: i18n.t("wechatBrowserDesc6") }}></div>
                </div> */}
                {
                    showEditPassword ? <React.Fragment>
                        <div className="confidential">{i18n.t("dialog.ConnectConfidentialMeeting")}</div>
                        <Input
                            className="input"
                            placeholder={i18n.t('message.pleaseEnterMeetPassword')}
                            value={search.password}
                            maxLength={15}
                            onChange={evt => {
                                const value = evt.target.value.trim();
                                if (/^[0-9]{0,15}$/.test(value)) {
                                    this.setState({
                                        search: {
                                            ...search,
                                            password: value
                                        }
                                    })
                                }
                            }}
                            onKeyDown={(e) => {
                                const evt = window.event || e;
                                if ((evt as any).keyCode === 13) {
                                    this.confirmPassword()
                                }
                            }}
                        />
                        <div className="submitBtn" onClick={this.confirmPassword.bind(this)}>{i18n.t("meet.submit")}</div>
                    </React.Fragment> : <React.Fragment>
                        {!loading && meetingData.theme && <div className='main-meet'>
                            <div className="name">
                                {meetingData.theme}
                            </div>
                            <div className="time">
                                <span>{meetingData.startDate}</span>
                                <span style={{ background: meetingData.statusBackground }}>{meetingData.statusText}</span>
                            </div>
                            {
                                meetingData.meetNumber && <div className="containerText">
                                    <span>{i18n.t('conferenceInvitationCode')}</span><span>：</span>
                                    <span>{meetingData.meetNumber}</span>
                                </div>
                            }
                            {
                                meetingData.password && <div className="containerText">
                                    <span>{i18n.t('meetPassword')}</span><span>：</span>
                                    <span>{meetingData.password}</span>
                                </div>
                            }
                            {
                                meetingData.content && <div className="containerText">
                                    <span>{i18n.t('contentMeeting')}</span><span>：</span>
                                    <span>{meetingData.content}</span>
                                </div>
                            }
                            {
                                meetingData.status !== 6 && <div className="containerText">
                                    <span>{i18n.t('conferenceAddress')}</span><span>：</span>
                                    <span className="address">{`${window.location.origin}/sv/${meetingData.meetNumber}?password=${meetingData.password || ""}`}<img className="icon-copy" src={require("static/images/icon-copy.png")} alt=""
                                        onClick={this.copy.bind(this)} /></span>
                                </div>
                            }
                        </div>}
                        <div className="text" dangerouslySetInnerHTML={{ __html: i18n.t("wechatBrowserDesc4") }}></div>
                        <div className="text">{i18n.t("wechatBrowserDesc5")}</div>
                        <div className="downloadBtn" onClick={this.downloadNow.bind(this)}>{i18n.t("downloadNow")}</div>
                        <div className="joinBrowser" onClick={this.joinBrowser.bind(this)}>{i18n.t("joinBrowser")}</div>
                    </React.Fragment>
                }
            </div>
            <div id="NewsToolBoxAddressGuide"></div>
        </div>
    }

    // 打开PC
    public openPC(path) {
        try {
            const iframe = document.createElement("iframe")
            iframe.src = `svmeet://sv/${path}`;
            iframe.style.display = "none"
            document.body.appendChild(iframe)
            setTimeout(() => {
                document.body.removeChild(iframe)
            }, 3000)
        } catch (e) {
            console.log(e)
        }
    }

    // 立即下载
    public downloadNow() {
        if (this.platform === "win") {
            window.location.href = this.windowsUrl
        } else if (this.platform === "mac") {
            window.location.href = this.macUrl
        }
        this.props.close()
    }

    // 浏览器入会
    public joinBrowser() {
        this.props.close()
    }

    // 复制
    public copy() {
        const { meetingData } = this.state
        copyText(`${window.location.origin}/sv/${meetingData.meetNumber}?password=${meetingData.password || ""}`, 'NewsToolBoxAddressGuide')
        message.success(i18n.t('copyClipboard1'))
    }

    // 提交密码
    public async confirmPassword() {
        if (!this.state.search.password) {
            message.warn(i18n.t("message.pleaseEnterMeetPassword"))
            return
        }
        this.getInviteInfo(this.state.search)
    }


    private platform;
    private path;
    private macUrl;
    private windowsUrl
}