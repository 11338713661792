import React from "react";
import moment from "moment";
import i18n from "i18n";
import api from "api";
import Message from "antd/lib/message";
import styles from "./index.module.css";
import { connect } from "react-redux";
import { CURRENT_MEETING_DATA, MEET_PROVISIONAL_USERNAME, GUIDE_PAGE, getDomainUrl } from "config";
import { SetOutCamera, InvitationModal } from "components"
import { IMeetItem, IMeetingItem } from "models/meet"
import DatePicker from "antd/lib/date-picker";
import { IUser } from "models/user"
import { setUser } from "reduxs/actions/user";
import { GetRandomNum } from "utils/data";
interface DispatchProps {
    user: IUser;
    setUser: (user: IUser) => void
}

const popupStyle = {
    background: "000"
}
interface IItem {
    password?: string;
    username?: string;
    theme?: string;
    content?: string;
    continueTime?: number[];
    externalPhone?: string[];
    isOpenWaitingRoom?: boolean;
}

interface IState {
    form: IMeetingItem;
    meetData: IMeetItem;
    checkboxUsername: boolean;
    showModal: boolean;
    showRefuseModal: boolean;
    maaForm: IMeetingItem,
    showInvitationModal: boolean;
    isMaaMeet: boolean;
    isSubmitting: boolean;
    showGuide: number;
    random: string;
    showDownloadAppModal: boolean;
}
class Index extends React.Component<{
    user: IUser;
    noticeSocket: any;
    closeShowModal: () => void,
} & any & DispatchProps, IState> {

    public state: IState = {
        form: {
            password: "",
            theme: "",
            content: "",
            primaryKey: "",
            startTime: "",
            username: "",
            reserveParticipants: [],
            secret: 0
        },
        meetData: {
            theme: "",
            content: "",
            meetId: "",
            password: "",
            primaryKey: "",
            residueTime: 0
        },
        checkboxUsername: false,
        showModal: false,
        showRefuseModal: false,
        maaForm: {
            password: "",
            theme: "",
            content: "",
            primaryKey: "",
            startTime: "",
            username: "",
            reserveParticipants: [],
            forecastDuration: undefined,
            remind: undefined,
            hostUserId: "",
            hostUser: "",
            month: 0,
            week: 0,
            whatNumber: undefined,
            whichDay: undefined,
            secret: 0
        },
        showInvitationModal: false,
        isMaaMeet: false,
        isSubmitting: false,
        showGuide: 0,
        random: GetRandomNum(100000, 999999) + "",
        showDownloadAppModal: false
    }

    public componentDidMount() {
        // 引导页
        const guide = localStorage.getItem(GUIDE_PAGE)
        if (guide) {
            if (JSON.parse(guide).indexOf("create-meet") > -1) {
                this.setState({ showGuide: 1 })
            }
        }

        const user = this.props.user;
        this.state.form.reserveParticipants && this.state.form.reserveParticipants.push({
            primaryKey: user.primaryKey,
            type: 0,
            userId: user.primaryKey,
            name: user.name,
            guestAddressBookId: "",
            dept: user.dept,
            guestUserId: user.guestUserId,
        })
        const { maaForm } = this.state;
        maaForm.hostUserId = user.primaryKey;
        maaForm.forecastDuration = 45; // 默认
        maaForm.remind = 5;// 默认5分钟
        maaForm.reserveParticipants && maaForm.reserveParticipants.push({
            primaryKey: user.primaryKey,
            type: 0,
            userId: user.primaryKey,
            name: user.name,
            guestAddressBookId: "",
            dept: user.dept,
            guestUserId: user.guestUserId,
        })
        this.setState({ maaForm, form: this.state.form })
    }

    public render() {
        const { form, maaForm, random } = this.state;
        const { user } = this.props;
        const createMeet1 = i18n.t('guideImages.createMeet1')
        return <div className={styles.forgetPwdPage}>

            {/* 引导页 */}
            {
                this.state.showGuide === 1 && <img className={styles.guide} src={require(`static/images/guide/${createMeet1}.png`)} alt="" />
            }
            {
                this.state.showGuide === 1 && <div className={styles.nextStep} style={this.getStyle()?.nextStep} onClick={this.nextStep.bind(this)}>{i18n.t('IKnow')}</div>

            }
            <div className={styles.formBox}>
                <div className={styles.meetTitle}>{i18n.t('createSession')}</div>
                <div className={styles.inputWrapper}>
                    <input
                        type="text"
                        className={styles.input}
                        placeholder={(!user.name && !form.username) ? i18n.t('conferenceTheme') : `${i18n.t('conferenceTheme')}：${i18n.t('nameMeeting', { name: form.username ? form.username : user.name })}`}
                        value={form.theme}
                        maxLength={30}
                        onChange={evt => this.setForm({ theme: evt.target.value })}
                    />
                </div>
                <div className={styles.inputWrapper}>
                    <input
                        className={styles.input}
                        placeholder={i18n.t('meetPassword') + "：" + random}
                        value={form.password}
                        maxLength={6}
                        onChange={evt => {
                            const value = evt.target.value.trim();
                            if (/^[0-9]{0,6}$/.test(value)) {
                                this.setForm({
                                    password: value
                                })
                            }
                        }}
                    />
                </div>
                <div className={styles.inputWrapper}>
                    <div
                        className={styles.input}
                        style={{ lineHeight: "0.48rem", cursor: "pointer" }}
                        onClick={() => this.setState({ showDownloadAppModal: true })}
                    >{i18n.t('meetingRoom') + "：" + i18n.t("meetingProduct.free")}</div>
                </div>
                <div className={styles.inputWrapper}>
                    <textarea className={styles.textarea} placeholder={i18n.t('contentMeeting')} name="" id="" maxLength={60}
                        onChange={(evt) => this.setForm({ content: evt.target.value })} ></textarea>
                </div>
                {
                    !user.name && <div className={styles.inputWrapper}>
                        <input
                            type="text"
                            className={styles.input}
                            placeholder={user.name ? `${user.name}（${i18n.t('userName')}）` : i18n.t('userName')}
                            value={form.username}
                            maxLength={8}
                            onChange={evt => {
                                const value = evt.target.value.trim();
                                this.setForm({
                                    username: value
                                })
                            }}
                            // onBlur={async (e) => {
                            //     if (e.currentTarget.value && (e.currentTarget.value !== user.name)) {
                            //         await this.setForm({ username: e.currentTarget.value })
                            //         // this.editUserName()
                            //     }
                            // }}
                            onKeyDown={(e) => {
                                const evt = window.event || e;
                                if ((evt as any).keyCode == 13) {
                                    //回车事件
                                    this.submit()
                                    // if (e.currentTarget.value && (e.currentTarget.value !== this.state.user.name)) {
                                    // this.editUserName()
                                    // }
                                }
                            }}
                        />
                    </div>
                }
                <div className={styles.inputWrapper} style={{ position: "relative" }}>
                    <img className={styles.datePickerImg} src={require("static/images/home/icon-date.png")} />
                    <DatePicker
                        className={styles.datePicker}
                        suffixIcon={""}
                        format="YYYY-MM-DD HH:mm"
                        showTime={{ format: 'HH:mm' }}
                        disabledDate={(current) => current && current < moment().endOf('day').subtract(1, 'days')}
                        disabledTime={this.disabledDateTime.bind(this)}
                        value={(maaForm.startTime as any) || ""}
                        style={{
                            width: '100%',
                            height: '0.48rem', fontSize: "0.14rem",
                            background: 'rgba(255, 255, 255, 0.2)',
                            fontFamily: "font-family: PingFangSC-Regular, PingFang SC",
                            fontWeight: 400,
                            color: '#FFFFFF',
                            borderRadius: '0.08rem',
                        }}
                        popupStyle={popupStyle}
                        placeholder={i18n.t('rightNow')}
                        onChange={async (value) => {
                            await this.setMaaForm({ startTime: value as any })
                            if (this.state.maaForm.startTime) {
                                const min = moment(this.state.maaForm.startTime).diff(new Date(), "minute")
                                if (min >= 5) {
                                    this.setState({ isMaaMeet: true })
                                } else {
                                    this.setState({ isMaaMeet: false })
                                }
                            } else {
                                this.setState({ isMaaMeet: false })
                            }
                        }}
                    />
                    {/* <div className={styles.maaMeetBtn}
                        style={{ opacity: maaForm.startTime ? 1 : 0.61 }}
                        onClick={this.maaSubmit.bind(this)}>确定并复制会议链接</div> */}
                </div>

                {/* <div className={styles.checkboxName}>
                    <Checkbox checked={this.state.checkboxUsername} onChange={(e) => {
                        this.setState({ checkboxUsername: e.target.checked })
                    }}></Checkbox>
                    <span className={styles.text}>在以后会议使用这个名字</span>
                </div> */}
                <div className={styles.btnBox}>
                    <div className={styles.backBtn} onClick={() => {
                        window.history.back()
                        //@ts-ignore
                        _hmt.push(['_trackEvent', "button", "click", 'create_meet_back_btn']);
                    }}>{i18n.t('returnHomePage')}</div>
                    <div className={styles.confirmBtn} onClick={this.submit.bind(this)}>{!this.state.isMaaMeet ? i18n.t('initiate') : i18n.t('reservationSession')}</div>
                </div>
            </div>
            <SetOutCamera />
            {
                this.state.showInvitationModal &&
                <InvitationModal style={{ width: "400px" }} defaultCopy={true} meetData={this.state.maaForm as any} pageText="maa-meet" />
            }
            {/* 指引icon */}
            <img src={require("static/images/guide/icon-guide.png")} alt="" style={{ cursor: "pointer", width: '0.33rem', height: '0.33rem', position: "fixed", bottom: '0.2rem', left: '0.2rem' }}
                onClick={() => this.setState({ showGuide: 1 })}
            />
            {
                this.state.showDownloadAppModal && <div className={styles.showDownloadAppModal}>
                    <div className={styles.main}>
                        <p>如需使用更大规模云会议室、体验更丰富的会议功能</p>
                        <p>请您下载<span>{i18n.t("svmeet")}</span>客户端</p>
                        <div className={styles.link}><a href={`${getDomainUrl()}/#/download`}>{getDomainUrl()}/#/download</a></div>
                        <div className={styles.btn} onClick={() => this.setState({ showDownloadAppModal: false })}>{i18n.t("shutDown")}</div>
                    </div>
                </div>
            }
        </div>
    }

    public async submit() {
        if (this.state.isSubmitting) return;
        const { form } = this.state;
        // if (!form.password) {
        //     Message.info(i18n.t('login.pleaseEnterYourPassword'))
        //     return
        // }
        if (!form.password) {
            form.password = this.state.random
        }
        if (form.password.length !== 6) {
            Message.info(i18n.t('message.pleaseEnterValid6Password'))
            return
        }
        if (this.state.maaForm.startTime) {
            const min = moment(this.state.maaForm.startTime).diff(new Date(), "minute")
            if (min >= 5) {// 预约会议
                //@ts-ignore
                _hmt.push(['_trackEvent', "button", "click", 'create_meet_btn', 'maa']);
                this.maaSubmit()
                return
            }
        }
        //@ts-ignore
        _hmt.push(['_trackEvent', "button", "click", 'create_meet_btn', 'create']);
        // 立即会议
        if (form.username === '') {
            if (this.props.user.name) {
                form.username = this.props.user.name
            } else {
                Message.info(i18n.t('message.pleaseEnterUserName'))
                return
            }
        }
        if (!form.theme) {
            form.theme = i18n.t('nameMeeting', { name: form.username })
        }
        if (form.reserveParticipants && form.reserveParticipants.length && !form.reserveParticipants[0].name) {
            form.reserveParticipants[0].name = form.username || "" // 主要处理新用户
        }
        this.setState({ isSubmitting: true }, async () => {
            const res = await api.meeting.createMeet(form)
            if (res.code === 200) {
                const bool = await api.meeting.joinMeet(res.data.meetNumber, res.data.password || "")
                this.setState({ isSubmitting: false })
                if (!this.props.user.name && (form.username !== this.props.user.name)) {
                    this.editUserName()
                }
                if (bool.code === 200) {
                    window.localStorage.setItem(MEET_PROVISIONAL_USERNAME, form.username || "")
                    window.localStorage.setItem(CURRENT_MEETING_DATA, JSON.stringify({
                        primaryKey: bool.data.primaryKey,
                        roomName: bool.data.roomName,
                        token: bool.data.token,
                        ...bool.data.meetingInfo
                    }))
                    this.props.history.push(`/meet/${res.data.meetNumber}`);
                }
            } else {
                if (res.code === 11004) {
                    Message.error(i18n.t('meetingTimeConflict'))
                } else if (res.code === 11017) {
                    Message.error(i18n.t('meetingServiceHasExpired'))
                } else {
                    Message.error(res.msg)
                }
                this.setState({ isSubmitting: false })
            }
        })

    }
    public async editUserName() {
        try {
            const res = await api.user.updateUserName(this.state.form.username || "");
            if (res.code === 200) {
                this.props.setUser(res.data)
            }
        } catch (e) {
            Message.error(i18n.t('message.requestFailed'))
        }
    }

    public async maaSubmit() {
        const { maaForm, form } = this.state;
        maaForm.content = form.content;
        if (form.username === '') {
            maaForm.username = this.props.user.name;
        }
        maaForm.theme = form.theme ? form.theme : i18n.t('nameMeeting', { name: maaForm.username });
        maaForm.password = form.password ? form.password : this.state.random
        if (maaForm.reserveParticipants && maaForm.reserveParticipants.length && !maaForm.reserveParticipants[0].name) {
            maaForm.reserveParticipants[0].name = form.username || "" // 主要处理新用户
        }
        const data: IMeetingItem = JSON.parse(JSON.stringify(maaForm));
        data.startTime = moment(maaForm.startTime).format("YYYY-MM-DD HH:mm:00");
        this.setState({ isSubmitting: true }, async () => {
            const res = await api.meeting.reserveMeet(data)
            if (res.code === 200) {
                maaForm.meetNumber = res.data.meetNumber;
                this.setState({ maaForm, showInvitationModal: true })
            } else {
                if (res.code === 11004) {
                    Message.error(i18n.t('meetingTimeConflict'))
                } else if (res.code === 11017) {
                    Message.error(i18n.t('meetingServiceHasExpired'))
                } else {
                    Message.error(res.msg)
                }
            }
            this.setState({ isSubmitting: false })
        })
    }

    public range(start, end) {
        const result: any[] = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    }

    public disabledDateTime(value) {
        if (moment(value).date() === moment().date()) {
            if (moment(value).hour() <= moment().hour()) {
                return {
                    disabledHours: () => this.range(0, 24).splice(0, moment().hour()),
                    disabledMinutes: () => this.range(0, moment().minute()),
                }
            } else {
                return {
                    disabledHours: () => this.range(0, 24).splice(0, moment().hour())
                }
            }
        } else {
            if (value === null) {
                return {
                    disabledHours: () => this.range(0, 24).splice(0, moment().hour()),
                    disabledMinutes: () => this.range(0, moment().minute()),
                }
            } else {
                return {}
            }
        }
    }

    public setForm(form: any) {
        this.setState({
            form: {
                ...this.state.form,
                ...form
            }
        })
    }

    public setMaaForm(form: any) {
        this.setState({
            maaForm: {
                ...this.state.maaForm,
                ...form
            }
        })
    }

    // 获取跳过、下一步的定位
    public getStyle() {
        if (this.state.showGuide === 1) {
            return { nextStep: { left: "42.70%", top: "53.24%" } }
        }
    }
    // 跳过
    public skip() {
        const guide = localStorage.getItem(GUIDE_PAGE)
        if (guide) {
            const gui = JSON.parse(guide)
            if (gui.indexOf("create-meet") > -1) {
                gui.splice('create-meet', 1)
                localStorage.setItem(GUIDE_PAGE, JSON.stringify(gui))
            }
        }
        this.setState({ showGuide: 0 })
    }

    // 下一步
    public nextStep() {
        if (this.state.showGuide === 1) {
            this.skip()
        }
    }
}

export default connect(
    (state: any) => ({
        noticeSocket: state.noticeSocket,
        user: state.user
    }),
    (dispatch) => {
        return {
            setUser: (data: any) => dispatch(setUser(data))
        }
    }
)(Index);