import { STORE_NOTICE_WEBSOCKET } from "reduxs/actions/message";


export function noticeSocket(state = {}, action: any) {
    switch (action.type) {
        case STORE_NOTICE_WEBSOCKET:
            return action.noticeSocket;
        default:
            return state;
    }
}
