
import React from "react";
import "./index.css"
import { CURRENT_MEETING_DATA } from "config/index"
import { IParticipantItem } from "models/meet";

export default class extends React.Component<{
    localParticipant: IParticipantItem;
    showConnectionQuality: () => void
}, {
    theme: string;
}> {

    constructor(props) {
        super(props)
        this.state = {
            theme: "",
        }
    }

    public componentDidMount() {
        const meet = window.localStorage.getItem(CURRENT_MEETING_DATA)
        if (meet) {
            this.setState({ theme: meet ? JSON.parse(meet).theme : "" })
        }
    }

    public render() {
        const stats = this.props.localParticipant && this.props.localParticipant.stats
        const connection = stats ? Math.floor(stats.connectionQuality / 33.4) : null
        return (
            <div className="meetTheme_page">
                {
                    connection === 0 && <img className="connection" onClick={this.changeConnectionQuality.bind(this)} src={require("static/images/meet/net-low.png")} alt="" />
                }
                {
                    connection === 1 && <img className="connection" onClick={this.changeConnectionQuality.bind(this)} src={require("static/images/meet/net-medium.png")} alt="" />
                } {
                    connection === 2 && <img className="connection" onClick={this.changeConnectionQuality.bind(this)} src={require("static/images/meet/net-good.png")} alt="" />
                }
                <img src={require("static/images/home/logo-small.png")} alt="" />
                <span className='theme'>{this.state.theme}</span>
            </div>
        );
    }

    public changeConnectionQuality() {
        this.props.showConnectionQuality()
    }
}