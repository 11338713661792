import { request } from "utils/request";
import axios from "axios";
import { SW_USER, STORAGE_ACCESS_TOKEN, API_URL } from "config";
import timezone from "moment-timezone";
import i18n from "i18n";
import { message } from "antd";

/**
 * @name 登录
 */
export async function login(params, type) {
    const response = (await request.post(
        `${API_URL}/user/login/${type}`,
        params,
    )).data;

    return response;
}

/**
 * @name 用户手机验证码登录
 */
export async function captchaTologin(phone: string, captcha: string) {
    const response = (await request.post(
        `${SW_USER}/toLogin/code`,
        {
            phone,
            code: Number(captcha)
        }
    )).data;

    return response;
}

/**
 * @name 获取企业
 */
export async function getEnterprise(params: any) {
    return request.get(
        `${API_URL}/company/registration?name=${params.name}&invitationCode=${params.invitationCodeStr}`,
    )
}

/**
 * @name 申请企业
 */
export async function theApplicant(params: any) {
    return request.post(
        `${API_URL}/system/bind/customer/${params}`,
    )
}

/**
 * @name 个人注册
 */
export async function userRegister(type, params) {
    return (await request.post(
        `${API_URL}/system/registration/${type}`, params,
    )).data;
}

/**
 * @name 企业注册
 */
export async function enterpriseRegister(type, params) {
    return (await request.post(
        `${API_URL}/company/registration/${type}`, params,
    )).data;
}

/**
 * @name 注册
 */
export async function userRegistration(form) {
    const response = (await request.post(
        `${SW_USER}/userRegistration`,
        {
            password: form.password,
            checkPassword: form.checkPassword,
            name: form.name,
            email: form.email,
            phone: form.phone,
            deptName: form.deptName,
            captcha: Number(form.captcha),
        },
    )).data;

    return response;
}

/**
 * @name 手机验证码
 */
export async function sendCode(type: string, phone: string, imgId: string, imgCode: string) {
    const response = (await request.post(
        `${API_URL}/sms/notice/sendCode/${type}/${phone}`, { imgId, code: imgCode }
    )).data;
    if (response.code === 200) {
        message.success(i18n.t("message.PhoneNumberTips", { value: phone }))
    } else if (response.code === 10006) { // 验证码发送失败
        message.error(i18n.t("message.FailedSendVerificationCode"))
    } else if (response.code === 10016) {
        message.error(i18n.t("message.imageVerificationCodeError"))
    } else {
        message.error(response.msg || i18n.t('message.requestFailed'))
    }
    return response;
}

/**
 * @name 邮箱验证码
 */
export async function emailSendCode(type: string, mail: string, imgId: string, imgCode: string) {
    const response = (await request.post(
        `${API_URL}/mail/notice/send/${type}/${mail}`, { imgId, code: imgCode }
    )).data;
    if (response.code === 200) {
        message.success(i18n.t("message.EmailTips", { value: mail }))
    } else if (response.code === 10006) { // 验证码发送失败
        message.error(i18n.t("message.FailedSendVerificationCode"))
    } else if (response.code === 10016) {
        message.error(i18n.t("message.imageVerificationCodeError"))
    } else {
        message.error(response.msg || i18n.t('message.requestFailed'))
    }
    return response;
}

/**
 * @name 忘记密码
 */
export async function recoverPassword(type: "email" | "phone", form) {
    const response = (await request.post(
        `${API_URL}/system/forget/${type}`,
        {
            password: form.password,
            checkPassword: form.checkPassword,
            account: form.account,
            captcha: Number(form.captcha),
        },
    )).data;

    return response;
}

/**
 * @name 重置密码
 */
export async function resetPassword(type: "email" | "phone", form) {
    const response = (await request.post(
        `${API_URL}/system/reset/${type}`,
        {
            password: form.password,
            checkPassword: form.checkPassword,
            captcha: Number(form.captcha),
        },
    )).data;

    return response;
}

/**
 * @name 重置-验证码
 */
export async function rpSendCode(phone: string) {
    const response = (await request.post(
        `${API_URL}/sms/notice/sendCode/rp/${phone}`
    )).data;

    return response;
}

/**
 * @name 获取组织下的用户
 */
export async function getDeptUsers(companyId?: string) {
    const response = (await request.get(
        `${API_URL}/company/get/users?companyId=${companyId ? companyId : ""}`,
    )).data;

    return response;
}

/**
 * @name 获取用户信息
 */
export async function getUserInfo(params?: any) {
    let url = ''
    if (params) {
        url = `${API_URL}/system/userInfo?isAll=${params}`
    } else {
        url = `${API_URL}/system/userInfo`
    }
    const response = (await request.get(url)).data;
    response.data = { ...response.data, token: localStorage.getItem(STORAGE_ACCESS_TOKEN) }
    return response;
}

/**
 * @name 修改用户企业
 */
export async function setUserCompany(params) {
    const response = (await request.put(
        `${API_URL}/system/switch/${params}`
    )).data;

    return response;
}

/**
 * @name 修改昵称
 */
export async function updateUserName(name: string) {
    const response = (await request.post(
        `${API_URL}/user/edit/info`,
        {
            name
        }
    )).data;

    return response;
}


/**
 * @name 检查token是否有效
 */
export async function checkToken() {
    const response = (await axios.get(
        `${API_URL}/system/check/token?jwt=${window.localStorage.getItem(STORAGE_ACCESS_TOKEN)}`, {
        headers: {
            timezone: timezone.tz.guess()
        }
    }
    )).data;

    return response;
}

/**
 * @name 检查手机号或邮箱是否存在
 */
export async function isAccountExist(type: string, account: string) {
    const response = (await request.get(
        `${API_URL}/user/check/${type}/${account}`,
    )).data;

    return response;
}

/**
 * @name 获取需要补充的用户信息字段
 */
// export async function getUserInfoField() {
//     const response = (await request.get(
//         `${API_URL}/admin/system/supplement/info`,
//     )).data;

//     return response;
// }

/**
 * @name 绑定手机号
 */
export async function bindPhone(data: any) {
    const response = (await request.put(
        `${API_URL}/user/bind/phone`,
        data
    )).data;

    return response;
}
