
import React from "react";
import i18n from "i18n";
import api from "api";
import { withRouter } from 'react-router-dom';
import { Select, message } from "antd";
import "./index.less"

interface IState {
    currentTranslationLang: string;
    language: Array<{
        value: string;
        label: string;
    }>;
}

class Index extends React.Component<{
    onClose?: (lang: string) => void;
}, IState> {

    public state: IState = {
        currentTranslationLang: "",
        language: []
    }

    public componentDidMount() {
        this.lang()
    }

    // 获取语言列表
    public async lang() {
        try {
            const res = await api.summary.translateLocales()
            const list: any[] = [];
            for (const key in res.data) {
                if (Object.prototype.hasOwnProperty.call(res.data, key)) {
                    const title = res.data[key].title;
                    list.push({
                        label: title,
                        value: key
                    })
                }
            }
            this.setState({ language: list })
        } catch (e) {
            message.error(i18n.t("message.requestFailed"))
        }
    }

    public render() {
        return <div className={'set-lang-component'}>
            <div className={'main'}>
                <div className="spokenLanguage">
                    <span className="title">{i18n.t("commonlySpokenLanguageIs")}</span>
                    <Select className="select"
                        defaultValue={i18n.t("notSet")}
                        value={this.state.currentTranslationLang || undefined}
                        onChange={(value) => this.setState({ currentTranslationLang: value })}>
                        {
                            this.state.language.map(x => {
                                return <Select.Option key={x.value} value={x.value}>{x.label}</Select.Option>
                            })
                        }
                    </Select>
                </div>
                <div className="btn" onClick={this.set.bind(this)}>{i18n.t("login.confirm")}</div>
            </div>
        </div>
    }

    public async set() {
        if (!this.state.currentTranslationLang) {
            message.info(i18n.t('setCommonlySpokenLanguage'))
            return
        }
        const res = await api.common.setDefaultTranslation(this.state.currentTranslationLang)
        if (res.code === 200) {
            if (res.data) {
                this.props.onClose && this.props.onClose(this.state.currentTranslationLang)
            }
        } else {
            message.error(i18n.t("message.requestFailed"))
        }
    }

}

export default withRouter(Index)