import { STORE_CURRENT_MEET_DATA, STORE_MUTED, STORE_E2EE_SUPPORTED, STORE_MY_INFO, STORE_PICTURE_IN_PICTURE, STORE_MEETING_RECORDING } from "reduxs/actions/meet";


export function currentMeetData(state = {}, action: any) {
    switch (action.type) {
        case STORE_CURRENT_MEET_DATA:
            return action.currentMeetData;
        default:
            return state;
    }
}

export function videoMuted(state = {}, action: any) {
    switch (action.type) {
        case STORE_MUTED:
            return action.videoMuted;
        default:
            return state;
    }
}

export function e2eeSupported(state = {}, action: any) {
    switch (action.type) {
        case STORE_E2EE_SUPPORTED:
            return action.e2eeSupported;
        default:
            return state;
    }
}


export function myInfo(state = {}, action: any) {
    switch (action.type) {
        case STORE_MY_INFO:
            return action.myInfo;
        default:
            return state;
    }
}

export function pictureInPictureData(state = {}, action: any) {
    switch (action.type) {
        case STORE_PICTURE_IN_PICTURE:
            return action.pictureInPictureData;
        default:
            return state;
    }
}

export function meetingRecordingData(state = {}, action: any) {
    switch (action.type) {
        case STORE_MEETING_RECORDING:
            return action.recordData;
        default:
            return state;
    }
}


