import $ from "jquery";
import { IParticipantItem } from "models/meet";
import { IRecorderCommand } from "models/common";
import { toPurchaseEnhancedService } from "utils/common"
import i18n from "i18n";
import { logger } from "utils/log";

const AUDIO_LEVEL_DOTS = 5;
const CENTER_DOT_INDEX = Math.floor(AUDIO_LEVEL_DOTS / 2);

export default {

    /**
     * @name 添加远端参与人布局
     */
    addRemoteUserContainer(userdata: IParticipantItem,
        userList: IParticipantItem[], mode: "gallery" | "speaker",
        speakerChanged: Function, switchSpeaker: Function
    ) {
        console.log("添加远端参与人布局")
        const participantId = userdata.participantId;
        const swiper = document.createElement('div')
        if (mode === 'speaker') {
            swiper.className = 'swiper-slide';
        } else {
            swiper.className = 'galley-participant-wrap';
        }

        const participantDiv = document.createElement('div');
        participantDiv.id = `participant_${participantId}`;

        if (mode === 'speaker') {
            participantDiv.className = 'videocontainer';
        } else {
            participantDiv.className = 'galley-participant-box';
        }

        participantDiv.onclick = function () {
            speakerChanged(participantId)
        }
        participantDiv.ondblclick = function () {
            switchSpeaker(participantId)
        }

        swiper.appendChild(participantDiv)
        participantDiv.innerHTML = `
        <div class = 'videocontainer__background'></div>
        <div class = 'videocontainer__toptoolbar'>
            <div class='moderator' style="display:${userdata.moderator ? 'flex' : 'none'}" id='${`participant_${participantId}`}_moderator'>
               <img src=${require("static/images/meet/speaker.png")} alt="" />
             </div>
        </div>
        <div class = 'videocontainer__toolbar'>
             <div class='username' id='${`participant_${participantId}`}_username'>${userdata.realName ? userdata.realName : (userdata._displayName || i18n.t('meet.stranger'))}${!userdata.sharedUser && userdata.translationLanguageText ? `(${userdata.translationLanguageText})` : ""}</div>
            <div class='muted' id='${`participant_${participantId}`}_muted'>
                <i class='iconfont icon-muted'></i>
            </div>
        </div>
        <div class = 'avatar-container'>
             <img class='${userdata.avatar ? "userAvatar" : "userAvatar1"}' id='${`participant_${participantId}`}_avatar' src='${userdata.avatar || require("static/images/default-photo.png")}' />
        </div>
        <span class='audioindicator-container'>
             <span class="audioindicator in-react">
                <span class="audiodot-bottom" ></span>
                <span class="audiodot-bottom" ></span>
                <span class="audiodot-middle" ></span>
                <span class="audiodot-top" ></span>
                <span class="audiodot-top" ></span>
            </span>
         </span>
        `;

        const remoteVideosContainer
            = document.getElementById('filmstripRemoteVideosContainer') as any;
        const localVideoContainer
            = document.getElementById('localVideoTileViewContainer');

        remoteVideosContainer.appendChild(swiper, localVideoContainer?.parentElement);
        if (mode === 'gallery') {
            this.setGalleyModeStyle(userList.length)
        }
    },

    /**
     * @name 添加本地参与用户布局
     */
    addLocalUserContainer(userdata: IParticipantItem, speakerChanged: Function, switchSpeaker: Function) {
        console.log("添加本地参与用户布局")
        const localVideoContainer
            = document.getElementById('localVideoTileViewContainer') as any;
        if (!localVideoContainer) return
        localVideoContainer.onclick = function () {
            speakerChanged(userdata.userId)
        }
        localVideoContainer.ondblclick = () => {
            switchSpeaker(userdata.userId)
        };
        localVideoContainer.innerHTML = `
        <div class = 'videocontainer__background'></div>
        <div class = 'videocontainer__toptoolbar'>
              <div class='moderator' style="display:${userdata.moderator ? 'flex' : 'none'}" id='participant_${userdata.userId}_moderator'>
               <img src=${require("static/images/meet/speaker.png")} alt="" />
             </div>
        </div>
        <div class = 'videocontainer__toolbar'>
            <div class='username' id='participant_${userdata.userId}_username'>${userdata._displayName || i18n.t('meet.stranger')}(${userdata.translationLanguageText})</div>
            <div class='muted' id='participant_${userdata.userId}_muted'>
                 <i class='iconfont icon-muted'></i>
             </div>
        </div>
        <div class = 'avatar-container'>
             <img class='${userdata.avatar ? "userAvatar" : "userAvatar1"}' id='participant_${userdata.userId}_avatar' src='${userdata.avatar || require("static/images/default-photo.png")}' />
        </div>
        <span class='audioindicator-container'>
            <span class="audioindicator in-react">
                <span class="audiodot-bottom" ></span>
                <span class="audiodot-bottom" ></span>
                <span class="audiodot-middle" ></span>
                <span class="audiodot-top" ></span>
                <span class="audiodot-top" ></span>
            </span>
        </span>
        `;
        this.addTopLeftUserContainer(userdata, speakerChanged)
    },
    /**
    * @name 两人模式添加左上角用户
    * @param userdata 
    */
    addTopLeftUserContainer(userdata: IParticipantItem, speakerChanged: Function) {
        console.log(userdata, '两人模式添加左上角用户')
        if (!userdata) return
        this.removeTopLeftUserContainer()
        const participantId = userdata.userId;

        const participantDiv = document.createElement('div');
        participantDiv.className = 'topleft_usercontainer videocontainer';

        participantDiv.onclick = () => {
            // 和主讲切换位置
            if ((window as any).participants.length === 1) return
            let participants: IParticipantItem[] = (window as any).participants
            let speakerUserdata;
            let selfUserData;
            participants && participants.forEach(participant => {
                if (participant.userId === this.speakerUserdata?.userId) {
                    speakerUserdata = participant
                }
                if (participant.userId === userdata.userId) {
                    selfUserData = participant
                }
            })
            this.addTopLeftUserContainer(speakerUserdata, speakerChanged)
            this.addSpeakerUserContainer({
                userdata: selfUserData
            })
            speakerChanged && speakerChanged(selfUserData.userId)
        }
        participantDiv.innerHTML = `
        <video id="localVideoSmall_${participantId}" autoplay></video>
        <div class = 'videocontainer__background'></div>
        <div class = 'videocontainer__toptoolbar'>
            <div class='moderator' style="display:${userdata.moderator ? 'flex' : 'none'}" id='self_participant_${userdata.userId}_moderator'>
               <img src=${require("static/images/meet/speaker.png")} alt="" />
             </div>
        </div>
        <div class = 'videocontainer__toolbar'>
             <div class='username' id='self_participant_${userdata.userId}_username'>${userdata.realName ? userdata.realName : (userdata._displayName || i18n.t('meet.stranger'))}${!userdata.sharedUser ? `(${userdata.translationLanguageText})` : ""}</div>
            <div class='muted' id='self_participant_${userdata.userId}_muted'  style='color:${userdata.muted ? 'e02020' : '#fff'}'>
                <i class='iconfont ${userdata.muted ? 'icon-muted' : 'icon-unmuted'}'></i>
            </div>
        </div>
        <div class = 'avatar-container'>
             <img class='${userdata.avatar ? "userAvatar" : "userAvatar1"}' id='self_participant_${userdata.userId}_avatar' src='${userdata.avatar || require("static/images/default-photo.png")}' />
        </div>
        <span class='audioindicator-container'>
             <span class="audioindicator in-react">
                <span class="audiodot-bottom" ></span>
                <span class="audiodot-bottom" ></span>
                <span class="audiodot-middle" ></span>
                <span class="audiodot-top" ></span>
                <span class="audiodot-top" ></span>
            </span>
         </span>
        `;

        const meetingMain = document.getElementById('meetingMain')
        meetingMain?.appendChild(participantDiv)

        const timer = setTimeout(() => {
            clearTimeout(timer)
            const trackList = userdata.list.filter(x => x.mediaType === "video")
            if (trackList.length) {
                const video = document.getElementById(`localVideoSmall_${participantId}`)
                trackList[0].jitsiTrack.attach(video)
            }
        }, 100)
    },
    /**
   * @name 删除左上角用户
   * @param userdata 
   */
    removeTopLeftUserContainer() {
        const list = document.getElementsByClassName('topleft_usercontainer')
        for (let i = 0; i < list.length; i++) {
            list[i].remove()
        }
    },
    speakerUserdata: null as IParticipantItem | null,
    /**
      * @name 添加主讲人
      */
    addSpeakerUserContainer(params: {
        userdata: IParticipantItem
    }) {
        const userdata: IParticipantItem = params.userdata
        console.log(userdata, '添加主讲人')
        this.speakerUserdata = userdata
        const div = document.getElementById('speaker_usercontainer')
        if (!div) return
        if (userdata) {
            div.innerHTML = userdata.local && userdata.sharedUser ?
                `<div class='speaker_div noVideoTextTwo'>${i18n.t('meet.youAreSharingScreen')}</div>` : `
            <div class='speaker_div speakerIcon'>
                 <img style=display:${userdata.moderator ? "inline-block" : "none"} src=${require("static/images/meet/speaker.png")} alt="" /> 
                 <span class='name' id="participant_speaker_username">${userdata.realName ? userdata.realName : (userdata._displayName || i18n.t('meet.stranger'))}${!userdata.sharedUser ? `(${userdata.translationLanguageText})` : ""}</span>
                <div class="speaker-audioindicator-container">
                    <span class='audioindicator-container' id="${userdata.participantId}-speaker-audioindicator-container">
                        <span class="audioindicator in-react">
                            <span class="audiodot-bottom" ></span>
                            <span class="audiodot-bottom" ></span>
                            <span class="audiodot-middle" ></span>
                            <span class="audiodot-top" ></span>
                            <span class="audiodot-top" ></span>
                        </span>
                    </span>
                </div>
            </div>
             <div class='speaker_div avatar-container'>
                 <img class='userAvatar' id='participant_${userdata.userId}_avatar' src='${userdata.avatar || require("static/images/default-photo.png")}' />
            </div>
            <video class="speaker_div" id="speakerVideo" autoplay></video>
        `;

            const timer = setTimeout(() => {
                clearTimeout(timer)
                const trackList = userdata.list.filter(x => x.mediaType === "video")
                if (trackList.length) {
                    const video = document.getElementById(`speakerVideo`)
                    trackList[0].jitsiTrack.attach(video)
                }
            }, 100)
        }
    },
    /**
    * @name 删除主讲人
    */
    removeSpeakerUserContainer() {
        let list = document.getElementsByClassName('speaker_div')
        for (let i = 0; i < list.length; i++) {
            list[i].remove()
        }
        list = document.getElementsByClassName('speaker_div')
        for (let i = 0; i < list.length; i++) {
            list[i].remove()
        }
    },
    /**
     * @name 用户离开
     * @param userdata 
     */
    removeUserContainer(userdata: IParticipantItem, boxNum: number, callback: Function) {
        if (!userdata) return
        const tracks = userdata.list;
        for (let i = 0; i < tracks.length; i++) {
            let container;
            if (tracks[i].mediaType === "audio") {
                container = document.getElementById('remoteAudio_' + userdata.participantId)
            } else {
                container = document.getElementById(userdata.local ? 'localVideo_container' : 'remoteVideo_' + userdata.participantId)

            }
            if (container) {
                tracks[i].jitsiTrack.detach(container);
            }
        }
        // if (!userdata.local) {
        const element = document.getElementById(`participant_${userdata.participantId}`) as any;
        if (element) {
            element.parentNode.parentNode.removeChild(element.parentNode)
        }
        // } else {
        // const element = document.getElementById(`participant_${userdata._displayName}`) as any;
        // if (element) {
        //     element.parentNode.parentNode.removeChild(element.parentNode)
        // }
        // }
        this.setGalleyModeStyle(boxNum)
        callback()
        if (boxNum === 1) {
            this.removeSpeakerUserContainer()
        }
    },
    /**
     * @name 主持人改变
     * @param userList 
     */
    userRoleChange(userList: IParticipantItem[]) {
        userList.forEach(element => {
            const node = document.getElementById(`participant_${element.userId}_moderator`)
            const nodeSelf = document.getElementById(`self_participant_${element.userId}_moderator`)
            if (node && element.moderator) {
                node.style.display = "flex";
                if (nodeSelf) nodeSelf.style.display = "flex";
            } else if (node) {
                node.style.display = "none";
                if (nodeSelf) nodeSelf.style.display = "none";
            }
        });
    },

    /**
   * 
   * @name 添加音视频标签 
   */
    async addVideoOrAudioElement(track, userdata: IParticipantItem, errorCallback?: Function) {
        let element: HTMLVideoElement | HTMLAudioElement | null = null;
        let id = ""
        if (track.getType() === "audio" && !userdata.local) {
            id = 'remoteAudio_' + userdata.participantId
            const ele = document.getElementById(id)
            ele && ele.parentNode && ele.parentNode.removeChild(ele);
            element = document.createElement('audio')
            element.id = id;
            element.autoplay = true;
            element.muted = false;
            // element.style.cssText = "display:block!important"
        } else if (track.getType() === "video") {
            id = userdata.local && track.videoType !== 'desktop' ? 'localVideo_container' : 'remoteVideo_' + userdata.participantId;
            const ele = document.getElementById(id)
            ele && ele.parentNode && ele.parentNode.removeChild(ele);
            element = document.createElement('video')
            element.id = id;
            element.autoplay = true;
            element.muted = true;
        }
        if (element) {
            let container;
            if (track.getType() === "audio") {
                container = document.getElementById('meetingMain')
                await container.parentElement.appendChild(element, container.children[0])
            } else {
                if (userdata.local && track.videoType !== 'desktop') {
                    container = document.getElementById('localVideoTileViewContainer') as any;
                } else {
                    container = document.getElementById(`participant_${userdata.participantId}`)
                }
                await container.insertBefore(element, container.children[0])
            }
            track.attach(element)
            this._play(element, () => {
                errorCallback && errorCallback()
            })
        }
        this.setAudioMutedIcon(userdata)
    },
    /**
     * @name 播放audio/video
     */
    _play(element, errorCallback, retries = 0) {
        element.play().then(() => {
            console.log("播放成功")
        }, e => {
            console.log('播放失败', e, e.code)
            if (retries < 3) {
                setTimeout(() => this._play(element, errorCallback, retries + 1), 1000);
                if (retries === 0) {
                    logger.warn('audio.play.error', element.id, e);
                }
            }
            if (retries === 2) {
                if (e.code === 0) { //code:0表示浏览器禁止自动播放
                    errorCallback()
                    document.body.onclick = () => {
                        this._play(element, errorCallback)
                    }
                }
            }
        })
    },
    /**
   * 
   * @name 删除音视频标签 
   */
    removeVideoOrAudioElement(track, participant: IParticipantItem) {
        let id = "";
        let local = track.isLocal();
        if (!local && track.getType() === "audio") {
            id = 'remoteAudio_' + participant.participantId
        } else if (track.getType() === "video") {
            id = local ? 'localVideo_container' : 'remoteVideo_' + participant.participantId;
        }
        const element = document.getElementById(id) as any;
        if (element) {
            track.detach(element);
            element.parentNode.removeChild(element);
        }
        if (local) {
            const username = document.getElementById(`participant_local_username`)
            if (username) username.style.visibility = "initial"
        } else {
            const username = document.getElementById(`participant_${participant.participantId}_username`)
            if (username) username.style.visibility = "initial"
        }
        if (participant.speaker) {
            const speakerEle = document.getElementById("speakerVideo") as any;
            if (speakerEle) {
                speakerEle.srcObject = null
            }
        }
    },

    /**
     * @name 设置语音icon
     * @param participantId 
     * @param muted 
     */
    setAudioMutedIcon(userdata: IParticipantItem, mode?: string) {
        const element = document.getElementById(`participant_${userdata.userId}_muted`)
        if (element) {
            const participantContainer = document.getElementById(userdata.local ? 'localVideoTileViewContainer' : `participant_${userdata.participantId}`)
            const elementSelf = document.getElementById(`self_participant_${userdata.userId}_muted`);
            if (userdata.muted) {
                element.style.color = '#e02020';
                if (element.firstElementChild) element.firstElementChild.className = 'iconfont icon-muted';
                if (participantContainer) {
                    participantContainer.style.border = "1px solid #000";
                    element.style.background = 'rgba(0, 0, 0, 0.5)'
                    if (elementSelf) elementSelf.style.background = 'rgba(0, 0, 0, 0.5)'
                }
                if (elementSelf) {
                    elementSelf.style.color = '#e02020';
                    if (elementSelf.firstElementChild) elementSelf.firstElementChild.className = 'iconfont icon-muted';
                }
            } else {
                element.style.color = '#fff';
                element.style.background = userdata.voiceIncentive ? "#0091FF" : 'rgba(0, 0, 0, 0.5)';
                if (participantContainer) participantContainer.style.border = userdata.voiceIncentive ? (mode === "pictureInPicture" ? "2px solid #0091FF" : '3px solid #0091FF') : "1px solid #000"
                if (element.firstElementChild) element.firstElementChild.className = 'iconfont icon-unmuted'
                if (elementSelf) {
                    elementSelf.style.color = '#fff';
                    elementSelf.style.background = userdata.voiceIncentive ? "#0091FF" : 'rgba(0, 0, 0, 0.5)';
                    if (elementSelf.firstElementChild) elementSelf.firstElementChild.className = 'iconfont icon-unmuted'
                }
            }
        }
    },

    /**
     * 
     * @name 设置用户名 
     */
    setDisplayName(userdata: IParticipantItem) {
        const participantId = userdata.participantId || userdata.userId
        const node = document.getElementById(`participant_${participantId}_username`);
        const text = (userdata.realName ? userdata.realName : (userdata._displayName || i18n.t('meet.stranger'))) + `(${userdata.translationLanguageText})`
        if (node) {
            node.innerText = text
        }
        const nodeSelf = document.getElementById(`self_participant_${participantId}_username`);
        if (nodeSelf) nodeSelf.innerText = text
        if (userdata.speaker) {
            const ele = document.getElementById(`participant_speaker_username`);
            if (ele) ele.innerText = text
        }
    },

    /**
   * 
   * @name 设置用户头像 
   */
    setAvatar(userdata: IParticipantItem) {
        const participantId = (userdata.local && !userdata.sharedUser) ? "local" : userdata.participantId;
        const node = document.getElementById(`participant_${participantId}_avatar`) as HTMLImageElement;
        if (node) {
            node.src = userdata.avatar;
            node.className = userdata.avatar ? "userAvatar" : "userAvatar1"
        }
        const nodeSelf = document.getElementById(`self_participant_${participantId}_avatar`) as HTMLImageElement;
        if (nodeSelf) {
            nodeSelf.src = userdata.avatar;
            nodeSelf.className = userdata.avatar ? "userAvatar" : "userAvatar1"
        }
    },

    /**
     * @name 切换主讲模式比例（右边放大按键）
     */
    // setSpeakerModeSizeProportion(size: "small" | "default") {
    //     const listEle = document.getElementsByClassName('videocontainer')
    //     Array.from(listEle).forEach((node: any) => {
    //         if (size === 'small') {
    //             node.style.width = '1.65rem';
    //             node.style.height = '0.93rem';
    //         } else {
    //             node.style.width = '3.2rem';
    //             node.style.height = '1.88rem';
    //         }
    //     })
    // },

    /**
     * @name 画廊/主讲模式切换
     */

    switchMode(mode: "gallery" | "speaker" | "pictureInPicture") {
        if (mode === 'gallery') {
            const container = document.getElementById('swiper-speaker')
            const filmstripRemoteVideosContainer = document.getElementById('filmstripRemoteVideosContainer') as HTMLElement;
            if (!filmstripRemoteVideosContainer) return

            if (container) {
                container.className = 'galley-container';
            }

            filmstripRemoteVideosContainer.className = 'galley-main';
            filmstripRemoteVideosContainer && Array.from(filmstripRemoteVideosContainer.childNodes).forEach(node => {
                (node as any).className = 'galley-participant-wrap';
                (node.firstChild as any).className = 'galley-participant-box';
                $(node).on('dblclick')
                $(node).off('click')
            })
            this.setGalleyModeStyle(filmstripRemoteVideosContainer.childNodes.length)
        } else if (mode === 'speaker') {
            this.deleteAllPictureLeaveUserOccupiedUI()
            const container = document.getElementById('swiper-speaker')
            const filmstripRemoteVideosContainer = document.getElementById('filmstripRemoteVideosContainer') as HTMLElement;
            if (!filmstripRemoteVideosContainer) return

            if (container) {
                container.className = 'swiper-container';
            }

            filmstripRemoteVideosContainer.className = 'swiper-wrapper';
            filmstripRemoteVideosContainer && Array.from(filmstripRemoteVideosContainer.childNodes).forEach((node: any) => {
                node.style.cssText = `display:block;`;
                node.className = 'swiper-slide';
                (node.firstChild as any).className = 'videocontainer';
                $(node).on('click')
                $(node).off('dblclick')
            })
        } else if (mode === 'pictureInPicture') {
            const container = document.getElementById('swiper-speaker')
            const filmstripRemoteVideosContainer = document.getElementById('filmstripRemoteVideosContainer') as HTMLElement;
            if (!filmstripRemoteVideosContainer) return

            if (container) container.className = 'galley-container';

            filmstripRemoteVideosContainer.className = 'galley-main';
            filmstripRemoteVideosContainer && Array.from(filmstripRemoteVideosContainer.childNodes).forEach((node: any) => {
                node.className = 'galley-participant-wrap';
                node.firstChild.className = 'galley-participant-box';
            })
            this.setGalleyModeStyle(filmstripRemoteVideosContainer.childNodes.length)
        }
    },
    /**
    * 
    * @param boxNum 获取画中画样式className 
    */
    getPictureClassName(index, template) {
        if (index === 0) {
            if ((template === 1 || template === 3)) {
                return "galley-participant-wrap mainPicture-warp left"
            } else {
                return "galley-participant-wrap mainPicture-warp right"
            }
        } else if (index === 1) {
            if (template === 1 || template === 3) {
                return "galley-participant-wrap picture1-warp right"
            } else {
                return "galley-participant-wrap picture1-warp left"
            }
        } else if (index === 2) {
            if (template === 1 || template === 3) {
                return "galley-participant-wrap picture2-warp right"
            } else {
                return "galley-participant-wrap picture2-warp left"
            }
        }
    },
    /**
   * 
   * @param boxNum 设置更新画中画样式 
   */
    setPictureInPictureStyle(participants: IParticipantItem[], template: 1 | 2 | 3 | 4) {
        const list = document.getElementsByClassName("galley-participant-box")
        let index;
        try {
            Array.from(list).forEach((element: any) => {
                if (element.id === "localVideoTileViewContainer") {
                    index = participants.findIndex(x => x.local && !x.sharedUser)
                    element.parentElement.style.cssText = `display:block;`
                    element.parentElement.className = this.getPictureClassName(index, template)
                } else if (element.id.indexOf("occupied") > -1) {//占位的
                } else {
                    index = participants.findIndex(x => x.userId && element.id.indexOf(x.userId) > -1)
                    if (index > -1) {
                        element.parentElement.style.cssText = "display:block"
                        element.parentElement.className = this.getPictureClassName(index, template)
                    } else {
                        element.parentElement.className = "galley-participant-wrap"
                        element.parentElement.style.cssText = "display:none"
                    }
                }
            })
        } catch (e) {
            console.log(e)
        }
    },

    /**
      * 
      * @param boxNum 主画面和小窗切换 
      */
    switchMainWindowAndWindow(participant: IParticipantItem) {
        let node;
        let node2;
        if (participant.local && !participant.sharedUser) { // 本地用户
            node = document.getElementById("localVideoTileViewContainer")
        } else {
            node = document.getElementById(`participant_${participant.userId}`)
        }

        node2 = document.getElementById(`participant_${participant.switchedId}`)
        if (!node2) {
            node2 = document.getElementById("localVideoTileViewContainer")
        }
        let nodeClass = node.parentElement.className
        let node2Class = node2.parentElement.className
        if (node2 && node) {
            node2.parentElement.className = nodeClass
        }
        if (node) {
            node.parentElement.className = node2Class
        }
    },
    /**
   * @param name 离开的画中画需要占位 
   */
    pictureLeaveUserOccupiedUI(index, template) {
        const div = document.createElement("div")
        if (index === 0) {// 主画面
            const main = document.getElementById("swiper-speaker")
            div.className = "picture_occupied"
            div.id = "picture_main_occupied"
            div.innerText = i18n.t("message.participantsHaveExited")
            main && main.appendChild(div)
        } else if (index === 1) {// 画中画1
            const parent = document.getElementById("filmstripRemoteVideosContainer")
            div.className = `${this.getPictureClassName(index, template)} small_picture_occupied galley-participant-box`
            div.id = "picture1_occupied"
            div.innerText = i18n.t("message.participantsHaveExited")
            parent && parent.appendChild(div)
        } else if (index === 2) {// 画中画2
            const parent = document.getElementById("filmstripRemoteVideosContainer")
            div.className = `${this.getPictureClassName(index, template)} small_picture_occupied galley-participant-box`
            div.id = "picture2_occupied"
            div.innerHTML = i18n.t("message.participantsHaveExited")
            parent && parent.appendChild(div)
        }
    },
    /**
    * @param name 删除画中画需要占位 
    */
    deletePictureLeaveUserOccupiedUI(index) {
        // 有新用户更新时删除占位
        if (index === 0) {// 主画面
            const node = document.getElementById('picture_main_occupied')
            node && node.parentNode && node.parentNode.removeChild(node)
        } else if (index === 1) {// 画中画1
            const node = document.getElementById('picture1_occupied')
            node && node.parentNode && node.parentNode.removeChild(node)
        } else if (index === 2) {// 画中画2
            const node = document.getElementById('picture2_occupied')
            node && node.parentNode && node.parentNode.removeChild(node)
        }
    },
    /**
  * @param name 删除所有画中画需要占位 
  */
    deleteAllPictureLeaveUserOccupiedUI() {
        const node = document.getElementById('picture_main_occupied')// 主画面
        node && node.parentNode && node.parentNode.removeChild(node)
        const node1 = document.getElementById('picture1_occupied')
        node1 && node1.parentNode && node1.parentNode.removeChild(node1)// 画中画1
        const node2 = document.getElementById('picture2_occupied')
        node2 && node2.parentNode && node2.parentNode.removeChild(node2)// 画中画2
    },
    /**
     * @param boxNum 开会人数 
     */
    setGalleyModeStyle(boxNum: number) {
        const node = document.getElementsByClassName('galley-participant-wrap')
        const filmstripRemoteVideosContainer = document.getElementById('filmstripRemoteVideosContainer')
        if (node && filmstripRemoteVideosContainer)
            Array.from(node).forEach((element: any) => {
                if (boxNum === 1) {
                    element.style.width = '100%'
                    return
                }
                if (window.innerWidth / window.innerHeight < 1.6) {// 4:3 或 3:2屏幕
                    // 3 * 3
                    // if (boxNum >= 3 && boxNum <= 9) {
                    //     filmstripRemoteVideosContainer.style.alignContent = "center"
                    // } else {
                    //     filmstripRemoteVideosContainer.style.alignContent = "initial"
                    // }
                    // if (boxNum >= 2 && boxNum <= 3) {
                    //     element.style.width = '66%'
                    // } else if (boxNum >= 4 && boxNum <= 5) {
                    //     element.style.width = '50%';
                    // } else {
                    //     element.style.width = '33%'
                    // }
                    if (boxNum >= 3 && boxNum <= 12) {
                        filmstripRemoteVideosContainer.style.alignContent = "center"
                    } else {
                        filmstripRemoteVideosContainer.style.alignContent = "initial"
                    }
                    if (boxNum >= 2 && boxNum <= 3) {
                        element.style.width = '66%'
                    } else if (boxNum >= 4 && boxNum <= 5) {
                        element.style.width = '50%';
                    } else if (boxNum === 6) {
                        element.style.width = '44%'
                    } else if (boxNum >= 7 && boxNum <= 12) {
                        element.style.width = '33%'
                    } else if (boxNum >= 13) {
                        element.style.width = '25%'
                    }
                } else { // 16:9
                    if (boxNum <= 16) {
                        filmstripRemoteVideosContainer.style.alignContent = "center"
                    } else {
                        filmstripRemoteVideosContainer.style.alignContent = "initial"
                    }
                    // 3:3
                    // if (boxNum >= 2 && boxNum <= 4) {
                    //     element.style.width = '50%'
                    // } else {
                    //     element.style.width = '33%'
                    //     if (boxNum >= 5 && boxNum <= 9) {
                    //         filmstripRemoteVideosContainer.style.alignContent = "center"
                    //     }
                    // }
                    if (boxNum >= 2 && boxNum <= 4) {
                        element.style.width = '50%'
                    } else if (boxNum >= 5 && boxNum <= 9) {
                        element.style.width = '33%'
                        filmstripRemoteVideosContainer.style.alignContent = "center"
                    } else if (boxNum >= 10) {
                        element.style.width = '25%'
                    }
                }
            })

    },

    /**
     * @name 获取audiolevel容器
     */
    audioLevelIndicator(lvl, element) {
        const audioLevel = Math.min(lvl * 1.2, 1);
        const stretchedAudioLevel = AUDIO_LEVEL_DOTS * audioLevel;

        for (let i = 0; i < AUDIO_LEVEL_DOTS; i++) {
            const distanceFromCenter = CENTER_DOT_INDEX - i;
            const audioLevelFromCenter
                = stretchedAudioLevel - Math.abs(distanceFromCenter);
            const cappedOpacity = Math.min(
                1, Math.max(0, audioLevelFromCenter));
            if (distanceFromCenter === 0) {
                const ele = element.getElementsByClassName('audiodot-middle') as any
                ele[0].style.opacity = cappedOpacity + ""
            } else if (distanceFromCenter < 0) {
                const ele = element.getElementsByClassName('audiodot-top') as any
                Array.from(ele).forEach((element: any) => {
                    element.style.opacity = cappedOpacity + ""
                });
            } else {
                const ele = element.getElementsByClassName('audiodot-bottom') as any
                Array.from(ele).forEach((element: any) => {
                    element.style.opacity = cappedOpacity + ""
                });
            }
        }
    },

    /**
     * @name 设置音量检测样式
     */
    setAudioLevel(id, lvl) {
        const element = document.getElementById(`participant_${id}`);
        if (element) {
            const container = element?.getElementsByClassName('audioindicator-container');
            if (container?.length) {
                this.audioLevelIndicator(lvl, container[0])
            }
            const e = document.getElementById(`${id}-speaker-audioindicator-container`)
            if (e) {
                this.audioLevelIndicator(lvl, e)
            }
            this.updateLargeVideoAudioLevel(id, lvl)
        } else { // 本地
            const element = document.getElementById(`localVideoTileViewContainer`)
            const container = element?.getElementsByClassName('audioindicator-container');
            if (container?.length) {
                this.audioLevelIndicator(lvl, container[0])
            }
            const e = document.getElementById('null-speaker-audioindicator-container')
            if (e) {
                this.audioLevelIndicator(lvl, e)
            }
            this.updateLargeVideoAudioLevel(null, lvl)
        }
    },

    /**
   * @name 更新音视频的音频级别
   * @param audioLevel 设置的新音频级别
   */
    updateLargeVideoAudioLevel(id, audioLevel) {
        const element = document.getElementById(id + 'speakerContainer')
        if (element) {
            let level = parseFloat(audioLevel);
            level = isNaN(level) ? 0 : level;

            let shadowElement = element.getElementsByClassName('dynamic-shadow') as any;

            if (shadowElement && shadowElement.length > 0) {
                shadowElement = shadowElement[0];
            }
            shadowElement.style.boxShadow = this._updateLargeVideoShadow(level);
        }
    },

    _updateLargeVideoShadow(level) {
        const scale = 2;
        const int: any = {
            level: level > 0.15 ? 20 : 0,
            color: 'rgba(255,255,255,0.4)'
        };

        const ext: any = {
            level: parseFloat(
                ((int.level * scale * level) + int.level).toFixed(0)),
            color: 'rgba(255,255,255,0.2)'
        };

        int.blur = int.level ? 2 : 0;

        ext.blur = ext.level ? 6 : 0;

        return [
            `0 0 ${int.blur}px ${int.level}px ${int.color}`,
            `0 0 ${ext.blur}px ${ext.level}px ${ext.color}`
        ].join(', ');
    },

    /**
     * 在上次N更改事件
     * @param endpointsLeavingLastN列表当前离开最后的N
     *  @param endpointsEnteringLastN当前进入最后N的列表
     */
    endpointsChangedNum: 0,
    onLastNEndpointsChanged(endpointsLeavingLastN, endpointsEnteringLastN, participantIds: IParticipantItem[]) {
        if (endpointsLeavingLastN) {
            endpointsLeavingLastN.forEach((id) => {
                const participant = participantIds.filter(participant => participant.participantId === id)
                if (participant.length) {
                    this._updateRemoteVideo(participant[0], false)
                }
            });
            // 解决两个参数都是空数组的情况
            if (endpointsLeavingLastN.length === 0 && endpointsEnteringLastN && endpointsEnteringLastN.length === 0) {
                participantIds.forEach(participant => {
                    if (!participant.local) {
                        this._updateRemoteVideo(participant, false)
                    }
                })
                return
            }
        }

        if (endpointsEnteringLastN && endpointsEnteringLastN.length) {
            if (this.endpointsChangedNum === 0) {
                participantIds.forEach(participant => {
                    if (!participant.local) {
                        if (endpointsEnteringLastN.indexOf(participant.participantId) > -1) { // 显示流
                            this._updateRemoteVideo(participant, true)
                        } else if (endpointsLeavingLastN && endpointsLeavingLastN.indexOf(participant.participantId) === -1) {
                            this._updateRemoteVideo(participant, false)
                        }
                    }
                })
            } else {
                endpointsEnteringLastN.forEach((id) => {
                    const participant = participantIds.filter(participant => participant.participantId === id)
                    if (participant.length) {
                        this._updateRemoteVideo(participant[0], true)
                    }
                });
            }
        }
        this.endpointsChangedNum += 1
    },

    _updateRemoteVideo(participant: IParticipantItem, force) {
        if (force) { // 显示流
            const target = participant.list.filter(x => x.mediaType === "video")
            if (target.length) {
                this.addVideoOrAudioElement(target[0].jitsiTrack, participant)
            }
        } else { // 显示头像
            const target = participant.list.filter(x => x.mediaType === "video")
            if (target.length) {
                this.removeVideoOrAudioElement(target[0].jitsiTrack, participant)
            }
        }
    },

    // 录屏/录音页面隐私提醒
    mediaRecorderPromptUI(params: IRecorderCommand) {
        if (params.status) {
            const eleBefore = document.getElementById('media_recorder_prompt')
            eleBefore && document.body.removeChild(eleBefore)
            const ele = document.createElement('div')
            ele.id = 'media_recorder_prompt'
            ele.className = "media_recorder_prompt_box";

            let typeText = ""
            switch (params.type) {
                case "video":
                    typeText = i18n.t('meet.videoR')
                    break;
                case "audio":
                    typeText = i18n.t('meet.audioR')
                    break;
                case "all":
                    typeText = `${i18n.t('meet.videoR')}/${i18n.t('meet.audioR')}`
                default:
                    break;
            }
            if (params.userType === "moderator") {
                ele.innerHTML = `<div class="media_recorder_prompt"><div></div></div><span>${i18n.t('meet.youAre', { text: typeText })}</span>`
            } else {
                ele.innerHTML = `<div class="media_recorder_prompt"><div></div></div><span>${i18n.t('meet.moderatorSomething', { text: typeText })}</span>`
            }
            document.body.appendChild(ele)
        } else {
            const ele = document.getElementById('media_recorder_prompt')
            ele && document.body.removeChild(ele)
        }

    },
    // 静音某个用户（把播放的audio关闭）
    pauseOrPlayUserAuido(bool: boolean, id: string) {
        const element = document.getElementById(`remoteAudio_${id}`) as HTMLVideoElement | HTMLAudioElement | null;
        if (element) {
            if (bool) {// 播放
                if (element.paused) {
                    element.play()
                }
            } else {// 暂停
                if (!element.paused) {
                    element.pause()
                }
            }
        }
    },

    // 添加续费按钮
    createRenewButtonElement() {
        const that = this;
        const div = document.createElement('div')
        div.className = "meet-renew-handle";
        div.id = 'meet-renew-handle';
        div.innerHTML = `${i18n.t('renewal')}`
        div.onmouseover = function () {
            const div1 = document.createElement('div')
            div1.className = "meet-renew-handle-info";
            div1.id = 'meet-renew-handle-info';
            div1.innerHTML = `${i18n.t('LowServiceBalance2')}`
            document.body.appendChild(div1)
        }
        div.onmouseleave = function () {
            const ele = document.getElementById('meet-renew-handle-info')
            ele && document.body.removeChild(ele)
        }
        div.onclick = function () {
            toPurchaseEnhancedService()
        }
        document.body.appendChild(div)
    },

    // 移除续费按钮
    removeRenewButtonElement() {
        const ele = document.getElementById('meet-renew-handle')
        if (ele) {
            document.body.removeChild(ele)
        }
    },

    // 添加返回白板按钮
    createReturnWhiteboardButton(callback) {
        const div = document.createElement('div')
        div.className = "meet-whiteboard-handle";
        div.id = 'meet-whiteboard-handle';
        div.innerHTML = `<img src=${require("static/images/meet/icon-whiteboard-a.png")} alt="" />`
        div.onclick = function () {
            callback()
        }
        document.body.appendChild(div)
    },

    // 移除返回白板按钮
    removeReturnWhiteboardButton() {
        const ele = document.getElementById('meet-whiteboard-handle')
        if (ele) {
            document.body.removeChild(ele)
        }
    },

    // 添加白板操作面板按钮
    createWhiteboardButton(data, callback: Function) {
        const div = document.createElement('div')
        div.className = "whiteboard-button-handle";
        div.id = 'whiteboard-button-handle';
        div.innerHTML = `
             <span id="name">${i18n.t("meet.whiteboardOfName", { name: data.username })}</span>
            <img id="zoomOut" src="${require("static/images/meet/icon-whiteboard.png")}"/>
            <img id="close" src="${require("static/images/meet/icon-close.png")}"/>
            <img id="jiantou" src="${require("static/images/meet/icon-jiantou-bold.png")}"/>
       `
        document.body.appendChild(div)
        // 最小化白板
        const zoomOut = document.getElementById('zoomOut') as any
        zoomOut.onclick = function () {
            callback(1)
        }

        // 关闭白板
        const close = document.getElementById('close') as any
        close.onclick = function () {
            if (close.style.opacity === "1") {
                callback(2)
            }
        }

        let bool = false
        const jiantou = document.getElementById('jiantou') as any
        jiantou.onclick = function () {
            if (bool) {
                jiantou.className = ""
                const ele = document.getElementById("whiteboard-button-handle")
                if (ele) {
                    ele.className = "whiteboard-button-handle";
                    setTimeout(() => {
                        const zoomOut = document.getElementById('zoomOut')
                        if (zoomOut) {
                            zoomOut.style.display = ""
                        }
                        const close = document.getElementById('close')
                        if (close) {
                            close.style.display = ""
                        }
                        const name = document.getElementById('name')
                        if (name) {
                            name.style.display = ""
                        }
                    }, 200)
                }
                bool = false
            } else {
                jiantou.className = "jiantou"
                const ele = document.getElementById("whiteboard-button-handle")
                if (ele) {
                    ele.className = "whiteboard-button-handle whiteboard-button-handle-active";
                    const zoomOut = document.getElementById('zoomOut')
                    if (zoomOut) {
                        zoomOut.style.display = "none"
                    }
                    const close = document.getElementById('close')
                    if (close) {
                        close.style.display = "none"
                    }
                    const name = document.getElementById('name')
                    if (name) {
                        name.style.display = "none"
                    }
                }
                bool = true
            }
        }

        this.disableCloseWhiteboardButton(data)
    },

    // 置灰白板关闭按钮
    disableCloseWhiteboardButton(data) {
        const closeBtn = document.getElementById('close')
        if (!data.isWhiteboardCreator && !data.isLocalModerator) {
            if (closeBtn) {
                closeBtn.style.opacity = "0.5"
            }
        } else {
            if (closeBtn) {
                closeBtn.style.opacity = "1"
            }
        }
    },

    // 移除白板操作面板按钮
    removeWhiteboardButton() {
        const ele = document.getElementById('whiteboard-button-handle')
        if (ele) {
            document.body.removeChild(ele)
        }
    },

    // 添加批注按钮
    createAnnotateButton(callback) {
        const div = document.createElement('div')
        div.className = "meet-annotate-handle";
        div.id = 'meet-annotate-handle';
        div.innerHTML = `${i18n.t('annotate')}`
        div.onclick = function () {
            callback()
        }
        document.body.appendChild(div)
    },

    // 移除续费按钮
    removeAnnotateButton() {
        const ele = document.getElementById('meet-annotate-handle')
        if (ele) {
            document.body.removeChild(ele)
        }
    },

}