
import React from "react";
import { ISummaryMessageItem } from "models/common"
import { Input } from "antd";
import i18n from "i18n";
import { Menu, Dropdown, Checkbox, Button } from "antd";
import videolayout from "utils/lib-jitsi-meet/videolayout";
import summary from "utils/lib-jitsi-meet/summary";
import "./index.css"

export default class extends React.Component<any, {
    summaryMessage: ISummaryMessageItem[];
    searchList: ISummaryMessageItem[];
    keywords: string;
    horn: boolean;
    checkedList: string[]
}> {

    constructor(props) {
        super(props)

        this.state = {
            summaryMessage: [],
            searchList: [],
            keywords: "",
            horn: false,
            checkedList: []
        }
    }

    public scrolling = false;

    public componentDidMount() {
        this.setState({ summaryMessage: window.opener.window.$summaryMessage }, () => {
            const timer = setTimeout(() => {
                window.opener.window.$speechServer.addEventListener('message', (receive) => {
                    let data = JSON.parse(receive.data)
                    this.onMessageSpeechServer(data)
                })
                this.scrollTop()
                clearTimeout(timer)
            }, 200)
        })

        const chat_contain = document.getElementById('summary_component');
        if (chat_contain) {
            let t1 = 0;
            let t2 = 0;
            let is = false;
            chat_contain.addEventListener('scroll', (e) => {
                t1 = chat_contain.scrollTop
                if (t2 <= t1) {//下滚
                    is = true
                } else {//上滚
                    this.scrolling = true
                }
                setTimeout(() => {
                    t2 = t1;
                    if (is && t2 === t1) {
                        this.scrolling = false
                        is = false
                    }
                }, 0);
            })
        }
    }

    // 语音识别消息
    public onMessageSpeechServer(data) {
        switch (data.type) {
            case 'speechInfo':
                // 用户ID
                let id = data.data.id;
                // 用户名称
                let displayName = data.data.speaker;
                // 识别结果
                let message = data.data.message;
                // 识别时间
                let time = data.data.time;
                // 如果会议中有用户开启了翻译，这里为翻译结果
                let tr = data.data.origin;
                // 是否最终结果，如果开启了realtime，会返回final为false的中间结果
                let final = data.data.final;
                // 结果序号，如果开启了realtime，中间结果和最终结果的序号一致
                let mid = data.data.mid;
                if (message) {
                    if (this.state.summaryMessage.length > 0 && this.state.summaryMessage[this.state.summaryMessage.length - 1].id === id) { // 同一人连续说话
                        const ele = document.getElementById(mid + "")
                        if (final) {
                            if (ele) {
                                const target = this.state.summaryMessage[this.state.summaryMessage.length - 1];
                                const index = target.message.indexOf(`<span style="color:rgba(255, 177, 0, 1)" id="${mid}">`)
                                target.final = true;
                                if (index !== -1) {
                                    const str = target.message.slice(index, target.message.indexOf(`</span>`, index) + 7)
                                    target.message = target.message.replace(str, '')
                                    target.message += message;
                                } else {
                                    if (target.message.indexOf(message) === -1) {
                                        target.message += message;
                                    }
                                }
                            } else {
                                console.log(2)
                            }
                        } else {
                            setTimeout(() => {
                                const ele = document.getElementById(mid + "")
                                if (ele) {
                                    console.log(3, message)
                                    ele.innerHTML = message;
                                } else {
                                    this.state.summaryMessage[this.state.summaryMessage.length - 1].message += `<span style="color:rgba(255, 177, 0, 1)" id="${mid}">${message}</span>`;
                                }
                            }, 200)
                        }
                    } else {
                        if (final) {
                            if (this.state.summaryMessage.length === 0) {// 第一句且说完了
                                this.state.summaryMessage.push({ id, displayName, message, time, tr, final, mid })
                            } else {
                                const ele = document.getElementById(mid + "")
                                if (ele) {
                                    const target = this.state.summaryMessage.filter(x => x.message.indexOf(`<span style="color:rgba(255, 177, 0, 1)" id="${mid}">`) !== -1)
                                    if (target.length) {
                                        const index = target[0].message.indexOf(`<span style="color:rgba(255, 177, 0, 1)" id="${mid}">`)
                                        target[0].final = true;
                                        if (index !== -1) {
                                            const str = target[0].message.slice(index, target[0].message.indexOf(`</span>`, index) + 7)
                                            target[0].message = target[0].message.replace(str, '')
                                        }
                                    }
                                }
                                if (this.state.summaryMessage.filter(x => x.id === id).length) {
                                    //@ts-ignore
                                    this.state.summaryMessage = this.state.summaryMessage.filter(x => x.mid !== mid)
                                }
                                this.state.summaryMessage.push({ id, displayName, message, time, tr, final, mid })
                            }
                        } else {
                            const ele = document.getElementById(mid + "")
                            if (ele) {
                                const target = this.state.summaryMessage.filter(x => x.message.indexOf(`<span style="color:rgba(255, 177, 0, 1)" id="${mid}">`) !== -1)
                                if (target.length) {
                                    const index = target[0].message.indexOf(`<span style="color:rgba(255, 177, 0, 1)" id="${mid}">`)
                                    target[0].final = true;
                                    if (index !== -1) {
                                        const str = target[0].message.slice(index, target[0].message.indexOf(`</span>`, index) + 7)
                                        target[0].message = target[0].message.replace(str, '')
                                    }
                                }
                            }

                            if (this.state.summaryMessage.filter(x => x.id === id).length) {
                                //@ts-ignore
                                this.state.summaryMessage = this.state.summaryMessage.filter(x => x.mid !== mid)
                            }
                            this.state.summaryMessage.push({ id, displayName, message: `<span style="color:rgba(255, 177, 0, 1)" id="${mid}">${message}</span>`, time, tr, final, mid })
                        }
                    }
                    this.setState({ summaryMessage: this.state.summaryMessage }, () => {
                        this.scrollTop()
                    })
                }
                break;
            default:
                break;
        }
    }

    public scrollTop() {
        const ele = document.getElementById("summary_component");
        if (!this.scrolling) {
            if (ele) {
                ele.scrollTop = ele.scrollHeight;
            }
        }
    }

    public filterFun(str: string) {
        str = str.replaceAll("</span>", "")
        str = str.replace(str.slice(str.lastIndexOf('<span'), str.lastIndexOf('>') + 1), "")
        if (str.indexOf('<span') > -1) {
            return this.filterFun(str)
        } else {
            return str
        }
    }

    public fuzzyQuery(list: any[], keyWord) {
        if (keyWord.trim() === "") {
            return [];
        }
        const arr: any[] = [];
        for (let i = 0; i < list.length; i++) {
            // 原文
            let bool = false;
            const text = this.filterFun(list[i].message)
            if (text.split(keyWord).length > 1) {
                list[i].message = text.replaceAll(keyWord, `<span style="color:rgba(255, 177, 0, 1)">${keyWord}</span>`);
                bool = true
            }
            // 翻译
            if (list[i].hasOwnProperty("tr")) {
                if (list[i].tr.split(keyWord).length > 1) {
                    list[i].tr = list[i].tr.replaceAll(keyWord, `<span style="color:rgba(255, 177, 0, 1)">${keyWord}</span>`);
                    bool = true
                }
            }
            if (bool) {
                arr.push(list[i]);
            }
        }
        return arr;
    }

    public render() {
        const lineNum = 2
        return <div className="summary_modal_component">

            <div className="nav">
                <Input placeholder={i18n.t("meet.pleaseEnter")} className="input" onChange={(e) => {
                    const arr = this.fuzzyQuery(JSON.parse(JSON.stringify(this.state.summaryMessage)), e.currentTarget.value)
                    this.setState({ searchList: arr, keywords: e.currentTarget.value.trim() })
                }} />
                {/* {
                    this.state.horn ?
                        <Dropdown trigger={["hover"]} overlay={<Menu>
                            {this.languageRender()}
                        </Menu>} placement="bottomCenter" arrow>
                            <img className="icon-up" style={{ marginRight: "0.2rem", transform: 'rotate(0deg)' }} onClick={() => this.hornClose()}
                                src={this.state.horn ? require("static/images/meet/icon-laba.png") : require("static/images/meet/icon-laba-close.png")} alt=""
                            />
                        </Dropdown>
                        : <Dropdown trigger={["click", "hover"]} overlay={<Menu>
                            {this.languageRender()}
                        </Menu>} placement="bottomCenter" arrow>
                            <img className="icon-up" style={{ marginRight: "0.2rem", transform: 'rotate(0deg)' }}
                                src={this.state.horn ? require("static/images/meet/icon-laba.png") : require("static/images/meet/icon-laba-close.png")} alt=""
                            />
                        </Dropdown>
                } */}
                <img className="icon-up" src={require("static/images/meet/icon-up.png")} onClick={() => {
                    window.close()
                }} />
            </div>

            <div className="list" id="summary_component">
                {
                    !this.state.searchList.length && this.state.keywords === "" && this.state.summaryMessage.map((ele, index) => {
                        return <div key={ele.id + index} className="msg_item">
                            <div className="parent">
                                <div >
                                    <span className="userName">{ele.displayName}：</span>
                                    <span className="mesage" style={{
                                        color: !ele.final ? "rgba(250, 100, 0, 1)" : (this.state.summaryMessage.length >= lineNum ? (index === (lineNum - 1) ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.7)') : ""),
                                    }} dangerouslySetInnerHTML={{ __html: ele.message }}></span>
                                </div>
                                {/* {ele.tr && <div style={{ fontSize: '13px', color: !ele.final ? "rgba(250, 100, 0, 0.7)" : 'rgba(255,255,255,0.7)' }}>
                                    <span className="userName"></span>
                                    <span className="mesage"> {ele.tr}</span>
                                </div>} */}
                            </div>
                        </div>
                    })
                }
                {
                    this.state.searchList.map((ele, index) => {
                        return <div key={ele.id + index} className="msg_item">
                            <div className="parent">
                                <div >
                                    <span className="userName">{ele.displayName}：</span>
                                    <span className="mesage" dangerouslySetInnerHTML={{ __html: ele.message }}></span>
                                </div>
                                {/* {ele.tr && <div style={{
                                    fontSize: '13px',
                                }}>
                                    <span className="userName"></span>
                                    <span className="mesage" dangerouslySetInnerHTML={{ __html: ele.tr }}></span>
                                </div>
                                } */}
                            </div>
                        </div>
                    })
                }
            </div>

            {
                this.scrolling && <img className="icon-arrow" src={require("static/images/meet/icon-bottom-arrow.png")} alt="" onClick={() => {
                    this.scrolling = false;
                    this.scrollTop()
                }} />
            }
        </div>
    }

    public componentWillUnmount() {
        window.close()
    }

    public languageRender() {

        return <div className='summay-languageRender'>
            {
                window.opener.window.$participants && window.opener.window.$participants.map(ele => {
                    if (!ele.local) {
                        return <div key={ele.userId} onChange={() => {
                            const index = this.state.checkedList.findIndex(x => x === ele.businessUserId)
                            if (index !== -1) {
                                this.state.checkedList.splice(index, 1)
                            } else {
                                this.state.checkedList.push(ele.businessUserId)
                            }
                            this.setState({ checkedList: this.state.checkedList })
                        }}>
                            <Checkbox checked={this.state.checkedList.indexOf(ele.businessUserId) > -1 ? true : false} style={{ width: "100%" }}>{ele.realName || ele._displayName}</Checkbox>
                        </div>
                    }
                })
            }
            <div style={{ textAlign: "center" }}>
                <Button onClick={() => this.horn()}>{i18n.t("meet.determine")}</Button>
            </div>
        </div>
    }

    public horn() { // 文本朗读
        if (!this.state.checkedList.length) {
            this.hornClose()
            return
        }
        const data = {}
        this.setState({ horn: true }, () => {
            this.state.checkedList.forEach(element => {
                if (window.opener.window.$participants) {
                    const target = window.opener.window.$participants.filter(x => x.businessUserId === element)
                    if (target.length) {
                        videolayout.pauseOrPlayUserAuido(!this.state.horn, target[0].participantId || "")
                    }
                }
            });
            if (this.state.horn) {
                this.state.checkedList.forEach(x => {
                    data[x] = "male"
                })
            }
            console.log(summary)
            console.log(window.opener.window)
            // window.opener.window.$speechServer &&  window.opener.window.$speechServer.send(JSON.stringify({
            //     type: 'startSpeech',
            //     locale: this.params.locale,
            //     realtime: this.params.realtime,
            //     speaker: data
            // }));
            // summary.openReadingAloud(this.state.horn, data)
            if (!this.state.horn) {
                this.setState({ checkedList: [] })
            }
        })
    }

    public hornClose() {
        this.setState({ horn: false })
        summary.openReadingAloud(this.state.horn, "")
        this.setState({ checkedList: [] })
    }

}