import * as user from "./user";
import * as meeting from "./meeting";
import * as summary from "./summary";
import * as waitingRoom from "./waitingRoom";
import * as notice from "./notice";
import * as common from "./common";
import * as addressList from "./addressList";
import * as whiteboard from "./whiteboard";

export default {
    user,
    meeting,
    summary,
    waitingRoom,
    notice,
    common,
    addressList,
    whiteboard
}