import React from 'react';
import ReactDOM from 'react-dom';
import Pages from './pages/index';
import '../src/styles/index.css';
import '../src/styles/videolayout.scss';
import "antd/dist/antd.css";
// import "./utils/moment_zn";
import { Provider } from "react-redux";
import { reducers } from "./utils/redux";
import { createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { PersistGate } from "redux-persist/lib/integration/react";

const myPersistReducer = persistReducer({
    key: "root",
    storage,
    stateReconciler: autoMergeLevel2
}, reducers)

const store = createStore(myPersistReducer, {}, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            preLoading: true,
        };
    }

    render() {
        return <Provider store={store}>
            <PersistGate persistor={persistStore(store)}>
                <Pages />
            </PersistGate>
        </Provider>;
    }
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
