
import React from "react";
import message from "antd/lib/message"
import i18n from "i18n";
import { Radio } from "antd"
import { IMeetingItem, IParticipantItem } from "models/meet";
import { IRecorderCommand } from "models/common";
import { IUser } from "models/user";
import { startRecord, startRecordAudio } from "utils/lib-jitsi-meet/devices";
import videoLayout from "utils/lib-jitsi-meet/videolayout";
import { connect } from "react-redux";
import { setMeetingRecordingData } from "reduxs/actions/meet";
import "./index.less";

interface DispatchProps {
    user: IUser
    setMeetingRecordingData: (data) => void
}
class Index extends React.Component<{
    participants: IParticipantItem[]
    meetingData: IMeetingItem;
    disableCloudRecording: boolean;
    onClose: () => void;
    sendMediaRecorderCommand: (params: IRecorderCommand) => void;
    websocketCloudRecording: (recordingMethod: 1 | 2) => void
} & DispatchProps, {
    recordingMethod: 1 | 2; // 1是视频2仅音频
    storageLocation: 1 | 2;// 1本地2云端
}> {

    constructor(props) {
        super(props)
        this.state = {
            recordingMethod: 1,
            storageLocation: 1
        }
    }

    public render() {
        const { recordingMethod, storageLocation } = this.state
        return <div className="meetingRecording_component" >
            <div className="main" >
                <div className="container_head">
                    <span>{i18n.t('meet.meetingRecording')}</span>
                    <img src={require('static/images/meet/icon-guanbi.png')} style={{ cursor: "pointer" }} alt="" onClick={() => this.props.onClose()} />
                </div>
                <div className='container_main'>
                    <div className="formItem">
                        <div className='label'>{i18n.t('meet.recordingMethod')}</div>
                        <div className='inputWrapper' style={{ justifyContent: "space-between" }}>
                            <Radio checked={recordingMethod === 1 ? true : false} onClick={() => this.recordingMethod(1)}>{i18n.t('meet.recordVideo')}</Radio>
                            <Radio checked={recordingMethod === 2 ? true : false} onClick={() => this.recordingMethod(2)}>{i18n.t('meet.recordAudioOnly')}</Radio>
                        </div>
                    </div>
                    <div className="formItem" style={{ alignItems: "baseline" }}>
                        <div className='label'>{i18n.t('meet.storageLocation')} </div>
                        <div className='inputWrapper' style={{ flexDirection: "column" }}>
                            <div className="item">
                                <Radio checked={storageLocation === 1 ? true : false} onClick={() => this.storageLocation(1)}>{i18n.t('meet.saveLocally')}</Radio>
                                <span className="mes">（ {i18n.t("message.saveLocallyMes")} ）</span>
                            </div>
                            <div className="item">
                                <Radio disabled={this.props.disableCloudRecording} checked={storageLocation === 2 ? true : false} onClick={() => this.storageLocation(2)}>{i18n.t('meet.cloudStorage')}</Radio>
                                <span className="mes">（ {i18n.t("message.cloudStorageMes")} ）</span>
                            </div>
                        </div>
                    </div>
                    {!this.props.meetingData.meetServiceId && <div className="meetingRecordMes">
                        <img src={require("static/images/icon-exclamation-blue.png")} alt="" />
                        {i18n.t("message.meetingRecordMes")}
                    </div>}
                    <div className="btns">
                        <div className="btn" onClick={this.cancel.bind(this)}>{i18n.t("cancel")}</div>
                        <div className="btn" onClick={this.startRecording.bind(this)}>{i18n.t("meet.startRecording")}</div>
                    </div>
                </div>
            </div>
        </div>
    }

    // 录制方式
    public recordingMethod(type) {
        this.setState({ recordingMethod: type })

    }
    // 储存位置
    public storageLocation(type) {
        this.setState({ storageLocation: type })
    }
    // 开始录制
    public async startRecording() {
        const { recordingMethod, storageLocation } = this.state;
        if (storageLocation === 1) { // 本地保存
            // const isSafariBrowser = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
            // if (isSafariBrowser) {
            //     message.info(i18n.t('meet.onlySupportsChromeBrowser'))
            //     return
            // }
            if (recordingMethod === 1) { // 录制视频
                startRecord(this.props.participants, this.props.meetingData.theme || "",
                    () => {
                        this.startRecordingUi()
                    }, () => {
                        this.endRecordingUi()
                    })
            } else { // 仅录制音频
                startRecordAudio(this.props.participants, this.props.meetingData.theme || "",
                    () => {
                        this.startRecordingUi()
                    })
            }
        } else { // 云端录制
            this.props.websocketCloudRecording(recordingMethod)
        }
        this.props.onClose()
    }

    // 开始录制UI
    public startRecordingUi() {
        const { recordingMethod, storageLocation } = this.state;
        this.props.setMeetingRecordingData({
            open: true,
            recordingMethod, // 录制方式
            storageType: storageLocation,// 录制类型
            socket: this.cloudRecordingSocket,
        })
        this.props.sendMediaRecorderCommand({
            status: true,
            userType: "user",
            type: recordingMethod === 2 ? "audio" : "video"
        })
        videoLayout.mediaRecorderPromptUI({
            status: true,
            userType: "moderator",
            type: recordingMethod === 2 ? "audio" : "video"
        })
    }

    // 停止录制UI
    public endRecordingUi() {
        const { recordingMethod } = this.state;
        this.props.sendMediaRecorderCommand({
            status: false,
            userType: "user",
            type: recordingMethod === 2 ? "audio" : "video"
        })
        videoLayout.mediaRecorderPromptUI({
            status: false,
            userType: "moderator",
            type: recordingMethod === 2 ? "audio" : "video"
        })
    }

    // 云录制
    // public websocketCloudRecording() {
    // const { recordingMethod } = this.state;
    // const { meetingData } = this.props;
    // // summary.speechServerWebSocket(this.props.meetingData.primaryKey, this.props.user.primaryKey, this.onMessageSpeechServer.bind(this))
    // const url = `wss://${(window as any).$environment === "svmeet" ? "www" : "deve"}.svmeet.com/oversee/ws/meeting/${meetingData.primaryKey}/user/sv-user-${this.props.user.primaryKey}?engine=${connectionOptions.serviceUrl}&domain=${connectionOptions.hosts.domain}&muc=${connectionOptions.hosts.muc}&anonymousdomain=${connectionOptions.hosts.anonymousdomain || ""}&origin=https://deve.svmeet.com`//&origin=https://ml.svmeet.com
    // //@ts-ignore
    // this.cloudRecordingSocket = new ReconnectingWebSocket(url, null, { debug: false, reconnectInterval: 8000 });
    // this.cloudRecordingSocket.onopen = () => {
    //     console.log("语音服务已连接");
    // }
    // this.cloudRecordingSocket.addEventListener('message', (receive) => {
    //     let data = JSON.parse(receive.data)
    //     switch (data.type) {
    //         case 'available':
    //             // 发送会议视频录制
    //             this.cloudRecordingSocket.send(JSON.stringify({
    //                 type: recordingMethod === 1 ? 'startVideoRecord' : 'startAudioRecord',
    //                 timeLimit: meetingData.meetServiceId === null ? 100000 : 0
    //             }));
    //             this.startRecordingUi()
    //             break;
    //         default:
    //             break;
    //     }
    // })
    // this.cloudRecordingSocket.onerror = () => {
    // }
    // }
    public async cancel() {
        this.props.onClose()
        this.props.setMeetingRecordingData({
            open: false,
        })
    }

    private cloudRecordingSocket;
}

export default connect(
    (state: any) => ({
        user: state.user
    }),
    (dispatch) => {
        return {
            setMeetingRecordingData: (data: any) => dispatch(setMeetingRecordingData(data))
        }
    }
)(Index)
