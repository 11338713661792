'use strict';

/**
 * 推送媒体默认约束
 */
const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
const CONSTRAINTS_PUSH = {
    audio: {
        echoCancellation: !!supportedConstraints.echoCancellation,
        noiseSuppression: !!supportedConstraints.noiseSuppression,
    },
    video: {
        width: {
            min: 640,
            ideal: 1280,
            max: 3840
        },
        height: {
            min: 360,
            ideal: 720,
            max: 2160
        },
        frameRate: {
            min: 6,
            ideal: 12,
            max: 24
        },
        // zoom: !!supportedConstraints.zoom,
    }
};
/**
 * 默认拉取种类
 */
const KINDS = new Set(['audio', 'video']);

/**
 * 获取远端应答信息
 * @param {string} url 请求地址
 * @param {string} tid 客户端ID
 * @param {string} streamId 流ID
 * @param {string} codec 流编码方式，可以为null
 * @param {string} sdp 本地sdp信息
 * @returns 远端应答信息
 */
const getRemoteOffer = (url, tid, streamId, codec, sdp) => {
    let apiUrl = new URL(url);
    if (codec) {
        apiUrl.searchParams.append('codec', codec);
    }
    let streamUrl = `webrtc://${apiUrl.hostname}${streamId}?${codec ? `codec=${codec}` : ''}`

    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'json';
        xhr.open('POST', apiUrl.toString());
        xhr.onerror = (err) => {
            console.error('get push session error.', err);
            reject(err);
        };
        xhr.onload = () => {
            console.log('get push session ready state:', xhr.readyState);
            console.log('get push session http state:', xhr.status);
            console.log('get push session response:', xhr.response);
            if (xhr.status === 200) {
                let result = xhr.response;
                // 没有返回数据
                if (!result) {
                    reject(new Error(`get push session response data not ${xhr.responseType}.`));
                    return;
                }

                // 状态码不为0
                if (result.code) {
                    reject(result);
                    return;
                }

                resolve(new RTCSessionDescription({
                    type: 'answer',
                    // 处理AV1编码问题
                    sdp: result.sdp.replaceAll("AV1X", "AV1")
                }));
            } else {
                reject(new Error(`get push session response status[${xhr.status}] error.`));
            }
        };
        xhr.send(JSON.stringify({
            api: apiUrl.toString(),
            tid: tid,
            streamurl: streamUrl,
            clientip: null,
            // 处理AV1编码问题
            sdp: sdp.replaceAll("AV1", "AV1X")
        }));
    });
};

class SrsSdk {
    constructor(pushApi, pullApi) {
        this.pushApi = pushApi;
        this.pullApi = pullApi;
        this.tid = Number(parseInt(new Date().getTime() * Math.random() * 100)).toString(16).substring(0, 7);
        this.senders = [];

        this.running = false;
    }
    /**
     * 开始推流
     * @param {obj} option 推流设置，必须设置id，如有需要可以设置codec
     * @param {obj | MediaStream} constraintsOrMediaStream 获取媒体约束 或 媒体流
     */
    async push(option, constraintsOrMediaStream) {
        if (this.running) {
            throw new Error('当前实例正在运行，不能重复执行。');
        }

        try {
            this.running = true;
            this.rtc = new RTCPeerConnection(null);

            if (constraintsOrMediaStream.constructor && constraintsOrMediaStream.constructor.name === 'MediaStream') {
                this.localStream = constraintsOrMediaStream;
            } else {
                let pushConstraints = Object.assign({}, CONSTRAINTS_PUSH, constraintsOrMediaStream);

                if (pushConstraints.audio) {
                    this.rtc.addTransceiver("audio", { direction: "sendonly" });
                }
                if (pushConstraints.video) {
                    this.rtc.addTransceiver("video", { direction: "sendonly" });
                }

                // 获取本地媒体流
                this.localStream = await navigator.mediaDevices.getUserMedia(pushConstraints);

            }
            this.localStream.getTracks().forEach((track) => {
                this.senders.push(this.rtc.addTrack(track, this.localStream));
            });

            // 创建本地应答
            let localOffer = await this.rtc.createOffer();
            this.rtc.setLocalDescription(localOffer);

            // 获取远端应答
            let remoteOffer = await getRemoteOffer(
                this.pushApi,
                this.tid,
                option.id,
                option.codec,
                localOffer.sdp,
            );

            // 设置远端应答
            await this.rtc.setRemoteDescription(remoteOffer);
        } catch (err) {
            await this.stop(true);

            this.running = false;
            throw err;
        }
    }

    /**
     * 开始拉流
     * @param {obj} option 拉流设置，必须设置id，如有需要可以设置codec
     * @param {array} kinds 拉流类型数组：video、audio。如果kings为null或者长度为0，则默认拉取全部类型
     * @param {function} ontrack 远程流加入事件监听函数
     */
    async pull(option, kinds, ontrack) {
        if (this.running) {
            throw new Error('当前实例正在运行，不能重复执行。');
        }

        console.debug('开始拉流:', option, kinds)

        try {
            this.running = true;
            this.rtc = new RTCPeerConnection(null);
            this.rtc.onconnectionstatechange = (evt) => {
                console.log('connection state change:', this.rtc.connectionState);
            };
            this.rtc.ontrack = ontrack;

            // 如果没有明确指定种类，则拉取全部。
            if (!kinds || !kinds.length) {
                console.debug('没有明确指定种类');
                KINDS.forEach(kind => {
                    this.rtc.addTransceiver(kind, { direction: "recvonly" });
                })
            } else {
                console.debug('指定种类', kinds);
                let effectiveKinds = kinds.filter(kind => KINDS.has(kind));
                if (!effectiveKinds.length) {
                    throw new Error('没有有效的拉取类型。');
                }
                effectiveKinds.forEach(kind => {
                    this.rtc.addTransceiver(kind, { direction: "recvonly" });
                });
            }

            // 创建本地应答
            let localOffer = await this.rtc.createOffer();
            this.rtc.setLocalDescription(localOffer);

            // 获取远端应答
            let remoteOffer = await getRemoteOffer(
                this.pullApi,
                this.tid,
                option.id,
                option.codec,
                localOffer.sdp
            );

            // 设置远端应答
            await this.rtc.setRemoteDescription(remoteOffer);
        } catch (err) {
            await this.stop(true);

            this.running = false;
            throw err;
        }
    }

    async stop(force) {
        if (!this.running && !force) {
            return;
        }

        if (this.localStream) {
            this.localStream.getTracks().forEach((track) => {
                track.stop();
            });
            this.localStream = null;
        }

        while (this.senders.length) {
            this.rtc.removeTrack(this.senders.shift());
        }

        this.rtc.close();

        this.running = false;
    }
}

export default SrsSdk