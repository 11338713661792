
import React from "react";
import i18n from "i18n";
import api from "api";
import { message } from "antd";
import "./index.less"

interface IState {
    language: Array<{
        value: string;
        label: string;
    }>;
}

class Index extends React.Component<{
}, IState> {

    public state: IState = {
        language: []
    }

    public componentDidMount() {
        this.lang()
    }

    // 获取语言列表
    public async lang() {
        try {
            const res = await api.summary.translateLocales()
            const list: any[] = [];
            for (const key in res.data) {
                if (Object.prototype.hasOwnProperty.call(res.data, key)) {
                    const title = res.data[key].title;
                    list.push({
                        label: title,
                        value: key
                    })
                }
            }
            this.setState({ language: list })
        } catch (e) {
            message.error(i18n.t("message.requestFailed"))
        }
    }

    public render() {
        return <div className={'language-list-component'}>
            <div className={'main'}>
                <div className="title">{i18n.t("meet.supportLanguage")}</div>
                <div className="list">
                    {
                        this.state.language.map(x => {
                            return <div className="item" key={x.value} >{x.label}</div>
                        })
                    }
                </div>
            </div>
        </div>
    }
}

export default Index