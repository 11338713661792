
import React from "react";
import { connect } from "react-redux";
import message from "antd/lib/message"
import Button from "antd/lib/button";
import Modal from "antd/lib/modal";
import styles from "./index.module.css";
import api from "api"
import { IUser } from "models/user";
import i18n from "i18n";
import { PHONE_RESEND_VCODE_TIME } from "config";

interface DispatchProps {
    user: IUser;
    setUser: (user: IUser) => void
}

interface IItem {
    account?: string;
    captcha?: string;
    password?: string;
    checkPassword?: string;
}

interface IState {
    form: IItem;
    showCheckPwd: boolean;
    captchaTime: string;
    showPwd: boolean;
    verificationImg: { id: string; img: string };
    imgCode: string;
}

const defaultForm: IItem = {
    account: "",
    captcha: "",
    password: "",
    checkPassword: "",
}


class Index extends React.Component<{
    type: string;
    onCancel: () => void;
    title?: string;
    account?: string;
} & DispatchProps & any, IState> {

    state: IState = {
        form: defaultForm,
        showCheckPwd: false,
        captchaTime: "",
        showPwd: false,
        verificationImg: { id: "", img: "" },
        imgCode: ""
    }

    public componentDidMount() {
        this.getVerificationImg()
    }

    public render() {
        const { form, captchaTime, verificationImg, imgCode } = this.state;
        return <Modal title={this.props.title || i18n.t('login.changePassword')} visible={true} width={"7.0rem"}
            onOk={this.editPassword.bind(this)}
            onCancel={() => this.setState({ form: defaultForm }, this.props.onCancel())}>
            <div className={styles.form}>
                <div className={styles.formItem}>
                    <div className={styles.label}><span>*</span> {i18n.t('login.accountNumber')}</div>
                    <div className={styles.inputWrapper}>
                        <div className={styles.label} style={{ width: "fit-content", maxWidth: "fit-content" }}>{this.props.account || this.props.user.email || this.props.user.phone}</div>
                    </div>
                </div>
                <div className={styles.formItem}>
                    <div className={styles.label}><span>*</span> {i18n.t('login.imageVerificationCode')}</div>
                    <div className={styles.inputWrapper}
                        style={{ display: "flex" }}>
                        <input
                            style={{ marginRight: 10, width: '2.78rem' }}
                            type="text"
                            className={styles.input}
                            placeholder={i18n.t('login.pleaseEnterImgVerificationCode')}
                            value={imgCode}
                            maxLength={4}
                            onChange={evt => {
                                const value = evt.target.value.trim();
                                if (/^[a-zA-Z0-9]{0,4}$/.test(value)) {
                                    this.setState({ imgCode: value })
                                }
                            }}
                        />
                        {verificationImg.img && <img src={'data:image/png;base64,' + verificationImg.img} alt="" className={styles.verificationImg} onClick={this.getVerificationImg.bind(this)} />}
                    </div>
                </div>
                <div className={styles.formItem}>
                    <div className={styles.label}><span>*</span> {i18n.t('login.verificationCode')}</div>
                    <div className={styles.inputWrapper}
                        style={{ display: "flex" }}>
                        <input
                            style={{ marginRight: 10, width: '2.78rem' }}
                            type="text"
                            className={styles.input}
                            placeholder={i18n.t('login.pleaseEnterVerificationCode')}
                            value={form.captcha}
                            maxLength={6}
                            onChange={evt => {
                                const value = evt.target.value;
                                if (/^[0-9]{0,6}$/.test(value)) {
                                    this.setForm({ captcha: value });
                                }
                            }}
                        />
                        <Button
                            className={captchaTime ? styles.captchaBtnActive : styles.captchaBtn}
                            disabled={!!captchaTime}
                            onClick={this.sendCode.bind(this)}>{captchaTime ? captchaTime : i18n.t('login.getVerificationCode')}</Button>
                    </div>
                </div>
                <div className={styles.formItem}>
                    <div className={styles.label}><span>*</span> {i18n.t('login.password')}</div>
                    <div className={styles.inputWrapper}>
                        <input
                            autoComplete="new-password"
                            type={this.state.showPwd ? "text" : "password"}
                            className={styles.input}
                            placeholder={i18n.t('login.pleaseEnterYourPassword')}
                            value={form.password}
                            maxLength={20}
                            onChange={evt => this.setForm({ password: evt.target.value.trim() })}
                        />
                        <img alt="" onClick={() => this.setState({ showPwd: !this.state.showPwd })} className={styles.eye} src={require("static/images/icon-eye.png")} />
                    </div>
                </div>
                <div className={styles.formItem}>
                    <div className={styles.label}><span>*</span> {i18n.t('login.confirmPassword')}</div>
                    <div className={styles.inputWrapper}>
                        <input
                            type={this.state.showCheckPwd ? "text" : "password"}
                            className={styles.input}
                            placeholder={i18n.t('login.pleaseEnterConfirmPassword')}
                            value={form.checkPassword}
                            maxLength={20}
                            onChange={evt => this.setForm({ checkPassword: evt.target.value.trim() })}
                        />
                        <img alt="" onClick={() => this.setState({ showCheckPwd: !this.state.showCheckPwd })} className={styles.eye} src={require("static/images/icon-eye.png")} />
                    </div>
                </div>
            </div>
        </Modal>
    }

    public async sendCode() {
        let res;
        const { imgCode, verificationImg } = this.state;
        if (!imgCode) {
            message.warn(i18n.t('login.pleaseEnterImgVerificationCode'))
            return
        }
        if (this.props.account) {// 忘记密码
            if (this.props.account.indexOf('@') > -1) {
                res = await api.user.emailSendCode('rp', this.props.account || "", verificationImg.id, imgCode)
            } else {
                res = await api.user.sendCode('rp', this.props.account || "", verificationImg.id, imgCode)
            }
        } else { // 重置密码
            if (this.props.user.email) {
                res = await api.user.emailSendCode('rp', this.props.account || this.props.user.email || "", verificationImg.id, imgCode)
            } else {
                res = await api.user.sendCode('rp', this.props.account || this.props.user.phone || "", verificationImg.id, imgCode)
            }
        }
        if (res.code === 200) {
            let num = PHONE_RESEND_VCODE_TIME;
            const timer = setInterval(() => {
                if (num === 1) {
                    clearInterval(timer)
                    this.setState({ captchaTime: "" })
                    return
                }
                this.setState({ captchaTime: i18n.t('login.reissueAfterNSeconds', { num: --num }) })
            }, 1000)
            this.getVerificationImg()
        }
    }

    public async editPassword() {
        const { form } = this.state;
        if (!form.password || !form.checkPassword || !form.captcha) {
            message.info(i18n.t('message.enterFequiredItem'))
            return
        }
        if (form.captcha.length !== 6) {
            message.info(i18n.t('message.6digitVerificationCode'))
            return
        }
        if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/.test(form.password)) {
            message.info(i18n.t('message.enter20password'))
            return
        }
        if (form.password !== form.checkPassword) {
            message.info(i18n.t('message.pleaseConfirmPassword'))
            return
        }
        let res;
        if (this.props.type === 'forget') {
            form.account = this.props.account;
            res = await api.user.recoverPassword(this.props.account.indexOf("@") === -1 ? "phone" : "email", form)
        } else {
            res = await api.user.resetPassword(this.props.user.phone ? "phone" : "email", form)
        }
        if (res.code === 200) {
            this.setState({ form: defaultForm })
            this.props.onCancel()
            message.success(i18n.t('message.passwordUpdated'))
        } else {
            message.error(res.msg)
        }
    }

    // 获取图片验证码
    public async getVerificationImg() {
        const res = await api.common.getVerificationImg()
        if (res.code === 200) {
            this.setState({ verificationImg: res.data })
        }
    }

    public setForm(form: IItem) {
        this.setState({
            form: {
                ...this.state.form,
                ...form
            }
        })
    }
}

export default connect(
    (state: any) => ({
        user: state.user
    }),
)(Index);