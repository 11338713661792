import React from "react";
import styles from "./index.module.css";
import Radio from "antd/lib/radio"
import Modal from "antd/lib/modal"
import message from "antd/lib/message"
import api from "api";
import i18n from "i18n";
import { CURRENT_MEETING_DATA, STORAGE_ACCESS_TOKEN, SUBTITLE_USAGE_MODAL } from "config";
import { TranslationDetail } from "components";
class Page404 extends React.Component<any>{

    public state = {
        checked: 0,
        success: false,
        visible: false,
        showTranslationDetailModal: false
    }

    public componentDidMount() {
        // window.addEventListener("message", (eve) => {
        // if (eve.data && eve.data.cmd === "closeVoteModal") {
        // this.setState({ visible: false })
        // }
        // });
        const subtitle = localStorage.getItem(SUBTITLE_USAGE_MODAL)
        if (subtitle) {
            this.setState({ showTranslationDetailModal: true })
        }
    }

    public render() {
        return <div className={styles.bg}>
            <div className={styles.nav}>
                <img src={require("static/images/home/mobile-logo.png")} />
            </div>
            <div className={styles.title}>{i18n.t('youHaveQuitMeeting')}</div>

            <div className={styles.btnBox}>
                <div className={styles.btnOne} onClick={this.submit.bind(this)}>{i18n.t('rejoin')}</div>
                <div className={styles.btnTwo} onClick={() => this.props.history.push('/home')}>{i18n.t('returnHomePage')}</div>
            </div>
            {/* <div className={styles.feedbackBox}>
                <div className={styles.feedbackBoxTitle}>
                    <img src={require("static/images/home/icon-feedback.png")} alt="" />
                    <span>{i18n.t('feedback')}</span>
                </div>
                <div className={styles.feedbackBoxText}>{i18n.t('satisfyAudioQuality')}</div>
                {
                    !this.state.success ? <div>
                        <Radio checked={this.state.checked === 1 ? true : false} onClick={() => this.satisfaction(true)}>{i18n.t('satisfaction')}</Radio>
                        <Radio checked={this.state.checked === 2 ? true : false} onClick={() => this.satisfaction(false)}>{i18n.t('noSatisfaction')}</Radio>
                    </div> : <div>{i18n.t('thanksFeedback')}</div>
                }
                <div className={styles.hrefText} onClick={() => this.setState({ visible: true })}>{i18n.t('participateVoting')}</div>
            </div> */}
            <img className={styles.bye} src={require("static/images/home/bye.png")} alt="" />
            <img className={styles.xingqiu} src={require("static/images/home/404-xingqiu.png")} alt="" />
            <Modal visible={this.state.visible} title={i18n.t('demandVote')} footer={null} onCancel={() => this.setState({ visible: false })}>
                <iframe width={"100%"} style={{ height: "60vh" }} frameBorder={0} src={((window as any).$environment === "ml" ? "https://ml.svmeet.com" : window.location.origin) + `/page/share/#/demand/${this.props.match.params.meetKey}/${window.localStorage.getItem(STORAGE_ACCESS_TOKEN)}`}></iframe>
            </Modal>

            {this.state.showTranslationDetailModal && <TranslationDetail onClose={() => this.setState({ showTranslationDetailModal: false })} />}

        </div>
    }

    public async submit() {
        const res = await api.meeting.joinMeet(this.props.match.params.meetId, "")
        if (res.code === 200) {
            window.localStorage.setItem(CURRENT_MEETING_DATA, JSON.stringify({
                primaryKey: res.data.primaryKey,
                roomName: res.data.roomName,
                token: res.data.token,
                ...res.data.meetingInfo
            }))
            if (!res.data.waitingRoom) {
                if (res.data.live === 1) {
                    this.props.history.push(`/meet/${this.props.match.params.meetId}`);
                } else {
                    this.props.history.push(`/live-meeting/${res.data.primaryKey}/${this.props.match.params.meetId}/${''}`)
                }
            }
        }
    }

    public iframeCloseVoteModal() {
        this.setState({ visible: false })
    }

    public async satisfaction(bool) {
        if (bool) {
            // @ts-ignore
            _hmt.push(['_trackEvent', "radio", "click", 'meet_audio_feedback_yes'])
        } else {
            // @ts-ignore
            _hmt.push(['_trackEvent', "radio", "click", 'meet_audio_feedback_no'])
        }
        this.setState({ checked: bool ? 1 : 2 })
        const res = await api.common.setSatisfaction(this.props.match.params.meetKey, bool)
        if (res.code) {
            this.setState({ success: true })
        } else {
            message.error(res.msg)
        }
    }

    public componentWillUnmount(){
        localStorage.removeItem(SUBTITLE_USAGE_MODAL)
    }
}

export default Page404;