import React from "react";
import moment from "moment";
import Message from "antd/lib/message";
import styles from "./index.module.css";
import api from "api";
import { connect } from "react-redux";
import { CURRENT_MEETING_DATA, MEET_PROVISIONAL_USERNAME } from "config";
import { SetOutCamera, InvitationModal } from "components"
import { IMeetingItem, IMeetItem } from "models/meet"
import { IUser } from "models/user";
import { setUser } from "reduxs/actions/user";
import i18n from "i18n";
import Whiteboard from "utils/whiteboard";
interface DispatchProps {
    user: IUser;
    setUser: (user: IUser) => void
}
interface IItem {
    meetId?: string;
    password?: string;
    username?: string;
}

interface IState {
    form: IItem;
    meetData: IMeetItem;
    checkboxUsername: boolean;
    showModal: boolean;
    showRefuseModal: boolean;
    listData: IMeetingItem[];
    showInvitationModal: boolean;
    detail: IMeetingItem;
}

class Index extends React.Component<{
    user: IUser
    noticeSocket: any;
    closeShowModal: () => void,
    RouteComponentProps
} & DispatchProps & any, IState> {

    public state: IState = {
        form: {
            meetId: "",
            password: "",
            username: ""
        },
        meetData: {
            theme: "",
            content: "",
            meetId: "",
            password: "",
            primaryKey: "",
            residueTime: 0
        },
        checkboxUsername: false,
        showModal: false,
        showRefuseModal: false,
        listData: [],
        showInvitationModal: false,
        detail: {
            theme: "",
            content: "",
            meetNumber: "",
            password: "",
            primaryKey: "",
            residueTime: 0,
            meetServiceId: "",
            username: "",
            reserveParticipants: []
        }
    }

    public async componentDidMount() {
        if (this.props.match.params.id) {
            const search = this.props.location.search.replace("?", "").split('&')
            const obj = {}
            search.forEach(element => {
                const arr = element.split("=")
                obj[arr[0]] = arr[1]
            });
            this.setForm({ meetId: this.props.match.params.id, password: obj['password'] })
            const res = await api.user.checkToken()
            if (res.code === 200 && res.data === false) {
                window.location.href = window.location.origin + window.location.pathname + "#/login/" + this.props.match.params.id + this.props.location.search
                return
            }
            this.getMeetData()
            this.getCurrentMeetList()
        } else {
            this.getCurrentMeetList()
        }
    }

    public render() {
        const { form, meetData } = this.state;
        const { user } = this.props;
        return <div className={styles.forgetPwdPage}>
            <div className={styles.formBox}>
                <div className={styles.inputWrapper}>
                    <input
                        type="text"
                        className={styles.input}
                        placeholder={i18n.t('participationInvitationCode')}
                        value={form.meetId}
                        maxLength={8}
                        minLength={6}
                        onChange={evt => this.setForm({ meetId: evt.target.value.trim() })}
                        onBlur={this.getMeetData.bind(this)}
                        onKeyDown={(e) => {
                            const evt = window.event || e;
                            if ((evt as any).keyCode === 13) {
                                //回车事件
                                this.submit()
                            }
                        }}
                    />
                </div>
                {/* <div className="formItem">
                    <div className='label'><span>*</span>{i18n.t('meetPassword')}</div> */}
                <div className={styles.inputWrapper}>
                    <input
                        className={styles.input}
                        placeholder={i18n.t('meetPassword')}
                        value={form.password}
                        maxLength={6}
                        onChange={evt => {
                            const value = evt.target.value.trim();
                            if (/^[0-9]{0,6}$/.test(value)) {
                                this.setForm({
                                    password: value
                                })
                            }
                        }}
                        onKeyDown={(e) => {
                            const evt = window.event || e;
                            if ((evt as any).keyCode === 13) {
                                this.submit()
                            }
                        }}
                    />
                </div>
                {/* </div> */}
                {!user.name && <div className={styles.inputWrapper}>
                    <input
                        type="text"
                        className={styles.input}
                        placeholder={this.props.user.name ? `${this.props.user.name}（${i18n.t('userName')}）` : i18n.t('userName')}
                        value={form.username}
                        maxLength={8}
                        onChange={evt => {
                            const value = evt.target.value.trim();
                            this.setForm({
                                username: value
                            })
                        }}
                        onBlur={async (e) => {
                            if (e.currentTarget.value && (e.currentTarget.value !== this.props.user.name)) {
                                await this.setForm({ username: e.currentTarget.value })
                            }
                        }}
                        onKeyDown={(e) => {
                            const evt = window.event || e;
                            if ((evt as any).keyCode === 13) {
                                //回车事件
                                this.submit()
                                // if (e.currentTarget.value && (e.currentTarget.value !== this.props.user.name)) {
                                //     this.editUserName()
                                // }
                            }
                        }}
                    />
                </div>}

                {/* <div className={styles.checkboxName}>
                    <Checkbox checked={this.state.checkboxUsername} onChange={(e) => {
                        this.setState({ checkboxUsername: e.target.checked })
                    }}></Checkbox>
                    <span className={styles.text}>在以后会议使用这个名字</span>
                </div> */}
                <div className={styles.btnBox}>
                    <div className={styles.backBtn} onClick={() => {
                        if (this.props.match.params.id) {
                            this.props.history.push("/home")
                        } else {
                            window.history.back()
                        }
                    }}>{i18n.t('returnHomePage')}</div>
                    <div className={styles.confirmBtn + (form.meetId ? "" : " " + styles.confirmBtnDisabled)} onClick={() => {
                        this.submit()
                        //@ts-ignore
                        _hmt.push(['_trackEvent', 'button', 'click', 'join-meet-btn'])
                    }}>{i18n.t('joinSession')}</div>
                </div>
                <div className={styles.meetTitle}>
                    {meetData.theme}
                </div>
                <div className={styles.meetDes}>
                    <div>{i18n.t('contentMeeting')}</div>
                    <div>{meetData.content}</div>
                </div>

                {/* 会议列表 */}
                <div className={styles.list}>
                    {
                        this.state.listData.map(node => {
                            return <div className={styles.listItem} key={node.primaryKey}>
                                <div className={styles.date} onClick={() => this.setState({ showInvitationModal: true, detail: node })}>
                                    <div>{node.theme}</div>
                                    <div className={styles.bottom}>
                                        <img src={require("static/images/home/icon-time.png")} alt="" />
                                        <span style={{ marginRight: "0.1rem" }}>{node.startTime}</span>
                                    </div>
                                </div>
                                <div className={styles.joinText} onClick={() => this.joinMeeting(node)}>
                                    {i18n.t('joinText')}</div>
                            </div>
                        })
                    }
                </div>
            </div>
            <SetOutCamera />
            {
                this.state.showModal && <div className={styles.waitingModal}>
                    <div className={styles.main}>
                        <div className={styles.containerHead}>
                            <span>{i18n.t('waitingForAccess')}</span>
                            <img src={require('static/images/meet/icon-guanbi.png')} style={{ cursor: "pointer" }} alt=""
                                onClick={() => this.setState({ showModal: false })} />
                        </div>
                        <div></div>
                        <div className={styles.containerText}>
                            {i18n.t('waitingForTheHostAgreed')}
                        </div>
                    </div>
                </div>
            }
            {
                this.state.showRefuseModal && <div className={styles.waitingModal}>
                    <div className={styles.main}>
                        <div className={styles.containerHead}>
                            <span>{i18n.t('waitingForAccess')}</span>
                            <img src={require('static/images/meet/icon-guanbi.png')} style={{ cursor: "pointer" }} alt=""
                                onClick={() => this.setState({ showRefuseModal: false })} />
                        </div>
                        <div className={styles.icon}><img src={require('static/images/meet/icon-refuse.png')} alt="" /></div>
                        <div className={styles.containerText}>
                            {i18n.t('refuseAdmissionInfo')}
                        </div>
                        <div className={styles.btns}>
                            <div className={styles.btn} onClick={() => window.history.back()}>{i18n.t('dropOut')}</div>
                            <div className={styles.btn} onClick={async () => {
                                await this.submit()
                                this.setState({ showRefuseModal: false, showModal: true })
                            }}>{i18n.t('joinMeeting')}</div>
                        </div>
                    </div>
                </div>
            }

            {
                this.state.showInvitationModal &&
                <InvitationModal style={{ width: "400px" }} defaultCopy={false} meetData={this.state.detail as any} pageText='maa-meet'
                    onClose={() => this.setState({ showInvitationModal: false })} />
            }

        </div>
    }

    public socketNoticeMsg() {
        if (this.props.noticeSocket && Object.keys(this.props.noticeSocket).length && this.props.noticeSocket.addEventListener)
            this.props.noticeSocket.addEventListener('message', (receive) => {
                let msg = JSON.parse(receive.data)
                if (msg.type === 'ping') return
                console.log('waiting', receive)
                if (msg.type === "allowWaitingRoom") {
                    this.props.history.push(`/meet/${this.state.form.meetId}`);
                }
                if (msg.type === "refuseWaitingRoom") {
                    this.setState({ showRefuseModal: true })
                }
                if (msg.type === 'waitingRoom') { // 新消息
                }
            })
    }

    public async getMeetData() {
        const { form } = this.state;
        if (form.meetId) {
            const res = await api.meeting.getMeetDetail(form.meetId || "")
            if (res.code === 200) {
                if (res.data) {
                    this.setState({ meetData: res.data })
                }
            }
        }

    }

    public async submit() {
        const { form } = this.state;
        if (!form.meetId) {
            Message.info(i18n.t('message.pleaseParticipationInvitationCode'))
            return
        }
        if (form.username === '') {
            if (this.props.user.name) {
                form.username = this.props.user.name
            } else {
                Message.info(i18n.t('message.pleaseEnterUserName'))
                return
            }
        }
        const res = await api.meeting.joinMeet(form.meetId, form.password || "")
        if (res.code === 200) {
            window.localStorage.setItem(CURRENT_MEETING_DATA, JSON.stringify({
                roomName: res.data.roomName,
                ...res.data.meetingInfo,
                primaryKey: res.data.primaryKey,
                token: res.data.token,
                // live: res.data.live,
                meetNumber: form.meetId,
                theme: this.state.meetData.theme,
            }))
            window.localStorage.setItem(MEET_PROVISIONAL_USERNAME, form.username || "")
            if (res.data.waitingRoom) {
                this.socketNoticeMsg()
                this.setState({ showModal: true })
            } else {
                if (res.data.live === 1) {
                    this.props.history.push(`/meet/${form.meetId}`);
                } else {
                    this.props.history.push(`/live-meeting/${res.data.primaryKey}/${form.meetId}/${form.password}`)
                }
            }
        }

        if (!this.props.user.name && (form.username !== this.props.user.name)) {
            this.editUserName()
        }
    }

    public async editUserName() {
        try {
            const res = await api.user.updateUserName(this.state.form.username || "");
            if (res.code === 200) {
                this.props.setUser(res.data)
            }
        } catch (e) {
            Message.error(i18n.t('message.requestFailed'))
        }
    }

    public async getCurrentMeetList() {
        const res = await api.meeting.getCurrentMeetList(moment().format())
        if (res.code === 200) {
            const data: IMeetingItem[] = [];
            const endArr: string[] = []
            res.data.forEach(x => {
                x.startTime = moment(x.startTime).format("YYYY-MM-DD HH:mm")
                if (x.status === 3 || x.status === 0) {
                    data.push(x)
                    if (x.status === 0) { // 进行中
                        endArr.push(x.primaryKey)
                    }
                }
            })
            this.setState({ listData: data })
            const whiteboard = new Whiteboard()
            whiteboard.clearWhiteboardLocalStorage(endArr)
        } else {
            Message.error(res.msg)
        }
    }

    public async joinMeeting(data: IMeetingItem) {
        const res = await api.meeting.joinMeet(data.meetNumber || "", data.password || "")
        if (res.code === 200) {
            window.localStorage.setItem(CURRENT_MEETING_DATA, JSON.stringify({
                roomName: res.data.roomName,
                ...res.data.meetingInfo,
                primaryKey: res.data.primaryKey,
                token: res.data.token,
                // live: res.data.live,
                meetNumber: data.meetNumber
            }))
            if (res.data.waitingRoom) {
                this.props.history.push(`/waiting-room/${data.meetNumber}`)
            } else {
                if (res.data.live === 1) {
                    this.props.history.push(`/meet/${data.meetNumber}`);
                } else {
                    this.props.history.push(`/live-meeting/${res.data.primaryKey}/${data.meetNumber}/${data.password}`)
                }
            }
        }
    }

    public setForm(form: IItem) {
        this.setState({
            form: {
                ...this.state.form,
                ...form
            }
        })
    }
}

export default connect(
    (state: any) => ({
        noticeSocket: state.noticeSocket,
        user: state.user
    }),
    (dispatch) => {
        return {
            setUser: (data: any) => dispatch(setUser(data))
        }
    }
)(Index);