import React from "react";
import i18n from "i18n";
import Button from "antd/lib/button";
import Dropdown from "antd/lib/dropdown";
import Menu from "antd/lib/menu";
import notification from "antd/lib/notification";
import Modal from "antd/lib/modal";
import Popover from "antd/lib/popover";
import Tooltip from "antd/lib/tooltip";
import Badge from "antd/lib/badge";
import message from "antd/lib/message";
import Spin from "antd/lib/spin";
import styles from "./index.module.css";
import { LoadingOutlined } from '@ant-design/icons';
import { CURRENT_MEETING_DATA, IS_EXISTING_USER } from "config";
import summary from "utils/lib-jitsi-meet/summary"
import videoLayout from "utils/lib-jitsi-meet/videolayout";
import api from "api";
import ListMode from "../list-mode"
import { endRecord, endRecordAudio } from "utils/lib-jitsi-meet/devices";
import { IMyPermission, IMeetingItem, IRaiseHandsListItem } from "models/meet";
import { watermark } from "utils/data";
interface IState {
    muted: boolean;
    shared: boolean;
    video: boolean;
    meetingData: IMeetingItem;
    isCopy: boolean;
    isFullScreen: boolean;
    showSelectModeratorModal: boolean;
    startMutedSwitch: boolean;
    startCameraSwitch: boolean;
    showLeaveConfirmModal: boolean;
    isKeydown: boolean;
    isVideoBlurred: boolean; // 是否背景模糊
    setVideoBgValue: string; // 设置背景的图片地址
    sendPrivateMessage: string; // 发送文字
    leftMenuSize: "small" | "default";
    showChatMessage: boolean | "";
    switchVideoLoading: Boolean;
    subtitlesSwitchLoading: boolean; // 服务连接后才可显示字幕开关
    switchSubtitles: boolean;
    language: object;
    switchTranslation: boolean;
    switchLive: boolean;
    tooltipMore: boolean;
    tooltipListUser: boolean;
    tooltipInvite: boolean;
    tooltipTranslation: boolean;
    tooltipShare: boolean;
    currentLanguage: string;
    currentDesktopMode: 0 | 1 | 2;// 共享设置模式
    currentShareMode: "content" | "video" | "whiteboard" | "";// 共享屏幕的模式
    isWhiteboardCreator: boolean;
    switchAnnotation: boolean;
    bandwidthQuality: 0 | 1 | 2 | 3 | null;// 带宽质量
    visibleMoreDropdown: boolean;// 更多菜单的展示隐藏
    visibleListUserDropdown: boolean;
    // openPictureInPicture: boolean;// 开启画中画
}
class Index extends React.Component<any & {
    e2eeSupported: boolean;
    chatMessage: Array<any>;
    currentTranslationAcccount: string;
    permissionMenu: object;
    myPermission: IMyPermission;
    raiseHandsList: IRaiseHandsListItem[];
    meetingSocket: any;
    iframeWhiteboard: any;
    remoteLiveUserList: any[];
    liveStreaming: boolean;
    whiteboardData: Function; // 白板实例
    recordData: any;
    setMoreMenu: (current: string) => void;
    showInviteCodeModal: () => void;
    showSummary: (value: boolean) => void;
    grantOwner: () => void;
    showHideReportProblem: () => void;
    showHideMeetingRecording: () => void;
    setDisplayName: () => void; // 设置名称
    muteRemote: () => void;// 静音远端用户
    closeCameraRemote: (type: 'user' | "all", userId: string) => void; // 关闭远端用户摄像头
    setShareScreenMode: (mode) => void;
    setShowGuide: () => void;// 显示引导页
    switchParticipantTranslationLang: (lang) => void;
    setDesktopParams: (type) => void;
    kickParticipant: () => void;
    sendCloseDesktopCommand: (participantId) => void;
    changePermission: (key) => void;
    raiseHand: () => void;
    confirmSpeakOperation: (participantId: string, status: 0 | 1) => void;
    invitationToSpeak: (participantId: string) => void;
    showLiveModal: () => void;
    showJoinDevice: () => void; // 手动加入设备
    previewDevice: () => void // 预览第三方设备
    streamLeaveChat: () => void // 移除第三方设备
    setReceiverConstraints: (selectedEndpoints: string[], laseN: Number) => void;
    sharedWhiteboard: (params) => void; // 开启白板
}, IState> {

    constructor(props) {
        super(props)
        this.Fn = this.Fn.bind(this)
        this.setMode = this.setMode.bind(this)
    }

    public state: IState = {
        muted: true,
        shared: false,
        video: false,
        meetingData: {
            meetId: "",
            password: "",
            primaryKey: "",
            theme: "",
            content: "",
            meetNumber: "",
            live: 1
        },
        isCopy: false,
        isFullScreen: false,
        showSelectModeratorModal: false,
        startMutedSwitch: false,
        startCameraSwitch: false,
        showLeaveConfirmModal: false,
        isKeydown: false,
        isVideoBlurred: false,
        setVideoBgValue: '',
        sendPrivateMessage: "",
        leftMenuSize: "default",
        showChatMessage: "",
        switchVideoLoading: false,
        subtitlesSwitchLoading: true,
        switchSubtitles: false,
        language: {},
        switchTranslation: true,
        switchLive: false,
        tooltipMore: false,
        tooltipListUser: false,
        tooltipInvite: false,
        tooltipTranslation: false,
        tooltipShare: false,
        currentLanguage: "",
        currentDesktopMode: 0,
        currentShareMode: "",
        isWhiteboardCreator: false,
        switchAnnotation: false,
        bandwidthQuality: null,
        visibleMoreDropdown: false,
        visibleListUserDropdown: false,
        // openPictureInPicture: false
    }

    public componentDidMount() {
        const res = window.localStorage.getItem(CURRENT_MEETING_DATA)
        if (res) {
            this.state.meetingData = JSON.parse(res);
        }
        this.setState({
            meetingData: this.state.meetingData
        })
        const fullscreenElement = document.fullscreenElement || (document as any).webkitFullscreenElement || (document as any).mozFullScreenElement || (document as any).webkitIsFullScreen || (document as any).mozFullScreen;
        //全屏按钮切换类
        if (fullscreenElement) {
            this.setState({ isFullScreen: true })
            //目前全屏
        }
        this.eventFullscreenchange()
        // 监听按住空格语音
        window.addEventListener("keydown", (e) => {
            if (e.keyCode === 32) {
                if (document.getElementById('meet-assist-handle')) return
                if (this.state.muted && this.state.isKeydown === false) {
                    this.setState({
                        muted: !this.state.muted,
                    }, async () => {
                        await this.props.setLacalMute(this.state.muted)
                        await this.setState({ isKeydown: true })
                    })
                } else {
                    if (this.state.isKeydown && this.state.muted) {
                        this.setState({ isKeydown: false })
                    }
                }
            }
        })
        window.addEventListener("keyup", (e) => {
            if (e.keyCode === 32) {
                if (document.getElementById('meet-assist-handle')) return
                if (this.state.muted === false && this.state.isKeydown) {
                    this.setState({ muted: true }, async () => {
                        await this.props.setLacalMute(this.state.muted)
                        await this.setState({ isKeydown: false })
                    })
                }
            }

        })
        window.addEventListener("resize", this.Fn)
        this.getTranslateLocales()

        const isSafariBrowser = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
        if (isSafariBrowser) {
            const ele = document.getElementById("inviteImg")
            if (ele) ele.style.transform = "rotate(90deg)"
        }
    }

    // 获取可翻译地区
    public async getTranslateLocales() {
        const res = await api.summary.translateLocales()
        this.setState({ language: res.data || [] })
    }

    public render() {
        const { muted, shared, video, currentShareMode } = this.state;
        const { mode, videoList } = this.props
        return <div className={styles.meetFooter}>
            <div className={styles.footerOne}>
                <div className={styles.backgroundArea} style={{
                    transition: "width 0.4s",
                    // width: leftMenuSize === "small" ? "1.5rem" : "2.06rem",
                    overflow: "hidden",
                    padding: "0.14rem 0.15rem"
                }}>

                    {/* <img className={styles.imgJiantou} src={require('static/images/meet/icon-jiantou-r-small.png')} alt=""
                        style={{ transform: leftMenuSize === "default" ? 'rotate(180deg)' : 'rotate(0deg)' }}
                        onClick={() => this.setState({ leftMenuSize: leftMenuSize === "small" ? "default" : "small" }, () => {
                            //@ts-ignore
                            _hmt.push(['_trackEvent', "icon", "click", 'left_arrow_icon'])
                        })} />
                    <span className={styles.line}></span> */}
                    {
                        videoList.length ? <Tooltip overlayClassName="meet-menu-tooltip" color={'rgba(64,64,64,1)'} title={i18n.t(mode === 'gallery' ? 'meet.PIPMode' : mode === "speaker" ? 'meet.galleryMode' : "meet.leadMode")}>
                            <img className={styles.img1} src={require('static/images/meet/icon-gallery.png')} alt="" onClick={this.setMode} /></Tooltip> : ""
                    }
                    <Dropdown trigger={["click"]}
                        overlayStyle={{ paddingBottom: "1%", width: "438px" }}
                        visible={this.state.visibleListUserDropdown}
                        onVisibleChange={(e) => {
                            this.setState({ visibleListUserDropdown: e })
                            if (e) {
                                this.setState({ tooltipListUser: false })
                            }
                        }}
                        overlay={
                            <Menu className="meet-footer" style={{ background: "rgba(28, 28, 28, 0.9)", borderRadius: '6px' }}>
                                <ListMode
                                    ref={(ref) => this.ListModeRef = ref}
                                    videoList={this.props.videoList.filter(x => !(x.sharedUser && x._displayName.indexOf("userId-") !== -1))}
                                    isLocalModerator={this.props.isLocalModerator}
                                    setDisplayName={(name) => this.props.setDisplayName(name)}
                                    muteRemote={this.props.muteRemote}
                                    closeCameraRemote={this.props.closeCameraRemote}
                                    switchParticipantTranslationLang={this.props.switchParticipantTranslationLang}
                                    kickParticipant={this.props.kickParticipant}
                                    grantOwner={this.props.grantOwner}
                                    setAllMutedPolicy={this.props.setAllMutedPolicy}
                                    permissionMenu={this.props.permissionMenu}
                                    changePermission={this.props.changePermission}
                                    raiseHand={this.props.raiseHand}
                                    confirmSpeakOperation={this.props.confirmSpeakOperation}
                                    invitationToSpeak={this.props.invitationToSpeak}
                                    myPermission={this.props.myPermission}
                                    raiseHandsList={this.props.raiseHandsList}
                                    muted={this.state.muted}
                                    meetingSocket={this.props.meetingSocket}
                                    remoteLiveUserList={this.props.remoteLiveUserList}
                                    previewDevice={this.props.previewDevice}
                                    streamLeaveChat={this.props.streamLeaveChat}
                                />
                            </Menu>
                        } placement="bottomCenter">
                        <Tooltip visible={this.state.tooltipListUser} overlayClassName="meet-menu-tooltip" color={'rgba(64,64,64,1)'} title={i18n.t('meet.participant')}>
                            <Badge style={{ boxShadow: "0 0 0 0px #fff", background: "rgba(0,0,0,0)" }}
                                count={this.props.videoList.filter(x => !(x.sharedUser && x._displayName.indexOf("userId-") !== -1)).length}
                                overflowCount={99} offset={[-10, 1]}>
                                <img src={require('static/images/meet/icon-user-list.png')} alt=""
                                    onMouseOut={() => this.setState({ tooltipListUser: false })} onMouseOver={() => this.setState({ tooltipListUser: true })}
                                /></Badge>
                        </Tooltip>
                    </Dropdown>
                    {/* {
                        this.props.videoList.length ? <img src={require(this.state.isFullScreen ? 'static/images/meet/icon-full-screen-1.png' : "static/images/meet/icon-full-screen.png")} alt="" onClick={() => {
                            this.setState({ isFullScreen: !this.state.isFullScreen }, async () => {
                                await this.isFullscreen(this.state.isFullScreen)
                                await this.props.hideMenu()
                            })
                        }} /> : ""
                    } */}
                    <Tooltip visible={this.state.tooltipInvite} overlayClassName="meet-menu-tooltip" color={'rgba(64,64,64,1)'} title={i18n.t('meet.invite')}>
                        <img id="inviteImg" className={styles.img3} src={require('static/images/meet/icon-share.png')} alt="" onClick={() => {
                            this.props.showInviteCodeModal()
                            this.setState({ tooltipInvite: false })
                            //@ts-ignore
                            _hmt.push(['_trackEvent', "icon", "click", 'menu_invite_icon'])
                        }}
                            onMouseOut={() => this.setState({ tooltipInvite: false })} onMouseOver={() => this.setState({ tooltipInvite: true })}
                        />
                    </Tooltip>
                    {   // 直播
                        this.state.meetingData.live === 0 && this.props.isLocalModerator && <Tooltip overlayClassName="meet-menu-tooltip" color={'rgba(64,64,64,1)'} title={i18n.t('liveText')}>
                            <img className={styles.img3} src={require(this.props.liveStreaming ? "static/images/meet/icon-live-a.png" : 'static/images/meet/icon-live.png')} alt="" onClick={() => {
                                this.props.showLiveModal()
                            }} />
                        </Tooltip>
                    }
                </div>
            </div>
            <div className={styles.footerTwo}>
                <div className={styles.backgroundArea}>
                    {// 字幕翻译
                        <div className={styles.item} onClick={() => {
                            if (!this.state.subtitlesSwitchLoading) {
                                this.setState({ switchSubtitles: !this.state.switchSubtitles, }, async () => {
                                    if (!this.state.switchSubtitles) {
                                        this.props.showSummary(false)
                                    } else {
                                        await this.props.showSummary(1)
                                    }
                                    const timer = setTimeout(() => {
                                        clearTimeout(timer)
                                        summary.openOrCloseSummary(this.state.switchSubtitles, this.props.currentTranslationAcccount, this.state.currentLanguage)
                                    }, 200)
                                })
                            } else {
                                this.props.showSummary(true)
                            }
                        }}>
                            <Tooltip overlayClassName="meet-menu-tooltip" color={'rgba(64,64,64,1)'} title={i18n.t('meet.open/closeSubtitle')}>
                                <img className={styles.img1} src={!this.state.switchSubtitles ? require('static/images/meet/subtitles-close.png') : require('static/images/meet/subtitles-open.png')} alt="" />
                            </Tooltip>
                        </div>}
                    {/* 
                    {//我的语言
                        this.state.switchTranslation ? <Popover content={this.languageRender.bind(this)} title={i18n.t('meet.supportLanguage')}
                            trigger={this.state.switchSubtitles ? 'click' : ""} placement="bottom"
                            onVisibleChange={(e) => {
                                if (e) {
                                    this.setState({ tooltipTranslation: false })
                                }
                            }}
                        >
                            <div className={styles.item}>
                                <Tooltip visible={this.state.tooltipTranslation} overlayClassName="meet-menu-tooltip" color={'rgba(64,64,64,1)'} title={i18n.t('meet.myLanguage')}>
                                    <img className={styles.img2}
                                        src={!this.state.switchSubtitles ? require('static/images/meet/translation-disabled.png') : require('static/images/meet/translation.png')} alt=""
                                        onMouseOut={() => this.setState({ tooltipTranslation: false })} onMouseOver={() => this.setState({ tooltipTranslation: true })} />
                                </Tooltip>
                            </div>
                        </Popover> : <div className={styles.item}>
                            <img className={styles.img2} src={(!this.state.switchSubtitles || !this.state.switchTranslation) ? require('static/images/meet/translation-disabled.png') : require('static/images/meet/translation.png')} alt="" />
                        </div>
                    } */}
                    {/* 静音 */}
                    <div className={styles.item} onClick={this.muted.bind(this)}
                    >
                        <Tooltip overlayClassName="meet-menu-tooltip" color={'rgba(64,64,64,1)'} title={i18n.t('meet.mute/eliminateMute')}>
                            <img className={styles.img3} src={muted ? require("static/images/meet/icon-mute.png") : require("static/images/meet/icon-muted.png")} />
                        </Tooltip>
                    </div>
                    {/* 摄像头 */}
                    <div className={styles.item}
                        onClick={this.switchVideo.bind(this)}>
                        <Tooltip overlayClassName="meet-menu-tooltip" color={'rgba(64,64,64,1)'} title={i18n.t('meet.open/closeUpCamera')}>
                            <img className={styles.img5} src={video ? require("static/images/meet/icon-open-video.png") : require("static/images/meet/icon-close-video.png")} />
                        </Tooltip>
                    </div>
                    {/* 共享 */}
                    <Dropdown trigger={["click"]}
                        overlayStyle={{ paddingBottom: "1%", zIndex: 100 }}
                        onVisibleChange={(e) => {
                            if (e) {
                                this.setState({ tooltipShare: false })
                            }
                        }}
                        overlay={
                            <Menu className="meet-footer" style={{
                                display: currentShareMode ||
                                    this.props.videoList.filter(x => x.sharedUser === true).length
                                    ? "none" : "inline-block", background: "rgba(0,0,0,0.8)", borderRadius: '0.14rem'
                            }}>
                                {this.shareScreenRender()}
                            </Menu>} placement="bottomCenter">
                        <div className={styles.item}>
                            <Tooltip visible={this.state.tooltipShare} overlayClassName="meet-menu-tooltip" color={'rgba(64,64,64,1)'}
                                title={i18n.t(shared || (this.props.videoList.filter(x => x.sharedUser === true).length && this.props.isLocalModerator) ? "meet.stopSharing" :
                                    ((currentShareMode === "whiteboard" || this.props.videoList.filter(x => x.sharedUser === true).length) ? "meet.noSharedPermission" : 'meet.sharingScreen'))}>
                                {
                                    shared || (this.props.videoList.filter(x => x.sharedUser === true).length && this.props.isLocalModerator) ? <img className={styles.img6} src={require('static/images/meet/icon-touping-close.png')} alt=""
                                        onClick={() => {
                                            if (shared) {
                                                this.setState({
                                                    shared: !shared,
                                                    currentShareMode: ""
                                                }, () => {
                                                    if (this.state.shared === false) {
                                                        this.props.switchVideo(false)
                                                        return
                                                    }
                                                })
                                            } else {
                                                this.kick()
                                            }
                                        }} onMouseOut={() => this.setState({ tooltipShare: false })}
                                        onMouseOver={() => this.setState({ tooltipShare: true })} /> :
                                        (currentShareMode === "whiteboard" ||
                                            this.props.videoList.filter(x => x.sharedUser === true).length ?
                                            <div style={{ opacity: 0.5 }}>
                                                <img className={styles.img6} src={require('static/images/meet/icon-touping.png')} alt=""
                                                    onMouseOut={() => this.setState({ tooltipShare: false })}
                                                    onMouseOver={() => this.setState({ tooltipShare: true })} />
                                            </div> :
                                            <img className={styles.img6} src={require('static/images/meet/icon-touping.png')} alt=""
                                                onMouseOut={() => this.setState({ tooltipShare: false })}
                                                onMouseOver={() => this.setState({ tooltipShare: true })} />)
                                }
                            </Tooltip>
                        </div>
                    </Dropdown>
                    {/* {// 共享
                        <div className={styles.item} onClick={() => {
                            if (!shared) {
                                const shareUser = this.props.videoList.filter(x => x.sharedUser === true);// 有人共享时，禁止共享
                                if (shareUser.length) {
                                    if (this.props.isLocalModerator) { // 主持人关闭他人共享
                                        this.kick()
                                    }
                                    return
                                }
                            }
                            this.setState({
                                shared: !shared,
                            }, () => {
                                if (this.state.shared === false) {
                                    this.props.switchVideo(false)
                                    return
                                }
                                this.props.switchVideoDesktop(this.state.shared)
                            })
                            //@ts-ignore
                            _hmt.push(['_trackEvent', "icon", "click", 'meet_share_desktop_icon', !shared])
                        }}>
                            <Tooltip overlayClassName="meet-menu-tooltip" color={'rgba(64,64,64,1)'}
                                title={i18n.t(!shared && this.props.videoList.filter(x => x.sharedUser === true).length ? "meet.noSharedPermission" : 'meet.sharingScreen')}>
                                {
                                    shared || (this.props.videoList.filter(x => x.sharedUser === true).length && this.props.isLocalModerator) ?
                                        <img className={styles.img6} src={require('static/images/meet/icon-touping-close.png')} alt="" /> :
                                        (this.props.videoList.filter(x => x.sharedUser === true).length ?
                                            <div style={{ opacity: 0.5 }}>
                                                <img className={styles.img6} src={require('static/images/meet/icon-touping.png')} alt="" />
                                            </div> :
                                            <img className={styles.img6} src={require('static/images/meet/icon-touping.png')} alt="" />)
                                }
                            </Tooltip>
                        </div>
                    } */}
                    {// 更多
                        <Dropdown trigger={["click"]}
                            overlayStyle={{ paddingBottom: "1%" }}
                            visible={this.state.visibleMoreDropdown}
                            onVisibleChange={(e) => {
                                this.setState({ visibleMoreDropdown: e })
                                if (e) {
                                    this.setState({ tooltipMore: false })
                                }
                            }}
                            overlay={
                                <Menu className="meet-footer" style={{ background: "rgba(0,0,0,0.8)", borderRadius: '0.14rem' }}>
                                    {this.moreRender()}
                                </Menu>} placement="bottomCenter">
                            <div className={styles.item}  >
                                <Tooltip visible={this.state.tooltipMore} overlayClassName="meet-menu-tooltip" color={'rgba(64,64,64,1)'} title={i18n.t('meet.more')}>
                                    <img className={styles.img7} src={require('static/images/meet/icon-more.png')} alt=""
                                        onMouseOut={() => this.setState({ tooltipMore: false })} onMouseOver={() => this.setState({ tooltipMore: true })}
                                    />
                                </Tooltip>
                            </div>
                        </Dropdown>
                    }
                </div>
            </div>
            <div className={styles.footerThree}>
                <div style={{
                    width: "3.86rem", height: '0.56rem', position: "relative",
                }}>
                    {(window as any).$environment !== "svmeet" && this.props.videoList.length &&
                        <div className={styles.backgroundArea} style={{
                            height: '0.56rem',
                            width: "2.98rem",
                            // transition: "width 0.2s",
                            // width: rightMenuSize === "small" ? "1.35rem" : (rightInput === "default" ? "2.98rem" : "4.4rem"),
                            overflow: "hidden"
                        }}>
                            {
                                // this.state.showChatMessage !== "" ?
                                <img className={styles.imgJiantou} src={require('static/images/meet/icon-jiantou-r-small.png')} alt=""
                                    style={{ transform: this.state.showChatMessage ? "rotate(90deg)" : "rotate(-90deg)" }}
                                    onClick={this.showOrHideChatMessage.bind(this)}
                                // onMouseOut={() => this.setState({ showChatMessage: '' })}
                                />

                                // <img className={styles.imgJiantou} src={require('static/images/meet/icon-jiantou-r-small.png')} alt=""
                                //     style={{ transform: rightMenuSize === "default" ? "rotate(0deg)" : "rotate(180deg)" }}
                                //     onClick={this.handleRightJiantou.bind(this)}
                                //     onMouseOver={this.howerRightJiantou.bind(this)}
                                //     onMouseOut={() => { isMouseOut = true; }}
                                // />
                            }

                            <span className={styles.line} style={{ height: "0.29rem" }}></span>
                            {
                                // rightMenuSize === "default" && 
                                <input type="text" className={styles.input} placeholder={i18n.t('meet.enterTextHere')}
                                    value={this.state.sendPrivateMessage}
                                    // onBlur={() => {
                                    //     const timer = setTimeout(() => {
                                    //         this.setState({ rightInput: "default" })
                                    //         timer && clearTimeout(timer)
                                    //     }, 300)
                                    // }}
                                    // onFocus={() => this.setState({ rightInput: "focus" }, () => {
                                    //     //@ts-ignore
                                    //     _hmt.push(['_trackEvent', "input", "click", 'meet_chat_input'])
                                    // })}
                                    onChange={(e) => this.setState({ sendPrivateMessage: e.currentTarget.value })}
                                    onKeyDown={async (evt) => {
                                        if (evt.keyCode == 13) {
                                            //回车事件
                                            if (!this.state.sendPrivateMessage.trim()) {
                                                return
                                            }
                                            await this.props.sendTextMessage(this.state.sendPrivateMessage.trim())
                                            this.setState({ sendPrivateMessage: "" })
                                            if (!this.state.showChatMessage) {
                                                this.showOrHideChatMessage()
                                            }
                                        }
                                    }}

                                />
                            }
                            <Tooltip overlayClassName="meet-menu-tooltip" color={'rgba(64,64,64,1)'} title={i18n.t('meet.emoji')}>
                                <img src={require('static/images/meet/icon-hand-like.png')} alt="" onClick={async () => {
                                    this.props.sendTextMessage('👍')
                                    if (!this.state.showChatMessage) {
                                        await this.showOrHideChatMessage()
                                        const tiemr = setTimeout(() => {
                                            this.showOrHideChatMessage()
                                            clearTimeout(tiemr)
                                        }, 2000)
                                    }
                                    //@ts-ignore
                                    _hmt.push(['_trackEvent', "icon", "click", 'meet_praise_icon'])
                                }} />
                            </Tooltip>
                        </div>
                    }
                    {/* 离开会议 */}
                    <div className={styles.item}
                        style={{ position: "absolute", right: 0, top: 0, background: "none" }}
                        onClick={async () => {
                            if (this.props.isLocalModerator && this.props.videoList.length > 1) {
                                let isOpenModal = true;
                                let otherHost = false
                                if (this.props.videoList.length === 2) {
                                    this.props.videoList.forEach(async node => { // 判断是否我自己及我自己的共享
                                        if (node.sharedUser && node.local) {
                                            isOpenModal = false
                                            this.stopMeeting()
                                        }
                                        if (!otherHost && !node.local && node.moderator) { // 除我自己有无其他主持人
                                            otherHost = true
                                        }
                                    })
                                }
                                if (isOpenModal) {
                                    const res = Modal.confirm({
                                        title: null,
                                        icon: null,
                                        className: "cast-modal",
                                        content: <div className="cast-modal-content" >
                                            <div>{i18n.t("dialog.doYouWantEndMeeting")}</div>
                                            <div className="btns">
                                                <div className="btn1" onClick={() => {
                                                    res.destroy()
                                                }}>{i18n.t("cancel")}</div>
                                                <div className="btn2" onClick={() => {
                                                    if (!otherHost) {
                                                        this.setState({ showSelectModeratorModal: true })
                                                    } else {
                                                        this.leaveMeeting()
                                                    }
                                                    res.destroy()
                                                }}>{i18n.t("meet.temporaryLeave")}</div>
                                                <div className="btn2" style={{ background: "#EA402A" }} onClick={async () => {
                                                    this.setState({ showLeaveConfirmModal: false })
                                                    this.stopMeeting()
                                                    res.destroy()
                                                }}>{i18n.t("meet.endTheCurrentMeeting")}</div>
                                            </div>
                                        </div>,
                                    })
                                }
                            } else {
                                this.leaveMeeting()
                            }
                        }}>
                        <Tooltip overlayClassName="meet-menu-tooltip" color={'rgba(64,64,64,1)'} title={i18n.t('meet.goAway')}>
                            <img className={styles.img4} src={require("static/images/meet/icon-leave-meet.png")} />
                        </Tooltip>
                    </div>
                </div>
            </div>
            <Modal visible={this.state.showSelectModeratorModal}
                title={i18n.t('meet.specifyTemporaryHost')}
                footer={null}
                className={styles.moderatorModal}
                onCancel={() => this.setState({ showSelectModeratorModal: false })}
            >
                {
                    this.props.videoList && this.props.videoList.map((x) => {
                        return !x.local && !x.sharedUser && <div key={x.participantId} className={styles.item}>
                            <div className={styles.left}>
                                <img src={x.avatar || require("static/images/default-photo.jpeg")} />
                                <div>
                                    {x._displayName ? x._displayName : i18n.t('meet.stranger')}
                                </div>
                            </div>
                            <div className={styles.mutedBox}>
                                <Button type="primary" onClick={async () => {
                                    await this.props.grantOwner(x.participantId)
                                    this.leaveMeeting()
                                }}>{i18n.t('meet.determine')}</Button>
                            </div>
                        </div>
                    })
                }
            </Modal>
            {/* <Modal visible={this.state.showLeaveConfirmModal}
                title={null}
                footer={null}
                onCancel={() => this.setState({ showLeaveConfirmModal: false })}
                className="cast-modal">
                <div className={styles.confirmMeet}>
                    <Button type="primary" onClick={() => {
                        if (this.props.videoList.length > 1) {
                            this.setState({ showSelectModeratorModal: true })
                        } else {
                            this.props.leaveMeet()
                            this.leavePage()
                        }
                    }}>{i18n.t('meet.temporaryLeave')}</Button>
                    <Button type="primary" onClick={async () => {
                        await this.props.stopMeet();
                        this.setState({ showLeaveConfirmModal: false })
                        this.leavePage()
                    }}>{i18n.t('meet.endTheCurrentMeeting')}</Button>
                </div>
            </Modal> */}
            <div id="NewsToolBoxMeetFooter"></div>
            {/* {this.invitationRender()} */}
        </div>
    }

    // 离开会议
    public leaveMeeting() {
        try {
            this.props.leaveMeet()
            this.leavePage()
            this.props.meetingSocket && this.props.meetingSocket.close();
            (window as any).websocketLive = null
        } catch (e) {
            console.log(e)
        }
    }

    // 结束会议
    public async stopMeeting() {
        try {
            await this.props.stopMeet()
            this.leavePage()
            this.props.meetingSocket && this.props.meetingSocket.close();
            (window as any).websocketLive = null
        } catch (e) {
            console.log(e)
        }
    }

    // 踢共享的用户
    public kick() {
        const target = this.props.videoList.filter(x => x.sharedUser === true)
        if (target.length) {
            const res = Modal.confirm({
                title: null,
                icon: null,
                className: "cast-modal",
                content: <div className="cast-modal-content">
                    <div>{i18n.t("kickShareUser", { name: target[0].realName ? target[0].realName : (target[0]._displayName.indexOf("userId-") === -1 ? i18n.t('meet.sharingOfName', { name: target[0]._displayName }) : target[0]._displayName) })}</div>
                    <div className="btns">
                        <div className="btn1" onClick={() => {
                            res.destroy()
                        }}>{i18n.t("cancel")}</div>
                        <div className="btn2" onClick={() => {
                            if (target[0]._displayName.indexOf("userId-") === -1) { // 手机端/大屏
                                this.props.sendCloseDesktopCommand(target[0].participantId)
                            } else {// web/electron共享用户
                                this.props.kickParticipant(target[0].participantId)
                            }
                            res.destroy()
                        }}>{i18n.t("meet.determine")}</div>
                    </div>
                </div>,
            })
        }
    }

    // 更多
    public moreRender() {
        return <div className={styles.moreModal}>
            {/* <div className={styles.moreItem} onClick={() => {
                this.setState({ openPictureInPicture: !this.state.openPictureInPicture, visibleMoreDropdown: false }, async () => {
                    // 开启画中画
                    if (this.state.openPictureInPicture) {
                        this.props.setMode("pictureInPicture")
                    } else {
                        this.props.setMode("speaker")
                    }
                })
            }}>
                <img src={require('static/images/meet/icon-quanping.png')} alt="" className={styles.icon} />
                <div>{this.state.openPictureInPicture ? i18n.t('meet.closePictureInPicture') : i18n.t('meet.openPictureInPicture')}</div>
            </div> */}
            <div className={styles.moreItem} onClick={() => {
                this.setState({ isFullScreen: !this.state.isFullScreen, visibleMoreDropdown: false }, async () => {
                    await this.isFullscreen(this.state.isFullScreen)
                    // await this.props.hideMenu()
                    //@ts-ignore
                    _hmt.push(['_trackEvent', "menu", "click", 'meet_menu_more_full_screen', this.state.isFullScreen])
                })
            }}>
                <img src={require('static/images/meet/icon-quanping.png')} alt="" className={styles.icon} />
                <div>{this.state.isFullScreen ? i18n.t('meet.exitFullScreen') : i18n.t('meet.openFullScreen')}</div>
            </div>
            {/* <div className={styles.moreItem} onClick={() => {
                this.props.setMoreMenu('bg')
                //@ts-ignore
                _hmt.push(['_trackEvent', "menu", "click", 'meet_menu_more_background_set'])
            }}>
                <img src={require('static/images/meet/icon-toggle-bg.png')} alt="" className={styles.icon} />
                <div>{i18n.t('meet.switchBackground')}</div>
            </div>
            {<div className={styles.moreItem} onClick={() => {
                if (!this.state.subtitlesSwitchLoading) {
                    this.setState({ switchSubtitles: !this.state.switchSubtitles }, () => {
                        summary.openOrCloseSummary(this.state.switchSubtitles)
                    })
                } else {
                    this.props.showSummary()
                }
            }}>
                <img src={require('static/images/meet/icon-zimu.png')} alt="" className={styles.icon} />
                <div>{this.state.switchSubtitles ? i18n.t('meet.closeSubtitle') : i18n.t('meet.openSubtitle')}</div>
            </div>} */}

            {/* {
                this.state.switchTranslation ? <Popover content={this.languageRender.bind(this)} title={i18n.t('meet.supportLanguage')} trigger={this.state.switchSubtitles ? 'click' : ""} placement="left">
                    <div className={styles.moreItem + " " + (!this.state.switchSubtitles ? styles.moreItemDisabled : "")}
                    >
                        <img src={require('static/images/meet/icon-letter-translation.png')} alt="" className={styles.icon} />
                        <div>{i18n.t('meet.myLanguage')}</div>
                    </div>
                </Popover> : <div className={styles.moreItem + " " + ((!this.state.switchSubtitles || !this.state.switchTranslation) ? styles.moreItemDisabled : "")}>
                    <img src={require('static/images/meet/icon-letter-translation.png')} alt="" className={styles.icon} />
                    <div>{i18n.t('meet.myLanguage')}</div>
                </div>
            } */}
            <div className={styles.moreItem} onClick={() => {
                this.setState({ visibleMoreDropdown: false })
                this.props.showHideReportProblem()
            }}>
                <img src={require('static/images/meet/icon-wenti.png')} alt="" className={styles.icon} />
                <div>{i18n.t('meet.reportProblem')}</div>
            </div>
            <div className={styles.moreItem} onClick={() => {
                this.setState({ visibleMoreDropdown: false })
                this.props.setMoreMenu('set')
                //@ts-ignore
                _hmt.push(['_trackEvent', "menu", "click", 'meet_menu_more_set'])
            }}>
                <img src={require('static/images/set/icon-shezhi.png')} alt="" className={styles.icon} />
                <div>{i18n.t('set')}</div>
            </div>
            {// 会议录制
                this.props.isLocalModerator &&
                <div className={styles.moreItem} onClick={() => {
                    this.setState({ visibleMoreDropdown: false })
                    const recordData = this.props.recordData
                    if (recordData && recordData.open) {
                        if (recordData.socket) { // 结束云端录制
                            summary.openOrCloseRecord(false, recordData.recordingMethod)
                            // recordData.socket.send(JSON.stringify({
                            //     type: recordData.recordingMethod === 1 ? 'stopVideoRecord' : "stopAudioRecord"
                            // }));
                            // recordData.socket.close()
                            this.endRecordingUi(recordData.recordingMethod)
                        } else { // 结束本地录制
                            if (recordData.recordingMethod === 1) {     // 视频
                                endRecord(() => {
                                    this.endRecordingUi(recordData.recordingMethod)
                                })
                            } else {// 音频
                                endRecordAudio(() => {
                                    this.endRecordingUi(recordData.recordingMethod)
                                })
                            }
                        }
                    } else { // 打开
                        this.props.showHideMeetingRecording()
                    }
                }}>
                    <img src={require('static/images/set/icon-luping.png')} alt="" className={styles.icon} />
                    <div>{i18n.t((this.props.recordData && this.props.recordData.open) ? 'meet.closeRecording' : 'meet.meetingRecording')}</div>
                </div>
            }
            {/* <Popover placement="right" trigger={["hover"]}
                        content={<div id="shareScreenModePopover" className={styles.shareScreenMode}>
                            <div onClick={this.websocketCloudRecording.bind(this)}>{i18n.t('remoteRecording')}</div>
                            <div onClick={() => {
                                const isSafariBrowser = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
                                if (isSafariBrowser) {
                                    message.info(i18n.t('meet.onlySupportsChromeBrowser'))
                                    return
                                }
                                this.setState({ visibleMoreDropdown: false })
                                if (!this.state.recordScreen) {
                                    startRecord(this.props.videoList, this.state.meetingData.theme || "",
                                        () => {
                                            this.setState({ recordScreen: true })
                                            this.startRecordingUi()
                                        }, () => {
                                            this.endRecordingUi()
                                        })
                                }
                            }}>{i18n.t('localRecording')}</div>
                        </div>}>
                       
                    </Popover> */}
            {/* {// 开始录音
                this.props.isLocalModerator && <div className={styles.moreItem} onClick={() => {
                    const isSafariBrowser = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
                    if (isSafariBrowser) {
                        message.info(i18n.t('meet.onlySupportsChromeBrowser'))
                        return
                    }
                    this.setState({ visibleMoreDropdown: false })
                    if (!this.state.recordAudio) {
                        startRecordAudio(this.props.videoList, this.state.meetingData.theme || "",
                            () => {
                                this.setState({ recordAudio: true })
                                this.props.sendMediaRecorderCommand({
                                    status: true,
                                    userType: "user",
                                    type: this.state.recordScreen ? "all" : "audio"
                                })
                                videoLayout.mediaRecorderPromptUI({
                                    status: true,
                                    userType: "moderator",
                                    type: this.state.recordScreen ? "all" : "audio"
                                })
                            })
                    } else {
                        endRecordAudio(() => {
                            this.setState({ recordAudio: false })
                            this.props.sendMediaRecorderCommand({
                                status: this.state.recordScreen ? true : false,
                                userType: "user",
                                type: this.state.recordScreen ? "video" : "audio"
                            })
                            videoLayout.mediaRecorderPromptUI({
                                status: this.state.recordScreen ? true : false,
                                userType: "moderator",
                                type: this.state.recordScreen ? "video" : "audio"
                            })
                        })
                    }
                }}>
                    <img src={require('static/images/set/icon-luyin.png')} alt="" className={styles.icon} />
                    <div>{this.state.recordAudio ? i18n.t('meet.stopRecordingAudio') : i18n.t('meet.startRecordingAudio')}</div>
                </div>
            } */}
            { // 共享设置
                this.state.shared && <Popover placement="right" trigger={["hover"]}
                    content={<div id="shareScreenModePopover" className={styles.shareScreenMode}>
                        <div onClick={() => {
                            this.props.setDesktopParams(0)
                            this.setState({ currentDesktopMode: 0, visibleMoreDropdown: false })
                        }} style={{ color: this.state.currentDesktopMode === 0 ? "#0091FF" : "" }}>{i18n.t('shareScreenMode1')}</div>
                        <div onClick={() => {
                            this.props.setDesktopParams(1)
                            this.setState({ currentDesktopMode: 1, visibleMoreDropdown: false })
                        }} style={{ color: this.state.currentDesktopMode === 1 ? "#0091FF" : "" }}>{i18n.t('shareScreenMode2')}</div>
                        <div onClick={() => {
                            this.props.setDesktopParams(2)
                            this.setState({ currentDesktopMode: 2, visibleMoreDropdown: false })
                        }} style={{ color: this.state.currentDesktopMode === 2 ? "#0091FF" : "" }}>{i18n.t('shareScreenMode3')}</div>
                    </div>}>
                    <div className={styles.moreItem}>
                        <img src={require('static/images/meet/icon-share-set.png')} alt="" className={styles.icon} />
                        <div>{i18n.t('shareSet')}</div>
                    </div></Popover>
            }
            {/* { // 批注
                this.state.shared && this.state.currentShareMode !== "whiteboard" && <div className={styles.moreItem} onClick={() => {
                    this.setState({ switchAnnotation: !this.state.switchAnnotation }, () => {
                        if (this.state.switchAnnotation) {
                            videoLayout.createAnnotateButton(() => {
                                videoLayout.removeAnnotateButton()
                                this.startAnnotate()
                            })
                        } else {
                            videoLayout.removeAnnotateButton()
                        }
                    })
                }}>
                    <img src={require('static/images/meet/icon-help.png')} alt="" className={styles.icon} />
                    <div>{this.state.switchAnnotation ? i18n.t('endAnnotate') : i18n.t('startAnnotate')}</div>
                </div>
            } */}
            {// 带宽质量
                <Popover placement="right" trigger={["hover"]}
                    content={<div id="shareScreenModePopover" className={styles.shareScreenMode}>
                        {/* <div onClick={() => {
                            this.setState({ bandwidthQuality: 3, visibleMoreDropdown: false }, () => this.props.setReceiverConstraints())
                        }} style={{ color: this.state.bandwidthQuality === 3 ? "#0091FF" : "" }}>{i18n.t('veryGood')}</div> */}
                        <div onClick={() => {
                            this.setState({ bandwidthQuality: 2, visibleMoreDropdown: false }, () => this.props.setReceiverConstraints())
                        }} style={{ color: this.state.bandwidthQuality === 2 ? "#0091FF" : "" }}>{i18n.t('Good')}</div>
                        <div onClick={() => {
                            this.setState({ bandwidthQuality: 1, visibleMoreDropdown: false }, () => this.props.setReceiverConstraints([], 1))
                        }} style={{ color: this.state.bandwidthQuality === 1 ? "#0091FF" : "" }}>{i18n.t('Medium')}</div>
                        <div onClick={() => {
                            this.setState({ bandwidthQuality: 0, visibleMoreDropdown: false }, () => this.props.setReceiverConstraints([], 0))
                        }} style={{ color: this.state.bandwidthQuality === 0 ? "#0091FF" : "" }}>{i18n.t('Low')}</div>
                    </div>}>
                    <div className={styles.moreItem}>
                        <img src={require('static/images/meet/icon-share-set.png')} alt="" className={styles.icon} />
                        <div>{i18n.t('bandwidthQuality')}</div>
                    </div></Popover>
            }
            { // 查看帮助
                this.props.isLocalModerator && <div className={styles.moreItem} onClick={() => {
                    this.props.setShowGuide()
                }}>
                    <img src={require('static/images/meet/icon-help.png')} alt="" className={styles.icon} />
                    <div>{i18n.t('meet.seeHelp')}</div>
                </div>
            }
            {// 加入设备
                <Popover placement="right" trigger={["hover"]}
                    content={<div id="shareScreenModePopover" className={styles.shareScreenMode}>
                        <div className={styles.moreItemDisabled}>{i18n.t('myDeviceList')}</div>
                        <div onClick={() => {
                            this.props.showJoinDevice()
                            this.setState({ visibleMoreDropdown: false })
                        }}>{i18n.t('joinManually')}</div>
                    </div>}>
                    <div className={styles.moreItem}>
                        <img src={require('static/images/set/icon-camera.png')} alt="" className={styles.icon} style={{ width: 12 }} />
                        <div>{i18n.t('joinDevice')}</div>
                        <span className="testMark">内测</span>
                    </div>
                </Popover>
            }
            {/* 
            {
                !this.props.isLocalModerator && <div className={styles.moreItem} onClick={this.goRemoteMeet.bind(this)}>
                    <img src={require('static/images/meet/icon-help.png')} alt="" className={styles.icon} />
                    <div>切换远场</div>
                </div>
            } */}

            {/* {
                this.props.e2eeSupported ? <div className={styles.moreItem}>
                    <div>警告:不是所有参加会议的人都支持程度不同的加密方式。如果你选中了这个装置并显示匹配的参与者将无法看到和听到任何东西</div>
                    <div>开启端对端加密</div>
                </div> : ''
            } */}
            {/* <div className={styles.moreItem + " " + styles.moreItemDisabled} onClick={this.switchLive.bind(this)}>
                <img src={require('static/images/meet/icon-quanping.png')} alt="" className={styles.icon} />
                <div>{this.state.switchLive ? '停止录屏' : '开始录屏'} </div>
            </div> */}
        </div >
    }

    // 停止录制UI
    public endRecordingUi(recordingMethod) {
        this.props.sendMediaRecorderCommand({
            status: false,
            userType: "user",
            type: recordingMethod === 1 ? "video" : "audio"
        })
        videoLayout.mediaRecorderPromptUI({
            status: false,
            userType: "moderator",
            type: recordingMethod === 1 ? "video" : "audio"
        })
    }

    public shareScreenRender() {
        const { shared } = this.state;
        return <div className={styles.moreModal}>
            {
                (this.props.myPermission.screenSharing === 1 && !this.props.isLocalModerator) ?
                    <div className={styles.moreItem} style={{ opacity: 0.5 }} onClick={this.preventSharingTips.bind(this, 'video')}>
                        <div>{i18n.t('shareScreenContent')}</div>
                    </div> :
                    <div className={styles.moreItem}
                        onClick={() => {
                            if (!shared) {
                                const shareUser = this.props.videoList.filter(x => x.sharedUser === true);
                                if (shareUser.length) {
                                    return
                                }
                            }
                            this.setState({
                                shared: !shared,
                                currentShareMode: "content"
                            }, () => {
                                if (this.state.shared === false) {
                                    this.props.switchVideo(false)
                                    return
                                }
                                this.props.switchVideoDesktop(this.state.shared)
                            })
                            // @ts-ignore
                            _hmt.push(['_trackEvent', "icon", "click", 'meet_share_desktop_icon', !shared])
                        }}>
                        <div>{i18n.t('shareScreenContent')}</div>
                    </div>
            }
            {
                (this.props.myPermission.screenSharing === 1 && !this.props.isLocalModerator) ?
                    <div className={styles.moreItem} style={{ opacity: 0.5 }} onClick={this.preventSharingTips.bind(this, 'video')}>
                        <div>{i18n.t('shareVideo')}</div>
                    </div> : <div className={styles.moreItem} onClick={() => {
                        if (!shared) {
                            const shareUser = this.props.videoList.filter(x => x.sharedUser === true);
                            if (shareUser.length) {
                                return
                            }
                        }
                        this.setState({
                            shared: !shared,
                            currentShareMode: "video",
                            currentDesktopMode: 1
                        }, () => {
                            if (this.state.shared === false) {
                                this.props.switchVideo(false)
                                return
                            }
                            this.props.switchVideoDesktop(this.state.shared)
                        })
                        // @ts-ignore
                        _hmt.push(['_trackEvent', "icon", "click", 'meet_share_desktop_icon', !shared])
                    }}>
                        <div>{i18n.t('shareVideo')}</div>
                    </div>
            }

            {
                (this.props.myPermission.whiteboard === 1 && !this.props.isLocalModerator) ?
                    <div className={styles.moreItem} style={{ opacity: 0.5 }} onClick={this.preventSharingTips.bind(this, 'whiteboard')}>
                        <div>{i18n.t('sharedWhiteboard')}</div>
                    </div> : <div className={styles.moreItem} onClick={() => {
                        if (this.state.currentShareMode === "whiteboard") return
                        this.openWhiteboard(false, true)
                    }}>
                        <div>{i18n.t('sharedWhiteboard')}</div>
                    </div>
            }
        </div>
    }

    public languageRender() {
        return <div className={styles.languageRender}>
            {
                Object.keys(this.state.language).map(ele => {
                    return <div key={ele} className={this.state.currentLanguage === ele ? styles.activeLanguage : ""}
                        onClick={this.language.bind(this, ele)}>{this.state.language[ele].title}</div>
                })
            }
        </div>
    }

    // 开启白板
    public openWhiteboard(annotation: boolean, activelyOpen: boolean) {
        this.setState({ currentShareMode: "whiteboard" })
        if (activelyOpen) {
            this.whiteboardModal = Modal.confirm({
                width: '3.5rem',
                title: null,
                icon: null,
                className: "subtitle-loading-modal",
                content: <div className="subtitle-loading-modal-content">
                    <img src={require("static/images/home/icon-close-white.png")} alt="" className="closeImg" onClick={() => this.whiteboardModal.destroy()} />
                    <Spin indicator={<LoadingOutlined style={{ fontSize: '0.24rem', color: "#fff" }} spin />} />
                    <div className="title" dangerouslySetInnerHTML={{ __html: i18n.t("loading") }}></div>
                </div>
            });
        }
        this.props.sharedWhiteboard({
            annotation,
            activelyOpen
        })
    }

    // 白板iframe加载成功
    public whiteboardIframeCallback(userType) {
        this.whiteboardModal && this.whiteboardModal.destroy()
        if (userType === 1) {
            this.setState({ isWhiteboardCreator: true })
        } else {
            this.setState({ isWhiteboardCreator: false })
        }
    }

    // 开启批注
    public startAnnotate() {
        // 启动批注
        const target = this.props.videoList.filter(x => x.sharedUser == true)
        if (target.length) {
            target[0].list.forEach(element => {
                if (element.videoType === "desktop") {
                    // const video1 = document.getElementById('localVideo_container') as any
                    const video = document.createElement("video")
                    video.autoplay = true
                    video.id = "annotateVideo"
                    // video.style.cssText = `height:${target[0].videoHeight ? target[0].videoHeight : 1080}px;width:${target[0].videoWidth ? target[0].videoWidth + 'px' : '100%'}`
                    video.style.width = window.innerWidth + "px"
                    video.style.height = window.innerHeight + "px"
                    video.style.objectFit = "contain"
                    document.body.appendChild(video)
                    element.jitsiTrack.attach(video)
                    if (video) {
                        const canvas = document.createElement("canvas") as any;
                        canvas.width = document.body.offsetWidth//video.offsetWidth ;
                        canvas.height = document.body.offsetHeight //video.offsetHeight //;
                        const timer = setTimeout(() => {
                            canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
                            // 开启白板
                            this.annotationImg = canvas.toDataURL("image/png")
                            // const img = document.createElement("img")
                            // img.style.width = "100%"
                            // img.src = this.annotationImg
                            // document.body.appendChild(img)
                            this.openWhiteboard(true, true)
                            document.body.removeChild(video)
                            clearTimeout(timer)
                        }, 600)

                        // 关闭共享省流
                        // this.props.setSpeakerSenderVideoConstraint(360)
                    }
                }
            });
        }
    }

    public confirmCloseWhiteboard() {
        if (!this.state.switchAnnotation) {// 关闭白板
            if (this.state.isWhiteboardCreator || this.props.isLocalModerator) {
                const res = Modal.confirm({
                    title: null,
                    icon: null,
                    className: "whiteboard-modal",
                    width: "300px",
                    zIndex: 10001,
                    content: <div className="whiteboard-modal-content" >
                        <div className="title">{i18n.t("closeWhiteboard")}</div>
                        <div>{i18n.t("closeWhiteboardDes")}</div>
                        <div>{i18n.t("closeWhiteboardDes2")}</div>
                        <div className="btns">
                            <div className="btn1" onClick={() => {
                                res.destroy()
                            }}>{i18n.t("cancel")}</div>
                            <div className="btn2" onClick={async () => {
                                res.destroy()
                                this.props.whiteboardData && this.props.whiteboardData.closeWhiteboard()
                                this.setState({ currentShareMode: "" })
                            }}>{i18n.t("shutDown")}</div>
                        </div>
                    </div>,
                });
            } else {
                this.props.whiteboardData && this.props.whiteboardData.closeWhiteboard()
                this.setState({ currentShareMode: "" })
            }
        } else {// 关闭批注
            const res = Modal.confirm({
                title: null,
                icon: null,
                className: "whiteboard-modal",
                width: "400px",
                zIndex: 10001,
                content: <div className="whiteboard-modal-content" >
                    <div className="title">{i18n.t("closeAnnotateConfirm")}</div>
                    <div>{i18n.t("closeAnnotateDes")}</div>
                    <div className="btns">
                        <div className="btn1" onClick={() => {
                            res.destroy()
                        }}>{i18n.t("cancel")}</div>
                        <div className="btn2" onClick={async () => {
                            res.destroy()
                            this.props.whiteboardData && this.props.whiteboardData.closeWhiteboard()
                            this.setState({ currentShareMode: "" })
                            // 打开批注按钮
                            if (this.state.isWhiteboardCreator) {
                                videoLayout.createAnnotateButton(() => {
                                    videoLayout.removeAnnotateButton()
                                    this.startAnnotate()
                                })
                            } else {
                                this.setState({ switchAnnotation: false })
                            }
                        }}>{i18n.t("shutDown")}</div>
                        {
                            this.props.isLocalModerator && <div className="btn2" style={{ width: "160px" }} onClick={async () => {
                                // 保存至云端
                                this.props.whiteboardData && this.props.whiteboardData.postSaveWhiteboardImg(this.props.iframeWhiteboard, async () => {
                                    res.destroy()
                                    this.props.whiteboardData && this.props.whiteboardData.closeWhiteboard()
                                    this.setState({ currentShareMode: "" })
                                    // 打开批注按钮
                                    if (this.state.isWhiteboardCreator) {
                                        videoLayout.createAnnotateButton(() => {
                                            videoLayout.removeAnnotateButton()
                                            this.startAnnotate()
                                        })
                                    } else {
                                        this.setState({ switchAnnotation: false })
                                    }
                                })
                            }}>{i18n.t("closeAnnotateDes2")}</div>
                        }
                    </div>
                </div>,
            });
        }
    }

    public language(lang) {
        summary.openOrCloseSummary(this.state.switchSubtitles, this.props.currentTranslationAcccount, lang)
        this.setState({ switchTranslation: false, currentLanguage: lang }, () => {
            this.setState({ switchTranslation: true })
        })
    }

    // 开启关闭直播
    public switchLive() {
        this.setState({ switchLive: !this.state.switchLive })
    }

    // 开关音频
    public muted() {
        if (this.props.myPermission.speak === 1 && this.state.muted) {// 禁止解除静音
            message.success({
                content: i18n.t('unmuteRaiseHand'),
                icon: <span></span>,
                style: {
                    marginTop: '30vh',
                },
                duration: 5,
                className: "meet-custom-message",
                key: "unmuteRaiseHand"
            })
            return
        }
        this.setState({
            muted: !this.state.muted,
        }, () => {
            this.props.setLacalMute(this.state.muted)
        })
        // @ts-ignore
        _hmt.push(['_trackEvent', "icon", "click", 'meet_menu_mute_icon', this.state.muted])
    }

    public setMode() {
        const { mode, setMode } = this.props
        setMode(mode === 'gallery' ? 'pictureInPicture' : mode === "speaker" ? "gallery" : "speaker")
        // this.setState({ openPictureInPicture: false })
        //@ts-ignore
        _hmt.push(['_trackEvent', "icon", "click", 'menu_mode_icon', mode === 'gallery' ? 1 : 0])
    }

    // 安全
    // public safetyRender() {
    //     return <div className={styles.safetyModal}>
    //         <div className={styles.safetyItem}>
    //             <div>成员入会关闭摄像头</div>
    //             <Switch checkedChildren="是" unCheckedChildren="否"
    //                 onChange={(value) => {
    //                     this.setState({ startCameraSwitch: value }, () => {
    //                         this.props.setStartMutedPolicy(this.state.startCameraSwitch, this.state.startMutedSwitch)
    //                     })
    //                 }} />
    //         </div>
    //         <div className={styles.safetyItem}>
    //             <div>成员入会静音</div>
    //             <Switch checkedChildren="是" unCheckedChildren="否"
    //                 onChange={(value) => {
    //                     this.setState({ startMutedSwitch: value }, () => {
    //                         this.props.setStartMutedPolicy(this.state.startCameraSwitch, this.state.startMutedSwitch)
    //                     })
    //                 }} />
    //         </div>
    //     </div>
    // }

    // 无共享权限提示
    public preventSharingTips(type) {
        message.success({
            content: i18n.t(type === "video" ? 'dialog.onlyHostOpenSharing' : "dialog.onlyHostOpenWhiteboard"),
            icon: <span></span>,
            style: {
                marginTop: '30vh',
            },
            duration: 2,
            className: "meet-custom-message",
            key: "preventSharingTips"
        })
    }

    // 全屏
    public isFullscreen(bool) {
        const docElm = document.documentElement as any;
        if (bool) {
            //W3C
            if (docElm.requestFullscreen) {
                docElm.requestFullscreen();
            }
            //FireFox
            else if (docElm.mozRequestFullScreen) {
                docElm.mozRequestFullScreen();
            }
            //Chrome等
            else if (docElm.webkitRequestFullScreen) {
                docElm.webkitRequestFullScreen();
            }
            //IE11
            else if (docElm.msRequestFullscreen) {
                (document.body as any).msRequestFullscreen();
            }
        } else {
            if ((document as any).hasOwnProperty('exitFullscreen') && document.exitFullscreen) {
                document.exitFullscreen();
            }
            else if ((document as any).msExitFullscreen) {
                (document as any).msExitFullscreen();
            }
            else if ((document as any).mozCancelFullScreen) {
                (document as any).mozCancelFullScreen();
            }
            else if ((document as any).webkitCancelFullScreen) {
                (document as any).webkitCancelFullScreen();
            } else {
                (window.parent as any).showTopBottom();
            }
        }

    }

    // 监听按esc退出全屏
    public eventFullscreenchange() {
        document.addEventListener('fullscreenchange', this.Fn.bind(this));
        document.addEventListener('webkitfullscreenchange', this.Fn.bind(this));
        document.addEventListener('mozfullscreenchange', this.Fn.bind(this));
        document.addEventListener('msfullscreenchange', this.Fn.bind(this));

    }
    public Fn() {
        const fullscreenElement = document.fullscreenElement || (document as any).webkitFullscreenElement || (document as any).mozFullScreenElement || (document as any).webkitIsFullScreen || (document as any).mozFullScreen;
        //全屏按钮切换类
        this.updateWatermarkArea()
        if (fullscreenElement) {
            // this.setState({ isFullScreen: true })
            //目前全屏
        } else {
            this.setState({ isFullScreen: false })
            //目前不是全屏
        }
    }

    // 缩小放大需要更新水印
    public updateWatermarkArea() {
        const list = document.getElementsByClassName("mask_div")
        if (list.length) {
            let parent = list[0].parentElement
            this.removeWatermark()
            watermark(parent, this.props.user.name + i18n.t('xxxwatermark'))
        }
    }

    public removeWatermark() {
        const list = document.getElementsByClassName("mask_div")
        if (list.length) {
            for (let i = 0; i < list.length; i++) {
                list[i].remove()
            }
            this.removeWatermark()
        }
    }

    public switchVideo() {
        if (this.state.switchVideoLoading) {
            return
        }
        this.setState({
            video: !this.state.video,
            isVideoBlurred: false,
            switchVideoLoading: true
        }, () => {
            this.props.switchVideo(this.state.video)
            // @ts-ignore
            _hmt.push(['_trackEvent', "icon", "click", 'meet_menu_switch_camera_icon', this.state.video])
        })
    }

    // public handleRightJiantou() {
    //     const { rightMenuSize } = this.state;
    //     this.setState({ rightMenuSize: rightMenuSize === "default" ? "small" : "default" })
    //     //@ts-ignore
    //     _hmt.push(['_trackEvent', "icon", "click", 'meet_right_arrow_icon'])
    // }

    // public howerRightJiantou() {
    //     const chat_message_contain = document.getElementById('chat_message_contain') as any;
    //     isMouseOut = false;
    //     if (chat_message_contain.style.height !== '0px') return
    //     if (this.state.showChatMessage === "") { // 消息列表收起时，悬浮两秒切成向上箭头
    //         const timer = setInterval(() => {
    //             if (isMouseOut === true) {
    //                 clearTimeout(timer)
    //                 howerTimeOut = 0;
    //                 return
    //             }
    //             howerTimeOut += 1;
    //             if (howerTimeOut === 1) {
    //                 this.setState({ showChatMessage: false })
    //                 clearTimeout(timer)
    //                 howerTimeOut = 0
    //             }
    //         }, 1000)
    //     }
    // }

    public showOrHideChatMessage() {
        this.setState({ showChatMessage: !this.state.showChatMessage }, () => {
            const timer = setTimeout(() => {
                const chat_message_contain = document.getElementById('chat_message_contain')
                if (this.state.showChatMessage === true) {
                    if (chat_message_contain)
                        chat_message_contain.style.height = chat_message_contain.scrollHeight + "px" //'2.54rem'
                } else if (this.state.showChatMessage === false) {
                    if (chat_message_contain)
                        chat_message_contain.style.height = '0px'
                }
                clearTimeout(timer)
            }, 200)
        })
    }

    // 离开页面
    public leavePage() {
        if (this.props.project === "yj") {
            window.parent.postMessage({  //参数是对象
                cmd: 'leave',
                params: {
                    success: true
                }
            }, '*');
            return
        }

        if (window.localStorage.getItem(IS_EXISTING_USER) === "true" || window.localStorage.getItem(IS_EXISTING_USER) === null) {// 注册过的用户
            this.props.history.push(`/feedback/${this.state.meetingData.meetNumber}/${this.state.meetingData.primaryKey}`)
        } else { // 临时用户退出跳到反馈页
            this.props.history.push("/rate-feedback");
        }
        window.localStorage.removeItem(IS_EXISTING_USER)
        window.localStorage.removeItem(CURRENT_MEETING_DATA)
    }

    // 初始化举手
    public raiseHandHandle(bool) {
        if (this.ListModeRef) {
            this.ListModeRef.setState({ raiseHand: bool })
        } else {
            sessionStorage.setItem("raiseHand", String(bool))
        }
    }

    // 去远场参会
    public async goRemoteMeet() {
        this.props.leaveMeet()
        this.leavePage();
        (window as any).websocketLive = this.props.meetingSocket;
        localStorage.setItem(CURRENT_MEETING_DATA, JSON.stringify(this.state.meetingData))
        this.props.history.push(`/live-meeting/${this.state.meetingData.primaryKey}/${this.state.meetingData.meetNumber}/${this.state.meetingData.password}`)
    }

    public componentWillUnmount() {
        this.props.whiteboardData && this.props.whiteboardData.closeWhiteboard()
        window.removeEventListener("keydown", () => { })

        this.isFullscreen(false)
        // 关闭字幕/会议提醒弹框
        notification.close('subtitle')
        window.removeEventListener("resize", this.Fn)
        this.cloudRecordingSocket && this.cloudRecordingSocket.close()
        message.destroy("preventSharingTips")
    }

    private ListModeRef;
    private cloudRecordingSocket;
    private whiteboardModal;
    private annotationImg;
}

export default Index;