import { request } from "utils/request";
import { STORAGE_ACCESS_TOKEN,API_URL } from "config";
import axios from "axios"
import timezone from "moment-timezone";


export async function startRecording(params: any) {
    return (await request.post('/tmedia/jibri/api/v1.0/startService', params)).data;
}

export async function endRecording() {
    return (await request.post('/tmedia/jibri/api/v1.0/stopService')).data;
}

export async function inform(params: any) {
    return (await request.get(`/rttransfer/live/start/${params}`)).data;
}

interface ISpeech {
    id: string;
    speaker: string;
    url: string;
    enableTranslate: boolean
}

/**
 * @name 实时音频流识别接口
 */
export async function speechRealtime(params: ISpeech) {
    return (await request.post(`/rttransfer/0.3/api/speech/realtime`, params)).data;
}

// /**
//  * @name 实时音频流识别记录
//  */
// export async function getSpeechRealtimeRecord(id: string) {
//     return (await request.get(`/rttransfer/0.3/api/speech/realtime/${id}`)).data;
// }

/**
 * @name 批量获取实时音频流识别记录
 */
export async function batchSpeechRealtimeRecord(ids: string[]) {
    return (await request.get(`/rttransfer/0.3/api/speech/realtime`, {
        params: { ids: ids.join(',') }
    })).data;
}


/**
 * @name 实时音频流识别记录文本文件（原文）
 */
export async function getSpeechRealtimeRecordText(id: string, name?: string) {
    return (await request.get(`/rttransfer/0.3/api/speech/realtime/${id}/text/`, {
        headers: {
            responseType: 'blob',
        }
    })).data;
}

/**
 * @name 实时音频流识别记录文本文件(摘要)
 */
export async function getSpeechRealtimeRecordTextSummary(id: string) {
    return (await request.get(`/rttransfer/0.3/api/speech/realtime/${id}/text/summary`, {
        headers: {
            responseType: 'blob',
        }
    })).data;
}

/**
 * @name WebSocket模式实时语音转文字功能开启 (开启websocket前需要先调此接口)
 */

export async function websocketStart(id: string) {
    return (await request.post(`/rttransfer/0.3/api/speech/websocket/${id}`)).data;
}

/**
 * @name WebSocket模式实时语音转文字功能开启 
 */

export async function websocketStop(id: string) {
    return (await request.delete(`/rttransfer/0.3/api/speech/websocket/${id}`)).data;
}

/**
 * @name 实时音频流识别记录文本数据修改
 */
export async function editSpeechRealtimeRecordText(id: string, data: any) {
    return (await request.put(`/rttransfer/0.3/api/speech/realtime/${id}/text/info`, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    })).data;
}


const path = (window as any).$environment === "deve" ? "deve" : "www"

/**
 * @name 翻译地区获取接口
 */
export async function translateLocales() {
    return (await axios.get(`https://${path}.svmeet.com/oversee/api/speech/translate/locales`, {
        headers: {
            timezone: timezone.tz.guess()
        }
    })).data
}


/**
 * @name 实时音频流识别记录文件获取接口
 */
export async function getSpeechRealtimeRecord(meetId: string, userId: string, type: "text" | "word", params?: { token: string, url: string, localList: string[] }) {
    let api = ''
    if (params) { //纪要
        api = `https://${path}.svmeet.com/oversee/api/speech/realtime/record/${meetId}/sv-user-${userId}/${type}?token=${params.token}&url=${params.url}`;
        if (params.localList.length)
            api += `&${params.localList.map(local => 'local=' + local).join('&')}`;
        api += `&timezoneOffset=${new Date().getTimezoneOffset()}`
    } else { // 字幕
        api = `https://${path}.svmeet.com/oversee/api/speech/realtime/record/${meetId}/sv-user-${userId}/${type}?token=${window.localStorage.getItem(STORAGE_ACCESS_TOKEN)}`//&origin=${'https://ml.svmeet.com'}
    }
    return (await axios.get(api, {
        responseType: "blob",
        headers: {
            timezone: timezone.tz.guess()
        }
    })).data
}

/**
 * @name 获取个人在会议中记录信息
 */
export async function getUserMeetRealtimeRecord(userId: string, meetIds: string[]) {
    let params = meetIds.map(meetId => `meetId=${meetId}`).join('&');
    return (await axios.get(`https://${path}.svmeet.com/oversee/api/speech/realtime/record/info/sv-user-${userId}?${params}`, {
        headers: {
            timezone: timezone.tz.guess()
        }
    })).data
}

/**
 * @name 实时音频流识别记录文件删除接口
 */
export async function deleteSpeechRealtimeRecord(meetId: string, userId: string) {
    let api = `https://${path}.svmeet.com/oversee/api/speech/realtime/record/${meetId}/${userId}?token=${window.localStorage.getItem(STORAGE_ACCESS_TOKEN)}`
    return (await axios.delete(api, {
        headers: {
            timezone: timezone.tz.guess()
        }
    })).data
}

/**
 * @name 获取当前会议音转文消费情况
 */
export async function getTransliterationConsumption(key: string) {
    const response = (await request.get(
        `${API_URL}/billing/iot/transliteration/consumption/${key}`)).data;

    return response;
}


