import React from "react";
import { withRouter } from 'react-router-dom';
import i18n from "i18n";
import Modal from "antd/lib/modal"
import { Menu, Dropdown } from 'antd';
import moment from "moment";
import { faqUrl } from "config";
import "./index.css";

class Index extends React.Component<{
    history: any;
    style?: any
    type?: any;
}, any> {
    constructor(props) {
        super(props);
        this.state = {
            svTextModal: false,
        };
    }

    public componentDidMount() {
        if (!this.props.style || (this.props.style && !this.props.style.hasOwnProperty("position"))) {
            this.setSize()
            window.addEventListener("resize", this.setSize)
        }
    }

    public setSize() {
        const downloadPage = document.getElementById("downloadPage")
        const ele = document.getElementById("introduction-component")
        if (window.innerWidth / window.innerHeight > 1.77 && window.innerHeight < 700) {
            if (ele) ele.style.cssText = "position:initial;margin-top:60px";
            if (downloadPage) downloadPage.style.cssText = "height:fit-content;"
        } else {
            if (ele) ele.style.cssText = "position: absolute;bottom: 0.29rem;";
            if (downloadPage) downloadPage.style.cssText = "height:inherit;"
        }
    }

    render() {
        return <div id="introduction-component" className="introduction-component" style={this.props.style}>
            {
                this.props.type !== "new" ?
                    <div className="main">
                        <span onClick={() => this.setState({ svTextModal: true })}>{i18n.t("PrivacyAgreement")}</span>  |  <span><a target="_blank" style={{ color: '#555555' }}
                        href='https://www.swntech.com/#/introduction'>{i18n.t("ProductIntroduction")}</a></span>  |
                 <Dropdown overlay={this.menuRender} placement="topCenter" arrow><span>{i18n.t("Instructions")}</span></Dropdown>  |  <span ><a target="_blank" href={faqUrl} style={{ color: '#555555' }}>{i18n.t("helpCenter")}</a></span>  |  <span onClick={() => this.props.history.push("/problem")}>{i18n.t("problemFeedback")}</span>  |  <span onClick={() => this.props.history.push("/about")}>{i18n.t("AboutUs")}</span>
                    </div> : <div className="main">
                        <span onClick={() => this.setState({ svTextModal: true })}>{i18n.t("PrivacyAgreement")}</span>  | <span onClick={() => this.props.history.push("/problem")}>{i18n.t("problemFeedback")}</span>
                    </div>
            }
            <a className="beian" target="_blank" href="https://beian.miit.gov.cn">©2021-{moment().format("YYYY")} 北京随闻科技有限公司 | 京ICP备18026489号-2</a>

            <Modal visible={this.state.svTextModal} width={'8.0rem'} style={{ padding: "0.2rem" }} footer={null}
                onCancel={() => this.setState({ svTextModal: false })}>
                <iframe style={{ width: "100%", height: '66vh', paddingTop: '20px' }} frameBorder={0} src={`https://www.svmeet.com/suiwen/${i18n.t('privacyUrl')}.html`} ></iframe>
            </Modal>
        </div >

    };

    menuRender() {
        return (<Menu>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href="https://www.svmeet.com:19000/public/resources/WebUserManual.pdf">
                    {i18n.t('WebVersionManual')}
                </a>
            </Menu.Item>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href="https://www.svmeet.com:19000/public/resources/PCUserManual.pdf">
                    {i18n.t('PcVersionManual')}
                </a>
            </Menu.Item>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href="https://www.svmeet.com:19000/public/resources/MobileUserManual.pdf">
                    {i18n.t('MobileVersionManual')}
                </a>
            </Menu.Item>
        </Menu>)
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.setSize)
    }
}

export default withRouter(Index)