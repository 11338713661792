import React from "react";
import i18n from "i18n";
import { connect } from "react-redux";
import { EditOutlined } from "@ant-design/icons";
import styles from "./index.module.css";
import { IUser } from "models/user";
import api from "api";
import message from "antd/lib/message";
import { setUser } from "reduxs/actions/user";
import { LogoutClearStorage } from "config";
import { ResetPassword } from "components";

interface DispatchProps {
    user: IUser;
    setUser: (user: IUser) => void
}

interface IItem {
    phone?: string;
    captcha?: string;
    password?: string;
    checkPassword?: string;
}

interface IState {
    isEditUsername: boolean;
    userName: string;
    form: IItem;
    showPasswordModal: boolean;
    showCheckPwd: boolean;
    captchaTime: string;
    showPwd: boolean;
}

const defaultForm: IItem = {
    phone: "",
    captcha: "",
    password: "",
    checkPassword: "",
}

class Index extends React.Component<DispatchProps, IState> {
    public state: IState = {
        isEditUsername: false,
        userName: "",
        form: defaultForm,
        showPasswordModal: false,
        showCheckPwd: false,
        captchaTime: "",
        showPwd: false
    }
    public render() {
        const { isEditUsername } = this.state;
        return <div className={styles.accountComponent}>
            <img src={require("static/images/home/icon-avatar.png")} alt="" />
            <div className={styles.username}>
                {
                    isEditUsername ? <input
                        type="text"
                        className={styles.inputName}
                        placeholder={i18n.t('user.placeholderUsername')}
                        value={this.state.userName}
                        maxLength={20}
                        onBlur={this.editUserName.bind(this)}
                        onChange={evt => this.setState({ userName: evt.target.value })}
                    /> : <span>{this.props.user.name}</span>
                }
                {
                    !isEditUsername && <EditOutlined onClick={() => this.setState({ isEditUsername: !this.state.isEditUsername, userName: this.props.user.name || "" })} />
                }

            </div>
            {/* <div className={styles.date}>注册日期</div> */}
            {this.props.user.phone && <div className={styles.date}>{i18n.t('user.phone')}：{this.props.user.phone}</div>}
            {this.props.user.email && <div className={styles.date}>{i18n.t('user.email')}：{this.props.user.email}</div>}
            <div className={styles.setPwdBtn} onClick={() => this.setState({ showPasswordModal: true })}>{i18n.t('setLoginPassword')}</div>
            <div className={styles.layoutBtn} onClick={() => {
                LogoutClearStorage()
                window.location.replace(window.location.origin + window.location.pathname + "#/login")
            }}>{i18n.t('signOut')}</div>
            {
                this.state.showPasswordModal && <ResetPassword onCancel={() => this.setState({ showPasswordModal: false })} />
            }
        </div>
    }

    public async editUserName() {
        try {
            const res = await api.user.updateUserName(this.state.userName);
            if (res.code === 200) {
                this.setState({ isEditUsername: false })
                this.props.setUser(res.data)
                message.info(i18n.t('message.modifySuccess'))
            } else {
                message.warn(res.msg)
            }
        } catch (e) {
            message.error(i18n.t('message.requestFailed'))
        }
    }

    // public async editPassword() {
    //     const { form } = this.state;
    //     if (!form.password || !form.checkPassword || !form.captcha) {
    //         message.info("请输入必填项")
    //         return
    //     }
    //     if (form.captcha.length !== 6) {
    //         message.info("请输入6位验证码")
    //         return
    //     }
    //     if ((form.password as any).length < 6 || (form.password as any).length > 20) {
    //         message.info("请输入6-20位密码")
    //         return
    //     }
    //     if (form.password !== form.checkPassword) {
    //         message.info("请确认密码一致")
    //         return
    //     }
    //     const res = await api.user.resetPassword(this.props.user.phone ? "phone" : "email", form)
    //     if (res.code === 200) {
    //         this.setState({ form: defaultForm, showPasswordModal: false })
    //         message.success("修改密码成功")
    //     } else {
    //         message.error(res.msg)
    //     }
    // }

    // public async sendCode() {
    //     let res;
    //     if (this.props.user.phone) {
    //         res = await api.user.sendCode('rp', this.props.user.phone || "")
    //     } else {
    //         res = await api.user.emailSendCode('rp', this.props.user.email || "")
    //     }
    //     if (res.code === 200) {
    //         let num = 100;
    //         const timer = setInterval(() => {
    //             if (num === 1) {
    //                 clearInterval(timer)
    //                 this.setState({ captchaTime: "" })
    //                 return
    //             }
    //             this.setState({ captchaTime: `${--num}秒后重发` })
    //         }, 1000)
    //         message.info(res.msg)
    //     } else {
    //         message.error(res.msg)
    //     }
    // }

    // public setForm(form: IItem) {
    //     this.setState({
    //         form: {
    //             ...this.state.form,
    //             ...form
    //         }
    //     })
    // }
}

export default connect(
    (state: any) => ({
        user: state.user
    }),
    (dispatch) => {
        return {
            setUser: (data: any) => dispatch(setUser(data))
        }
    }
)(Index);