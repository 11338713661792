//@ts-nocheck
import i18n from "i18n";
import moment from "moment";
import { IParticipantItem } from "models/meet";
import Modal from "antd/lib/modal"
import notification from "antd/lib/notification"
import message from "antd/lib/message"
import { logger } from "utils/log";
import { AVRecorder } from "utils/record/av-recorder"
import { ARecorder } from "utils/record/a-recorder"
/**
 * 查询已连接的A/V输入和输出设备
 * 已知设备的状态
 * @returns {Function}
 */
export function getAvailableDevices() {
    return new Promise(resolve => {
        //@ts-ignore
        const { mediaDevices } = JitsiMeetJS;
        if (mediaDevices.isDeviceListAvailable()
            && mediaDevices.isDeviceChangeAvailable()) {
            mediaDevices.enumerateDevices(devices => {
                // dispatch(updateDeviceList(devices));
                resolve(devices);
            });
        } else {
            resolve([]);
        }
    });
}

// 保存文件
async function createFileWriter(extName: string): Promise<FileSystemWritableFileStream> {
    //@ts-ignore
    const fileHandle = await window.showSaveFilePicker({
        suggestedName: extName
    })
    return await fileHandle.createWritable()
}

/**
 * @name 录屏
 */
let mediaRecorderSv: AVRecorder | null = null;
let audioContextSv: any = null;
let displayStreamSv: any = null;
let audioDestination: any = null;
let participantsContext = {}

// 开始录屏
export const startRecord = (participants: IParticipantItem[], theme: string, success: Function, close: Function) => {
    if ('VideoEncoder' in window) {
        console.log("webcodecs is supported.")
    } else {
        if (navigator.userAgent.indexOf("Chrome") === -1) {
            message.warn(i18n.t("message.onlySupportsChromeBrowser1"))
        } else {
            message.warn(i18n.t("message.onlySupportsChromeBrowser1"))
        }
        return
    }
    openIndexedDB('video', async (db, saveInIndexDB) => {
        displayStreamSv = await navigator.mediaDevices.getDisplayMedia({
            video: true,
            audio: {
                echoCancellation: true,
            }
        });
        let outputStream;
        audioContextSv = new AudioContext();
        audioDestination = audioContextSv.createMediaStreamDestination();

        if (displayStreamSv.getAudioTracks().length) {
            let displaySource = audioContextSv.createMediaStreamSource(displayStreamSv);
            displaySource.connect(audioDestination);
        }

        participants.forEach(user => {
            user.list.forEach(item => {
                if (item.mediaType === "audio") {
                    const source = audioContextSv.createMediaStreamSource(item.jitsiTrack.stream)
                    participantsContext[user.userId] = source
                    source.connect(audioDestination)
                }
            })
        })
        outputStream = audioDestination.stream;

        // 混入视频流
        let recordTracks = [];
        outputStream.getAudioTracks().forEach(track => {
            recordTracks.push(track);
        });
        let videoWidth = 1280
        let videoHeight = 720
        displayStreamSv.getVideoTracks().forEach(track => {
            recordTracks.push(track);
            track.onended = () => {
                endRecord(close)
            };
            const params = track.getSettings()
            videoWidth = params.width
            videoHeight = params.height
        });

        let recordStream = new MediaStream(recordTracks);
        mediaRecorderSv = new AVRecorder(recordStream, {
            width: videoWidth,
            height: videoHeight,
            audioCodec: 'aac'
        })
        const recordName = `${moment().format("YYYY:MM:DD HH:mm")} ${theme}-${i18n.t('meet.videoRecording')}.mp4`;
        mediaRecorderSv.onEnded = function () {
            // 清空当前用户保存的录音数据
            db && clearIndexedDB(db, 'video')
        }
        mediaRecorderSv.onError = function () {
            // 清空当前用户保存的录音数据
            endRecord()
            mediaRecorderSv = null
            db && clearIndexedDB(db, 'video')
            message.error(i18n.t("message.onlySupportsChromeBrowser2"))
        }
        await mediaRecorderSv.start()
        const chunks = []
        const timer = setTimeout(async () => {
            clearTimeout(timer)
            if (!mediaRecorderSv) return
            let bool = true
            try {
                const writer = await createFileWriter(recordName)
                const transformer = new TransformStream({
                    async transform(videoFrame, controller) {
                        controller.enqueue(videoFrame);
                        if (saveInIndexDB) {
                            saveInIndexDB({ blob: videoFrame, name: recordName })
                        }
                        if (bool) {
                            bool = false
                            success()
                        }
                    },
                });
                mediaRecorderSv.outputStream.pipeThrough(transformer).pipeTo(writer).catch(console.error)
            } catch (e) {
                console.log(e)
                message.info(i18n.t("message.recordEndMsg"))
                const reader = mediaRecorderSv.outputStream.getReader();
                reader.read().then(function processText({ done, value }) {
                    if (done) {
                        console.log("Stream complete");
                        saveFile(chunks, recordName)
                        return;
                    }
                    chunks.push(value)
                    if (saveInIndexDB) {
                        saveInIndexDB({ blob: value, name: recordName })
                    }
                    if (bool) {
                        bool = false
                        success()
                    }
                    return reader.read().then(processText);
                });
            }
        }, 100)
    })
};

// 结束录制
export async function endRecord(success: Function) {
    if (!mediaRecorderSv) return
    audioContextSv && audioContextSv.close();
    displayStreamSv && displayStreamSv.getTracks().forEach(track => track.stop());
    await mediaRecorderSv?.stop()
    success && message.success(i18n.t("message.recordingFileComplete"))
    for (const key in participantsContext) {
        if (Object.prototype.hasOwnProperty.call(participantsContext, key)) {
            const element = participantsContext[key];
            element.disconnect()
        }
    }
    success && success()
    mediaRecorderSv = null;
    audioContextSv = null;
    displayStreamSv = null;
    audioDestination = null;
    participantsContext = {}
}

// 退出音频流
export function disconnectAudioContext(participantId) {
    // 录屏
    if (participantsContext[participantId]) {
        participantsContext[participantId].disconnect()
    }
    // 录音
    if (participantsContextA[participantId]) {
        participantsContextA[participantId].disconnect()
    }
}

// 加音频流
export function connectAudioContext(participant: IParticipantItem) {
    participant.list.forEach(item => {
        if (item.mediaType === "audio") {
            // 录屏
            if (audioContextSv) {
                const source = audioContextSv.createMediaStreamSource(item.jitsiTrack.stream)
                participantsContext[participant.userId] = source
                source.connect(audioDestination)
            }
            // 录音
            if (audioContextA) {
                const source = audioContextA.createMediaStreamSource(item.jitsiTrack.stream)
                participantsContextA[participant.userId] = source
                source.connect(audioDestinationA)
            }
        }
    })
}

/**
 * @name 录音
 */
let mediaRecorderA: any = null;
let audioContextA: any = null;
let audioDestinationA: any = null;
let participantsContextA: any = {}

// 开始录音
export const startRecordAudio = async (participants: IParticipantItem[], theme: string, success: Function) => {
    if ('AudioEncoder' in window) {
        console.log("webcodecs is supported.")
    } else {
        if (navigator.userAgent.indexOf("Chrome") === -1) {
            message.warn(i18n.t("message.onlySupportsChromeBrowser1"))
        } else {
            message.warn(i18n.t("message.onlySupportsChromeBrowser1"))
        }
        return
    }
    openIndexedDB('audio', async (db, saveInIndexDB) => {
        let outputStream;
        audioContextA = new AudioContext();
        audioDestinationA = audioContextA.createMediaStreamDestination();

        participants.forEach(user => {
            user.list.forEach(item => {
                if (item.mediaType === "audio") {
                    const source = audioContextA.createMediaStreamSource(item.jitsiTrack.stream)
                    participantsContextA[user.userId] = source
                    source.connect(audioDestinationA)
                }
            })
        })
        outputStream = audioDestinationA.stream;

        mediaRecorderA = new ARecorder(outputStream, {
            audioCodec: 'aac'
        })
        await mediaRecorderA.start()
        const recordName = `${moment().format("YYYY:MM:DD HH:mm")} ${theme}-${i18n.t('meet.audioRecording')}.mp4`;
        mediaRecorderA.onEnded = function () {
            // 清空当前用户保存的录音数据
            db && clearIndexedDB(db, 'audio')
        }
        mediaRecorderA.onError = function () {
            // 清空当前用户保存的录音数据
            endRecordAudio()
            mediaRecorderA = null
            db && clearIndexedDB(db, 'audio')
            message.error(i18n.t("message.onlySupportsChromeBrowser2"))
        }
        const chunks = []
        const timer = setTimeout(async () => {
            clearTimeout(timer)
            if (!mediaRecorderA) return
            let bool = true
            try {
                const writer = await createFileWriter(recordName)
                const transformer = new TransformStream({
                    async transform(videoFrame, controller) {
                        controller.enqueue(videoFrame);
                        if (saveInIndexDB) {
                            saveInIndexDB({ blob: videoFrame, name: recordName })
                        }
                        if (bool) {
                            bool = false
                            success()
                        }
                    },
                });
                mediaRecorderA.outputStream.pipeThrough(transformer).pipeTo(writer).catch(console.error)
            } catch (e) {
                console.log(e)
                message.info(i18n.t("message.recordEndMsg"))
                const reader = mediaRecorderA.outputStream.getReader();
                reader.read().then(function processText({ done, value }) {
                    if (done) {
                        console.log("Stream complete");
                        saveFile(chunks, recordName)
                        return;
                    }
                    chunks.push(value)
                    if (saveInIndexDB) {
                        saveInIndexDB({ blob: value, name: recordName })
                    }
                    if (bool) {
                        bool = false
                        success()
                    }
                    return reader.read().then(processText);
                });
            }
        }, 100)
    })
};

// 结束录音
export function endRecordAudio(success: Function) {
    if (!mediaRecorderA) return
    mediaRecorderA && mediaRecorderA.stop();
    audioContextA && audioContextA.close();
    message.success(i18n.t("message.recordingFileComplete"))
    for (const key in participantsContextA) {
        if (Object.prototype.hasOwnProperty.call(participantsContextA, key)) {
            const element = participantsContextA[key];
            element.disconnect()
        }
    }
    success()
    mediaRecorderA = null;
    audioContextA = null;
    audioDestinationA = null;
    participantsContextA = {}
}

// 打开数据库
function openIndexedDB(table, callback) {
    if (!window.indexedDB) {
        console.log("Your browser doesn't support a stable version of IndexedDB. Such and such feature will not be available.");
    } else {
        // 打开or创建数据库
        const request = window.indexedDB.open('recordBlob');
        // 数据库初始化事件
        request.onupgradeneeded = function (event) {
            console.log("数据库初始化事件")
            const db = event.target.result;
            // 创建数据库实例
            db.createObjectStore('audio', { keyPath: 'id', autoIncrement: true });
            db.createObjectStore('video', { keyPath: 'id', autoIncrement: true });
        }
        request.onsuccess = function (event) {
            const db = event.target.result;
            callback(db, function saveInIndexDB(data) {
                //调用我们自己添加的方法来处理一个事务
                db.transaction([table], "readwrite").objectStore(table).add(data)
            })
        }
        request.onerror = function (event) {
            logger.error('报错了', event)
            callback()
        }
    }
}

// 读取数据库:检查录音录像文件是否未保存
export function readRecordIndexedDB() {
    const request = window.indexedDB.open('recordBlob', 1);
    request.onupgradeneeded = function (event) {
        console.log("数据库初始化事件")
        const db = event.target.result;
        // 创建数据库实例
        db.createObjectStore('audio', { keyPath: 'id', autoIncrement: true });
        db.createObjectStore('video', { keyPath: 'id', autoIncrement: true });
    }
    request.onsuccess = function (event) {
        const db = event.target.result;
        // 获取录音所有数据
        try {
            const transaction = db.transaction(['audio']);
            const objectStore = transaction.objectStore('audio');
            const request = objectStore.getAll()
            request.onsuccess = function (event) {
                if (request.result && request.result.length) {
                    console.log(request.result);
                    Modal.confirm({
                        title: i18n.t("message.unsavedRecordingFile"),
                        icon: null,
                        okText: i18n.t("message.save"),
                        cancelText: i18n.t("message.notSave"),
                        async onOk() {
                            let recordedChunks = [];
                            let name = ""
                            request.result.forEach(element => {
                                recordedChunks.push(element.blob)
                                if (!name) {
                                    name = element.name
                                }
                            });
                            await saveFile(recordedChunks, name)
                            clearIndexedDB(db, 'audio')
                        },
                        onCancel() {
                            clearIndexedDB(db, 'audio')
                        },
                    });
                } else {
                    console.log('未获得数据记录');
                }
            };
        } catch (e) {
            console.log(e)
        }
    }
    const request1 = window.indexedDB.open('recordBlob', 1);
    request1.onsuccess = function (event) {
        const db = event.target.result;
        // 获取录像所有数据
        try {
            const transaction1 = db.transaction(['video']);
            const objectStore1 = transaction1.objectStore('video');
            const request1 = objectStore1.getAll()
            request1.onsuccess = function (event) {
                if (request1.result && request1.result.length) {
                    console.log(request1.result);
                    Modal.confirm({
                        title: i18n.t("message.unsavedRecordVideoFile"),
                        icon: null,
                        okText: i18n.t("message.save"),
                        cancelText: i18n.t("message.notSave"),
                        async onOk() {
                            let recordedChunks = [];
                            let name = ""
                            request1.result.forEach(element => {
                                recordedChunks.push(element.blob)
                                if (!name) {
                                    name = element.name
                                }
                            });
                            await saveFile(recordedChunks, name, 'video')
                            clearIndexedDB(db, 'video')
                        },
                        onCancel() {
                            clearIndexedDB(db, 'video')
                        },
                    });
                } else {
                    console.log('未获得数据记录');
                }
            };
        } catch (e) {
            console.log(e)
        }
    }
}
// 清除数据
function clearIndexedDB(db, table) {
    //删除存储空间全部记录
    const store = db.transaction(table, 'readwrite').objectStore(table);
    store.clear();
    console.log('已删除存储空间' + table + '全部记录');
    db.close();
}

// 保存文件
async function saveFile(recordedChunks, recordName, type) {
    console.log("类型", type, recordName)
    // 保存文件
    const newBlob = new Blob(recordedChunks, { type: type === "video" ? 'video/mp4' : "audio/mp3" });
    const url = URL.createObjectURL(newBlob);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.download = recordName;
    a.click();
    window.URL.revokeObjectURL(url);
}

/**
 * @name 远程协助控制
 */
export class authorizeRemoteAssistance {
    constructor(props) {
        this.userId = props.userId
        this.room = props.room;
        this.props = props
        this.showVideoModal = false
        this.down = false
        this.createButton(() => {
            if (this.showVideoModal) {
                this.showVideoModal = false
                this.removeEvent()
                try { this.modalVideo && document.body.removeChild(this.modalVideo) } catch { }
                this.modalVideo = null
                notification.close('connectionQualityOperationDelay') // 关闭提示
            } else {
                this.showVideoModal = true
                this.createBox()
            }
            this.updateButton()
        })
        this.onresize = this.onresize.bind(this)
        this.mousemoveAssis = this.mousemoveAssis.bind(this)
        this.mouseclickAssis = this.mouseclickAssis.bind(this)
        this.mousedblclickAssis = this.mousedblclickAssis.bind(this)
        this.keydownAssis = this.keydownAssis.bind(this)
        this.mousedownAssis = this.mousedownAssis.bind(this)
        this.mouseupAssis = this.mouseupAssis.bind(this)
        this.copyAssis = this.copyAssis.bind(this)
        this.pasteAssis = this.pasteAssis.bind(this)
        this.desktopEle = document.getElementById('speakerVideo')
        window.addEventListener("resize", this.onresize)
    }
    mousemoveAssis(e) {
        console.log(this.down)
        if (this.down) return
        const x = e.offsetX;
        const y = e.offsetY;
        const x1 = Math.round(x / (this.video?.offsetWidth / this.props.screenWidth));
        const y1 = Math.round(y / (this.video?.offsetHeight / this.props.screenHeight));
        this.room && this.room.sendEndpointMessage(this.userId, {
            type: "remote-assistance",
            result: {
                type: "mousemove",
                x: x1,
                y: y1,
            }
        })
    }
    mouseclickAssis(e) {
        console.log(e)
        this.room && this.room.sendEndpointMessage(this.userId, {
            type: "remote-assistance",
            result: {
                type: "mouseclick",
            }
        })
    }
    mousedblclickAssis(e) {
        console.log(e)
        this.room && this.room.sendEndpointMessage(this.userId, {
            type: "remote-assistance",
            result: {
                type: "mousedblclick",
            }
        })
    }
    keydownAssis(e) {
        console.log(e)
        this.room && this.room.sendEndpointMessage(this.userId, {
            type: "remote-assistance",
            result: {
                type: "keytap",
                key: e.key,
                alt: e.altKey,
                ctrl: e.ctrlKey,
                meta: e.metaKey,
                shift: e.shiftKey,
            }
        })
    }
    mousedownAssis(e) {
        let which = "left"
        if (e.which === 2) {
            which = "middle"
        } else if (e.which === 3) {
            which = "right"
        }
        this.room && this.room.sendEndpointMessage(this.userId, {
            type: "remote-assistance",
            result: {
                type: "mousedown",
                which
            }
        })
    }
    mouseupAssis(e) {
        let which = "left"
        if (e.which === 2) {
            which = "middle"
        } else if (e.which === 3) {
            which = "right"
        }
        this.room && this.room.sendEndpointMessage(this.userId, {
            type: "remote-assistance",
            result: {
                type: "mouseup",
                which
            }
        })
    }
    sendTextAssis(text) {
        this.room && this.room.sendEndpointMessage(this.userId, {
            type: "remote-assistance",
            result: {
                type: "text",
                text
            }
        })
    }
    copyAssis() {
        this.room && this.room.sendEndpointMessage(this.userId, {
            type: "remote-assistance",
            result: {
                type: "copy",
            }
        })
    }
    pasteAssis(e) {
        const clipdata = e.clipboardData || window.clipboardData
        const text = clipdata.getData('text/plain')
        this.room && this.room.sendEndpointMessage(this.userId, {
            type: "remote-assistance",
            result: {
                type: "paste",
                text
            }
        })
    }
    remove() {
        this.video && this.removeEvent()
        this.removeButton()
        try { this.modalVideo && document.body.removeChild(this.modalVideo) } catch { }
        window.removeEventListener("resize", this.onresize)
    }

    removeEvent() {
        this.video.removeEventListener("mousemove", this.mousemoveAssis)
        this.video.removeEventListener("click", this.mouseclickAssis)
        this.video.removeEventListener("dblclick", this.mousedblclickAssis)
        this.video.removeEventListener("mousedown", this.mousedownAssis)
        this.video.removeEventListener("mouseup", this.mouseupAssis)
        window.onmousewheel = document.onwheel = function (e) { }
        window.removeEventListener("keydown", this.keydownAssis)
    }

    // 添加操作按钮
    createButton(callback) {
        const div = document.createElement('div')
        div.className = "meet-assist-handle";
        div.id = 'meet-assist-handle';
        div.innerHTML = `<img id="assist-img" src=${require("static/images/icon-desktop-assist1.png")} alt="" /><span id="assist-text">${i18n.t('remoteAssistance')}</span>`
        div.onclick = function () {
            callback()
        }
        // div.onmousedown = (event) => {
        //     this.down = true
        //     if (!this.showVideoModal) return
        //     event = event || window.event
        //     console.log(event)
        //     let offsetX = event.offsetX
        //     let offsetY = event.offsetY

        //     const div1 = document.getElementById("meet-assist-handle")
        //     document.onmousemove = event => {
        //         console.log(event, 1, div1, event.clientX - offsetX, event.clientY - offsetY)
        //         if ((event.clientX > window.innerWidth - div1.offsetWidth) || (event.clientX < 0) || (event.clientY > window.innerHeight) || (event.clientY < 0)) return
        //         div1.style.bottom = "auto"
        //         div1.style.right = "auto"
        //         div1.style.left = `${event.clientX - offsetX}px`
        //         div1.style.top = `${event.clientY - offsetY}px`
        //     }

        //     document.onmouseup = () => {
        //         console.log(2)
        //         document.onmousemove = null
        //         document.onmouseup = null
        //         this.down = false
        //     }
        // }
        // div.onmouseup = () => {
        //     this.down = false
        // }
        document.body.appendChild(div)
    }
    // 更新按钮
    updateButton() {
        const text = document.getElementById('assist-text')
        if (text) {
            text.innerHTML = i18n.t(this.showVideoModal ? "backMeeting" : 'remoteAssistance')
        }
        const img = document.getElementById('assist-img')
        if (img) {
            img.src = require(this.showVideoModal ? "static/images/icon-back.png" : "static/images/icon-desktop-assist1.png")
        }
    }
    // 移除操作按钮
    removeButton() {
        const ele = document.getElementById('meet-assist-handle')
        if (ele) {
            document.body.removeChild(ele)
        }
    }
    // 创建video框
    createBox() {
        this.modalVideo = document.createElement("div")
        this.modalVideo.style.cssText = "position: fixed;top: 0;left: 0;z-index: 100;width: 100vw;height: 100vh;display: flex; align-items: center; justify-content: center;background: rgba(0, 0, 0, 1);overflow: hidden;";
        this.modalVideo.oncontextmenu = function () { return false }
        document.body.appendChild(this.modalVideo)
        this.video = document.createElement("video")
        this.video.srcObject = this.desktopEle.srcObject;
        this.setVideoStyle(this.props.desktopUser)
        this.modalVideo.appendChild(this.video)
        const timer = setTimeout(() => {
            this.video.play()
            clearTimeout(timer)
        }, 300)
        // 鼠标移动
        this.video.addEventListener("mousemove", this.mousemoveAssis)
        // 鼠标单击
        this.video.addEventListener("click", this.mouseclickAssis)
        // 鼠标双击
        this.video.addEventListener("dblclick", this.mousedblclickAssis)
        // 鼠标按下
        this.video.addEventListener("mousedown", this.mousedownAssis)
        // 鼠标抬起
        this.video.addEventListener("mouseup", this.mouseupAssis)
        // 复制
        this.video.addEventListener("copy", this.copyAssis)
        // 粘贴
        this.video.addEventListener("paste", this.pasteAssis)
        // 监听鼠标滚动
        window.onmousewheel = document.onwheel = (e) => {
            console.log(e)
            const x = e.wheelDeltaX;
            const y = e.wheelDeltaY;
            this.room && this.room.sendEndpointMessage(this.userId, {
                type: "remote-assistance",
                result: {
                    type: "scrollmouse",
                    x: x,
                    y: y,
                }
            })
        }
        // 监听按键
        window.addEventListener("keydown", this.keydownAssis)
    }
    // 更新video节点大小
    setVideoStyle(user) {
        if (this.video) {
            this.video.style.cssText = this.getStyle(user)
        }
    }
    // 获取video的宽高
    getStyle(data) {
        if (data.videoWidth && data.videoHeight) {
            if (data.videoWidth > data.videoHeight) {
                if (data.videoHeight * (document.body.offsetWidth / data.videoWidth) > document.body.offsetHeight) {// 宽设为100%时:video的实际高>可展示的区域
                    return "width:auto;height:100%"
                } else {
                    return "width:100%;height:auto"
                }
            } else {
                if (data.videoWidth * (document.body.offsetHeight / data.videoHeight) > document.body.offsetWidth) {// 高设为100%时:video的实际宽>可展示的区域
                    return "width:100%;height:auto"
                } else {
                    return "width:auto;height:100%"
                }
            }
        } else {
            return "width:100%;height:auto"
        }
    }
    // 窗口大小改变时
    onresize() {
        this.setVideoStyle(this.props.desktopUser)
    }
}