
import { request } from "../utils/request";
import { API_URL } from "config";
/**
 * @name 保存白板图片
 */
export async function saveWhiteboardPng(meetingInfoId: string, png) {
    const response = (await request.post(
        `${API_URL}/whiteboard/save/png/${meetingInfoId}`,
        {
            png
        }
    )).data;
    return response;
}