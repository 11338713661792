// @ts-nocheck

/**
 * @name 会议连接参数
 */
export const connectionOptions = svmeet_config ? svmeet_config : {
    hosts: {
        domain: "meet.jitsi",
        muc: "muc.meet.jitsi"
    },

    // serviceUrl: 'https://meeting.swnai.com/http-bind', // 线上
    // serviceUrl: 'https://deve.svmeet.com/http-bind', // deve
    // serviceUrl: 'https://mldev.svmeet.com/http-bind', // ml
    clientNode: 'http://jitsi.org/jitsimeet',
    enableLayerSuspension: true,
    enableNoisyMicDetection: true,// 开启啸叫检测模块
    enableNoAudioDetection: true,
    testing: {
        // Disables the End to End Encryption feature. Useful for debugging
        disableE2EE: false,
    }
};

/**
 * @name 会议默认背景
 */
export const defaultMeetBackgroundImages = [
    {
        id: Math.random(),
        path: '/resources/public/images/video_1.jpg'
    },
    // {
    //     id: Math.random(),
    //     path: '/resources/public/images/video_2.jpg'
    // },
    {
        id: Math.random(),
        path: '/resources/public/images/video_3.jpg'
    }, {
        id: Math.random(),
        path: '/resources/public/images/video_4.jpg'
    }, {
        id: Math.random(),
        path: '/resources/public/images/video_5.jpg'
    },
]

/**
 * @name 设置共享屏幕参数
 */

export function desktopApplyConstraints(type, jitsiTrack) {
    console.log(type, jitsiTrack, window.screen.height, window.devicePixelRatio)
    if (type === 0) { // 内容模式
        jitsiTrack.track.contentHint = "detail";
        jitsiTrack.track.applyConstraints({
            height: {
                max: 1080
            },
            advanced: [
                { frameRate: 5 }
            ],
            frameRate: 5,
        })
    } else if (type === 1) {// 视频流畅优先
        jitsiTrack.track.contentHint = "motion";
        jitsiTrack.track.applyConstraints({
            width: 1280,
            height: 720,
            advanced: [
                { frameRate: 30 }
            ],
            frameRate: 30,
        })
    } else if (type === 2) {//视频清晰优先
        jitsiTrack.track.contentHint = "motion";
        jitsiTrack.track.applyConstraints({
            width: 1920,
            height: 1080,
            advanced: [
                { frameRate: 15 }
            ],
            frameRate: 15,
        })
    }
}