import React from "react";
import i18n from "i18n";
import { Introduction } from "components";
import api from "api";
import moment from "moment";
import "./index.less"

interface IState {
    showMobileQrcode: boolean;
    showMobileQrcodeIos: boolean;
    menu: 1 | 2 | 3;
    versionList: Array<{
        version: string;
        url: string;
        env: "M" | "D" | "R";
        updateDate: string;
    }>
}

class Index extends React.Component<any, IState> {

    constructor(props) {
        super(props)
        this.env = (window as any).$environment === "ml" ? "M" : ((window as any).$environment === "deve" ? "D" : "R")
    }

    public state: IState = {
        showMobileQrcode: false,
        showMobileQrcodeIos: false,
        menu: 1,
        versionList: [
            {
                version: "",
                url: "",
                env: "R",
                updateDate: ""
            },
            {
                version: "",
                url: "",
                env: "R",
                updateDate: ""
            }, {
                version: "",
                url: "",
                env: "R",
                updateDate: ""
            }, {
                version: "",
                url: "",
                env: "R",
                updateDate: ""
            }, {
                version: "",
                url: "",
                env: "R",
                updateDate: ""
            }
        ]
    }
    public componentDidMount() {
        this.changeMenu(this.state.menu)
    }
    public async getVersion(platform, index) {
        const { versionList } = this.state
        const res = await api.common.getVersion(platform)
        if (res.code === 200) {
            versionList[index] = res.data
            versionList[index].env = this.env;
            versionList[index].updateDate = moment(res.data.updateDate).format("YYYY" + i18n.t("date.year") + "MM" + i18n.t("date.month") + "DD" + i18n.t("date.day"))
            this.setState({ versionList })
        }
    }
    public render() {
        const { menu, versionList } = this.state;
        return <div id="downloadPage" className='downloadPage'>
            <div className='nav'>
                <img src={require("static/images/logo2.png")} alt="" />
                <div onClick={() => window.history.back()}>{i18n.t("return")}</div>
            </div>
            <div className='banner'>
                <span>{i18n.t('downloadMsg')}</span>
                <span>{i18n.t("downloadMsg1")}</span>
            </div>
            <div className='menu'>
                <div className={menu === 1 ? 'active' : ""} onClick={this.changeMenu.bind(this, 1)}>{i18n.t("PCSide")}</div>
                <div className={menu === 2 ? 'active' : ""} onClick={this.changeMenu.bind(this, 2)}>{i18n.t("mobileTerminal")}</div>
                {/* <div className={menu === 3 ? 'active' : ""} onClick={this.changeMenu.bind(this, 3)}>{i18n.t("largeScreenTerminal")}</div> */}
            </div>
            <div className='list'>
                {menu === 1 && <React.Fragment>
                    <div className="item">
                        <div className="platform">
                            <img src={require("static/images/download/window.png")} alt="" />
                            <span>Windows {i18n.t("version")}</span>
                        </div>
                        <a className="btn" href={versionList[0].url + "/svmeet_setup_" + versionList[0].version + "_" + versionList[0].env + ".exe"}>{i18n.t("download")}</a>
                        <div className="version">{i18n.t("version")}：{versionList[0].version}</div>
                        <div className="version">{versionList[0].updateDate}</div>
                    </div>
                    <div className="item">
                        <div className="platform">
                            <img src={require("static/images/download/mac.png")} alt="" />
                            <span>macOS {i18n.t("version")}</span>
                        </div>
                        <a className="btn" href={versionList[1].url + "/svmeet_setup_" + versionList[1].version + "_" + versionList[1].env + ".dmg"}>{i18n.t("download")}</a>
                        <div className="version">{i18n.t("version")}：{versionList[1].version}</div>
                        <div className="version">{versionList[1].updateDate}</div>
                    </div>
                </React.Fragment>}
                {menu === 2 && <React.Fragment>
                    <div className="item item2">
                        <div className="platform">
                            <img src={require("static/images/download/android1.png")} alt="" />
                            <span>Android {i18n.t("version")}</span>
                        </div>
                        <a className="btn" href={versionList[2].url}>{i18n.t("download")}</a>
                        <div className="version">{i18n.t("version")}：{versionList[2].version}</div>
                        <div className="version">{versionList[2].updateDate}</div>
                        <img className='qrImg'
                            src={require(`static/images/home/download-${(window as any).$environment || "ml"}.png`)} alt="" />
                    </div>
                    <div className="item item2">
                        <div className="platform">
                            <img src={require("static/images/download/ios1.png")} alt="" />
                            <span>iOS {i18n.t("version")}</span>
                        </div>
                        <div className="text" >苹果商店搜索【随闻悦见】</div>
                        {/* <a className="btn" href={versionList[3].url + "/svmeet_setup_" + versionList[0].version + "_" + versionList[0].env + ".dmg"}>{i18n.t("download")}</a> */}
                        <div className="version">{i18n.t("version")}：{versionList[3].version}</div>
                        <div className="version">{versionList[3].updateDate}</div>
                        <img className='qrImg'
                            src={require(`static/images/home/download-${(window as any).$environment === "svmeet" ? "ios" : (window as any).$environment}.png`)} alt="" />
                    </div>
                </React.Fragment>}
                {menu === 3 && <React.Fragment>
                    <div className="item">
                        <div className="platform">
                            <img src={require("static/images/download/androidTV.png")} alt="" />
                            <span>安卓TV {i18n.t("version")}</span>
                        </div>
                        <a className="btn" href={versionList[4].url}>{i18n.t("download")}</a>
                        <div className="version">{i18n.t("version")}：{versionList[4].version}</div>
                        <div className="version">{versionList[4].updateDate}</div>
                    </div>
                </React.Fragment>}
            </div>
            {/* <div className={styles.list}>
                <a className={styles.item} href={windowsUrl}>
                    <img src={require("static/images/download/windows.png")} alt="" />
                    <div className={styles.text}>Windows</div>
                    <div className={styles.btn}>{i18n.t('download')}</div>
                </a>
                <a className={styles.item} href={macUrl}>
                    <img style={{ width: "0.52rem", height: "0.64rem" }} src={require("static/images/download/ios.png")} alt="" />
                    <div className={styles.text}>macOS</div>
                    <div className={styles.btn}>{i18n.t('download')}</div>
                </a>
                <div className={styles.item} onMouseLeave={() => this.setState({ showMobileQrcode: false })}>
                    {
                        this.state.showMobileQrcode ? <img className={styles.qrImg}
                            src={require(`static/images/home/download-${(window as any).$environment || "ml"}.png`)} alt="" /> :
                            <img style={{ width: "0.6457rem", height: "0.70rem" }} src={require("static/images/download/android.png")} alt="" />
                    }
                    <div className={styles.text}>Android</div>
                    {
                        !this.state.showMobileQrcode && <div className={styles.btn}
                            onMouseOver={() => this.setState({ showMobileQrcode: true })}
                        >{i18n.t('scanIt')}</div>
                    }

                    {
                        this.state.showMobileQrcode && <div className={styles.text2}>{i18n.t('phoneScanDownload')}</div>
                    }

                </div>
                <div className={styles.item} style={{ height: this.state.showMobileQrcodeIos ? "3.57rem" : "2.80rem" }} onMouseLeave={() => this.setState({ showMobileQrcodeIos: false })}>
                    {
                        this.state.showMobileQrcodeIos ? <img className={styles.qrImg}
                            src={require(`static/images/home/download-${(window as any).$environment === "svmeet" ? "ios" : (window as any).$environment}.png`)} alt="" /> :
                            <img style={{ width: "0.6457rem", height: "0.70rem" }} src={require("static/images/download/appstore.png")} alt="" />
                    }
                    <div className={styles.text}>iOS</div>
                    {
                        !this.state.showMobileQrcodeIos && <div className={styles.btn}
                            onMouseOver={() => this.setState({ showMobileQrcodeIos: true })}
                        >{i18n.t('scanIt')}</div>
                    }
                    {
                        this.state.showMobileQrcodeIos && <div className={styles.text2}>{i18n.t('phoneScanDownload')}</div>
                    }
                    {
                        this.state.showMobileQrcodeIos && <div className={styles.or} style={{ marginTop: "0.16rem" }}>{i18n.t("or")}</div>
                    }
                    {
                        this.state.showMobileQrcodeIos && <div className={styles.or}>{i18n.t("AppSearch")}</div>
                    }
                    {
                        this.state.showMobileQrcodeIos && <div className={styles.or}>“{i18n.t("svmeet")}”</div>
                    }
                </div>
                <a className={styles.item} href={androidTvUrl}>
                    <img style={{ width: "0.80rem", height: "0.60rem" }} src={require("static/images/download/tv.png")} alt="" />
                    <div className={styles.text}>Android  TV</div>
                    <div className={styles.btn}>{i18n.t('download')}</div>
                </a>
            </div> */}
            <Introduction style={{ position: "initial", padding: "0.40rem 0 0.15rem" }} />
        </div>
    }

    public changeMenu(value) {
        this.setState({ menu: value })
        if (value === 1) {
            this.getVersion('winPC', 0)
            this.getVersion('macPC', 1)
        } else if (value === 2) {
            this.getVersion('android', 2)
            this.getVersion('ios', 3)
        } else {
            this.getVersion('swnTv', 4)
        }
    }

    private env;
}

export default Index;