
import React from "react";
import { withRouter } from 'react-router-dom';
import message from "antd/lib/message"
import Button from "antd/lib/button";
import Modal from "antd/lib/modal";
import api from "api"
import i18n from "i18n";
import { PHONE_RESEND_VCODE_TIME, EMAIL_RESEND_VCODE_TIME, LogoutClearStorage } from "config";
import { connect } from "react-redux";
import { IUser } from "models/user";
import { setUser } from "reduxs/actions/user";
import "./index.less";

interface DispatchProps {
    user: IUser;
    setUser: (user: IUser) => void
}
interface IItem {
    captcha?: string; // 手机验证码
    phone?: string;
    captchaEmail?: string; // 邮箱验证码
}
interface IState {
    form: IItem;
    captchaTime: string;// 手机号验证码倒计时
    submitting: boolean;
    captchaTimeEmail: string;
    phoneAreaCode: string; // 地区码的key
    phoneAreaCodeValue: string;// 地区码
    defaultPhoneAreaCode: string;
    defaultPhoneAreaCodeValue: string;
    // codeList: any[], // 支持的地区码
    imgCode: string; // 邮箱图片验证码
    phoneImgCode: string;// 手机图片验证码
    verificationImg: { id: string; img: string }; // 邮箱图片
    verificationImg2: { id: string; img: string };// 手机图片
}

const defaultForm: IItem = {
    captcha: "",
    phone: "",
    captchaEmail: ""
}

class Index extends React.Component<{
    type: string;
    onCancel: () => void;
    title?: string;
} & DispatchProps & any, IState> {

    state: IState = {
        form: defaultForm,
        captchaTime: "",
        submitting: false,
        captchaTimeEmail: "",
        phoneAreaCode: "",
        phoneAreaCodeValue: "",
        defaultPhoneAreaCode: "",
        defaultPhoneAreaCodeValue: "",
        // codeList: []
        imgCode: "",
        phoneImgCode: "",
        verificationImg: { id: "", img: "" },
        verificationImg2: { id: "", img: "" },
    }

    public async componentDidMount() {
        let code = "";
        navigator.languages.forEach(lang => {
            if (code === "") {
                if (lang.split('-').length === 2) {
                    code = lang.split('-')[1]
                }
            }
        })
        if (!code) return
        const defaultCode = await api.common.getAreaCode("", code)
        if (defaultCode.code === 200) {
            if (defaultCode.data.length) {
                this.setState({
                    phoneAreaCode: defaultCode.data[0].primaryKey,
                    phoneAreaCodeValue: defaultCode.data[0].phoneAreaCode,
                    defaultPhoneAreaCode: defaultCode.data[0].primaryKey,
                    defaultPhoneAreaCodeValue: defaultCode.data[0].phoneAreaCode
                })
            }
        }
        this.getVerificationImg(1)
        this.getVerificationImg(2)
    }

    public render() {
        const { form, captchaTime, captchaTimeEmail, imgCode, phoneImgCode, verificationImg, verificationImg2 } = this.state;
        return <Modal title={i18n.t('dialog.bindPhone')} visible={true} width={"400px"} closable={false}
            className="bindPhone-component"
            footer={null}>
            <div className="title">{i18n.t("dialog.bindPhoneMessage")}</div>
            <div className='form'>
                <div className='formItem'>
                    <div className='label'>{i18n.t('login.accountNumber')}</div>
                    <div className='inputWrapper'>
                        <div className='label account' style={{ width: "fit-content", maxWidth: "fit-content" }}>{this.props.user.email}</div>
                    </div>
                </div>
                <div className='formItem'>
                    <div className='label'>{i18n.t("user.email") + i18n.t('login.imageVerificationCode')}</div>
                    <div className='inputWrapper'
                        style={{ display: "flex" }}>
                        <input
                            style={{ marginRight: 10, width: "135px" }}
                            type="text"
                            className='input'
                            placeholder={i18n.t('login.pleaseEnterImgVerificationCode')}
                            value={imgCode}
                            maxLength={4}
                            onChange={evt => {
                                const value = evt.target.value.trim();
                                if (/^[a-zA-Z0-9]{0,4}$/.test(value)) {
                                    this.setState({ imgCode: value })
                                }
                            }}
                        />
                        {verificationImg.img && <img src={'data:image/png;base64,' + verificationImg.img} alt="" className='verificationImg' onClick={this.getVerificationImg.bind(this, 1)} />}
                    </div>
                </div>
                <div className='formItem'>
                    <div className='label'>{i18n.t("user.email") + i18n.t('login.verificationCode')}</div>
                    <div className='inputWrapper'
                        style={{ display: "flex" }}>
                        <input
                            style={{ marginRight: 10, width: "135px" }}
                            type="text"
                            className='input'
                            placeholder={i18n.t('login.pleaseEnterVerificationCode')}
                            value={form.captchaEmail}
                            maxLength={6}
                            onChange={evt => {
                                const value = evt.target.value;
                                if (/^[0-9]{0,6}$/.test(value)) {
                                    this.setForm({ captchaEmail: value });
                                }
                            }}
                        />
                        <Button
                            className={captchaTimeEmail ? 'captchaBtnActive' : 'captchaBtn'}
                            disabled={!!captchaTimeEmail}
                            onClick={this.sendEmailCode.bind(this)}><span dangerouslySetInnerHTML={{ __html: captchaTimeEmail ? captchaTimeEmail : i18n.t('login.getVerificationCode') }}></span></Button>
                    </div>
                </div>
                <div className='formItem'>
                    <div className='label'>{i18n.t('user.phone')}</div>
                    <div className='inputWrapper' >
                        {/* <Select showSearch
                            className="code-search"
                            style={{ width: 80 }}
                            suffixIcon={<span></span>}
                            optionFilterProp="children"
                            dropdownStyle={{ width: 400 }}
                            onChange={(e) => {
                                const target = this.state.codeList.filter(x => x.primaryKey === e)
                                if (target.length) {
                                    this.setState({ phoneAreaCode: e, phoneAreaCodeValue: target[0].phoneAreaCode })
                                }
                            }}
                            filterOption={true}
                            optionLabelProp="label"
                            value={this.state.phoneAreaCode}
                            dropdownMatchSelectWidth={200}
                            onSearch={(e) => {
                                this.setState({ phoneAreaCode: e })
                            }}
                            onBlur={(e) => {
                                if (!this.state.phoneAreaCode)
                                    this.setState({
                                        phoneAreaCode: this.state.defaultPhoneAreaCode,
                                        phoneAreaCodeValue: this.state.defaultPhoneAreaCodeValue
                                    })
                            }}
                        >
                            {
                                this.state.codeList.map(node => {
                                    return <Select.Option key={node.primaryKey} label={node.phoneAreaCode} value={node.primaryKey}>
                                        {node.phoneAreaCode} {node.country}
                                    </Select.Option>
                                })
                            }
                        </Select> */}
                        <input
                            type="text"
                            className='input'
                            placeholder={i18n.t('message.pleaseEnterPhoneNumber')}
                            value={form.phone}
                            maxLength={this.state.phoneAreaCodeValue === "+86" ? 11 : 100}
                            onChange={evt => {
                                let value = evt.target.value.trim()
                                if (this.state.phoneAreaCodeValue === "+86") {
                                    if (!/^[0-9]{0,11}$/.test(value)) {
                                        return
                                    }
                                } else {
                                    if (/[\u4E00-\u9FA5a-zA-Z]/g.test(value)) {
                                        return
                                    }
                                }
                                this.setForm({ phone: value })
                            }}
                        />
                    </div>
                </div>
                <div className='formItem'>
                    <div className='label'>{i18n.t("cellPhone") + i18n.t('login.imageVerificationCode')}</div>
                    <div className='inputWrapper'
                        style={{ display: "flex" }}>
                        <input
                            style={{ marginRight: 10, width: "135px" }}
                            type="text"
                            className='input'
                            placeholder={i18n.t('login.pleaseEnterImgVerificationCode')}
                            value={phoneImgCode}
                            maxLength={4}
                            onChange={evt => {
                                const value = evt.target.value.trim();
                                if (/^[a-zA-Z0-9]{0,4}$/.test(value)) {
                                    this.setState({ phoneImgCode: value })
                                }
                            }}
                        />
                        {verificationImg2.img && <img src={'data:image/png;base64,' + verificationImg2.img} alt="" className='verificationImg' onClick={this.getVerificationImg.bind(this, 2)} />}
                    </div>
                </div>
                <div className='formItem'>
                    <div className='label'>{i18n.t("cellPhone") + i18n.t('login.verificationCode')}</div>
                    <div className='inputWrapper'
                        style={{ display: "flex" }}>
                        <input
                            style={{ marginRight: 10, width: "135px" }}
                            type="text"
                            className='input'
                            placeholder={i18n.t('login.pleaseEnterVerificationCode')}
                            value={form.captcha}
                            maxLength={6}
                            onChange={evt => {
                                const value = evt.target.value;
                                if (/^[0-9]{0,6}$/.test(value)) {
                                    this.setForm({ captcha: value });
                                }
                            }}
                            onKeyDown={(e) => {
                                const evt = window.event || e;
                                if ((evt as any).keyCode == 13) {
                                    this.bindPhone()
                                }
                            }}
                        />
                        <Button
                            className={captchaTime ? 'captchaBtnActive' : 'captchaBtn'}
                            disabled={!!captchaTime}
                            onClick={this.sendPhoneCode.bind(this)}><span dangerouslySetInnerHTML={{ __html: captchaTime ? captchaTime : i18n.t('login.getVerificationCode') }}></span></Button>
                    </div>
                </div>
                <div className="btns">
                    <Button className="btn1" onClick={() => {
                        LogoutClearStorage()
                        this.props.history.push('/login')
                    }}>{i18n.t('signOut')}</Button>
                    <Button type="primary" className="btn" loading={this.state.submitting} onClick={this.bindPhone.bind(this)}>{i18n.t('meet.submit')}</Button>
                </div>
            </div>
        </Modal>
    }
    // 发送邮箱验证码
    public async sendEmailCode() {
        const email = this.props.user.email
        if (email) {
            const { imgCode, verificationImg } = this.state;
            if (!imgCode) {
                message.warn(i18n.t('login.pleaseEnterImgVerificationCode'))
                return
            }
            const res = await api.user.emailSendCode('bind', this.props.user.email || "", verificationImg.id, imgCode)
            if (res.code === 200) {
                let num = EMAIL_RESEND_VCODE_TIME;
                const timer = setInterval(() => {
                    if (num === 1) {
                        clearInterval(timer)
                        this.setState({ captchaTimeEmail: "" })
                        return
                    }
                    this.setState({ captchaTimeEmail: i18n.t('login.reissueAfterNSeconds', { num: --num }) })
                }, 1000)
                this.getVerificationImg(1)
            }
        }
    }
    // 发送手机号验证码
    public async sendPhoneCode() {
        const { phoneImgCode, verificationImg2, form } = this.state;
        if (form.phone) {
            if (!phoneImgCode) {
                message.warn(i18n.t('login.pleaseEnterImgVerificationCode'))
                return
            }
            const res = await api.user.sendCode('bind', form.phone || "", verificationImg2.id, phoneImgCode)
            if (res.code === 200) {
                let num = PHONE_RESEND_VCODE_TIME;
                const timer = setInterval(() => {
                    if (num === 1) {
                        clearInterval(timer)
                        this.setState({ captchaTime: "" })
                        return
                    }
                    this.setState({ captchaTime: i18n.t('login.reissueAfterNSeconds', { num: --num }) })
                }, 1000)
                this.getVerificationImg(2)
            }
        } else {
            message.warn(i18n.t('message.pleaseEnterPhoneNumber'))
        }
    }
    // 绑定手机号
    public async bindPhone() {
        const { form } = this.state;
        if (!form.phone) {
            message.info(i18n.t('message.pleaseEnterPhoneNumber'))
            return
        }
        if (this.state.phoneAreaCodeValue === "+86" && !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(form.phone)) { // 对+86的手机号做验证
            message.warn(i18n.t('message.pleaseEnterValidPhoneNum'))
            return
        }
        if (form.captcha?.length !== 6 || form.captchaEmail?.length !== 6) {
            message.info(i18n.t('message.6digitVerificationCode'))
            return
        }
        this.setState({ submitting: true }, async () => {
            try {
                const res = await api.user.bindPhone({
                    phone: form.phone,
                    areaCode: this.state.phoneAreaCodeValue,
                    authenticateCode: form.captchaEmail,
                    email: this.props.user.email,
                    code: form.captcha
                })
                if (res.code === 200) {
                    this.props.setUser(res.data)
                    this.props.onCancel()
                    message.success(i18n.t('message.bindSuccess'))
                } else {
                    if (res.code === 10014) {
                        message.warn(i18n.t("message.phoneNumberIsBound"))
                    } else {
                        message.error(res.msg || i18n.t("message.requestFailed"))
                    }
                }
                this.setState({ submitting: false })
            } catch (e) {
                message.error(i18n.t("message.requestFailed"))
                this.setState({ submitting: false })
            }
        })
    }

    // 获取图片验证码
    public async getVerificationImg(value) {
        const res = await api.common.getVerificationImg()
        if (res.code === 200) {
            if (value === 1) {
                this.setState({ verificationImg: res.data })
            } else {
                this.setState({ verificationImg2: res.data })
            }
        }
    }

    public setForm(form: IItem) {
        this.setState({
            form: {
                ...this.state.form,
                ...form
            }
        })
    }
}


export default withRouter(connect(
    (state: any) => ({
        user: state.user
    }),
    (dispatch) => {
        return {
            setUser: (data: any) => dispatch(setUser(data))
        }
    }
)(Index));