export const version = "3.8.7";
export const vCode = 26

export const SW_USER = "/api/system/sw/swUser";
export const SYS_USER = "/api/system/sysUser";
export const API_URL = "/api"

export const STORAGE_ACCESS_TOKEN = "access_token";
export const USER_DATA = "user";
export const CURRENT_MEETING_DATA = "current_meeting_data";
export const SETTING_PARAMS = "setting_params";
export const IS_EXISTING_USER = "is_existing_user";
export const MEET_PROVISIONAL_USERNAME = "meet_provisional_username";
export const GUIDE_PAGE = 'guide_page';
export const SUBTITLE_USAGE_MODAL = "subtitle_usage_modal";// 是否展示字幕使用量
export const WHITEBOARD_LOCALSTORAGE = 'whiteboard_' // 白板本地数据
export const JVB_SERVER_REGION = "jvb_server_region";// 级联

export function LogoutClearStorage() {
    window.localStorage.removeItem(STORAGE_ACCESS_TOKEN);
    window.localStorage.removeItem(USER_DATA);
    window.localStorage.removeItem(CURRENT_MEETING_DATA);
    window.localStorage.removeItem("noticeSocket")
    window.localStorage.removeItem(SUBTITLE_USAGE_MODAL)
    window.localStorage.removeItem(MEET_PROVISIONAL_USERNAME)
    try {
        (window as any).websocketWhiteboard && (window as any).websocketWhiteboard.close()
    } catch { }
}

export const dateFormat = "yyyy-MM-DDTHH:mm:ss.SSSZZ";

// export const mobileDownUrl = `https://www.svmeet.com:19000/android/${(window as any).$environment || "svmeet"}/phone/latest-android.apk`//安卓下载的app路径

// const env = (window as any).$environment === "ml" ? "ml" : ((window as any).$environment === "deve" ? "dev" : "prop")

// export const windowsUrl = `https://www.svmeet.com:19000/electron/${env}/svmeet_setup_latest.exe`

// export const macUrl = `https://www.svmeet.com:19000/electron/${env}/svmeet_setup_latest.dmg`

// export const androidTvUrl = `https://www.svmeet.com:19000/android/${(window as any).$environment || "svmeet"}/tv/last-android-tv.apk`//安卓tv下载路径

// 白板
export const whiteboardUrl = `${(window as any).$environment === "svmeet" ? 'https://www.svmeet.com' : `https://${(window as any).$environment}.svmeet.com`}/whiteboard/`

// faq页面
export const faqUrl = `${getDomainUrl()}/faq/html/111/index.html`

// 直播推送和拉取接口地址
export const LIVE_URL_PUSH = `${(window as any).$environment === "svmeet" ? 'https://www.svmeet.com' : "https://deve.svmeet.com"}/rtc/v1/publish/`;
export const LIVE_URL_PULL = `${(window as any).$environment === "svmeet" ? 'https://www.svmeet.com' : "https://deve.svmeet.com"}/rtc/v1/play/`;
export const LIVE_CODEC = "h264"
export const LIVE_URL = `wss://${(window as any).$environment === "svmeet" ? 'www.svmeet.com' : "deve.svmeet.com"}/rtc/test/`

// 画廊模式最多展示多少个
export const SHOW_N_PARTICIPANTS_PER_PAGE = 16

// 手机重新发送验证码时间
export const PHONE_RESEND_VCODE_TIME = 60

// 邮箱重新发送验证码时间
export const EMAIL_RESEND_VCODE_TIME = 60

export function getWebSocketUrl() {
    if ((window as any).$environment === "ml") {
        return "wss://ml.svmeet.com"
    } else if ((window as any).$environment === "deve") {
        return "wss://deve.svmeet.com"
    } else if ((window as any).$environment === "svmeet") {
        return "wss://www.svmeet.com"
    }
}

export function getDomainUrl() {
    if ((window as any).$environment === "ml") {
        return "https://ml.svmeet.com"
    } else if ((window as any).$environment === "deve") {
        return "https://deve.svmeet.com"
    } else if ((window as any).$environment === "svmeet") {
        return "https://www.svmeet.com"
    }
}
