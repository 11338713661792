//@ts-nocheck
import axios from "axios"

function get_log_func(m_args) {
    let temp_arr: any[] = [];
    for (let m_arg of m_args) {
        if (m_arg) {
            if (m_arg instanceof Object) {
                try {
                    temp_arr.push(JSON.stringify(m_arg));
                } catch (e) {
                    try {
                        let str = '?';
                        for (let key in m_arg) {
                            str += key + '=' + m_arg[key]
                            str += '&'
                        }
                        temp_arr.push(str);
                    } catch (e) {
                        logger.warn('日志转换失败')
                    }
                }
            } else {
                temp_arr.push(m_arg);
            }
        }
    }
    return temp_arr.join(" | ");
}
//@ts-ignore
let loglevel = typeof logLevel !== "undefined" ? logLevel : "WARN"
console.log('日志等级', loglevel)
// 日志等级： VERBOSE < DEBUG < LOG < INFO < WARN < ERROR < OFF

let logger = {
    log: function (...args) {
        if (LogJS && !['OFF', 'ERROR', 'WARN', 'INFO'].includes(loglevel)) {
            LogJS.info(get_log_func(args));
            console.log(new Date(), ...args)
        }
        updateLog(['OFF', 'ERROR', 'WARN', 'INFO'], args)
    },
    info: function (...args) {
        if (LogJS && !['OFF', 'ERROR', 'WARN'].includes(loglevel)) {
            LogJS.info(get_log_func(args));
        }
        console.info(new Date(), ...args)
        updateLog(['OFF', 'ERROR', 'WARN'], args)
    },
    warn: function (...args) {
        if (LogJS && !['OFF', 'ERROR'].includes(loglevel)) {
            LogJS.warn(get_log_func(args));
        }
        console.warn(new Date(), ...args)
        updateLog(['OFF', 'ERROR'], args)
    },
    error: function (...args) {
        if (LogJS && !['OFF'].includes(loglevel)) {
            LogJS.error(get_log_func(args));
        }
        console.error(new Date(), ...args)
        updateLog(['OFF'], args)
    },
    debug: function (...args) {
        if (LogJS && !['OFF', 'ERROR', 'WARN', 'INFO', 'LOG'].includes(loglevel)) {
            LogJS.debug(get_log_func(args));
            console.debug(new Date(), ...args)
        }
        updateLog(['OFF', 'ERROR', 'WARN', 'INFO', 'LOG'], args)
    },
    verbose: function (...args) {
        if (LogJS && !['OFF', 'ERROR', 'WARN', 'INFO', 'LOG', 'DEBUG'].includes(loglevel)) {
            zlog.verbose(get_log_func(args));
            console.log(new Date(), ...args)
        }
        updateLog(['OFF', 'ERROR', 'WARN', 'INFO', 'LOG', 'DEBUG'], args)
    },
}

export { logger }

function updateLog(levels: string[], args) {
    if ((window as any).isUpdateLog) {
        if (!levels.includes((window as any).updateLogParams.level)) {
            if (!(window as any).updateLogParams.module || (window as any).updateLogParams.module === args[0])
                // 上传日志
                __logstash(args)
        }
    }
}

function __logstash(log) {
    let data: any = {}
    data.terminalID = "web"
    data.id = (window as any).updateLogParams.particpantId || '';
    data.name = (window as any).updateLogParams.name || '';
    data.room = (window as any).meetingData.primaryKey || '';
    data.log = log
    axios.post((window as any).updateLogParams.serverUrl || "http://maxframing.svmeet.com:50001",
        JSON.stringify(data), {
        headers: {
            'Content-type': "application/json",
        }
    })
        .then(r => {
        }).catch(r => {
            console.warn(r.message)
        });
}