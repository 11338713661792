
import i18n from "i18n";
import React from "react";
import "./index.css"

export default class extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    public render() {
        return (
            <div className="waitingJoinMeet_page">
                <img src={require("static/images/meet/icon-hourglass.png")} />
                <div className="text">{i18n.t('meet.waitHostArrival')}</div>
            </div>
        );
    }
}