import Login from "../pages/login";
import Home from "../pages/home";
import Meet from "../pages/meet";
import JoinMeet from "../pages/join-meet";
import Feedback from "../pages/feedback";
import CreateMeet from "../pages/maa-meet";
import SummaryModal from "../pages/meet/summary-modal"
import Download from "../pages/download"
import About from "../pages/about-us"
// import ProductIntroduction from "../pages/product-introduction";
import ProblemFeedback from "../pages/problem-feedback"
import LiveMeeting from "../pages/live-meeting";

const defaultRoutes = [
    { path: "/", component: Home },
    { path: "/login/:meetId", component: Login },
    { path: "/login", component: Login },
    { path: "/home", component: Home },
    // { path: "/meeting/:meetId", component: InviteeMeet },
    { path: "/meet/:meetId", component: Meet },
    // { path: "/s/:meetId", component: InviteeMeet },
    { path: "/join-meet", component: JoinMeet },
    { path: "/join-meet/:id", component: JoinMeet }, // 邀请链接
    { path: "/create-meet", component: CreateMeet },
    // { path: "/feedback/:meetId", component: Feedback },
    { path: "/feedback/:meetId/:meetKey", component: Feedback },
    { path: "/download", component: Download },
    { path: "/about", component: About },
    // { path: "/introduction", component: ProductIntroduction },
    { path: "/problem", component: ProblemFeedback },
    // 答辩会入口
    // { path: "/reply", component: ReplyEntry },
    // { path: "/reply-meeting/:meetId", component: ReplyMeeting },
    // 等候室
    // { path: "/waiting-room/:meetId/:project", component: WaitingRoom },
    // { path: "/waiting-room/:meetId", component: WaitingRoom },
    // 应急项目，录屏后台用户
    { path: "/meet/:meetId/:project", component: Meet }, // 
    { path: "/summary", component: SummaryModal },
    { path: "/live-meeting/:meetKey/:meetNumber/:password", component: LiveMeeting }

]

export const routes = [
    ...defaultRoutes,
];