
import React from "react";
import message from "antd/lib/message"
import Select from "antd/lib/select"
import Modal from "antd/lib/modal"
import api from "api"
import i18n from "i18n";
import { Spin, Upload } from "antd"
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons'
import { debounce } from "utils/data";
import "./index.less";

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
export default class extends React.Component<{
    onClose: () => void;
    meetingListId: string;
    type: string;
    styleType: string;
}, {
    content: string;
    type: string | undefined;
    images: string[];
    contactInformation: string;
    typeData: Array<{
        value: string;
        lable: string;
    }>;
    fileList: any[]
    previewImage: string;
    previewVisible: boolean;
    previewTitle: string;
    isSubmitting: boolean;
}> {

    constructor(props) {
        super(props)
        this.submit = debounce(this.submit.bind(this), 500) as any;
        // this.handleChange = debounce(this.handleChange.bind(this), 500) as any;
        this.state = {
            content: "",
            type: this.props.type || undefined,
            images: [],
            contactInformation: "",
            typeData: [
                {
                    lable: i18n.t('meetingQuestions'),
                    value: "6"
                }
            ],
            fileList: [],
            previewVisible: false,
            previewImage: '',
            previewTitle: '',
            isSubmitting: false
        }
    }

    public async submit() {
        const { content, contactInformation, type, fileList, isSubmitting } = this.state;
        if (isSubmitting) return
        if (!content) {
            message.info(i18n.t('meet.enterQuestionDesc'))
            return
        }
        if (!type) {
            message.info(i18n.t('message.PleaseSelectQuestionType'))
            return
        }
        let isPhone: any = null
        let isEmail: any = null
        if (contactInformation && !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(contactInformation)) {
            isPhone = false
        } else {
            isPhone = true
        }
        if (!isPhone) {
            if (contactInformation && !/^[a-zA-Z0-9_\\.-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(contactInformation)) {
                isEmail = false
            } else {
                isEmail = true
            }
        }
        if (contactInformation && !isEmail && !isPhone) {
            if (/^[0-9]*$/.test(contactInformation)) {
                message.warn(i18n.t('message.pleaseEnterValidPhoneNum'))
            } else {
                message.warn(i18n.t('message.pleaseEnterYourVaildEmail'))
            }
            return
        }
        await this.setState({ isSubmitting: true })
        const asyncFun: any[] = [];
        fileList.forEach(async file => {
            asyncFun.push(this.uploadFile(file.originFileObj));
        })
        Promise.all(asyncFun).then(async rs => {
            const data = {
                meetingListId: this.props.meetingListId,
                content: content,
                contactInformation: contactInformation,
                type: Number(type),
                imageUrls: rs
            }
            const res = await api.common.setFeedback(data)
            this.setState({ isSubmitting: false })
            if (res.code === 200) {
                this.props.onClose()
                message.success(i18n.t("thanksFeedback"))
            } else {
                message.error(i18n.t("message.requestFailed"))
            }
        })
    }

    public uploadFile(file) {
        return new Promise(async (resolve, reject) => {
            const res = await api.common.uploadFile(file)
            if (res) {
                resolve(res.data)
            } else {
                message.error(res.msg)
                reject()
            }
        })
    }

    public render() {
        const { typeData, type, content, fileList, previewImage, previewTitle, previewVisible, contactInformation, images } = this.state;
        const { styleType } = this.props
        return <div className="reportProblem_component" >
            <div className={styleType === "1" ? "main active" : "main"} >
                <div className={styleType === "1" ? "container_head active" : "container_head"}>
                    <span>{i18n.t('meet.reportProblem')}</span>
                    <img src={require('static/images/meet/icon-guanbi.png')} style={{ cursor: "pointer" }} alt="" onClick={() => this.props.onClose()} />
                </div>
                <div className='container_main'>
                    <div className="formItem">
                        <div className='label'><span>*</span>{i18n.t('QuestionType')}</div>
                        <div className='inputWrapper' style={{ flexDirection: "column" }}>
                            <Select className={styleType === "1" ? "select active" : "select"} placeholder={i18n.t('message.PleaseSelectQuestionType')} value={this.props.type || type} disabled={!!this.props.type}
                                onChange={(value, e) => {
                                    this.setState({ type: value })
                                }}>
                                {
                                    typeData.map(x => {
                                        return <Select.Option key={x.value} value={x.value}>{x.lable}</Select.Option>
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                    <div className="formItem" style={{ alignItems: "baseline" }}>
                        <div className='label'>
                            <p><span>*</span>{i18n.t('ProblemCommentDescription')}</p>
                            <p>（{content.length}/200)</p>
                        </div>
                        <div className='inputWrapper' style={{ flexDirection: "column" }}>
                            <textarea className={styleType === "1" ? "textarea active" : "textarea"} placeholder={i18n.t('meet.enterQuestionDesc')} name="" id="" maxLength={200}
                                value={content}
                                onChange={(evt) => this.setState({ content: evt.target.value })}
                            ></textarea>
                        </div>
                    </div>
                    <div className="formItem" style={{ alignItems: "baseline" }}>
                        <div className='label'></div>
                        <div className='inputWrapper uploadImage' >
                            <div className="imageList">
                                {
                                    images.map((node, index) => {
                                        return <div className="imgItem">
                                            <img src={node} onClick={this.handlePreview.bind(this, fileList[index])} />
                                            <CloseOutlined className="delete" onClick={() => this.handleDelete(index)} />
                                        </div>
                                    })
                                }
                            </div>
                            <Upload
                                accept={".png, .jpg, .jpeg"}
                                showUploadList={false}
                                beforeUpload={this.beforeUpload.bind(this)}
                                onChange={this.handleChange.bind(this)}
                                onPreview={this.handlePreview.bind(this)}
                            >
                                {images.length >= 3 ? null : <img className="img" src={styleType === "1" ? require("static/images/upload.png") : ""} alt="" />}
                            </Upload>
                        </div>
                    </div>
                    <div className="formItem" >
                        <div className='label'>{i18n.t('ContactInformation')}</div>
                        <div className='inputWrapper' >
                            <input className={styleType === "1" ? "input active" : "input"} placeholder={i18n.t('message.PleaseLeaveContactInformation')} name="" id=""
                                value={contactInformation}
                                onChange={(evt) => this.setState({ contactInformation: evt.target.value.trim() })}
                            ></input>
                        </div>
                    </div>
                    <div className="formItem" >
                        <div className='label'></div>
                        <div className='inputWrapper'>
                            <Spin key="problemKey" indicator={<LoadingOutlined style={{ fontSize: "0.18rem" }} />} spinning={this.state.isSubmitting}>
                                <div className={"btn" + (content && type ? "" : ' btnDisable')} onClick={this.submit.bind(this)}>{i18n.t('meet.submit')}</div>
                            </Spin>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={() => this.setState({ previewVisible: false })} >
                <img alt="" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </div>
    }

    public beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        if (!isJpgOrPng) {
            message.info(i18n.t('message.uploadFile'));
        }
        const isLt2M = file.size / 1024 / 1024 < 1;
        if (!isLt2M) {
            message.info(i18n.t('message.imageSmaller', { num: 1 }));
        }
        return isJpgOrPng && isLt2M || (Upload as any).LIST_IGNORE;
    }

    public handleChange = ({ file }) => {
        const isLt2M = file.size / 1024 / 1024 < 1;
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        if (!(isJpgOrPng && isLt2M)) {
            return
        }
        if (this.state.fileList.length === 0 || this.state.fileList.filter(x => x.uid === file.uid).length === 0) {
            getBase64(file.originFileObj).then((imageUrl) => {
                this.state.images.push(imageUrl as string)
                this.state.fileList.push(file)
                this.setState({ fileList: this.state.fileList, images: this.state.images })
            });
        }
    }

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };

    handleDelete(index) {
        this.state.images.splice(index, 1)
        this.state.fileList.splice(index, 1)
        this.setState({ images: this.state.images, fileList: this.state.fileList })
    }
}