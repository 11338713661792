
import React from "react";
import i18n from "i18n";
import { connect } from "react-redux";
import { enumerateDevices, beginDetect, getUserMedia } from "utils/common";
import { createReplaceBgEffect } from "utils/background"
import Progress from "antd/lib/progress";
import Checkbox from "antd/lib/checkbox";
import Switch from "antd/lib/switch";
import Modal from "antd/lib/modal";
import Select from "antd/lib/select"
import { SETTING_PARAMS } from "config";
import { defaultMeetBackgroundImages } from "utils/lib-jitsi-meet/config"
import { isObjectValueEqual } from "utils/data";
import conference from "utils/lib-jitsi-meet/conference";
import { defaultPictureInPictureData, IDefaultDeviceSetItem, IParticipantItem, IPictureInPictureItem } from "models/meet";
import { IUser } from "models/user";
import { setPictureInPictureData } from "reduxs/actions/meet";
import "./index.less"
interface DispatchProps {
    user: IUser
    pictureInPictureData: IPictureInPictureItem;
    setPictureInPictureData: (setPictureInPictureData: IPictureInPictureItem) => void
}
interface IDevice {
    deviceId: string;
    label: string;
    groupId?: string;
}
interface IState {
    devices: {
        audioInput: IDevice[];
        audioOutput: IDevice[];
        videoInput: IDevice[];
    },
    menuActive: 'blur' | 'img' | "",
    isVideoBlurred: boolean; // 是否背景模糊
    setVideoBgValue: string; // 设置背景的图片地址
    setParams: IDefaultDeviceSetItem;
    audioInputPercent: number;
    isLoading: boolean;
    currentMenu: "device" | "bg" | "watermark" | "pictureInPicture";
    detectSpeaker: boolean;
    pictureInPictureData: IPictureInPictureItem;
}

let isSafariBrowser = false;
let isMobile = false
class Index extends React.Component<{
    settingParams?: IDefaultDeviceSetItem;
    styleDisplay?: string
    menu: string[] //'videoinput', 'audioinput', 'audiooutput','bgSet','watermark','pictureInPicture'
    participants: IParticipantItem[];
    setVideoInputDevice?: (deviceId) => void;
    setAudioInputDevice?: (deviceId) => void;
    setAudiooutputDevice?: (deviceId) => void;
    onClose: () => void;
    setBgImg?: (blurEffectInstance, settingParams?: IDefaultDeviceSetItem) => void;
    switchWatermark?: (checked) => void;
    switchPictureInPicture?: (checked, data?) => void
} & DispatchProps, IState> {

    public state: IState = {
        devices: {
            audioInput: [],
            audioOutput: [],
            videoInput: [],
        },
        menuActive: '',
        isVideoBlurred: false,
        setVideoBgValue: '',
        setParams: {
            backgroundModel: ""
        },
        audioInputPercent: 0,
        isLoading: true,
        currentMenu: "device",
        detectSpeaker: false,
        pictureInPictureData: defaultPictureInPictureData
    }

    public async componentDidMount() {
        if (this.props.pictureInPictureData && this.props.pictureInPictureData.switch) {
            this.setPictureInPictureData()
        }
        //@ts-ignore
        JitsiMeetJS.init();
        if (this.props.settingParams) {
            await this.setState({ setParams: this.props.settingParams })
        } else {
            const setParams = window.localStorage.getItem(SETTING_PARAMS)
            if (setParams) {
                await this.setState({ setParams: JSON.parse(setParams) })
            }
        }
        if (this.props.menu.indexOf('audioinput') > -1) {
            this.isRecord = true
            this.toggleAudioDevice(this.state.setParams.audioInput ? this.state.setParams.audioInput.deviceId : "default")
        }
        this.createLocalTracks(this.state.setParams.videoInput ? this.state.setParams.videoInput.deviceId : 'default')
        this.enumerateDevices()

        this.detection()
        this.setState({ isLoading: false })
        //@ts-ignore
        // this.cameraChangeSupported = JitsiMeetJS.mediaDevices.isDeviceChangeAvailable('input');
        //@ts-ignore
        this.speakerChangeSupported = JitsiMeetJS.mediaDevices.isDeviceChangeAvailable('output');
    }

    public detection() {
        isSafariBrowser = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
        const u = navigator.userAgent;
        if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {//安卓手机
            console.log("安卓手机");
        } else if (u.indexOf('iPhone') > -1) {//苹果手机
            isMobile = true;
            console.log("苹果手机");
        } else if (u.indexOf('Windows Phone') > -1) {//winphone手机
            console.log("winphone手机");
        }
        if (navigator.userAgent.match(/iPad/i)) {
            isMobile = true;
        }
    }

    // 创建视频媒体
    public createLocalTracks(deviceId) {
        if (isSafariBrowser && isMobile) {
            return
        }
        this.localMediaStream && this.localMediaStream.stream.getTracks().map(x => {
            x.stop()
        })
        let video = document.getElementById('set_camera_component_videoinput') as any;
        if (video) {
            video.src = null
        }
        setTimeout(() => {
            // @ts-ignore
            JitsiMeetJS.createLocalTracks({
                devices: ["video"],
                cameraDeviceId: deviceId,
            })
                .then((localTracks) => {
                    this.localMediaStream = localTracks[0];
                    this.setVideo()
                    if (this.props.menu.indexOf('bgSet') > -1) {
                        if (this.state.setParams.backgroundModel === "img") {
                            this.setReplaceBgEffect({ path: this.state.setParams.backgroundImg })
                        } else if (this.state.setParams.backgroundModel === "blur") {
                            this.setState({ isVideoBlurred: true }, () => {
                                this.setBlurEffect()
                            })
                        }
                    }
                })
                .catch(error => {
                    console.error(error);
                    conference._handleAudioVideoError(error, 'video')
                });
        }, 100)
    }

    // 创建音频媒体
    public toggleAudioDevice(deviceId) {
        if (isSafariBrowser && isMobile) {
            return
        }
        getUserMedia("audio", deviceId ? deviceId : "default", (stream) => {
            this.isRecord = false
            this.isRecord = true
            if (this.localMediaStreamAudio) {
                this.localMediaStreamAudio.getTracks().map(x => {
                    x.stop()
                })
            }
            this.localMediaStreamAudio = stream;
            beginDetect(stream, () => this.isRecord, (mv) => {
                this.setState({ audioInputPercent: mv })
            })
        })
    }

    public setVideo() {
        const node = document.getElementById("set_camera_component_videoinput")
        node && this.localMediaStream.attach(node);
    }

    public changeMenu(value) {
        if (this.state.currentMenu === "pictureInPicture" && value !== "pictureInPicture" && this.state.pictureInPictureData.switch) {
            // 画中画开启时需要提示用户
            this.pictureInPConfirmModal(value)
        } else {
            this.setState({ currentMenu: value })
        }
    }

    public render() {
        const carouselList: any[] = []
        for (let i = 0; i < defaultMeetBackgroundImages.length; i += 3) {
            carouselList.push(defaultMeetBackgroundImages.slice(i, i + 3));
        }
        const { devices, setParams, isVideoBlurred, currentMenu, pictureInPictureData } = this.state;
        const { participants } = this.props
        return <div className='set_camera_component' id='set_camera_component'
            style={{ display: this.props.styleDisplay || "" }}
            onClick={(e: any) => {
                if (e.target.className === 'set_camera_component' && !(currentMenu === "pictureInPicture" && pictureInPictureData.switch)) {
                    this.props.onClose()
                }
            }}>
            <div className='container' style={{ width: currentMenu === "pictureInPicture" ? 'max-content' : "4.50rem" }}>
                <div className='container_head'>
                    <span>{i18n.t('set')}</span>
                    <img src={require('static/images/meet/icon-guanbi.png')} style={{ cursor: "pointer" }} alt="" onClick={this.closeModal.bind(this)} />
                </div>
                <div className='container_title'>
                    <div className={`device ${currentMenu === "device" && "active"}`} onClick={this.changeMenu.bind(this, "device")}>{i18n.t("devices")}</div>
                    {this.props.menu.indexOf('bgSet') > -1 && <div className={`${currentMenu === "bg" && "active"}`} onClick={this.changeMenu.bind(this, "bg")}>{i18n.t("background")}</div>}
                    {this.props.menu.indexOf('watermark') > -1 && <div className={`${currentMenu === "watermark" && "active"}`} onClick={this.changeMenu.bind(this, "watermark")}>{i18n.t("watermark")}</div>}
                    {this.props.menu.indexOf('pictureInPicture') > -1 && <div className={`${currentMenu === "pictureInPicture" && "active"}`} onClick={this.changeMenu.bind(this, "pictureInPicture")}>{i18n.t("meet.pictureInPictureFollow")}</div>}
                </div>
                {
                    currentMenu !== "watermark" && currentMenu !== "pictureInPicture" && !this.state.isLoading && ((isSafariBrowser && isMobile) ? "" : <div className='container_video'>
                        <video id={"set_camera_component_videoinput"} autoPlay={true} style={{ width: '100%', height: "2.2rem", background: "#000" }}></video>
                    </div>)
                }
                {
                    currentMenu === "device" && this.props.menu.indexOf('videoinput') > -1 && <div className='container_devices'>
                        <select className='select'
                            value={this.state.setParams.videoInput?.deviceId}
                            onChange={(e) => {
                                this.setState({
                                    setParams: {
                                        ...this.state.setParams,
                                        videoInput: {
                                            deviceId: e.currentTarget.value,
                                        }
                                    }
                                }, () => this.setStorage())
                                this.createLocalTracks(devices.videoInput[0].deviceId === e.currentTarget.value ? "default" : e.currentTarget.value)
                                this.props.setVideoInputDevice && this.props.setVideoInputDevice(e.currentTarget.value)
                            }}>
                            {
                                devices.videoInput.map(ele => {
                                    return <option style={{ background: "#4d4d4d" }} key={ele.deviceId} value={ele.deviceId}>{ele.label}</option>
                                })
                            }
                        </select>
                    </div>
                }
                {/* {this.props.menu.indexOf('bgSet') > -1 && <div className='container_background_title'>背景设置</div>} */}
                {/* {this.props.menu.indexOf('bgSet') > -1 && <div className='container_background_btns'>
                    <div className={`btn ${menuActive === 'blur' ? "active" : ""}`}
                        onClick={() => {
                            this.setState({
                                menuActive: menuActive === "blur" ? "" : "blur", setParams: {
                                    ...this.state.setParams,
                                    backgroundModel: menuActive === "blur" ? "" : "blur"
                                }
                            }, () => {
                                this.setStorage()
                                this.setBlurEffect()
                                //@ts-ignore
                                _hmt.push(['_trackEvent', "button", "click", 'set_background_blur_btn'])
                            })
                        }}>背景模糊</div>
                    <div className={`btn ${menuActive === 'img' ? "active" : ""}`}
                        onClick={() => this.setState({ menuActive: "img" }, () => {
                            //@ts-ignore
                            _hmt.push(['_trackEvent', "button", "click", 'set_background_image_btn'])
                        })}>背景设置</div>
                </div>} */}

                {
                    currentMenu === "bg" && this.props.menu.indexOf('bgSet') > -1 ?
                        <div className='setBgList'>
                            <div className={`setBgItem`} onClick={() => {
                                this.setState({
                                    isVideoBlurred: false, setParams: {
                                        backgroundModel: ""
                                    }
                                }, () => {
                                    this.setStorage()
                                    this.setReplaceBgEffect('')
                                })
                            }}>
                                {i18n.t("without")}
                            </div>
                            <div className={`setBgItem` + (isVideoBlurred ? " setBgItemActive" : "")}
                                onClick={() => {
                                    if (this.state.isVideoBlurred) return
                                    this.setState({
                                        isVideoBlurred: true,
                                        setParams: {
                                            ...this.state.setParams,
                                            backgroundModel: "blur"
                                        }
                                    }, () => {
                                        this.setStorage()
                                        this.setBlurEffect()
                                    })
                                    //@ts-ignore
                                    _hmt.push(['_trackEvent', "button", "click", 'set_background_blur_btn'])
                                }}>
                                {i18n.t('blurBackground')}
                            </div>
                            {
                                defaultMeetBackgroundImages.map(data => {
                                    return <div key={data.id} className={'setBgItem' + (setParams.backgroundModel === "img" && data.path === setParams.backgroundImg ? " setBgItemActive" : "")}
                                        onClick={() => {
                                            this.setState({
                                                isVideoBlurred: false, setParams: {
                                                    backgroundModel: "img",
                                                    backgroundImg: data.path
                                                }
                                            }, () => this.setStorage())
                                            this.setReplaceBgEffect(data)
                                        }}>
                                        <img src={data.path} alt="" />
                                    </div>
                                })
                            }
                        </div> : ""
                }

                {currentMenu === "device" && this.props.menu.indexOf('audioinput') > -1 && <div className='container_background_title'>
                    <span style={{ marginRight: "0.1rem" }}>{i18n.t('microphoneSetting')}</span>
                    {
                        (isSafariBrowser && isMobile) ? "" : <Progress percent={this.state.audioInputPercent} steps={15} size="small" strokeColor="#51F51F" format={() => ""} />
                    }
                </div>}

                {
                    currentMenu === "device" && this.props.menu.indexOf('audioinput') > -1 && <div className='container_devices' style={{ marginTop: "0.1rem" }}>
                        <select className='select'
                            value={this.state.setParams.audioInput?.deviceId}
                            onChange={(e) => {
                                this.setState({
                                    setParams: {
                                        ...this.state.setParams,
                                        audioInput: {
                                            deviceId: e.currentTarget.value,
                                        }
                                    }
                                }, () => {
                                    this.setStorage()
                                    this.searchDeviceGroupId(this.state.setParams.audioInput?.deviceId, "audioInput")
                                })
                                this.toggleAudioDevice(e.currentTarget.value)
                                this.props.setAudioInputDevice && this.props.setAudioInputDevice(e.currentTarget.value)
                            }}>
                            {
                                devices.audioInput.map(ele => {
                                    return <option style={{ background: "#4d4d4d" }} key={ele.deviceId} value={ele.deviceId}>{ele.label}</option>
                                })
                            }

                        </select>
                    </div>
                }

                {this.speakerChangeSupported && currentMenu === "device" && this.props.menu.indexOf('audiooutput') > -1 && <div className='container_background_title'>{i18n.t('speakerSetting')}</div>}
                {/* <div style={{ color: "#fff" }} onClick={() => {
                    // const audio = document.createElement("audio")
                    // audio.autoplay = true
                    // audio.loop = true
                    // audio.style.cssText = "display:none"
                    // audio.src = require("static/audio/1.mp3")
                    // document.body.appendChild(audio)
                    //@ts-ignore
                    const dom = document.getElementById("detectSpeaker") as any
                    let timer;
                    if (detectSpeaker) {
                        timer && clearInterval(timer)
                        dom && dom.pause()
                    } else {
                        dom && dom.play()
                        this.props.settingParams?.audioOutput?.deviceId && dom && dom.setSinkId(this.props.settingParams?.audioOutput?.deviceId)
                        timer = setInterval(() => {
                            console.log(dom.volume)
                        }, 500)
                    }
                    this.setState({ detectSpeaker: !detectSpeaker })
                }}>{detectSpeaker ? "停止测试" : "检测扬声器"}</div>
                <audio id="detectSpeaker" loop style={{ display: "none" }} src={require("static/audio/1.mp3")}></audio> */}
                {
                    this.speakerChangeSupported && currentMenu === "device" && this.props.menu.indexOf('audiooutput') > -1 && <div className='container_devices' style={{ marginTop: "0.1rem" }}>
                        <select className='select'
                            value={this.state.setParams.audioOutput?.deviceId}
                            onChange={(e) => {
                                this.setState({
                                    setParams: {
                                        ...this.state.setParams,
                                        audioOutput: {
                                            deviceId: e.currentTarget.value,
                                        }
                                    }
                                }, () => this.setStorage())
                                this.props.setAudiooutputDevice && this.props.setAudiooutputDevice(e.currentTarget.value)
                                // this.searchDeviceGroupId(e.currentTarget.value, "audioOutput")
                            }}>
                            {
                                devices.audioOutput.map(ele => {
                                    return <option style={{ background: "#4d4d4d" }} key={ele.deviceId} value={ele.deviceId}>{ele.label}</option>
                                })
                            }

                        </select>
                    </div>
                }
                {// 水印
                    currentMenu === "watermark" && <div className="menuWatermark">
                        <div style={{ marginRight: "0.1rem" }}>{i18n.t('turnOnScreenWatermark')}</div>
                        <Checkbox className="watermarkCheckbox" checked={setParams.watermark} onChange={(e) => {
                            this.setState({
                                setParams: {
                                    ...setParams,
                                    watermark: e.target.checked
                                }
                            }, () => {
                                this.setStorage()
                                this.props.switchWatermark && this.props.switchWatermark(this.state.setParams.watermark)
                            })
                        }}></Checkbox>
                    </div>
                }
                {// 画中画
                    currentMenu === "pictureInPicture" && <div className="menuPictureInPicture">
                        <div className="itemform">
                            <div className="label">{i18n.t("meet.followSwitch")}</div>
                            <Switch checked={pictureInPictureData.switch} onChange={(value) => {
                                if (value) {
                                    this.setPictureState({ switch: value })
                                } else {
                                    this.pictureInPConfirmModal()
                                }
                            }} />
                        </div>
                        {
                            pictureInPictureData.switch && <React.Fragment>
                                <div className="itemform">
                                    <div className="label">{i18n.t("meet.template")}</div>
                                    <div className="templateBox">
                                        <div className="item" onClick={() => this.setPictureState({ template: 1 })}>
                                            <img src={require("static/images/pictureInPicture/template1.png")} alt="" />
                                            <img src={require(pictureInPictureData.template === 1 ? "static/images/pictureInPicture/radioA.png" : "static/images/pictureInPicture/radio.png")} alt="" />
                                        </div>
                                        <div className="item" onClick={() => this.setPictureState({ template: 2 })}>
                                            <img src={require("static/images/pictureInPicture/template2.png")} alt="" />
                                            <img src={require(pictureInPictureData.template === 2 ? "static/images/pictureInPicture/radioA.png" : "static/images/pictureInPicture/radio.png")} alt="" />
                                        </div>
                                        <div className="item" onClick={() => this.setPictureState({ template: 3 })}>
                                            <img src={require("static/images/pictureInPicture/template3.png")} alt="" />
                                            <img src={require(pictureInPictureData.template === 3 ? "static/images/pictureInPicture/radioA.png" : "static/images/pictureInPicture/radio.png")} alt="" />
                                        </div>
                                        <div className="item" onClick={() => this.setPictureState({ template: 4 })}>
                                            <img src={require("static/images/pictureInPicture/template4.png")} alt="" />
                                            <img src={require(pictureInPictureData.template === 4 ? "static/images/pictureInPicture/radioA.png" : "static/images/pictureInPicture/radio.png")} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="itemform">
                                    <div className="label">{i18n.t("meet.picturePlane")}</div>
                                    <div className="mainWindowBox">
                                        <div className="item">
                                            <span>{i18n.t("meet.mainWindow")}</span>
                                            <Select className='antdSelect'
                                                value={pictureInPictureData.mainPicture.defaultLabel || pictureInPictureData.mainPicture.value}
                                                onChange={(value) => this.setPictureState({ mainPicture: { ...pictureInPictureData.mainPicture, value } })}>
                                                <Select.Option value="systemAutomatic">{i18n.t("meet.sharingScreen")}</Select.Option>
                                                {
                                                    participants.map(ele => {
                                                        if (!ele.sharedUser) {
                                                            return <Select.Option disabled={ele.local && ele.businessUserId === pictureInPictureData.picture1.value} key={ele.userId}
                                                                value={ele.businessUserId}>{ele.realName || ele._displayName}</Select.Option>
                                                        }
                                                    })
                                                }
                                            </Select>
                                        </div>
                                        <div className="item">
                                            <span>{i18n.t("meet.pictureInPicture1")}</span>
                                            <Select className='antdSelect'
                                                value={pictureInPictureData.picture1.defaultLabel || pictureInPictureData.picture1.value}
                                                onChange={(value) => this.setPictureState({ picture1: { ...pictureInPictureData.picture1, value: value } })}>
                                                {(pictureInPictureData.template === 1 || pictureInPictureData.template === 2) && <Select.Option value="voiceStimulation">{i18n.t("voiceStimulation")}</Select.Option>}
                                                {
                                                    participants.map(ele => {
                                                        if (!ele.sharedUser) {
                                                            return <Select.Option disabled={ele.businessUserId === pictureInPictureData.mainPicture.value} key={ele.userId}
                                                                value={ele.businessUserId}>{ele.realName || ele._displayName}</Select.Option>
                                                        }
                                                    })
                                                }
                                            </Select>
                                        </div>
                                        {
                                            (pictureInPictureData.template === 3 || pictureInPictureData.template === 4) && <div className="item">
                                                <span>{i18n.t("meet.pictureInPicture2")}</span>
                                                <Select className='antdSelect'
                                                    value={pictureInPictureData.picture2.defaultLabel || pictureInPictureData.picture2.value}
                                                    onChange={(value) => this.setPictureState({ picture2: { ...pictureInPictureData.picture2, value } })}>
                                                    <Select.Option value="voiceStimulation">{i18n.t("voiceStimulation")}</Select.Option>
                                                    {
                                                        participants.map(ele => {
                                                            if (!ele.sharedUser) {
                                                                return <Select.Option disabled={ele.businessUserId === pictureInPictureData.mainPicture.value || (ele.businessUserId === pictureInPictureData.picture1.value)} key={ele.userId}
                                                                    value={ele.businessUserId}>{ele.realName || ele._displayName}</Select.Option>
                                                            }
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                }
            </div>
        </div >
    }
    // 画中画数据setState
    public setPictureState(data, isSendWs?: boolean) {
        const { pictureInPictureData } = this.state
        console.log('画中画数据', data, pictureInPictureData)
        if (data.hasOwnProperty("mainPicture")) {
            data.mainPicture.defaultLabel = ""
            if (data.mainPicture.value === pictureInPictureData.picture1.value) {//主窗口和窗口1相同时：按顺序给画中画1选一个或语音激励
                const target = this.props.participants.filter(x => !x.sharedUser && x.businessUserId !== data.mainPicture.value && x.businessUserId !== pictureInPictureData.picture2.value)
                if (pictureInPictureData.template === 1 || pictureInPictureData.template === 2) {
                    pictureInPictureData.picture1.value = "voiceStimulation"
                } else {
                    if (target.length) { // 去重后有用户
                        pictureInPictureData.picture1.value = target[0].businessUserId
                    } else { // 去重后用户不够
                        pictureInPictureData.picture1.value = pictureInPictureData.picture2.value
                        pictureInPictureData.picture2.value = "voiceStimulation"
                    }
                }
            } else if (data.mainPicture.value === pictureInPictureData.picture2.value) {//主窗口和窗口1相同时：语音激励
                pictureInPictureData.picture2.value = "voiceStimulation"
            }
        } else if (data.hasOwnProperty("picture1")) {
            data.picture1.defaultLabel = ""
            if (data.picture1.value === pictureInPictureData.picture2.value) {
                pictureInPictureData.picture2.value = "voiceStimulation"
            }
        } else if (data.hasOwnProperty("template")) {
            if ((data.template === 3 || data.template === 4) && pictureInPictureData.picture1.value === "voiceStimulation") {
                const target = this.props.participants.filter(x => !x.sharedUser && x.businessUserId !== pictureInPictureData.mainPicture.value)
                if (target.length) {
                    pictureInPictureData.picture1.value = target[0].businessUserId
                }
            }
        } else if (data.hasOwnProperty("picture2")) {
            data.picture2.defaultLabel = ""
        } else if (data.hasOwnProperty("switch")) {
            if (data.switch && !pictureInPictureData.picture1.value) { // 画中画1默认值是自己
                pictureInPictureData.picture1.value = this.props.user.primaryKey || ""
            }
        }
        this.setState({ pictureInPictureData: { ...pictureInPictureData, ...data } }, async () => {
            if (isSendWs) {
                this.sendPictureWs(this.state.pictureInPictureData.switch)
            }
        })
    }
    // 画中画：转换数据发给服务端
    public async sendPictureWs(data) {
        const { pictureInPictureData } = this.state
        console.log('转换数据发给服务端', data, pictureInPictureData)
        const obj: any = {
            style: 2,
            mainWindow: {},
            windows: [],
            stencil: pictureInPictureData.template
        }
        if (data) {
            if (isObjectValueEqual(this.props.pictureInPictureData, pictureInPictureData)) {//没做修改
                return
            }
            await this.props.setPictureInPictureData(this.state.pictureInPictureData)
            // 通知会中所有人开启跟随
            switch (pictureInPictureData.template) {
                case 1:
                    obj.style = 2
                    break;
                case 2:
                    obj.style = 1
                    break;
                case 3:
                    obj.style = 2
                    break;
                case 4:
                    obj.style = 1
                    break;
                default:
                    break;
            }
            if (pictureInPictureData.hasOwnProperty("mainPicture")) { // 主画面
                this.props.participants.forEach(p => {
                    if (p.businessUserId === pictureInPictureData.mainPicture.value) {
                        obj.mainWindow.type = 3;// 具体用户
                        obj.mainWindow.userId = p.businessUserId;
                    } else if (Object.keys(obj.mainWindow).length === 0 && pictureInPictureData.mainPicture.value === "systemAutomatic") {
                        if (p.sharedUser || (p.businessUserId !== pictureInPictureData.picture1.value && (p.businessUserId !== pictureInPictureData.picture2.value))) {
                            obj.mainWindow.type = 1;// 共享
                            obj.mainWindow.dataId = p.userId
                            obj.mainWindow.userId = p.businessUserId
                        }
                    }
                })
            }
            if (pictureInPictureData.hasOwnProperty("picture1")) { // 画中画1
                obj.windows.push({})
                if (pictureInPictureData.picture1.value === "voiceStimulation") { // 语音激励
                    obj.windows[0].type = 2;
                } else {
                    this.props.participants.forEach(p => {
                        if (p.businessUserId === pictureInPictureData.picture1.value) {
                            obj.windows[0].userId = p.businessUserId;
                            obj.windows[0].type = 3;// 具体用户
                        }
                    })
                }
                obj.windows[0].number = 1
                obj.windows[0].hide = false
            }

            if ((pictureInPictureData.template === 3 || pictureInPictureData.template === 4) && pictureInPictureData.hasOwnProperty("picture2")) { // 画中画2
                obj.windows.push({})
                if (pictureInPictureData.picture2.value === "voiceStimulation") { // 语音激励
                    obj.windows[1].type = 2;
                } else {
                    this.props.participants.forEach(p => {
                        if (p.businessUserId === pictureInPictureData.picture2.value) {
                            obj.windows[1].userId = p.businessUserId;
                            obj.windows[1].type = 3;// 具体用户
                        }
                    })
                }
                obj.windows[1].number = 2
                obj.windows[1].hide = false
            }
            if (!Object.keys(obj.mainWindow).length) { // 人员不足模式需要的人数时，主画面选择共享屏幕可能会出现没有的情况
                obj.mainWindow = {
                    type: 1
                }
            }
            console.log('发给服务端的数据', obj)
            this.props.switchPictureInPicture && this.props.switchPictureInPicture(true, obj)
        } else {
            this.props.setPictureInPictureData(this.state.pictureInPictureData)
            // 通知会中所有人关闭跟随
            if (!pictureInPictureData.switch) {
                this.props.switchPictureInPicture && this.props.switchPictureInPicture(false)
            }
        }
    }
    // 画中画：确认同步跟随
    public pictureInPConfirmModal(changeMenuValue?, isClose?) {
        const modal = Modal.confirm({
            title: null,
            icon: null,
            className: "cast-modal",
            content: <div className="cast-modal-content" >
                <div>{i18n.t("message.synchronizeChangesAllParticipants")}</div>
                <div className="btns">
                    <div className="btn1" onClick={() => {
                        modal.destroy()
                        if (changeMenuValue) {
                            this.setState({
                                currentMenu: changeMenuValue,
                                pictureInPictureData: this.props.pictureInPictureData.switch ? this.props.pictureInPictureData : defaultPictureInPictureData
                            })
                        } else if (isClose) {
                            this.props.onClose()
                        }
                    }}>{i18n.t("cancel")}</div>
                    <div className="btn2" onClick={() => {
                        if (changeMenuValue) { // 切换菜单提示
                            this.setState({ currentMenu: changeMenuValue })
                            this.sendPictureWs(true)
                        } else if (isClose) { // 关闭设置提示
                            this.sendPictureWs(true)
                            this.props.onClose()
                        } else { // 跟随开关按钮关闭提示
                            this.setPictureState({ switch: false }, true)
                        }
                        modal.destroy()
                    }}>{i18n.t("meet.determine")}</div>
                </div>
            </div>,
        })
    }
    // 画中画：关闭modal
    public closeModal() {
        const { currentMenu, pictureInPictureData } = this.state;
        if (currentMenu === "pictureInPicture" && pictureInPictureData.switch) {
            // 画中画开启时需要提示用户
            this.pictureInPConfirmModal("", true)
        } else {
            this.props.onClose()
        }
    }
    // 当数据发生变化时更新画中画dom
    public setPictureInPictureData() {
        const pictureInPictureData = this.props.pictureInPictureData
        if (pictureInPictureData.mainPicture.value) pictureInPictureData.mainPicture.defaultLabel = i18n.t("message.participantsHaveExited")
        if (pictureInPictureData.picture1.value) pictureInPictureData.picture1.defaultLabel = i18n.t("message.participantsHaveExited")
        if (pictureInPictureData.picture2.value && (pictureInPictureData.template === 3 || pictureInPictureData.template === 4)) {
            pictureInPictureData.picture2.defaultLabel = i18n.t("message.participantsHaveExited")
        }
        this.props.participants && this.props.participants.forEach(participant => {
            if (pictureInPictureData.mainPicture.value === "systemAutomatic" || participant.businessUserId === pictureInPictureData.mainPicture.value) {
                pictureInPictureData.mainPicture.defaultLabel = ""
            }
            if (pictureInPictureData.picture1.value === "voiceStimulation" || participant.businessUserId === pictureInPictureData.picture1.value) {
                pictureInPictureData.picture1.defaultLabel = ""
            }
            if (pictureInPictureData.picture2.value === "voiceStimulation" || participant.businessUserId === pictureInPictureData.picture2.value) {
                pictureInPictureData.picture2.defaultLabel = ""
            }
        })
        this.setState({ pictureInPictureData })
    }
    public searchDeviceGroupId(deviceId, kind: "audioInput" | "audioOutput") {
        if (kind === "audioInput") {
            const targetAudioInput = this.state.devices.audioInput.filter(d => d.deviceId === deviceId)
            if (targetAudioInput.length) {
                // 蓝牙耳机：对应的扬声器设备（输入和输出需要同时选中groupId相同的才会有声音）
                const corresponding = this.state.devices.audioOutput.filter(d => d.groupId === targetAudioInput[0].groupId)
                if (corresponding.length) {
                    this.setState({
                        setParams: {
                            ...this.state.setParams,
                            audioOutput: {
                                deviceId: corresponding[0].deviceId,
                            }
                        }
                    }, () => this.setStorage())
                    this.props.setAudiooutputDevice && this.props.setAudiooutputDevice(corresponding[0].deviceId)
                }
            }
        }
        // if (kind === "audioOutput") {
        //     const targetAudioOutput = this.state.devices.audioOutput.filter(d => d.deviceId === deviceId)
        //     console.log(targetAudioOutput)
        //     if (targetAudioOutput.length) {
        //         // 蓝牙耳机：对应的麦克风设备（输入和输出需要同时选中groupId相同的才会有声音）
        //         const corresponding = this.state.devices.audioInput.filter(d => d.groupId === targetAudioOutput[0].groupId)
        //         if (corresponding.length) {
        //             console.log(corresponding)
        //             this.setState({
        //                 setParams: {
        //                     ...this.state.setParams,
        //                     audioInput: {
        //                         deviceId: corresponding[0].deviceId,
        //                     }
        //                 }
        //             }, () => this.setStorage())
        //             this.toggleAudioDevice(corresponding[0].deviceId)
        //             this.props.setAudioInputDevice && this.props.setAudioInputDevice(corresponding[0].deviceId)
        //         }
        //     }
        // }
    }

    public enumerateDevices() {
        enumerateDevices((videoInput, audioInput, audioOutput) => {
            this.setState({
                devices: {
                    videoInput,
                    audioInput,
                    audioOutput,
                },
            })
        })
    }

    public setStorage() {
        let param: any = window.localStorage.getItem(SETTING_PARAMS)
        if (param) {
            param = JSON.parse(param)
            window.localStorage.setItem(SETTING_PARAMS, JSON.stringify({ ...param, ...this.state.setParams }))
        }
    }

    // 设置背景模糊
    public async setBlurEffect() {
        // this.setState({ isVideoBlurred: true }, async () => {
        if (this.state.isVideoBlurred) {
            await createReplaceBgEffect("", "blur")
                .then(blurEffectInstance => {
                    this.props.setBgImg && this.props.setBgImg(blurEffectInstance, this.state.setParams)
                    createReplaceBgEffect("", "blur").then((blurEffectInstance1) => {
                        this.localMediaStream.setEffect(blurEffectInstance1)
                            .then(async () => {
                                await this.setVideo()
                                console.log('setEffect success')
                            }).catch(error => {
                                console.error('setEffect failed with error:', error);
                                this.setState({ isVideoBlurred: false })
                            })
                    })
                }).catch(error => {
                    console.error('createBlurEffect failed with error:', error);
                    this.setState({ isVideoBlurred: false })
                })
            // } else {
            //     this.props.setBgImg && this.props.setBgImg(undefined, this.state.setParams)
            //     this.localMediaStream.setEffect(undefined).then(async () => {
            //         await this.setVideo()
            //         console.log('setEffect success')
            //         this.setState({ isVideoBlurred: false })
            //     })
        }
        // })
    }

    // 背景设置
    public async setReplaceBgEffect(data) {
        if (!data || this.state.setVideoBgValue === data.path) {
            this.props.setBgImg && this.props.setBgImg(undefined, this.state.setParams)
            this.localMediaStream.setEffect(undefined)
            this.setVideo()

        } else {
            await createReplaceBgEffect(data.path)
                .then(blurEffectInstance => {
                    this.props.setBgImg && this.props.setBgImg(blurEffectInstance, this.state.setParams)
                    console.log(blurEffectInstance)
                    // 单独给设置的video创建
                    createReplaceBgEffect(data.path).then((blurEffectInstance1) => {
                        this.localMediaStream.setEffect(blurEffectInstance1)
                            .then(async () => {
                                this.setVideo()
                                console.log('setEffect success')
                            }).catch(error => {
                                console.error('setEffect failed with error:', error);
                                this.setState({ setVideoBgValue: '' })
                            })
                    })
                }).catch(error => {
                    console.error('createBlurEffect failed with error:', error);
                    this.setState({ setVideoBgValue: '' })
                })
        }
    }

    public clear() {
        this.localMediaStream && this.localMediaStream.setEffect(undefined).then(() => {
            if (isSafariBrowser && isMobile) {
            } else {
                this.localMediaStreamAudio && this.localMediaStreamAudio.getTracks().map(x => {
                    x.stop()
                })
                this.localMediaStream && this.localMediaStream.stream.getTracks().map(x => {
                    x.stop()
                })
            }

            let video = document.getElementById('set_camera_component_videoinput') as any;
            if (video) {
                video.src = null
            }
        })
    }

    public componentWillUnmount() {
        this.clear()
        this.isRecord = false
        // 清除video
        navigator.mediaDevices.ondevicechange = () => { }
    }

    private localMediaStream;
    private localMediaStreamAudio;
    private isRecord = false;
    private speakerChangeSupported;
    // private cameraChangeSupported;
}

export default connect(
    (state: any) => ({
        pictureInPictureData: state.pictureInPictureData,
        user: state.user
    }),
    (dispatch) => {
        return {
            setPictureInPictureData: (data: any) => dispatch(setPictureInPictureData(data))
        }
    }, null, { forwardRef: true }
)(Index)
